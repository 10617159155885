import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { useSelector } from 'react-redux'

import { getFirstSectionId } from 'modules/exam/ducks/selectors'

export const TutorialEnd = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => moveToExamSection()

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialSectionReview)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))
  }, [])

  return (
    <ExamContent>
      <TutorialEndContainer>
        <div>
          <div className='heavy-bordered'>
            <h2>End of Tutorial</h2>
            <p>This concludes the tutorial.</p>
            <p>
              You can now use your computer mouse and keyboard to navigate the
              exam.
            </p>
          </div>
          <p>
            Select{' '}
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page and begin the exam.
          </p>
        </div>
      </TutorialEndContainer>
    </ExamContent>
  )
}

export default TutorialEnd

const TutorialEndContainer = styled.div`
  p {
    margin: 16px 0;
    line-height: 22.4px;
  }

  ul,
  li {
    line-height: 22.4px;
  }

  li {
    margin-bottom: 2px !important;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }

  .indent {
    text-indent: 30px;
  }

  .answer {
    margin-top: 20px !important;
  }
`

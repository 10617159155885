import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import * as R from 'ramda'
import qs from 'qs'

import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import { authStudent } from 'services/AuthService'
import { fetchExamsList } from 'services/ExamsService'

import { Heading1 } from 'examkrackers-components'

export const PreviewExam = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const { push } = useHistory()

  const token = R.propOr('', 'token', params)
  const examId = R.propOr('', 'examId', params)

  const handleFetchAndRedirectExams = () => {
    const handleSuccess = response => {
      const examsList = R.pathOr([], ['data', 'data'], response)
      const studentExamId = R.pipe(
        R.find(R.propEq('exam_id', examId)),
        R.propOr('', 'id')
        // @ts-ignore
      )(examsList)

      push(`/exam/${studentExamId}`)
    }
    const handleError = e => {
      console.error(e)
    }

    fetchExamsList({
      query: `?${qs.stringify({
        order: {
          by: 'created_at',
          dir: 'desc'
        },
        limit: {
          page: 1,
          // This is a workaround to make sure that we have the desired exam
          // on the list - can be problematic in future when total exams amount
          // will reach this limit
          take: 9999
        }
      })}`
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  const handleAuth = async () => {
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.token)
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)

    try {
      const result = await authStudent({ token })
      LocalStorageService.set(LOCAL_STORAGE_KEYS.token, result.data.token)
      handleFetchAndRedirectExams()
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    handleAuth()
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.preview')}</title>
      </Helmet>
      <AuthContainer>
        <div className='title'>
          <Heading1>{t('preview.title')}...</Heading1>
        </div>
      </AuthContainer>
    </React.Fragment>
  )
}

const AuthContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

export default PreviewExam

import { Action, NotificationReducer } from 'types'
import { API_STATES } from 'utils/redux'
import { fetchNotificationsRoutine } from 'modules/notifications/ducks/actions'

export const initialState: NotificationReducer = {
  state: API_STATES.PRISTINE,
  notifications: []
}

export default (state = initialState, action: Action): NotificationReducer => {
  switch (action.type) {
    case fetchNotificationsRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchNotificationsRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchNotificationsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        notifications: action.payload.data
      }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import { useHistory } from 'react-router-dom'

import { pathOr, pipe } from 'ramda'
import { useSelector } from 'react-redux'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import PATHS from 'utils/paths'

export const FinishExam = (): JSX.Element => {
  const [firstLoadOcuured, setFirstLoadOccured] = useState(false)
  const history = useHistory()

  const examDetails = useSelector(getExamDetails)

  const redirectToExamsList = () => history.push(PATHS.exams)

  const hasExamDetails = pipe(
    pathOr(null, ['exam', 'id']),
    isNotNilOrEmpty
  )(examDetails)
  const hasGoogleFormUrl = pipe(
    pathOr(null, ['exam', 'originalExam', 'google_form_url']),
    isNotNilOrEmpty
  )(examDetails)

  const googleFormUrl = pathOr('', ['exam', 'originalExam', 'google_form_url'])(
    examDetails
  )

  const handleOnLoad = () => {
    if (firstLoadOcuured) {
      redirectToExamsList()
    }
    setFirstLoadOccured(true)
  }

  const handleOpenInNewTab = () => {
    setTimeout(redirectToExamsList, 10000)
    window.open(googleFormUrl, '_blank')
  }

  useEffect(() => {
    if (hasExamDetails && !hasGoogleFormUrl) {
      redirectToExamsList()
    }
  }, [examDetails])

  return (
    <ExamContent>
      <AdBlockInfo>
        If this form is not visible to you, please click&nbsp;
        <span className='link' onClick={handleOpenInNewTab}>
          <b>here</b>
        </span>
        &nbsp;to open it in new tab
      </AdBlockInfo>
      <FormContainer>
        <iframe
          src={googleFormUrl}
          width='640'
          height='3663'
          frameBorder='0'
          // @ts-ignore
          marginHeight='0'
          // @ts-ignore
          marginWidth='0'
          // eslint-disable-next-line react/no-unknown-property
          onLoad={handleOnLoad}
        >
          Loading…
        </iframe>
      </FormContainer>
    </ExamContent>
  )
}

export default FinishExam

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`

const AdBlockInfo = styled.div`
  display: flex;
  justify-content: center;

  .link {
    text-decoration: underline !important;
    font-weight: bold;
    max-width: 640px;
    cursor: pointer;
  }
`

import React from 'react'
import {
  NavArrowDownIcon,
  NavArrowRightIcon,
  NavArrowUpIcon
} from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SaltyFlashcardPileAnimation from '../../flashcardsStudy/components/SaltyFlashcardPileAnimation/SaltyFlashcardPileAnimation'
import { useSelector } from 'react-redux'
import { getCourseFlashcardsCount } from '../../courses/ducks/selectors'

const FlashcardsHotkeysLegend = () => {
  const { t } = useTranslation()
  const courseFlashcardsCount = useSelector(getCourseFlashcardsCount)

  return (
    <HotkeysLegend>
      <div>
        <HotkeysLegendTitle>
          {t('flashcards.study.legend.title')}
        </HotkeysLegendTitle>
        <HotkeysLegendOption>
          <HotkeysLegendKey>
            <NavArrowRightIcon />
          </HotkeysLegendKey>
          {t('flashcards.study.legend.nextQuestion')}
        </HotkeysLegendOption>
        <HotkeysLegendOption>
          <HotkeysLegendKey>
            <NavArrowUpIcon />
          </HotkeysLegendKey>
          {t('flashcards.study.legend.correctAnswer')}
        </HotkeysLegendOption>
        <HotkeysLegendOption>
          <HotkeysLegendKey>
            <NavArrowDownIcon />
          </HotkeysLegendKey>
          {t('flashcards.study.legend.incorrectAnswer')}
        </HotkeysLegendOption>
        <HotkeysLegendOption>
          <HotkeysLegendKey paddingForWord>Space</HotkeysLegendKey>
          {t('flashcards.study.legend.flipFlashcard')}
        </HotkeysLegendOption>
      </div>
      <SaltyFlashcardPileAnimation cardsNumber={courseFlashcardsCount} />
    </HotkeysLegend>
  )
}

export default FlashcardsHotkeysLegend

const HotkeysLegend = styled.div`
  border-radius: 8px;
  min-width: 244px;
  max-width: 244px;
  height: 572px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  padding: 24px 32px;
  font-size: ${({ theme }) => theme.typography.fontSizeExtraSmall};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const HotkeysLegendTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
`

const HotkeysLegendOption = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.main.grey600};
  margin-top: 16px;
`

const HotkeysLegendKey = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.main.grey600};
  padding: ${({ paddingForWord }) => (paddingForWord ? '8px 14px' : '8px')};
  margin-right: 15px;
  border-radius: 5px;
  min-width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.main.text};
  font-size: ${({ theme }) => theme.typography.inputSmall};
  background: ${({ theme }) => theme.colors.flashcards.hotkeysLegend};
`

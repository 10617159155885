import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { EK_PURCHASE_PORTAL_PAGE } from 'utils/links'
import PATHS from 'utils/paths'

import { Button, Heading2, Text } from 'examkrackers-components'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { useDispatch } from 'react-redux'
import { removeCurrentCourseRoutine } from 'modules/courses/ducks/actions'

const ModalExpiredCourse = (): JSX.Element => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const dispatch = useDispatch()

  const handleRemoveCurrentCourse = useCallback(
    () => dispatch(removeCurrentCourseRoutine()),
    [dispatch]
  )

  const openPurchasePortal = () => {
    window.open(EK_PURCHASE_PORTAL_PAGE, '_blank')
  }

  const handleCancel = () => {
    push(PATHS.selectCourse)
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)
    handleRemoveCurrentCourse()
  }

  return (
    <Overlay>
      <Container>
        <Heading2 bold>{t('courses.expiredModal.title')}</Heading2>
        <br />
        <Text>{t('courses.expiredModal.description')}</Text>
        <br />
        <br />
        <ButtonsContainer>
          <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={openPurchasePortal}
          >
            {t('courses.expiredModal.submit')}
          </Button>
          <Button
            variant='contained'
            size='small'
            color='tertiary'
            onClick={handleCancel}
          >
            {t('courses.expiredModal.cancel')}
          </Button>
        </ButtonsContainer>
      </Container>
    </Overlay>
  )
}

export default ModalExpiredCourse

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;

  button {
    min-width: 159px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background: ${({ theme }) => theme.colors.modal.overlay};
  transition: opacity 400ms ${({ theme }) => theme.transitions.easing.easeInOut};
  z-index: ${({ theme }) => theme.zIndex.mainMenu + 10};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  max-width: 505px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.mainShadow};
  padding: 48px 84px;
  text-align: center;
`

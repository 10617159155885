const PAGE_IDS = {
  start: 'start-page',
  confirmId: 'confirm-id-page',
  certification: 'certification-page',
  certificationDecline: 'certification-decline-page',
  tutorialStart: 'tutorial-start-page',
  tutorialNavigation: 'tutorial-navigation',
  tutorialAnswer: 'tutorial-answer',
  tutorialScroll: 'tutorial-scroll',
  tutorialHighlight: 'tutorial-highlight',
  tutorialStrikethrough: 'tutorial-strikethrough',
  tutorialPeriodicTable: 'tutorial-periodic-table',
  tutorialFlagForReview: 'tutorial-flag-for-review',
  tutorialSectionReview: 'tutorial-review',
  tutorialEnd: 'tutorial-end-page',
  breakShort: 'break-short',
  blankPage: 'blank',
  endExam: 'end-exam',
  googleForm: 'google-form'
}

export default PAGE_IDS

import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import debounce from 'lodash.debounce'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'

export const TooltipFlashCardsChart = (): JSX.Element => {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })

  const handleShowTooltip = data => {
    const event: MouseEvent = R.propOr({ x: 0, y: 0 }, 'e', data)
    setIsVisible(true)
    setPosition({ top: event.y, left: event.x })
  }

  const handleHideTooltip = () => {
    setIsVisible(false)
    setPosition({ top: 0, left: 0 })
  }

  const debouncedMouseEnter = debounce(handleShowTooltip)
  const debouncedMouseLeave = debounce(handleHideTooltip)

  useEffect(() => {
    eventEmitter.on(events.plHoverEnter, debouncedMouseEnter)
    eventEmitter.on(events.plHoverLeave, debouncedMouseLeave)

    return () => {
      eventEmitter.off(events.plHoverEnter, debouncedMouseEnter)
      eventEmitter.off(events.plHoverLeave, debouncedMouseLeave)
    }
  }, [])

  return isVisible ? (
    <Container
      style={{ top: `${position.top - 50}px`, left: `${position.left}px` }}
      position={position}
    >
      {t('dashboard.charts.proficiencyLevel')}
    </Container>
  ) : (
    <div />
  )
}

export default TooltipFlashCardsChart

const Container = styled.div`
  position: fixed;
  z-index: 1100;
  padding: 8px;
  border-radius: 8px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  font-size: 11px;
  padding: 10px;
  background: ${({ theme }) =>
    theme.colors.dashboard.speedometer.infoBoxBackground};
  border: 1px solid
    ${({ theme }) => theme.colors.dashboard.speedometer.infoButtonBorder};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Heading2 } from 'examkrackers-components'
import { Category } from 'types/onboarding'
import { ModalCategoryImages } from 'modules/onboarding/components/ModalCategoryImages'
import { storageUrl } from 'utils/onboarding'
import { isNilOrEmpty } from 'utils/ramda'

export const TileCategory = ({
  title,
  description,
  id,
  images,
  image_url
}: Category) => {
  const { t } = useTranslation()
  const url = storageUrl + image_url
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Container>
        <ContentContainer>
          <Content>
            <Heading2 bold>{title}</Heading2>
            <ContentItem>{description}</ContentItem>
          </Content>
          <Button
            variant='contained'
            onClick={handleModalOpen}
            size='small'
            color='tertiary'
            disabled={isNilOrEmpty(images)}
          >
            {t('onboarding.seeOnboarding')}
          </Button>
        </ContentContainer>
        <ImageContainer>
          <img
            alt='category'
            src={image_url ? url : '/assets/illustrations/SaltyBigBrain.svg'}
          />
        </ImageContainer>
      </Container>
      {modalOpen && (
        <ModalCategoryImages
          open={modalOpen}
          onClose={handleModalClose}
          categoryId={id}
          categoryTitle={title}
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 408px;
  height: 200px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border: 1px solid ${({ theme }) => theme.colors.main.grey300};
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * {
    &:last-child {
      max-width: 180px;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ContentItem = styled.span`
  width: 230px;
  max-height: 72px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  line-height: 24px;
  overflow: hidden;
`
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 100px;
  }
`

import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getVideosList } from 'modules/videos/ducks/selectors'
import VideoDetailsListItem from './VideoDetailsListItem'
// import VideoListInfiniteScroller from './VideoListInfiniteScroller'
import * as R from 'ramda'
import qs from 'qs'
import { MixedVideo } from 'types/videos'

interface IParsedQuery extends qs.ParsedQs {
  filter?: {
    search: string
    category: string
    'b.id': string
    'bcc.id': string
    'sfv.id': string
  }
}

export const VideoDetailsList = ({ category }): JSX.Element => {
  const videosList: MixedVideo[] = useSelector(getVideosList)
  const params = useParams()
  const id = R.propOr('', 'id', params)
  const {
    location: { search }
  } = useHistory()
  const parsedQuery: IParsedQuery = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  const { filter } = parsedQuery
  const bookId = filter?.['b.id'] || ''
  const queryCategory = filter?.category || ''

  const renderVideos = videosList
    .filter(video =>
      queryCategory === 'favourites'
        ? video.is_favourite === true
        : video.category === category
    )
    .filter(video => video.id !== id)
    .filter(video =>
      queryCategory === 'favourites'
        ? true
        : // @ts-ignore
        video.tags
        ? // @ts-ignore
          video.tags[0].book_id === bookId
        : true
    )
    .map(video => (
      <VideoDetailsListItem key={`video-list-item-${video.id}`} video={video} />
    ))

  return (
    <Container>
      <VideosListContainer>{renderVideos}</VideosListContainer>
      {/* <VideoListInfiniteScroller /> */}
    </Container>
  )
}

export default VideoDetailsList

const Container = styled.div`
  height: 100%;
`

const VideosListContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`

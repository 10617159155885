import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import PATHS from 'utils/paths'
import { useSelector } from 'react-redux'
import { getAccountType, getStudent } from '../../../auth/ducks/selectors'
import { getExamDetails } from '../../ducks/selectors'
import { pathOr } from 'ramda'
import AnalyticsService from '../../../../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../../../../utils/analytics'
import { DATE_FORMATS, formatDate } from '../../../../utils/date'

export const CertificationDecline = (props: any): JSX.Element => {
  const { setCurrentPage, setNavigationHandlers } = props
  const history = useHistory()
  const { t } = useTranslation()
  const user = useSelector(getStudent)

  const accountType: string = useSelector(getAccountType)
  const examDetails = useSelector(getExamDetails)
  const type_title = pathOr('', ['type', 'title'], examDetails)
  const title = pathOr('', ['exam', 'title'], examDetails)

  const redirectToExamsList = () => {
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.examEnded, {
      Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
      'Exam Title': title,
      'Exam format': type_title,
      'Account type': accountType
    })
    history.push(PATHS.exams)
  }
  const handlePrevious = () => {
    setCurrentPage(PageIds.certification)
  }

  React.useEffect(() => {
    setNavigationHandlers((prevState: any) => ({
      ...prevState,
      endTestDayCertificationOnClick: redirectToExamsList,
      previousButtonOnClick: handlePrevious
    }))
  }, [])

  return (
    <ExamContent>
      <p>{t('exam.forfeit.paragraph1')}</p>
      <p>{t('exam.forfeit.paragraph2')}</p>
      <Warning>{t('exam.forfeit.warning')}</Warning>
    </ExamContent>
  )
}

const Warning = styled.p`
  color: red;
  display: block;
  white-space: pre;
  padding: 4em 8em;
  font-size: 14px;
`

export default CertificationDecline

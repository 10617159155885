import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

import {
  Loader,
  CheckmarkContainedIcon,
  CloseContainedIcon
} from 'examkrackers-components'

export const NotesLoadingState = (): JSX.Element => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [savedSuccess, setSavedSuccess] = useState(false)
  const [savedFail, setSavedFail] = useState(false)

  const saveIsLoading = bool => setIsLoading(bool)
  const saveIsSuccess = bool => setSavedSuccess(bool)
  const saveIsFail = bool => setSavedFail(bool)

  useEffect(() => {
    eventEmitter.on(events.notesLoading, saveIsLoading)
    eventEmitter.on(events.notesSavedSuccess, saveIsSuccess)
    eventEmitter.on(events.notesSavedFailure, saveIsFail)

    return () => {
      eventEmitter.on(events.notesLoading, saveIsLoading)
      eventEmitter.on(events.notesSavedSuccess, saveIsSuccess)
      eventEmitter.on(events.notesSavedFailure, saveIsFail)
    }
  }, [])

  const loadingSuccess = (
    <>
      <StyledSuccessIcon />
      <span>{t('bookViewer.notes.saved')}</span>
    </>
  )
  const loadingFailed = (
    <>
      <StyledErrorIcon />
      <span>{t('bookViewer.notes.saveFailed')}</span>
    </>
  )
  const loadingState = (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  )

  const callbackMessage = () => {
    switch (true) {
      case isLoading:
        return loadingState
      case savedSuccess:
        return loadingSuccess
      case savedFail:
        return loadingFailed
      default:
        return ''
    }
  }

  return <Container>{callbackMessage()}</Container>
}

export default NotesLoadingState

const LoaderContainer = styled.div`
  margin-right: 8px;
`

const Container = styled.div`
  height: 20px;
  position: absolute;
  right: 8px;
  top: 42px;
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.main.grey600};
  z-index: ${({ theme }) => theme.zIndex.navigation + 1};
`

const StyledSuccessIcon = styled(CheckmarkContainedIcon)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.success500};
`

const StyledErrorIcon = styled(CloseContainedIcon)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.error500};
`

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { catchSkippedAnimationTrigger } from 'utils/flashcards'
import { propOr } from 'ramda'
import { formatDate, DATE_FORMATS } from 'utils/date'

const defaultAnimationDimensions = {
  running: false,
  timestamp: '',
  top: 0,
  left: 0
}

const FlashcardsSkippedAnimation = () => {
  const [animationDimensions, setAnimationDimensions] = useState(
    defaultAnimationDimensions
  )
  const isRunning = propOr(false, 'running', animationDimensions)

  const startAnimation = dimensions => {
    setAnimationDimensions({
      ...dimensions,
      running: true,
      timestamp: formatDate(new Date(), DATE_FORMATS.dashedDateAndTime)
    })
  }

  const stopAnimation = () => setAnimationDimensions(defaultAnimationDimensions)

  useEffect(() => {
    catchSkippedAnimationTrigger(startAnimation)
  }, [])

  return (
    <>
      {isRunning && (
        <AnimatedSkippedPage
          id='animated-skipped-page'
          animationDimensions={animationDimensions}
          onAnimationEnd={stopAnimation}
        />
      )}
    </>
  )
}

export default FlashcardsSkippedAnimation

const AnimatedSkippedPage = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.font};
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  top: ${({ animationDimensions }) => animationDimensions.top}px;
  left: ${({ animationDimensions }) => animationDimensions.left}px;
  animation-name: move-page-to-skipped-${({ animationDimensions }) => animationDimensions.timestamp};
  animation-duration: 2s;
  animation-delay: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;

  @keyframes move-page-to-skipped-${({ animationDimensions }) =>
    animationDimensions.timestamp} {
    0% {
      opacity: 1;
    }

    20% {
      transform: translateY(-15px);
      z-index: 3;
    }

    40% {
      transform: translate(50px, -15px);
      z-index: 10;
    }
    60% {
      transform: translate(68px, -15px);
      z-index: 3;
    }
    80% {
      transform: translate(70px, 0px);
      z-index: 3;
    }

    100% {
      transform: translate(68px, 10px);
      z-index: 3;
      opacity: 1;
    }
  }
`

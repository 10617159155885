import { createRoutine } from 'redux-saga-routines'
// import * as R from 'ramda'

import * as Effects from 'utils/saga'

import * as CalendarService from 'services/CalendarService'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

export const getAamcEventsColorsRoutine = createRoutine(
  'GET_AAMC_EVENTS_COLORS'
)

export const getManualCalendarTasksRoutine = createRoutine(
  'GET_MANUAL_CALENDAR_TASKS_ROUTINE'
)

export const setManualCalendarTasksRoutine = createRoutine(
  'SET_MANUAL_CALENDAR_TASKS_ROUTINE'
)

export const updateManualCalendarTasksRoutine = createRoutine(
  'UPDATE_MANUAL_CALENDAR_TASKS_ROUTINE'
)

export const addManualCalendarTaskRoutine = createRoutine(
  'ADD_MANUAL_CALENDAR_TASK_ROUTINE'
)

export const removeManualCalendarTaskRoutine = createRoutine(
  'REMOVE_MANUAL_CALENDAR_TASK_ROUTINE'
)

export const setManualCalendarActiveTabRoutine = createRoutine(
  'SET_MANUAL_CALENDAR_ACTIVE_TAB_ROUTINE'
)

export const clearManualCalendarTasksRoutine = createRoutine(
  'CLEAR_MANUAL_CALENDAR_TASKS_ROUTINE'
)

export const setVisibleDatesRoutine = createRoutine('SET_VISIBLE_DATES')

export function* fetchAamcEventsColors() {
  const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.originalCourseId)

  yield Effects.put(getAamcEventsColorsRoutine.request())
  try {
    // Call the CalendarService to get the AAMC events colors
    const result = yield Effects.call(CalendarService.getAamcEventsColors, {
      id: courseId
    })
    // Dispatch a success action with the retrieved colors
    yield Effects.put(getAamcEventsColorsRoutine.success(result.data.data))
  } catch (e) {
    // Dispatch a failure action with the error message
    yield Effects.put(getAamcEventsColorsRoutine.failure(e))
    console.error(e)
  }
}

export function* getManualCalendarTasks({ payload }) {
  const { rebuild } = payload
  yield Effects.put(getManualCalendarTasksRoutine.request())
  try {
    const result = yield Effects.call(CalendarService.getManualCalendarTasks, {
      rebuild
    })
    yield Effects.put(
      getManualCalendarTasksRoutine.success(
        result.data?.calendar_events?.data || []
      )
    )
  } catch (e) {
    yield Effects.put(getManualCalendarTasksRoutine.failure(e))
    console.error(e)
  }
}

export function* setManualCalendarTasks({ payload }) {
  const { list } = payload
  yield Effects.put(setManualCalendarTasksRoutine.request())
  try {
    yield Effects.put(setManualCalendarTasksRoutine.success(list))
  } catch (e) {
    yield Effects.put(setManualCalendarTasksRoutine.failure(e))
    console.error(e)
  }
}

export function* updateManualCalendarTasks({ payload }) {
  yield Effects.put(updateManualCalendarTasksRoutine.request())
  try {
    yield Effects.put(updateManualCalendarTasksRoutine.success(payload))
  } catch (e) {
    yield Effects.put(updateManualCalendarTasksRoutine.failure(e))
    console.error(e)
  }
}

export function* addManualCalendarTask({ payload }) {
  const { event } = payload
  yield Effects.put(addManualCalendarTaskRoutine.request())
  try {
    yield Effects.put(addManualCalendarTaskRoutine.success(event))
  } catch (e) {
    yield Effects.put(addManualCalendarTaskRoutine.failure(e))
    console.error(e)
  }
}

export function* removeManualCalendarTask({ payload }) {
  yield Effects.put(removeManualCalendarTaskRoutine.request())
  try {
    yield Effects.put(removeManualCalendarTaskRoutine.success(payload))
  } catch (e) {
    yield Effects.put(removeManualCalendarTaskRoutine.failure(e))
    console.error(e)
  }
}

export function* clearManualCalendarTasks({ payload }) {
  const { list } = payload
  yield Effects.put(clearManualCalendarTasksRoutine.request())
  try {
    yield Effects.put(clearManualCalendarTasksRoutine.success(list))
  } catch (e) {
    yield Effects.put(clearManualCalendarTasksRoutine.failure(e))
    console.error(e)
  }
}

export function* setManualCalendarActiveTab({ payload }) {
  const { activeTab } = payload
  yield Effects.put(setManualCalendarActiveTabRoutine.request())
  try {
    yield Effects.put(setManualCalendarActiveTabRoutine.success(activeTab))
  } catch (e) {
    yield Effects.put(setManualCalendarActiveTabRoutine.failure(e))
    console.error(e)
  }
}

export function* setVisibleDates({ payload }) {
  yield Effects.put(setVisibleDatesRoutine.request(payload))
}

export function* fetchAamcEventsColorsWatcher() {
  yield Effects.takeLatest(getAamcEventsColorsRoutine, fetchAamcEventsColors)
}

export function* getManualCalendarTasksWatcher() {
  yield Effects.takeEvery(getManualCalendarTasksRoutine, getManualCalendarTasks)
}

export function* setManualCalendarTasksWatcher() {
  yield Effects.takeLatest(
    setManualCalendarTasksRoutine,
    setManualCalendarTasks
  )
}

export function* addManualCalendarTaskWatcher() {
  yield Effects.takeLatest(addManualCalendarTaskRoutine, addManualCalendarTask)
}

export function* removeManualCalendarTaskWatcher() {
  yield Effects.takeLatest(
    removeManualCalendarTaskRoutine,
    removeManualCalendarTask
  )
}

export function* clearManualCalendarTasksWatcher() {
  yield Effects.takeLatest(
    clearManualCalendarTasksRoutine,
    clearManualCalendarTasks
  )
}

export function* setVisibleDatesWatcher() {
  yield Effects.takeLatest(setVisibleDatesRoutine, setVisibleDates)
}

export function* setManualCalendarActiveTabWatcher() {
  yield Effects.takeLatest(
    setManualCalendarActiveTabRoutine,
    setManualCalendarActiveTab
  )
}

export function* updateManualCalendarTasksWatcher() {
  yield Effects.takeLatest(
    updateManualCalendarTasksRoutine,
    updateManualCalendarTasks
  )
}

export const calendarSagas = [
  Effects.fork(fetchAamcEventsColorsWatcher),
  Effects.fork(getManualCalendarTasksWatcher),
  Effects.fork(setManualCalendarTasksWatcher),
  Effects.fork(addManualCalendarTaskWatcher),
  Effects.fork(removeManualCalendarTaskWatcher),
  Effects.fork(clearManualCalendarTasksWatcher),
  Effects.fork(setVisibleDatesWatcher),
  Effects.fork(setManualCalendarActiveTabWatcher),
  Effects.fork(updateManualCalendarTasksWatcher)
]

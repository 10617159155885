import { string, object } from 'yup'
import { validateValues, validateField } from 'utils/form'

export const createCustomBoxSchema = object().shape({
  title: string()
    .required(() => ({ key: 'form.customBox.errors.required' }))
    .max(10, () => ({
      key: 'form.customBox.errors.maxChars',
      options: { number: 10 }
    }))
})

export const validateCreateCustomBoxField = values =>
  validateField(createCustomBoxSchema, values)
export const validateCreateCustomBoxValues = validateValues(
  createCustomBoxSchema
)

import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toggleQuestionFlag } from 'services/ExamsService'
import { FlagIcon, FlagOutlinedIcon } from 'examkrackers-components'
import { fetchQuestionsGraphDataRoutine } from '../ducks/actions'

interface ExamTopComponentsProps {
  isFlagged: boolean
  questionId: string
  sectionId: string
}

export const ExamTopComponents = ({
  isFlagged,
  questionId,
  sectionId
}: ExamTopComponentsProps): JSX.Element => {
  // Get React-Redux dispatcher
  const dispatch = useDispatch()

  // Indicator for whether the question is flagged or not
  const [flagged, setFlagged] = React.useState(isFlagged)

  // Update flag if comes with a delay
  React.useEffect(() => {
    setFlagged(isFlagged)
  }, [isFlagged])

  const fetchQuestionsGraphData = React.useCallback(
    () =>
      dispatch(fetchQuestionsGraphDataRoutine({ id: sectionId, silent: true })),
    [dispatch, sectionId]
  )

  const handleFlagClick = async () => {
    const handleSuccess = () => {
      // Toggle the flag optimistically
      setFlagged(!flagged)
      fetchQuestionsGraphData()
    }
    const handleError = (e: Error) => console.error(e)
    toggleQuestionFlag({ id: questionId })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <StyledFlagIcon onClick={handleFlagClick}>
      {flagged ? <FlagIcon /> : <FlagOutlinedIcon />}
    </StyledFlagIcon>
  )
}

const StyledFlagIcon = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
`

export default React.memo(ExamTopComponents)

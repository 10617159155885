import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { TileCategory } from 'modules/onboarding/components/TileCategory'
import { selectOnboardingCategories } from 'modules/onboarding/ducks/selectors'
import { fetchOnboardingCategoriesRoutine } from 'modules/onboarding/ducks/actions'
import { Category } from 'types/onboarding'

export const ListCategories = () => {
  const dispatch = useDispatch()
  const categories = useSelector(selectOnboardingCategories)

  useEffect(() => {
    dispatch(fetchOnboardingCategoriesRoutine())
  }, [])

  return (
    <Container>
      {categories.map((category: Category) => (
        <TileCategory key={category.id} {...category} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

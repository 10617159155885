import APIService from 'services/APIService'
import { FetchNotificationsPayload } from 'types/notifications'

const api = APIService()

export const fetchNotifications = (payload: FetchNotificationsPayload) => {
  return api.get(
    `student-notifications/student/${payload.studentId}?limit[page]=1&limit[take]=1000`
  )
}

export const markNotificationAsRead = (notificationId: string) => {
  return api.post(`student-notifications/${notificationId}/read`)
}

export const markNotificationAsUnRead = (notificationId: string) => {
  return api.post(`student-notifications/${notificationId}/unread`)
}

export const markAllNotificationsAsRead = () => {
  return api.post(`student-notifications/all/read`)
}

export const toggleNotificationLFlag = (notificationId: string) => {
  return api.patch(`student-notifications/${notificationId}/flag`)
}

export const getNotificationsCount = () => {
  return api.get(`student-notifications/unseen-count`)
}

export const markAllNotificationsAsSeen = () => {
  return api.post(`student-notifications/all/see`)
}

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import {
  PadlockLockedIcon,
  PadlockOpenIcon,
  IconButton
} from 'examkrackers-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { saveRightColumnState } from 'services/BooksService'
import { getBookByOriginalId } from 'modules/books/ducks/selectors'
import * as R from 'ramda'
import { isNilOrEmpty } from 'utils/ramda'
import { refreshBookFragmentDataRoutine } from '../ducks/actions'
import { BOOK_VIEWER_PARAMS } from 'utils/books'

import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { useHistory, useParams } from 'react-router-dom'

const Padlock = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const params = useParams()
  const bookId: string = R.propOr('', 'bookId', params)
  const partOrder: string = R.propOr('', 'partOrder', params)
  const chapterOrder: string = R.propOr('', 'chapterOrder', params)
  const handleBookFetching = useCallback(
    state => getBookByOriginalId(state, bookId),
    [bookId]
  )
  const book = useSelector(handleBookFetching)
  const previewState = R.propOr('', 'preview_state', book)
  const rightColumnLocked = isNilOrEmpty(previewState)
  const {
    location: { search }
  } = useHistory()

  const studentBookId = R.propOr('', 'id', book)
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const fetchTotalPartData = React.useCallback(
    (bookId: string, chapterOrder: string, partOrder: string) =>
      dispatch(
        refreshBookFragmentDataRoutine({
          params: { bookId, chapterOrder, partOrder },
          fragmentName: 'book'
        })
      ),
    [dispatch]
  )

  const handleSuccess = () => {
    fetchTotalPartData(bookId, chapterOrder, partOrder)
    setIsPadlockLockedState(locked => !locked)
  }

  const handleError = () => {
    showToast({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    })
  }

  const rightColumnParams = R.pick(
    [
      BOOK_VIEWER_PARAMS.rightTopActive,
      BOOK_VIEWER_PARAMS.rightBottomActive,
      BOOK_VIEWER_PARAMS.rightTopExpand,
      BOOK_VIEWER_PARAMS.rightBottomExpand
    ],
    parsedQuery
  )

  const payload = rightColumnLocked
    ? {
        studentBookId,
        preview_state: qs.stringify(rightColumnParams)
      }
    : {
        studentBookId,
        preview_state: ''
      }

  const [isPadlockLockedState, setIsPadlockLockedState] = useState(
    isNilOrEmpty(previewState)
  )

  const handlePadlockLocked = () => {
    saveRightColumnState(payload).then(handleSuccess).catch(handleError)
  }

  const isBottomExpanded = R.pipe(
    R.propOr('false', BOOK_VIEWER_PARAMS.rightBottomExpand),
    R.equals('true')
  )(parsedQuery)

  // useEffect just to confirm the state after the API response
  useEffect(() => {
    if (rightColumnLocked === true) {
      setIsPadlockLockedState(true)
    } else {
      setIsPadlockLockedState(false)
    }
  }, [rightColumnLocked])

  return (
    <PadlockWrapper bottomExpanded={isBottomExpanded}>
      <IconButton
        tooltip={t('books.padlock')}
        tooltipId='padlock-tooltip'
        icon={
          isPadlockLockedState ? <PadlockOpenIcon /> : <PadlockLockedIcon />
        }
        size='medium'
        onClick={handlePadlockLocked}
      />
    </PadlockWrapper>
  )
}

export default Padlock

const PadlockWrapper = styled.div`
  position: absolute;
  top: -30px;
  right: ${({ bottomExpanded }) => (bottomExpanded ? '164px;' : '-6px')};
  transition: all 300ms;
  button {
    font-size: 20px;
    svg {
      fill: ${({ theme }) => theme.colors.buttons.transparent.secondary.font};
    }
  }
`

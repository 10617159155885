import React from 'react'
import { useFreeTrialTooltips } from '../hooks/useFreeTrialTooltips'

// The purpose of this component is to reduce the number
// of re-renders for each change of "currentCourse" in redux store.
//   Instead of calling the hook directly in the component with
//   the chart, it was separated into a separate component, so
// that a change in "currentCourse" does not redraw the chart

const FreeTrialChartTooltipProvider = ({ id, data }): JSX.Element => {
  useFreeTrialTooltips(id, data)

  return <div />
}

export default FreeTrialChartTooltipProvider

import { Action, CoursesReducer } from 'types'
import { API_STATES } from 'utils/redux'
import {
  fetchCoursesListRoutine,
  fetchCurrentCourseRoutine,
  removeCurrentCourseRoutine
} from 'modules/courses/ducks/actions'

export const initialState: CoursesReducer = {
  state: API_STATES.PRISTINE,
  courses: [],
  currentCourse: {
    accessible_from: '',
    accessible_to: '',
    book_course_id: '',
    completed_at: '',
    external_created_at: '',
    id: '',
    flashcard_count: 0,
    flashcard_snapshot: null,
    book_order: null,
    is_ready: false,
    metadata: '',
    status: '',
    student_id: '',
    title: '',
    type: ''
  }
}

// Exams Reducer
export default (state = initialState, action: Action): CoursesReducer => {
  switch (action.type) {
    case fetchCoursesListRoutine.REQUEST:
    case fetchCurrentCourseRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchCoursesListRoutine.FAILURE:
    case fetchCurrentCourseRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchCoursesListRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        courses: action.payload.data
      }
    case fetchCurrentCourseRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        currentCourse: action.payload.data
      }
    case removeCurrentCourseRoutine.SUCCESS:
      return {
        ...state,
        currentCourse: {
          accessible_from: '',
          accessible_to: '',
          book_course_id: '',
          completed_at: '',
          external_created_at: '',
          id: '',
          flashcard_count: 0,
          is_ready: false,
          metadata: '',
          status: '',
          student_id: '',
          title: '',
          type: '',
          flashcard_snapshot: null,
          book_order: null
        }
      }
    default:
      return state
  }
}

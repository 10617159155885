import { getTheme } from 'examkrackers-components'
import * as R from 'ramda'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/diagnostics/utils/events'

export const EXAM_SECTION_DIFFICULTY_CHART_SERIES = {
  difficulty: 'Question Difficulty (%)',
  placeholder: 'placeholder'
}

export const getExamSectionDifficultySeries = series => [
  {
    name: EXAM_SECTION_DIFFICULTY_CHART_SERIES.difficulty,
    type: 'bar',
    data: series.difficulty || []
  },
  // this is a placeholder to have the same chart as time chart
  // which is mixed - I need it to have x axis in the same dimensions
  {
    name: EXAM_SECTION_DIFFICULTY_CHART_SERIES.placeholder,
    type: 'line',
    data: []
  }
]

const getBarColor =
  ({ checkIsSkipped, checkIsCorrect }) =>
  ({ dataPointIndex }) => {
    const theme = getTheme()

    const isCorrect = checkIsCorrect(dataPointIndex + 1)
    const isSkipped = checkIsSkipped(dataPointIndex + 1)

    switch (true) {
      case isCorrect:
        return theme.colors.diagnostics.question.correct
      case isSkipped:
        return theme.colors.diagnostics.question.skipped
      default:
        return theme.colors.diagnostics.question.incorrect
    }
  }

export const ExamSectionDifficultyChart = data => {
  const theme = getTheme()
  const { categories, checkIsSkipped, checkIsCorrect } = data

  return {
    colors: [getBarColor({ checkIsSkipped, checkIsCorrect }), 'transparent'],
    stroke: {
      curve: ['straight', 'straight'],
      dashArray: [0, 0],
      width: 0
    },
    subtitle: {
      margin: 0
    },
    title: {
      margin: 0
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      borderColor: theme.colors.main.grey300,
      position: 'back',
      yaxis: {
        lines: {
          show: true
        }
      },
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      show: false,
      itemMargin: {
        horizontal: 0,
        vertical: 0
      }
    },
    chart: {
      offsetY: 0,
      parentHeightOffset: 0,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      },
      events: {
        click: (event, chartContext, config) => {
          const dataPointIndex = R.propOr(-1, 'dataPointIndex', config)

          // @ts-ignore
          if (dataPointIndex >= 0) {
            eventEmitter.emit(events.questionClick, Number(dataPointIndex) + 1)
          }
        },
        mouseMove: (event, chartContext, config) => {
          const dataPointIndex = R.propOr(-1, 'dataPointIndex', config)

          // @ts-ignore
          if (dataPointIndex >= 0) {
            eventEmitter.emit(events.questionHover, Number(dataPointIndex) + 1)
          }
        },
        updated: () => eventEmitter.emit(events.chartUpdated)
      }
    },
    xaxis: {
      title: {
        text: 'Questions',
        offsetX: 0,
        offsetY: -10,
        style: {
          fontSize: '11px',
          color: theme.colors.main.text
        }
      },
      tickPlacement: 'on',
      overwriteCategories: categories,
      labels: {
        hideOverlappingLabels: false,
        rotate: 0,
        style: {
          fontSize: '14px'
        }
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: [
      {
        forceNiceScale: false,
        showForNullSeries: true,
        showAlways: true,
        decimalsInFloat: 0,
        seriesName: EXAM_SECTION_DIFFICULTY_CHART_SERIES.difficulty,
        max: 100,
        tickAmount: 2,
        show: true,
        labels: {
          offsetY: 5,
          style: {
            fontSize: '11px',
            color: theme.colors.main.text
          }
        },
        title: {
          offsetX: -5,
          text: 'Question Difficulty (%)',
          style: {
            color: theme.colors.main.text,
            fontSize: '11px'
          }
        }
      },
      {
        forceNiceScale: false,
        showForNullSeries: true,
        showAlways: true,
        decimalsInFloat: 0,
        seriesName: EXAM_SECTION_DIFFICULTY_CHART_SERIES.placeholder,
        max: 100,
        tickAmount: 2,
        show: true,
        opposite: true,
        labels: {
          style: {
            colors: ['transparent'],
            fontSize: '11px',
            color: theme.colors.diagnostics.diagnostic.passageWorking.font
          }
        },
        title: {
          text: 'Placeholder',
          style: {
            color: 'transparent',
            fontSize: '11px'
          }
        }
      }
    ],
    tooltip: {
      followCursor: true,
      custom: () =>
        '<div class="tooltip-question-view">Click line to view question</div>',
      marker: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: 15,
        borderRadius: 4
      }
    }
  }
}

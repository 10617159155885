import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as R from 'ramda'
import qs from 'qs'
import debounce from 'lodash.debounce'
import { propOr } from 'ramda'

import styled from 'styled-components'

import {
  saveBookContentPositionsRoutine,
  saveScrollAnchorPositionRoutine
} from 'modules/books/ducks/actions'
import {
  selectScrollAnchorPositionY,
  selectBookContentsPositions,
  getBookContentsWithImages,
  getBookContentImages,
  getOrderedBookContentsList,
  getBookByOriginalId
} from 'modules/books/ducks/selectors'

import { BookContent } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'
import LocalStorageService from 'services/LocalStorageService'
import { TABS } from 'modules/books/components/BookRightColumn'

import { LOCAL_STORAGE_KEYS } from 'utils/storage'

const hasNonEmptyImages = R.pipe(R.propOr([], 'images'), isNotNilOrEmpty)

const hasCurrentBookContentImagesAttached = R.pipe(R.last, hasNonEmptyImages)

const takeFirstImageId = R.pipe(
  R.last,
  R.propOr([], 'images'),
  R.head,
  R.propOr('', 'id')
)

const findNearestImage = R.pipe(
  // @ts-ignore
  R.reverse,
  R.find(hasNonEmptyImages),
  R.propOr([], 'images'),
  R.head,
  R.propOr('', 'id')
)

export const ScrollAnchorHandler = (): JSX.Element => {
  const dispatch = useDispatch()
  const { push, location } = useHistory()
  const { search, pathname } = location
  const anchorPositionY = useSelector(selectScrollAnchorPositionY)
  const bookContentsPositions = useSelector(selectBookContentsPositions)
  // @ts-ignore
  const bookContents: BookContent[] = useSelector(getOrderedBookContentsList)
  const bookContentsWithImages = useSelector(getBookContentsWithImages)
  const debug = LocalStorageService.get(LOCAL_STORAGE_KEYS.bookScrollDebug)
  const bookContentImages = useSelector(getBookContentImages)

  const getNearestBookContentImageIndexByContentId = bookContentId => {
    const bookContentIndex = R.findIndex(R.propEq('id', bookContentId))(
      bookContents
    )
    const findNearestImageId = R.pipe(
      R.take(bookContentIndex + 1),
      R.ifElse(
        hasCurrentBookContentImagesAttached,
        takeFirstImageId,
        findNearestImage
      )
    )(bookContentsWithImages)

    return R.findIndex(R.propEq('id', findNearestImageId))(bookContentImages)
  }

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const params = useParams()
  const bookId: string = propOr('', 'bookId', params)
  const book = useSelector(state => getBookByOriginalId(state, bookId))
  const previewState = propOr('', 'preview_state', book)
  const rightColumnLocked = isNotNilOrEmpty(previewState)

  const saveScrollAnchorPosition = useCallback(
    positionY => dispatch(saveScrollAnchorPositionRoutine({ positionY })),
    [dispatch]
  )

  const saveScrollBookContentPositions = useCallback(
    positions => dispatch(saveBookContentPositionsRoutine(positions)),
    [dispatch]
  )

  const saveBookContentsPositions = () => {
    const positions = bookContents.map(bookContent => {
      const bookContentId: string = R.propOr('', 'id')(bookContent)
      const bookContentAnchor = document.getElementById(
        `book-content-anchor-${bookContentId}`
      )
      const dimensionsBookContentAnchor = bookContentAnchor
        ? bookContentAnchor.getBoundingClientRect()
        : {
            height: 0,
            top: 0
          }

      return {
        id: bookContentId,
        height: dimensionsBookContentAnchor.height,
        top: dimensionsBookContentAnchor.top
      }
    })
    saveScrollBookContentPositions(positions)
  }

  const saveAnchorPosition = () => {
    const scrollContainer = document.getElementById('left-container-scroller')
    // @ts-ignore
    const scrollContainerHeight = scrollContainer.clientHeight
    // @ts-ignore
    const dimensionsContainer = scrollContainer.getBoundingClientRect()

    // Minimum Y position of te anchor is the Y position of the scroll container
    const contextAnchorMin = dimensionsContainer.y
    // Set the anchor checkpoint at 5/12 of the scroll container height
    const contextAnchorMax = (scrollContainerHeight * 5) / 12 + contextAnchorMin

    saveScrollAnchorPosition(contextAnchorMax)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(bookContents)) {
      setTimeout(saveBookContentsPositions, 700)
    }
  }, [bookContents])

  const findCurrentContext = () => {
    const matchedBookContent = R.find(
      (bookContentPosition: { top: number; height: number; id: string }) => {
        const { top, height } = bookContentPosition
        const delta = top + height
        return delta > anchorPositionY && top <= anchorPositionY
      }
      // @ts-ignore
    )(bookContentsPositions)
    const matchedBookContentId = R.propOr('', 'id', matchedBookContent)

    const bookContent = R.find(R.propEq('id', matchedBookContentId))(
      bookContents
    )

    return bookContent
  }

  const handleScroll = () => saveBookContentsPositions()
  const debouncedScrollHandler = debounce(handleScroll, 200)

  // somehow this push needs to be done with setTimeout...
  // I'm not sure why it is not working immediately
  const changePath = path => setTimeout(() => push(path))

  React.useEffect(() => {
    saveAnchorPosition()

    window.addEventListener('resize', saveAnchorPosition)
    eventEmitter.on(events.updatePath, changePath)

    return () => {
      window.removeEventListener('resize', saveAnchorPosition)
      eventEmitter.off(events.updatePath, changePath)
    }
  }, [])

  React.useEffect(() => {
    const scrollContainer = document.getElementById('left-container-scroller')

    // @ts-ignore
    scrollContainer.addEventListener('scroll', debouncedScrollHandler)

    return () => {
      // @ts-ignore
      scrollContainer.removeEventListener('scroll', debouncedScrollHandler)
    }
  }, [bookContents])

  React.useEffect(() => {
    const currentContext = findCurrentContext()
    const bookContentId: string = R.propOr('', 'id')(currentContext)
    const bookContentIdFromQuery: string = R.propOr(
      '',
      BOOK_VIEWER_PARAMS.bookContentIdContext
    )(parsedQuery)
    const imageSlideFromQuery: string = R.propOr(
      '1',
      BOOK_VIEWER_PARAMS.imageSlide
    )(parsedQuery)
    const subchapterId: string = R.propOr('', 'subchapter_id')(currentContext)
    const imageIndex = getNearestBookContentImageIndexByContentId(bookContentId)

    const parsedPreviewState = qs.parse(previewState, {
      ignoreQueryPrefix: true
    })

    const shrinkBottomIfImageIsChanged =
      !rightColumnLocked && Number(imageIndex) !== Number(imageSlideFromQuery)
        ? {
            [BOOK_VIEWER_PARAMS.rightBottomExpand]: false,
            [BOOK_VIEWER_PARAMS.rightTopActive]: TABS.images
          }
        : {}

    const setDefaultValuesIfPadlockIsLocked = rightColumnLocked
      ? {
          [BOOK_VIEWER_PARAMS.rightBottomExpand]: propOr(
            false,
            BOOK_VIEWER_PARAMS.rightBottomExpand,
            parsedPreviewState
          ),
          [BOOK_VIEWER_PARAMS.rightTopActive]: propOr(
            false,
            BOOK_VIEWER_PARAMS.rightTopActive,
            parsedPreviewState
          ),
          [BOOK_VIEWER_PARAMS.rightBottomActive]: propOr(
            false,
            BOOK_VIEWER_PARAMS.rightBottomActive,
            parsedPreviewState
          ),
          [BOOK_VIEWER_PARAMS.rightTopExpand]: propOr(
            false,
            BOOK_VIEWER_PARAMS.rightTopExpand,
            parsedPreviewState
          )
        }
      : {}

    if (
      isNotNilOrEmpty(currentContext) &&
      bookContentId !== bookContentIdFromQuery
    ) {
      const newQuery = qs.stringify({
        ...parsedQuery,
        [BOOK_VIEWER_PARAMS.bookContentIdContext]: bookContentId,
        [BOOK_VIEWER_PARAMS.sectionIdContext]: subchapterId,
        [BOOK_VIEWER_PARAMS.imageSlide]:
          imageIndex >= 0 ? imageIndex : imageSlideFromQuery,
        ...shrinkBottomIfImageIsChanged,
        ...setDefaultValuesIfPadlockIsLocked
      })

      // I have no ide why simple push is not working here, so I made
      // this event emitter to handle push outside this useEffect
      eventEmitter.emit(events.updatePath, `${pathname}?${newQuery}`)
    }
  }, [bookContentsPositions, search])

  return <Anchor positionY={anchorPositionY} debug={debug} />
}

export default ScrollAnchorHandler

const Anchor = styled.div`
  display: ${({ debug }) => (debug ? 'inline-block' : 'none')};
  position: fixed;
  width: 1280px;
  height: 5px;
  background: red;
  top: ${({ positionY }) => positionY}px;
  z-index: 5000;
`

import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PATHS from 'utils/paths'

import { isDateBeforeToday } from 'utils/date'
import { SEVERITY } from 'utils/toast'

import { showToastRoutine } from 'modules/toast/ducks/actions'

export const ExamScoreReport = ({ date }): JSX.Element => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    if (isDateBeforeToday(date)) {
      showToast({
        key: 'toast.accessPeriodInvalid',
        severity: SEVERITY.error
      })
      push(PATHS.exams)
    }
  }, [date])

  return <div />
}

export default ExamScoreReport

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'

import { fetchTotalPartDataRoutine } from 'modules/books/ducks/actions'

import { getBookByOriginalId } from 'modules/books/ducks/selectors'

export const BookHeader = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()

  const fetchTotalPartData = React.useCallback(
    (bookId: string, chapterOrder: number, partOrder: number) =>
      dispatch(fetchTotalPartDataRoutine({ bookId, chapterOrder, partOrder })),
    [dispatch]
  )

  const bookId: string = R.propOr('', 'bookId')(params)
  const partOrder: number = R.pipe(R.propOr('', 'partOrder'), Number)(params)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  React.useEffect(() => {
    fetchTotalPartData(bookId, chapterOrder, partOrder)
  }, [bookId, chapterOrder, partOrder])

  const book = useSelector(state => getBookByOriginalId(state, bookId))

  const bookTitle = R.propOr('', 'title', book)

  return (
    <Helmet>
      <title>{t('pages.books.viewer', { title: bookTitle })}</title>
    </Helmet>
  )
}

export default BookHeader

import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Course } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { propOr, pipe } from 'ramda'
import {
  CalendarEventTypes,
  getCalendar,
  getMcatDates
} from 'services/CalendarService'
import {
  addDaysToDate,
  cutTimeAndFormat,
  DATE_FORMATS,
  formatDate,
  getUniversalDateString,
  getOnlyDate
} from 'utils/date'
import {
  createWeeksArray,
  generateCalendarDays,
  generateDateObject,
  sortArchivedEventsByType
} from 'utils/calendar'
import {
  addManualCalendarTaskRoutine,
  getAamcEventsColorsRoutine,
  setManualCalendarTasksRoutine
} from 'modules/calendar/ducks/actions'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { fetchExamsListRoutine } from 'modules/exams/ducks/actions'
// import { intervalToDuration, isAfter } from 'date-fns'

import { selectManualEvents } from 'modules/calendar/ducks/selectors'
import * as R from 'ramda'

import { CalendarFistClass } from 'types/courses'
import { isAfter } from 'date-fns'

type CalendarState = {
  exam_at: string | Date
  start_at: string | Date
  mcat_at: string | Date
  calendarDays: any[]
  calendarDayEvents: {
    [key: string]: CalendarEventTypes
  }
} | null

export interface CalendarContextTypes {
  course: Partial<Course>
  lists?: any
  courseDaysAmount: number
  attachNewEventToDate: (event: CalendarEventTypes) => void
  detachEventFromDate: (event: CalendarEventTypes) => void
  children: ReactNode
  isLoading: boolean
  isConfigMode: boolean
  calendarWeeks: { start: string; end: string; days: any[] }[] | []
  setLists: (payload: any) => void
  setConfigMode: (val: boolean) => void
  updateEvent: ({
    oldEvent,
    newEvent
  }: {
    oldEvent: CalendarEventTypes
    newEvent: CalendarEventTypes
  }) => void
  updateEventStatus: (event: CalendarEventTypes, status: string) => void
  calendar: CalendarState
  mcatDateOptions: { label: string; value: string }[] | []
  archiveEvents: CalendarEventTypes[] | []
  courseEndDate: Date
  firstVisibleSunday: string
  setFirstVisibleSunday: (arg: string) => void
  endDateFirstDay?: CalendarFistClass
  originalCourseId?: string | null
}

const defaultValues = {
  course: {},
  lists: undefined,
  courseDaysAmount: 0,
  attachNewEventToDate: () => {},
  detachEventFromDate: () => {},
  children: <div />,
  isLoading: false,
  isConfigMode: false,
  calendarWeeks: [],
  setLists: () => {},
  setConfigMode: () => {},
  updateEvent: () => {},
  updateEventStatus: () => {},
  mcatDateOptions: [],
  archiveEvents: [],
  calendar: null,
  courseEndDate: new Date(),
  firstVisibleSunday: '',
  setFirstVisibleSunday: () => {},
  originalCourseId: ''
}

export const CalendarContext =
  createContext<CalendarContextTypes>(defaultValues)

export const CalendarContextProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const [lists, setLists] = useState({})
  const currentCourse: Course = useSelector(getCurrentCourse)
  const archiveEvents = useSelector(selectManualEvents)
  const [isLoading, setIsLoading] = useState(true)
  const [configMode, setConfigMode] = useState(false)
  const [calendar, setCalendar] = useState<CalendarState>(null)
  const [calendarWeeks, setCalendarWeeks] = useState<any[]>([])
  const [mcatDateOptions, setMcatDateOptions] = useState<any[]>([])
  const [firstVisibleSunday, setFirstVisibleSunday] = useState('')

  const originalCourseId = LocalStorageService.get(
    LOCAL_STORAGE_KEYS.originalCourseId
  )
  const bookCourseId = currentCourse?.book_course_id || null

  const dispatch = useDispatch()

  // const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const handleCalendarFetch = () => {
    getCalendar()
      .then(resp => {
        const calendarDates = resp.data.calendar
        const events = resp.data.calendar_events.data
        const startDate = cutTimeAndFormat(calendarDates.start_at)
        const endDate = cutTimeAndFormat(calendarDates.exam_at)
        const mcatDate = cutTimeAndFormat(calendarDates.mcat_at)
        const weeks = createWeeksArray(startDate, mcatDate)
        setCalendarWeeks(weeks)

        const archived = events.filter(e => e.status === 'archived')
        const notArchived = events.filter(e => e.status != 'archived')

        dispatch(
          setManualCalendarTasksRoutine({
            list: sortArchivedEventsByType(archived)
          })
        )

        const calendarDayEvents = generateCalendarDays(
          startDate,
          endDate,
          notArchived
        )
        const calendarDays = generateDateObject(startDate, mcatDate)

        setConfigMode(isNilOrEmpty(calendarDates.exam_at))
        setCalendar({
          ...calendarDates,
          calendarDayEvents,
          calendarDays
        })
        setLists(calendarDayEvents)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const daysAmount = currentCourse.metadata
    ? pipe(
        propOr('{}', 'metadata'),
        JSON.parse,
        propOr('0', 'days_amount'),
        Number
      )(currentCourse)
    : 0

  // const daysAmount = isNotNilOrEmpty(currentCourse.accessible_from)
  //   ? intervalToDuration({
  //       start: new Date(currentCourse.accessible_from as string),
  //       end: new Date(currentCourse.accessible_to as string)
  //     }).days
  //   : 0

  const attachNewEventToDate = (
    event: CalendarEventTypes | CalendarEventTypes[]
  ) => {
    if (Array.isArray(event)) {
      event.map(singleEvent => {
        const eventDate = singleEvent.event_date.slice(0, 10)

        return setLists(prev => ({
          ...prev,
          [eventDate]: [...prev[eventDate], singleEvent]
        }))
      })
    } else {
      const eventDate = event.event_date.slice(0, 10)

      setLists(prev => ({
        ...prev,
        [eventDate]: [...prev[eventDate], event]
      }))
    }
  }

  const detachEventFromDate = (event: CalendarEventTypes) => {
    const dateWithoutTime = event.event_date.match(getOnlyDate)
    const extractedDate = dateWithoutTime ? dateWithoutTime[0] : ''

    setLists(prev => {
      const day = prev[extractedDate]
      return {
        ...prev,
        [extractedDate]: day.filter(e => e.id !== event.id)
      }
    })
  }

  const updateEvent = ({ oldEvent, newEvent }) => {
    const existingEventDate = getUniversalDateString(oldEvent.event_date)
    const newEventDate = getUniversalDateString(newEvent.event_date)

    if (newEvent.status === 'archived') {
      setLists(prev => {
        const oldDay = prev[existingEventDate]

        return {
          ...prev,
          [existingEventDate]: oldDay.filter(e => {
            return e.id !== newEvent.id
          })
        }
      })

      dispatch(
        addManualCalendarTaskRoutine({
          event: {
            ...newEvent,
            event_date: '2030-04-04T00:00:00'
          }
        })
      )
    } else {
      if (existingEventDate === newEventDate) {
        setLists(prev => {
          const day = prev[newEventDate]

          return {
            ...prev,
            [newEventDate]: day.map(e => {
              return e.id == newEvent.id ? newEvent : e
            })
          }
        })
      } else {
        setLists(prev => {
          const oldDay = prev[existingEventDate]
          const newDay = prev[newEventDate]

          if (oldDay) {
            return {
              ...prev,
              [newEventDate]: [...newDay, newEvent],
              [existingEventDate]: oldDay.filter(e => {
                return e.id !== newEvent.id
              })
            }
          } else {
            return {
              ...prev,
              [newEventDate]: [...newDay, newEvent]
            }
          }
        })
      }
    }
  }

  const updateEventStatus = (event, status) => {
    setLists(prev => {
      const updatedLists = { ...prev }

      if (status === 'archived') {
        for (const date in updatedLists) {
          updatedLists[date] = updatedLists[date].filter(e => e.id !== event.id)
        }
        return updatedLists
      }

      // Iterate over each date in the lists
      for (const date in updatedLists) {
        updatedLists[date] = updatedLists[date].map(e => {
          if (e.id == event.id) {
            return {
              ...e,
              status: status // update the status here
            }
          } else {
            return e
          }
        })
      }

      return updatedLists
    })
  }

  const courseEndDate = useMemo(() => {
    const accessibleTo = R.propOr('', 'accessible_to', currentCourse)

    const calculatedAccessibleTo = addDaysToDate(new Date(), daysAmount)

    return isNotNilOrEmpty(accessibleTo)
      ? new Date(accessibleTo as Date)
      : calculatedAccessibleTo
  }, [currentCourse])

  useEffect(() => {
    dispatch(
      fetchExamsListRoutine({
        query:
          '?order%5Bby%5D=title&order%5Bdir%5D=asc&limit%5Btake%5D=1000&limit%5Bpage%5D=1'
      })
    )
    handleCalendarFetch()
  }, [])

  useEffect(() => {
    originalCourseId && dispatch(getAamcEventsColorsRoutine())
  }, [originalCourseId])

  useEffect(() => {
    if (bookCourseId) {
      getMcatDates({ id: bookCourseId })
        .then(resp => {
          const dates = resp.data.data

          const options = dates
            .filter(date => isAfter(new Date(date.mcat_date), new Date()))
            .sort(
              (a, b) =>
                new Date(a.mcat_date).getTime() -
                new Date(b.mcat_date).getTime()
            )
            .map(date => ({
              label: formatDate(date.mcat_date, DATE_FORMATS.slash),
              value: propOr('', 'id', date)
            }))

          setMcatDateOptions(options)
        })
        .catch(err => {
          setMcatDateOptions([])
          console.error(err)
        })
    }
  }, [bookCourseId])

  return (
    <CalendarContext.Provider
      value={{
        archiveEvents,
        attachNewEventToDate,
        calendar,
        calendarWeeks,
        course: currentCourse,
        courseDaysAmount: daysAmount as number,
        courseEndDate,
        detachEventFromDate,
        isConfigMode: configMode,
        isLoading,
        lists,
        mcatDateOptions,
        setConfigMode,
        setLists,
        updateEvent,
        updateEventStatus,
        firstVisibleSunday,
        setFirstVisibleSunday,
        originalCourseId
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}

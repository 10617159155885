import React, { useCallback, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import {
  getIsOrdinaryStudent,
  isAuthLoaded
} from 'modules/auth/ducks/selectors'
import {
  SHORT_STUDENTS_ACTIVITY_TIME,
  LONG_STUDENTS_ACTIVITY_TIME
} from 'utils/user'

import {
  shortStudentActivity,
  longStudentActivity
} from 'services/StudentService'

export const ActiveOnSite = ({ isActive, isPaused, isCorrectPath }) => {
  const dispatch = useDispatch()
  const [timerState, setTimerState] = React.useState(0)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const calculateActiveTime = () => {
    if (isActive) {
      setTimerState(prevState => prevState + 1)
    }
  }

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )

  React.useEffect(() => {
    let timer

    if (isCorrectPath && isActive && !isPaused) {
      timer = setInterval(calculateActiveTime, 1000)
    } else {
      clearInterval(timer)
      setTimerState(0)
    }

    return () => {
      clearInterval(timer)
    }
  }, [isCorrectPath, isActive, isPaused])

  React.useEffect(() => {
    /*
      // checking if the user is on the platform another 2 minutes but not equal || equal up to 30 minutes.
      // Time intentionally increased by 1-2sec.,
      // because the API has validation and will not allow to increase the time less than 2 / 30 minutes
    */
    if (
      timerState > 0 &&
      timerState % 121 === 0 &&
      timerState % 1802 !== 0 &&
      isOrdinaryStudent &&
      isAuthFetched &&
      isActive &&
      !isPaused
    ) {
      shortStudentActivity({ duration: SHORT_STUDENTS_ACTIVITY_TIME })
        .then(fetchStudentDetails)
        .catch(console.error)
    }
    if (
      timerState > 0 &&
      timerState % 1802 === 0 &&
      isOrdinaryStudent &&
      isAuthFetched &&
      isActive &&
      !isPaused
    ) {
      longStudentActivity({ duration: LONG_STUDENTS_ACTIVITY_TIME })
        .then(fetchStudentDetails)
        .catch(console.error)
    }
  }, [timerState, isActive])
  return <div />
}

export default memo(ActiveOnSite)

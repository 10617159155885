import { createRoutine } from 'redux-saga-routines'
import * as Effects from '@redux-saga/core/effects'
import * as SettingsService from 'services/SettingsService'
import { SETTINGS_NAMESPACE } from 'utils/settings'

// ROUTINES
export const fetchCourseSettingsRoutine = createRoutine('FETCH_COURSE_SETTINGS')

// ACTIONS
function* fetchCourseSettings() {
  yield Effects.put(fetchCourseSettingsRoutine.request())
  try {
    const result = yield Effects.call(
      SettingsService.fetchSettingsByNamespace,
      { namespace: SETTINGS_NAMESPACE.courses }
    )
    yield Effects.put(fetchCourseSettingsRoutine.success(result.data))
  } catch (e) {
    yield Effects.put(fetchCourseSettingsRoutine.failure(e))
  }
}

// WATCHERS
export function* fetchCourseSettingsWatcher() {
  yield Effects.takeLatest(
    fetchCourseSettingsRoutine.TRIGGER,
    fetchCourseSettings
  )
}

// SAGAS
export const settingsSagas = [Effects.fork(fetchCourseSettingsWatcher)]

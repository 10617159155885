import { pathOr } from 'ramda'

export const errors = {
  accountInactive: 'user.inactive',
  accountUnauthenticated: 'user.unauthenticated',
  tokenExpired: 'user.token-expired',
  emailExists: 'user.email-exists',
  loginExists: 'user.login-exists',
  entityNotFound: 'entity.not-found',
  entityExists: 'entity.already-exists',
  entityInvalid: 'entity.invalid',
  dateFromPast: 'date.from-past',
  targetScoreValue: 'target-score.value-not-allowed',
  studentImpersonating: 'student.impersonating',
  courseNotFound: 'student-course.not-found',
  coursePaused: 'student-course.paused',
  saltyBucksInsufficientFunds: 'salty-bucks.insufficient-funds',
  examAlreadyCompleted: 'exams.already-completed',
  usernameAlreadyExists: 'entity.already-exists',
  flashcardBoxExists: 'flashcard-box.already-exists'
}

export const getErrorName = (error: Error) =>
  pathOr('', ['response', 'data', 'error', 'errorCode'], error)

export const getErrorMessage = (error: Error) =>
  pathOr('', ['response', 'data', 'error', 'message'], error)

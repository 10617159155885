import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'

import { saveBookReadingProgress } from 'services/BooksService'
import { useSelector } from 'react-redux'
import {
  getIsOrdinaryStudent,
  isAuthLoaded
} from 'modules/auth/ducks/selectors'

export const BookReadingProgress = (): JSX.Element => {
  const params = useParams()

  const bookId: string = R.propOr('', 'bookId', params)
  const partOrder: string = R.propOr('', 'partOrder', params)
  const chapterOrder: string = R.propOr('', 'chapterOrder', params)

  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const saveBookProgress = () => {
    const handleSuccess = () => {}
    const handleError = e => console.error(e)

    saveBookReadingProgress({
      bookId,
      partOrder,
      chapterOrder
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  useEffect(() => {
    if (isOrdinaryStudent && isAuthFetched) {
      saveBookProgress()
    }
  }, [params, isOrdinaryStudent, isAuthFetched])

  return <div />
}

export default BookReadingProgress

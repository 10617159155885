import { useTranslation } from 'react-i18next'
import { getTheme } from 'examkrackers-components'
import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'

export const AnswerContentQuestion = data => {
  const removeEmptyBooks = data => {
    return data.filter(item => {
      return (
        parseInt(item.correct) +
          parseInt(item.incorrect) +
          parseInt(item.untried) >
        0
      )
    })
  }
  const { t } = useTranslation()
  const theme = getTheme()
  const correctAnswerContentQuestions = removeEmptyBooks(data).map(a =>
    parseInt(a.correct, 10)
  )
  const incorrectAnswerContentQuestions = removeEmptyBooks(data).map(a =>
    parseInt(a.incorrect, 10)
  )
  const untriedAnswerContentQuestions = removeEmptyBooks(data).map(a =>
    parseInt(a.untried, 10)
  )

  const contentQuestionsTags = data.map(a => {
    const correct = parseInt(a.correct)
    const incorrect = parseInt(a.incorrect)
    const untried = parseInt(a.untried)
    const total = correct + incorrect + untried
    if (total > 0) {
      return a.book_tag && a.book_tag.toUpperCase()
    }
    return ''
  })

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  return {
    series: [
      {
        name: t('dashboard.charts.correct'),
        data: correctAnswerContentQuestions
      },
      {
        name: t('dashboard.charts.incorrect'),
        data: incorrectAnswerContentQuestions
      },
      {
        name: t('dashboard.charts.untried'),
        data: untriedAnswerContentQuestions
      }
    ],
    options: {
      chart: {
        id: 'cqChart',
        background: theme.colors.main.white,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false
        }
      },
      colors: [
        'url(#GradientCqCorrect)',
        'url(#GradientCqIncorrect)',
        'url(#GradientCqUntried)'
      ],
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
          color: '#888'
        },
        position: 'front'
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          barHeight: '70%',
          colors: {
            backgroundBarColors: ['url(#GradientBook)'],
            backgroundBarRadius: 10
          }
        }
      },
      stroke: {
        width: 0
      },
      title: {
        text: t('dashboard.charts.contentQuestions'),
        align: 'left',
        style: {
          fontSize: '16',
          fontFamily: 'Arial, sans-serif'
        },
        offsetX: 42,
        offsetY: 20
      },
      grid: {
        show: false,
        padding: {
          right: 120,
          left: 20
        }
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        categories: contentQuestionsTags.filter(a => a),
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: true,
          align: 'left',
          offsetY: 3,
          style: {
            colors: isDarkTheme ? ['#ffffff'] : ['#000000'],
            fontSize: '12px',
            fontFamily: 'Arial, sans-serif',
            fontWeight: 700,
            cssClass: 'apexcharts-yaxis-label'
          }
        }
      },
      fill: {
        colors: [
          'url(#GradientCqCorrect)',
          'url(#GradientCqIncorrect)',
          'url(#GradientCqUntried)'
        ],
        opacity: 1
      },
      legend: {
        show: false
      },
      dataLabels: {
        formatter: function (val, opts) {
          return opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
        },
        style: {
          fontSize: '10px',
          colors: [`${theme.colors.main.white}`],
          fontFamily: 'Arial, sans-serif',
          fontWeight: 400
        }
      }
    }
  }
}

import * as R from 'ramda'
import { getTheme } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { formatNumberWithDecimal } from 'utils/numbers'

export const saltyBucksByCategoryChartOptions = data => {
  const { t } = useTranslation()

  const seriesData = R.pipe(
    R.propOr([], 'categories'),
    R.map((category: string) => R.pathOr(null, ['values', category], data))
  )(data)

  const categoriesData: string[] = R.pipe(
    R.propOr([], 'categories'),
    R.map((category: string) =>
      t(`leaderboard.rightColumn.categoriesChart.categories.${category}`)
    )
  )(data)
  const theme = getTheme()

  return {
    series: [
      {
        data: seriesData
      }
    ],
    options: {
      chart: {
        id: 'sbByCategoryChart',
        type: 'bar',
        background: theme.colors.backgrounds.main,
        toolbar: {
          show: false
        }
      },
      fill: {
        colors: [theme.colors.dashboard.sb.mainLine],
        opacity: 1
      },
      title: {
        text: t('leaderboard.rightColumn.categoriesChart.title'),
        align: 'left',
        style: {
          fontSize: '16',
          fontFamily: 'Arial, sans-serif'
        },
        offsetX: 16,
        offsetY: 10
      },
      xaxis: {
        categories: categoriesData,
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: false,
          trim: false,
          maxHeight: 50,
          style: {
            fontSize: '10px',
            colors: theme.colors.dashboard.sb.text,
            fontFamily: 'Arial, sans-serif'
          }
        },
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: theme.colors.dashboard.sb.text,
            fontFamily: 'Arial, sans-serif'
          },
          formatter: function (value) {
            return value && formatNumberWithDecimal(value)
          },
          offsetY: 2
        }
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
        <div class="category-tooltip">
          <span class="category-tooltip-label">
            ${categoriesData[dataPointIndex]}
          </span>
          <div class="category-tooltip-value">
            <span class="icon-ek-salty-bucks category-tooltip-value-icon" />
            <span class="category-tooltip-value-label">
              ${formatNumberWithDecimal(series[seriesIndex][dataPointIndex])}
            </span>
          </div>
        </div>
        `
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        strokeDashArray: 0,
        borderColor: theme.colors.dashboard.sb.text
      }
    }
  }
}

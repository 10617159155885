import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { isNotNilOrEmpty } from 'utils/ramda'
import { getPassageRealOrderByOrder } from 'utils/exam'

import { getSectionDetailsById } from 'modules/exam/ducks/selectors'
import {
  getPassageGraphLines,
  getPassageReadingTimeGraph
} from 'modules/diagnostics/ducks/selectors'

import { Tooltip } from 'examkrackers-components'

import { RootReducer } from 'types'

export const TopTimeChartExamSectionDiagnostics = (): JSX.Element => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [chartPosition, setChartPosition] = useState({ x: 0, y: 0, width: 0 })
  const [chartInnerPosition, setChartInnerPosition] = useState({
    x: 0,
    y: 0,
    width: 0
  })
  const [passageLinesPosition, setPassageLinesPosition] = useState([])
  const [passageLabelsPosition, setPassageLabelsPosition] = useState([])

  const params = useParams()
  const sectionId: string = R.propOr('', 'sectionId')(params)
  const passageReadingTimeGraph = useSelector(getPassageReadingTimeGraph)
  const passageGraphLines = useSelector(state =>
    getPassageGraphLines(state, sectionId)
  )
  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, sectionId)
  )

  const wpmValues = R.pipe(
    // @ts-ignore
    R.uniqBy(R.prop('y')),
    // @ts-ignore
    R.map(R.prop('wordsPerMinute'))
    // @ts-ignore
  )(passageReadingTimeGraph)

  const saveChartPosition = () => {
    const chart = document.querySelector('#time-chart-exam-section')
    const chartInner = document.querySelector('.apexcharts-inner')

    // @ts-ignore
    const chartPosition = chart
      ? chart.getBoundingClientRect()
      : { x: 0, y: 0, width: 0 }
    // @ts-ignore
    const chartInnerPosition = chartInner
      ? chartInner.getBoundingClientRect()
      : { x: 0, y: 0, width: 0 }

    setChartPosition(chartPosition)
    setChartInnerPosition(chartInnerPosition)
  }

  const savePassageLinesPosition = () =>
    R.pipe(
      // @ts-ignore
      R.map(questionNumber => {
        const gridLine = document.querySelector(
          `#time-chart-exam-section .apexcharts-gridline[dataX="${questionNumber}"`
        )
        // @ts-ignore
        return gridLine ? gridLine.getBoundingClientRect() : { x: 0, y: 0 }
      }),
      setPassageLinesPosition
      // @ts-ignore
    )(passageGraphLines)

  const saveLabelsPositions = () =>
    R.pipe(
      R.addIndex(R.map)(
        // @ts-ignore
        (position, index) => {
          const nextPosition = passageLinesPosition[index + 1]
          const currentXPosition: number = R.propOr(0, 'x', position)
          const nextXPosition: number = R.propOr(0, 'x', nextPosition)

          const middleXPosition =
            currentXPosition + (nextXPosition - currentXPosition) / 2
          return middleXPosition - chartPosition.x
        }
      ),
      // @ts-ignore
      R.dropLast(1),
      setPassageLabelsPosition
      // @ts-ignore
    )(passageLinesPosition)

  const renderPassageEdges = R.map(
    (gridLinePosition: { x: number; y: number }) => {
      return (
        <PassageEdge
          key={`passage-edge-${gridLinePosition.x}-${gridLinePosition.y}`}
          x={gridLinePosition.x - chartPosition.x}
          y={chartInnerPosition.y - chartPosition.y}
        />
      )
    }
  )(passageLinesPosition)

  const renderPassageCells = R.addIndex(R.map)((labelXPosition, index) => {
    const order = index + 1
    // @ts-ignore
    const realOrder: number = getPassageRealOrderByOrder(sectionDetails, order)

    // @ts-ignore
    const wpm = isNotNilOrEmpty(wpmValues) ? wpmValues[index] : '-'

    return (
      <PassageCell
        key={`passage-cell-${labelXPosition}-${index}`}
        x={labelXPosition}
        y={chartInnerPosition.y - chartPosition.y}
      >
        <div className='passage-number'>
          {realOrder || (
            <DqItem>
              <div>{t('diagnostics.dq')}</div>
              <Tooltip
                id={`id-tooltip-dq-${index}`}
                info
                tooltipContent={t('diagnostics.discreetQuestion')}
              />
            </DqItem>
          )}
        </div>
        <div className='passage-wpm'>{realOrder ? wpm : '-'}</div>
      </PassageCell>
    )
  })(passageLabelsPosition)

  const firstX: number = R.pipe(
    R.head,
    R.propOr(0, 'x'),
    Number
  )(passageLinesPosition)

  const lastX: number = R.pipe(
    R.last,
    R.propOr(0, 'x'),
    Number
  )(passageLinesPosition)

  const rowWidth = lastX - firstX

  useEffect(() => {
    if (!loading) {
      savePassageLinesPosition()
    }
  }, [loading])

  useEffect(() => {
    if (isNotNilOrEmpty(passageLinesPosition)) {
      saveLabelsPositions()
    }
  }, [passageLinesPosition])

  useEffect(() => {
    setTimeout(saveChartPosition, 2000)
    setTimeout(() => setLoading(false), 3000)
  }, [])

  return (
    <Container loading={loading}>
      <Label width={rowWidth} x={firstX - chartPosition.x}>
        {t('diagnostics.graph.legend.passages')}
      </Label>
      <WpmLegend
        width={rowWidth}
        x={firstX - chartPosition.x}
        y={chartInnerPosition.y - chartPosition.y}
      >
        <span>{t('diagnostics.graph.legend.wpm')}</span>
        <Tooltip
          info
          tooltipContent={t('diagnostics.graph.legend.wpmTooltip')}
          id='wpm'
        />
      </WpmLegend>
      <WpmRow
        width={rowWidth}
        x={firstX - chartPosition.x}
        y={chartInnerPosition.y - chartPosition.y}
      />
      {renderPassageEdges}
      {renderPassageCells}
    </Container>
  )
}

export default TopTimeChartExamSectionDiagnostics

const topMargin = 12

const Container = styled.div`
  margin-top: ${topMargin}px;

  .__react_component_tooltip {
    font-size: 11px !important;
  }
`

const PassageEdge = styled.div`
  position: absolute;
  opacity: 0.9;
  left: ${({ x }) => x - 1}px;
  top: ${({ y }) => y - topMargin}px;
  width: 1.5px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.main.grey300};
`

const WpmRow = styled.div`
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y - topMargin}px;
  width: ${({ width }) => width}px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.main.grey200};
  border-top: 1px solid ${({ theme }) => theme.colors.main.grey300};
`

const Label = styled.div`
  position: absolute;
  left: ${({ x }) => x}px;
  top: 6px;
  width: ${({ width }) => width}px;
  height: 24px;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
`

const WpmLegend = styled.div`
  position: absolute;
  left: ${({ x }) => x - 53}px;
  top: ${({ y }) => y - topMargin}px;
  width: ${({ width }) => width}px;
  height: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 11px;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  color: ${({ theme }) =>
    theme.colors.diagnostics.diagnostic.wordsPerMinute.font};

  svg {
    font-size: 12px;
  }
`

const PassageCell = styled.div`
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y - topMargin * 3}px;
  height: calc(24px * 2);
  line-height: 24px;
  font-size: 11px;
  transform: translateX(-50%);
  text-align: center;
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  .passage-wpm {
    font-weight: bold;
    color: ${({ theme }) =>
      theme.colors.diagnostics.diagnostic.wordsPerMinute.font};
  }
`

const DqItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & div:first-of-type {
    margin-right: 5px;
  }

  svg {
    font-size: 12px;
  }
`

import React, { useEffect, useMemo, useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'
import { useHistory } from 'react-router-dom'

import ResetSalty from 'assets/images/Calendar_Reset_Saltys.svg'

import { getMcatDates } from 'services/CalendarService'

import {
  SingleSelect,
  CalendarIcon,
  Button,
  Tooltip
} from 'examkrackers-components'

import { getOptionByValue } from 'utils/form'
import {
  columnsOrder,
  countStudyDaysInRange,
  daysOfWeek,
  prevStateOfCalendarColumns
} from 'utils/calendar'
import {
  DATE_FORMATS,
  formatDate,
  getUniversalDate,
  isBeforeToday
} from 'utils/date'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import DateInput from 'components/DateInput/DateInput'
import ResetCalendarConfirmationModal from './ResetCalendarConfirmationModal'
import DayListItem from 'modules/calendar/setup/DayListItem'
import LeftColumnQuestions from 'modules/calendar/setup/LeftColumnQuestions'
import useCalendarSettingsValidation from 'hooks/useCalendarSettingsValidation'
import { addDays, isAfter } from 'date-fns'
import withSettingsPageWrapper from 'modules/calendar/SettingsPageWrapper'
import { CalendarContext, CalendarContextProvider } from 'hooks/CalendarContext'

interface McatDate {
  id: string
  course_id: string
  mcat_date: string
}

const initialValues = {
  mcat_at: '',
  calendar_start_at: '',
  exam_at: '',
  prioriDays: [0, 0, 0, 0, 0, 0, 0]
}

const WrappedResetCalendarModalNew = () => {
  return (
    <CalendarContextProvider>
      <ResetCalendarModalNew />
    </CalendarContextProvider>
  )
}

const ResetCalendarModalNew: React.FC = () => {
  const [values, setValues] = useState(initialValues)
  const [datesMcat, setDatesMcat] = useState<McatDate[]>([])
  const { t } = useTranslation()
  const history = useHistory()

  const currentCourse = useSelector(getCurrentCourse)
  const courseId = propOr('', 'book_course_id', currentCourse)

  const { course } = useContext(CalendarContext)
  const amountOfDays = course.metadata && JSON.parse(course.metadata)
  const parsedAmountOfDays =
    amountOfDays && propOr(0, 'days_amount', amountOfDays)
  const isOneWeekSubscription = parsedAmountOfDays == 7

  const currentChoiceOfUsersPrioriDays =
    isNotNilOrEmpty(currentCourse) &&
    prevStateOfCalendarColumns(currentCourse.prioridays)

  const [columnsData, setColumnsData] = useState(currentChoiceOfUsersPrioriDays)

  const mcatDateOptions: { label: string; value: string }[] = useMemo(() => {
    return datesMcat
      .filter(date =>
        isAfter(
          new Date(date.mcat_date),
          addDays(new Date(course.accessible_from), 7)
        )
      )
      .sort(
        (a, b) =>
          new Date(a.mcat_date).getTime() - new Date(b.mcat_date).getTime()
      )
      .map(date => ({
        label: formatDate(date.mcat_date, DATE_FORMATS.slash),
        value: propOr('', 'id', date)
      }))
  }, [datesMcat])

  const {
    dndErrors,
    startDateMax,
    examDateBoundaries,
    errors,
    touchedFields,
    setTouchedFields
  } = useCalendarSettingsValidation({
    course: currentCourse,
    values,
    mcatDateOptions,
    columnsData
  })

  const handleDragEnd = result => {
    const { source, destination, draggableId } = result
    setTouchedFields(prev => [...prev, 'priori_days'])

    if (destination.droppableId === 'mcatDay') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 6
    } else if (destination.droppableId === 'reviewDay') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 7
    } else if (destination.droppableId === 'priorityOne') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 1
    } else if (destination.droppableId === 'priorityTwo') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 2
    } else if (destination.droppableId === 'priorityThree') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 3
    } else if (destination.droppableId === 'priorityFour') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 4
    } else if (destination.droppableId === 'priorityFive') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 5
    } else {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 0
    }

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const startColumn = columnsData[source.droppableId]
    const finishColumn = columnsData[destination.droppableId]

    if (startColumn === finishColumn) {
      const newDay = Array.from(startColumn.day)
      const [removed] = newDay.splice(0, 1)
      newDay.splice(destination.index, 0, removed)

      const newColumn = {
        ...startColumn,
        day: newDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newColumn
      }
      setColumnsData(newState)
    } else {
      const startNewDay = startColumn.day
      const [removed] = startNewDay.splice(0, 1)
      const newStartColumn = {
        ...startColumn,
        day: startNewDay
      }
      const finishNewDay = Array.from(finishColumn.day)
      finishNewDay.splice(destination.index, 0, removed)
      const newFinishColumn = {
        ...finishColumn,
        day: finishNewDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newStartColumn,
        [destination.droppableId]: newFinishColumn
      }

      setColumnsData(newState)
    }
  }

  const handleChangeMcatDate = option => {
    setValues(prev => ({ ...prev, mcat_at: option.value }))
    setTouchedFields(prev => [...prev, 'mcat_at'])
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
    setTouchedFields(prev => [...prev, name])
  }

  const handleCancel = () => {
    history.goBack()
  }

  useEffect(() => {
    courseId &&
      getMcatDates({ id: courseId })
        .then(resp => {
          setDatesMcat(resp.data.data)
        })
        .catch(err => {
          console.error(err)
        })
  }, [])

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      mcat_at: currentCourse.mcatDate.id,
      exam_at: isOneWeekSubscription
        ? getUniversalDate(course.accessible_to)
        : currentCourse.exam_at,
      calendar_start_at: isOneWeekSubscription
        ? getUniversalDate(new Date())
        : currentCourse.calendar_start_at,
      prioriDays: currentCourse.prioridays
    }))
  }, [currentCourse, isOneWeekSubscription])

  const studyDaysCount = countStudyDaysInRange(
    values.calendar_start_at,
    values.exam_at,
    values.prioriDays
  )

  const { calendar_start_at, mcat_at, exam_at, prioriDays } = values
  const hasAllValues = [calendar_start_at, mcat_at, exam_at, prioriDays].every(
    el => isNotNilOrEmpty(el)
  )

  const handleCalendarManualOpen = (id: string) => () => {
    const el = document.getElementById(id)

    if (el) {
      el.click()
    }
  }

  return (
    <Container data-testid='ResetCalendarModalNew-Container'>
      <TitleWrapper data-testid='ResetCalendarModalNew-TitleWrapper-reset'>
        {t('calendar.reset.title')}
      </TitleWrapper>
      <TextContainer data-testid='ResetCalendarModalNew-TextContainer-reset'>
        <SubtitleWrapper data-testid='ResetCalendarModalNew-SubtitleWrapper-reset'>
          {t('calendar.reset.description')}
        </SubtitleWrapper>
      </TextContainer>
      <ColumnsWrapper data-testid='ResetCalendarModalNew-ColumnsWrapper'>
        <LeftColumn data-testid='ResetCalendarModalNew-LeftColumn'>
          <QuestionContainer data-testid='ResetCalendarModalNew-QuestionContainer'>
            <DescWrapper data-testid='ResetCalendarModalNew-DescWrapper'>
              <QuestionTitle data-testid='ResetCalendarModalNew-QuestionTitle-mcat'>
                {t('calendar.setup.mcat.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ResetCalendarModalNew-QuestionDescription-mcat'>
                {t('calendar.setup.mcat.tooltip')}
              </QuestionDescription>
            </DescWrapper>
            <SelectWrapper data-testid='ResetCalendarModalNew-SelectWrapper-mcat'>
              <StyledSelect
                data-testid='ResetCalendarModalNew-StyledSelect-mcat'
                removeMargin
                label=''
                size='small'
                options={mcatDateOptions}
                onChange={handleChangeMcatDate}
                disabled={isNilOrEmpty(mcatDateOptions)}
                value={getOptionByValue(values.mcat_at)(mcatDateOptions)}
              />
            </SelectWrapper>
          </QuestionContainer>
          {errors.mcatDate && isNotNilOrEmpty(values.mcat_at) && (
            <Error data-testid='ResetCalendarModalNew-Error-mcat'>
              {errors.mcatDate}
            </Error>
          )}
          <QuestionContainer
            disabled={isNilOrEmpty(values.mcat_at) || isOneWeekSubscription}
            data-testid='ResetCalendarModalNew-QuestionContainer-start'
          >
            <DescWrapper data-testid='ResetCalendarModalNew-DescWrapper-start'>
              <QuestionTitle data-testid='ResetCalendarModalNew-QuestionTitle-start'>
                {t('calendar.setup.start.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ResetCalendarModalNew-QuestionDescription-start'>
                {t('calendar.setup.start.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper data-testid='ResetCalendarModalNew-PickerWrapper-start'>
              <DateInput
                data-testid='ResetCalendarModalNew-DateInput-start'
                name='calendar_start_at'
                id='calendar_start_at_input'
                value={values.calendar_start_at}
                validate={() => true}
                size='small'
                onChange={handleValueChange}
                minDate={new Date()}
                maxDate={startDateMax}
              />
            </PickerWrapper>

            <IconContainer data-testid='ResetCalendarModalNew-IconContainer-calendar-start'>
              <CalendarIcon
                data-testid='ResetCalendarModalNew-CalendarIcon-start'
                onClick={handleCalendarManualOpen('calendar_start_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.startDate && isNotNilOrEmpty(values.calendar_start_at) && (
            <Error data-testid='ResetCalendarModalNew-Error-startDate'>
              {errors.startDate}
            </Error>
          )}
          <QuestionContainer
            disabled={
              isNilOrEmpty(values.calendar_start_at) || isOneWeekSubscription
            }
            data-testid='ResetCalendarModalNew-QuestionContainer-end'
          >
            <DescWrapper data-testid='ResetCalendarModalNew-DescWrapper-end'>
              <QuestionTitle data-testid='ResetCalendarModalNew-QuestionTitle-end'>
                {t('calendar.setup.end.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ResetCalendarModalNew-QuestionDescription-end'>
                {t('calendar.setup.end.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper data-testid='ResetCalendarModalNew-PickerWrapper-exam'>
              <DateInput
                data-testid='ResetCalendarModalNew-DateInput-exam'
                name='exam_at'
                id='exam_at_input'
                value={values.exam_at}
                validate={() => true}
                size='small'
                // t={t}
                onChange={handleValueChange}
                minDate={examDateBoundaries.min}
                maxDate={examDateBoundaries.max}
                withExtendModal
              />
            </PickerWrapper>
            <IconContainer data-testid='ResetCalendarModalNew-IconContainer-calendar-exam'>
              <CalendarIcon
                data-testid='ResetCalendarModalNew-CalendarIcon-exam'
                onClick={handleCalendarManualOpen('exam_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.endDate && isNotNilOrEmpty(values.exam_at) && (
            <Error data-testid='ResetCalendarModalNew-Error-end'>
              {errors.endDate}
            </Error>
          )}

          <LeftColumnQuestions
            data-testid='ResetCalendarModalNew-LeftColumnQuestions-exam'
            disabled={isNilOrEmpty(values.exam_at) || isOneWeekSubscription}
          />
        </LeftColumn>
        <RightColumn data-testid='ResetCalendarModalNew-RightColumn'>
          <ReorganizeSaltyImage
            data-testid='ResetCalendarModalNew-ReorganizeSaltyImage'
            id='reorganize-salty'
            src={ResetSalty}
            alt='Salty reorganize'
          />
          <DragDropContext
            onDragEnd={handleDragEnd}
            data-testid='ResetCalendarModalNew-DragDropContext'
          >
            <WrapperDnd
              disabled={isNilOrEmpty(values.exam_at) || isOneWeekSubscription}
              data-testid='ResetCalendarModalNew-WrapperDnd'
            >
              <WeekDaysColumn data-testid='ResetCalendarModalNew-WeekDaysColumn'>
                <>
                  <ColumnTitle data-testid='ResetCalendarModalNew-ColumnTitle-mandatoryDaysOff'>
                    {t('calendar.setup.mandatoryDaysOff')}
                  </ColumnTitle>
                  {columnsOrder.map((columnId, index) => {
                    const column = columnsData[columnId]
                    if (index <= 6) {
                      return (
                        <DropZoneWrapper
                          key={column.id}
                          data-testid={`ResetCalendarModalNew-DropZoneWrapper-mandatoryDaysOff-${column.id}`}
                        >
                          <Droppable
                            data-testid={`ResetCalendarModalNew-Droppable-mandatoryDaysOff-${column.id}`}
                            droppableId={column.id}
                            isDropDisabled={
                              column.day && column.day.length === 1
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <SingleDropZone
                                  data-testid={`ResetCalendarModalNew-SingleDropZone-mandatoryDaysOff-${column.id}`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <DropContainer
                                    data-testid={`ResetCalendarModalNew-DropContainer-mandatoryDaysOff-${column.title}`}
                                  >
                                    {column.title}
                                  </DropContainer>

                                  {isNotNilOrEmpty(column.day) && (
                                    <DayListItem
                                      data-testid={`ResetCalendarModalNew-DayListItem-mandatoryDaysOff${column.day[0]}`}
                                      day={column.day[0]}
                                      index={index}
                                    />
                                  )}
                                  {provided.placeholder}
                                </SingleDropZone>
                              )
                            }}
                          </Droppable>
                        </DropZoneWrapper>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              </WeekDaysColumn>
              <McatDaysColumn data-testid='ResetCalendarModalNew-McatDaysColumn'>
                <>
                  <McatContainer data-testid='ResetCalendarModalNew-McatContainer'>
                    <ColumnTitle data-testid='ResetCalendarModalNew-ColumnTitle'>
                      {t('calendar.setup.fullLengthMCATDay')}
                    </ColumnTitle>
                    {columnsOrder.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index === 7) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`ResetCalendarModalNew-DropZoneWrapper-fullLengthMcatDay-${column.id}`}
                          >
                            <Droppable
                              data-testid={`ResetCalendarModalNew-Droppable-fullLengthMcatDay-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZoneActive
                                    data-testid={`ResetCalendarModalNew-SingleDropZoneActive-fullLengthMcatDay-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                  >
                                    <DropContainer
                                      data-testid={`ResetCalendarModalNew-DropContainer-fullLengthMcatDay-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>
                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`ResetCalendarModalNew-DayListItem-fullLengthMcatDay-${column.day[0]}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZoneActive>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </McatContainer>
                  <McatContainer data-testid='ResetCalendarModalNew-McatContainer-fullLengthMCATReviewDay'>
                    <ColumnTitle>
                      {t('calendar.setup.fullLengthMCATReviewDay')}
                    </ColumnTitle>
                    {columnsOrder.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index === 8) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`ResetCalendarModalNew-DropZoneWrapper-fullLengthMCATReviewDay-${column.id}`}
                          >
                            <Droppable
                              data-testid={`ResetCalendarModalNew-Droppable-fullLengthMCATReviewDay-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZoneActive
                                    data-testid={`ResetCalendarModalNew-SingleDropZoneActive-fullLengthMCATReviewDay-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                  >
                                    <DropContainer
                                      data-testid={`ResetCalendarModalNew-DropContainer-fullLengthMCATReviewDay-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>
                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`ResetCalendarModalNew-DayListItem-fullLengthMCATReviewDay-${column.day[0]}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZoneActive>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </McatContainer>
                </>
              </McatDaysColumn>
              <PrioriColumn data-testid='ResetCalendarModalNew-McatContainer-PrioriColumn'>
                <>
                  <ColumnTitle data-testid='ResetCalendarModalNew-McatContainer-ColumnsOrder-studyDays'>
                    {t('calendar.setup.studyDays')}
                  </ColumnTitle>
                  {columnsOrder.map((columnId, index) => {
                    const column = columnsData[columnId]
                    if (index > 8) {
                      return (
                        <DropZoneWrapper
                          key={column.id}
                          data-testid={`ResetCalendarModalNew-DropZoneWrapper-studyDays-${column.id}`}
                        >
                          <Droppable
                            data-testid={`ResetCalendarModalNew-Droppable-studyDays-${column.id}`}
                            droppableId={column.id}
                            isDropDisabled={
                              column.day && column.day.length === 1
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <SingleDropZoneActive
                                  data-testid={`ResetCalendarModalNew-SingleDropZoneActive-studyDays-${column.id}`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  isDraggingOver={snapshot.isDraggingOver}
                                >
                                  <DropContainer
                                    data-testid={`ResetCalendarModalNew-DropContainer-studyDays-${column.title}`}
                                  >
                                    {column.title}
                                  </DropContainer>
                                  {isNotNilOrEmpty(column.day) && (
                                    <DayListItem
                                      data-testid={`ResetCalendarModalNew-DayListItem-studyDays-${column.day[0]}`}
                                      day={column.day[0]}
                                      index={index}
                                    />
                                  )}
                                  {provided.placeholder}
                                </SingleDropZoneActive>
                              )
                            }}
                          </Droppable>
                        </DropZoneWrapper>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              </PrioriColumn>
            </WrapperDnd>
          </DragDropContext>
          {isNotNilOrEmpty(dndErrors) &&
            touchedFields.includes('priori_days') && (
              <DndErrors data-testid='ResetCalendarModalNew-DndErrors'>
                {dndErrors.map(error => (
                  <Error data-testid='ResetCalendarModalNew-Error'>
                    {error}
                  </Error>
                ))}
              </DndErrors>
            )}
        </RightColumn>
      </ColumnsWrapper>
      {studyDaysCount > 0 &&
        studyDaysCount < 44 &&
        hasAllValues &&
        !isOneWeekSubscription && (
          <WarningBox data-testid='ResetCalendarModalNew-WarningBox'>
            <WarningBoxContent data-testid='ResetCalendarModalNew-WarningBoxContainer'>
              Attention: Your current selection has only {studyDaysCount} Study
              Days.
              <br />
              We recommend at least 44 Study Days, so that you have at least one
              study day per chapter.
            </WarningBoxContent>
          </WarningBox>
        )}
      {isOneWeekSubscription && (
        <WarningBox>
          <WarningBoxContent>
            {t('calendar.setupForOneWeekSubWarningPartOne')}
            <br />
            {t('calendar.setupForOneWeekSubWarningPartTwo')}
          </WarningBoxContent>
        </WarningBox>
      )}
      <ButtonsWrapper data-testid='ResetCalendarModalNew-ButtonsWrapper'>
        <ButtonContainer data-testid='ResetCalendarModalNew-ButtonContainer-cancel'>
          <ButtonWrapper
            color='tertiary'
            onClick={handleCancel}
            data-testid='ResetCalendarModalNew-ButtonWrapper-cancel'
          >
            {t('calendar.button.cancel')}
          </ButtonWrapper>
        </ButtonContainer>
        <ButtonContainer data-testid='ResetCalendarModalNew-ButtonContainer-reset'>
          {(isNotNilOrEmpty(errors) && !isOneWeekSubscription) ||
          (isBeforeToday(values.calendar_start_at) &&
            !isOneWeekSubscription) ? (
            <Tooltip
              data-testid='ResetCalendarModalNew-Tooltip-reset'
              id='inactive-button'
              tooltipContent={
                'This form must be completed in order to continue. You will be able to make changes at any time by clicking on the Reorganize button on your calendar.'
              }
            >
              <ButtonWrapper
                data-testid='ResetCalendarModalNew-ButtonWrapper-reset'
                variant='contained'
                color='secondary'
                onClick={() => {}}
                disabled
              >
                {t('calendar.reset.button')}
              </ButtonWrapper>
            </Tooltip>
          ) : (
            <ResetCalendarConfirmationModal
              data-testid='ResetCalendarModalNew-ResetCalendarConfirmationModal'
              disabled={
                (isNotNilOrEmpty(errors) && !isOneWeekSubscription) ||
                (isBeforeToday(values.calendar_start_at) &&
                  !isOneWeekSubscription)
              }
              values={values}
              canFormBeSubmitted
              isStartDateValid
            />
          )}
        </ButtonContainer>
      </ButtonsWrapper>
    </Container>
  )
}

export default withSettingsPageWrapper(WrappedResetCalendarModalNew)

const Container = styled.div`
  margin: 20px 132px;
  font-size: 12px;
  padding-bottom: 30px;
`

const TitleWrapper = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`
const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const SubtitleWrapper = styled.div`
  text-align: center;
  font-size: 10px;
  width: 616px;
  line-height: 14px;
  margin-top: 10px;
`

const LeftColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RightColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SingleDropZone = styled.div`
  color: ${({ theme }) => theme.colors.main.grey400};
  background-color: #fbf5f2;
  background-color: #fbf5f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 26px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C5C6C8FF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`
const SingleDropZoneActive = styled(SingleDropZone)`
  color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary500 : '#444'};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary300 : '#fff3ed'};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237B7C7DFF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`

const WrapperDnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-top: 36px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const ColumnTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  width: 155px;
  text-align: center;
  font-weight: bold;
`
const WeekDaysColumn = styled.div``
const McatDaysColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
`
const McatContainer = styled.div`
  &:first-child {
    margin-bottom: 33px;
  }
`
const PrioriColumn = styled.div``

const DropZoneWrapper = styled.div`
  margin: 6px;
`
const DropContainer = styled.div`
  position: absolute;
`

const PickerWrapper = styled.div`
  display: flex;

  width: 120px;
  margin-top: -20px;
  margin-bottom: -30px;
`
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const QuestionTitle = styled.div`
  font-size: 14px;
  line-height: 30px;
`
const QuestionDescription = styled.div`
  font-size: 10px;
  margin-left: 20px;
  line-height: 20px;
  width: 310px;
`
const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 990px; */
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  font-size: 30px;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 7px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
  height: ${({ theme }) => theme.dimensions.inputHeight};
`
const StyledSelect = styled(SingleSelect)`
  min-width: 121px;
  max-width: 121px;
  div {
    overflow-x: hidden;
  }
`
const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ReorganizeSaltyImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 121px;
  width: 165px;
  margin-left: 190px;
  margin-top: 51px;
  margin-bottom: 51px;
`

const Error = styled.div`
  color: red;
`

const DndErrors = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;
  /* margin-top: 24px; */
  justify-content: center;
`

const WarningBox = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const WarningBoxContent = styled.div`
  text-align: center;
  color: red;
`

import APIService from 'services/APIService'
import {
  UpdateChapterTimersPayload,
  UpdateFlashcardsLearningTimePayload,
  UpdateVideoWatchingTimePayload
} from 'types'
import { dissoc } from 'ramda'

const api = APIService()

export const updateChapterReadingTime = (
  payload: UpdateChapterTimersPayload
) => {
  return api.patch(
    `/activity-timers/chapter/${payload.chapterId}`,
    dissoc('chapterId', payload)
  )
}

export const updateFlashcardsLearningTime = (
  payload: UpdateFlashcardsLearningTimePayload
) => {
  return api.patch(
    `/activity-timers/flashcard/${payload.flashcardId}`,
    dissoc('flashcardId', payload)
  )
}

export const updateVideoWatchingTime = (
  payload: UpdateVideoWatchingTimePayload
) => {
  return api.patch(
    `/activity-timers/video/${payload.videoId}`,
    dissoc('videoId', payload)
  )
}

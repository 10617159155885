import React from 'react'
import styled from 'styled-components'
import qs from 'qs'
import { find, includes, map, pipe, propEq, propOr, sum } from 'ramda'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { QUERY_PARAMS } from 'utils/flashcards'

import FlashcardsFilterBox from 'modules/flashcards/components/FlashcardsFilterBox'
import FlashcardFilterCustomBox from 'modules/flashcards/components/FlashcardFilterCustomBox'

import { getCustomBoxes } from 'modules/flashcards/ducks/selectors'
import { selectAllBooks } from 'modules/books/ducks/selectors'

import { Book, Course } from 'types'

export const CUSTOM_BOX_CATEGORY_ID = '00000000-0000-0000-0000-000000000000'

export const FlashcardCustomBoxesRow = (): JSX.Element => {
  const allCustomBoxes: Course = useSelector(getCustomBoxes)
  const allBooks: Book[] = useSelector(selectAllBooks)

  const findTagColor = bookId =>
    pipe(
      find(propEq('id', bookId)),
      propOr('', 'tag_colour')
      // @ts-ignore
    )(allBooks)

  const {
    location: { search }
  } = useHistory()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const currentExpandedBookLevels: string[] = propOr(
    [],
    QUERY_PARAMS.expandedBookLevels,
    parsedQuery
  )

  const isSelected = includes(CUSTOM_BOX_CATEGORY_ID, currentExpandedBookLevels)

  const renderCustomBoxes =
    // @ts-ignore
    allCustomBoxes.map(box => (
      <FlashcardFilterCustomBox
        customBox={box}
        key={`custom-box-${propOr('', 'id', box)}`}
        name={propOr('', 'title', box)}
        // @ts-ignore
        color={findTagColor(propOr('', 'student_book_id', box))}
        filter='custom_box_id'
        filterId={propOr('', 'id', box)}
      />
    ))

  const totalCount = pipe(
    // @ts-ignore
    map(pipe(propOr('0', 'flashcard_amount'), Number)),
    sum
    // @ts-ignore
  )(allCustomBoxes)

  return (
    <Row>
      <FlashcardsFilterBox count={totalCount} name='Custom' />
      {isSelected && <Divider />}
      {isSelected && <ChaptersWrapper>{renderCustomBoxes}</ChaptersWrapper>}
    </Row>
  )
}

export default FlashcardCustomBoxesRow

export const Row = styled.div`
  display: inline-flex;
  flex: none;
  gap: 16px;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
`

export const ChaptersWrapper = styled.div`
  display: inline-flex;
  flex: none;
  gap: 8px;
  flex-wrap: wrap;
  width: calc(100% - 140px);
  align-items: center;
`

const Divider = styled.div`
  width: 16px;
  height: 1px;
  background: ${({ theme }) => theme.colors.main.grey600};
`

import React, { useCallback, useState, memo, useEffect } from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InputField } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import {
  createCustomBox,
  addFlashcardToCustomBox
} from 'services/FlashcardsService'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { fetchCustomBoxesRoutine } from '../ducks/actions'
import { errors, getErrorName } from '../../../utils/errors'
import {
  validateCreateCustomBoxField,
  validateCreateCustomBoxValues
} from 'modules/flashcards/ducks/schema'
import { selectBookById } from '../../books/ducks/selectors'

import { Course } from 'types'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

const emptyValues = {
  title: ''
}

export const FormCreateAndAddToCustomBox = ({
  flashcard,
  handleClose
}): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [valid, _validateSchema] = React.useState(false)
  const [values, setValues] = React.useState(emptyValues)
  const [isLoading, setIsLoading] = useState(false)
  const flashcardBookId: string = propOr('', 'book_id', flashcard)
  const book = useSelector(state => selectBookById(state, flashcardBookId))
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const tag = propOr('', 'tag', book)

  useEffect(() => {
    setValues({
      title: `${tag}_`
    })
  }, [book])

  const handleOnChange = (name: string, value: any) =>
    setValues({ ...values, [name]: value })

  const fetchCustomBoxes = React.useCallback(
    () => dispatch(fetchCustomBoxesRoutine()),
    [dispatch]
  )

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  React.useEffect(() => {
    validateCreateCustomBoxValues(values, _validateSchema)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    const student_flashcard_id: string = propOr(
      '',
      'student_flashcard_id',
      flashcard
    )

    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.flashcardAddedToABox, {
      'Course type': currentCourse.type || false,
      'Course name': currentCourse.title || false,
      'Course expiration date': currentCourse?.accessible_to || false,
      'Flashcard Proficiency Level': flashcard?.proficiency_level || false,
      'Flashcard # id': flashcard?.id || false,
      'Method of adding to the box': 'Create new custom box'
    })

    setIsLoading(true)

    const handleError = e => {
      if (getErrorName(e) === errors.flashcardBoxExists) {
        showToast({
          key: 'toast.customBoxNameAlreadyExists',
          severity: SEVERITY.error,
          options: { title: values.title }
        })
      } else {
        showToast({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error,
          options: {}
        })
      }
      setIsLoading(false)
    }

    const handleSuccess = response => {
      fetchCustomBoxes()
      const boxId: string = propOr('', 'id', response.data)

      addFlashcardToCustomBox({
        box_id: boxId,
        student_flashcard_id
      })
        .then(() => {
          showToast({
            key: 'toast.addedToCustomBox',
            severity: SEVERITY.success,
            options: {
              title: values.title
            }
          })
          handleClose()
          setIsLoading(false)
        })
        .catch(handleError)
    }

    createCustomBox({
      title: values.title,
      student_book_id: flashcardBookId
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InputContainer>
        <InputField
          t={t}
          required
          value={values.title}
          name='title'
          id='create-custom-box-title'
          label={t('form.customBox.label')}
          onChange={handleOnChange}
          validate={validateCreateCustomBoxField(values)}
        />
      </InputContainer>
      <ButtonsContainer>
        <Button
          size='small'
          color='secondary'
          id='add-to-box-submit'
          isLoading={isLoading}
          disabled={isLoading || !valid}
          type='submit'
        >
          {t('flashcards.addToCustomBox.submitCTA')}
        </Button>
        <Button
          size='small'
          id='add-to-box-cancel'
          disabled={isLoading}
          color='tertiary'
          variant='contained'
          onClick={handleClose}
        >
          {t('flashcards.addToCustomBox.cancelCTA')}
        </Button>
      </ButtonsContainer>
    </FormContainer>
  )
}

export default memo(FormCreateAndAddToCustomBox)

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
const InputContainer = styled.div`
  width: 100%;
  max-width: 300px;
`

const ButtonsContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  button {
    min-width: 150px;
  }
`

import {
  studentFetchDetailsRoutine,
  updateBackgroundMusicSettingRoutine,
  updateCQAnimationsSettingRoutine,
  updateDashboardThemeRoutine
} from './actions'
import { Action, AuthReducer } from 'types'
import { API_STATES } from 'utils/redux'

export const initialState: AuthReducer = {
  state: API_STATES.PRISTINE,
  student: {
    external_id: '',
    id: '',
    email: '',
    name: '',
    created_at: '',
    is_active: false,
    is_impersonated: false,
    impersonated_by: {
      id: '',
      email: '',
      role: ''
    },
    has_books: false,
    has_exams: false,
    is_preview: false,
    preview_admin: {
      id: '',
      email: '',
      role: ''
    },
    role: '',
    phone_number: '',
    video_bg_music_enabled: true,
    cq_animations_enabled: true,
    theme: 'light'
  }
}

export default (state = initialState, action: Action): AuthReducer => {
  switch (action.type) {
    case studentFetchDetailsRoutine.REQUEST:
    case updateDashboardThemeRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case studentFetchDetailsRoutine.FAILURE:
    case updateDashboardThemeRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case studentFetchDetailsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        student: action.payload
      }
    case updateBackgroundMusicSettingRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case updateBackgroundMusicSettingRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case updateBackgroundMusicSettingRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        student: action.payload
      }
    case updateCQAnimationsSettingRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case updateCQAnimationsSettingRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case updateCQAnimationsSettingRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        student: action.payload
      }
    case updateDashboardThemeRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        student: action.payload
      }
    default:
      return state
  }
}

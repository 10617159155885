import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { StarIcon } from 'examkrackers-components'
import styled from 'styled-components'
import VideoRateSystem from 'modules/videos/components/VideoRateSystem'
import * as R from 'ramda'
import { fetchVideoRate } from 'services/VideosService'
import useOutsideClick from 'hooks/useOutsideClick'

import { getIsImpersonate } from 'modules/auth/ducks/selectors'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/videos/utils/events'

import { Course } from 'types'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

interface VideoRateButtonProps {
  rate: number
  videoId: string
  earningForVideoRating: number
  videoTitle: string | boolean
  videoCategory: string | boolean
  tags: string | boolean
  duration: string | boolean
  user: any
  currentCourse: Course
}

const VideoRateButton = (props: VideoRateButtonProps): JSX.Element => {
  const {
    rate,
    videoId,
    earningForVideoRating,
    videoTitle,
    videoCategory,
    tags,
    duration,
    user,
    currentCourse
  } = props
  const { t } = useTranslation()

  const [showRatingComponent, setShowRatingComponent] = useState(false)
  const [videoRate, setVideoRate] = useState(0)

  const isImpersonate = useSelector(getIsImpersonate)
  const isNotImpersonate = !isImpersonate

  const handleStarComponentClose = () =>
    eventEmitter.emit(events.rateToggle, false)
  const containerRef = useRef(null)
  useOutsideClick(containerRef, handleStarComponentClose)

  const fetchVideoRating = () => {
    const handleSuccess = response => {
      const rating = R.pathOr({}, ['data', 'rating'], response)
      // @ts-ignore
      setVideoRate(rating)
    }
    const handleError = e => console.error(e)

    fetchVideoRate({ videoId }).then(handleSuccess).catch(handleError)
  }

  const displayRate = () => {
    if (rate > 0) {
      return rate
    } else {
      return '-'
    }
  }
  const handleButtonClick = () => {
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.rateAVideo, {
      'Course type': currentCourse?.type || false,
      'Course name': currentCourse?.title || false,
      'Course expiration date': currentCourse?.accessible_to || false,
      'Video title': videoTitle || false,
      'Video category': videoCategory || false,
      'Book tag': tags,
      'Video lenght': duration
    })

    eventEmitter.emit(events.rateToggle, true)
    fetchVideoRating()
  }

  const handleRateComponent = isComponentOpened => {
    setShowRatingComponent(isComponentOpened)
  }

  useEffect(() => {
    eventEmitter.on(events.rateToggle, handleRateComponent)

    return () => {
      eventEmitter.off(events.rateToggle, handleRateComponent)
    }
  }, [])
  return (
    <RateButtonWrapper ref={containerRef}>
      <RatingButton
        onClick={handleButtonClick}
        showRatingComponent={showRatingComponent}
        isNotImpersonate={isNotImpersonate}
        disabled={!isImpersonate}
      >
        <IconWrapper>
          <StarIcon width='20px' height='20px' />
        </IconWrapper>
        <RateWrapper>{displayRate()}</RateWrapper>
        <IconText>{t('videos.rating.button')}</IconText>
      </RatingButton>
      {showRatingComponent && isNotImpersonate && (
        <VideoRateSystem
          earningForVideoRating={earningForVideoRating}
          videoId={videoId}
          videoRate={videoRate}
        />
      )}
    </RateButtonWrapper>
  )
}

export default VideoRateButton

const RatingButton = styled.div`
  width: 157px;
  height: 32px;
  color: ${({ theme }) => theme.colors.main.text};
  border-radius: 4px;
  :hover {
    cursor: ${({ isNotImpersonate }) =>
      isNotImpersonate ? 'pointer' : 'not-allowed'};
    background-color: ${({ theme, isNotImpersonate }) =>
      isNotImpersonate ? theme.colors.main.grey300 : 'inherit'};
  }
  background-color: ${({ showRatingComponent, isNotImpersonate, theme }) =>
    showRatingComponent && isNotImpersonate && theme.colors.main.grey300};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
  color: ${({ theme }) => theme.colors.dashboard.questions.correctBarVia};
`
const RateWrapper = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.main.black};
`
const IconText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`
const RateButtonWrapper = styled.div`
  position: relative;
`

import React from 'react'
import { useSelector } from 'react-redux'
import { selectNotesList } from 'modules/notes/ducks/selectors'
import styled from 'styled-components'
import { propOr, pipe, filter, any, propEq, head, find } from 'ramda'

import { useHistory } from 'react-router-dom'
import qs from 'qs'

import { Tag } from 'examkrackers-components'

import { selectAllBooks } from 'modules/books/ducks/selectors'
import { Book } from 'types'

const Section = ({ book, activeTab, sectionsWithPinNotes }) => {
  const { location, push } = useHistory()
  const { pathname, search } = location
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const books: Book[] = useSelector(selectAllBooks)

  const sectionsWithPinNotesData: [] = propOr([], 'data', sectionsWithPinNotes)
  const isActiveTabNotes = activeTab === 'notes'
  const isActiveTabPins = activeTab === 'pins'
  const currentNotesSections = useSelector(selectNotesList)
  const handleOnClick = (
    sectionId,
    chapterOrder,
    partOrder,
    bookId,
    isAlreadySelected
  ) => {
    const newQuery = qs.stringify({
      ...parsedQuery,
      sectionId,
      chapterOrder,
      partOrder,
      bookId
    })
    push(`${pathname}?${newQuery}`)
  }

  return (
    <>
      {isActiveTabPins ? (
        <>
          {sectionsWithPinNotesData.map(section => {
            const sectionId = propOr('', 'subchapter_id', section)
            const isAlreadySelected =
              propOr('', 'sectionId', parsedQuery) === sectionId
            const tagColor = propOr('grey', 'tag_colour', section)
            const bookTag = propOr('', 'tag', section)
            const chapterOrder = propOr('', 'chapter_order', section)
            const sectionOrder = propOr('', 'subchapter_order', section)
            const partOrder = propOr('', 'part', section)
            const sectionTitle = propOr('', 'subchapter_title', section)
            const bookId = propOr('', 'book_id', section)
            const pinCount = propOr('0', 'pin_count', section)

            return (
              <SectionTitleWrapper
                // @ts-ignore
                active={isAlreadySelected}
                key={`button-chapter-${sectionId}`}
                onClick={() => {
                  // @ts-ignore
                  handleOnClick(
                    sectionId,
                    chapterOrder,
                    partOrder,
                    bookId,
                    isAlreadySelected
                  )
                }}
              >
                <TagWrapper>
                  <Tag
                    onClick={() => {}}
                    // @ts-ignore
                    text={bookTag}
                    // @ts-ignore
                    color={tagColor}
                  />
                </TagWrapper>
                <EllipsisTitle>
                  {' '}
                  ({pinCount}) {chapterOrder}.{sectionOrder} {sectionTitle}
                </EllipsisTitle>
              </SectionTitleWrapper>
            )
          })}
        </>
      ) : null}
      {isActiveTabNotes ? (
        <>
          {currentNotesSections.map(section => {
            const isAlreadySelected =
              propOr('', 'sectionId', parsedQuery) === section.id
            const chapterId = propOr('', 'chapter_id', section)
            const hasChapter = pipe(
              // @ts-ignore
              filter(
                pipe(propOr([], 'chapters'), any(propEq('id', chapterId)))
              ),
              head
              // @ts-ignore
            )(books)
            const bookId = propOr('', 'book_id', hasChapter)
            const bookChapters = propOr('', 'chapters', hasChapter)

            const chapterOrder = pipe(
              find(pipe(propEq('id', chapterId))),
              propOr('', 'order')
              // @ts-ignore
            )(bookChapters)
            const redirectToProperSection = section => {
              push(
                `/books/${bookId}/chapter/${chapterOrder}/part/${section.part}?sectionIdContext=${section.id}&rightBottomActive=notes`
              )
            }
            const tagColor = propOr('grey', 'tag_colour', hasChapter)
            const bookTag = propOr('', 'tag', hasChapter)
            return (
              <SectionTitleWrapper
                active={isAlreadySelected}
                key={`button-chapter-${section.id}`}
                onClick={() => {
                  // @ts-ignore
                  handleOnClick(section.id, isAlreadySelected)
                }}
              >
                <TagWrapper>
                  <Tag
                    onClick={() => redirectToProperSection(section)}
                    // @ts-ignore
                    text={bookTag}
                    // @ts-ignore
                    color={tagColor}
                  />
                </TagWrapper>
                <EllipsisTitle>
                  {' '}
                  {chapterOrder}.{section.order} {section.title}
                </EllipsisTitle>
              </SectionTitleWrapper>
            )
          })}
        </>
      ) : null}
    </>
  )
}

export default Section

const SectionTitleWrapper = styled.div`
  width: 522px;
  height: 46px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  background: ${({ theme, active }) =>
    active ? theme.colors.main.primary200 : theme.colors.backgrounds.main};
  margin-bottom: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin: 12px 0px 0px 16px;
  transition: all 0.3s;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.main.primary200};
  }
`
const EllipsisTitle = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`
const TagWrapper = styled.div`
  padding-right: 16px;
`

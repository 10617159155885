import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import styled from 'styled-components'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/diagnostics/utils/events'

import { isNotNilOrEmpty } from 'utils/ramda'

import TimeChartExamSectionDiagnostics from 'modules/diagnostics/components/TimeChartExamSectionDiagnostics'
import TopTimeChartExamSectionDiagnostics from 'modules/diagnostics/components/TopTimeChartExamSectionDiagnostics'
import DifficultyChartExamSectionDiagnostics from 'modules/diagnostics/components/DifficultyChartExamSectionDiagnostics'
import LegendExamSectionDiagnostics from 'modules/diagnostics/components/LegendExamSectionDiagnostics'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import {
  fetchAverageQuestionsGraphDataRoutine,
  fetchPassageReadingTimeGraphRoutine,
  fetchPassageWorkingTimeGraphRoutine,
  fetchQuestionsGraphDataRoutine,
  fetchAveragePassagesGraphDataRoutine
} from 'modules/diagnostics/ducks/actions'

import { getExamDetails } from 'modules/exam/ducks/selectors'
import { getQuestionsData } from '../ducks/selectors'
import { BouncingLoader } from 'examkrackers-components'

export const TabExamSectionDiagnostics = (): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()
  const { push } = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 3500)
  }, [])

  const id: string = R.propOr('', 'id')(params)
  const sectionId: string = R.propOr('', 'sectionId')(params)

  const examDetails = useSelector(getExamDetails)
  const exam_id = R.pathOr('', ['exam', 'exam_id'])(examDetails)
  const sectionOrder = R.pipe(
    R.propOr([], 'questions'),
    R.find(R.propEq('id', sectionId)),
    R.propOr(0, 'order')
  )(examDetails)

  const questionsData = useSelector(getQuestionsData)

  const fetchExamDetails = React.useCallback(
    () => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  const fetchPassageReadingTimeGraph = React.useCallback(
    () => dispatch(fetchPassageReadingTimeGraphRoutine({ id: sectionId })),
    [dispatch]
  )

  const fetchPassageWorkingTimeGraph = React.useCallback(
    () => dispatch(fetchPassageWorkingTimeGraphRoutine({ id: sectionId })),
    [dispatch]
  )

  const fetchQuestionsGraphData = React.useCallback(
    () => dispatch(fetchQuestionsGraphDataRoutine({ id: sectionId })),
    [dispatch]
  )

  const fetchAverageQuestionsGraphData = React.useCallback(
    (exam_id: string, section_order: unknown) =>
      dispatch(
        fetchAverageQuestionsGraphDataRoutine({ exam_id, section_order })
      ),
    [dispatch]
  )

  const fetchAveragePassagesGraphData = React.useCallback(
    (exam_id: string, section_order: unknown) =>
      dispatch(
        fetchAveragePassagesGraphDataRoutine({ exam_id, section_order })
      ),
    [dispatch]
  )

  const handleQuestionClick = questionNumber => {
    const questionId = R.pipe(
      R.find(R.propEq('order', questionNumber)),
      R.propOr('', 'id')
    )(questionsData)
    const explanationPath = `/exam/${id}/explanations/${questionId}`

    return push(explanationPath)
  }

  React.useEffect(() => {
    eventEmitter.on(events.questionClick, handleQuestionClick)

    return () => {
      eventEmitter.off(events.questionClick, handleQuestionClick)
    }
  }, [questionsData])

  React.useEffect(() => {
    fetchExamDetails()
    fetchPassageReadingTimeGraph()
    fetchPassageWorkingTimeGraph()
    fetchQuestionsGraphData()
  }, [])

  React.useEffect(() => {
    if (isNotNilOrEmpty(exam_id)) {
      fetchAverageQuestionsGraphData(exam_id, sectionOrder)
      fetchAveragePassagesGraphData(exam_id, sectionOrder)
    }
  }, [examDetails])

  return (
    <Container loading={loading}>
      <LoadingContainer loading={loading}>
        <BouncingLoader />
      </LoadingContainer>
      <ChartContainer loading={loading}>
        <TopTimeChartExamSectionDiagnostics />
        <TimeChartExamSectionDiagnostics />
        <DifficultyChartExamSectionDiagnostics />
      </ChartContainer>
      <LegendContainer loading={loading}>
        <LegendExamSectionDiagnostics />
      </LegendContainer>
    </Container>
  )
}

export default TabExamSectionDiagnostics

const Container = styled.div`
  max-height: 584px;
  height: calc(100vh - 140px);
  display: flex;
  gap: 16px;
  position: relative;
  overflow: ${({ loading }) => (loading ? 'hidden' : 'unset')};
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.menu};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  animation: timed-out-opacity 3500ms ease-in;
  display: ${({ loading }) => (loading ? 'flex' : 'none')};

  @keyframes timed-out-opacity {
    0% {
      opacity: 1;
      display: flex;
    }
    70% {
      opacity: 1;
      display: flex;
    }
    99% {
      opacity: 0;
      display: flex;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`

const ChartContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  * {
    font-family: ${({ theme }) => theme.typography.fontFamily} !important;
  }

  .apexcharts-gridlines-vertical {
    line {
      visibility: hidden;
    }
  }

  line.apexcharts-gridline.grid-hover-active {
    visibility: visible !important;
    stroke-dasharray: 0;
    stroke-width: 3px;
    stroke-opacity: 1;
    stroke: ${({ theme }) => theme.colors.main.text};
    z-index: 100;
  }

  .apexcharts-tooltip {
    border: none !important;
    box-shadow: none;
    border-radius: unset;
    background: transparent !important;
    overflow: visible !important;
  }

  .tooltip-question-view {
    background: ${({ theme }) => theme.colors.main.text};
    color: ${({ theme }) => theme.colors.main.white};
    padding: 4px 8px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 11px;
    border-radius: 4px;
    position: relative;
  }

  .apexcharts-xcrosshairs {
    display: none;
  }

  .apexcharts-marker {
    visibility: hidden;
  }

  .apexcharts-xaxis {
    stroke-opacity: 0;
  }

  .apexcharts-gridlines-horizontal .apexcharts-gridline,
  .apexcharts-gridlines-horizontal .apexcharts-gridline {
    stroke-opacity: 0;
  }

  .apexcharts-gridlines-horizontal .apexcharts-gridline:first-child,
  .apexcharts-gridlines-horizontal .apexcharts-gridline:last-child {
    stroke-opacity: 1;
  }

  .apexcharts-xaxis-label {
    visibility: hidden;
  }
`

const LegendContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  height: 100%;
  width: 288px;
  flex: none;
`

import APIService from 'services/APIService'
import { FetchBookErratasPayload, FetchExamErratasPayload } from 'types/errata'

const api = APIService()

export const getBookErratas = (payload: FetchBookErratasPayload) =>
  api.get(
    `/book-erratas/book/${payload.bookId}?order[by]=created_at&order[dir]=desc&limit[page]=1&limit[take]=10`
  )

export const getExamErratas = (payload: FetchExamErratasPayload) =>
  api.get(
    `/exam-erratas/exam/${payload.examId}?order[by]=created_at&order[dir]=desc&limit[page]=1&limit[take]=10`
  )

export const getAllExamErrata = () =>
  api.get(
    `exam-erratas?order[by]=created_at&order[dir]=desc&limit[page]=1&limit[take]=1000`
  )

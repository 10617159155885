import {
  map,
  applySpec,
  pipe,
  prop,
  propOr,
  pathOr,
  multiply,
  juxt,
  flatten,
  find,
  propEq
} from 'ramda'
import { ExamSectionDetails, ExamQuestionDetails } from 'types'

const getQuestionPageIds = pipe(
  propOr([], 'questions'),
  map((question: ExamQuestionDetails) => prop('id', question))
)

const getSectionPageIds = pipe(prop('id'), Array.of)

const getReviewPageId = pipe(
  (section: ExamSectionDetails) => prop('id', section),
  Array.of,
  id => `section-review-${id}`
)

export const getTimeConfigBySection = (
  sectionIdsWithCurrentTime,
  timeOptionMultiplier
) =>
  map(
    applySpec({
      pageIds: pipe(
        juxt([getQuestionPageIds, getSectionPageIds, getReviewPageId]),
        flatten
      ),
      startSeconds: sectionDetails => {
        const sectionId = propOr('', 'id', sectionDetails)
        const defaultTime = pipe(
          pathOr(0, ['sectionLength', 'sectionMinutes']),
          multiply(60),
          multiply(timeOptionMultiplier)
        )(sectionDetails)

        return pipe(
          find(propEq('section_id', sectionId)),
          propOr(defaultTime, 'seconds_left')
        )(sectionIdsWithCurrentTime)
      }
    })
  )

export const getSavedTimeOrDefault = (
  sectionIdsWithCurrentTime,
  sectionId,
  secondsLeft
) =>
  map((config: { section_id: string; seconds_left: number }) =>
    applySpec({
      section_id: c => propOr('', 'section_id', c),
      seconds_left: timeConfig =>
        timeConfig.section_id === sectionId
          ? secondsLeft
          : timeConfig.seconds_left
    })(config)
  )(sectionIdsWithCurrentTime)

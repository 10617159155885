import React, { useCallback, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import * as R from 'ramda'
import {
  Button,
  Heading1,
  WarningReversed,
  BouncingLoader
} from 'examkrackers-components'

import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { AuthContainer } from './Auth'
import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIsAuthorised,
  getStudent,
  isAuthLoaded
} from '../modules/auth/ducks/selectors'
import PATHS from 'utils/paths'

export const AuthImpersonation = (): JSX.Element => {
  const [error, setError] = React.useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const params = useParams()
  const history = useHistory()
  const token = R.propOr('', 'token', params)

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )

  const handleImpersonate = async () => {
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.token)
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)

    try {
      LocalStorageService.set(LOCAL_STORAGE_KEYS.token, token)
      fetchStudentDetails()
    } catch (e) {
      console.error(e)
      setError(true)
    }
  }

  const isAuthorised = useSelector(getIsAuthorised)
  const isAuthFetched = useSelector(isAuthLoaded)
  const student = useSelector(getStudent)

  const hasCourses =
    R.propOr(false, 'has_courses', student) ||
    R.propOr(false, 'has_books', student)

  useEffect(() => {
    if (isAuthorised && isAuthFetched) {
      const redirectPath = hasCourses ? PATHS.selectCourse : PATHS.exams
      history.push(redirectPath)
    }
  }, [student])

  const handleBack = () => history.goBack()

  React.useEffect(() => {
    handleImpersonate()
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.auth.impersonation')}</title>
      </Helmet>
      <AuthContainer>
        {error ? (
          <>
            <WarningReversed />
            <div className='title'>
              <Heading1>{t('auth.error.title')}</Heading1>
              <Heading1>{t('auth.error.subtitle')}</Heading1>
            </div>
            <ButtonContainer>
              <Button type='button' id='go-back-btn' onClick={handleBack}>
                {t('auth.goBack')}
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <StyledBouncingLoader />
            <div className='title'>
              <Heading1>{t('auth.title')}</Heading1>
              <Heading1>{t('auth.subtitle')}</Heading1>
            </div>
          </>
        )}
      </AuthContainer>
    </React.Fragment>
  )
}

const ButtonContainer = styled.div`
  margin-top: 30px;
`

const StyledBouncingLoader = styled(BouncingLoader)`
  margin-right: 20px;
`

export default AuthImpersonation

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'

import ApexChart from 'react-apexcharts'
import { TimeChart } from 'modules/learningTime/utils/TimeChart'
import useOutsideClick from 'hooks/useOutsideClick'

import { fetchStudyTimeData } from 'services/DashboardService'
import {
  // Button,
  CloseIcon,
  IconButton,
  Loader,
  NavArrowLeftIcon,
  NavArrowRightIcon
} from 'examkrackers-components'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { useSelector } from 'react-redux'
import { getIsImpersonate } from 'modules/auth/ducks/selectors'

type LearningTimeChartData = {
  dates: string[]
  data: []
}

export const LearningTimeChart = ({ isOpened, handleIsClose, isFreeTrial }) => {
  const [data, setData] = useState<LearningTimeChartData>({
    data: [],
    dates: []
  })
  const [currentPage, setCurrentPage] = useState(3)
  const containerRef = useRef(null)
  const isImpersonate = useSelector(getIsImpersonate)

  const weeksLength = R.pipe(
    R.propOr([], 'data'),
    R.head,
    R.propOr([], 'data'),
    // @ts-ignore
    R.length
    // @ts-ignore
  )(data)

  const setLabelsForWeek = (
    weekday,
    chart,
    chartTotalLeftOffset,
    topOffset
  ) => {
    // Finds bar for specific week
    const weekdayBars = document.querySelector(
      `#learning-time-chart g[seriesName='${weekday}']`
    )

    // here for each bar a span is created with given week day and positioned absolutely to the chart container
    if (weekdayBars) {
      const barsList = Array.from(weekdayBars.children).filter(el =>
        // @ts-ignore
        R.includes('apexcharts-bar-area', el.classList)
      )
      const firstLetterofWeekday = weekday.slice(0, 1)
      barsList.forEach((bar, index) => {
        const barLabel = document.createElement('div')
        const barLabelText = document.createTextNode(firstLetterofWeekday)
        const barTotalLeftOffset = bar.getBoundingClientRect().left
        const barLeftOffset = barTotalLeftOffset - chartTotalLeftOffset

        // @ts-ignore
        barLabel.style.cssText = `position: absolute; left: ${
          barLeftOffset - 4 + 'px'
        }; top: ${topOffset}px; width: ${'29px'}; font-size: ${'12px'};`
        barLabel.className = 'x-axis-dynamic-custom-labels'

        barLabel.appendChild(barLabelText)
        // @ts-ignore
        chart.appendChild(barLabel)
      })
    }
  }

  const setLabels = () => {
    const chart = document.getElementById('learning-time-chart')
    const chartXAxis = document.querySelector(
      '#learning-time-chart .apexcharts-xaxis'
    )
    // @ts-ignore
    const chartTotalLeftOffset = chart.getBoundingClientRect().left
    const topOffset =
      // @ts-ignore
      chartXAxis.getBoundingClientRect().top - chart.getBoundingClientRect().top

    setLabelsForWeek('Sun', chart, chartTotalLeftOffset, topOffset)
    setLabelsForWeek('Mon', chart, chartTotalLeftOffset, topOffset)
    setLabelsForWeek('Tue', chart, chartTotalLeftOffset, topOffset)
    setLabelsForWeek('Wed', chart, chartTotalLeftOffset, topOffset)
    setLabelsForWeek('Thu', chart, chartTotalLeftOffset, topOffset)
    setLabelsForWeek('Fri', chart, chartTotalLeftOffset, topOffset)
    setLabelsForWeek('Sat', chart, chartTotalLeftOffset, topOffset)
  }

  const timeSpendOnLearningChart = TimeChart(data)

  const itemsPerPage = 4

  const dataPool =
    // @ts-ignore
    weeksLength > 12
      ? timeSpendOnLearningChart.series.map(dayOfWeek => {
          return { ...dayOfWeek, data: dayOfWeek.data.slice(1, 13) }
        })
      : timeSpendOnLearningChart.series

  const currentData = dataPool.map(item => {
    const start = () => {
      if (currentPage === 1) {
        return 0
      }
      if (currentPage === 2) {
        return 4
      }
      if (currentPage === 3) {
        return 8
      }
      return 0
    }
    const end = start() + itemsPerPage

    return {
      ...item,
      data: item.data.slice(start(), end)
    }
  })
  const datesData =
    data && data.dates.length === 13 ? data.dates.slice(1, 13) : data.dates

  const formattedDates = datesData.map(date => {
    const [year, month, day] = date.split('-')
    const shortYear = year.slice(2)
    return [month, day, shortYear].join('/')
  })
  const firstDate = () => {
    if (isNilOrEmpty(data.dates)) {
      return ''
    }
    const [year, month, day] = data.dates[0].split('-')
    const shortYear = year.slice(2)
    return [month, day, shortYear].join('/')
  }
  const [, , , fourth, , , , eigth, , , , twelfth] = formattedDates
  const firstRange = `${firstDate()} - ${fourth}`
  const secondRange = `${fourth} - ${eigth}`
  const thirdRange = `${eigth} - ${twelfth}`

  useOutsideClick(containerRef, handleIsClose)

  const handleRange = () => {
    if (currentPage === 1) {
      return firstRange
    }
    if (currentPage === 2) {
      return secondRange
    }
    if (currentPage === 3) {
      return thirdRange
    }
    return firstRange
  }

  useEffect(() => {
    if (isOpened) {
      fetchStudyTimeData()
        .then(result => setData(result.data))
        .catch(console.error)
    }
  }, [isOpened])

  useEffect(() => {
    if (isOpened && isNotNilOrEmpty(data)) {
      setTimeout(setLabels, 1000)
    }
  }, [isOpened])

  const GraphPlaceholder = data && (
    <ChartContainer id='learning-time-chart' ref={containerRef}>
      {/* @ts-ignore */}
      <ApexChart
        id='learning-time-chart-svg'
        // @ts-ignore
        options={timeSpendOnLearningChart}
        series={currentData}
        type='bar'
        width='802px'
        height='273px'
      />
      <ButtonsContainer>
        <IconButton
          icon={<NavArrowLeftIcon />}
          variant='filled'
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          size='small'
        />

        <DateRange>
          {isNilOrEmpty(data.dates) ? <Loader /> : handleRange()}
        </DateRange>

        <IconButton
          type='button'
          icon={<NavArrowRightIcon />}
          variant='filled'
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === 3}
          size='small'
        />
      </ButtonsContainer>
      <div onClick={handleIsClose} className='learning-time-close'>
        <CloseIcon />
      </div>
    </ChartContainer>
  )

  return isOpened ? (
    <Container isFreeTrial={isFreeTrial} isImpersonate={isImpersonate}>
      <AlertWrapper>{GraphPlaceholder}</AlertWrapper>
    </Container>
  ) : null
}

export default LearningTimeChart

const Container = styled.div`
  position: fixed;
  top: ${({ isFreeTrial, isImpersonate }) =>
    isFreeTrial || isImpersonate ? '96px' : '64px'};
  left: -16px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.mainMenu + 10};

  .learning-time-close {
    position: absolute;
    right: 12.5px;
    top: 12.5px;
    cursor: pointer;
    font-size: 9px;
  }
`

const AlertWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
const ChartContainer = styled.div`
  // this is needed for positioning labels that are append with JS code on mount
  position: relative !important;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.chartShadow};
  overflow: hidden;

  // hides original X axis labels - but they are needed to keep the X axis area
  .xaxis-labels {
    opacity: 0;
  }
  .x-axis-dynamic-custom-labels {
    display: inline-block;
    color: ${({ theme }) => theme.colors.main.grey600};
    font-size: 12px;
    text-align: center;
  }
  .apexcharts-gridline {
    stroke: ${({ theme }) => theme.colors.dashboard.chartsGridLine};
  }

  .apexcharts-bar-area:hover {
    fill: ${({ theme }) => theme.colors.main.secondary500};
  }

  #learning-time-chart-svg {
    g.apexcharts-datalabels {
      transform: translateX(40px);
    }
  }

  .apexcharts-data-labels {
    transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};
    opacity: 0;
  }
`
const ButtonsContainer = styled.div`
  position: absolute;
  top: 40px;
  /* right: 40px; */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  /* button {
    min-width: 170px;
  } */
`

const DateRange = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.main.primary700};
`

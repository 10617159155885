import React, { useContext } from 'react'
import { format, isAfter, isBefore, isSameDay, parseISO } from 'date-fns'
import { getMonthNumberByName } from 'utils/calendar'
import { Tooltip } from 'examkrackers-components'
import Holidays from 'date-holidays'
import styled, { css } from 'styled-components'
import { isDateWithinInterval } from 'utils/date'

import { getVisibleDates } from './ducks/selectors'
import { useSelector } from 'react-redux'
import { CalendarContext } from 'hooks/CalendarContext'

const LeftColumnCalendarRow = ({ row, rowIndex, year, month }) => {
  const { calendar, course } = useContext(CalendarContext)
  const visibleDates = useSelector(getVisibleDates)

  const finalDate = calendar?.exam_at || ''
  const startDate = calendar?.start_at || ''
  const mcatDate = calendar?.mcat_at || ''

  const hd = new Holidays('US')
  const getHolidays = hd.getHolidays()

  const checkHoliday = date => {
    const holiday = getHolidays.find(
      h =>
        isSameDay(new Date(h.start), date) &&
        ['public', 'bank'].includes(h.type)
    )
    return holiday ? { ...holiday } : null
  }

  const isHoliday = date => {
    return !!checkHoliday(date)
  }

  const scrollToDay = date => {
    const parsedDate = parseISO(date)
    const startDatePlusOneDay = new Date(
      new Date(startDate).setDate(new Date(startDate).getDate() + 1)
    )

    const mcatDateMinusOneDay = new Date(
      new Date(mcatDate).setDate(new Date(mcatDate).getDate() - 1)
    )

    const startDateToString = format(startDatePlusOneDay, 'yyyy-MM-dd')
    const mcatDateToString = format(mcatDateMinusOneDay, 'yyyy-MM-dd')

    if (!date) return
    if (isBefore(parsedDate, startDatePlusOneDay)) {
      const startDateTableCell = document.getElementById(
        `table-cell-${startDateToString}`
      )

      if (startDateTableCell) {
        startDateTableCell.scrollIntoView({
          behavior: 'smooth'
        })
        return
      }
    }
    if (isAfter(parsedDate, mcatDateMinusOneDay)) {
      const mcatDateTableCell = document.getElementById(
        `table-cell-${mcatDateToString}`
      )

      if (mcatDateTableCell) {
        return mcatDateTableCell.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
    const element = document.getElementById(`table-cell-${date}`)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  return (
    <tr key={rowIndex}>
      {row.map((day, index) => {
        const today = format(new Date(), 'yyyy-MM-dd')
        const currentlyChecked = `${year}-${getMonthNumberByName(month)}-${day
          .toString()
          .padStart(2, '0')}`
        const isStartDate =
          new Date(currentlyChecked).toDateString() ==
          new Date(startDate).toDateString()

        const dayHolidays = [checkHoliday(new Date(currentlyChecked))]
        const isHolidayToday = isHoliday(new Date(currentlyChecked))

        const isFinalDate = isSameDay(
          new Date(finalDate),
          new Date(currentlyChecked)
        )
        const isMcatDate = isSameDay(
          new Date(mcatDate),
          new Date(currentlyChecked)
        )

        const courseStartDate = new Date(course.accessible_from as string)
        const courseEndDate = new Date(course.accessible_to as string)

        const isWithin = isDateWithinInterval(new Date(currentlyChecked), {
          start: courseStartDate,
          end: courseEndDate
        })

        const isBetweenMonths =
          day === '' &&
          parseInt(getMonthNumberByName(month)) >
            new Date(startDate).getMonth() + 1 &&
          parseInt(getMonthNumberByName(month)) <
            new Date(finalDate).getMonth() + 2

        const isToday = today === currentlyChecked

        const generateTooltipContent = () => {
          if (isStartDate) {
            return 'Start Date'
          }
          if (isToday) {
            return "Today's Date"
          }
          if (isFinalDate) {
            return 'End Date'
          }
          if (isMcatDate) {
            return 'MCAT Date'
          }

          return ''
        }
        const isVisible = (visibleDates as string[]).includes(
          `${year}-${getMonthNumberByName(month)}-${day
            .toString()
            .padStart(2, '0')}`
        )

        return (
          <TableCell
            data-testid={`LeftColumnCalendarRow-TableCell-${day}`}
            key={`left-table-cell-${currentlyChecked.replace(
              '00',
              `00${index}`
            )}`}
            disabled={(!isFinalDate && !isWithin) || isFinalDate || isMcatDate}
            isStartDate={isStartDate}
            isToday={isToday}
            isFinalDate={isFinalDate}
            isMcatDate={isMcatDate}
            isBetweenMonths={isBetweenMonths}
            day={day}
            isVisible={isVisible}
            onClick={() =>
              scrollToDay(currentlyChecked.replace('00', `00${index}`))
            }
          >
            {isMcatDate || isFinalDate || isStartDate || isToday ? (
              <StyledTooltip
                data-testid={`LeftColumnCalendarRow-StyledTooltip-${day}`}
                id={`left-table-cell-${currentlyChecked.replace(
                  '00',
                  `00${index}`
                )}`}
                tooltipContent={generateTooltipContent() as string}
              >
                <DayWrapper
                  today={today === currentlyChecked}
                  data-testid={`LeftColumnCalendarRow-DayWrapper-${day}`}
                >
                  <CellHeader
                    data-testid={`LeftColumnCalendarRow-CallHeader-${day}`}
                  >
                    {isHolidayToday ? (
                      <StyledTooltip
                        data-testid={`LeftColumnCalendarRow-StyledTooltip-isHolidayToday-${day}`}
                        tooltipContent={dayHolidays[0]?.name as string}
                        id={`${currentlyChecked}-left-table-cell`}
                      >
                        <DayNumber
                          data-testid={`LeftColumnCalendarRow-DayNumber-isHolidayToday-${day}`}
                          today={today === currentlyChecked}
                          holiday={isHolidayToday}
                          isMcatDate={isMcatDate}
                          disabled={!isFinalDate && !isWithin}
                        >
                          {day}
                        </DayNumber>
                      </StyledTooltip>
                    ) : (
                      <DayNumber
                        data-testid={`LeftColumnCalendarRow-DayNumber-!isHolidayToday-${day}`}
                        today={today === currentlyChecked}
                        isMcatDate={isMcatDate}
                        disabled={!isFinalDate && !isWithin}
                      >
                        {day}
                      </DayNumber>
                    )}
                    {day !== '' ? (
                      <Strikethrough
                        today={today}
                        date={currentlyChecked}
                        data-testid={`LeftColumnCalendarRow-Strikethrough-${day}`}
                      />
                    ) : null}
                  </CellHeader>
                </DayWrapper>
              </StyledTooltip>
            ) : (
              <DayWrapper
                today={today === currentlyChecked}
                data-testid={`LeftColumnCalendarRow-DayWrapper-${day}`}
              >
                <CellHeader>
                  {isHolidayToday ? (
                    <Tooltip
                      data-testid={`LeftColumnCalendarRow-Tootip-isHolidayToday-${day}`}
                      tooltipContent={dayHolidays[0]?.name as string}
                      id={`${currentlyChecked}-left-table-cell`}
                    >
                      <DayNumber
                        data-testid={`LeftColumnCalendarRow-DayNumber-isHolidayToday-${day}`}
                        today={today === currentlyChecked}
                        holiday={isHolidayToday}
                        isMcatDate={isMcatDate}
                        disabled={!isFinalDate && !isWithin}
                      >
                        {day}
                      </DayNumber>
                    </Tooltip>
                  ) : (
                    <DayNumber
                      data-testid={`LeftColumnCalendarRow-DayNumber-!isHolidayToday-${day}`}
                      today={today === currentlyChecked}
                      isMcatDate={isMcatDate}
                      disabled={!isFinalDate && !isWithin}
                    >
                      {day}
                    </DayNumber>
                  )}
                  {day !== '' ? (
                    <Strikethrough
                      today={today}
                      date={currentlyChecked}
                      data-testid={`LeftColumnCalendarRow-Strikethrough-${day}`}
                    />
                  ) : null}
                </CellHeader>
              </DayWrapper>
            )}
          </TableCell>
        )
      })}
    </tr>
  )
}

export default LeftColumnCalendarRow

const TableCell = styled.td<{
  disabled: boolean
  isFinalDate: boolean
  today: boolean
  isMcatDate: boolean
  isVisible: boolean
}>`
  text-align: center !important;
  position: relative;
  transition: all 0.1s ease-in-out;
  vertical-align: top;
  background-color: white;
  cursor: pointer;

  ${({
    disabled,
    isFinalDate,
    isMcatDate,
    theme,
    isStartDate,
    isToday,
    isBetweenMonths,
    day
  }) => {
    if (isFinalDate) {
      return css`
        outline: 1.5px solid #e83e2e;
        border-radius: 6px;
        z-index: 1;
      `
    } else if (isMcatDate) {
      return css`
        border-radius: 6px;
        overflow: hidden;
        background-color: ${theme?.colors?.calendar.exam.orange.tag};
      `
    } else if (isStartDate) {
      return css`
        border-radius: 6px;
        outline: 1.5px solid #119b4c;
        z-index: 1;
      `
    } else if (isToday) {
      return css`
        border-radius: 6px;
        outline: 1.5px solid #42210b;
        z-index: 1;
      `
    } else if (day === '' && disabled) {
      return 'background-color: #fff;'
    } else if (isBetweenMonths && disabled) {
      return css`
        background-color: #fff;
        cursor: unset !important;
      `
    } else if (disabled) {
      return css`
        background-color: #f2f2f2;
      `
    } else {
      return css`
        background-color: white;
      `
    }
  }};

  ${({ isVisible, isMcatDate }) =>
    isVisible && !isMcatDate
      ? css`
          ::after {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            right: 0;
            bottom: 8px;
            background-color: #f2d34e;
            opacity: 0.25;
            z-index: 1;
          }
        `
      : null}

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.colors.main.primary200};
  }
`

const DayWrapper = styled.div`
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`

const CellHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
`

const DayNumber = styled.div<{
  today: string
  holiday: string
  isMcatDate: boolean
  disabled: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 400;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.main.grey600 : theme.colors.main.grey900};
  padding: 3px;

  ${({ today, holiday, isMcatDate }) => {
    if (today && holiday) {
      return css`
        background-color: red;
        color: #fff;
        border-radius: 100%;
        width: 16.5px;
      `
    } else if (isMcatDate) {
      return css`
        color: #fff;
      `
    } else if (holiday) {
      return css`
        color: red;
      `
    }
  }}
`

// const FinalDateCell = styled.div`
//   position: absolute;
//   /* top: 14px;
//   left: 6px;
//   height: 20px;
//   width: 20px; */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: bold;
//   font-size: 8px;
// `

const Strikethrough = styled.div`
  display: ${({ today, date }) => (date < today ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 0.5px;
  background-color: #949596;
  transform: translate(-50%, -50%) rotate(-45deg);
`

const StyledTooltip = styled(Tooltip)`
  cursor: pointer !important;
  /* display: flex; */
  text-align: center !important;
`

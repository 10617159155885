import * as R from 'ramda'
import { createSelector } from 'reselect'

export const selectLearningTime = state => state.learningTime

export const getLearningTimeDetails = createSelector(
  R.pathOr({}, ['learningTime', 'details']),
  R.identity
)

export const getLearningTimePassedSeconds = createSelector(
  R.pathOr(0, ['learningTime', 'passedTime']),
  R.identity
)

export const getInactiveTimePassedSeconds = createSelector(
  R.pathOr(0, ['learningTime', 'inactiveTime']),
  R.identity
)

export const getChapterReadingTimePassedSeconds = createSelector(
  R.pathOr(0, ['learningTime', 'chapterReadingTime']),
  R.identity
)

export const getFlashcardsLearningTimePassedSeconds = createSelector(
  selectLearningTime,
  (state, flashcardId) => flashcardId,
  (learningTime, flashcardId) =>
    R.pathOr(0, ['flashcardLearningTime', flashcardId])(learningTime)
)

export const getVideoWatchingTimePassedSeconds = createSelector(
  selectLearningTime,
  (state, videoId) => videoId,
  (learningTime, videoId) =>
    R.pathOr(0, ['videoWatchingTime', videoId])(learningTime)
)

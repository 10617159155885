import * as R from 'ramda'
import { createSelector } from 'reselect'
import { RootReducer } from 'types'

// Selector
export const selectExamTimeTracker = (state: RootReducer) =>
  state.examTimeTracker

export const getExamPassedTime = createSelector(
  selectExamTimeTracker,
  R.prop('passedTime')
)

export const getExamCurrentTimers = createSelector(
  selectExamTimeTracker,
  R.prop('currentTimers')
)

export const getExamQuestionOriginalId = createSelector(
  selectExamTimeTracker,
  R.prop('questionOriginalId')
)

export const getExamPassageOriginalId = createSelector(
  selectExamTimeTracker,
  R.prop('passageOriginalId')
)

export const getQuestionIdOnPassageCheckbox = createSelector(
  selectExamTimeTracker,
  R.prop('questionIdOnPassageCheckbox')
)

export const getPassedTimeOnPassageCheckbox = createSelector(
  selectExamTimeTracker,
  R.prop('passedTimeOnPassageCheckbox')
)

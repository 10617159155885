import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  AerobicRespirationGame,
  ThemeProvider,
  GlobalStyles
} from 'examkrackers-aerobic-respiration-game'

import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const SaltyBlox = (): JSX.Element => {
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const [playRespirationGame, setPlayRespirationGame] = useState(true)

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && playRespirationGame) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.playRespirationGame, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Game name': 'Respiration Game'
      })
      setPlayRespirationGame(false)
    }
  }, [currentCourse, playRespirationGame])

  return (
    <ThemeProvider>
      <>
        <GlobalStyles />
        <AerobicRespirationGame />
      </>
    </ThemeProvider>
  )
}

export default SaltyBlox

import { number, object, string } from 'yup'
import { validateField, validateValues } from 'utils/form'

export const addCalendarEventSchema = object().shape({
  title: string().max(250, 'Max. 250 characters').required('Title is required'),
  event_date: string().required('Event date is required'),
  type: string().required('Event type is required'),
  duration: number().required('Event duration is required'),
  status: string().required('Status is required')
})

export const validateAddEventField = values =>
  validateField(addCalendarEventSchema, values)
export const validateAddEventValue = validateValues(addCalendarEventSchema)

import { createRoutine } from 'redux-saga-routines'
import * as Effects from 'utils/saga'
import { ActionPayload } from 'types'
import {
  getExamCurrentTimers,
  getExamPassageOriginalId,
  getExamPassedTime,
  getExamQuestionOriginalId,
  getQuestionIdOnPassageCheckbox,
  getPassedTimeOnPassageCheckbox
} from './selectors'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import * as R from 'ramda'
import { SECTION_STATUSES } from 'modules/exam/utils/exam'
import { getIsPreviewAdmin } from 'modules/auth/ducks/selectors'
import LocalStorageService from '../../../services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from '../../../utils/storage'
import { isNotNilOrEmpty } from '../../../utils/ramda'
import { updateCourseTimeState } from '../../../services/CourseService'
import { COURSE_LEARNING_TIME_STATUS } from '../../../utils/courses'

export const resetPassedTimeRoutine = createRoutine('RESET_PASSED_TIME')
export const countPassedTimeRoutine = createRoutine('INCREASE_PASSED_TIME')
export const setCurrentTimersRoutine = createRoutine('SET_CURRENT_TIMERS')
export const setQuestionOriginalIdRoutine = createRoutine(
  'SET_QUESTION_ORIGINAL_ID'
)
export const setPassageOriginalIdRoutine = createRoutine(
  'SET_PASSAGE_ORIGINAL_ID'
)
export const updateTimersOnUnmountRoutine = createRoutine(
  'UPDATE_TIMERS_ON_UNMOUNT'
)
export const setQuestionIdOnPassageCheckboxRoutine = createRoutine(
  'SET_QUESTION_ID_ON_PASSAGE_CHECKBOX'
)
export const setPassedTimePassageCheckboxRoutine = createRoutine(
  'SET_PASSED_TIME_ON_PASSAGE_CHECKBOX'
)

//
// Actions
//

function* resetPassedTime() {
  yield Effects.put(resetPassedTimeRoutine.success())
}

function* countPassedTime() {
  yield Effects.put(countPassedTimeRoutine.success())
}

function* setCurrentTimers({ payload }: ActionPayload) {
  yield Effects.put(setCurrentTimersRoutine.success(payload))
}

interface UpdateTimersOnUnmountPayload {
  payload: {
    handleSaveTimers: any
    generatePayload: any
    currentPage: any
  }
}

function* updateTimersOnUnmount({ payload }: UpdateTimersOnUnmountPayload) {
  const timers = yield Effects.select(getExamCurrentTimers)
  const passedTime = yield Effects.select(getExamPassedTime)
  const examDetails = yield Effects.select(getExamDetails)
  const passageOriginalId = yield Effects.select(getExamPassageOriginalId)
  const questionOriginalId = yield Effects.select(getExamQuestionOriginalId)
  const questionIdOnPassageCheckbox = yield Effects.select(
    getQuestionIdOnPassageCheckbox
  )
  const passedTimeOnPassageCheckbox = yield Effects.select(
    getPassedTimeOnPassageCheckbox
  )

  const updateCourseLearningTimeIfNeeded = () => {
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
    const hasCourse = isNotNilOrEmpty(courseId)

    const handleError = e => console.error(e)
    const handleSuccess = () => {}

    if (hasCourse) {
      updateCourseTimeState({
        id: courseId,
        // @ts-ignore
        state: COURSE_LEARNING_TIME_STATUS.started,
        seconds: passedTime
      })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  const isFalsePassage = R.pipe(
    // @ts-ignore
    R.propOr([], ['questions']),
    // @ts-ignore
    R.map(R.prop('passages')),
    R.flatten,
    R.find(R.propEq('original_exam_passage_id', passageOriginalId)),
    R.propOr(false, 'is_false_passage')
    // @ts-ignore
  )(examDetails)

  const sectionStatus = R.pipe(
    // @ts-ignore
    R.prop('questions'),
    R.find(
      // @ts-ignore
      R.pipe(
        // @ts-ignore
        R.prop('questions'),
        R.find(R.propEq('original_exam_question_id', questionOriginalId))
      )
    ),
    R.propOr('', 'section_status')
    // @ts-ignore
  )(examDetails)

  const timerCheckboxes = R.pipe(
    R.pathOr('{}', ['exam', 'timer_checkboxes']),
    JSON.parse
  )(examDetails)

  const questionPayloadOnUnmount = payload.generatePayload({
    passedTime,
    timers,
    passageOriginalId,
    questionOriginalId,
    isFalsePassage,
    questionIdOnPassageCheckbox,
    passedTimeOnPassageCheckbox,
    sectionStatus,
    timerCheckboxes
  })

  const passageCheckboxes: { [p: string]: boolean } = R.propOr(
    {},
    'isPassageReadingTimeStopped',
    timerCheckboxes
  )
  const questionCheckboxes: { [p: string]: boolean } = R.propOr(
    {},
    'isQuestionWorkingTimeToCalculate',
    timerCheckboxes
  )

  const isPassageReadingTimeStopped = {
    ...passageCheckboxes,
    [passageOriginalId]: true
  }

  const isPassageCurrentlyStopped = R.propOr(
    false,
    passageOriginalId,
    isPassageReadingTimeStopped
  )

  const isQuestionWorkingTimeSavedAsZero = R.pipe(
    R.propOr('', questionOriginalId),
    R.propEq('working', 0)
  )(questionPayloadOnUnmount)

  // Mark question working time as "to calculate" if passage is STOPPED by unmount
  const isQuestionWorkingTimeToCalculate =
    sectionStatus === SECTION_STATUSES.phase1 &&
    // @ts-ignore
    isPassageCurrentlyStopped &&
    isQuestionWorkingTimeSavedAsZero
      ? { ...questionCheckboxes, [questionOriginalId]: true }
      : questionCheckboxes

  const isPreviewAdmin = yield Effects.select(getIsPreviewAdmin)
  if (!isPreviewAdmin) {
    updateCourseLearningTimeIfNeeded()
    payload.handleSaveTimers(
      {
        ...timers,
        ...questionPayloadOnUnmount
      },
      { isPassageReadingTimeStopped, isQuestionWorkingTimeToCalculate }
    )
  }

  yield Effects.put(updateTimersOnUnmountRoutine.success())
}

function* setQuestionOriginalId({ payload }: ActionPayload) {
  yield Effects.put(setQuestionOriginalIdRoutine.success(payload))
}

function* setPassageOriginalId({ payload }: ActionPayload) {
  yield Effects.put(setPassageOriginalIdRoutine.success(payload))
}

function* setQuestionIdOnPassageCheckbox({ payload }: ActionPayload) {
  yield Effects.put(setQuestionIdOnPassageCheckboxRoutine.success(payload))
}

function* setPassedTimePassageCheckbox({ payload }: ActionPayload) {
  yield Effects.put(setPassedTimePassageCheckboxRoutine.success(payload))
}

//
// Watchers
//

function* resetPassedTimeWatcher() {
  yield Effects.takeLatest(resetPassedTimeRoutine.TRIGGER, resetPassedTime)
}
function* countPassedTimeWatcher() {
  yield Effects.takeLatest(countPassedTimeRoutine.TRIGGER, countPassedTime)
}
function* setCurrentTimersWatcher() {
  yield Effects.takeLatest(setCurrentTimersRoutine.TRIGGER, setCurrentTimers)
}
function* setQuestionOriginalIdWatcher() {
  yield Effects.takeLatest(
    setQuestionOriginalIdRoutine.TRIGGER,
    setQuestionOriginalId
  )
}
function* setPassageOriginalIdWatcher() {
  yield Effects.takeLatest(
    setPassageOriginalIdRoutine.TRIGGER,
    setPassageOriginalId
  )
}
function* updateTimersOnUnmountWatcher() {
  yield Effects.takeLatest(
    updateTimersOnUnmountRoutine.TRIGGER,
    updateTimersOnUnmount
  )
}

function* setPassedTimePassageCheckboxWatcher() {
  yield Effects.takeLatest(
    setPassedTimePassageCheckboxRoutine.TRIGGER,
    setPassedTimePassageCheckbox
  )
}
function* setQuestionIdOnPassageCheckboxWatcher() {
  yield Effects.takeLatest(
    setQuestionIdOnPassageCheckboxRoutine.TRIGGER,
    setQuestionIdOnPassageCheckbox
  )
}

//
// Sagas
//

export const examTimeTrackerSagas = [
  Effects.fork(resetPassedTimeWatcher),
  Effects.fork(countPassedTimeWatcher),
  Effects.fork(setCurrentTimersWatcher),
  Effects.fork(setQuestionOriginalIdWatcher),
  Effects.fork(setPassageOriginalIdWatcher),
  Effects.fork(updateTimersOnUnmountWatcher),
  Effects.fork(setPassedTimePassageCheckboxWatcher),
  Effects.fork(setQuestionIdOnPassageCheckboxWatcher)
]

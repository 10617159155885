import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as R from 'ramda'
import { pathOr } from 'ramda'
import qs from 'qs'
import { useHistory } from 'react-router-dom'

import { VIDEOS_QUERY_PARAMS } from 'utils/videos'
import { Heading1, Loader } from 'examkrackers-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  getVideosList,
  getVideosPagination,
  selectBookVideosState,
  selectVideosState
} from 'modules/videos/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { Video } from 'types/videos'
import { fetchVideosListRoutine } from '../ducks/actions'

const MOCKED_CATEGORIES = [
  { name: 'All', id: 'all' },
  { name: 'Books', id: 'books' },
  { name: 'Classes', id: 'recordings' },
  { name: 'Onboarding', id: 'onboarding' },
  { name: 'Bonus Videos', id: 'medreel' },
  { name: 'Reviews', id: 'review' }
]

export const VideoCategories = (): JSX.Element => {
  const { t } = useTranslation()
  const booksVideoState = useSelector(selectBookVideosState)
  const otherCategoriesVideoState = useSelector(selectVideosState)
  const [isLoading, setIsLoading] = useState<any>(true)
  const [categoryVideosLoading, setCategoryVideosLoading] = useState<any>(true)
  const [favoriteCount, setFavoriteCount] = useState(0)
  const [categories, setCategories] = useState<{ name: string; id: string }[]>(
    []
  )

  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const dispatch = useDispatch()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const selectedCategory = pathOr(
    'all',
    ['filter', VIDEOS_QUERY_PARAMS.category],
    parsedQuery
  )
  const videosList = useSelector(getVideosList) as Video[]
  const getVideoPagination = useSelector(getVideosPagination)
  const videosListWithoutBookVideos =
    videosList.filter(video => video.category === 'recordings').length +
      videosList.filter(video => video.category === 'onboarding').length +
      videosList.filter(video => video.category === 'medreel').length +
      videosList.filter(video => video.category === 'review').length || 0

  const fetchVideosFromBook = useCallback(
    () => dispatch(fetchVideosListRoutine()),
    [dispatch]
  )

  const getVideoCategories = () => {
    setCategories(MOCKED_CATEGORIES)

    const filterQuery = R.pipe(
      R.propOr({}, 'filter'),
      // @ts-ignore
      R.dissoc(VIDEOS_QUERY_PARAMS.favorites)
      // @ts-ignore
    )(parsedQuery)

    const newQuery = qs.stringify(
      {
        ...parsedQuery,
        filter: {
          // @ts-ignore
          ...filterQuery,
          [VIDEOS_QUERY_PARAMS.category]: MOCKED_CATEGORIES[0].id
        }
      },
      { addQueryPrefix: true }
    )

    setTimeout(() => replace(`${pathname}${newQuery}`), 200)
  }

  const getFavouriteVideosCount = () => {
    const currentFavCount = videosList.filter(
      video => video.is_favourite === true
    ).length

    setFavoriteCount(currentFavCount)
  }

  const onCateoryClick = category => () => {
    const filterQuery = R.pipe(
      R.propOr({}, 'filter'),
      // @ts-ignore
      R.dissoc(VIDEOS_QUERY_PARAMS.favorites),
      R.dissoc(VIDEOS_QUERY_PARAMS['b.id']),
      R.dissoc(VIDEOS_QUERY_PARAMS['bcc.id']),
      R.dissoc(VIDEOS_QUERY_PARAMS.search),
      R.dissoc(VIDEOS_QUERY_PARAMS['bsubc.id'])

      // @ts-ignore
    )(parsedQuery)

    const newQuery = qs.stringify(
      {
        ...parsedQuery,
        filter: {
          // @ts-ignore
          ...filterQuery,
          [VIDEOS_QUERY_PARAMS.category]: R.propOr('all', 'id', category)
        }
      },
      { addQueryPrefix: true }
    )

    replace(`${pathname}${newQuery}`)
  }

  const categoryCount = category => {
    if (category === 'all') {
      return videosListWithoutBookVideos + getVideoPagination.recordsTotal
    }
    if (category === 'books') {
      return isLoading && getVideoPagination.recordsTotal === 0 ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        getVideoPagination.recordsTotal
      )
    }
    return categoryVideosLoading ? (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    ) : (
      videosList.filter(video => video.category === category).length
    )
  }

  const renderCategories = categories.map((category, index) => (
    <CategoryItem
      onClick={onCateoryClick(category)}
      key={`category-item-${index}`}
      isActive={selectedCategory === R.propOr('', 'id', category)}
    >
      <span>{R.propOr('', 'name', category)}</span>
      <span>({categoryCount(category.id)})</span>
    </CategoryItem>
  ))

  const onFavoritesClick = () => {
    const filterQuery = R.pipe(
      R.propOr({}, 'filter'),
      R.dissoc(VIDEOS_QUERY_PARAMS['b.id']),
      R.dissoc(VIDEOS_QUERY_PARAMS['bcc.id']),
      R.dissoc(VIDEOS_QUERY_PARAMS.search),
      R.assoc('category', 'favourites')
      // @ts-ignore
    )(parsedQuery)

    const newQuery = qs.stringify(
      {
        ...parsedQuery,
        filter: {
          // @ts-ignore
          ...filterQuery,
          [VIDEOS_QUERY_PARAMS.favorites]: 'isNotNull'
        }
      },
      { addQueryPrefix: true }
    )

    replace(`${pathname}${newQuery}`)
  }

  const isFavoritesCategorySelected = R.pipe(
    pathOr(null, ['filter', VIDEOS_QUERY_PARAMS.favorites]),
    isNotNilOrEmpty
  )(parsedQuery)

  useEffect(() => {
    getVideoCategories()
    getFavouriteVideosCount()
  }, [])

  useEffect(() => {
    getFavouriteVideosCount()
  }, [videosList])

  useEffect(() => {
    if (booksVideoState === 'done') {
      return setIsLoading(false)
    }
    if (booksVideoState === 'in-progress') {
      return setIsLoading(true)
    }
  }, [booksVideoState])

  useEffect(() => {
    if (otherCategoriesVideoState === 'done') {
      return setCategoryVideosLoading(false)
    }
    if (otherCategoriesVideoState === 'in-progress') {
      return setCategoryVideosLoading(true)
    }
  }, [otherCategoriesVideoState])

  useEffect(() => {
    !getVideoPagination ? fetchVideosFromBook() : null
  }, [getVideoPagination])

  return (
    <Container>
      <Heading1 bold>{t('videos.title')}</Heading1>
      <CategoriesTitle>{t('videos.categories')}</CategoriesTitle>
      <CategoriesList>
        {renderCategories}
        <CategoryItem
          onClick={onFavoritesClick}
          isActive={isFavoritesCategorySelected}
        >
          <span>{t('videos.favorites')}</span>
          <span>({favoriteCount})</span>
        </CategoryItem>
      </CategoriesList>
    </Container>
  )
}

export default VideoCategories

const Container = styled.div``

const CategoriesTitle = styled.div`
  margin: 21px 0;
  color: ${({ theme }) => theme.colors.main.grey600};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
`

const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CategoryItem = styled.div`
  display: flex;
  max-height: max-content;
  padding: 0 12px;
  gap: 8px;
  line-height: 32px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.main.primary200 : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  transition: background 250ms
    ${({ theme }) => theme.transitions.easing.easeInOut};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.main.text};

  span {
    display: flex;
  }
`
const LoaderWrapper = styled.div`
  width: 15px;
  transform: translateY(8px) translateX(3px) scale(0.5);
`

import { StudentProps } from 'types'
import * as R from 'ramda'
import { always } from 'ramda'

export const STUDENT_ROLES = {
  exam: 'exam',
  books: 'books',
  course: 'course'
}

export const findStudentRoles = (student: StudentProps) => {
  const appendRoleByProp = (prop, role) =>
    R.pipe(
      R.propOr(false, prop),
      R.ifElse(R.equals(false), always([]), always([role]))
    )

  return R.pipe(
    R.juxt([
      appendRoleByProp('has_books', STUDENT_ROLES.books),
      appendRoleByProp('has_courses', STUDENT_ROLES.course),
      appendRoleByProp('has_exams', STUDENT_ROLES.exam)
    ]),
    R.flatten
  )(student)
}

export const previewStudentsEmail = 'exam.preview@examkrackers.com'

export const SHORT_STUDENTS_ACTIVITY_TIME = 2
export const LONG_STUDENTS_ACTIVITY_TIME = 30

export const ANALYTICS_ACCOUNT_TYPE = {
  exam: 'Exam',
  course: 'Course',
  freeTrial: 'Free Trial'
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import qs from 'qs'
import styled, { keyframes } from 'styled-components'
import { propOr, pipe } from 'ramda'
import { Button, SuspenseFadeIn } from 'examkrackers-components'

import { BOOK_VIEWER_PARAMS } from 'utils/books'
import { getBookContentImages } from 'modules/books/ducks/selectors'
import { RootReducer } from 'types'
import { useTranslation } from 'react-i18next'

const FixedImage = React.lazy(
  () => import('modules/books/components/FixedImage')
)

export const FixedImagesPreview = ({ rightColumnLocked }): JSX.Element => {
  const [lockedImageIndex, setLockedImageIndex] = React.useState<number>(0)
  const [newImagePopupOpen, setNewImagePopupOpen] =
    React.useState<boolean>(false)
  const { t } = useTranslation()
  const { location, push } = useHistory()
  const { search, pathname } = location

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const fixedImages = useSelector((state: RootReducer) =>
    getBookContentImages(state)
  )

  const currentSlide = pipe(
    propOr('0', BOOK_VIEWER_PARAMS.imageSlide),
    Number
  )(parsedQuery)

  const setCurrentSlide = value => {
    const newQuery = qs.stringify({
      ...parsedQuery,
      [BOOK_VIEWER_PARAMS.imageSlide]: value
    })

    push(`${pathname}?${newQuery}`)
  }

  const getImageByIndex = rightColumnLocked
    ? fixedImages[lockedImageIndex]
    : fixedImages[currentSlide]
  const pagesTotal = fixedImages.length
  const currentPage = currentSlide + 1

  const changePage = newPage => () => {
    setCurrentSlide(newPage - 1)
    setLockedImageIndex(newPage - 1)
  }

  React.useEffect(() => {
    if (rightColumnLocked) {
      setLockedImageIndex(currentSlide)
    }
  }, [rightColumnLocked])

  React.useEffect(() => {
    if (rightColumnLocked && currentSlide !== lockedImageIndex) {
      setNewImagePopupOpen(true)
    }
  }, [lockedImageIndex, currentSlide])
  return (
    <Container>
      <SuspenseFadeIn>
        <FixedImage
          image={getImageByIndex}
          handleNext={changePage(currentPage + 1)}
          disableNext={pagesTotal === currentPage || pagesTotal === 0}
          handlePrevious={changePage(currentPage - 1)}
          disablePrevious={currentPage === 1 || pagesTotal === 0}
        />
        {newImagePopupOpen ? (
          <NewImagePopup open={newImagePopupOpen}>
            <span>{t('books.newImagePopup.title')}</span>
            <ButtonContainter>
              <Button
                size='small'
                color='secondary'
                onClick={() => {
                  setNewImagePopupOpen(false)
                  setLockedImageIndex(currentSlide)
                }}
              >
                {t('books.newImagePopup.view')}
              </Button>
              <Button
                onClick={() => setNewImagePopupOpen(false)}
                size='small'
                color='tertiary'
              >
                {t('books.newImagePopup.dismiss')}
              </Button>
            </ButtonContainter>
          </NewImagePopup>
        ) : (
          <></>
        )}
      </SuspenseFadeIn>
    </Container>
  )
}

export default FixedImagesPreview

const fadeIn = keyframes`
  from {
    opacity: 0;
    right: -100%;
  }
  to {
    opacity: 1;
    right: 2px;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`
const NewImagePopup = styled.div`
  animation: ${fadeIn} 0.5s ease-out;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px;
  transition: right 0.3s ease-in-out;
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadows.mainShadow};
  background-color: ${({ theme }) => theme.colors.main.primary100};
  outline: 1px solid ${({ theme }) => theme.colors.main.primary700};
  span {
    display: block;
    width: 100%;
    text-align: center;
  }
  button {
    padding: 4px 8px;
    height: fit-content;
  }
`

const ButtonContainter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { TextareaField, Button, CloseIcon } from 'examkrackers-components'

import { useDispatch, useSelector } from 'react-redux'
import { pipe, propOr, filter, propEq, head } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import {
  addPinNoteBody,
  addPinNoteTitle,
  deletePinNote,
  updatePinNote
} from 'services/BooksService'

import { refreshBookFragmentDataRoutine } from 'modules/books/ducks/actions'

import { RootReducer, Book } from 'types'

import {
  selectBookByOriginalId,
  selectAllBooks
} from 'modules/books/ducks/selectors'
interface PinNoteProps {
  variant: string
  isOpened?: boolean
  title?: string
  content?: string
  onSave?: (e: any) => void
  onRemove?: (e: any) => void
  handleCloseNote?: (e: any) => void
  studentBookContentId?: string
  bookId: string
  pinTitle?: string
  pinNote?: string
  pinNoteId?: string
  pinNoteList?: boolean
  studentBookIdFromProps?: string
}

export const PinNote = (props: PinNoteProps) => {
  const {
    variant,
    isOpened = true,
    handleCloseNote,
    studentBookContentId,
    bookId,
    pinTitle,
    pinNote,
    pinNoteId,
    pinNoteList,
    studentBookIdFromProps
  } = props
  const [note, setNote] = useState(pinNote)
  const [title, setTitle] = useState(pinTitle)
  const [noteId, setNoteId] = useState(pinNoteId)
  const [countCharacters, setCountCharacters] = useState(0)

  const books: Book[] = useSelector(selectAllBooks)

  const isTitleTheSame = pinTitle === title
  const isNoteTheSame = pinNote === note
  const isNoteEmpty = isNilOrEmpty(note)
  const isTitleEmpty = isNilOrEmpty(title)
  const isTitleEmptyOrTheSame = isNotNilOrEmpty(title) && isTitleTheSame

  const hasNoteId = isNotNilOrEmpty(noteId)

  const dispatch = useDispatch()
  const params = useParams()

  const partOrder: number = pipe(propOr('', 'partOrder'), Number)(params)
  const chapterOrder: number = pipe(propOr('1', 'chapterOrder'), Number)(params)
  const { t } = useTranslation()
  const {
    location: { search },
    push
  } = useHistory()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const chapterOrderFromQuery = propOr('', 'chapterOrder', parsedQuery)
  const partOrderFromQuery = propOr('', 'partOrder', parsedQuery)
  const selectedNoteId = propOr('', 'sectionId', parsedQuery)

  const book = useSelector((state: RootReducer) =>
    selectBookByOriginalId(state, bookId)
  )
  const studentBookId: string = studentBookIdFromProps || propOr('', 'id', book)

  const handleNoteOnChange = (name, value) => {
    setNote(value)
    setCountCharacters(value.length)
  }
  const handleTitleOnChange = e => {
    setTitle(e.target.value)
  }

  const fetchTotalPartData = React.useCallback(
    () =>
      dispatch(
        refreshBookFragmentDataRoutine({
          params: { bookId, chapterOrder, partOrder },
          fragmentName: 'book_contents'
        })
      ),
    [dispatch]
  )

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const onSuccessAction = () => {
    eventEmitter.emit(events.refreshPinNotesList, true)
  }

  const isTooManyCharacter = countCharacters > 200

  const handleAddPinNoteBody = async e => {
    e.preventDefault()

    const handleSuccess = () => {
      fetchTotalPartData()
      showToast({
        key: 'pinNotes.addNotesSuccessfully',
        severity: SEVERITY.success
      })
      // @ts-ignore
      handleCloseNote && handleCloseNote()
      onSuccessAction()
    }
    const handleError = () => {
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    hasNoteId &&
      !isNoteTheSame &&
      !isNoteEmpty &&
      updatePinNote({
        // @ts-ignore
        id: noteId,
        // @ts-ignore
        note: note
      })
        .then(handleSuccess)
        .catch(handleError)

    !hasNoteId &&
      !isNoteEmpty &&
      addPinNoteBody({
        // @ts-ignore
        studentBookContentId: studentBookContentId,
        variant: variant,
        // @ts-ignore
        note: note
      })
        .then(handleSuccess)
        .catch(handleError)

    !isTitleEmptyOrTheSame &&
      !isNoteEmpty &&
      addPinNoteTitle({
        studentBookId: studentBookId,
        variant: variant,
        // @ts-ignore
        title: title
      })
        .then(handleSuccess)
        .catch(handleError)
  }

  const handleDeletePinNote = async e => {
    e.preventDefault()

    const handleSuccess = () => {
      fetchTotalPartData()
      showToast({
        key: 'pinNotes.deletePinNote',
        severity: SEVERITY.success
      })
      onSuccessAction()
      eventEmitter.emit(events.pinNoteDeleted)
    }

    const handleError = () => {
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }
    // @ts-ignore
    deletePinNote({ id: noteId }).then(handleSuccess).catch(handleError)
  }

  const mainBookId = pipe(
    // @ts-ignore
    filter(propEq('id', bookId)),
    head,
    propOr('', 'book_id')
    // @ts-ignore
  )(books)

  const redirectToProperSection = () => {
    push(
      `/books/${mainBookId}/chapter/${chapterOrderFromQuery}/part/${partOrderFromQuery}?sectionIdContext=${selectedNoteId}&bookContentIdContext=${studentBookContentId}`
    )
  }

  useEffect(() => {
    setNote(pinNote)
  }, [pinNote, isOpened])
  useEffect(() => {
    setTitle(pinTitle)
  }, [pinTitle, isOpened])
  useEffect(() => {
    setNoteId(pinNoteId)
  }, [pinNoteId])

  useEffect(() => {
    pinNote && setCountCharacters(pinNote.length)
  }, [pinNote, isOpened])

  return (
    <>
      {isOpened && !pinNoteList ? (
        <Container variant={variant}>
          <Close onClick={handleCloseNote}>
            <CloseIcon />
          </Close>
          <Input
            variant={variant}
            size='small'
            type='text'
            value={title}
            onChange={handleTitleOnChange}
            placeholder={t('pinNotes.titlePlaceholder')}
          />
          <BorderLine variant={variant} />
          <TextAreaOverride variant={variant}>
            <TextareaField
              t={t}
              name='value'
              id='change-target-score-value'
              onChange={handleNoteOnChange}
              validate={() => {}}
              value={note}
              placeholder={t('pinNotes.notePlaceholder')}
              maxHeight='112px'
            />
          </TextAreaOverride>
          <CounterCharacterWrapper
            isTooManyCharacter={isTooManyCharacter}
          >{`${countCharacters}/200`}</CounterCharacterWrapper>
          <BorderLine variant={variant} />
          <ButtonContainer>
            <Button
              id='pinNote-cancel'
              disabled={!hasNoteId}
              type='button'
              color='transparent'
              variant='contained'
              onClick={handleDeletePinNote}
              size='small'
            >
              {t('pinNotes.remove')}
            </Button>
            <Button
              id='pinNote-save'
              disabled={
                ((isNoteEmpty || isNoteTheSame) &&
                  (isTitleEmpty || isTitleTheSame)) ||
                (!isTitleEmpty && isNoteEmpty) ||
                isTooManyCharacter
              }
              type='button'
              color='transparent'
              variant='contained'
              onClick={handleAddPinNoteBody}
              size='small'
            >
              {t('pinNotes.save')}
            </Button>
          </ButtonContainer>
        </Container>
      ) : (
        <div />
      )}
      {pinNoteList ? (
        <ListContainer variant={variant}>
          <Close onClick={handleCloseNote}>
            <CloseIcon />
          </Close>
          <Input
            variant={variant}
            size='small'
            type='text'
            value={title}
            onChange={handleTitleOnChange}
            placeholder={t('pinNotes.titlePlaceholder')}
          />
          <BorderLine variant={variant} />
          <TextAreaOverride variant={variant}>
            <TextareaField
              t={t}
              name='value'
              id='change-target-score-value'
              onChange={handleNoteOnChange}
              validate={() => {}}
              value={note}
              placeholder={t('pinNotes.notePlaceholder')}
              maxHeight='112px'
            />
          </TextAreaOverride>
          <CounterCharacterWrapper
            isTooManyCharacter={isTooManyCharacter}
          >{`${countCharacters}/200`}</CounterCharacterWrapper>
          <BorderLine variant={variant} />
          <ButtonContainer>
            <Button
              id='pinNote-cancel'
              disabled={!hasNoteId}
              type='button'
              color='transparent'
              variant='contained'
              onClick={handleDeletePinNote}
              size='small'
            >
              {t('pinNotes.remove')}
            </Button>
            <Button
              id='pinNote-goTo'
              // @ts-ignore
              disabled={
                !hasNoteId || !chapterOrderFromQuery || !partOrderFromQuery
              }
              type='button'
              color='transparent'
              variant='contained'
              onClick={redirectToProperSection}
              size='small'
            >
              {t('pinNotes.goTo')}
            </Button>
            <Button
              id='pinNote-save'
              disabled={
                ((isNoteEmpty || isNoteTheSame) &&
                  (isTitleEmpty || isTitleTheSame)) ||
                (!isTitleEmpty && isNoteEmpty) ||
                isTooManyCharacter
              }
              type='button'
              color='transparent'
              variant='contained'
              onClick={handleAddPinNoteBody}
              size='small'
            >
              {t('pinNotes.save')}
            </Button>
          </ButtonContainer>
        </ListContainer>
      ) : (
        <div />
      )}
    </>
  )
}

export default PinNote

const Container = styled.div`
  z-index: 999;
  position: absolute;
  right: 0;
  width: 242px;
  height: 186px;
  padding: 0 12px 12px;
  border-radius: 6px;
  background-color: ${({ theme, variant }) => {
    switch (true) {
      case variant === 'A':
        return theme.colors.pins.variantA.background
      case variant === 'B':
        return theme.colors.pins.variantB.background
      case variant === 'C':
        return theme.colors.pins.variantC.background
      default:
        return theme.colors.pins.variantA.background
    }
  }};
  border: 1px solid
    ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.shape
        case variant === 'B':
          return theme.colors.pins.variantB.shape
        case variant === 'C':
          return theme.colors.pins.variantC.shape
        default:
          return theme.colors.pins.variantA.shape
      }
    }};
`
const ListContainer = styled.div`
  width: 242px;
  height: 186px;
  padding: 0 12px 12px;
  border-radius: 6px;
  background-color: ${({ theme, variant }) => {
    switch (true) {
      case variant === 'A':
        return theme.colors.pins.variantA.background
      case variant === 'B':
        return theme.colors.pins.variantB.background
      case variant === 'C':
        return theme.colors.pins.variantC.background
      default:
        return theme.colors.pins.variantA.background
    }
  }};
  border: 1px solid
    ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.shape
        case variant === 'B':
          return theme.colors.pins.variantB.shape
        case variant === 'C':
          return theme.colors.pins.variantC.shape
        default:
          return theme.colors.pins.variantA.shape
      }
    }};
`
const Input = styled.input`
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  margin: 0 !important;
  padding: 0;
  border: none;
  background-color: ${({ theme, variant }) => {
    switch (true) {
      case variant === 'A':
        return theme.colors.pins.variantA.background
      case variant === 'B':
        return theme.colors.pins.variantB.background
      case variant === 'C':
        return theme.colors.pins.variantC.background
      default:
        return theme.colors.pins.variantA.background
    }
  }};
  color: ${({ theme, variant }) => {
    switch (true) {
      case variant === 'A':
        return theme.colors.pins.variantA.shape
      case variant === 'B':
        return theme.colors.pins.variantB.shape
      case variant === 'C':
        return theme.colors.pins.variantC.shape
      default:
        return theme.colors.pins.variantA.shape
    }
  }};

  &::-webkit-input-placeholder {
    color: ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.shape
        case variant === 'B':
          return theme.colors.pins.variantB.shape
        case variant === 'C':
          return theme.colors.pins.variantC.shape
        default:
          return theme.colors.pins.variantA.shape
      }
    }} !important;
  }

  &::-moz-placeholder {
    color: ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.shape
        case variant === 'B':
          return theme.colors.pins.variantB.shape
        case variant === 'C':
          return theme.colors.pins.variantC.shape
        default:
          return theme.colors.pins.variantA.shape
      }
    }} !important;
  }

  &::-ms-placeholder {
    color: ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.shape
        case variant === 'B':
          return theme.colors.pins.variantB.shape
        case variant === 'C':
          return theme.colors.pins.variantC.shape
        default:
          return theme.colors.pins.variantA.shape
      }
    }} !important;
  }

  &::placeholder {
    color: ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.shape
        case variant === 'B':
          return theme.colors.pins.variantB.shape
        case variant === 'C':
          return theme.colors.pins.variantC.shape
        default:
          return theme.colors.pins.variantA.shape
      }
    }} !important;
  }
`
const BorderLine = styled.div`
  border-bottom: 1px solid
    ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.shape
        case variant === 'B':
          return theme.colors.pins.variantB.shape
        case variant === 'C':
          return theme.colors.pins.variantC.shape
        default:
          return theme.colors.pins.variantA.shape
      }
    }};
`
const TextAreaOverride = styled.div`
  & > div {
    height: 80px;
    flex-wrap: wrap;
    margin: 0 !important;
    padding: 8px 0 !important;
    border: none;
    background-color: ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.background
        case variant === 'B':
          return theme.colors.pins.variantB.background
        case variant === 'C':
          return theme.colors.pins.variantC.background
        default:
          return theme.colors.pins.variantA.background
      }
    }};
    color: ${({ theme, variant }) => {
      switch (true) {
        case variant === 'A':
          return theme.colors.pins.variantA.shape
        case variant === 'B':
          return theme.colors.pins.variantB.shape
        case variant === 'C':
          return theme.colors.pins.variantC.shape
        default:
          return theme.colors.pins.variantA.shape
      }
    }};

    input {
      font-size: 14px;
      line-height: 40px;
    }
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const Close = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 999;
  transition: color 0.2s ${({ theme }) => theme.transitions.easing.easeInOut} 0s;
`

const CounterCharacterWrapper = styled.div`
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
  color: ${({ theme, isTooManyCharacter }) =>
    isTooManyCharacter ? theme.colors.main.error500 : theme.colors.main.black};
  font-weight: ${({ isTooManyCharacter }) =>
    isTooManyCharacter ? 'bold' : 400};
`

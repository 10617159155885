import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import { getCurrentExamTypeId } from 'modules/examTypes/ducks/selectors'
import { getExamTypeProjections } from 'modules/diagnostics/ducks/selectors'
import { fetchExamTypeScoreProjectionsRoutine } from 'modules/diagnostics/ducks/actions'

import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import ModalChangeTargetScore from 'modules/examTypes/components/ModalChangeTargetScore'

// Score summary section
export const ScoreSummary = (): JSX.Element => {
  // Get i18n handler
  const { t } = useTranslation()
  const typeId = useSelector(getCurrentExamTypeId)
  const examTypeProjections = useSelector(getExamTypeProjections)

  // Get React-Redux dispatcher
  const dispatch = useDispatch()

  const fetchExamTypeScoreProjections = React.useCallback(
    id => dispatch(fetchExamTypeScoreProjectionsRoutine({ id })),
    [dispatch]
  )

  React.useEffect(() => {
    if (isNotNilOrEmpty(typeId)) {
      fetchExamTypeScoreProjections(typeId)
    }
  }, [typeId])

  const totalData = R.pipe(
    R.propOr([], 'score_projection'),
    // @ts-ignore
    R.find(R.propEq('id', null))
    // @ts-ignore
  )(examTypeProjections)

  const totalAnswers = R.propOr(0, 'all_question_amount', examTypeProjections)
  const totalCorrectAnswers = R.propOr(0, 'correct_answer_amount', totalData)
  const totalPercentileRank = R.propOr(0, 'percentile_rank', totalData)
  const totalTargetScore = Number(R.propOr(0, 'target_score', totalData))

  return (
    <ScoreSummaryContainer>
      <div className='score-summary__details-container'>
        <div className='score-summary__details'>
          <div className='score-summary__cell-container'>
            <div className='score-summary__cell score-summary__cell--total'>
              <div className='score-summary__label'>
                {t('diagnostics.labels.targetScore')}
              </div>
              <div className='score-summary__cell-min-height'>
                <div className='score-summary__label-small'>
                  {t('diagnostics.labels.targetScore')}
                </div>
                <div className='score-summary__target-container'>
                  <div className='score-summary__target-score'>
                    {totalTargetScore}
                  </div>
                  <ModalChangeTargetScore initialValue={totalTargetScore} />
                </div>
              </div>
            </div>
          </div>
          <div className='score-summary__cell-container'>
            <div className='score-summary__cell score-summary__cell--total'>
              <div className='score-summary__cell-min-height'>
                <div className='score-summary__label-small'>
                  {t('diagnostics.table.headers.percentile_rank')}
                </div>
                <div className='score-summary__label'>
                  {totalPercentileRank}%
                  <span className='score-summary__estimated'>*</span>
                </div>
              </div>
            </div>
          </div>
          <div className='score-summary__cell-container'>
            <div className='score-summary__cell score-summary__cell--total'>
              <div className='score-summary__cell-min-height'>
                <div className='score-summary__label-small'>
                  {t('diagnostics.table.headers.amount_correct')}
                </div>
                <div className='score-summary__label-answers'>
                  {totalCorrectAnswers}{' '}
                  <span className='score-summary__label-answers--highlighted'>
                    of {totalAnswers}
                    <span className='score-summary__estimated'>*</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className='score-summary__estimated-label'>
        *{t('diagnostics.estimated')}
      </span>
    </ScoreSummaryContainer>
  )
}

const ScoreSummaryContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  .score-summary__details-container {
    width: 100%;
  }

  .score-summary__cell {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 0;
  }

  .score-summary__details {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
    margin-right: 10%;
    padding: 0.5rem 0;
  }

  .score-summary__label {
    font-size: 1.5714rem;
    font-weight: bold;
  }

  .score-summary__label-answers {
    font-size: 1.5714rem;
    font-weight: bold;
  }

  .score-summary__label-answers--highlighted {
    font-size: 1.5714rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.main.secondary500};
  }

  .score-summary__cell-target {
    .score-summary__label-answers--highlighted {
      color: ${({ theme }) => theme.colors.main.secondary500};
    }
  }

  .score-summary__target-score {
    font-size: 1.5714rem;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 2px;
    margin-left: 15px;
    display: flex;
  }

  .score-summary__cell-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .score-summary__label-small {
    font-size: 0.8571rem;
    margin-bottom: 0.36rem;
    text-align: center;
  }

  .score-summary__target-container {
    display: flex;
    align-items: center;
    margin-bottom: -0.2rem;
  }

  .score-summary__cell-min-height {
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .score-summary__estimated {
  }

  .score-summary__estimated-label {
    font-size: 0.8571rem;
    position: absolute;
    bottom: 7px;
    right: 6px;
  }
`

export default ScoreSummary

import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import qs from 'qs'
import styled from 'styled-components'
import { propOr, pipe } from 'ramda'

import { BOOK_VIEWER_PARAMS } from 'utils/books'
import { getBookChapterImages } from 'modules/books/ducks/selectors'
import FixedImage from 'modules/books/components/FixedImage'

import { RootReducer } from 'types'

export const ChapterImagesPreview = (): JSX.Element => {
  const { location, push } = useHistory()
  const { search, pathname } = location

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const chapterImages = useSelector((state: RootReducer) =>
    getBookChapterImages(state)
  )

  const currentSlide = pipe(
    propOr('0', BOOK_VIEWER_PARAMS.chapterImageSlide),
    Number
  )(parsedQuery)

  const setCurrentSlide = value => {
    const newQuery = qs.stringify({
      ...parsedQuery,
      [BOOK_VIEWER_PARAMS.chapterImageSlide]: value
    })

    push(`${pathname}?${newQuery}`)
  }

  const getImageByIndex = chapterImages[currentSlide]
  const pagesTotal = chapterImages.length
  const currentPage = currentSlide + 1

  const changePage = newPage => () => {
    setCurrentSlide(newPage - 1)
  }

  return (
    <Container>
      <FixedImage
        image={getImageByIndex}
        handleNext={changePage(currentPage + 1)}
        disableNext={pagesTotal === currentPage || pagesTotal === 0}
        handlePrevious={changePage(currentPage - 1)}
        disablePrevious={currentPage === 1 || pagesTotal === 0}
      />
    </Container>
  )
}

export default ChapterImagesPreview

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  overflow: hidden;
`

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import * as R from 'ramda'
import {
  ExamContent,
  ExamAnswers,
  ResponseRequiredModal
} from 'examkrackers-components'

import PageIds from 'modules/exam/utils/pageIds'
import { getExamDetails, getFirstSectionId } from 'modules/exam/ducks/selectors'

export const Certification = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props

  const [responseRequiredModalOpen, setResponseRequiredModalVisibility] =
    useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState('')

  const firstSectionId = useSelector(getFirstSectionId)
  const examDetails = useSelector(getExamDetails)
  const examSectionsCount = R.pathOr(1, ['type', 'section_count'], examDetails)

  const openResponseRequiredModal = () =>
    setResponseRequiredModalVisibility(true)
  const closeResponseRequiredModal = () =>
    setResponseRequiredModalVisibility(false)

  const nextId = examSectionsCount > 1 ? PageIds.tutorialStart : firstSectionId

  const handleNext = () => {
    setCurrentPage(
      selectedAnswer === 'B' ? PageIds.certificationDecline : nextId
    )
  }

  const onNextClick = () => {
    if (selectedAnswer === '') {
      openResponseRequiredModal()
    } else {
      handleNext()
    }
  }

  useEffect(() => {
    setOnTimeEnd(handleNext)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: onNextClick
    }))
  }, [selectedAnswer])

  const certificationAnswers = [
    {
      answerCode: 'A',
      answerContent: 'I agree'
    },
    {
      answerCode: 'B',
      answerContent: 'I do NOT agree'
    }
  ]

  return (
    <ExamContent>
      <CertificationContainer>
        <div>
          <div className='heavy-bordered test-day-certification__description'>
            <h2>TEST-DAY CERTIFICATION</h2>
            <p>
              By proceeding with Examkrackers practice MCAT exam, you certify
              that you have not and will not engage in any conduct that
              compromises the integrity, validity, or security of the MCAT exam.
              You certify that:
            </p>
            <ul>
              <li>
                Prior to the exam, you did not give or receive information about
                the questions that might appear on this exam. During the exam,
                you will not give or receive information about the content of
                the exam.
              </li>
              <li>
                You will keep the content on this exam confidential
                indefinitely, including, but not limited to: exam questions,
                passages, graphics, incorrect responses, correct answers, and
                topic lists, frequency and order of exam topics, when described
                in more specific detail than the What’s on the Examkrackers
                practice MCAT Exam.
              </li>
              <li>
                You are the person whose name appears on this practice MCAT exam
                registration and you meet the eligibility requirements for
                sitting for the practice MCAT exam.
              </li>
              <li>
                You are taking the Examkrackers MCAT exam for the sole purpose
                of improving your score on an official MCAT which may lead to
                you applying to and attending a health-professions school and
                program that accepts MCAT exam results to satisfy a test-score
                admissions requirement, unless otherwise approved by the
                Examkrackers.
              </li>
              <li>
                You will not access any written materials, electronic devices,
                cell phones, or other unauthorized items at any point during
                today’s practice MCAT administration which includes any
                scheduled or unscheduled breaks.
              </li>
              <li>
                You will abide by all Examkrackers practice MCAT policies and
                procedures found, including the Examrackers Agreement to which
                you agreed when you purchased this course.
              </li>
              <li>
                You understand that a violation of any Examkrackers practice
                MCAT rule may result in an investigation which could lead to a
                report of factual findings sent to legitimately interested
                parties, score cancellation, a partial or permanent ban from
                taking the Examkrackers practice MCAT in the future, or civil or
                criminal penalties.
              </li>
            </ul>
          </div>
          <p className='question__content'>
            <strong>
              I have read, and agree to comply with, the terms of this Test-Day
              Certification, including all additional terms and policies set
              forth in
              <i> Examkrackers practice MCAT policies and procedures, </i>
              and I understand the consequences for failing to comply.
            </strong>
          </p>
          <div>
            <ExamAnswers
              answers={certificationAnswers}
              onSelectAnswer={setSelectedAnswer}
              selectedAnswerCode={selectedAnswer}
            />
          </div>
        </div>
        <ResponseRequiredModal
          initHeight={185}
          open={responseRequiredModalOpen}
          handleClose={closeResponseRequiredModal}
        />
      </CertificationContainer>
    </ExamContent>
  )
}

export default Certification

const CertificationContainer = styled.div`
  h2 {
    margin-bottom: 0;
  }

  .test-day-certification__description {
    min-height: 376.78px;
  }

  .answer {
    margin-top: 15px;
  }

  .question__content {
    margin-bottom: 35px;
  }
`

import React from 'react'
import styled from 'styled-components'
import ModalResetFlashcards from './ModalResetFlashcards'
import FlashcardsProficiencyDrawersAnimation from './FlashcardsProficiencyDrawersAnimation'
import FlashcardsProficiencyDrawersList from './FlashcardsProficiencyDrawersList'
import FlashcardsSkippedAnimation from './FlashcardsSkippedAnimation'
import FlashcardsRound from './FlashcardsRound'

const FlashcardsProficiencyDrawers = () => {
  return (
    <ProficiencyBoxes id='wrapper'>
      <FlashcardsProficiencyDrawersAnimation />
      <FlashcardsSkippedAnimation />
      <FlashcardsRound />
      <FlashcardsProficiencyDrawersList />
      <ResetContainer>
        <ModalResetFlashcards />
      </ResetContainer>
    </ProficiencyBoxes>
  )
}

export default FlashcardsProficiencyDrawers

const ProficiencyBoxes = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

const ResetContainer = styled.div`
  width: 210px;
  text-align: center;
`

export const GENERAL_SOCKET_EVENTS = {
  connect: 'connect',
  disconnect: 'disconnect',
  connectError: 'connect_error',
  error: 'error'
}

export const NOTIFICATIONS_EVENTS = {
  notification: 'notification'
}

import pile1000 from './piles/pile-1000.svg'
import pile900 from './piles/pile-900.svg'
import pile800 from './piles/pile-800.svg'
import pile700 from './piles/pile-700.svg'
import pile600 from './piles/pile-600.svg'
import pile500 from './piles/pile-500.svg'
import pile400 from './piles/pile-400.svg'
import pile300 from './piles/pile-300.svg'
import pile200 from './piles/pile-200.svg'
import pile100 from './piles/pile-100.svg'
import pile75 from './piles/pile-75.svg'
import pile50 from './piles/pile-50.svg'
import pile25 from './piles/pile-25.svg'
import pile20 from './piles/pile-20.svg'
import pile15 from './piles/pile-15.svg'
import pile10 from './piles/pile-10.svg'
import pile9 from './piles/pile-9.svg'
import pile8 from './piles/pile-8.svg'
import pile7 from './piles/pile-7.svg'
import pile6 from './piles/pile-6.svg'
import pile5 from './piles/pile-5.svg'
import pile4 from './piles/pile-4.svg'
import pile3 from './piles/pile-3.svg'
import pile2 from './piles/pile-2.svg'
import pile1 from './piles/pile-1.svg'

export const getPileImageByCardsNumber = cardsNumber => {
  switch (true) {
    case cardsNumber >= 1000:
      return pile1000
    case cardsNumber < 1000 && cardsNumber >= 900:
      return pile900
    case cardsNumber < 900 && cardsNumber >= 800:
      return pile800
    case cardsNumber < 800 && cardsNumber >= 700:
      return pile700
    case cardsNumber < 700 && cardsNumber >= 600:
      return pile600
    case cardsNumber < 600 && cardsNumber >= 500:
      return pile500
    case cardsNumber < 500 && cardsNumber >= 400:
      return pile400
    case cardsNumber < 400 && cardsNumber >= 300:
      return pile300
    case cardsNumber < 300 && cardsNumber >= 200:
      return pile200
    case cardsNumber < 200 && cardsNumber >= 100:
      return pile100
    case cardsNumber < 100 && cardsNumber >= 75:
      return pile75
    case cardsNumber < 75 && cardsNumber >= 50:
      return pile50
    case cardsNumber < 50 && cardsNumber >= 25:
      return pile25
    case cardsNumber < 25 && cardsNumber >= 20:
      return pile20
    case cardsNumber < 20 && cardsNumber >= 15:
      return pile15
    case cardsNumber < 15 && cardsNumber >= 10:
      return pile10
    case cardsNumber < 10 && cardsNumber >= 9:
      return pile9
    case cardsNumber < 9 && cardsNumber >= 8:
      return pile8
    case cardsNumber < 8 && cardsNumber >= 7:
      return pile7
    case cardsNumber < 7 && cardsNumber >= 6:
      return pile6
    case cardsNumber < 6 && cardsNumber >= 5:
      return pile5
    case cardsNumber < 5 && cardsNumber >= 4:
      return pile4
    case cardsNumber < 4 && cardsNumber >= 3:
      return pile3
    case cardsNumber < 3 && cardsNumber >= 2:
      return pile2
    case cardsNumber < 2 && cardsNumber >= 1:
      return pile1
    default:
      return pile1
  }
}

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { StarIcon } from 'examkrackers-components'

interface IconProps {
  fillTheStar: string
  onMouseEnterEvent?: () => void
  onMouseLeaveEvent?: () => void
  index: number
  rating: number
  handleClickFunction: (index: number) => void
  hoverFillStar?: number
}

const VideoIconStar = ({
  onMouseEnterEvent,
  onMouseLeaveEvent,
  fillTheStar,
  index,
  rating,
  handleClickFunction,
  hoverFillStar
}: IconProps) => {
  const fillStarsOnHover = useMemo(() => {
    if (hoverFillStar === undefined) return fillTheStar

    if (hoverFillStar > rating && hoverFillStar >= index) {
      return fillTheStar
    } else if (index <= hoverFillStar || index <= rating) {
      return fillTheStar
    } else {
      return '#DDDFE1'
    }
  }, [fillTheStar, hoverFillStar, index, rating])

  return (
    <StarContainer
      key={index}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      fill={fillStarsOnHover}
      onClick={() => handleClickFunction(index)}
      onMouseEnter={onMouseEnterEvent}
      onMouseLeave={onMouseLeaveEvent}
    >
      <StarIcon />
    </StarContainer>
  )
}

export default VideoIconStar

const StarContainer = styled.div`
  svg {
    font-size: 20px;
    color: ${({ fill }) => fill};
  }
`

import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import * as onboardingService from 'services/OnboardingService'
import { ActionPayload } from 'types'

// ROUTINES
export const fetchOnboardingCategoriesRoutine = createRoutine(
  'FETCH_ONBOARDING_CATEGORIES'
)
export const fetchCategoryImagesRoutine = createRoutine('FETCH_CATEGORY_IMAGES')

// ACTIONS
function* fetchOnboardingCategories() {
  yield Effects.put(fetchOnboardingCategoriesRoutine.request())
  try {
    const result = yield Effects.call(onboardingService.fetchCategories, {
      query: '?limit[page]=1&limit[take]=1000'
    })
    yield Effects.put(
      fetchOnboardingCategoriesRoutine.success(R.propOr([], 'data', result))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchOnboardingCategoriesRoutine.failure(e))
  }
}

function* fetchCategoryImages({ payload }: ActionPayload) {
  yield Effects.put(fetchCategoryImagesRoutine.request())
  try {
    const result = yield Effects.call(
      onboardingService.fetchCategoryImages,
      payload
    )
    yield Effects.put(
      fetchCategoryImagesRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchCategoryImagesRoutine.failure(e))
  }
}

// WATCHERS

// watcher FETCH_ONBOARDING_CATEGORIES
export function* fetchOnboardingCategoriesWatcher() {
  yield Effects.takeLatest(
    fetchOnboardingCategoriesRoutine.TRIGGER,
    fetchOnboardingCategories
  )
}
// watcher FETCH_CATEGORY_IMAGES
export function* fetchCategoryImagesWatcher() {
  yield Effects.takeLatest(
    fetchCategoryImagesRoutine.TRIGGER,
    fetchCategoryImages
  )
}
// SAGAS
export const onboardingSagas = [
  Effects.fork(fetchOnboardingCategoriesWatcher),
  Effects.fork(fetchCategoryImagesWatcher)
]

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FormChangeTargetScore from 'modules/examTypes/forms/FormChangeTargetScore'
import { Modal, EditIcon, IconButton } from 'examkrackers-components'

interface ModalChangeTargetScoreProps {
  sectionOrder?: number
  initialValue: number
}

export const ModalChangeTargetScore = (props: ModalChangeTargetScoreProps) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton
        id='change-target-score'
        name='change-score'
        icon={<EditIcon />}
        onClick={handleOpen}
        color='black'
        variant='transparent'
      />
      <Modal
        title={t('diagnostics.changeTargetScore.title')}
        open={open}
        handleClose={handleClose}
      >
        <FormChangeTargetScore
          sectionOrder={props.sectionOrder}
          initialValue={props.initialValue}
          onSubmitSuccess={handleClose}
        />
      </Modal>
    </>
  )
}

export default ModalChangeTargetScore

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Modal, Button } from 'examkrackers-components'

import { startExam } from 'services/ExamsService'
import { isNil, propOr } from 'ramda'
import { isNotNil } from 'utils/ramda'

import { showToastRoutine } from 'modules/toast/ducks/actions'
import { getStartExamError } from 'modules/exams/ducks/errors'
import { useDispatch, useSelector } from 'react-redux'

import AnalyticsService from 'services/AnalyticsService'
import {
  getAccountType,
  getIsImpersonate,
  getStudent
} from '../../auth/ducks/selectors'

import { Exam } from 'types'
import { DATE_FORMATS, formatDate } from '../../../utils/date'
import { ANALYTICS_EVENTS } from 'utils/analytics'

interface ModalConfirmProps {
  id: string
  disabled: boolean
  isDisabledForFreeTrial?: boolean
  isOpen?: boolean
  closeCallback?: any
  exam: Exam
  isCalendarPage?: boolean
}

export const ModalConfirmStart = (props: ModalConfirmProps) => {
  const {
    exam,
    id,
    disabled,
    isOpen,
    closeCallback,
    isDisabledForFreeTrial,
    isCalendarPage
  } = props
  const { push } = useHistory()
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const student = useSelector(getStudent)
  const title = propOr('', 'title', exam)
  const type_title = propOr('', 'type_title', exam)
  const time_option = propOr('', 'time_option', exam)
  const accountType: string = useSelector(getAccountType)
  const isImpersonate = useSelector(getIsImpersonate)

  const showToast = React.useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    if (isNotNil(isOpen)) {
      // @ts-ignore
      setOpen(isOpen)
    }
  }, [isOpen])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    AnalyticsService(student).logEvent(ANALYTICS_EVENTS.warningPopupClosed, {
      Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
      'Exam Title': title,
      'Exam format': type_title,
      'Account type': accountType,
      Cancel: true
    })
    typeof closeCallback === 'function' && closeCallback()
  }

  const redirectToExam = (id: string) => () => {
    AnalyticsService(student).logEvent(ANALYTICS_EVENTS.examStarted, {
      Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
      'Time option': time_option,
      'Exam Title': title,
      'Exam format': type_title,
      'Account type': accountType
    })
    push(`/exam/${id}`)
  }

  const handleStartExam = () => {
    const timeOptionElement = document.querySelectorAll(
      `[data-exam-time-option-id="${id}"]`
    )[0]
    const selectedTimeOption =
      timeOptionElement?.getAttribute('data-exam-time-option-value') || '1.0'

    const handleError = e => {
      console.error(e)
      showToast(getStartExamError(e))
    }
    const handleSuccess = redirectToExam(id)

    startExam({ id, timeOption: selectedTimeOption })
      .then(handleSuccess)
      .catch(handleError)
  }

  const tooltipId = isDisabledForFreeTrial
    ? `tooltip-unavailable-${id}`
    : undefined
  const tooltip = isDisabledForFreeTrial
    ? t('toast.examNotAvailableInFreeTrial')
    : undefined

  const renderExamButton = () => {
    if (isCalendarPage) {
      return (
        <Button
          disabled={disabled}
          id={`${id}-start-exam`}
          onClick={handleOpen}
          size='small'
          color='secondary'
          tooltipId={tooltipId}
          tooltip={tooltip}
        >
          {t('calendar.button.beginExam')}
        </Button>
      )
    } else {
      return (
        <Button
          disabled={disabled}
          id={`${id}-start-exam`}
          onClick={handleOpen}
          size='small'
          color='secondary'
          tooltipId={tooltipId}
          tooltip={tooltip}
        >
          {t('exams.actions.start')}
        </Button>
      )
    }
  }

  return (
    <>
      {isNil(isOpen) && renderExamButton()}
      <Modal
        title={t('exams.startExam.title')}
        open={open}
        handleClose={handleClose}
      >
        <ModalContainer>
          <ExamTitle>Starting exam: {exam.title}</ExamTitle>
          <ul>
            <li>{t('exams.startExam.description1')}</li>
            <li>{t('exams.startExam.description2')}</li>
            <li>{t('exams.startExam.description3')}</li>
            <li>{t('exams.startExam.description4')}</li>
          </ul>
          <ButtonContainer>
            <Button
              size='small'
              color='secondary'
              type='submit'
              id='start-exam-submit'
              onClick={handleStartExam}
              // @ts-ignore
              disabled={isImpersonate}
            >
              {t('exams.startExam.submitCTA')}
            </Button>
            <Button
              size='small'
              type='submit'
              color='tertiary'
              id='start-exam-cancel'
              onClick={handleClose}
            >
              {t('exams.resumeExam.cancelCTA')}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default ModalConfirmStart

const ButtonContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    min-width: 130px;
  }

  button + button {
    margin-left: 10px;
  }
`

const ModalContainer = styled.div`
  max-width: 400px;
  text-align: left;
  margin: 0 auto;

  ul {
    list-style-type: disc;
    margin-left: 14px;

    li {
      margin-bottom: 10px;
    }
  }
`

const ExamTitle = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`

export default {
  notesLoading: 'notes_loading',
  notesSavedSuccess: 'notes_saved_success',
  notesSavedFailure: 'notes_saved_failure',
  updatePath: 'update_path',
  showCQAnimation: 'show_CQ_animation',
  contentQuestionLoading: 'CQ_loading',
  openResource: 'open_resource',
  showGlossaryTooltip: 'showGlossaryTooltip',
  resourceMouseEnter: 'resource_mouse_enter',
  resourceMouseLeaveAndClose: 'resource_mouse_leave_and_close',
  resourceClick: 'resource_click',
  resourceVideoWatched: 'resource_video_watched',
  bookContentSelection: 'book_content_selection',
  attachmentSelection: 'attachment_selection',
  textResourceSelection: 'text_resource_selection',
  redirectAndScrollToBookContent: 'redirect_and_scroll_to_book_content',
  restorationSingleCq: 'restoring_single_cq',
  restorationChapterCq: 'restoring_chapter_cq',
  refreshPinNotesList: 'refresh_pin_notes_list',
  highlightSearchPhrase: 'highlight_search_phrase',
  padlockLocked: 'padlockLocked',
  pinNoteDeleted: 'pin_note_deleted'
}

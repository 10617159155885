import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { propOr, pipe, equals } from 'ramda'
import qs from 'qs'

import { BOOK_VIEWER_PARAMS } from 'utils/books'
import Image from 'components/Image'
import {
  Button,
  NavArrowLeftIcon,
  NavArrowRightIcon
} from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'utils/ramda'

export const FixedImage = ({
  image,
  handleNext,
  disableNext,
  handlePrevious,
  disablePrevious
}): JSX.Element => {
  const { location } = useHistory()
  const { t } = useTranslation()
  const { search } = location

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const isTopExpanded = pipe(
    propOr('false', BOOK_VIEWER_PARAMS.rightTopExpand),
    equals('true')
  )(parsedQuery)

  const imageSmallUrl: string = propOr('', 'small_ver', image)
  const imageLargeUrl: string = propOr('', 'image', image)

  const imageSmall = imageSmallUrl || imageLargeUrl
  const imageLarge = imageLargeUrl || imageSmallUrl

  const imageUrl = isTopExpanded ? imageLarge : imageSmall

  return (
    <ImageContainer>
      {isNotNilOrEmpty(imageUrl) && <Image url={imageUrl} />}
      <div className='image-nav-btns'>
        <Button
          size='small'
          variant='contained'
          color='tertiary'
          onClick={handlePrevious}
          disabled={disablePrevious}
          startIcon={<NavArrowLeftIcon />}
        >
          {t('flashcards.study.previous')}
        </Button>
        <Button
          size='small'
          variant='contained'
          color='tertiary'
          onClick={handleNext}
          disabled={disableNext}
          endIcon={<NavArrowRightIcon />}
        >
          {t('flashcards.study.next')}
        </Button>
      </div>
    </ImageContainer>
  )
}

export default FixedImage

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  div[aria-label='animation'] {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    max-width: 100%;
  }

  & > div:first-child,
  & > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    max-width: 100%;
  }

  div[aria-label='animation'] svg {
    max-height: 100%;
    max-width: 100%;
  }

  & > div:first-child img,
  & > div:first-child object {
    max-height: 100%;
    max-width: 100%;
  }

  .image-nav-btns {
    display: none;
    opacity: 0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 100px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    transition: opacity 600ms
      ${({ theme }) => theme.transitions.easing.easeInOut};

    button {
      z-index: 2;
    }
  }

  .controls-container {
    display: none;
    position: absolute;
    bottom: 10px;
    z-index: 1;
    width: 100%;
    height: ${({ theme }) => theme.dimensions.buttonSmallHeight};
  }

  &:hover .image-nav-btns {
    display: flex;
    opacity: 1;
    margin: 0 auto;
  }

  &:hover .controls-container {
    display: flex;
    opacity: 1;
  }

  .controls-container > div {
    background: ${({ theme }) => theme.colors.backgrounds.main};
  }

  .speed-options {
    top: calc(-150% - 5px);
  }
`

import i18n from 'providers/i18n'

export const sortingOptions = () => [
  {
    label: i18n.t('notes.section'),
    value: 'natural'
  },
  {
    label: i18n.t('notes.update'),
    value: 'updated_at'
  }
]

import React, { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import styled from 'styled-components'

import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import PATHS from 'utils/paths'
import { isDateBeforeToday, DATE_FORMATS, formatDate } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import { SEVERITY } from 'utils/toast'

import LocalStorageService from 'services/LocalStorageService'
import { initialiseCourse } from 'services/CourseService'

import { fetchCoursesListRoutine } from 'modules/courses/ducks/actions'
import {
  getCoursesList,
  getCurrentCourse
} from 'modules/courses/ducks/selectors'
import { showToastRoutine } from 'modules/toast/ducks/actions'

import {
  Button,
  CheckmarkContainedIcon,
  EditIcon,
  IconButton,
  ProfileIcon
} from 'examkrackers-components'

import { Course } from 'types'
import { useHistory } from 'react-router-dom'
import useOutsideClick from 'hooks/useOutsideClick'
import { getStudent } from 'modules/auth/ducks/selectors'

import AnalyticsService from 'services/AnalyticsService'
import {
  ANALYTICS_EVENTS,
  handleActionAnalytics,
  getCourseTypeForAnalyticsEvents
} from 'utils/analytics'
import { getMcatDates } from 'services/CalendarService'

const ProfileDropdown = ({ handleOpenModalEditUsername }) => {
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { push } = useHistory()
  const containerRef = useRef(null)
  const user = useSelector(getStudent)
  const name = propOr('', 'name', user)
  const email = propOr('', 'email', user)

  const courses: Course[] = useSelector(getCoursesList)

  const currentCourse: Course = useSelector(getCurrentCourse)

  const fetchCourses = React.useCallback(
    () => dispatch(fetchCoursesListRoutine()),
    [dispatch]
  )

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  React.useEffect(() => {
    fetchCourses()
  }, [])

  const handleOpen = () => setOpen(open => !open)
  const handleClose = () => setOpen(false)
  useOutsideClick(containerRef, handleClose)

  const redirectToSelectCourses = () => {
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.navigateToAllCourseslist, {
      'Course type': currentCourse?.type || false,
      'Course name': currentCourse?.title || false,
      'Course expiration date': currentCourse?.accessible_to || false
    })
    // using window.location.href, to re-render the whole app
    window.location.href = PATHS.selectCourse
  }

  const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)

  const handleChangeCourse = course => () => {
    const accessibleTo = propOr('', 'accessible_to', course)
    const isReady = propOr(false, 'is_ready', course)
    const isExpired = isDateBeforeToday(accessibleTo)
    const saveCourseInLocalStorage = () => {
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseType)
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseEndDateId)
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.originalCourseId)
      LocalStorageService.set(LOCAL_STORAGE_KEYS.studentCourseId, course.id)
      LocalStorageService.set(LOCAL_STORAGE_KEYS.studentCourseType, course.type)
      LocalStorageService.set(
        LOCAL_STORAGE_KEYS.originalCourseId,
        course.book_course_id
      )
      course.end_date_id
        ? LocalStorageService.set(
            LOCAL_STORAGE_KEYS.studentCourseEndDateId,
            course.end_date_id
          )
        : null
    }

    const redirectToCourseDashboard = () => {
      saveCourseInLocalStorage()

      // using window.location.href, to re-render the whole app
      window.location.href = PATHS.dashboard
    }

    const handleInitialise = () => {
      const handleSuccess = () => {}
      const handleError = e => {
        console.error(e)
        showToast({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error
        })
      }

      initialiseCourse({
        id: course.id
      })
        .then(handleSuccess)
        .catch(handleError)

      saveCourseInLocalStorage()
      // using window.location.href, to re-render the whole app
      window.location.href = PATHS.dashboardLoading
    }

    switch (true) {
      case isExpired:
        return () => {}
      case isReady:
        return redirectToCourseDashboard()
      default:
        return handleInitialise()
    }
  }

  const courseAnalysis = course => {
    AnalyticsService(user).logEvent(
      ANALYTICS_EVENTS[
        getCourseTypeForAnalyticsEvents(
          handleActionAnalytics(course),
          course?.type
        )
      ],
      {
        Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
        'Course type': course?.type || false,
        'Course name': course?.title || false,
        'Course expiration date': course?.accessible_to || false
      }
    )
  }

  const handleChangeCourseTimeout = course => () => {
    // this is because there are some endpoints that are sending
    // course activity timers on components unmount with actual course id from
    // the local storage. So to update the entity with proper course id (before it is changed here)
    // I'm unmounting the components by redirecting first to the dashboard and then changing the
    // course context
    courseAnalysis(course)
    // push(PATHS.dashboard)
    setTimeout(handleChangeCourse(course), 500)
  }

  const coursesList = courses.map((course: Course) => {
    const accessibleTo = propOr('', 'accessible_to', course)
    const isStarted = isNotNilOrEmpty(accessibleTo)
    const isExpired = isDateBeforeToday(accessibleTo)
    const className = isExpired
      ? 'course-dropdown-item expired'
      : 'course-dropdown-item'

    const isDisabled = isStarted === false

    const CourseRow = handler => (
      <CourseRowWrapper
        onClick={handler}
        className={className}
        key={`course-dropdown-${course.id}`}
        disabled={isDisabled}
      >
        <WrapperInfo>
          <div>{propOr('', 'title', course)}</div>
          <div>
            ({t(`courses.courseTypes.${propOr('unknown', 'type', course)}`)})
          </div>
        </WrapperInfo>
        {courseId === course.id && (
          <div className='course-dropdown-checkmark'>
            <CheckmarkContainedIcon />
          </div>
        )}
      </CourseRowWrapper>
    )

    const isFreeTrial = course.type === 'free_trial'
    const isLiveCourse = course.type === 'live_course'

    const handleStartFreeTrialCourse = () => {
      const courseId = course?.book_course_id || null

      if (courseId) {
        getMcatDates({ id: courseId })
          .then(() => {
            // legacy way of setting priori days and exam date for free trial, must change the call to an async function if needed
            // const dates = resp.data.data
            // const startDate = parseISO(course?.accessible_from || '')
            // const mcatDate = findNearestMcatDate(dates)
            // const endDate = subDays(parseISO(mcatDate?.mcat_date || ''), 7)

            // const result = {
            //   startDate: getUniversalDateString(startDate),
            //   endDate: getUniversalDateString(endDate),
            //   mcatDateId: mcatDate.id
            // }

            push(PATHS.dashboardLoading)
            initialiseCourse({ id: course.id }),
              // await Promise.all([
              //   setPrioriDays({
              //     id: course.id,
              //     prioridays: [7, 1, 2, 3, 4, 0, 6]
              //   }),
              //   setExamDate({
              //     calendar_start_at: result.startDate,
              //     exam_at: result.endDate,
              //     course_id: course.id,
              //     mcat_date_id: result.mcatDateId
              //   })
              // ])

              handleChangeCourseTimeout(course)()
          })
          .catch(err => {
            console.error(err)
          })
      }
    }

    // const daysAmount = pipe(
    //   propOr('{}', 'metadata'),
    //   JSON.parse,
    //   propOr('0', 'days_amount'),
    //   Number
    // )(course)

    return (isStarted && !isFreeTrial && !isLiveCourse) ||
      (isFreeTrial && isNotNilOrEmpty(course?.exam_at))
      ? CourseRow(handleChangeCourseTimeout(course))
      : isFreeTrial
      ? CourseRow(handleStartFreeTrialCourse)
      : isLiveCourse
      ? CourseRow(handleChangeCourseTimeout(course))
      : CourseRow(() => {
          push({ pathname: PATHS.calendarSetup, state: { course } })
        })
    // <ModalInitialiseCourse
    //   course={course}
    //   key={`course-dropdown-modal-${course.id}`}
    //   handleSubmit={handleChangeCourseTimeout(course)}
    //   trigger={CourseRow(() => {})}
    //   title={t('calendar.settings.formTitle')}
    //   description={t('courses.startCourseModal.description1', {
    //     courseName: propOr('-', 'subtitle', course)
    //   })}
    // />
  })

  return (
    <Container onClick={handleOpen} ref={containerRef}>
      <StyledIconButton
        icon={<ProfileIcon />}
        variant='transparent'
        tooltip={t('navigation.profile.tooltip')}
        tooltipId='profile-dropdown-button'
        size='medium'
      />
      <Menu open={open}>
        <div className='top-label'>{t('navigation.profile.title')}</div>
        <div className='profile-dropdown-item'>
          <div
            className='profile-item-container'
            onClick={handleOpenModalEditUsername}
          >
            <div className='profile-details'>
              <div className='profile-name'>{name}</div>
              <div className='profile-email'>{email}</div>
            </div>
            <div className='profile-icon'>
              <EditIcon />
            </div>
          </div>
        </div>
        <div className='courses-top-label'>{t('courses.title')}</div>
        <div className='courses-container'>{coursesList}</div>
        <div className='all-courses-container'>
          <Button
            variant='contained'
            color='tertiary'
            onClick={redirectToSelectCourses}
            size='small'
          >
            {t('courses.allCourses')}
          </Button>
        </div>
      </Menu>
    </Container>
  )
}

export default ProfileDropdown

const Container = styled.div`
  margin: 0 4px;
  position: relative;
`

const Menu = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  right: 0;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.mainShadow};
  border: 1px solid ${props => props.theme.colors.main.grey300};
  min-width: 304px;
  animation: fadeIn 300ms ease-out;
  padding: 16px;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .top-label,
  .courses-top-label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }

  .courses-top-label {
    padding-top: 16px;
  }

  .top-label {
    font-size: 16px;
  }

  .courses-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
  }

  .all-courses-container {
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      margin: 0 auto 16px;
      height: 1px;
      background-color: ${props => props.theme.colors.main.grey300};
    }
  }

  .profile-dropdown-item,
  .course-dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    &.expired:hover {
      box-shadow: none;
    }

    &.expired {
      color: ${({ theme }) => theme.colors.main.grey600};
      cursor: not-allowed;
    }

    &.expired .course-dropdown-checkmark {
      color: ${({ theme }) => theme.colors.main.grey600};
    }
  }

  .course-dropdown-item {
    height: 32px;
    padding: 0 12px;
  }

  .profile-dropdown-item {
    margin-top: 12px;
    border: 1px solid ${({ theme }) => theme.colors.main.grey300};
    border-width: 1px 0 1px 0;
  }

  /* 
  .profile-details {
    display: flex;
    flex-direction: column;
  } */

  .profile-item-container {
    margin: 12px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .course-dropdown-item:hover,
  .profile-item-container:hover {
    background-color: ${({ theme }) => theme.colors.main.grey200};
    border-radius: 6px;
    outline: 2px solid ${({ theme }) => theme.colors.main.grey200};
  }

  .profile-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .profile-email {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.main.grey600};
  }

  .profile-icon {
    color: ${({ theme }) => theme.colors.main.grey600};
  }

  .course-dropdown-checkmark {
    color: ${({ theme }) => theme.colors.main.success500};
    font-size: 22px;
    display: flex;
    align-items: center;
  }
`
const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.main.text};

  :hover {
    color: ${({ theme }) => theme.colors.main.primary500} !important;
    background-color: ${({ theme }) => theme.colors.main.grey300} !important;
  }
`
const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const CourseRowWrapper = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  head,
  isNil,
  pathOr,
  propOr,
  map,
  pipe,
  find,
  propEq,
  add,
  has,
  sum
} from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { useHistory } from 'react-router-dom'

import {
  getFlashcardsProficiencyLevelStats,
  getFlashcardsStudyList,
  getSkippedCount
} from 'modules/flashcards/ducks/selectors'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/flashcards/utils/events'

import FlashcardAnswer from 'modules/flashcards/components/FlashcardAnswer'
import {
  fetchFlashcardsStudyListRoutine,
  skipCardRoutine,
  resetSkippedCardsRoutine
} from '../flashcards/ducks/actions'
import {
  findLowestLevelWithFlashcards,
  statsWithSkippedIncluded,
  findNextLevelWithFlashcards,
  checkIfNextLevelEmpty,
  setRoundNumber
} from 'utils/flashcards'
import { isNotNilOrEmpty } from 'utils/ramda'
import FlashcardsHotkeysLegend from 'modules/flashcards/components/FlashcardsHotkeysLegend'
import usePrevious from '../../hooks/usePrevious'
import EmptyStudyState from './components/EmptySudyState'

const ColumnRightFlashcardsStudy = () => {
  const {
    replace,
    location: { pathname, search, state }
  } = useHistory()
  const dispatch = useDispatch()
  const proficiencyStats = useSelector(getFlashcardsProficiencyLevelStats)

  const fetchFlashcardStudy = useCallback(
    query => dispatch(fetchFlashcardsStudyListRoutine({ query })),
    [dispatch]
  )

  const skipCard = useCallback(
    plvl => dispatch(skipCardRoutine({ plvl })),
    [dispatch]
  )

  const resetSkippedCards = useCallback(
    plvl => dispatch(resetSkippedCardsRoutine({ plvl })),
    [dispatch]
  )

  const flashcards = useSelector(getFlashcardsStudyList)
  const currentFlashcard = head(flashcards)

  const prevSearch = usePrevious(search)

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const prevParsedQuery = qs.parse(prevSearch, { ignoreQueryPrefix: true })

  const currentPage: number = pipe(
    pathOr('1', ['limit', 'page']),
    Number
  )(parsedQuery)
  const skippedCount: number = useSelector(getSkippedCount)
  const queryPlvl = Number(
    pathOr(1, ['filter', 'f.proficiency_level'], parsedQuery)
  )

  const actualStats = statsWithSkippedIncluded(
    proficiencyStats,
    queryPlvl,
    skippedCount
  )

  const selectedProfLevel = Number(
    pathOr(
      findLowestLevelWithFlashcards(actualStats),
      ['filter', 'f.proficiency_level'],
      parsedQuery
    )
  )

  const prevSelectedProfLevel = Number(
    pathOr(
      findLowestLevelWithFlashcards(actualStats),
      ['filter', 'f.proficiency_level'],
      prevParsedQuery
    )
  )

  const totalCardsInLvl = pipe(
    find(propEq('proficiency_level', selectedProfLevel)),
    propOr(0, 'count'),
    add(skippedCount)
  )(proficiencyStats)

  const isLastPage = currentPage === totalCardsInLvl || totalCardsInLvl === 0

  const [sessionNumber, setSessionNumber] = useState(null)

  useEffect(() => {
    if (prevSelectedProfLevel !== selectedProfLevel) {
      resetSkippedCards(prevSelectedProfLevel)
    }
  }, [selectedProfLevel])

  useEffect(() => {
    resetSkippedCards(selectedProfLevel)
  }, [sessionNumber])

  useEffect(() => {
    setRoundNumber(sessionNumber || 1)
  }, [sessionNumber])

  const resetSession = () => {
    // @ts-ignore
    setSessionNumber(1)
  }

  useEffect(() => {
    eventEmitter.on(events.flashcardArchived, handleNextAfterArchive)

    return () => {
      eventEmitter.off(events.flashcardArchived, handleNextAfterArchive)
    }
  })

  // reset session when student clicks "reset plvl" btn
  useEffect(() => {
    eventEmitter.on(events.resetPlvl, resetSession)

    return () => {
      eventEmitter.off(events.resetPlvl, resetSession)
    }
  }, [])

  useEffect(() => {
    const hasProfStats = isNotNilOrEmpty(proficiencyStats)

    if (isNil(sessionNumber) && hasProfStats) {
      // @ts-ignore
      setSessionNumber(selectedProfLevel)
    }
  }, [search, proficiencyStats])

  const handleChangePlvlManually = number => {
    setSessionNumber(number)
  }

  useEffect(() => {
    // @ts-ignore
    eventEmitter.on(events.changePlvlManually, handleChangePlvlManually)

    return () => {
      // @ts-ignore
      eventEmitter.off(events.changePlvlManually, handleChangePlvlManually)
    }
  }, [])

  const changePlvl = plvl => {
    const getPartFilterIfNeeded = has('part', parsedQuery)
      ? { part: parsedQuery.part }
      : {}

    const query = {
      ...getPartFilterIfNeeded,
      filter: {
        ...parsedQuery.filter,
        'f.proficiency_level': plvl
      },
      limit: {
        take: 1,
        page: 1
      }
    }

    replace(
      `${pathname}${qs.stringify(query, {
        encode: false,
        addQueryPrefix: true
      })}`,
      state
    )
  }

  const changePage = newPage => () => {
    const getPartFilterIfNeeded = has('part', parsedQuery)
      ? { part: parsedQuery.part }
      : {}

    const query = {
      ...getPartFilterIfNeeded,
      filter: parsedQuery.filter,
      limit: {
        take: 1,
        page: newPage
      }
    }

    replace(
      `${pathname}${qs.stringify(query, {
        encode: false,
        addQueryPrefix: true
      })}`,
      state
    )
  }

  const startNewSession = (stats = actualStats) => {
    // @ts-ignore
    setSessionNumber(prevState => {
      const currentSessionNumber = prevState || 0

      // if this is the maximum session (equal to the number of boxes) then do not change its number
      // if not, set the next session as the next non-empty box
      return Number(currentSessionNumber) === proficiencyStats.length
        ? proficiencyStats.length
        : findNextLevelWithFlashcards(stats, selectedProfLevel)
    })
    changePlvl(findLowestLevelWithFlashcards(stats))
  }

  const changeProficiencyLevelWithinSession = () => {
    changePlvl(findNextLevelWithFlashcards(actualStats, selectedProfLevel))
  }

  const changeProficiencyLevelToNextLevel = () => {
    changePlvl(Number(selectedProfLevel) + 1)
  }

  const changePageByOne = () => {
    changePage(currentPage + 1)()
  }

  // please see https://en.wikipedia.org/wiki/Leitner_system for reference
  const handleNext = (afterArchive = false) => {
    const isLastProficiencyLvlInSession =
      Number(sessionNumber) === Number(selectedProfLevel)

    const isNotLastPage = !isLastPage
    const isLastPageButIsNotLastBoxInSession =
      isLastPage && !isLastProficiencyLvlInSession
    const isLastPageAndIsLastBoxInSession =
      isLastPage && isLastProficiencyLvlInSession

    const isLastPageAndPrevIsArchived =
      isLastPage && afterArchive && totalCardsInLvl !== 0
    const lastCardWasArchived =
      isLastPage && afterArchive && totalCardsInLvl === 0

    if (isLastPageAndPrevIsArchived) {
      fetchFlashcardStudy(search)
    }

    if (isNotLastPage && afterArchive) {
      fetchFlashcardStudy(search)
    }

    if (isNotLastPage && !afterArchive) {
      changePageByOne()
    }

    if (isLastPageButIsNotLastBoxInSession) {
      changeProficiencyLevelWithinSession()
    }

    if (
      (isLastPageAndIsLastBoxInSession && !afterArchive) ||
      lastCardWasArchived
    ) {
      startNewSession()
      resetSkippedCards(selectedProfLevel)
    }
  }

  const handleNextAfterArchive = () => {
    handleNext(true)
  }

  const handleSkipCardIfNeeded = () => {
    if (!isLastPage) {
      skipCard(selectedProfLevel)
    }
  }

  // please see https://en.wikipedia.org/wiki/Leitner_system for reference
  const handleSuccessAnswer = (data: {
    has_changed: boolean
    from_p_level: number
    to_p_level: number
    callback?: () => void
  }) => {
    const currentCardIsRemovedFromPagination = data.has_changed
    const isLastProficiencyLvlInSession =
      Number(sessionNumber) === Number(selectedProfLevel)
    const isNextLevelEmpty = checkIfNextLevelEmpty(
      actualStats,
      selectedProfLevel
    )
    const changedToNextLevel = data.to_p_level - data.from_p_level === 1
    const changedToFirstLevel = data.to_p_level === 1

    const flashcardWasAnsweredAndItIsNotTheLast =
      currentCardIsRemovedFromPagination && !isLastPage
    const lastFlashcardWasAnsweredCorrectlyInMiddleSessionButNextBoxIsEmpty =
      isLastPage &&
      currentCardIsRemovedFromPagination &&
      !isLastProficiencyLvlInSession &&
      isNextLevelEmpty &&
      changedToNextLevel
    const lastFlashcardWasAnsweredIncorrectlyInMiddleSessionButNextBoxIsEmpty =
      isLastPage &&
      currentCardIsRemovedFromPagination &&
      !isLastProficiencyLvlInSession &&
      isNextLevelEmpty &&
      changedToFirstLevel
    const lastFlashcardWasAnsweredAtTheEndOfSession =
      isLastPage && isLastProficiencyLvlInSession

    if (flashcardWasAnsweredAndItIsNotTheLast) {
      // refresh the query to retrieve the flashcard that fell in its place
      fetchFlashcardStudy(search)
    } else if (
      lastFlashcardWasAnsweredCorrectlyInMiddleSessionButNextBoxIsEmpty
    ) {
      // User answered correctly to the last card, which goes to the next plvl that did not have any cards
      changeProficiencyLevelToNextLevel()
    } else if (
      lastFlashcardWasAnsweredIncorrectlyInMiddleSessionButNextBoxIsEmpty
    ) {
      // User answered incorrectly to the last card, which goes to the first plvl, but next plvl did not have any cards
      changeProficiencyLevelWithinSession()
    } else if (lastFlashcardWasAnsweredAtTheEndOfSession) {
      // this is a manual stats change, because the updated "proficiencyStats"
      // is lost while triggering startNewSession() here
      const updatedStats = map(plvlStats => {
        const count: number = propOr(1, 'count', plvlStats)
        const plvl: number = propOr(1, 'proficiency_level', plvlStats)

        const newCount = () => {
          if (data.from_p_level === plvl) {
            return count - 1
          } else if (data.to_p_level === plvl) {
            return count + 1
          } else {
            return count
          }
        }

        return {
          proficiency_level: plvl,
          count: newCount()
        }
      })(proficiencyStats)

      // User ends the session
      startNewSession(data.has_changed ? updatedStats : proficiencyStats)
    } else {
      handleNext()
    }
  }

  const totalFlashcardsCount = pipe(
    // @ts-ignore
    map(propOr(0, 'count')),
    sum
    // @ts-ignore
  )(actualStats)

  return (
    <RightColumnWrapper>
      <FlashcardContainer>
        {totalFlashcardsCount === 0 ? (
          <EmptyStudyState />
        ) : (
          <FlashcardAnswer
            afterNext={handleSkipCardIfNeeded}
            trackFlashcardsLearningTime
            size='large'
            handleNext={handleNext}
            onSuccessAnswer={handleSuccessAnswer}
            disableNext={false}
            handlePrevious={changePage(currentPage - 1)}
            disablePrevious={currentPage === 1}
            flashcard={currentFlashcard}
          />
        )}
      </FlashcardContainer>
      <FlashcardsHotkeysLegend />
    </RightColumnWrapper>
  )
}

export default ColumnRightFlashcardsStudy

const RightColumnWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const FlashcardContainer = styled.div`
  margin: 0 28px;
`

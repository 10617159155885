import React from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import placeholder from 'assets/images/book_placeholder.png'
import { formatSecondsToHoursMinutesAndSeconds } from 'utils/date'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import AddToFavorites from './AddToFavorites'
import { VIDEOS_QUERY_PARAMS } from 'utils/videos'
import { StarIcon, Tag } from 'examkrackers-components'
import { BOOK_VIEWER_PARAMS } from 'utils/books'

export const VideoListItem = ({ video }): JSX.Element => {
  const { t } = useTranslation()
  const {
    push,
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const videoRating = R.propOr('-', 'rating', video)
  const formatRating = rating => parseFloat(rating).toFixed(1)
  const fakeRating = R.propOr(0, 'fake_rating', video)
  const useFakeRating = R.propOr(false, 'use_fake_rating', video)
  const displayRating = () => {
    // @ts-ignore
    if (useFakeRating && fakeRating > 0) {
      return formatRating(fakeRating)
      // @ts-ignore
    } else if (videoRating > 0) {
      return formatRating(videoRating)
    }
    return '-'
  }

  const formatString = formattedString => {
    if (formattedString === 'books') {
      formattedString = 'Books'
    } else if (formattedString === 'draft') {
      formattedString = 'Draft'
    } else if (formattedString === 'medreel') {
      formattedString = 'Bonus Videos'
    } else if (formattedString === 'recordings') {
      formattedString = 'Classes'
    } else if (formattedString === 'onboarding') {
      formattedString = 'Onboarding'
    } else if (formattedString === 'review') {
      formattedString = 'Reviews'
    }
    return formattedString
  }

  const thumbnailUrl = isNotNilOrEmpty(video.thumbnail)
    ? video.thumbnail
    : placeholder
  const videoId = R.propOr('', 'id', video)

  const handleScrollToBookContent = contentId => {
    const bookContentAnchor = document.getElementById(
      `book-content-anchor-original-id-${contentId}`
    )

    bookContentAnchor &&
      bookContentAnchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
  }

  const redirectToBookContent = tag => {
    const link = `/books/${tag.book_id}/chapter/${tag.chapter_order}/part/${tag.part}?${BOOK_VIEWER_PARAMS.sectionIdContext}=${tag.subchapter_id}&${BOOK_VIEWER_PARAMS.bookContentIdContext}=${tag.content_id}`
    push(link)
    setTimeout(() => handleScrollToBookContent(tag.content_id), 5000)
  }

  const handleClick = () => {
    const filterQueryWithoutFavorities = R.pipe(
      R.propOr({}, 'filter'),
      // @ts-ignore
      R.dissoc(VIDEOS_QUERY_PARAMS.favorites)
      // @ts-ignore
    )(parsedQuery)

    const currentQueryWithResetPagination = {
      ...parsedQuery,
      filter: filterQueryWithoutFavorities,
      limit: {
        take: 10,
        page: 1
      }
    }

    push(
      `/videos/${videoId}${qs.stringify(currentQueryWithResetPagination, {
        addQueryPrefix: true
      })}`
    )
  }

  return (
    <RowContainer onClick={handleClick}>
      <div className='col col1'>
        <ThumbnailContainer>
          <Thumbnail src={thumbnailUrl} />
          {video.is_watched && (
            <>
              <ThumbnailOverlay />
              <WatchedLabelContainer>
                <WatchedLabel>{t('videos.filters.watched')}</WatchedLabel>
              </WatchedLabelContainer>
            </>
          )}
        </ThumbnailContainer>
      </div>
      <div className='col col2'>
        <VideoTitle>{video.title}</VideoTitle>
      </div>
      <div className='col col3 center'>
        {' '}
        <RatingWrapper>
          <IconWrapper>
            <StarIcon width='20px' height='20px' />
          </IconWrapper>
          <RateNumberWrapper>{displayRating()}</RateNumberWrapper>
        </RatingWrapper>
      </div>
      <div className='col col4 center'>
        <VideoCategory>{formatString(video.category)}</VideoCategory>
      </div>
      <div className='col col5 center'>
        {video.tags ? (
          <Tag
            onClick={() => redirectToBookContent(video.tags[0])}
            text={`${video.tags[0].tag} ${video.tags[0].chapter_order}.${video.tags[0].subchapter_order}`}
            color={video.tags[0].tag_colour}
            id={video.id}
          />
        ) : null}
      </div>
      <div className='col col6 center'>
        <AddToFavorites video={video} />
      </div>
      <div className='col col7 right'>
        <VideoTime>
          {formatSecondsToHoursMinutesAndSeconds(video.duration)}
        </VideoTime>
      </div>
    </RowContainer>
  )
}

export default VideoListItem

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.main.grey200};
    border-radius: 6px;
  }

  .center {
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
  }

  .col {
    display: flex;
    align-items: center;
    white-space: pre-wrap;
  }

  .col1 {
    width: 10%;
    flex: none;
  }

  .col2 {
    width: 43%;
    flex: none;
  }

  .col3 {
    width: 10%;
    flex: none;
  }

  .col4 {
    width: 10%;
    flex: none;
  }

  .col5 {
    width: 10%;
    flex: none;
  }

  .col6 {
    width: 5%;
    flex: none;
  }

  .col7 {
    width: 10%;
    flex: none;
  }
`

const ThumbnailContainer = styled.div`
  height: 48px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  box-shadow: ${props => props.theme.shadows.mainShadow};
`

const ThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.main.white};
  opacity: 0.5;
`

const Thumbnail = styled.img`
  height: 100%;
`

const WatchedLabelContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WatchedLabel = styled.div`
  padding: 2px 4px;
  height: 20px;
  background: ${({ theme }) => theme.colors.main.white};
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.main.text};
`

const VideoTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.main.heading};
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`

const VideoCategory = styled.div`
  min-width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 11px;
  background: ${({ theme }) => theme.colors.main.grey200};
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.main.text};
`

const VideoTime = styled.div`
  min-width: 54px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.main.grey300};
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.main.heading};
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.dashboard.questions.correctBarVia};
`
const RateNumberWrapper = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.main.black};
`
const RatingWrapper = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  align-items: center;
  line-height: 20px;
`

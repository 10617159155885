import React from 'react'
import styled from 'styled-components'

import SwitchHighlight from 'modules/books/components/SwitchHighlight'
import InputSearchGlossaryWords from 'modules/glossary/components/InputSearchGlossaryWords'
import * as R from 'ramda'
import { useSelector } from 'react-redux'

import { getLearningTimeDetails } from 'modules/learningTime/ducks/selectors'
import { COURSE_LEARNING_TIME_STATUS, COURSE_TYPES } from 'utils/courses'
import { Course } from '../../../types'
import { getCurrentCourse } from '../../courses/ducks/selectors'
import { getIsImpersonate, isAuthLoaded } from 'modules/auth/ducks/selectors'

export const TopHandlers = (): JSX.Element => {
  const learningTimeDetails = useSelector(getLearningTimeDetails)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isUserImpersonate = useSelector(getIsImpersonate)
  const isImpersonate = isUserImpersonate && isAuthFetched

  const learningTimeState: string = R.propOr(
    COURSE_LEARNING_TIME_STATUS.started,
    'state',
    learningTimeDetails
  )

  const isPaused = learningTimeState === COURSE_LEARNING_TIME_STATUS.paused

  return (
    <Container
      isPaused={isPaused}
      isFreeTrial={isFreeTrial}
      isImpersonate={isImpersonate}
    >
      <ContainerInner isFreeTrial={isFreeTrial} isImpersonate={isImpersonate}>
        {!isPaused && <SwitchHighlight />}
        <InputSearchGlossaryWords />
      </ContainerInner>
    </Container>
  )
}

export default TopHandlers

const ContainerInner = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;
  justify-content: center;
  height: ${({ theme, isFreeTrial, isImpersonate }) =>
    isFreeTrial || isImpersonate
      ? `calc(${theme.dimensions.studentTopNavHeight} - ${theme.dimensions.topNotificationHeight})`
      : theme.dimensions.studentTopNavHeight};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.1px;
  margin-left: 218px;
`

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: ${({ theme, isFreeTrial, isImpersonate }) =>
    isFreeTrial || isImpersonate ? theme.dimensions.topNotificationHeight : 0};
  left: 0;
  display: flex;
  justify-content: center;
  height: 0px;
  z-index: ${({ theme }) => theme.zIndex.navigation};
  opacity: ${({ isPaused }) => (isPaused ? 0 : 1)};
`

import React from 'react'
import styled from 'styled-components'

export const SpeedometerVelocityLines = ({
  orangeBlurDeviation,
  yellowBlurDeviation,
  acceleration,
  percent
}): JSX.Element => {
  const rValue = 108

  const calculateStrokeDashoffset = percent => {
    const cValue = Math.PI * (rValue * 2)

    if (percent < 0) {
      return 0
    } else if (percent > 100) {
      return 100
    } else {
      return ((100 - percent) / 100) * cValue
    }
  }

  const percentOfPaintedCircle = 75
  const strokeDashoffsetOfPaintedCircle = calculateStrokeDashoffset(
    percentOfPaintedCircle
  )

  const actualPercent = (percent * percentOfPaintedCircle) / 100

  return (
    <StyledSvg
      width='412'
      height='301'
      viewBox='0 0 412 301'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      id='velocity-lines'
    >
      <g>
        <circle
          transform='rotate(140 50 50)'
          r={rValue}
          cx='-15'
          cy='-115'
          fill='transparent'
          strokeDasharray='659'
          strokeDashoffset={strokeDashoffsetOfPaintedCircle}
        />
        <circle
          id='bar'
          transform='rotate(140 50 50)'
          r={rValue}
          cx='-15'
          cy='-115'
          fill='transparent'
          strokeDasharray='659'
          strokeDashoffset={calculateStrokeDashoffset(actualPercent + 1)}
        />
      </g>
    </StyledSvg>
  )
}

export default SpeedometerVelocityLines

const StyledSvg = styled.svg`
  transform: rotate(-90 50 50);

  circle {
    stroke-dashoffset: ${({ strokeDashoffset }) => strokeDashoffset};
    transition: stroke-dashoffset 1s linear;
    stroke-width: 4px;
    stroke: #00fb90;
  }

  #bar {
    stroke: #ed0823;
    stroke-width: 5px;
  }
`

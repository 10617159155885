import React, { useState } from 'react'
import styled from 'styled-components'
import { AddContainedIcon, Modal } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'

import FormCreateCustomBox from 'modules/flashcards/forms/FormCreateCustomBox'

export const AddCustomBox = ({ book }): JSX.Element => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Container onClick={handleOpen}>
        <IconContainer>
          <AddContainedIcon />
        </IconContainer>
        <LabelContainer>{t('flashcards.customBox.createCTA')}</LabelContainer>
      </Container>
      <Modal
        title={t('flashcards.customBox.createCTA')}
        open={open}
        handleClose={handleClose}
      >
        <FormCreateCustomBox book={book} handleClose={handleClose} />
      </Modal>
    </>
  )
}

export default AddCustomBox

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  min-width: 76px;
  min-height: 103px;
  cursor: pointer;
`

const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  font-size: 30px;
`

const LabelContainer = styled.div`
  font-size: 11px;
  line-height: 16px;
`

import { useTranslation } from 'react-i18next'
import { getTheme } from 'examkrackers-components'
import * as R from 'ramda'
import { formatDate } from 'utils/date'
import { formatNumberWithDecimal } from 'utils/numbers'
import { isNilOrEmpty } from 'utils/ramda'

export const SBIncomeHistoryChart = (data, isDashboard) => {
  const { t } = useTranslation()
  const theme = getTheme()

  const generateCategories = () => {
    const temp = R.pipe(
      // @ts-ignore
      R.sortBy(R.prop('created_at')),
      // @ts-ignore
      R.map(R.prop('created_at'))
      // @ts-ignore
    )(data)

    // @ts-ignore
    return temp.map(el => formatDate(el, 'MMM dd'))
  }

  const sbBalance = R.pipe(
    // @ts-ignore
    R.sortBy(R.prop('created_at')),
    R.map(
      R.pipe(
        R.propOr(null, 'balance'),
        R.ifElse(R.isNil, R.identity, balance => parseInt(balance, 10))
      )
    )
    // @ts-ignore
  )(data)

  const sbCategories = generateCategories()

  const onlyOneDataValue = isNilOrEmpty(data[1]?.balance)

  return {
    series: [
      {
        data: sbBalance
      }
    ],
    legend: {
      show: false
    },
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    title: {
      text: t('dashboard.charts.saltyBucks'),
      align: 'left',
      style: {
        fontSize: '16',
        fontFamily: 'Arial, sans-serif'
      },
      margin: 23,
      offsetX: isDashboard ? 40 : 16
    },
    // @ts-ignore
    colors: [theme.colors.dashboard.sb.mainLine],
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1,
      curve: 'straight'
    },
    grid: {
      show: true,
      // @ts-ignore
      borderColor: theme.colors.dashboard.sb.strokeDash,
      padding: {
        top: -20,
        bottom: 0,
        left: 30
      }
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      enabledOnSeries: undefined,
      shared: false,
      intersect: false,
      inverseOrder: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `
        <p>
          ${sbCategories[dataPointIndex]}
        </p>
        <i class='icon-ek-salty-bucks' />
        <div class="arrow_box">
          <span>
            ${formatNumberWithDecimal(series[seriesIndex][dataPointIndex])}
          </span>
          </div>
        `
      },
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
        fontFamily: undefined
      },
      onDatasetHover: {
        highlightDataSeries: false
      },
      x: {
        show: false,
        format: 'MMM',
        formatter: 0
      },
      y: {
        show: false,
        formatter: undefined
      },
      z: {
        show: false,
        formatter: undefined,
        title: 'Size: '
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 10,
        offsetY: 0
      }
    },
    markers: {
      show: true,
      // @ts-ignore
      strokeColor: theme.colors.dashboard.sb.mainLine,
      colors: theme.colors.dashboard.totalProgressBackground,
      strokeWidth: 3,
      size: onlyOneDataValue ? 3 : null,
      hover: {
        sizeOffset: onlyOneDataValue ? 3 : 5
      }
    },
    xaxis: {
      show: true,
      categories: sbCategories,
      labels: {
        show: true,
        hideOverlappingLabels: false,
        rotate: 0,
        style: {
          colors: theme.colors.dashboard.totalProgress,
          fontFamily: 'Arial, sans-serif',
          fontSize: '11px'
        },
        tooltip: {
          enabled: false
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        formatter: val => formatNumberWithDecimal(val),
        style: {
          colors: '#949596',
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-yaxis-label'
        },
        offsetX: 10,
        offsetY: 3,
        rotate: 0
      },
      axisBorder: {
        show: false
      }
    }
  }
}

import { init, track, identify, Identify } from '@amplitude/analytics-browser'

// @ts-ignore
const API_KEY: string = process.env.REACT_APP_ANALYTICS_API_KEY

const setAnalyticsInstance = user => () => {
  const { id, email, name } = user

  init(API_KEY, id)

  const identifyObj = new Identify()
  identifyObj.set('id', id)
  identifyObj.set('email', email)
  identifyObj.set('name', name)

  identify(identifyObj)
}

const logAnalyticsEvent = (name, properties) => {
  track(name, properties)
}

export default user => ({
  initUser: setAnalyticsInstance(user),
  logEvent: logAnalyticsEvent
})

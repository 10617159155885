import {
  createSelector,
  OutputParametricSelector,
  OutputSelector
} from 'reselect'
import { RootReducer } from 'types'
import {
  or,
  pathOr,
  propEq,
  propOr,
  prop,
  find,
  pipe,
  map,
  any,
  applySpec,
  multiply,
  includes,
  last,
  equals,
  flatten
} from 'ramda'
import { QUESTION_STATUSES } from '../utils/exam'
import { getSectionFullTitleFromExamDetails } from 'utils/exam'
import PageIds from '../utils/pageIds'
import { API_STATES } from 'utils/redux'

export const selectExam = (state: RootReducer) => state.exam

export const getIsExamLoading = createSelector(
  selectExam,
  pipe(propOr([], 'state'), equals(API_STATES.IN_PROGRESS))
)

export const getQuestionStatus = createSelector(
  selectExam,
  (state, questionId) => questionId,
  (examState, questionId) =>
    pathOr(
      QUESTION_STATUSES.unseen,
      ['stepStates', questionId, 'status'],
      examState
    )
)

export const getQuestionFlag = createSelector(
  selectExam,
  (state, questionId) => questionId,
  (examState, questionId) =>
    pathOr(false, ['stepStates', questionId, 'flagged'], examState)
)

export const getQuestionAnswer = createSelector(
  selectExam,
  (state, questionId) => questionId,
  (examState, questionId) =>
    pathOr(null, ['stepStates', questionId, 'answer'], examState)
)

export const getQuestion = createSelector(
  selectExam,
  getQuestionStatus,
  getQuestionFlag,
  getQuestionAnswer,
  (state, questionId) => questionId,
  (exam, status, flagged, answer, id) => ({
    status,
    flagged,
    answer,
    id
  })
)

export const getQuestionStepStates = createSelector(
  selectExam,
  propOr({}, 'stepStates')
)

export const getStepHtml = createSelector(
  selectExam,
  (state, stepId) => stepId,
  (examReducer, stepId) => pathOr({}, ['stepHtml', stepId], examReducer)
)

export const getExamDetails = createSelector(
  selectExam,
  propOr({}, 'examDetails')
)

export const getExamLastSavedPage = createSelector(
  getExamDetails,
  pathOr(PageIds.start, ['state', 'current_page'])
)

export const getExamQuestionsMap: OutputSelector<any, any, (res: any) => any> =
  createSelector(getExamDetails, propOr([], 'questions'))

export const getFirstSectionId = createSelector(
  getExamDetails,
  pipe(propOr([], 'questions'), find(propEq('order', 1)), propOr('', 'id'))
)

export const getLastSectionId = createSelector(
  getExamDetails,
  pipe(propOr([], 'questions'), last, propOr('', 'id'))
)

export const getSectionDetailsById = createSelector(
  getExamDetails,
  (state, id) => id,
  (examDetails, id) =>
    pipe(propOr([], 'questions'), find(propEq('id', id)))(examDetails)
)

export const getSectionFullTitleById = createSelector(
  getExamDetails,
  (state, id) => id,
  (examDetails, id) => getSectionFullTitleFromExamDetails(id)(examDetails)
)

export const getSectionsIdsWithOriginalTime = createSelector(
  getExamDetails,
  pipe(
    propOr([], 'questions'),
    map(
      applySpec({
        section_id: prop('id'),
        seconds_left: pipe(
          pathOr(0, ['sectionLength', 'sectionMinutes']),
          multiply(60)
        )
      })
    )
  )
)

export const getSectionsIdsWithCurrentTime = createSelector(
  getExamDetails,
  getSectionsIdsWithOriginalTime,
  (examDetails, sectionsIdsWithOriginalTime) => {
    const timeOptionMultiplier = pipe(
      pathOr('1.0', ['exam', 'time_option']),
      Number
    )(examDetails)

    return map(
      applySpec({
        section_id: prop('section_id'),
        seconds_left: timeConfig => {
          const savedTime = pipe(
            pathOr([], ['state', 'exam_seconds_left']),
            find(propEq('section_id', timeConfig.section_id)),
            propOr(0, 'seconds_left')
          )(examDetails)

          return savedTime || timeConfig.seconds_left * timeOptionMultiplier
        }
      })
    )(sectionsIdsWithOriginalTime)
  }
)

const isSectionPage = pageId => section =>
  or(
    pipe(propOr([], 'questions'), any(propEq('id', pageId)))(section),
    pipe(propOr('', 'id'), id => includes(id, pageId))(section)
  )

export const getSectionIdByCurrentPageId: OutputParametricSelector<
  RootReducer,
  string,
  any,
  (res1: any, res2: string) => any
> = createSelector(
  getExamDetails,
  (state, pageId) => pageId,
  (examDetails, pageId) =>
    pipe(
      propOr([], 'questions'),
      pipe(find(isSectionPage(pageId)), propOr('', 'id'))
    )(examDetails)
)

export const getQuestionOriginalOrder: OutputParametricSelector<
  RootReducer,
  string,
  any,
  (res1: any, res2: string) => any
> = createSelector(
  getExamDetails,
  (state, questionId) => questionId,
  (examDetails, questionId) =>
    pipe(
      propOr([], 'questions'),
      // @ts-ignore
      map(propOr([], 'questions')),
      flatten,
      find(propEq('id', questionId)),
      propOr(0, 'original_order')
      // @ts-ignore
    )(examDetails)
)

import { createRoutine } from 'redux-saga-routines'
import { ActionPayload, PaginationProps } from 'types'
import { put, call, takeLatest, fork, takeEvery } from 'utils/saga'
import * as VideosService from 'services/VideosService'
import { pathOr } from 'ramda'
import { select, all } from '@redux-saga/core/effects'
import { getVideosPagination, selectVideosState } from './selectors'
import * as R from 'ramda'
import { SORT_DIRECTION } from 'utils/table'
import qs from 'qs'
import { API_STATES } from '../../../utils/redux'
import { VideosByCategoriesPayload } from 'types/videos'

export const fetchVideosListRoutine = createRoutine('FETCH_VIDEOS_LIST')
export const resetVideosListRoutine = createRoutine('RESET_VIDEOS_LIST')
export const fetchVideosListWithInfiniteScrollRoutine = createRoutine(
  'FETCH_VIDEOS_LIST_WITH_INFINITE_SCROLL'
)
export const fetchVideosByCategoriesRoutine =
  createRoutine('FETCH_PER_CATEGORY')

function* fetchVideosList({ payload }: ActionPayload) {
  const itemsPerPage = 250
  let page = 1
  let totalFetched = 0
  let totalItems = 1

  yield put(fetchVideosListRoutine.request())
  try {
    const result = yield call(VideosService.fetchBookVideosList, {
      ...payload,
      page: 1,
      take: 50
    })
    yield put(fetchVideosListRoutine.success(pathOr([], ['data'], result)))
    totalItems = result.data.meta.recordsTotal
  } catch (e) {
    yield put(fetchVideosListRoutine.failure(e))
    console.error(e)
  }

  while (totalFetched < totalItems) {
    yield put(fetchVideosListRoutine.request())
    try {
      const result = yield call(VideosService.fetchBookVideosList, {
        ...payload,
        page: page,
        take: itemsPerPage
      })
      yield put(fetchVideosListRoutine.success(pathOr([], ['data'], result)))
      totalItems = result.data.meta.recordsTotal
      totalFetched += result.data.data.length
      page += 1
    } catch (e) {
      yield put(fetchVideosListRoutine.failure(e))
      console.error(e)
    }
  }
}

function* resetVideosList() {
  yield put(resetVideosListRoutine.request())
  try {
    yield put(resetVideosListRoutine.success())
  } catch (e) {
    yield put(resetVideosListRoutine.failure(e))
  }
}

function* fetchVideosListWithInfiniteScroll({ payload }: ActionPayload) {
  const pagination: PaginationProps = yield select(getVideosPagination)
  const reducerState = yield select(selectVideosState)
  const queryString = R.propOr('', 'query', payload)

  // @ts-ignore
  const parsedQuery = qs.parse(queryString, { ignoreQueryPrefix: true })

  const initialQuery = {
    ...parsedQuery,
    order: { by: 'order', dir: R.toLower(SORT_DIRECTION.asc) },
    limit: { take: 10, page: 1 }
  }

  const initialQueryWithoutCategory = R.omit(['category'], initialQuery)

  const query = R.propEq('recordsTotal', 0)(pagination)
    ? qs.stringify(initialQueryWithoutCategory, { addQueryPrefix: true })
    : qs.stringify(
        {
          ...initialQueryWithoutCategory,
          // @ts-ignore
          limit: { take: 10, page: Number(R.propOr(0, 'page', pagination)) + 1 }
        },
        { addQueryPrefix: true }
      )

  const hasMorePages = Number(pagination.page) < Number(pagination.pagesTotal)
  const isInitialFetch =
    Number(pagination.page) === 1 &&
    (reducerState !== API_STATES.DONE ||
      reducerState !== API_STATES.IN_PROGRESS)

  if (hasMorePages || isInitialFetch) {
    yield put(fetchVideosListWithInfiniteScrollRoutine.request())
    try {
      const { data } = yield call(VideosService.fetchVideosList, {
        query
      })
      yield put(fetchVideosListWithInfiniteScrollRoutine.success(data))
    } catch (e) {
      yield put(fetchVideosListWithInfiniteScrollRoutine.failure(e))
    }
  }
}

function* fetchVideosByCategories(payload: VideosByCategoriesPayload) {
  yield put(fetchVideosByCategoriesRoutine.request())
  try {
    const result = yield all(
      payload.payload.categories.map(category =>
        call(VideosService.fetchVideosByCategory, { category })
      )
    )
    const mergeNested = arr => R.pipe(R.pluck('data'), R.flatten)(arr)

    yield put(fetchVideosByCategoriesRoutine.success(mergeNested(result)))
  } catch (e) {
    yield put(fetchVideosByCategoriesRoutine.failure(e))
    console.error(e)
  }
}

export function* fetchVideosListWatcher() {
  yield takeLatest(fetchVideosListRoutine, fetchVideosList)
}

export function* fetchVideosListWithInfiniteScrollWatcher() {
  yield takeEvery(
    fetchVideosListWithInfiniteScrollRoutine,
    fetchVideosListWithInfiniteScroll
  )
}

export function* resetVideosListWatcher() {
  yield takeLatest(resetVideosListRoutine, resetVideosList)
}

export function* fetchVideosByCategoriesWatcher() {
  yield takeEvery(fetchVideosByCategoriesRoutine, fetchVideosByCategories)
}

export const videoSagas = [
  fork(fetchVideosListWatcher),
  fork(fetchVideosListWithInfiniteScrollWatcher),
  fork(resetVideosListWatcher),
  fork(fetchVideosByCategoriesWatcher)
]

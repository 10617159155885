import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import { find, pathOr, pipe, propEq, propOr } from 'ramda'
import { BackButton } from 'examkrackers-components'
import PATHS from 'utils/paths'
import { getCustomBoxes } from '../flashcards/ducks/selectors'
import * as R from 'ramda'

import { Course } from 'types'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const HeaderFlashcardsStudy = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    push,
    location: { search }
  } = useHistory()
  const allBooks = useSelector(selectAllBooks)
  const [startStudyingFlashcards, setStartStudyingFlashcards] = useState(true)

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const selectedBookId = pathOr('', ['filter', 'b.id'], parsedQuery)
  const selectedChapterId = pathOr('', ['filter', 'bcc.id'], parsedQuery)
  const selectedCustomBoxId = pathOr(
    '',
    ['filter', 'custom_box_id'],
    parsedQuery
  )
  const allCustomBoxes = useSelector(getCustomBoxes)
  const selectedCustomBox = R.find(R.propEq('id', selectedCustomBoxId))(
    allCustomBoxes
  )
  const customBoxTitle = R.propOr('', 'title', selectedCustomBox)

  const currentBook = pipe(find(propEq('id', selectedBookId)))(allBooks)
  const chapter = pipe(
    propOr([], 'chapters'),
    // @ts-ignore
    R.find(R.propEq('id', selectedChapterId))
    // @ts-ignore
  )(currentBook)

  const bookTitle = propOr('All Books', 'title', currentBook)

  const chapterTitle = propOr('', 'title', chapter)

  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)

  const handleGoBack = () => push(PATHS.flashcardsBoxes)

  const getBookTitle = () => {
    switch (true) {
      case isNotNilOrEmpty(selectedBookId) && isNilOrEmpty(chapterTitle):
        return bookTitle
      case isNotNilOrEmpty(chapterTitle):
        return `${bookTitle} / ${chapterTitle}`
      case isNotNilOrEmpty(customBoxTitle):
        return customBoxTitle
      default:
        return 'All Books'
    }
  }

  const fleshcardType = () => {
    if (isNotNilOrEmpty(currentBook?.tag) && isNotNilOrEmpty(chapter?.order)) {
      return `${currentBook?.tag}_${chapter?.order}`
    } else if (
      isNotNilOrEmpty(currentBook?.tag) &&
      isNilOrEmpty(chapter?.order)
    ) {
      return `${currentBook?.tag}`
    } else {
      return false
    }
  }

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && startStudyingFlashcards) {
      if (getBookTitle() === 'All Books') {
        AnalyticsService(user).logEvent(
          ANALYTICS_EVENTS.startStudyingAllFlashcards,
          {
            'Course type': currentCourse?.type || false,
            'Course name': currentCourse?.title || false,
            'Course expiration date': currentCourse?.accessible_to || false
          }
        )
      } else {
        AnalyticsService(user).logEvent(
          ANALYTICS_EVENTS.startStudyingBookFlashcards,
          {
            'Course type': currentCourse?.type || false,
            'Course name': currentCourse?.title || false,
            'Course expiration date': currentCourse?.accessible_to || false,
            'Flashcard box': fleshcardType()
          }
        )
      }
      setStartStudyingFlashcards(false)
    }
  }, [currentCourse, startStudyingFlashcards])

  return (
    <Header>
      {getBookTitle()}
      <ExitButtonContainer>
        <BackButton onClick={handleGoBack}>
          {t('flashcards.study.exit')}
        </BackButton>
      </ExitButtonContainer>
    </Header>
  )
}

export default HeaderFlashcardsStudy

const Header = styled.div`
  margin-top: 8px;
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`

const ExitButtonContainer = styled.div`
  position: absolute;
  top: -6px;
  right: 0;
`

import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import {
  VideoPlayer,
  NavArrowRightIcon,
  NavArrowLeftIcon,
  IconButton
} from 'examkrackers-components'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/learningTime/utils/events'
import * as R from 'ramda'
import { saveVideoProgress } from 'services/VideosService'
import { VIDEO_PROGRESS_REQUIRED_TO_EARN_SB } from 'utils/books'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIsOrdinaryStudent,
  isAuthLoaded,
  getStudent
} from 'modules/auth/ducks/selectors'
import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import { resetInactiveTimePassedSecondsRoutine } from 'modules/learningTime/ducks/actions'
import Tags from '../../../components/Tags'
import VideoRateButton from './VideoRateButton'
import AddToFavorites from './AddToFavorites'

import videoEvents from 'modules/videos/utils/events'
import { fetchAppSettingByNameSpace } from 'services/BooksService'

import { videoStringTags, videoMinutesDuration } from 'utils/videos'
import { Course } from 'types'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const VideoDetailsMainVideo = ({ video }): JSX.Element => {
  const dispatch = useDispatch()
  const [playerRef, setPlayerRef] = useState(null)
  const [loading, setLoading] = useState(true)
  const [videoIdRating, setVideoIdRating] = useState('')
  const [newVideoRate, setNewVideoRate] = useState(0)
  const [earningForRating, setEarningForRating] = useState(0)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isVideoPlayerHidden, setIsVideoPlayerHidden] = useState(false)
  const videoId = R.propOr('', 'id', video)
  const formatRating = rating => parseFloat(rating).toFixed(1)
  const rating = R.propOr(0, 'rating', video)
  const fakeRating = R.propOr(0, 'fake_rating', video)
  const useFakeRating = R.propOr(false, 'use_fake_rating', video)
  const tags = R.propOr([], 'tags', video)
  const duration = R.propOr([], 'duration', video)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)

  const fetchStudentDetails = useCallback(() => {
    dispatch(studentFetchDetailsRoutine())
  }, [dispatch])

  const resetInactiveTimePassedSeconds = React.useCallback(
    () => dispatch(resetInactiveTimePassedSecondsRoutine()),
    [dispatch]
  )

  const isTheSameVideoId = videoIdRating === videoId

  const displayRating = () => {
    if (useFakeRating) {
      return formatRating(fakeRating)
    } else if (isTheSameVideoId && !useFakeRating && newVideoRate > 0) {
      return formatRating(newVideoRate)
    } else {
      return formatRating(rating)
    }
  }

  const fetchAppSettingsForVideoRating = () => {
    const handleSuccess = response => {
      const results = R.pathOr([], ['data', 'data'], response)
      const earningForVideoRating = R.pipe(
        R.find(R.pipe(R.propEq('name', 'rating_video'))),
        propOr('-', 'value')
      )(results)
      // @ts-ignore
      setEarningForRating(earningForVideoRating)
    }
    const handleError = e => console.error(e)

    fetchAppSettingByNameSpace().then(handleSuccess).catch(handleError)
  }

  const handleChangesInVideoRating = videoRating => {
    setNewVideoRate(videoRating)
  }

  const resourceId = R.propOr('', 'resource_id', video)

  const onPlayerStart = () => {
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.playAVideo, {
      'Course type': currentCourse?.type || false,
      'Course name': currentCourse?.title || false,
      'Course expiration date': currentCourse?.accessible_to || false,
      'Video title': video?.title || false,
      'Video category': video?.category || false,
      'Book tag': videoStringTags(tags),
      'Video lenght': videoMinutesDuration(duration)
    })

    eventEmitter.emit(events.videoWatchingTimeStart, resourceId)
  }
  const onPlayerEnd = () => {
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.pauseAVideo, {
      'Course type': currentCourse?.type || false,
      'Course name': currentCourse?.title || false,
      'Course expiration date': currentCourse?.accessible_to || false,
      'Video title': video?.title || false,
      'Video category': video?.category || false,
      'Book tag': videoStringTags(tags),
      'Video lenght': videoMinutesDuration(duration)
    })

    eventEmitter.emit(events.videoWatchingTimePause, resourceId)
  }

  const calculatedVideoProgress = progress => {
    const shouldSaveProgress =
      (100 * progress.playedSeconds) / video.video_duration >=
      VIDEO_PROGRESS_REQUIRED_TO_EARN_SB

    if (shouldSaveProgress && isOrdinaryStudent && isAuthFetched) {
      fetchStudentDetails()
    }
  }

  const handleTimestampSave = progress => {
    calculatedVideoProgress(progress)
    resetInactiveTimePassedSeconds()
    if (progress.playedSeconds > 0 && isOrdinaryStudent && isAuthFetched) {
      saveVideoProgress({
        id: video.id,
        video_timestamp: Math.floor(progress.playedSeconds)
      })
    }
  }

  const handleVideoIdRating = rating => {
    setVideoIdRating(rating)
  }

  useEffect(() => {
    eventEmitter.on(videoEvents.videoRating, handleChangesInVideoRating)
    eventEmitter.on(videoEvents.videoId, handleVideoIdRating)

    return () => {
      eventEmitter.off(videoEvents.videoRating, handleChangesInVideoRating)
      eventEmitter.off(videoEvents.videoId, handleVideoIdRating)
    }
  }, [])

  useEffect(() => {
    fetchAppSettingsForVideoRating()
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset
      if (scrollTop > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Container isScrolled={isScrolled}>
      {isScrolled ? (
        <>
          <VideoContainer isScrolled={isScrolled}>
            <StyledIconButton
              isVideoPlayerHidden={isVideoPlayerHidden}
              icon={
                isVideoPlayerHidden ? (
                  <NavArrowLeftIcon />
                ) : (
                  <NavArrowRightIcon />
                )
              }
              variant='transparent'
              size='medium'
              onClick={() => {
                setIsVideoPlayerHidden(!isVideoPlayerHidden)
              }}
            />
            {isVideoPlayerHidden ? null : (
              <StyledVideoPlayer
                // @ts-ignore
                playerRef={player => setPlayerRef(player)}
                onPlay={onPlayerStart}
                onPause={onPlayerEnd}
                onEnded={onPlayerEnd}
                progressInterval={5000}
                onProgress={handleTimestampSave}
                url={video.source}
                controls
                width='365px'
                height='211px'
                onReady={() => {
                  // @ts-ignore
                  playerRef.seekTo(
                    propOr(false, 'is_watched', video)
                      ? 0
                      : propOr(0, 'video_timestamp', video)
                  )
                  setLoading(false)
                }}
              />
            )}
          </VideoContainer>
        </>
      ) : (
        <>
          <VideoContainer>
            <StyledIconButton
              isVideoPlayerHidden={isVideoPlayerHidden}
              icon={
                isVideoPlayerHidden ? (
                  <NavArrowLeftIcon />
                ) : (
                  <NavArrowRightIcon />
                )
              }
              variant='transparent'
              size='medium'
              onClick={() => {
                setIsVideoPlayerHidden(!isVideoPlayerHidden)
              }}
            />
            <StyledVideoPlayer
              // @ts-ignore
              playerRef={player => setPlayerRef(player)}
              onPlay={onPlayerStart}
              onPause={onPlayerEnd}
              onEnded={onPlayerEnd}
              progressInterval={5000}
              onProgress={handleTimestampSave}
              url={video.source}
              controls
              width={loading ? '0' : '812px'}
              height={loading ? '0' : '480px'}
              onReady={() => {
                // @ts-ignore
                playerRef.seekTo(
                  propOr(false, 'is_watched', video)
                    ? 0
                    : propOr(0, 'video_timestamp', video)
                )
                setLoading(false)
              }}
            />
          </VideoContainer>
        </>
      )}
      <VideoDescriptionLayout>
        <VideoDescriptionContainer>
          <VideoDescriptionHeadingContainer>
            <div>
              {video.tags ? <Tags tags={video.tags} id={video.id} /> : null}
            </div>
            <VideoTitle title={video.title}>{video.title}</VideoTitle>
          </VideoDescriptionHeadingContainer>
          <VideoDescription>{video.description}</VideoDescription>
        </VideoDescriptionContainer>
        <VideoActionButtons>
          <VideoRatingContainer>
            <VideoRateButton
              // @ts-ignore
              rate={displayRating()}
              // @ts-ignore
              videoId={videoId}
              earningForVideoRating={earningForRating}
              videoTitle={video?.title || false}
              videoCategory={video?.category || false}
              tags={videoStringTags(tags)}
              duration={videoMinutesDuration(duration)}
              currentCourse={currentCourse}
            />
          </VideoRatingContainer>
          <VideoFavContainer>
            <AddToFavorites video={video} variant='large' />
          </VideoFavContainer>
        </VideoActionButtons>
      </VideoDescriptionLayout>
    </Container>
  )
}

export default VideoDetailsMainVideo

const Container = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  min-height: ${({ isScrolled }) => (isScrolled ? '100px' : '575px')};
  transition: min-height 0.5s ease-in-out;
`

const VideoContainer = styled.div`
  position: ${({ isScrolled }) => isScrolled && 'fixed'};
  bottom: ${({ isScrolled }) => isScrolled && 0};
  right: ${({ isScrolled }) => isScrolled && 0};
  height: ${({ isScrolled }) => (isScrolled ? '211px' : '470px')};
`

const VideoDescriptionLayout = styled.div`
  width: 100%;
  display: flex;
  gap: 42px;
`

const VideoDescriptionContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const VideoDescriptionHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  white-space: nowrap;
`

const VideoTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.main.heading};
  width: 100%;
  max-width: 520px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const VideoDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.main.text};
  padding: 4px;
`

const VideoActionButtons = styled.div`
  width: 157px;
`

const VideoFavContainer = styled.div``

const VideoRatingContainer = styled.div`
  margin-bottom: 15px;
`
const StyledIconButton = styled(IconButton)`
  position: absolute;
  bottom: 171px;
  left: -40px;
  width: 40px;
  height: 40px;
  font-size: 20px !important;
  background-color: ${({ theme }) => theme.colors.main.grey300} !important;
  color: ${({ theme }) => theme.colors.main.text};
  :hover {
    color: ${({ theme }) => theme.colors.main.primary500}!important;
  }
`
const StyledVideoPlayer = styled(VideoPlayer)`
  position: relative;
`

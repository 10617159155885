import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { omit } from 'ramda'
import { Tag } from 'examkrackers-components'
import qs from 'qs'
import styled from 'styled-components'
import * as R from 'ramda'

const BookFiltersTag = ({ book }) => {
  const {
    replace,
    location: { pathname, search }
  } = useHistory()

  const isActive = useMemo(() => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    const currentBookId = R.pathOr({}, ['filter', 'b.id'], parsedQuery)
    return currentBookId === book.id
  }, [search])

  const handleClick = () => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    const bookIdFilter = isActive ? {} : { 'b.id': book.id }

    const newQuery = {
      ...parsedQuery,
      limit: { ...parsedQuery.limit, page: 1 },
      filter: {
        ...omit(
          ['bcc.id', 'bs.id', 'b.id', '__unassigned'],
          parsedQuery.filter
        ),
        ...bookIdFilter
      }
    }

    replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
  }

  return (
    <TagWrapper>
      <Tag
        text={book.tag}
        color={book.tag_colour}
        isActive={isActive}
        onClick={handleClick}
      />
    </TagWrapper>
  )
}

export default BookFiltersTag

const TagWrapper = styled.div`
  & > div:first-of-type {
    white-space: nowrap;
  }
`

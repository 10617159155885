import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Tabs } from 'examkrackers-components'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'
import { useSelector } from 'react-redux'
import {
  getExamDetails,
  getSectionDetailsById
} from '../../exam/ducks/selectors'
import AnalyticsService from '../../../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../../../utils/analytics'
import * as R from 'ramda'
import { getAccountType, getStudent } from '../../auth/ducks/selectors'
import { RootReducer } from '../../../types'

interface ExamScoreTabsProps {
  activeTab: 'answer-sheet' | 'diagnostics'
  children: JSX.Element
  tiles?: JSX.Element[]
  saltyImageUrl?: string
}

const ExamScoreTabs = ({
  activeTab = 'answer-sheet',
  children,
  tiles,
  saltyImageUrl
}: ExamScoreTabsProps) => {
  const { push } = useHistory()
  const params = useParams()
  const examId = propOr('', 'id', params)
  const sectionId = propOr('', 'sectionId', params)
  const examDetails = useSelector(getExamDetails)
  const student = useSelector(getStudent)
  const accountType: string = useSelector(getAccountType)
  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, sectionId)
  )

  const tabs = [
    { label: 'Answer Sheet', value: 'answer-sheet' },
    { label: 'Diagnostics', value: 'diagnostics' }
  ]

  const handleTabChange = tab => {
    AnalyticsService(student).logEvent(
      tab === 'diagnostics'
        ? ANALYTICS_EVENTS.diagnosticsReviewed
        : ANALYTICS_EVENTS.answerSheetReviewed,
      {
        'Exam Title': R.pathOr('', ['exam', 'title'], examDetails),
        'Exam format': R.pathOr('', ['type', 'title'], examDetails),
        'Account type': accountType,
        'Precentile Rank': R.pathOr(
          0,
          ['exam', 'scores', 'exam', 'percentile_rank'],
          examDetails
        ),
        'Raw Score': R.pathOr(
          0,
          ['exam', 'scores', 'exam', 'amount_correct'],
          examDetails
        ),
        'Scaled Score': R.pathOr(
          0,
          ['exam', 'scores', 'exam', 'scaled_score'],
          examDetails
        ),
        'Section Title': R.propOr('-', 'title', sectionDetails),
        'Section Number': R.propOr(0, 'order', sectionDetails)
      }
    )

    if (tab === activeTab) return
    switch (tab) {
      case 'answer-sheet':
        push(
          `/exam/${examId}/score-report/answers-graph/${sectionId}/answer-sheet`
        )
        break
      case 'diagnostics':
        push(
          `/exam/${examId}/score-report/answers-graph/${sectionId}/diagnostic`
        )
        break
      default:
        break
    }
  }

  const tabContents = useMemo(() => {
    return tabs.map(tab => {
      return tab.value === activeTab
        ? {
            value: tab.value,
            content: children
          }
        : {
            value: tab.value,
            content: null
          }
    })
  }, [activeTab, children])

  const diagnosticTiles = useMemo(() => {
    if (isNotNilOrEmpty(tiles)) {
      // @ts-ignore
      return tiles.map(tile => tile)
    } else {
      return null
    }
  }, [tiles])

  const [saltyHistoryOpen, setSaltyHistoryOpen] = useState(false)
  const saltyBucksHistoryStatusHandler = () => {
    setSaltyHistoryOpen(saltyHistoryOpen => !saltyHistoryOpen)
  }
  useEffect(() => {
    eventEmitter.on(
      events.saltyBucksHistoryOpened,
      saltyBucksHistoryStatusHandler
    )
  }, [])

  return (
    <PageWrapper hasTiles={isNotNilOrEmpty(tiles)}>
      {saltyImageUrl && (
        <SaltyImage saltyHistoryOpen={saltyHistoryOpen}>
          <img src={saltyImageUrl} alt='salty-image' />
        </SaltyImage>
      )}
      {diagnosticTiles && <Tiles>{diagnosticTiles}</Tiles>}
      <Tabs
        activeTab={activeTab}
        position='topLeft'
        tabs={tabs}
        tabContents={tabContents}
        onChange={handleTabChange}
      />
    </PageWrapper>
  )
}

export default ExamScoreTabs

const PageWrapper = styled.div`
  margin-top: ${({ hasTiles }) => (hasTiles ? '7px' : '46px')};
  position: relative;
`

const Tiles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 7px;
  gap: 15px;
`

const SaltyImage = styled.div`
  z-index: ${({ theme, saltyHistoryOpen }) =>
    saltyHistoryOpen
      ? theme.zIndex.navigation - 3
      : theme.zIndex.navigation + 1};
  position: absolute;
  display: block;
  top: -15px;
  left: 230px;

  img {
    display: block;
    position: absolute;
    max-width: 65px;
    animation: salty-pop-in 800ms ease-in;
  }

  @keyframes salty-pop-in {
    0% {
      transform: scale(0.1);
    }

    100% {
      transform: scale(1);
    }
  }
`

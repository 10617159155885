import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getIsImpersonate, isAuthLoaded } from 'modules/auth/ducks/selectors'

import { setThemeNotificationVisibility } from 'examkrackers-components'

export const ImpersonateNotificationHandler = (): JSX.Element => {
  const isAuthFetched = useSelector(isAuthLoaded)
  const isImpersonate = useSelector(getIsImpersonate)

  useEffect(() => {
    setThemeNotificationVisibility(isImpersonate && isAuthFetched)
  }, [isAuthFetched, isImpersonate])

  return <div />
}

export default ImpersonateNotificationHandler

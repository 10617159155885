import store from 'store'

const get =
  (instance = store) =>
  (key: string, defaultValue = null) =>
    instance.get(key, defaultValue)
const set =
  (instance = store) =>
  (key: string, value: any) =>
    instance.set(key, value)
const remove =
  (instance = store) =>
  (key: string) =>
    instance.remove(key)
const clear =
  (instance = store) =>
  () =>
    instance.clearAll()

export const localStorageService = (instance = store) => ({
  get: get(instance),
  set: set(instance),
  remove: remove(instance),
  clear: clear(instance)
})

export default localStorageService()

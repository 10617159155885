import React, { useCallback, useEffect } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { RouteProps } from 'types'
import { useSelector, useDispatch } from 'react-redux'

import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import PATHS from 'utils/paths'

import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'

import {
  getIsAuthorised,
  isAuthLoaded,
  getIsAuthActive,
  getStudent
  // getStudent
} from 'modules/auth/ducks/selectors'
import {
  studentFetchDetailsRoutine,
  studentLogoutRoutine
} from 'modules/auth/ducks/actions'
import RoutePrivate from './RoutePrivate'

interface PrivateRouteProps {
  allowedRoles: string[]
}

export const RouteExam = (props: RouteProps & PrivateRouteProps) => {
  const { component, allowedRoles, path, location, ...rest } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const redirectToNotFoundPage = () => history.push(PATHS.notFound)
  const redirectToCourseDeletedPage = () => history.push(PATHS.courseDeleted)
  const redirectToCoursePausedPage = () => history.push(PATHS.coursePaused)
  const studentDetails = useSelector(getStudent)

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )
  const logoutStudent = useCallback(
    () => dispatch(studentLogoutRoutine()),
    [dispatch]
  )

  useEffect(() => {
    LocalStorageService.set(
      LOCAL_STORAGE_KEYS.gameStudentDetails,
      studentDetails
    )
  }, [studentDetails])

  // const user: StudentProps = useSelector(getStudent)
  const isAuthorised = useSelector(getIsAuthorised)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isAuthActive = useSelector(getIsAuthActive)
  // const roles = findStudentRoles(user)

  useEffect(() => {
    if (isAuthorised && !isAuthFetched) {
      fetchStudentDetails()
    }

    eventEmitter.on(events.logoutUser, logoutStudent)
    eventEmitter.on(events.courseDeleted, redirectToCourseDeletedPage)
    eventEmitter.on(events.coursePaused, redirectToCoursePausedPage)
    eventEmitter.on(events.notFound, redirectToNotFoundPage)

    return () => {
      eventEmitter.off(events.logoutUser, logoutStudent)
      eventEmitter.off(events.courseDeleted, redirectToCourseDeletedPage)
      eventEmitter.off(events.coursePaused, redirectToCoursePausedPage)
      eventEmitter.off(events.notFound, redirectToNotFoundPage)
    }
  }, [])

  useEffect(() => {
    if (isAuthorised && isAuthFetched && !isAuthActive) {
      logoutStudent()
    }
  }, [isAuthorised, isAuthFetched, isAuthActive])

  useEffect(() => {
    if (!isAuthorised) {
      history.push(PATHS.unauthorized)
    }
  }, [isAuthorised])

  const Component = component

  const renderPageOrRedirect = props => {
    switch (true) {
      case !isAuthorised:
        return (
          <Redirect
            to={{
              pathname: PATHS.unauthorized,
              state: { from: location }
            }}
          />
        )
      default:
        return <Component {...props} />
    }
  }

  return <Route {...rest} render={renderPageOrRedirect} />
}

RoutePrivate.defaultProps = {
  allowedRoles: []
}

export default RouteExam

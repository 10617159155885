import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { HtmlRenderer, Tag } from 'examkrackers-components'

import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/date'
import { useHistory } from 'react-router-dom'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import ErrataDetails from './ErrataDetails'
import { isNilOrEmpty } from 'utils/ramda'

import { Book, Errata, TagColor } from './ErrataTypes'
import errataKrackers from 'assets/images/errata-krackers.png'
import { Subchapter } from 'types'

interface ErrataListlProps {
  books: Book[]
  setSelectedErrata: (any) => void
  selectedErrata: Errata | undefined
  fetchBookErratas: () => void
  erratasList: Errata[]
  selectedBook: Book | undefined
  setSelectedBook: (any) => void
  fetchExamErratas: () => void
  subchapterList: Subchapter[]
}

const ErratasList = ({
  setSelectedErrata,
  selectedErrata,
  fetchBookErratas,
  erratasList,
  selectedBook,
  setSelectedBook,
  fetchExamErratas,
  books,
  subchapterList
}: ErrataListlProps) => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const TABS = {
    books: 'books',
    exams: 'exams'
  }

  const [selectedTab, setSelectedTab] = useState(TABS.books)

  // @ts-ignore
  const selectedBookId = selectedBook.book_id
  const handleSelectTab = tab => {
    setSelectedTab(tab)
  }

  useEffect(() => {
    selectedTab === TABS.books ? fetchBookErratas() : fetchExamErratas()
  }, [selectedTab])

  const isActive = bookId => bookId === selectedBookId

  const handleSelectBook = book => setSelectedBook(book)

  const isImageFirst = htmlString => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const firstChild = doc.body.firstChild

    let charCount = 0

    const containsImage = node => {
      if (node.nodeName === 'IMG') {
        return charCount <= 20
      }
      if (node.nodeType === Node.TEXT_NODE) {
        charCount += node.textContent.length
      }
      for (let i = 0; i < node.childNodes.length; i++) {
        if (containsImage(node.childNodes[i])) {
          return true
        }
      }
      return false
    }

    return firstChild && containsImage(firstChild)
  }
  const handleScrollToSubchapter = subchapterId => {
    const studentSubchapterAnchor = document.getElementById(
      `subchapter-anchor-${subchapterId}`
    )

    studentSubchapterAnchor &&
      studentSubchapterAnchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  const renderErratasList = erratasList.map(errata => {
    const shouldAddEllipsis = errata.content_raw.length > 40
    const studentSubchapterId = subchapterList.find(
      subchapter => subchapter.original_subchapter_id === errata.subchapter_id
    )?.id

    const redirectToBookContent = () => {
      const link = `/books/${errata.book_id}/chapter/${errata.chapter_order}/part/${errata.part}?${BOOK_VIEWER_PARAMS.sectionIdContext}=${errata.subchapter_id}`

      push(link)
      setTimeout(() => {
        handleScrollToSubchapter(studentSubchapterId)
      }, 3000)
    }
    // const errataDeltaObject = R.pipe(
    //   R.propOr('{}', 'content_delta_object'),
    //   JSON.parse
    // )(errata)

    return (
      <ErrataListItem
        key={errata.id}
        onClick={() => setSelectedErrata(errata)}
        selectedErrata={selectedErrata}
        id={errata.id}
      >
        {errata.tag && (
          <TagWrapper>
            <Tag
              text={`${errata.tag} ${errata.chapter_order}.${errata.subchapter_order}`}
              color={errata.tag_colour as TagColor}
              onClick={redirectToBookContent}
            />

            <CreatedAt>
              {t('erratas.createdAt') +
                formatDate(errata.created_at, 'MM/dd/yyyy')}
            </CreatedAt>
          </TagWrapper>
        )}
        <ErrataPreview>
          {errata?.exam_title ? (
            <ExamErrataTitleContainer>
              <p>{errata.exam_title}</p>
              <CreatedAt>
                {t('erratas.createdAt') +
                  formatDate(errata.created_at, 'MM/dd/yyyy')}
              </CreatedAt>
            </ExamErrataTitleContainer>
          ) : (
            <HtmlRenderer
              // id={`errata-${errata.id}`}
              htmlString={
                isImageFirst(errata.content_html)
                  ? 'Image errata'
                  : `${errata.content_raw}${shouldAddEllipsis ? '...' : ''}`
              }
            />
          )}
        </ErrataPreview>
      </ErrataListItem>
    )
  })

  const filtersBook = books.map(book => (
    <Tag
      key={`book-filter-${book.id}`}
      text={book.tag as string}
      color={book.tag_colour as undefined}
      onClick={() => handleSelectBook(book)}
      isActive={isActive(book.book_id)}
    />
  ))

  useEffect(() => {
    setSelectedErrata(erratasList[0])
  }, [erratasList])

  const emptyList = isNilOrEmpty(erratasList)

  return (
    <Wrapper>
      <IconContainer>
        <KrackersWrapper src={errataKrackers} />
      </IconContainer>
      <BooksContainer>
        <BookFiltersWrapper>
          {selectedTab === TABS.books && filtersBook}
        </BookFiltersWrapper>
      </BooksContainer>

      <Tabs>
        <Tab
          onClick={() => handleSelectTab(TABS.books)}
          selectedTab={selectedTab}
          id={TABS.books}
        >
          {t('erratas.tabs.books')}
        </Tab>
        <Tab
          onClick={() => handleSelectTab(TABS.exams)}
          selectedTab={selectedTab}
          id={TABS.exams}
        >
          {t('erratas.tabs.exams')}
        </Tab>
      </Tabs>
      <ListWrapper>
        {emptyList ? null : (
          <List TABS={TABS} selectedTab={selectedTab}>
            {renderErratasList}
          </List>
        )}
        <ErrataDetails
          selectedBook={selectedBook}
          selectedErrata={selectedErrata}
          subchapterList={subchapterList}
        />
      </ListWrapper>
    </Wrapper>
  )
}

export default ErratasList

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Tabs = styled.div`
  width: 684px;
  margin: 16px 0;
`
const Tab = styled.button`
  border: solid;
  background-color: transparent;
  width: 50%;
  height: 32px;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme, selectedTab, id }) =>
    selectedTab === id
      ? theme.colors.main.primary500
      : theme.colors.main.grey600};
  border-color: ${({ theme, selectedTab, id }) =>
    selectedTab === id
      ? theme.colors.main.primary500
      : theme.colors.main.grey300};
  border-width: ${({ selectedTab, id }) =>
    selectedTab === id ? '0 0 2px 0' : '0 0 1px 0'};
  transition: all 200ms;
`

const ListWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  gap: 16px;
  overflow: hidden;
`

const BookFiltersWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
  ::-webkit-scrollbar {
    height: 4px;
  }
`

const List = styled.div`
  min-width: 157px;
  max-height: ${({ selectedTab, TABS }) =>
    selectedTab === TABS.books ? '430px' : '440px'};
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const ErrataListItem = styled.button`
  height: fit-content;
  width: 142px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: left;
  ${({ selectedErrata, id, theme }) =>
    selectedErrata &&
    selectedErrata.id === id &&
    css`
      background: ${theme.colors.errata.highlight.background};
      border: 1px solid ${theme.colors.errata.highlight.stroke};
    `}
`

const ExamErrataTitleContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 24px;
`

const TagWrapper = styled.div`
  margin-left: 0px;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  & > div {
    padding: 0 3px !important;
  }
`

const ErrataPreview = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 130px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
  padding: 0 2px;
  cursor: pointer;

  .ql-editor {
    padding: 0;
  }

  > * {
    max-width: 100%;
    max-height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: left !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    cursor: pointer;
  }

  * + * {
    display: none;
  }
`

const CreatedAt = styled.p`
  display: flex;
  color: ${({ theme }) => theme.colors.main.grey600};
  font-size: 11px;
  line-height: 16px;
`
const BooksContainer = styled.div`
  height: 50px;
`
const KrackersWrapper = styled.img`
  display: flex;
  width: 120px;
  height: 80px;
  z-index: 999;
`

const IconContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 48px;
`

import { useEffect } from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { selectFreeTrialUnavailableBookTags } from 'modules/books/ducks/selectors'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'

export const useFreeTrialTooltips = (id, data) => {
  // @ts-ignore
  const unavailableBookTags: string[] = useSelector(
    selectFreeTrialUnavailableBookTags
  )

  const setFreeTrialTooltips = () => {
    const yAxisLabelsContainer = document.querySelector(
      `#${id} .apexcharts-yaxis-texts-g`
    )
    const yAxisLabels = Array.from(
      // @ts-ignore
      R.propOr([], 'children', yAxisLabelsContainer)
    )

    yAxisLabels.forEach(textElement => {
      // @ts-ignore
      const titleElement = textElement.getElementsByTagName('title')[0]
      const title: string = R.propOr('', 'innerHTML')(titleElement)
      // @ts-ignore
      textElement.setAttribute('dataLabel', title)
      const transformTagsToUpperCase = R.map(R.toUpper)(unavailableBookTags)

      if (R.includes(R.toUpper(title), transformTagsToUpperCase)) {
        // @ts-ignore
        textElement.style.cursor = 'pointer'
        // @ts-ignore
        textElement.style.fill = '#949596'
        // @ts-ignore
        textElement.onmouseenter = e =>
          eventEmitter.emit(events.unavailableForFreeTrialHoverEnter, {
            e,
            title
          })
        // @ts-ignore
        textElement.onmouseleave = e =>
          eventEmitter.emit(events.unavailableForFreeTrialHoverLeave, {
            e,
            title
          })
      }
    })
  }

  const timedOutTooltips = () => setTimeout(setFreeTrialTooltips, 1000)

  useEffect(() => {
    timedOutTooltips()
  }, [data, unavailableBookTags])

  useEffect(() => {
    window.addEventListener('resize', timedOutTooltips)

    return () => {
      window.removeEventListener('resize', timedOutTooltips)
    }
  }, [unavailableBookTags])
}

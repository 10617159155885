import React, { useCallback, useState } from 'react'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { useParams } from 'react-router'

import { trackStudentBookProgress } from 'services/BooksService'
import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import { getChapterByBookOriginalIdAndOrder } from 'modules/books/ducks/selectors'
import {
  getIsOrdinaryStudent,
  isAuthLoaded
} from 'modules/auth/ducks/selectors'

import { RootReducer } from 'types'

export const BookActiveTime = (): JSX.Element => {
  const dispatch = useDispatch()
  const params = useParams()

  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const [sendPushReadParts, setSendPushReadParts] = useState(true) // single flag whether to send the read fragment to the API it has already been sent
  const [timerBookRead, setTimerBookRead] = React.useState(0)

  const bookId: string = R.propOr('', 'bookId')(params)
  const partOrder: number = R.pipe(R.propOr('', 'partOrder'), Number)(params)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  const chapter = useSelector((state: RootReducer) =>
    getChapterByBookOriginalIdAndOrder(state, bookId, chapterOrder)
  )
  const chapterId: string = R.propOr('', 'id', chapter)

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )

  const calculateActiveTime = () => {
    const isActive = !document.hidden
    if (isActive)
      setTimerBookRead(prevState => (prevState >= 20 ? 20 : prevState + 1))
    if (!isActive) setTimerBookRead(0)
  }

  /*
    // each time we change the chapter and section of the book
    // we restart the send flag and the time spent reading
  */
  React.useEffect(() => {
    setTimerBookRead(0)
    setSendPushReadParts(true)
  }, [chapterId, partOrder])

  React.useEffect(() => {
    const timer = setInterval(calculateActiveTime, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  React.useEffect(() => {
    if (
      isNotNilOrEmpty(chapterId) &&
      timerBookRead >= 20 &&
      sendPushReadParts &&
      isOrdinaryStudent &&
      isAuthFetched
    ) {
      setSendPushReadParts(false) // change to false because we want send information only once to the API, that the part of the book has been read
      trackStudentBookProgress({ chapterId, partOrder })
        .then(fetchStudentDetails)
        .catch(console.error)
    }
  }, [
    chapterId,
    partOrder,
    timerBookRead,
    sendPushReadParts,
    isOrdinaryStudent,
    isAuthFetched
  ])

  return <div />
}

export default BookActiveTime

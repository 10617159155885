import {
  addQuestionStateRoutine,
  fetchExamDetailsRoutine,
  resetExamDetailsRoutine,
  fetchQuestionHtmlRoutine,
  saveStepHtmlRoutine,
  saveQuestionAnswerRoutine,
  saveQuestionStatusRoutine
} from './actions'

import { Action, ExamReducer } from 'types'
import { getAllExamQuestionsStates } from 'modules/exam/utils/examPagesConfig'
import { API_STATES } from 'utils/redux'

export const initialState: ExamReducer = {
  state: API_STATES.PRISTINE,
  stepStates: {},
  stepHtml: {},
  examDetails: {
    exam: {
      id: '',
      layout_id: '',
      student_id: '',
      exam_id: '',
      external_id: '',
      title: '',
      created_at: '',
      accessible_from: null,
      accessible_to: null,
      is_active: true,
      access_period: 0,
      status: '',
      is_intact: false,
      completed_at: null,
      timers: '',
      time_option: '',
      break_definition: null,
      completed_as: null,
      scores: {
        sections: [],
        exam: {
          percentile_rank: '0.00'
        }
      },
      is_excluded_from_pts: false,
      timer_checkboxes: null,
      exam_type_id: '',
      originalExam: {
        id: '',
        layout_id: '',
        title: '',
        file_name: '',
        created_at: '',
        uploaded_by: '',
        is_active: false,
        external_id: '',
        access_period: 0,
        exam_length: '',
        deleted_at: '',
        exam_type_id: '',
        google_form_url: null
      }
    },
    questions: [],
    state: {
      exam_seconds_left: null,
      current_page: null
    },
    type: {
      title: '',
      type: '',
      subtype: ''
    },
    layout: {
      id: '',
      title: ''
    },
    score_projection: []
  }
}

export default (state = initialState, action: Action): ExamReducer => {
  switch (action.type) {
    //
    // addQuestionStateRoutine
    //
    case addQuestionStateRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case addQuestionStateRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case addQuestionStateRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        stepStates: {
          ...state.stepStates,
          [action.payload.id]: {
            ...state.stepStates[action.payload.id],
            ...action.payload
          }
        }
      }

    //
    // saveStepHtmlRoutine
    //
    case saveStepHtmlRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case saveStepHtmlRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case saveStepHtmlRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        stepHtml: {
          ...state.stepHtml,
          [action.payload.id]: {
            ...state.stepHtml[action.payload.id],
            ...action.payload
          }
        }
      }

    //
    // saveQuestionAnswer
    //
    case saveQuestionStatusRoutine.REQUEST:
    case saveQuestionAnswerRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case saveQuestionStatusRoutine.FAILURE:
    case saveQuestionAnswerRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case saveQuestionStatusRoutine.SUCCESS:
    case saveQuestionAnswerRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        stepStates: {
          ...state.stepStates,
          [action.payload.id]: {
            ...state.stepStates[action.payload.id],
            ...action.payload
          }
        }
      }

    //
    // fetchQuestionHtmlRoutine
    //
    case fetchQuestionHtmlRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchQuestionHtmlRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchQuestionHtmlRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        stepHtml: {
          ...state.stepHtml,
          [action.payload.id]: {
            ...state.stepHtml[action.payload.id],
            ...action.payload
          }
        }
      }

    //
    // fetchExamDetailsRoutine
    //
    case fetchExamDetailsRoutine.REQUEST:
      return {
        ...state,
        state:
          action.payload.id !== state.examDetails.exam.id
            ? API_STATES.IN_PROGRESS
            : state.state
      }
    case fetchExamDetailsRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchExamDetailsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        examDetails: action.payload,
        stepStates: {
          ...state.stepStates,
          ...getAllExamQuestionsStates(action.payload)
        }
      }
    case resetExamDetailsRoutine.SUCCESS:
      return initialState
    default:
      return state
  }
}

import i18n from 'providers/i18n'
import {
  find,
  ifElse,
  isNil,
  pipe,
  propEq,
  propOr,
  reduce,
  last,
  filter
} from 'ramda'

export const EXAM_STATUS = {
  scheduled: 'scheduled',
  completed: 'completed',
  paused: 'paused',
  unavailable: 'unavailable',
  in_progress: 'in_progress',
  expired: 'expired'
}

export const EXAM_SCORES_STATUS = {
  calculated: 'calculated'
}

export const EXAM_TIME_OPTION_VALUES = {
  regular: '1.0',
  quarter: '1.25',
  half: '1.5',
  twice: '2.0'
}

export const examTimeOptions = () => [
  {
    label: i18n.t('exams.timeOptions.regular'),
    value: EXAM_TIME_OPTION_VALUES.regular
  },
  {
    label: `x${EXAM_TIME_OPTION_VALUES.quarter}`,
    value: EXAM_TIME_OPTION_VALUES.quarter
  },
  {
    label: `x${EXAM_TIME_OPTION_VALUES.half}`,
    value: EXAM_TIME_OPTION_VALUES.half
  },
  {
    label: `x${EXAM_TIME_OPTION_VALUES.twice}`,
    value: EXAM_TIME_OPTION_VALUES.twice
  }
]

export const resetTextSelection = () => {
  if (window?.getSelection) {
    if (window?.getSelection()?.empty) {
      // Chrome
      window?.getSelection()?.empty()
    } else if (window?.getSelection()?.removeAllRanges) {
      // Firefox
      window?.getSelection()?.removeAllRanges()
    }
  }
}

export const getSectionFullTitleFromExamDetails = sectionId => examDetails =>
  pipe(
    propOr([], 'questions'),
    find(propEq('id', sectionId)),
    ifElse(
      pipe(propOr(null, 'full_title'), isNil),
      propOr('', 'title'),
      propOr('', 'full_title')
    )
  )(examDetails)

export const disableCopyPaste = () => {
  const bodyElement = document.getElementsByTagName('body')[0]
  bodyElement.setAttribute('oncopy', 'return false')
}

export const enableCopyPaste = () => {
  const bodyElement = document.getElementsByTagName('body')[0]
  bodyElement.removeAttribute('oncopy')
}

export const addRealOrderToPassages = passages =>
  // @ts-ignore
  reduce(
    // @ts-ignore
    (acc, passage) => {
      const lastPassageOrder = pipe(
        // @ts-ignore
        filter(propEq('is_false_passage', false)),
        last,
        propOr(0, 'realOrder')
        // @ts-ignore
      )(acc)

      const updatedPassage = {
        // @ts-ignore
        ...passage,
        // @ts-ignore
        realOrder: passage.is_false_passage ? null : lastPassageOrder + 1
      }

      return [...acc, updatedPassage]
    },
    []
  )(passages)

export const getPassageRealOrderById = (
  sectionDetails,
  passageId,
  defaultValue
) =>
  pipe(
    propOr([], 'passages'),
    find(propEq('id', passageId)),
    propOr(defaultValue, 'realOrder')
  )(sectionDetails)

export const getPassageRealOrderByOrder = (sectionDetails, passageOrder) =>
  pipe(
    propOr([], 'passages'),
    find(propEq('order', passageOrder)),
    propOr('', 'realOrder')
  )(sectionDetails)

export const getPassagesCount = sectionDetails =>
  pipe(
    propOr([], 'passages'),
    // @ts-ignore
    filter(propEq('is_false_passage', false)),
    last,
    propOr('', 'realOrder')
    // @ts-ignore
  )(sectionDetails)

export const preloadExamImages = () => {
  const images = [
    '/assets/exam/Alert.svg',
    '/assets/exam/AvatarPlaceholder.png',
    '/assets/exam/Info.svg',
    '/assets/exam/PeriodicTable.png',
    '/assets/exam/question-cloud.svg',
    '/assets/exam/resize-left.gif',
    '/assets/exam/resize-right.gif'
  ]

  images.forEach(picture => {
    // eslint-disable-next-line no-undef
    const img = new Image()
    img.src = picture
  })
}

export const SCORE_CALCULATION_METHOD = {
  scaled: 'scaled', // shows scaled & percentile
  percentile: 'percentile' // shows only percentile
}

import React from 'react'
import { Button, Modal } from 'examkrackers-components'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'
// import PATHS from 'utils/paths'

import PreReadingSalty from 'assets/images/Calendar_Salty_LiveClassPreReadingPopup.svg'
// import { CalendarContext } from 'hooks/CalendarContext'
import { useTranslation } from 'react-i18next'

const PreReadingModal = ({ open, setOpen, setWithoutPreReading }) => {
  // const { course } = useContext(CalendarContext)
  const { t } = useTranslation()
  // const { push } = useHistory()
  // console.group('PreReadingModal')
  // console.log('course', course)
  // console.log('open', open)

  // console.groupEnd()

  const handleClose = () => {
    setOpen(false)
    setWithoutPreReading(true)
  }

  const handleAssign = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      hasBorder
      data-testid='PreReadingModal-Modal'
    >
      <>
        <MainWrapper data-testid='PreReadingModal-MainWrapper'>
          <HideCloseButtonWrapper data-testid='PreReadingModal-HideCloseButtonWrapper' />
          <Container data-testid='PreReadingModal-Container'>
            <TextWrapper data-testid='PreReadingModal-TextWrapper'>
              <SectionWrapper data-testid='PreReadingModal-SectionWrapper-titleFirstPart'>
                <SectionTitle data-testid='PreReadingModal-SectionTitle-titleFirstPart'>
                  {t('calendar.preReadingModal.titleFirstPart')}
                </SectionTitle>
              </SectionWrapper>
              <SectionWrapper data-testid='PreReadingModal-SectionWrapper-titleSecondPart'>
                <SectionTitle data-testid='PreReadingModal-SectionTitle-titleSecondPart'>
                  {t('calendar.preReadingModal.titleSecondPart')}
                </SectionTitle>
              </SectionWrapper>
            </TextWrapper>
            <MainSectionWrapper data-testid='PreReadingModal-MainSectionWrapper'>
              <DescriptionWrapper data-testid='PreReadingModal-DescriptionWrapper'>
                {t('calendar.preReadingModal.description')}
              </DescriptionWrapper>
              <SaltyContainer data-testid='PreReadingModal-SaltyContainer'>
                <PreReadingSaltyImage
                  data-testid='PreReadingModal-PreReadingSaltyImage'
                  id='reorganize-salty'
                  src={PreReadingSalty}
                  alt='Salty reorganize'
                />
              </SaltyContainer>
            </MainSectionWrapper>
          </Container>
          <ButtonsWrapper data-testid='PreReadingModal-ButtonsWrapper'>
            <ButtonWrapper
              color='tertiary'
              onClick={handleClose}
              data-testid='PreReadingModal-ButtonWrapper-ButtonDecline'
            >
              {t('calendar.preReadingModal.ButtonDecline')}
            </ButtonWrapper>

            <ButtonWrapper
              color='secondary'
              onClick={handleAssign}
              data-testid='PreReadingModal-ButtonAccept'
            >
              {t('calendar.preReadingModal.ButtonAccept')}
            </ButtonWrapper>
          </ButtonsWrapper>
        </MainWrapper>
      </>
    </Modal>
  )
}

export default PreReadingModal

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 76px;
`

const SectionWrapper = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  &:first-child {
    margin-bottom: 20px;
  }

  &:last-child {
    font-weight: bold;
  }
`

const SectionTitle = styled.div`
  font-size: 16px;
  width: 460px;
  text-align: left;
`

const DescriptionWrapper = styled.div`
  font-size: 14px;
  text-align: left;
  width: 300px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 65px;
  gap: 16px;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
  padding: 12px;
`

const MainSectionWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const SaltyContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`
const PreReadingSaltyImage = styled.img`
  height: 121px;
  width: 165px;
`
const MainWrapper = styled.div`
  min-width: 690px;
  max-width: 690px;
  position: relative;
`

const HideCloseButtonWrapper = styled.div`
  position: absolute;
  top: -48px;
  right: -20px;
  width: 80px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.main.white};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
  margin-bottom: 14px;
`

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Tag } from 'examkrackers-components'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { BOOK_VIEWER_PARAMS, HIGHLIGHT_MODES } from 'utils/books'
import { TABS } from 'modules/books/components/BookRightColumn'
import qs from 'qs'

export const Tags = ({
  tags,
  id,
  activeTopTab = TABS.images,
  activeBottomTab = TABS.notes
}): JSX.Element => {
  const { push } = useHistory()

  const redirectToProperChapter = tag => () => {
    const redirectQuery = {
      [BOOK_VIEWER_PARAMS.bookContentIdContext]: tag.content_id,
      [BOOK_VIEWER_PARAMS.rightTopActive]: activeTopTab,
      [BOOK_VIEWER_PARAMS.rightBottomActive]: activeBottomTab || TABS.notes,
      [BOOK_VIEWER_PARAMS.highlightMode]: HIGHLIGHT_MODES.none
    }

    const query = qs.stringify(redirectQuery)
    push(
      `/books/${tag.book_id}/chapter/${tag.chapter_order}/part/${tag.part}?${query}`
    )
  }

  const renderTags = useMemo(() => {
    return tags.map(tag => (
      <TagWrapper key={`tag-wrapper-${id}-tag-${tag.subchapter_id}`}>
        <Tag
          onClick={redirectToProperChapter(tag)}
          text={`${tag.tag} ${tag.chapter_order}.${tag.subchapter_order}`}
          color={tag.tag_colour}
        />
      </TagWrapper>
    ))
  }, [tags])

  return <TagsWrapper>{isNotNilOrEmpty(tags) && renderTags}</TagsWrapper>
}

export default Tags

const TagsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2px;
`

const TagWrapper = styled.div`
  margin: 5px 3px;
`

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useHistory, useParams } from 'react-router-dom'
import { propOr } from 'ramda'
import { authStudent, fetchStudentDetails } from 'services/AuthService'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import PATHS from 'utils/paths'
import { Loader } from 'examkrackers-components'

// This route redirects to salty blox game for students with no products purchased
export const SaltyBloxLoader = (): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const params = useParams()
  const { push } = useHistory()

  const token = propOr('', 'token', params)

  const handleAminoAcidRedirect = () => {
    push(PATHS.gameSaltyBlox)
  }

  const handleAuth = async () => {
    try {
      const result = await authStudent({ token })

      setLoading(false)

      // clear step: make sure that token is refreshed and active course is
      // cleared
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.token)
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)
      LocalStorageService.set(LOCAL_STORAGE_KEYS.token, result.data.token)

      // used for correct reditect after exiting the game
      LocalStorageService.remove(LOCAL_STORAGE_KEYS.gamesExitKrackU)
      LocalStorageService.set(LOCAL_STORAGE_KEYS.gamesExitKrackU, true)

      // finally, redirect to game
      handleAminoAcidRedirect()
    } catch (e) {
      console.error(e)
      push(PATHS.unauthorized)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (token) {
      handleAuth()
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.general.saltyBlox')}</title>
      </Helmet>
      <AuthContainer>
        {loading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : null}
      </AuthContainer>
    </React.Fragment>
  )
}

export default SaltyBloxLoader

const AuthContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

const LoaderWrapper = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(8px) translateX(3px) scale(4);
`

import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import PATHS from 'utils/paths'

import LocalStorageService from 'services/LocalStorageService'
import { fetchSingleCourse } from 'services/CourseService'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

import { BouncingLoader, Heading1, Heading2 } from 'examkrackers-components'

import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { pathOr } from 'ramda'
import { set } from 'store'

const Dashboard = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const location = useHistory()
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)
  const [isOnDemand, setIsOnDemand] = useState(false)
  const [isFreeTrial, setIsFreeTrial] = useState(false)
  const [isLiveCourse, setIsLiveCourse] = useState(false)

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const checkIfReady = () => {
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)

    const handleSuccess = response => {
      const isCourseReady = pathOr(false, ['data', 'is_ready'], response)
      setIsReady(isCourseReady)
      const isOnDemandType = response.data.type === 'on_demand'
      const isFreeTrialType = response.data.type === 'free_trial'
      const isLiveCourseType = response.data.type === 'live_course'

      setIsOnDemand(isOnDemandType)
      setIsFreeTrial(isFreeTrialType)
      setIsLiveCourse(isLiveCourseType)
    }
    const handleError = e => {
      console.error(e)
      push(PATHS.selectCourse)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    fetchSingleCourse({ id: courseId }).then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    const handleCheckIfReady = setInterval(checkIfReady, 5000)

    return () => {
      clearInterval(handleCheckIfReady)
    }
  }, [])
  // changed for new init flow

  useEffect(() => {
    if (isReady && isOnDemand) {
      push({
        pathname: PATHS.calendarSetup
      })
    } else if (isReady && isFreeTrial) {
      push({
        pathname: PATHS.calendarSetupFreeTrial
      })
    } else if (isReady && isLiveCourse) {
      push(PATHS.dashboard)
    } else if (isReady && !isOnDemand && !isFreeTrial && !isLiveCourse) {
      push(PATHS.calendar)
    }
  }, [isReady])

  return (
    <>
      <Helmet>
        <title>{t('pages.dashboard')}</title>
      </Helmet>
      <Container>
        <StyledBouncingLoader />
        <div className='title'>
          <Heading1>{t('courses.loadingCourse.heading')}</Heading1>
          <Heading2>{t('courses.loadingCourse.description')}</Heading2>
        </div>
      </Container>
    </>
  )
}

export default Dashboard

const Container = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

const StyledBouncingLoader = styled(BouncingLoader)`
  margin-right: 20px;
`

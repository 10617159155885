import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CalendarIcon, NewTabIcon, Tooltip } from 'examkrackers-components'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'
import { getClassInfo } from 'services/ClassScheduleRepository'
import { formatTime } from 'modules/exam/utils/time'
import { useTranslation } from 'react-i18next'
import ModalCalendarImage from './ModalCalendarImage'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/notifications/utils/events'

interface NotificationCalendarProps {
  groupTitle: string
  buttonText: string
  calendarModalOpen: any
  setCalendarModalOpen: any
}

const NotificationCalendarClass = ({
  groupTitle,
  calendarModalOpen,
  setCalendarModalOpen
}: NotificationCalendarProps) => {
  const [classDays, setClassDays] = useState([])

  const [calendarImageUrl, setCalendarImageUrl] = useState()
  const hasClasses = classDays.length > 0
  const currentCourse: Course = useSelector(getCurrentCourse)
  const courseEndDateId = currentCourse?.end_date_id
  const { t } = useTranslation()
  const fetchClassInfo = async () => {
    const response = await getClassInfo(currentCourse.id)
    const data = await response.data
    setClassDays(data.days)
    setCalendarImageUrl(data.calendar_image_url)
  }
  useEffect(() => {
    if (
      courseEndDateId !== null &&
      courseEndDateId !== undefined &&
      courseEndDateId !== ''
    ) {
      fetchClassInfo()
    }
  }, [courseEndDateId])
  const handleCloseCalendarModal = () => {
    setCalendarModalOpen(false)
  }
  const handleOpenCalendarModal = () => {
    setCalendarModalOpen(true)
  }
  const handleClick = url => {
    if (url) {
      return window.open(url, '_blank', 'noopener,noreferrer')
    }
    return () => {}
  }

  const formatTopic = (topic, topicNumber) => {
    const value = `${topic || ' '}  ${topicNumber || ' '}`
    return value.trim() || ''
  }

  const displayFormattedTime = (startTime, endTime) => {
    const formattedStartTime = formatTime(startTime)
    const formattedEndTime = formatTime(endTime)

    if (formattedEndTime.includes('AM') && formattedStartTime.includes('AM')) {
      return `${formattedStartTime.replace('AM', '')} - ${formattedEndTime}`
    }
    if (formattedEndTime.includes('PM') && formattedStartTime.includes('PM')) {
      return `${formattedStartTime.replace('PM', '')} - ${formattedEndTime}`
    }

    return `${formattedStartTime} - ${formattedEndTime}`
  }

  const handleFetchList = isOpen => {
    !isOpen && fetchClassInfo()
  }

  useEffect(() => {
    eventEmitter.on(events.fetchList, handleFetchList)
    return () => {
      eventEmitter.off(events.fetchList, handleFetchList)
    }
  }, [])

  return (
    <>
      {!hasClasses ? null : (
        <Container>
          <CalendarGroupWrapper onClick={handleOpenCalendarModal}>
            <IconContainer>
              <CalendarIcon />
            </IconContainer>
            <GroupTitle>
              {groupTitle}&nbsp;
              <span> {t('notifications.timezone')}</span>
            </GroupTitle>
            <ModalCalendarImage
              handleClose={handleCloseCalendarModal}
              isOpen={calendarModalOpen}
              imageUrl={calendarImageUrl}
            />
          </CalendarGroupWrapper>
          <SubTitle>{t('notifications.nextDays')}</SubTitle>
          <ContentWrapper>
            {classDays.map((classDay: any) => {
              const hasMeetingUrl =
                classDay?.meeting_url !== '' && classDay?.meeting_url !== null
              return (
                <ClassesWrapper
                  key={`index-${classDay.id}`}
                  meetingUrl={classDay.meeting_url}
                  onClick={() => {
                    handleClick(classDay.meeting_url)
                  }}
                >
                  {hasMeetingUrl ? (
                    <TooltipContainer data='tooltip-container'>
                      <Tooltip
                        tooltipContent={t('notifications.tooltip')}
                        id={classDay.id}
                      >
                        <ClassWrapper>
                          <ClassTime>
                            <p>
                              {`${classDay.weekday} ${displayFormattedTime(
                                classDay.class_time,
                                classDay.class_time_end
                              )} ${formatTopic(
                                classDay.class_topic,
                                classDay.class_topic_number
                              )}`}
                            </p>
                          </ClassTime>
                          <IconWrapper>
                            {classDay.meeting_url ? (
                              <NewTabIcon width='10px' height='10px' />
                            ) : null}
                          </IconWrapper>
                        </ClassWrapper>
                      </Tooltip>
                    </TooltipContainer>
                  ) : (
                    <ClassWrapper>
                      <ClassTime>
                        <p>
                          {`${classDay.weekday} ${displayFormattedTime(
                            classDay.class_time,
                            classDay.class_time_end
                          )} ${formatTopic(
                            classDay.class_topic,
                            classDay.class_topic_number
                          )}`}
                        </p>
                      </ClassTime>
                      <IconWrapper>
                        {classDay.meeting_url ? (
                          <NewTabIcon width='10px' height='10px' />
                        ) : null}
                      </IconWrapper>
                    </ClassWrapper>
                  )}
                </ClassesWrapper>
              )
            })}
          </ContentWrapper>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.main.grey300};
  border-radius: 6px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  min-width: 178px;
  width: 100%;
`

const CalendarGroupWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  gap: 4px;
  cursor: pointer;
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.heading};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const GroupTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 4px;
  margin-right: -4px;
  > span {
    align-self: center;
    font-size: 9px;
    font-weight: 400;
  }
`
const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 10px !important;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
`
const ClassesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  cursor: ${({ meetingUrl }) => (meetingUrl ? 'pointer' : 'unset')};
`
const ClassWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.main.grey300};
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const ClassTime = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 11px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 8px;
`

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

const ContentWrapper = styled.div`
  overflow: auto;
  max-height: 200px;
`

const TooltipContainer = styled.div`
  & > div,
  & > div > div:first-child {
    width: 100%;
    cursor: pointer;
  }
`

export default NotificationCalendarClass

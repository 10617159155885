// Dear developer
// I'm really sorry of what is happening here
// Timers logic were changed multiple times which had an impact on the first approach taken here
// This code is really a mess and I'm ashamed of it
// Please forgive me
// @Sandra

// Basic assumptions:
// - Passage Working Time (PWT) = Question Working Times (in this passage) + Passage Reading Time
// - Passage Reading Time (PRT):
//   * if student checked the Red Checkbox = passed time from mounting until checking the Red Checkbox
//   * if student didn't press the Red Checkbox and:
//     a) changes the question = passed time from mounting until changing the page, and the passage is marked as Read
//     b) goes to previous question in previous passage = passed time from mounting until changing the page, but the
//        passage is not marked as Read until student checks the Red Checkbox or navigates to another question
// - Passage Checking Time (PCT) = passed time on that passage in PHASE 2 of the exam (so after reaching "review" page)
// - Question Working Time (QWT):
//   * if student checked the Red Checkbox = passed time - PRT
//   * if student didn't press the Red Checkbox = 0, but this question is marked as "to calculate" for the API. After
//     finishing a section API will take this question and assign it an average of other QWTs. If student returns to a
//     question that was marked as "to calculate" the working time is saved as "working_temp" and will be added to the
//     average value
// - Question Checking Time (QCT) = passed time on that question in PHASE 2 of the exam (so after reaching "review" page)

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'

import {
  getExamDetails,
  getSectionIdByCurrentPageId,
  getStepHtml
} from 'modules/exam/ducks/selectors'
import {
  getExamPassedTime,
  getQuestionIdOnPassageCheckbox,
  getPassedTimeOnPassageCheckbox
} from 'modules/examTimeTracker/ducks/selectors'
import { COURSE_LEARNING_TIME_STATUS } from 'utils/courses'

import {
  resetPassedTimeRoutine,
  countPassedTimeRoutine,
  setCurrentTimersRoutine,
  setQuestionOriginalIdRoutine,
  setPassageOriginalIdRoutine,
  updateTimersOnUnmountRoutine,
  setPassedTimePassageCheckboxRoutine,
  setQuestionIdOnPassageCheckboxRoutine
} from 'modules/examTimeTracker/ducks/actions'

import { updateCourseTimeState } from 'services/CourseService'
import { saveExamTimers } from 'services/ExamsService'
import { RootReducer } from 'types'
import { find, propEq, propOr, pipe, pathOr } from 'ramda'
import { SECTION_STATUSES } from 'modules/exam/utils/exam'
import usePrevious from 'hooks/usePrevious'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useParams } from 'react-router-dom'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import * as R from 'ramda'

import { preparePayloadOnLastQuestionUnmount } from 'modules/exam/utils/timeTracking'
import { getIsPreviewAdmin } from 'modules/auth/ducks/selectors'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

export const TimeTracking = ({
  currentPage,
  passageRead,
  currentPageConfig
}): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()
  const id: string = propOr('', 'id')(params)
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)
  const [isNotFound, setIsNotFound] = useState(false)

  const prevPage = usePrevious(currentPage)
  const prevPassageRead = usePrevious(passageRead)
  const prevPageConfig = usePrevious(currentPageConfig)

  const handleNotFoundExam = () => setIsNotFound(true)

  useEffect(() => {
    eventEmitter.on(events.notFound, handleNotFoundExam)

    return () => {
      eventEmitter.off(events.notFound, handleNotFoundExam)
    }
  }, [])

  // @ts-ignore
  const questionNumber: number = R.propOr(0, 'currentPage', currentPageConfig)
  // @ts-ignore
  const prevQuestionNumber: number = R.propOr(0, 'currentPage', prevPageConfig)

  // Using reducer instead of useState to have access to the up-to-date value on unmount stage
  const passedTime = useSelector(getExamPassedTime)

  const updateCourseLearningTimeIfNeeded = () => {
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
    const hasCourse = isNotNilOrEmpty(courseId)

    const handleError = e => console.error(e)
    const handleSuccess = () => {}

    if (hasCourse) {
      updateCourseTimeState({
        id: courseId,
        // @ts-ignore
        state: COURSE_LEARNING_TIME_STATUS.started,
        seconds: passedTime
      })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  const resetPassedTime = useCallback(
    () => dispatch(resetPassedTimeRoutine()),
    [dispatch]
  )

  const countPassedTime = useCallback(
    () => dispatch(countPassedTimeRoutine()),
    [dispatch]
  )

  const setCurrentTimers = useCallback(
    payload => dispatch(setCurrentTimersRoutine(payload)),
    [dispatch]
  )

  const setPassageOriginalId = useCallback(
    payload => dispatch(setPassageOriginalIdRoutine(payload)),
    [dispatch]
  )

  const setQuestionOriginalId = useCallback(
    payload => dispatch(setQuestionOriginalIdRoutine(payload)),
    [dispatch]
  )

  const updateTimersOnUnmount = useCallback(
    payload => dispatch(updateTimersOnUnmountRoutine(payload)),
    [dispatch]
  )

  // Save the id of the question on which passage reading time box was checked
  const setQuestionIdOnPassageCheckbox = useCallback(
    payload => dispatch(setQuestionIdOnPassageCheckboxRoutine(payload)),
    [dispatch]
  )

  // Save the passed time when clicking the checkbox, tu subtract it from the total passed time
  const setPassedTimeOnPassageCheckbox = useCallback(
    payload => dispatch(setPassedTimePassageCheckboxRoutine(payload)),
    [dispatch]
  )

  const questionIdOnPassageCheckbox = useSelector(
    getQuestionIdOnPassageCheckbox
  )
  const passedTimeOnPassageCheckbox = useSelector(
    getPassedTimeOnPassageCheckbox
  )

  const examDetails = useSelector(getExamDetails)
  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, currentPage)
  )
  const prevStepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, prevPage)
  )
  const sectionIdByCurrentPageId = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, currentPage)
  )

  // Handler to fetch exam details.
  const fetchExamDetails = React.useCallback(
    () => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  const isFalsePassage = R.propOr(false, 'isFalsePassage', stepHtml)
  const isPreviousFalsePassage = R.propOr(false, 'isFalsePassage', prevStepHtml)

  const passageOriginalId: string = propOr('', 'passageOriginalId', stepHtml)
  const prevPassageOriginalId: string = propOr(
    '',
    'passageOriginalId',
    prevStepHtml
  )
  const questionOriginalId: string = propOr('', 'questionOriginalId', stepHtml)
  const prevQuestionOriginalId: string = propOr(
    '',
    'questionOriginalId',
    prevStepHtml
  )
  const sectionStatus = pipe(
    propOr([], 'questions'),
    find(propEq('id', sectionIdByCurrentPageId)),
    propOr(SECTION_STATUSES.phase1, 'section_status')
  )(examDetails)

  const examTimers: object = pipe(
    pathOr('{}', ['exam', 'timers']),
    JSON.parse
  )(examDetails)

  const timerCheckboxes: any = pipe(
    pathOr('{}', ['exam', 'timer_checkboxes']),
    JSON.parse
  )(examDetails)

  const passageReading = pathOr(0, [passageOriginalId, 'reading'], examTimers)
  const prevPassageReading = pathOr(
    0,
    [prevPassageOriginalId, 'reading'],
    examTimers
  )
  const passageWorking = pathOr(0, [passageOriginalId, 'working'], examTimers)
  const prevPassageWorking = pathOr(
    0,
    [prevPassageOriginalId, 'working'],
    examTimers
  )
  const passageChecking = pathOr(0, [passageOriginalId, 'checking'], examTimers)
  const prevPassageChecking = pathOr(
    0,
    [prevPassageOriginalId, 'checking'],
    examTimers
  )

  const prevQuestionWorking = pathOr(
    0,
    [prevQuestionOriginalId, 'working'],
    examTimers
  )

  const prevTempQuestionWorking = pathOr(
    0,
    [prevQuestionOriginalId, 'working_temp'],
    examTimers
  )

  const prevQuestionChecking = pathOr(
    0,
    [prevQuestionOriginalId, 'checking'],
    examTimers
  )

  const hasIdsFetched =
    isNotNilOrEmpty(passageOriginalId) && isNotNilOrEmpty(questionOriginalId)
  const hasPrevIdsFetched =
    isNotNilOrEmpty(prevPassageOriginalId) &&
    isNotNilOrEmpty(prevQuestionOriginalId)

  const handleSaveTimers = (timers, checkboxes = timerCheckboxes) => {
    const handleError = e => console.error(e)
    // Do not remove this state refresh! Without that we will loose timers tracking
    const handleSuccess = () => fetchExamDetails()

    if (!isPreviewAdmin) {
      saveExamTimers({ id, timers, timerCheckboxes: checkboxes })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  useEffect(() => {
    const timer = setInterval(countPassedTime, 1000)

    if (prevPage !== currentPage) {
      resetPassedTime()
    }

    return () => {
      clearInterval(timer)
    }
  }, [currentPage])

  useEffect(() => {
    setPassageOriginalId(passageOriginalId)
    setQuestionOriginalId(questionOriginalId)
  }, [stepHtml])

  useEffect(() => {
    return () => {
      if (!isNotFound) {
        updateTimersOnUnmount({
          generatePayload: preparePayloadOnLastQuestionUnmount,
          handleSaveTimers: handleSaveTimers
        })
      }
    }
  }, [isNotFound])

  useEffect(() => {
    setCurrentTimers(examTimers)
  }, [examDetails])

  // HANDLE TIMERS ON PAGE CHANGE
  useEffect(() => {
    if (prevPage !== currentPage) {
      // reset the checkbox question id & passage time on checkbox if the page was changed
      // this is for avoiding negative values if user checks the checkbox and quickly changes the page
      setQuestionIdOnPassageCheckbox('')
      setPassedTimeOnPassageCheckbox(0)
    }

    if (passedTime > 0 && prevPage !== currentPage && hasPrevIdsFetched) {
      const isPassagePreviouslyStopped = R.pathOr(false, [
        'isPassageReadingTimeStopped',
        prevPassageOriginalId
      ])(timerCheckboxes)
      const isQuestionMarkedAsToCalculate = R.pathOr(false, [
        'isQuestionWorkingTimeToCalculate',
        prevQuestionOriginalId
      ])(timerCheckboxes)
      const passageReadingTime = isPassagePreviouslyStopped
        ? prevPassageReading
        : prevPassageReading + passedTime

      const passageWorkingTimeOnPathChange = () => {
        switch (true) {
          // Add passed time on PHASE1 when page was changed (so prev passage is updated)
          case sectionStatus === SECTION_STATUSES.phase1 &&
            !isPreviousFalsePassage:
            return prevPassageWorking + passedTime
          default:
            return prevPassageWorking
        }
      }

      const passageCheckingTimeOnPathChange = () => {
        switch (true) {
          // Add passed time on PHASE2 when page was changed (so prev passage is updated)
          case sectionStatus === SECTION_STATUSES.review &&
            !isPreviousFalsePassage:
            return prevPassageChecking + passedTime
          default:
            return prevPassageChecking
        }
      }

      const isQuestionAlreadyTrackedInTimers = R.pipe(
        R.propOr('', prevQuestionOriginalId),
        isNotNilOrEmpty
      )(examTimers)

      const questionWorkingTimeOnPathChange = () => {
        switch (true) {
          // Passage Reading Time was not saved yet, question is not saved in timers, and question was not marked as "to calculate"
          case sectionStatus === SECTION_STATUSES.phase1 &&
            !isPassagePreviouslyStopped &&
            !isQuestionAlreadyTrackedInTimers &&
            !isQuestionMarkedAsToCalculate &&
            !isPreviousFalsePassage:
            return 0
          // Passage Reading time was saved on this specific question (manually checked by the student) & question is not saved in timers
          case sectionStatus === SECTION_STATUSES.phase1 &&
            isPassagePreviouslyStopped &&
            prevQuestionOriginalId === questionIdOnPassageCheckbox &&
            !isQuestionMarkedAsToCalculate:
            return (
              prevQuestionWorking + passedTime - passedTimeOnPassageCheckbox
            )
          // Passage Reading Time was saved before (but not on this question) & question is not marked as "to calculate"
          case sectionStatus === SECTION_STATUSES.phase1 &&
            isPassagePreviouslyStopped &&
            !isQuestionMarkedAsToCalculate:
          case sectionStatus === SECTION_STATUSES.phase1 &&
            isPreviousFalsePassage:
            return prevQuestionWorking + passedTime
          default:
            return prevQuestionWorking
        }
      }

      const questionTempWorkingTimeOnPathChange = () => {
        switch (true) {
          // Passage Reading Time was previously saved on this question, question was marked as "to calculate" and student returns into this question
          case sectionStatus === SECTION_STATUSES.phase1 &&
            isPassagePreviouslyStopped &&
            isQuestionAlreadyTrackedInTimers &&
            isQuestionMarkedAsToCalculate:
            return prevTempQuestionWorking + passedTime
          default:
            return prevTempQuestionWorking
        }
      }

      const questionCheckingTimeOnPathChange = () => {
        switch (true) {
          // Add passed time on PHASE2 when page was changed (so prev question is updated)
          case sectionStatus === SECTION_STATUSES.review:
            return prevQuestionChecking + passedTime
          default:
            return prevQuestionChecking
        }
      }

      const payload = {
        ...examTimers,
        [prevPassageOriginalId]: {
          resource_type: 'passage',
          reading: isPreviousFalsePassage ? 0 : passageReadingTime,
          working: passageWorkingTimeOnPathChange(),
          working_temp: 0,
          checking: passageCheckingTimeOnPathChange()
        },
        [prevQuestionOriginalId]: {
          resource_type: 'question',
          reading: 0,
          working: questionWorkingTimeOnPathChange(),
          working_temp: questionTempWorkingTimeOnPathChange(),
          checking: questionCheckingTimeOnPathChange()
        }
      }

      const isNavigatedToPrevQuestion =
        prevQuestionNumber === questionNumber + 1
      const isNavigatedToAnotherPassage =
        prevPassageOriginalId !== passageOriginalId

      const passageCheckboxes: { [p: string]: boolean } = R.propOr(
        {},
        'isPassageReadingTimeStopped',
        timerCheckboxes
      )
      const questionCheckboxes: { [p: string]: boolean } = R.propOr(
        {},
        'isQuestionWorkingTimeToCalculate',
        timerCheckboxes
      )

      // if students navigates to prev question and it is in another passage, then this passage should be PAUSED, (not checked)
      const isPassageReadingTimeStopped =
        isNavigatedToPrevQuestion && isNavigatedToAnotherPassage
          ? passageCheckboxes
          : { ...passageCheckboxes, [prevPassageOriginalId]: true }

      const isPassageCurrentlyStopped = R.propOr(
        false,
        prevPassageOriginalId,
        isPassageReadingTimeStopped
      )

      // Mark question working time as "to calculate" if passage is STOPPED by changing the path
      const isQuestionWorkingTimeToCalculate =
        sectionStatus === SECTION_STATUSES.phase1 &&
        // @ts-ignore
        isPassageCurrentlyStopped &&
        // @ts-ignore
        !isPassagePreviouslyStopped &&
        questionWorkingTimeOnPathChange() === 0
          ? { ...questionCheckboxes, [prevQuestionOriginalId]: true }
          : questionCheckboxes

      updateCourseLearningTimeIfNeeded()
      handleSaveTimers(payload, {
        isPassageReadingTimeStopped,
        isQuestionWorkingTimeToCalculate
      })
    }
  }, [
    passedTime,
    currentPage,
    examDetails,
    stepHtml,
    prevStepHtml,
    currentPageConfig
  ])

  // HANDLE TIMERS ON SELECT CHECKBOX
  useEffect(() => {
    if (passedTime >= 0 && !prevPassageRead && passageRead && hasIdsFetched) {
      const passageReadingTimeOnSelectCheckbox = () => {
        switch (true) {
          // Passage has standalone questions (it is not a real passage)
          case isFalsePassage:
            return 0
          // Passage Reading Time has been already saved
          case prevPassageRead:
            return passageReading
          default:
            return passageReading + passedTime
        }
      }

      if (passageReadingTimeOnSelectCheckbox() > 0) {
        // Save question id on which the time box is checked
        setQuestionIdOnPassageCheckbox(questionOriginalId)
        setPassedTimeOnPassageCheckbox(passedTime)
      }

      const payload = {
        ...examTimers,
        [passageOriginalId]: {
          resource_type: 'passage',
          reading: passageReadingTimeOnSelectCheckbox(),
          working: passageWorking,
          working_temp: 0,
          checking: passageChecking
        }
      }

      const passageCheckboxes: { [p: string]: boolean } = R.propOr(
        {},
        'isPassageReadingTimeStopped',
        timerCheckboxes
      )
      const isPassageReadingTimeStopped = {
        ...passageCheckboxes,
        [passageOriginalId]: true
      }
      const isQuestionWorkingTimeToCalculate: { [p: string]: boolean } =
        R.propOr({}, 'isQuestionWorkingTimeToCalculate', timerCheckboxes)

      handleSaveTimers(payload, {
        isPassageReadingTimeStopped,
        isQuestionWorkingTimeToCalculate
      })
    }
  }, [passedTime, passageRead, examDetails, stepHtml, currentPageConfig])

  return <div />
}

export default TimeTracking

import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import { select } from '@redux-saga/core/effects'
import * as examsService from 'services/ExamsService'
import * as examTypesService from 'services/ExamTypesService'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import { ActionPayload } from '../../../types'

//
// Routines
//
export const fetchScoreProjectionDataRoutine = createRoutine(
  'FETCH_SCORE_PROJECTION_DATA'
)
export const fetchPassageReadingTimeGraphRoutine = createRoutine(
  'FETCH_PASSAGE_READING_TIME_GRAPH'
)

export const fetchPassageWorkingTimeGraphRoutine = createRoutine(
  'FETCH_PASSAGE_WORKING_TIME_GRAPH'
)

export const fetchQuestionsGraphDataRoutine = createRoutine(
  'FETCH_QUESTIONS_GRAPH_DATA'
)

export const toggleExamExclusionRoutine = createRoutine('TOGGLE_EXAM_EXCLUSION')

export const fetchPassageTimersRoutine = createRoutine('FETCH_PASSAGE_TIMERS')
export const fetchTargetQuestionsGraphDataRoutine = createRoutine(
  'FETCH_TARGET_QUESTIONS_GRAPH_DATA'
)
export const fetchAverageQuestionsGraphDataRoutine = createRoutine(
  'FETCH_AVERAGE_QUESTIONS_GRAPH_DATA'
)
export const fetchTargetPassagesGraphDataRoutine = createRoutine(
  'FETCH_TARGET_PASSAGES_GRAPH_DATA'
)
export const fetchAveragePassagesGraphDataRoutine = createRoutine(
  'FETCH_AVERAGE_PASSAGES_GRAPH_DATA'
)

export const fetchSingleQuestionMetricsRoutine = createRoutine(
  'FETCH_SINGLE_QUESTION_METRICS'
)

export const fetchExamTypeScoreProjectionsRoutine = createRoutine(
  'FETCH_EXAM_TYPE_SCORE_PROJECTIONS'
)

export const saveComparedExamRoutine = createRoutine('SAVE_COMPARED_EXAM')

export const saveComparedExamQuestionsRoutine = createRoutine(
  'SAVE_COMPARED_EXAM_QUESTIONS'
)

export const saveComparedExamPassagesRoutine = createRoutine(
  'SAVE_COMPARED_EXAM_PASSAGES'
)

export const saveComparedExamPassagesWorkingRoutine = createRoutine(
  'SAVE_COMPARED_EXAM_PASSAGES_WORKING'
)

export const fetchComparedTargetQuestionsGraphDataRoutine = createRoutine(
  'FETCH_COMPARED_TARGET_QUESTIONS_GRAPH_DATA'
)

export const fetchComparedAverageQuestionsGraphDataRoutine = createRoutine(
  'FETCH_COMPARED_AVERAGE_QUESTIONS_GRAPH_DATA'
)

export const fetchComparedTargetPassagesGraphDataRoutine = createRoutine(
  'FETCH_COMPARED_TARGET_PASSAGES_GRAPH_DATA'
)

export const fetchComparedAveragePassagesGraphDataRoutine = createRoutine(
  'FETCH_COMPARED_AVERAGE_PASSAGES_GRAPH_DATA'
)

export const fetchComparedPassageTimersRoutine = createRoutine(
  'FETCH_COMPARED_PASSAGE_TIMERS'
)

export const resetComparedExamRoutine = createRoutine('RESET_COMPARED_EXAM')

export const saveDiagnosticGraphLegendStateRoutine = createRoutine(
  'SAVE_DIAGNOSTIC_GRAPH_LEGEND_STATE'
)

//
// Actions
//

// Fetch exam type score projections
function* fetchExamTypeScoreProjections({ payload }: ActionPayload) {
  yield Effects.put(fetchExamTypeScoreProjectionsRoutine.request())

  try {
    const result = yield Effects.call(
      examTypesService.fetchExamTypeScoreProjections,
      payload
    )
    yield Effects.put(
      fetchExamTypeScoreProjectionsRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchExamTypeScoreProjectionsRoutine.failure(e))
    console.error(e)
  }
}

// Fetch the current student's score projection data
function* fetchScoreProjectionData({ payload }: ActionPayload) {
  yield Effects.put(fetchScoreProjectionDataRoutine.request())

  try {
    const result = yield Effects.call(
      examsService.fetchScoreProjectionData,
      payload
    )
    yield Effects.put(
      fetchScoreProjectionDataRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchScoreProjectionDataRoutine.failure(e))
    console.error(e)
  }
}

function* fetchPassageReadingTimeGraph({ payload }: ActionPayload) {
  yield Effects.put(fetchPassageReadingTimeGraphRoutine.request())

  try {
    const result = yield Effects.call(
      examsService.getPassageReadingTimeGraph,
      payload
    )
    yield Effects.put(
      fetchPassageReadingTimeGraphRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchPassageReadingTimeGraphRoutine.failure(e))
    console.error(e)
  }
}

function* fetchPassageWorkingTimeGraph({ payload }: ActionPayload) {
  yield Effects.put(fetchPassageWorkingTimeGraphRoutine.request())

  try {
    const result = yield Effects.call(
      examsService.getPassageWorkingTimeGraph,
      payload
    )
    yield Effects.put(
      fetchPassageWorkingTimeGraphRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchPassageWorkingTimeGraphRoutine.failure(e))
    console.error(e)
  }
}

function* fetchPassageTimers({ payload }: ActionPayload) {
  yield Effects.put(fetchPassageTimersRoutine.request())
  try {
    const result = yield Effects.call(examsService.getPassageTimers, payload)
    yield Effects.put(
      fetchPassageTimersRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchPassageTimersRoutine.failure(e))
    console.error(e)
  }
}

function* fetchQuestionsGraphData({ payload }: ActionPayload) {
  // FIXME: This is a hack.
  if (!R.propOr(true, 'silent', payload)) {
    yield Effects.put(fetchQuestionsGraphDataRoutine.request())
  }

  try {
    const result = yield Effects.call(
      examsService.getQuestionsGraphData,
      R.omit(['silent'], payload)
    )
    yield Effects.put(
      fetchQuestionsGraphDataRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchQuestionsGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* fetchTargetQuestionsGraphData({ payload }: ActionPayload) {
  yield Effects.put(fetchTargetQuestionsGraphDataRoutine.request())

  try {
    const result = yield Effects.call(
      examsService.getTargetQuestionsGraphData,
      payload
    )
    yield Effects.put(
      fetchTargetQuestionsGraphDataRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchTargetQuestionsGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* fetchAverageQuestionsGraphData({ payload }: ActionPayload) {
  yield Effects.put(fetchAverageQuestionsGraphDataRoutine.request())
  try {
    const result = yield Effects.call(
      examsService.getAverageQuestionsGraphData,
      payload
    )
    yield Effects.put(
      fetchAverageQuestionsGraphDataRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchAverageQuestionsGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* fetchTargetPassagesGraphData({ payload }: ActionPayload) {
  yield Effects.put(fetchTargetPassagesGraphDataRoutine.request())
  try {
    const result = yield Effects.call(
      examsService.getTargetPassagesGraphData,
      payload
    )
    yield Effects.put(
      fetchTargetPassagesGraphDataRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchTargetPassagesGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* fetchAveragePassagesGraphData({ payload }: ActionPayload) {
  yield Effects.put(fetchAveragePassagesGraphDataRoutine.request())
  try {
    const result = yield Effects.call(
      examsService.getAveragePassagesGraphData,
      payload
    )
    yield Effects.put(
      fetchAveragePassagesGraphDataRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchAveragePassagesGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* saveComparedExam({ payload }: ActionPayload) {
  yield Effects.put(saveComparedExamRoutine.request())

  try {
    const exam = yield Effects.call(examsService.fetchExamsDetails, {
      id: payload.values.examId
    })
    yield Effects.put(
      saveComparedExamRoutine.success(R.pathOr({}, ['data'], exam))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(saveComparedExamRoutine.failure())
  }
}

function* saveComparedExamPassages({ payload }: ActionPayload) {
  yield Effects.put(saveComparedExamPassagesRoutine.request())

  try {
    const passages = yield Effects.call(
      examsService.getPassageReadingTimeGraph,
      {
        id: payload.values.sectionId
      }
    )
    yield Effects.put(
      saveComparedExamPassagesRoutine.success(R.pathOr([], ['data'], passages))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(saveComparedExamPassagesRoutine.failure())
  }
}

function* saveComparedExamPassagesWorking({ payload }: ActionPayload) {
  yield Effects.put(saveComparedExamPassagesWorkingRoutine.request())

  try {
    const passages = yield Effects.call(
      examsService.getPassageWorkingTimeGraph,
      {
        id: payload.values.sectionId
      }
    )
    yield Effects.put(
      saveComparedExamPassagesWorkingRoutine.success(
        R.pathOr([], ['data'], passages)
      )
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(saveComparedExamPassagesWorkingRoutine.failure())
  }
}

function* saveComparedExamQuestions({ payload }: ActionPayload) {
  yield Effects.put(saveComparedExamQuestionsRoutine.request())

  try {
    const questions = yield Effects.call(examsService.getQuestionsGraphData, {
      id: payload.values.sectionId
    })
    yield Effects.put(
      saveComparedExamQuestionsRoutine.success(
        R.pathOr([], ['data'], questions)
      )
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(saveComparedExamQuestionsRoutine.failure())
  }
}

function* fetchComparedPassageTimers({ payload }: ActionPayload) {
  yield Effects.put(fetchComparedPassageTimersRoutine.request())
  try {
    const result = yield Effects.call(examsService.getPassageTimers, payload)
    yield Effects.put(
      fetchComparedPassageTimersRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchComparedPassageTimersRoutine.failure(e))
    console.error(e)
  }
}

function* fetchComparedTargetQuestionsGraphData({ payload }: ActionPayload) {
  yield Effects.put(fetchComparedTargetQuestionsGraphDataRoutine.request())

  try {
    const result = yield Effects.call(
      examsService.getTargetQuestionsGraphData,
      payload
    )
    yield Effects.put(
      fetchComparedTargetQuestionsGraphDataRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchComparedTargetQuestionsGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* fetchComparedAverageQuestionsGraphData({ payload }: ActionPayload) {
  yield Effects.put(fetchComparedAverageQuestionsGraphDataRoutine.request())
  try {
    const result = yield Effects.call(
      examsService.getAverageQuestionsGraphData,
      payload
    )
    yield Effects.put(
      fetchComparedAverageQuestionsGraphDataRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchComparedAverageQuestionsGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* fetchComparedTargetPassagesGraphData({ payload }: ActionPayload) {
  yield Effects.put(fetchComparedTargetPassagesGraphDataRoutine.request())

  try {
    const result = yield Effects.call(
      examsService.getTargetPassagesGraphData,
      payload
    )
    yield Effects.put(
      fetchComparedTargetPassagesGraphDataRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchComparedTargetPassagesGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* fetchComparedAveragePassagesGraphData({ payload }: ActionPayload) {
  yield Effects.put(fetchComparedAveragePassagesGraphDataRoutine.request())
  try {
    const result = yield Effects.call(
      examsService.getAveragePassagesGraphData,
      payload
    )
    yield Effects.put(
      fetchComparedAveragePassagesGraphDataRoutine.success(
        R.pathOr([], ['data'], result)
      )
    )
  } catch (e) {
    yield Effects.put(fetchComparedAveragePassagesGraphDataRoutine.failure(e))
    console.error(e)
  }
}

function* toggleExamExclusion({ payload }: ActionPayload) {
  yield Effects.put(toggleExamExclusionRoutine.request())
  try {
    // Check if the current user is being impersonated by an admin.
    const userDetails = yield select(getStudent)
    const impersonating: boolean = R.propOr(
      false,
      'is_impersonated',
      userDetails
    )

    if (impersonating) {
      throw new Error('Cannot change target score while impersonating.')
    }

    const result = yield Effects.call(examsService.togglePTSExclusion, {
      id: payload.values.id
    })
    yield Effects.put(
      toggleExamExclusionRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(toggleExamExclusionRoutine.failure(e))
    console.error(e)
  }
}

export function* refreshTargetScoreData() {
  yield Effects.put(fetchScoreProjectionDataRoutine.request())

  const examDetails = yield select(getExamDetails)
  const examTypeId = R.pathOr('', ['exam', 'exam_type_id'], examDetails)
  const examLayoutId = R.pathOr('', ['exam', 'layout_id'], examDetails)

  yield Effects.call(fetchScoreProjectionDataRoutine, {
    type_id: examTypeId,
    layout_id: examLayoutId
  })

  yield Effects.put(
    fetchExamDetailsRoutine({
      id: R.pathOr('', ['exam', 'id'], examDetails)
    })
  )
}

// Routine to fetch the metrics for a single exam question.
function* fetchSingleQuestionMetrics({ payload }: ActionPayload) {
  yield Effects.put(fetchSingleQuestionMetricsRoutine.request())

  try {
    const result = yield Effects.call(
      examsService.getSingleQuestionMetrics,
      payload
    )

    yield Effects.put(
      fetchSingleQuestionMetricsRoutine.success(R.pathOr({}, ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchSingleQuestionMetricsRoutine.failure(e))
    console.error(e)
  }
}

// Routine to reset compared exam data
function* resetComparedExam() {
  yield Effects.put(resetComparedExamRoutine.request())
  yield Effects.put(resetComparedExamRoutine.success())
}

// Routine to save legend state
function* saveDiagnosticGraphLegendState(payload) {
  yield Effects.put(saveDiagnosticGraphLegendStateRoutine.request())
  yield Effects.put(saveDiagnosticGraphLegendStateRoutine.success(payload))
}

//
// Watchers
//

export function* fetchScoreProjectionDataWatcher() {
  yield Effects.takeLatest(
    fetchScoreProjectionDataRoutine.TRIGGER,
    fetchScoreProjectionData
  )
}

export function* fetchExamTypeScoreProjectionsWatcher() {
  yield Effects.takeLatest(
    fetchExamTypeScoreProjectionsRoutine.TRIGGER,
    fetchExamTypeScoreProjections
  )
}

export function* fetchPassageReadingTimeGraphWatcher() {
  yield Effects.takeLatest(
    fetchPassageReadingTimeGraphRoutine.TRIGGER,
    fetchPassageReadingTimeGraph
  )
}

export function* fetchPassageWorkingTimeGraphWatcher() {
  yield Effects.takeLatest(
    fetchPassageWorkingTimeGraphRoutine.TRIGGER,
    fetchPassageWorkingTimeGraph
  )
}

export function* fetchQuestionsGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchQuestionsGraphDataRoutine.TRIGGER,
    fetchQuestionsGraphData
  )
}

export function* fetchPassageTimersWatcher() {
  yield Effects.takeLatest(
    fetchPassageTimersRoutine.TRIGGER,
    fetchPassageTimers
  )
}

export function* fetchTargetQuestionsGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchTargetQuestionsGraphDataRoutine.TRIGGER,
    fetchTargetQuestionsGraphData
  )
}

export function* fetchAverageQuestionsGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchAverageQuestionsGraphDataRoutine.TRIGGER,
    fetchAverageQuestionsGraphData
  )
}

export function* fetchTargetPassagesGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchTargetPassagesGraphDataRoutine.TRIGGER,
    fetchTargetPassagesGraphData
  )
}

export function* fetchAveragePassagesGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchAveragePassagesGraphDataRoutine.TRIGGER,
    fetchAveragePassagesGraphData
  )
}

export function* toggleExamExclusionWatcher() {
  yield Effects.takeLatest(
    toggleExamExclusionRoutine.TRIGGER,
    toggleExamExclusion
  )
}

export function* fetchSingleQuestionMetricsWatcher() {
  yield Effects.takeLatest(
    fetchSingleQuestionMetricsRoutine.TRIGGER,
    fetchSingleQuestionMetrics
  )
}

export function* saveComparedExamWatcher() {
  yield Effects.takeLatest(saveComparedExamRoutine.TRIGGER, saveComparedExam)
}

export function* saveComparedExamQuestionsWatcher() {
  yield Effects.takeLatest(
    saveComparedExamQuestionsRoutine.TRIGGER,
    saveComparedExamQuestions
  )
}

export function* saveComparedExamPassagesWatcher() {
  yield Effects.takeLatest(
    saveComparedExamPassagesRoutine.TRIGGER,
    saveComparedExamPassages
  )
}

export function* saveComparedExamPassagesWorkingWatcher() {
  yield Effects.takeLatest(
    saveComparedExamPassagesWorkingRoutine.TRIGGER,
    saveComparedExamPassagesWorking
  )
}

export function* fetchComparedTargetQuestionsGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchComparedTargetQuestionsGraphDataRoutine.TRIGGER,
    fetchComparedTargetQuestionsGraphData
  )
}

export function* fetchComparedAverageQuestionsGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchComparedAverageQuestionsGraphDataRoutine.TRIGGER,
    fetchComparedAverageQuestionsGraphData
  )
}

export function* fetchComparedTargetPassagesGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchComparedTargetPassagesGraphDataRoutine.TRIGGER,
    fetchComparedTargetPassagesGraphData
  )
}

export function* fetchComparedAveragePassagesGraphDataWatcher() {
  yield Effects.takeLatest(
    fetchComparedAveragePassagesGraphDataRoutine.TRIGGER,
    fetchComparedAveragePassagesGraphData
  )
}

export function* resetComparedExamWatcher() {
  yield Effects.takeLatest(resetComparedExamRoutine.TRIGGER, resetComparedExam)
}

export function* saveDiagnosticGraphLegendStateWatcher() {
  yield Effects.takeLatest(
    saveDiagnosticGraphLegendStateRoutine.TRIGGER,
    saveDiagnosticGraphLegendState
  )
}

export function* fetchComparedPassageTimersWatcher() {
  yield Effects.takeLatest(
    fetchComparedPassageTimersRoutine.TRIGGER,
    fetchComparedPassageTimers
  )
}

// SAGAS

export const diagnosticSagas = [
  Effects.fork(saveComparedExamPassagesWorkingWatcher),
  Effects.fork(saveComparedExamPassagesWatcher),
  Effects.fork(saveComparedExamQuestionsWatcher),
  Effects.fork(saveComparedExamWatcher),
  Effects.fork(fetchExamTypeScoreProjectionsWatcher),
  Effects.fork(fetchScoreProjectionDataWatcher),
  Effects.fork(fetchPassageReadingTimeGraphWatcher),
  Effects.fork(fetchPassageWorkingTimeGraphWatcher),
  Effects.fork(fetchPassageTimersWatcher),
  Effects.fork(fetchQuestionsGraphDataWatcher),
  Effects.fork(fetchTargetQuestionsGraphDataWatcher),
  Effects.fork(fetchAverageQuestionsGraphDataWatcher),
  Effects.fork(fetchTargetPassagesGraphDataWatcher),
  Effects.fork(fetchAveragePassagesGraphDataWatcher),
  Effects.fork(toggleExamExclusionWatcher),
  Effects.fork(fetchSingleQuestionMetricsWatcher),
  Effects.fork(fetchComparedTargetQuestionsGraphDataWatcher),
  Effects.fork(fetchComparedAverageQuestionsGraphDataWatcher),
  Effects.fork(fetchComparedTargetPassagesGraphDataWatcher),
  Effects.fork(fetchComparedAveragePassagesGraphDataWatcher),
  Effects.fork(resetComparedExamWatcher),
  Effects.fork(saveDiagnosticGraphLegendStateWatcher),
  Effects.fork(fetchComparedPassageTimersWatcher)
]

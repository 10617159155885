import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  validateChangeUsernameField,
  validateChangeUsernameValue
} from 'modules/auth/ducks/schema'

import { getStudent } from 'modules/auth/ducks/selectors'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
// import { isNotNilOrEmpty } from 'utils/ramda'

import { InputField, Button } from 'examkrackers-components'
import { updateStudentUsername } from 'services/AuthService'
import { errors, getErrorName } from 'utils/errors'

const emptyValues = {
  email: '',
  username: ''
}

export const FormEditUsername = (props): JSX.Element => {
  const { onSubmitSuccess } = props
  const dispatch = useDispatch()

  const studentInfo = useSelector(getStudent)

  const { t } = useTranslation()
  const [valid, _validateSchema] = React.useState(false)
  const [values, setValues] = React.useState(emptyValues)
  const [isLoading, setIsLoading] = React.useState(false)

  const showToast = React.useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  React.useEffect(() => {
    setValues({
      email: studentInfo.email,
      username: studentInfo.username
    })
  }, [studentInfo])

  React.useEffect(() => {
    validateChangeUsernameValue(values, _validateSchema)
  }, [values])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)

    const handleSuccess = () => {
      onSubmitSuccess()
      setIsLoading(false)

      showToast({
        key: 'toast.usernameChanged',
        severity: SEVERITY.success,
        options: { username: values.username }
      })
    }

    const handleError = e => {
      // @ts-ignore
      const name = getErrorName(e)
      if (name === errors.usernameAlreadyExists) {
        showToast({
          key: 'toast.usernameAlreadyExists',
          severity: SEVERITY.error,
          options: { username: values.username }
        })
      } else
        showToast({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error
        })
      setIsLoading(false)
    }
    // Avoiding the request if the username hasn't been changed while the modal was open
    // values.username - component state, studentInfo.username - redux store data
    values.username === ('' || null) || values.username !== studentInfo.username
      ? updateStudentUsername(values.username)
          .then(handleSuccess)
          .catch(handleError)
      : handleSuccess()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        t={t}
        name='email'
        value={values.email}
        id='user-email'
        label={t('form.username.email')}
        disabled
        onChange={handleOnChange}
        validate={validateChangeUsernameField(values)}
      />
      <InputField
        className='username-input'
        t={t}
        name='username'
        value={values.username}
        id='student-username'
        label={t('form.username.label')}
        onChange={handleOnChange}
        validate={validateChangeUsernameField(values)}
        required
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='edit-username-submit'
          color='secondary'
          disabled={isLoading || !valid}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {t('form.username.CTA')}
        </Button>
        <Button
          id='edit-username-cancel'
          type='button'
          color='tertiary'
          variant='contained'
          isLoading={isLoading}
          onClick={onSubmitSuccess}
        >
          {t('form.username.cancelButton')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default FormEditUsername

const ButtonContainer = styled.div`
  margin: 40px 0 0;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  button {
    min-width: 160px;
  }
`

const Form = styled.div`
  display: flex;
  flex-direction: column;

  .username-input ~ div {
    white-space: pre-line;
    text-align: left;
    bottom: -33px;
  }
`

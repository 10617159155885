import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBookByOriginalId,
  selectCurrentBookChaptersWithExams,
  selectCurrentBookFreeTrialExam
} from 'modules/books/ducks/selectors'
import { useParams } from 'react-router'
import styled, { css } from 'styled-components'
import { pipe, propOr, pathOr } from 'ramda'
import {
  ContentQuestionsOutlineIcon,
  SingleSelect,
  StatusTag,
  Tooltip
} from 'examkrackers-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ModalConfirmStart from 'modules/exams/components/ModalConfirmStart'
import { isNotNilOrEmpty } from 'utils/ramda'
import { EXAM_STATUS } from 'utils/exam'
import ModalConfirmResume from 'modules/exams/components/ModalConfirmResume'
import { fetchChaptersWithExamsRoutine } from 'modules/books/ducks/actions'
import { Course } from 'types'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import * as R from 'ramda'
import { COURSE_TYPES } from 'utils/courses'
import { getIsImpersonate } from 'modules/auth/ducks/selectors'

interface IAssignedExam {
  status?: string
  id?: string
  is_available?: boolean
  title?: string
}
interface IExams {
  assigned_exam: IAssignedExam
  order: string
  title: unknown
  free_trial: boolean
}

const SelectChapterExam = () => {
  const [open, setOpen] = useState(false)
  const [isConfirmStartModalOpen, setIsConfirmStartModalOpen] = useState(false)
  const [isConfirmResumeModalOpen, setIsConfirmResumeModalOpen] =
    useState(false)
  const [selectedChapter, setSelectedChapter] = useState(null)
  const { push } = useHistory()
  const { t } = useTranslation()
  const params = useParams()
  const bookId = propOr('1', 'bookId', params)
  const book = useSelector(state => getBookByOriginalId(state, bookId))
  const chapters = useSelector(selectCurrentBookChaptersWithExams)
  const freeTrialExam = useSelector(selectCurrentBookFreeTrialExam)
  const dispatch = useDispatch()
  const chapterOrder: number = pipe(propOr('1', 'chapterOrder'), Number)(params)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isImpersonate = useSelector(getIsImpersonate)

  const isFreeTrialCourse = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  useEffect(() => {
    book && dispatch(fetchChaptersWithExamsRoutine({ id: book.id }))
    setSelectedChapter(null)
  }, [book])

  const handleConfirmStartModalOpen = () => setIsConfirmStartModalOpen(true)
  const handleConfirmResumeModalOpen = () => setIsConfirmResumeModalOpen(true)
  const closeAllModals = () => {
    setIsConfirmStartModalOpen(false)
    setIsConfirmResumeModalOpen(false)
  }

  useEffect(() => {
    const container = document.getElementById('select-exam-container')
    const trigger = document.getElementById('select-chapter-exam-trigger')
    const onClick = e => {
      // @ts-ignore
      const isClickInsideElement = container.contains(e.target)
      // @ts-ignore
      const isClickOnTrigger = trigger.contains(e.target)
      const isSelectOption = pathOr('', ['target', 'id'], e).includes('option')

      if (!isClickInsideElement && !isClickOnTrigger && !isSelectOption) {
        setOpen(false)
      }
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [])

  const toggleOpen = () => setOpen(prev => !prev)

  const onChapterChange = option => {
    if (option.isDisabledInFreeTrial) {
      return
    }
    closeAllModals()
    if (
      isFreeTrialCourse &&
      option.exam &&
      (option.exam.hasOwnProperty('is_free_trial') ||
        option.exam.hasOwnProperty('is_free_trial_chapter')) &&
      option.exam.is_free_trial === true
    ) {
      setSelectedChapter(option)
    }
    // resetting the select value if the student chose a chapter with no exam
    if (option.exam === null) {
      setSelectedChapter(null)
    }
    if (option.exam.is_available !== false && option.exam !== null) {
      setSelectedChapter(option)
    }
    if (option.exam !== null && option.exam.status !== EXAM_STATUS.completed) {
      setSelectedChapter(option)
    }
  }

  const redirectToExams = () => {
    push('/exams')
  }

  const redirectToScoreReport = id => {
    push(`/exam/${id}/score-report`)
  }

  useEffect(() => {
    if (
      isNotNilOrEmpty(chapterOrder) &&
      isNotNilOrEmpty(chaptersOptions) &&
      isNotNilOrEmpty(selectedChapter)
    ) {
      const exam = propOr({}, 'exam', selectedChapter)
      // @ts-ignore
      switch (exam.status) {
        case EXAM_STATUS.scheduled:
          handleConfirmStartModalOpen()
          break
        case EXAM_STATUS.completed:
          // @ts-ignore
          redirectToScoreReport(exam.id)
          break
        case EXAM_STATUS.paused:
        case EXAM_STATUS.in_progress:
          handleConfirmResumeModalOpen()
          break
        default:
          break
      }
    }
  }, [selectedChapter])

  const getStatusTag = status => {
    switch (status) {
      case EXAM_STATUS.completed:
        return (
          <StatusTag
            text={t('bookViewer.chapterExams.status.completed')}
            color='green'
          />
        )
      case EXAM_STATUS.scheduled:
        return (
          <StatusTag
            text={t('bookViewer.chapterExams.status.available')}
            color='orange'
          />
        )
      case EXAM_STATUS.paused:
      case EXAM_STATUS.in_progress:
        return (
          <StatusTag
            text={t('bookViewer.chapterExams.status.paused')}
            color='grey'
          />
        )
      case EXAM_STATUS.expired:
        return (
          <StatusTag
            text={t('bookViewer.chapterExams.status.expired')}
            color='red'
          />
        )
      default:
        return null
    }
  }

  const exams: IExams[] = isNotNilOrEmpty(freeTrialExam)
    ? [
        {
          assigned_exam: freeTrialExam,
          order: '',
          title: propOr('', 'title', freeTrialExam),
          free_trial: true
        },
        ...chapters
      ]
    : chapters

  const chaptersOptions = useMemo(() => {
    return exams?.map(chapter => {
      const examStatus = pathOr('', ['assigned_exam', 'status'], chapter)
      // @ts-ignore
      const hasExam = isNotNilOrEmpty(chapter.assigned_exam)
      const isFreeTrialExam =
        propOr(false, 'free_trial', chapter) ||
        pathOr(false, ['assigned_exam', 'is_available'], chapter)
      const isDisabledInFreeTrial = isFreeTrialCourse && !isFreeTrialExam

      const option = (
        <OptionWrapper
          disabled={!hasExam || isDisabledInFreeTrial || isImpersonate}
        >
          <OptionText>
            {/* @ts-ignore */}
            {hasExam
              ? `${chapter.order}. ${chapter.assigned_exam?.title}`
              : `${chapter.order}. ${t(
                  'bookViewer.chapterExams.noChapterExam'
                )}`}
          </OptionText>
          {getStatusTag(examStatus)}
        </OptionWrapper>
      )

      return {
        label:
          isDisabledInFreeTrial && hasExam ? (
            <TooltipContainer data='tooltip-container'>
              <Tooltip
                tooltipContent={t('toast.examNotAvailableInFreeTrial')}
                // @ts-ignore
                id={`select-chapter-exam-tooltip-${chapter.order}-${chapter.title}`}
              >
                {option}
              </Tooltip>
            </TooltipContainer>
          ) : (
            option
          ),
        // @ts-ignore
        value: chapter.order,
        exam: propOr(null, 'assigned_exam', chapter),
        isDisabledInFreeTrial
      }
    })
  }, [chapters, currentCourse])

  const clearSelectedChapter = () => setSelectedChapter(null)

  return (
    <Wrapper>
      <Trigger onClick={toggleOpen} id='select-chapter-exam-trigger'>
        <ContentQuestionsOutlineIcon style={{ fontSize: 14 }} />
        <TriggerTitle>{t('bookViewer.chapterExams.title')}</TriggerTitle>
      </Trigger>
      <Content open={open} id='select-exam-container'>
        <div className='select-container'>
          <SingleSelect
            removeMargin
            label={t('bookViewer.navigation.chapter')}
            size='small'
            // @ts-ignore
            options={chaptersOptions}
            onChange={onChapterChange}
            value={selectedChapter}
          />
        </div>
        <RedirectToMcat onClick={redirectToExams}>
          {t('bookViewer.navigation.goToExams')}
        </RedirectToMcat>
      </Content>
      <ModalConfirmStart
        disabled={false}
        closeCallback={clearSelectedChapter}
        // @ts-ignore
        id={pathOr('', ['exam', 'id'], selectedChapter)}
        // @ts-ignore
        exam={pathOr({}, ['exam'], selectedChapter)}
        isOpen={isConfirmStartModalOpen}
      />
      <ModalConfirmResume
        // @ts-ignore
        exam={pathOr({}, ['exam'], selectedChapter)}
        disabled={false}
        closeCallback={clearSelectedChapter}
        // @ts-ignore
        id={pathOr('', ['exam', 'id'], selectedChapter)}
        isOpen={isConfirmResumeModalOpen}
      />
    </Wrapper>
  )
}

export default SelectChapterExam

const Wrapper = styled.div`
  position: relative;
`

const Trigger = styled.div`
  background: ${({ theme }) => theme.colors.books.chapterExam.backgroundDark};
  padding: 4px 8px;
  border-radius: 4px 4px 0 0;
  font-size: 11px;
  height: 24px;
  position: relative;
  top: 4px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const TriggerTitle = styled.div`
  margin-left: 5px;
`

const Content = styled.div`
  background: ${({ theme }) => theme.colors.books.chapterExam.backgroundLight};
  transition: all 0.3s;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  width: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.books.chapterExam.borderDark};
  border-radius: 0 4px 4px 4px;
  box-shadow: ${props => props.theme.shadows.mainShadow};

  .select-container {
    width: 100%;

    & > div:first-child > div:nth-child(3) > div {
      overflow: auto;
    }
  }

  ${({ open }) =>
    open &&
    css`
      bottom: -80px;
      width: 350px;
      height: 80px;
      overflow: visible;
      padding: 25px 10px;
    `}
`

const RedirectToMcat = styled.div`
  position: absolute;
  right: 0;
  top: 7px;
  padding-right: 10px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: underline;
`

const OptionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const OptionText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
`

const TooltipContainer = styled.div`
  & > div,
  & > div > div:first-child {
    width: 100%;
  }
`

import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import usePrevious from 'hooks/usePrevious'

import { getLearningTimePassedSeconds } from 'modules/learningTime/ducks/selectors'
import {
  setVideoWatchingTimePassedSecondsRoutine,
  handleUpdateVideoWatchingTimeRoutine
} from 'modules/learningTime/ducks/actions'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/learningTime/utils/events'
import {
  getIsOrdinaryStudent,
  getStudent,
  isAuthLoaded
} from 'modules/auth/ducks/selectors'

import { isNotNilOrEmpty } from 'utils/ramda'

export const VideoWatchingTime = (): JSX.Element => {
  const dispatch = useDispatch()
  const [shouldCountTime, setShouldCountTime] = useState(true)
  const [videoId, setVideoId] = useState('')
  const user = useSelector(getStudent)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const increaseVideoWatchingTimeSeconds = useCallback(
    () => dispatch(setVideoWatchingTimePassedSecondsRoutine({ videoId })),
    [dispatch, videoId]
  )

  const handleUpdateVideoWatchingTime = useCallback(
    videoId => dispatch(handleUpdateVideoWatchingTimeRoutine({ videoId })),
    [dispatch]
  )

  // @ts-ignore
  const passedGeneralLearningTimeSeconds: number = useSelector(
    getLearningTimePassedSeconds
  )
  const prevGeneralLearningTimeSeconds = usePrevious(
    passedGeneralLearningTimeSeconds
  )

  useEffect(() => {
    if (
      shouldCountTime &&
      prevGeneralLearningTimeSeconds !== passedGeneralLearningTimeSeconds &&
      isNotNilOrEmpty(videoId)
    ) {
      increaseVideoWatchingTimeSeconds()
    }
  }, [passedGeneralLearningTimeSeconds, videoId, shouldCountTime])

  const handlePauseTimer = videoId => {
    setShouldCountTime(false)
    handleUpdateVideoWatchingTime(videoId)
    setVideoId('')
  }
  const handleStartTimer = videoId => {
    setShouldCountTime(true)
    setVideoId(videoId)
  }

  useEffect(() => {
    if (isOrdinaryStudent && isAuthFetched) {
      eventEmitter.on(events.videoWatchingTimePause, handlePauseTimer)
      eventEmitter.on(events.videoWatchingTimeStart, handleStartTimer)
    }

    return () => {
      if (isOrdinaryStudent && isAuthFetched) {
        eventEmitter.off(events.videoWatchingTimePause, handlePauseTimer)
        eventEmitter.off(events.videoWatchingTimeStart, handleStartTimer)
      }
    }
  }, [videoId, user, isAuthFetched])

  return <div />
}

export default VideoWatchingTime

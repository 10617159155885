import React, { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Quill from 'quill'

import { propOr } from 'ramda'

import debounce from 'lodash.debounce'

import { upsertNoteForSubchapterRoutine } from 'modules/books/ducks/actions'
import {
  fetchNotesForCourseRoutine,
  fetchNotesForParticularBookRoutine
} from 'modules/notes/ducks/actions'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

import { isNilOrEmpty } from 'utils/ramda'

import { WysiwygEditor } from 'examkrackers-components'

const NoteForm = ({ note, sectionId }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const {
    location: { search }
  } = useHistory()

  const noteText: string = propOr(null, 'delta_object')(note)
  const noteDeltaObject = JSON.parse(noteText)
  const paramBookId = propOr('', 'id', params)

  const fetchNotesForBook = useCallback(
    (bookId, query) => {
      dispatch(
        fetchNotesForParticularBookRoutine({
          // @ts-ignore
          bookId: bookId,
          query
        })
      )
    },
    [dispatch]
  )

  const fetchNotesForCourse = useCallback(
    query => {
      dispatch(
        fetchNotesForCourseRoutine({
          query
        })
      )
    },
    [dispatch]
  )

  const refreshListAfterSuccess = () => {
    if (isNilOrEmpty(paramBookId)) {
      fetchNotesForCourse(search)
    } else {
      fetchNotesForBook(paramBookId, search)
    }
  }

  const handleSuccess = () => {
    refreshListAfterSuccess()
    setTimeout(() => eventEmitter.emit(events.notesLoading, false), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedSuccess, true), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedSuccess, false), 2000)
  }
  const handleError = () => {
    setTimeout(() => eventEmitter.emit(events.notesLoading, false), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedFailure, true), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedFailure, false), 2000)
  }

  const handleSaveNote = useCallback(
    payload => dispatch(upsertNoteForSubchapterRoutine(payload)),
    [dispatch]
  )

  const handleOnChange = (value: Quill) => {
    eventEmitter.emit(events.notesLoading, true)
    const delta_object = value.getContents()
    const raw = value.getText()

    const payload = {
      values: {
        delta_object,
        raw,
        sectionId
      },
      callback: handleSuccess,
      onFailure: handleError
    }

    handleSaveNote(payload)
  }
  const debounceHandler = debounce(handleOnChange, 300)

  return (
    <WysiwygEditor
      // @ts-ignore
      id={`note-viewer-${note?.id}`}
      // @ts-ignore
      value={JSON.stringify(noteText)}
      name='delta_object'
      onChange={debounceHandler}
      validate={() => true}
      initialValue={noteDeltaObject}
      formats={{
        size: true,
        header: true,
        bold: true,
        italic: true,
        underline: true,
        orderedList: true,
        bulletList: true,
        align: true,
        scriptSuper: true,
        scriptSub: true,
        blockquote: true,
        formula: true,
        clean: true
      }}
    />
  )
}

export default memo(NoteForm, (prevProps, nextProps) => {
  const nextSectionId = propOr('', 'sectionId', nextProps)
  const prevSectionId = propOr('', 'sectionId', prevProps)

  return nextSectionId === prevSectionId
})

import React from 'react'
import { propOr, pathOr, pipe, values, map } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { ExamStepHtml } from 'types'

export const CONTENT_TYPES = {
  question: 'question',
  answers: 'answers',
  passage: 'passage'
}

export const generateSaveStepHtmlPayload = (): ExamStepHtml => {
  const stepQuestion: HTMLElement | null =
    document.getElementById('step-question')
  const stepPassage: HTMLElement | null =
    document.getElementById('step-passage')
  const stepAnswerA: HTMLElement | null =
    document.getElementById('answer-content-A')
  const stepAnswerB: HTMLElement | null =
    document.getElementById('answer-content-B')
  const stepAnswerC: HTMLElement | null =
    document.getElementById('answer-content-C')
  const stepAnswerD: HTMLElement | null =
    document.getElementById('answer-content-D')

  const htmlAnswerA = propOr('', 'innerHTML', stepAnswerA)
  const htmlAnswerB = propOr('', 'innerHTML', stepAnswerB)
  const htmlAnswerC = propOr('', 'innerHTML', stepAnswerC)
  const htmlAnswerD = propOr('', 'innerHTML', stepAnswerD)

  const wrapInStrikeIfNeeded = (stepAnswer, htmlAnswer) =>
    stepAnswer?.parentElement?.localName === 'strike'
      ? `<strike>${htmlAnswer}</strike>`
      : htmlAnswer

  const formatAnswer = (stepAnswer, htmlAnswer) =>
    wrapInStrikeIfNeeded(stepAnswer, htmlAnswer)

  return {
    [CONTENT_TYPES.question]: propOr('', 'innerHTML', stepQuestion),
    [CONTENT_TYPES.passage]: propOr('', 'innerHTML', stepPassage),
    [CONTENT_TYPES.answers]: {
      A: formatAnswer(stepAnswerA, htmlAnswerA),
      B: formatAnswer(stepAnswerB, htmlAnswerB),
      C: formatAnswer(stepAnswerC, htmlAnswerC),
      D: formatAnswer(stepAnswerD, htmlAnswerD)
    }
  }
}

export const getSavedHtmlByPathOrDefault = (path, def, savedHtml) => {
  const savedHTMLByPath = pathOr('', path)(savedHtml)

  return isNotNilOrEmpty(savedHTMLByPath) ? (
    <div dangerouslySetInnerHTML={{ __html: savedHTMLByPath }} />
  ) : (
    def
  )
}

export const generateSaveQuestionHtmlPayloads = (stepHtml: ExamStepHtml) => {
  const stepHtmlState: ExamStepHtml = generateSaveStepHtmlPayload()
  const findResourceIdByType = type => {
    const idPropName = type === CONTENT_TYPES.passage ? 'passageId' : 'id'

    return propOr('', idPropName, stepHtml)
  }

  return pipe(
    types => values(types),
    map(type => ({
      resource_id: findResourceIdByType(type),
      content_type: type,
      content:
        type === CONTENT_TYPES.answers
          ? JSON.stringify(propOr('', type, stepHtmlState))
          : propOr('', type, stepHtmlState)
    }))
  )(CONTENT_TYPES)
}

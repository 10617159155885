import React from 'react'
import FlashcardsDrawer from 'modules/flashcards/components/FlashcardsDrawer'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getFlashcardsProficiencyLevelStats } from 'modules/flashcards/ducks/selectors'
import styled from 'styled-components'
import { Tooltip } from 'examkrackers-components'

const FlashcardsProficiencyDrawersList = () => {
  const { t } = useTranslation()
  const proficiencyStats = useSelector(getFlashcardsProficiencyLevelStats)

  const renderDrawers = proficiencyStats.map((stat, index) => (
    <FlashcardsDrawer
      key={`drawer-${index}`}
      id={`drawer-${index}`}
      drawer={{
        label: `Level ${stat.proficiency_level}`,
        count: stat.count,
        level: stat.proficiency_level
      }}
    />
  ))

  return (
    <>
      <ProficiencyBoxesHeader>
        <span>{t('flashcards.study.proficiencyTitle')}</span>
        <Tooltip
          info
          tooltipContent={
            <Trans
              className='tooltip-container'
              i18nKey='flashcards.study.proficiencyTitleTooltip'
            >
              <strong>0</strong> 1 <LineBreak /> 3 <LineBreak /> 5
            </Trans>
          }
          id='header-plvl-tooltip'
        />
      </ProficiencyBoxesHeader>
      {renderDrawers}
    </>
  )
}

export default FlashcardsProficiencyDrawersList

const LineBreak = styled.div`
  margin-bottom: 12px;
`

const ProficiencyBoxesHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  #header-plvl-tooltip {
    max-width: 300px;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    text-align: left;
    padding: 12px;

    strong {
      font-weight: bold !important;
    }
  }

  svg {
    font-size: 12px;
  }
`

import { createRoutine } from 'redux-saga-routines'
import * as Effects from 'utils/saga'
import * as GlossaryService from 'services/GlossaryService'
import { ActionPayload } from 'types'
import * as R from 'ramda'

export const fetchGlossaryWordsListRoutine = createRoutine(
  'FETCH_GLOSSARY_LIST'
)
export const resetGlossaryWordsListRoutine = createRoutine(
  'RESET_GLOSSARY_LIST'
)

function* fetchGlossaryWordsList(action: ActionPayload) {
  const { payload } = action
  yield Effects.put(fetchGlossaryWordsListRoutine.request())

  try {
    const result = yield Effects.call(
      GlossaryService.fetchGlossaryWordsList,
      payload
    )
    yield Effects.put(
      fetchGlossaryWordsListRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchGlossaryWordsListRoutine.failure(e))
    console.error(e)
  }
}

function* resetGlossaryWordsList() {
  yield Effects.put(resetGlossaryWordsListRoutine.success())
}

function* fetchGlossaryWordsListWatcher() {
  yield Effects.takeLatest(
    fetchGlossaryWordsListRoutine.TRIGGER,
    fetchGlossaryWordsList
  )
}

function* resetGlossaryWordsListWatcher() {
  yield Effects.takeLatest(
    resetGlossaryWordsListRoutine.TRIGGER,
    resetGlossaryWordsList
  )
}

export const glossarySagas = [
  Effects.fork(fetchGlossaryWordsListWatcher),
  Effects.fork(resetGlossaryWordsListWatcher)
]

import { propOr } from 'ramda'
import { createSelector, OutputSelector } from 'reselect'
import { RootReducer } from 'types'

// Selector
export const selectStudent = (state: RootReducer) => state.student

//
// Student Navigation
//

// Get the current student navigation state
export const getNavigation: OutputSelector<any, any, (res: any) => any> =
  createSelector(selectStudent, propOr([], 'navigation'))

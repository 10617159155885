export const COURSE_LEARNING_TIME_STATUS = {
  started: 'started',
  paused: 'paused'
}

export const COURSE_TYPES = {
  live: 'live_course',
  onDemand: 'on_demand',
  freeTrial: 'free_trial'
}

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ThemeProvider, GlobalStyles, Toast } from 'examkrackers-components'

import { hideToastRoutine } from 'modules/toast/ducks/actions'
import {
  getToastKey,
  getToastOptions,
  getToastSeverity,
  isToastVisible
} from 'modules/toast/ducks/selectors'

export const ToastNotification = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(isToastVisible)
  const translationKey = useSelector(getToastKey)
  const options: any = useSelector(getToastOptions)
  const severity = useSelector(getToastSeverity)
  const handleClose = useCallback(
    () => dispatch(hideToastRoutine()),
    [dispatch]
  )

  const showToastContent = t(translationKey, options)

  return (
    <ThemeProvider>
      <div>
        <GlobalStyles />
        <Toast severity={severity} handleClose={handleClose} open={visible}>
          {showToastContent}
        </Toast>
      </div>
    </ThemeProvider>
  )
}

export default ToastNotification

import React, { useEffect, memo, useState } from 'react'
import { pathOr, propOr } from 'ramda'
import { Animation, playSound } from 'examkrackers-components'
import styled from 'styled-components'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

const ContentQuestionAnimation = ({ animations }) => {
  const [animationJSON, setAnimationJSON] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const showAnimation = answerObject => {
    const correct = propOr(false, 'isCorrectAnswer')(answerObject)
    const shouldShowAnimation = propOr(
      false,
      'shouldShowAnimation'
    )(answerObject)
    const correctSound = pathOr('', ['correct', 'sound'])(animations)
    const incorrectSound = pathOr('', ['incorrect', 'sound'])(animations)
    const correctAnimation = pathOr(null, ['correct', 'animation'])(animations)
    const incorrectAnimation = pathOr(null, ['incorrect', 'animation'])(
      animations
    )

    const sound = correct ? correctSound : incorrectSound
    const animationJSON = correct ? correctAnimation : incorrectAnimation

    setAnimationJSON(animationJSON)
    const startAnimation = () => setIsPlaying(true)

    // @ts-ignore
    if (shouldShowAnimation) {
      playSound(sound, null, startAnimation)
    } else {
      eventEmitter.emit(events.contentQuestionLoading, false)
    }
  }

  const onAnimationEnd = () => {
    setAnimationJSON(null)
    setIsPlaying(false)
    eventEmitter.emit(events.contentQuestionLoading, false)
  }

  useEffect(() => {
    eventEmitter.on(events.showCQAnimation, showAnimation)

    return () => {
      eventEmitter.off(events.showCQAnimation, showAnimation)
    }
  }, [animations])

  return (
    <AnimationWrapper isVisible={isPlaying}>
      <Animation
        onAnimationEnd={onAnimationEnd}
        loop={false}
        autoplay={isPlaying}
        hideControls
        data={animationJSON}
      />
    </AnimationWrapper>
  )
}

export default memo(ContentQuestionAnimation)

const AnimationWrapper = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  top: -40px;
  z-index: ${({ theme }) => theme.zIndex.navigation + 1};
  left: 0;
  div[aria-label='animation'] {
    z-index: 9999;
    position: relative;
  }
`

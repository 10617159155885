import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fetchEarningsForLeaderboard } from 'services/LeaderboardService'
import { propOr, pathOr } from 'ramda'
import { Earning } from 'types/saltyBucksEarnings'
import { formatNumberWithDecimal } from 'utils/numbers'

import { SaltyBucksIcon } from 'examkrackers-components'
import ModalLastEarnings from './ModalLastEarnings'

const LastEarnings = () => {
  const [data, setData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const handleClose = () => {
    setModalOpen(false)
  }
  const handleOpen = () => {
    setModalOpen(true)
  }

  const { t } = useTranslation()
  useEffect(() => {
    fetchEarningsForLeaderboard({
      query: 'order[by]=created_at&order[dir]=desc&limit[take]=3'
    })
      // @ts-ignore
      .then(result => setData(pathOr([], ['data', 'data'], result)))
      .catch(console.error)
  }, [])

  const earningsList = data.map((earning: Earning) => {
    const operationType = propOr('unknown', 'operation_type', earning)
    const isOperationTypeOutcome = operationType === 'outcome'
    const log = t(
      `navigation.saltyBucksDropDown.logs.${pathOr(
        'unknown',
        ['reference_type'],
        earning
      )}.${pathOr('unknown', ['operation_subtype'], earning)}`
    )
    return (
      <RowWrapper
        key={earning.id}
        isOperationTypeOutcome={isOperationTypeOutcome}
      >
        <CauseWrapper>{log}</CauseWrapper>
        <AmountWrapper>
          <IconWrapper>
            <SaltyBucksIcon />
          </IconWrapper>
          {isOperationTypeOutcome ? '-' : null}{' '}
          {formatNumberWithDecimal(earning.amount)}
        </AmountWrapper>
      </RowWrapper>
    )
  })
  return (
    <HistoryWrapper>
      <Title>{t('leaderboard.rightColumn.earnings.title')}</Title>
      <SeeMoreButtonWrapper onClick={handleOpen}>See more</SeeMoreButtonWrapper>
      <div>{earningsList}</div>
      <ModalLastEarnings open={modalOpen} handleClose={handleClose} />
    </HistoryWrapper>
  )
}

export default LastEarnings

const HistoryWrapper = styled.div`
  display: flex;
  height: 182px;
  flex-direction: column;
  align-items: left;
  padding: 16px;
  flex: 1;
  z-index: ${({ theme, isSaltyHistoryOpened }) =>
    isSaltyHistoryOpened ? theme.zIndex.mainMenu + 20 : theme.zIndex.menu - 15};
`
const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.main.heading};
  margin: 0 0 8px 12px;
`
const SeeMoreButtonWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 16px;
  color: ${({ theme }) => theme.colors.main.primary500};
`
const RowWrapper = styled.div`
  color: ${({ theme, isOperationTypeOutcome }) =>
    isOperationTypeOutcome
      ? theme.colors.main.error500
      : theme.colors.main.heading};
  margin-left: 1px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
  font-size: 14px;
  padding: 12px 0;
  justify-content: space-between;
  &:last-of-type {
    border: none;
    padding-bottom: 0px;
  }
`
const CauseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`

const AmountWrapper = styled.div`
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.main.currency500};
  font-weight: 700;
`

const IconWrapper = styled.div`
  padding-top: 1px;
  padding-right: 5px;
`

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { catchProficiencyLevelAnimationTrigger } from 'utils/flashcards'
import { propOr } from 'ramda'
import { formatDate, DATE_FORMATS } from 'utils/date'

import {
  CheckmarkContainedIcon,
  CloseContainedIcon
} from 'examkrackers-components'

const defaultAnimationDimensions = {
  running: false,
  timestamp: '',
  toOffset: 0,
  top: 0,
  left: 0
}

const FlashcardsProficiencyDrawersList = () => {
  const [animationDimensions, setAnimationDimensions] = useState(
    defaultAnimationDimensions
  )
  const isRunning = propOr(false, 'running', animationDimensions)

  const startAnimation = dimensions => {
    setAnimationDimensions({
      ...dimensions,
      running: true,
      timestamp: formatDate(new Date(), DATE_FORMATS.dashedDateAndTime)
    })
  }

  const stopAnimation = () => setAnimationDimensions(defaultAnimationDimensions)

  useEffect(() => {
    catchProficiencyLevelAnimationTrigger(startAnimation)
  }, [])

  const isCorrect = animationDimensions.toOffset > 0

  return (
    <>
      {isRunning && (
        <AnimatedPage
          id='animated-page'
          animationDimensions={animationDimensions}
          onAnimationEnd={stopAnimation}
        >
          {isCorrect ? <StyledCorrect /> : <StyledIncorrect />}
        </AnimatedPage>
      )}
    </>
  )
}

export default FlashcardsProficiencyDrawersList

const AnimatedPage = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.font};
  position: absolute;
  z-index: 3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  top: ${({ animationDimensions }) => animationDimensions.top}px;
  left: ${({ animationDimensions }) => animationDimensions.left}px;
  animation-name: move-page-to-drawer-${({ animationDimensions }) => animationDimensions.timestamp};
  animation-duration: 2s;
  animation-delay: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;

  @keyframes move-page-to-drawer-${({ animationDimensions }) =>
    animationDimensions.timestamp} {
    0% {
      opacity: 1;
    }

    20% {
      transform: translateY(-15px);
      z-index: 3;
    }

    40% {
      transform: translate(50px, -15px);
      z-index: 10;
    }
    60% {
      transform: translate(
        50px,
        ${({ animationDimensions }) => animationDimensions.toOffset}px
      );
      z-index: 10;
    }
    80% {
      transform: translate(
        0,
        ${({ animationDimensions }) => animationDimensions.toOffset}px
      );
      z-index: 3;
    }

    100% {
      transform: translate(
        0,
        ${({ animationDimensions }) => animationDimensions.toOffset + 15}px
      );
      z-index: 3;
      opacity: 1;
    }
  }
`

const StyledCorrect = styled(CheckmarkContainedIcon)`
  color: ${({ theme }) => theme.colors.main.success500};
  font-size: 14px;
`

const StyledIncorrect = styled(CloseContainedIcon)`
  color: ${({ theme }) => theme.colors.main.error500};
  font-size: 14px;
`

import React from 'react'
import { propOr } from 'ramda'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getTimeVariant } from 'utils/date'
import { useTranslation } from 'react-i18next'
import NotificationContent from './NotificationContent'
// import useOutsideClick from 'hooks/useOutsideClick'
import {
  markNotificationAsRead,
  markNotificationAsUnRead
} from 'services/NotificationService'
import { fetchNotificationsRoutine } from '../ducks/actions'
import { getStudent } from 'modules/auth/ducks/selectors'
import { Tooltip } from 'examkrackers-components'
interface Notification {
  author_id: string
  created_at: string
  description_delta_object: string
  description_html: string
  description_raw: string
  id: string
  is_read: boolean
  is_flagged: boolean
  notification_id: string
  student_course_id: string
  student_id: string
  title: string
}

interface NotificationViewProps {
  notification: Notification
  isExpanded: boolean
}

const NotificationView = ({
  notification,
  isExpanded
}: NotificationViewProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const student = useSelector(getStudent)
  const studentId = propOr('', 'id', student)
  const notificationTitle = propOr('', 'title', notification)
  const notificationDescriptionRaw = notification?.description_raw || ''
  const is_read = propOr(false, 'is_read', notification)
  const notificationCreatedAt = propOr('', 'created_at', notification)
  const notificationId: string = propOr('', 'id', notification)

  const fetchNotifications = React.useCallback(
    payload => dispatch(fetchNotificationsRoutine(payload)),
    [dispatch]
  )

  const handleMarkParticulrNotificationAsRead = () => {
    const handleSuccess = () => studentId && fetchNotifications({ studentId })
    const handleError = e => console.error(e)
    const notificationId: string = propOr('', 'id', notification)
    markNotificationAsRead(notificationId)
      .then(handleSuccess)
      .catch(handleError)
  }
  const handleMarkParticulrNotificationAsUnread = () => {
    const handleSuccess = () => studentId && fetchNotifications({ studentId })
    const handleError = e => console.error(e)
    const notificationId: string = propOr('', 'id', notification)
    markNotificationAsUnRead(notificationId)
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <MainContainer>
      <Container isExpanded={isExpanded}>
        <TooltipContainer data='tooltip-container'>
          <Tooltip
            tooltipContent={
              is_read
                ? t('notifications.markAsUnread')
                : t('notifications.hideAsRead')
            }
            id={notificationId}
          >
            <DotWrapper>
              <Dot
                isRead={is_read}
                onClick={
                  is_read
                    ? handleMarkParticulrNotificationAsUnread
                    : handleMarkParticulrNotificationAsRead
                }
              />
            </DotWrapper>
          </Tooltip>
        </TooltipContainer>
        <CenterWrapper>
          <TitleWrapper>{notificationTitle}</TitleWrapper>
          <TimeWrapper>{notificationDescriptionRaw}</TimeWrapper>
          <TimeWrapper>{getTimeVariant(notificationCreatedAt)}</TimeWrapper>
        </CenterWrapper>
      </Container>
      <ContentWrapper isExpanded={isExpanded}>
        <NotificationContent notificationId={notificationId} />
      </ContentWrapper>
    </MainContainer>
  )
}

const MainContainer = styled.div``
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 16px;
  height: auto;
  background-color: ${({ isExpanded, theme }) =>
    isExpanded ? theme.colors.notifications.active : 'transparent'};
  position: relative;
  cursor: pointer;
`
const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.notifications.active};
  height: auto;
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
`

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.main.text};
  margin-left: 16px;
`

const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
`

const Dot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${({ theme, isRead }) =>
    isRead ? theme.colors.main.grey600 : theme.colors.main.primary500};
  padding: 5px;
`

const TimeWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.main.grey600};
  margin-left: 16px;
  width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const TooltipContainer = styled.div`
  & > div,
  & > div > div:first-child {
    width: 100%;
    cursor: pointer;
  }
`

export default NotificationView

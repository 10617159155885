import { mapIndexed } from 'utils/ramda'
import * as R from 'ramda'
import { ScoreProjectionDataEntity, ScoreProjectionDetail } from 'types'

interface OrderedScoreProjectionDetail extends ScoreProjectionDetail {
  order: number
}

const saveOrder = (
  scoreProjections: ScoreProjectionDetail[]
): OrderedScoreProjectionDetail[] =>
  // @ts-ignore
  mapIndexed((point: ScoreProjectionDetail, index: number) => ({
    ...point,
    order: index
  }))(scoreProjections)

const filterExculdedExceptSelf = comparedIndex =>
  R.filter(
    (point: OrderedScoreProjectionDetail) =>
      point.order === comparedIndex || !point.is_excluded_from_pts
  )

const findByIndex = index => R.view(R.lensIndex(index))

const findLinePointY = (comparedIndex, operation) => list => {
  const newIndex = R.findIndex(R.propEq('order', comparedIndex))(list)
  const index = operation(newIndex, 1)

  return R.pipe(findByIndex(index), R.propOr(0, 'scaled_score'))(list)
}

const findLinePointX = (comparedIndex, operation) => list => {
  const newIndex = R.findIndex(R.propEq('order', comparedIndex))(list)
  const index = operation(newIndex, 1)

  return R.pipe(findByIndex(index), R.propOr(0, 'order'))(list)
}

const findMarginalPoints = comparedIndex =>
  R.pipe(
    saveOrder,
    filterExculdedExceptSelf(comparedIndex),
    R.applySpec({
      y0: findLinePointY(comparedIndex, R.subtract),
      x0: findLinePointX(comparedIndex, R.subtract),
      y1: findLinePointY(comparedIndex, R.add),
      x1: findLinePointX(comparedIndex, R.add)
    })
  )

const getInterpolatedValue = (x0, x1, y0, y1) => x =>
  y0 + (x - x0) * ((y1 - y0) / (x1 - x0))

const calculateYPoint = currentIndex =>
  R.pipe(findMarginalPoints(currentIndex), p =>
    getInterpolatedValue(p.x0, p.x1, p.y0, p.y1)(currentIndex)
  )

// This is for organising data which was changed in the api
const organiseData = data =>
  R.map(
    // @ts-ignore
    d => ({
      // @ts-ignore
      ...d,
      scaled_score: R.has('pts', d)
        ? R.propOr(0, 'pts', d)
        : R.propOr(0, 'scaled_score', d)
    })
  )(data)

export const getIncludedScoresData = (data: ScoreProjectionDetail[]) =>
  R.pipe(
    organiseData,
    mapIndexed(
      // @ts-ignore
      (point: ScoreProjectionDetail, index: number) => ({
        y: point.is_excluded_from_pts
          ? calculateYPoint(index)(data)
          : point.scaled_score,
        x: point.title
      })
    )
  )(data)

export const organiseTableScoreProjectionData = data =>
  R.pipe(
    (scoresData: ScoreProjectionDataEntity[]) =>
      R.map(R.prop('scores'))(scoresData),
    (scoresDataArrays: ScoreProjectionDetail[][]) =>
      R.flatten(scoresDataArrays),
    (scores: ScoreProjectionDetail[]) =>
      // @ts-ignore this line filters out 'undefined' elements
      R.filter(R.identity, scores),
    (scores: ScoreProjectionDetail[]) =>
      R.groupBy((score: ScoreProjectionDetail) => R.prop('title')(score))(
        scores
      ),
    R.dissoc('Target'),
    R.values
  )(data)

export const findScoresByPropEq =
  (propName: string, equalValue: any) => (data: ScoreProjectionDataEntity[]) =>
    R.pipe(
      // @ts-ignore
      (data: ScoreProjectionDataEntity[]) =>
        R.find(R.propEq(propName, equalValue))(data),
      (entity: ScoreProjectionDataEntity) => R.propOr([], 'scores')(entity)
      // @ts-ignore
    )(data)

export const getTargetScoreData = data => {
  const lastCompleted = R.pipe(
    // @ts-ignore
    R.filter(R.propEq('completed', true)),
    // @ts-ignore
    R.sortBy(R.prop('completed_as')),
    // @ts-ignore
    R.takeLast(1),
    R.head
    // @ts-ignore
  )(data)

  // This is for organising data which was changed in the api
  const organisedData = mapIndexed((d, index) => ({
    // @ts-ignore
    ...d,
    // @ts-ignore
    is_excluded_from_pts: R.not(
      R.has('target_score', d) || R.equals(d, lastCompleted)
    ),
    scaled_score: R.has('target_score', d)
      ? R.propOr(0, 'target_score', d)
      : R.propOr(0, 'scaled_score', d)
  }))(data)

  // @ts-ignore
  const calculatePointIfNeeded = (point, index) =>
    point.is_excluded_from_pts
      ? // @ts-ignore
        calculateYPoint(index)(organisedData)
      : point.scaled_score

  const getIncludedScoresData = mapIndexed(
    // @ts-ignore
    (point: ScoreProjectionDetail, index: number) => ({
      // @ts-ignore
      y:
        R.equals(point, lastCompleted) || R.propEq('completed', false, point)
          ? calculatePointIfNeeded(point, index)
          : null,
      x: point.title
    })
  )

  // @ts-ignore
  return getIncludedScoresData(organisedData)
}

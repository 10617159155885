import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ExamContent, ExamAnswers } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'
import { propOr } from 'ramda'
import { isNilOrEmpty } from '../../../../utils/ramda'

export const TutorialAnswer = (props): JSX.Element => {
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialAnswer)
  )
  const currentAnswer: string = propOr('', 'answer', questionState)

  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const [selectedAnswer, setSelectedAnswer] = useState(currentAnswer)
  const dispatch = useDispatch()
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialScroll)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialNavigation)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? QUESTION_STATUSES.incomplete
          : questionState.status,
      id: PageIds.tutorialAnswer
    })
  }, [])

  const tutorialAnswers = [
    {
      answerCode: 'A',
      answerContent: 'Correct answer'
    },
    {
      answerCode: 'B',
      answerContent: 'Incorrect answer'
    }
  ]

  const handleSelectAnswer = answer => {
    const newAnswer = answer === selectedAnswer ? '' : answer
    const newStatus = isNilOrEmpty(newAnswer)
      ? QUESTION_STATUSES.incomplete
      : QUESTION_STATUSES.complete

    updateQuestionStep({
      ...questionState,
      status: newStatus,
      id: PageIds.tutorialAnswer,
      answer: newAnswer
    })
    setSelectedAnswer(newAnswer)
  }

  return (
    <ExamContent>
      <TutorialAnswerContainer>
        <div>
          <div className='heavy-bordered'>
            <h2>Select an Answer</h2>
            <h3>Description</h3>
            <p>
              You will use your mouse to select and submit one answer per page.
            </p>
            <h3>Instructions</h3>
            <ul className='numeric'>
              <li>
                Place your hand on the mouse and move it. Observe how the
                pointer on the screen moves as your hand moves.
              </li>
              <li>
                To select an answer option, move the pointer to the text,
                graphic, or circle next to the answer option and select it by
                clicking the left button on your mouse. The open circle next to
                the answer option you selected is now filled in.
              </li>
              <li>
                To deselect an answer option, select the text, graphic, or
                circle next to your answer option again. The circle is no longer
                filled in.
              </li>
            </ul>
            <h3>Practice</h3>
            <p className='tutorial__question'>Select the correct answer.</p>
            <div>
              <ExamAnswers
                answers={tutorialAnswers}
                onSelectAnswer={handleSelectAnswer}
                selectedAnswerCode={selectedAnswer}
              />
            </div>
          </div>
          <p>
            Select&nbsp;
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page.
          </p>
        </div>
      </TutorialAnswerContainer>
    </ExamContent>
  )
}

export default TutorialAnswer

const TutorialAnswerContainer = styled.div`
  p {
    margin: 16px 0;
  }

  ul {
    margin-bottom: 25.888px;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }
`

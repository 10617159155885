import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getCalendar, setExamDate } from 'services/CalendarService'
import { BouncingLoader, Button, DatePicker } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'

const initialValues = {
  calendar_start_at: new Date(),
  exam_at: new Date()
}

interface CalendarResetSettingsValues {
  calendar_start_at: string | Date
  exam_at: string | Date
}

const CalendarSettings = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] =
    useState<CalendarResetSettingsValues>(initialValues)
  const { push } = useHistory()

  const { t } = useTranslation()

  const handleCalendarFetch = () => {
    getCalendar()
      .then(resp => {
        const calendarDates = resp.data.calendar

        const startDate = new Date(calendarDates.start_at)
        const finishCourseDate = calendarDates.exam_at
          ? new Date(calendarDates.exam_at)
          : new Date()

        setValues({
          calendar_start_at: startDate,
          exam_at: finishCourseDate
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSaveDate = async () => {
    setIsLoading(true)
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
    try {
      await setExamDate({
        calendar_start_at: values.calendar_start_at,
        exam_at: values.exam_at,
        course_id: courseId
      })
      setIsLoading(false)
      push(PATHS.calendarDaysOff)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    handleCalendarFetch()
  }, [])

  if (isLoading) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <Container>
      <ContentWrapper>
        <Title>{t('calendar.resetModal.dateTitle')}</Title>
        <PickerWrapper>
          <DatePicker
            name='calendar_start_at'
            id='calendar_start_at'
            label={t('calendar.resetModal.startDateOfCourse')}
            value={values.calendar_start_at}
            validate={() => true}
            t={t}
            onChange={handleValueChange}
            minDate={new Date()}
          />
          {/* <div>{values.exam_at && values.exam_at}</div> */}
          <DatePicker
            name='exam_at'
            id='exam_at'
            label={t('calendar.resetModal.endDateOfCourse')}
            value={values.exam_at}
            validate={() => true}
            t={t}
            onChange={handleValueChange}
            minDate={new Date()}
          />
        </PickerWrapper>
        <ButtonWrapper>
          <Button variant='outlined' onClick={handleSaveDate}>
            Save
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </Container>
  )
}

export default CalendarSettings

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 400px;
  min-width: 400px;
  flex-direction: column;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const PickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  width: 100%;
`
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;

  display: flex;
  justify-content: center;
`

import React from 'react'
import styled from 'styled-components'

import { Button, NavArrowRightIcon } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { Course } from 'types'
import { useSelector } from 'react-redux'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'

const SubscribeButton = () => {
  const { t } = useTranslation()

  const currentCourse: Course = useSelector(getCurrentCourse)
  const isProduction = process.env.RELEASE_TYPE === 'production'
  const haveTransactionId = isNotNilOrEmpty(currentCourse.transaction_id)
  const transactionId = propOr('', 'transaction_id', currentCourse)

  const openPurchasePortal = () => {
    // window.open(EK_PURCHASE_PORTAL_PAGE, '_blank')
    if (isProduction && haveTransactionId) {
      window.open(
        `https://examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!isProduction && haveTransactionId) {
      window.open(
        `https://test.examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!haveTransactionId && !isProduction) {
      window.open(
        'https://test.examkrackers.com/my-account?panel=orders',
        '_blank'
      )
    } else if (!haveTransactionId && isProduction) {
      window.open('https://examkrackers.com/my-account?panel=orders', '_blank')
    }
  }

  return (
    <ButtonContainer>
      <Button
        id='subscribe-button'
        endIcon={<NavArrowRightIcon />}
        onClick={openPurchasePortal}
        size='small'
        color='tertiary'
      >
        {t('bookViewer.subscribe')}
      </Button>
    </ButtonContainer>
  )
}

export default SubscribeButton

const ButtonContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};
`

import { API_STATES } from 'utils/redux'
import {
  fetchExamTypesRoutine,
  fetchExamSubtypesRoutine,
  fetchAvailableExamTypesRoutine,
  setCurrentExamTypeIdRoutine
} from './actions'
import { Action, ExamTypesReducer } from 'types'

// Initial student state
export const initialState: ExamTypesReducer = {
  state: API_STATES.PRISTINE,
  currentTypeId: '',
  examTypes: [],
  availableTypes: [],
  examSubtypes: []
}

// Student reducer
export default (state = initialState, action: Action): ExamTypesReducer => {
  switch (action.type) {
    case fetchExamTypesRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchExamTypesRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        examTypes: action.payload
      }
    case fetchExamTypesRoutine.FAILURE:
    case fetchExamSubtypesRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchExamSubtypesRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        examSubtypes: action.payload
      }
    case fetchAvailableExamTypesRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        availableTypes: action.payload
      }
    case setCurrentExamTypeIdRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        currentTypeId: action.payload
      }
    default:
      return state
  }
}

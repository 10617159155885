import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import FlashcardAnswer from 'modules/flashcards/components/FlashcardAnswer'
import { isNotNilOrEmpty } from 'utils/ramda'
import { Course, Flashcard } from 'types'
import { useSelector } from 'react-redux'
import { selectFreeTrialAvailableBookTags } from 'modules/books/ducks/selectors'
import { isElementAvailable } from 'utils/freeTrial'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import * as R from 'ramda'
import { COURSE_TYPES } from 'utils/courses'
import { useTranslation } from 'react-i18next'

interface FlashcardCardPreviewProps {
  flashcard: Flashcard
  side?: string
  scale?: number
}

export const ScaledFlashcardCardPreview = (
  props: FlashcardCardPreviewProps
): JSX.Element => {
  const { t } = useTranslation()

  const scaledCardRef = useRef(null)
  const [scaledCardDimensions, setScaledCardDimensions] = useState({
    width: 145,
    height: 116
  })
  const { scale = 0.25, flashcard } = props
  const [loading, setIsLoading] = useState(true)
  const availableBookTags = useSelector(selectFreeTrialAvailableBookTags)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const saveDimensions = () => {
    const cardElement = scaledCardRef.current
    if (isNotNilOrEmpty(cardElement)) {
      // @ts-ignore
      setScaledCardDimensions(cardElement.getBoundingClientRect())
    }
  }

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 100)
  }, [])

  useEffect(() => {
    if (!loading) {
      saveDimensions()
    }
  }, [loading])

  const originalWidth = scaledCardDimensions.width / scale
  const originalHeight = scaledCardDimensions.height / scale
  const top = -(originalHeight - scaledCardDimensions.height) / 2
  const left = -(originalWidth - scaledCardDimensions.width) / 2

  const isAvailable = isElementAvailable(
    isFreeTrial,
    flashcard,
    availableBookTags
  )

  const UnavailableFlashcard = (
    <FlashcardPlaceholder scale={scale}>
      <SaltyImage src='/assets/illustrations/SaltyDexter.svg' />
      <span>{t('flashcards.freeTrialScaled')}</span>
    </FlashcardPlaceholder>
  )

  const AvailableFlashcard = (
    <ScaledContainer
      loading={loading}
      ref={scaledCardRef}
      scale={scale}
      top={top}
      left={left}
    >
      <FlashcardAnswer
        flashcard={flashcard}
        disableActions
        disableNext
        disablePrevious
        disableFlip
        handleNext={() => {}}
        handlePrevious={() => {}}
        onSuccessAnswer={() => {}}
        size='small'
        side={props.side}
      />
    </ScaledContainer>
  )

  const Flashcard = isAvailable ? AvailableFlashcard : UnavailableFlashcard

  return (
    <Container
      height={scaledCardDimensions.height}
      width={scaledCardDimensions.width}
    >
      {Flashcard}
    </Container>
  )
}

export default ScaledFlashcardCardPreview

const Container = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  position: relative;

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${({ theme }) => theme.shadows.darkShadow};
    background: ${({ theme }) => theme.colors.backgrounds.main};
    border-radius: ${({ theme }) => theme.shape.borderRadiusSmall};
    opacity: ${({ loading }) => (loading ? 1 : 0)};
    transition: opacity 500ms
      ${({ theme }) => theme.transitions.easing.easeInOut};
  }
`

const ScaledContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  transform: scale(${({ scale, loading }) => (loading ? 1 : scale)});
  opacity: ${({ loading }) => (loading ? 0 : 1)};
  transition: opacity 500ms ${({ theme }) => theme.transitions.easing.easeInOut};
`

// original size
// width: 500px;
// height: 400px;
const FlashcardPlaceholder = styled.div`
  height: ${({ scale }) => scale * 400}px;
  width: ${({ scale }) => scale * 500}px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.main.primary500};
`

const SaltyImage = styled.img`
  height: 59px;
`

import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import { Checkbox } from 'examkrackers-components'
import qs from 'qs'
import { useDispatch } from 'react-redux'

import { BOOK_VIEWER_PARAMS, HIGHLIGHT_MODES } from 'utils/books'
import { useHistory } from 'react-router-dom'
import {
  confirmCourseTopicRoutine,
  uncheckCourseTopicRoutine,
  unmarkTopicCheckboxRoutine,
  markTopicCheckboxRoutine
} from '../ducks/actions'
import { propOr, propEq } from 'ramda'

export const CourseTopicsRow = ({
  topic: entry,
  isSearched,
  isBookView,
  isEven
}): JSX.Element => {
  const { is_mastered, topic, id, level } = entry
  const { push } = useHistory()
  const dispatch = useDispatch()

  const tags: any[] = propOr([], 'bookContentCourseTopics', entry)

  const confirmTopic = useCallback(
    () => dispatch(confirmCourseTopicRoutine(entry)),
    [dispatch, entry]
  )

  const uncheckTopic = useCallback(
    () => dispatch(uncheckCourseTopicRoutine(entry)),
    [dispatch, entry]
  )

  const markTopicCheckbox = useCallback(
    bookContentCourseTopic =>
      dispatch(markTopicCheckboxRoutine(bookContentCourseTopic)),
    [dispatch, entry]
  )

  const unmarkTopicCheckbox = useCallback(
    bookContentCourseTopic =>
      dispatch(unmarkTopicCheckboxRoutine(bookContentCourseTopic)),
    [dispatch, entry]
  )

  const handleCheckboxOnChange = () =>
    is_mastered ? uncheckTopic() : confirmTopic()

  const handleTagClick = (bookId, chapterOrder, partOrder, contentId) => () => {
    const redirectQuery = {
      [BOOK_VIEWER_PARAMS.bookContentIdContext]: contentId,
      [BOOK_VIEWER_PARAMS.highlightMode]: HIGHLIGHT_MODES.none
    }

    const query = qs.stringify(redirectQuery)
    push(`/books/${bookId}/chapter/${chapterOrder}/part/${partOrder}?${query}`)
  }

  const renderTags = tags
    .filter(tag => propEq('is_artificial', false, tag))
    .map((tag, index) => {
      const handleTagCheckboxOnChange = () =>
        tag.is_read ? unmarkTopicCheckbox(tag) : markTopicCheckbox(tag)

      return (
        <TagWrapper key={`tag-${id}-${index}`}>
          <TagContainer
            onClick={handleTagClick(
              tag.original_book_id,
              tag.chapter_order,
              tag.part,
              tag.content_id
            )}
            isSeen={tag.is_seen}
          >
            <span>{tag.tag}</span>
            <span>
              {tag.chapter_order}. {tag.subchapter_order}.
            </span>
          </TagContainer>
          <CheckmarkContainer>
            <Checkbox
              name={`${tag.id}-confirm`}
              isSelected={tag.is_read}
              onChange={handleTagCheckboxOnChange}
              outlined
            />
          </CheckmarkContainer>
        </TagWrapper>
      )
    })

  return (
    <RowContainer
      isHighlighted={isEven}
      isBookView={isBookView}
      isSearched={isSearched}
    >
      <ConfirmColumnContainer>
        <Checkbox
          name='select'
          isSelected={is_mastered}
          onChange={handleCheckboxOnChange}
          outlined
        />
      </ConfirmColumnContainer>
      <TagsColumnContainer>{renderTags}</TagsColumnContainer>
      <TopicColumnContainer isConfirmed={is_mastered} level={level}>
        {topic}
      </TopicColumnContainer>
    </RowContainer>
  )
}

export default memo(CourseTopicsRow)

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme, isHighlighted }) => {
    switch (true) {
      case isHighlighted:
        return theme.colors.checklist.backgroundInterior
      default:
        return 'transparent'
    }
  }};
  align-items: center;
  padding: 6px 4px;
  border-radius: 2px;
  gap: 12px;
  animation: ${({ isSearched }) =>
    isSearched ? 'highlight-if-searched 2500ms ease-in' : 'none'};

  @keyframes highlight-if-searched {
    0% {
      background: transparent;
    }
    30% {
      background: ${({ theme }) => theme.colors.main.primary200};
    }
    100% {
      background: transparent;
    }
  }
`

const ConfirmColumnContainer = styled.div`
  flex: none;
  width: 7%;
`

const TagsColumnContainer = styled.div`
  flex: none;
  display: flex;
  gap: 16px;
  width: 60%;
  overflow-x: auto;
  scrollbar-height: thin;

  &::-webkit-scrollbar {
    height: 6px;
  }
`

const TopicColumnContainer = styled.div`
  flex: none;
  width: 30%;
  color: ${({ theme, isConfirmed }) =>
    isConfirmed ? theme.colors.main.grey400 : theme.colors.main.text};
  text-decoration: ${({ isConfirmed }) =>
    isConfirmed ? 'line-through' : 'none'};
  padding-left: ${({ level }) => level * 24}px;
  margin-right: 10px;
  white-space: pre-wrap;
`

const TagContainer = styled.div`
  padding: 7px;
  border: 1px solid
    ${({ theme, isSeen }) =>
      isSeen ? theme.colors.main.grey300 : theme.colors.main.grey400};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  color: ${({ theme, isSeen }) =>
    isSeen ? theme.colors.main.grey300 : theme.colors.main.text};
  border-radius: 2px;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  gap: 4px;
  white-space: nowrap;

  span {
    color: ${({ theme, isSeen }) =>
      isSeen ? theme.colors.main.grey300 : theme.colors.main.text};
  }
`

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const CheckmarkContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500} !important;
  font-size: 12px;
`

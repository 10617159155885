import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import dndDots from 'assets/images/dndDots.png'

const DayListItem = ({ day, index }) => {
  const isNotMandatoryColumn = index > 6

  return (
    <MainContainer data-testid='DayListItem-MainContainer'>
      <Draggable
        data-testid='DayListItem-Draggable'
        draggableId={day.id}
        index={index}
      >
        {(provided, snapshot) => {
          return (
            <Wrapper
              data-testid='DayListItem-Wrapper'
              ref={provided.innerRef}
              {...provided.draggableProps}
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              isNotMandatoryColumn={isNotMandatoryColumn}
            >
              {!isNotMandatoryColumn && (
                <IconWrapper>
                  <DotsIcon src={dndDots} alt='dndDots' />
                </IconWrapper>
              )}
              <ValueWrapper>{day.value}</ValueWrapper>
            </Wrapper>
          )
        }}
      </Draggable>
    </MainContainer>
  )
}

export default DayListItem
const MainContainer = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  z-index: 1;
`
const Wrapper = styled.div`
  display: flex;
  width: 150px;
  height: 26px;

  color: #444;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  border-color: ${({ theme, isDragging, isNotMandatoryColumn }) =>
    isDragging
      ? theme.colors.main.primary500
      : isNotMandatoryColumn
      ? theme.colors.main.primary600
      : 'var(--Gray, #d8d8d8)'};
  background: ${({ theme, isNotMandatoryColumn }) =>
    isNotMandatoryColumn ? theme.colors.main.primary400 : 'white'};
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
`
const ValueWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const DotsIcon = styled.img``
const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20px;
  padding-left: 10px;
`

import React, { useEffect, useState, memo } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { pathOr, propOr } from 'ramda'
import qs from 'qs'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

import {
  fetchPhraseInCourseList,
  fetchGlossaryWordsList
} from 'services/GlossaryService'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'
import Tags from 'components/Tags'

import { highlightText } from 'utils/string'
import { SORT_DIRECTION } from 'utils/table'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

import {
  BookIcon,
  BouncingLoader,
  CloseIcon,
  GlossaryIcon,
  UnderlinedTabs,
  HtmlRenderer
} from 'examkrackers-components'
import { SEARCH_GLOSSARY_TABS } from 'modules/glossary/components/InputSearchGlossaryWords'
import usePrevious from '../../../hooks/usePrevious'

export const TooltipGlossary = (): JSX.Element => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const currentCourse: Course = useSelector(getCurrentCourse)

  const [glossaryResults, setGlossaryResults] = useState([])
  const [bookResults, setBookResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [shouldChangePosition, setShouldChangePosition] = useState(false)
  const [currentTab, setCurrentTab] = React.useState(
    SEARCH_GLOSSARY_TABS.glossary
  )
  const prevTab = usePrevious(currentTab)

  const [text, setSearchedText] = useState('')
  const prevText = usePrevious(text)

  const [mousePosition, setMousePosition] = useState({ top: 0, left: 0 })
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [middleColumnPosition, setMiddleColumnPosition] = useState({
    left: 0,
    width: 0
  })

  const handleSearchGlossary = text => {
    setIsLoading(true)

    const handleSuccess = response => {
      const results = pathOr([], ['data', 'data'], response)
      setGlossaryResults(results)
      setIsLoading(false)
    }

    const handleError = e => {
      console.error(e)
      setIsLoading(false)
    }

    const query = `?${qs.stringify({
      order: { by: 'phrase_raw', dir: SORT_DIRECTION.asc },
      limit: { take: 100, page: 1 },
      filter: { search: text }
    })}`

    fetchGlossaryWordsList({ query }).then(handleSuccess).catch(handleError)
  }

  const handleSearchBook = text => {
    setIsLoading(true)

    const handleSuccess = response => {
      const results = pathOr([], ['data', 'data'], response)
      setBookResults(results)
      setIsLoading(false)
    }

    const handleError = e => {
      console.error(e)
      setIsLoading(false)
    }

    fetchPhraseInCourseList({
      id: currentCourse.id,
      search: text
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  const saveSearchQuery = text => {
    setSearchedText(text)
    saveMiddleColumnPosition()
  }

  const handleSearch = (text, disablePositionChange = false) => {
    if (!disablePositionChange) {
      setShouldChangePosition(true)
    }

    if (currentTab === SEARCH_GLOSSARY_TABS.glossary) {
      handleSearchGlossary(text)
    } else {
      handleSearchBook(text)
    }
  }

  useEffect(() => {
    const hasSearchTextChanged = prevText !== text
    const hasTabChanged = prevTab !== currentTab
    const hasSearchChanged = hasSearchTextChanged || hasTabChanged

    if (isNotNilOrEmpty(text) && hasSearchChanged) {
      handleSearch(text, hasTabChanged)
    }
  }, [text, currentTab])

  const hideTooltip = () => {
    setSearchedText('')
    setBookResults([])
    setGlossaryResults([])
    setCurrentTab(SEARCH_GLOSSARY_TABS.glossary)
  }

  const handleMouseMove = event => {
    setMousePosition({ left: event.pageX, top: event.pageY })
  }

  useEffect(() => {
    if (shouldChangePosition) {
      setPosition(mousePosition)
      setShouldChangePosition(false)
    }
  }, [mousePosition, shouldChangePosition])

  const saveMiddleColumnPosition = () => {
    const middleColumnContainer = document.getElementById('middle-column')

    if (middleColumnContainer) {
      // @ts-ignore
      const dimensions = middleColumnContainer.getBoundingClientRect()
      // @ts-ignore
      // @ts-ignore
      setMiddleColumnPosition({
        left: dimensions.left
      })
    }
  }

  useEffect(() => {
    eventEmitter.on(events.showGlossaryTooltip, saveSearchQuery)

    return () => {
      eventEmitter.off(events.showGlossaryTooltip, saveSearchQuery)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [glossaryResults])

  const renderGlossaryResults = glossaryResults.map(
    (word: {
      id: string
      phrase_html: string
      phrase_delta_object: string
      explanation_html: string
      explanation_delta_object: string
    }) => (
      <ResultContainer key={word.id}>
        <PhraseContainer>
          <div className='phrase'>
            <HtmlRenderer htmlString={highlightText(word.phrase_html, text)} />
          </div>

          <span>-</span>

          <div className='explanation'>
            <HtmlRenderer
              htmlString={highlightText(word.explanation_html, text)}
            />
          </div>
        </PhraseContainer>
      </ResultContainer>
    )
  )
  const renderBookResults = bookResults.map((result, index) => {
    const chapterOrder = propOr('', 'chapter_order', result)
    const partOrder = propOr('', 'part', result)
    const bookContentId = propOr('', 'student_book_content_id', result)
    const subchapterId = propOr('', 'student_subchapter_id', result)
    const bookOriginalIdFromResponse = propOr('', 'original_book_id', result)
    const tag = propOr('', 'tag', result)

    const handleScrollToBookContent = () => {
      const bookContentAnchor = document.getElementById(
        `book-content-anchor-${bookContentId}`
      )
      bookContentAnchor &&
        bookContentAnchor.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
    }

    const handleHighlightSearchPhrase = () => {
      eventEmitter.emit(events.highlightSearchPhrase, {
        bookContentId,
        phrase: text
      })
    }

    const redirectToBookContent = () => {
      const link = `/books/${bookOriginalIdFromResponse}/chapter/${chapterOrder}/part/${partOrder}?${BOOK_VIEWER_PARAMS.sectionIdContext}=${subchapterId}&${BOOK_VIEWER_PARAMS.bookContentIdContext}=${bookContentId}`

      push(link)
      setTimeout(handleScrollToBookContent, 3000)
      setTimeout(handleHighlightSearchPhrase, 4000)
    }

    const contentRaw = R.propOr('', 'content_raw', result)
    const resourceRaw = R.propOr('', 'resource_raw', result)

    const hasContentResult = R.propOr(false, 'content_match', result)
    const hasResourceResult = R.propOr(false, 'resource_match', result)

    return (
      <React.Fragment key={`book-phrase-result-${bookContentId}-${index}`}>
        {hasContentResult && (
          <ResultContainer
            key={`book-phrase-result-content-${bookContentId}-${index}`}
          >
            <BookPhraseContainer>
              <div className='index-outer'>
                <div onClick={redirectToBookContent} className='index'>
                  <TagsParent>
                    <Tags tags={[tag]} id={bookContentId} />
                  </TagsParent>
                </div>
              </div>
              <div
                className='phrase'
                dangerouslySetInnerHTML={{
                  __html: highlightText(contentRaw, text)
                }}
              />
            </BookPhraseContainer>
          </ResultContainer>
        )}
        {hasResourceResult && (
          <ResultContainer
            key={`book-phrase-result-resource-${bookContentId}-${index}`}
          >
            <BookPhraseContainer>
              <div className='index-outer'>
                <div onClick={redirectToBookContent} className='index'>
                  <TagsParent>
                    <Tags tags={[tag]} id={bookContentId} />
                  </TagsParent>
                </div>
              </div>
              <div
                className='phrase'
                dangerouslySetInnerHTML={{
                  __html: highlightText(resourceRaw, text)
                }}
              />
            </BookPhraseContainer>
          </ResultContainer>
        )}
      </React.Fragment>
    )
  })

  const renderSearchForText = (
    <SearchForContainer>
      <div className='label'>{t('bookViewer.glossary.searchingFor')}</div>
      <div className='value'>{text}</div>
    </SearchForContainer>
  )

  const tabs = [
    {
      label: (
        <>
          <GlossaryIcon />
          <span>{t('bookViewer.glossary.tabs.glossary')}</span>
        </>
      ),
      value: SEARCH_GLOSSARY_TABS.glossary
    },
    {
      label: (
        <>
          <BookIcon />
          <span>{t('bookViewer.glossary.tabs.book')}</span>
        </>
      ),
      value: SEARCH_GLOSSARY_TABS.book
    }
  ]

  const tabContents = [
    {
      value: SEARCH_GLOSSARY_TABS.glossary,
      content: (
        <>
          {renderSearchForText}
          {isLoading && (
            <LoaderContainer>
              <BouncingLoader />
            </LoaderContainer>
          )}
          {!isLoading && isNilOrEmpty(renderGlossaryResults) && (
            <EmptyStateContainer>
              {t('bookViewer.glossary.emptyGlossary')}
            </EmptyStateContainer>
          )}
          {!isLoading && isNotNilOrEmpty(glossaryResults) && (
            <ResultsList>{renderGlossaryResults}</ResultsList>
          )}
        </>
      )
    },
    {
      value: SEARCH_GLOSSARY_TABS.book,
      content: (
        <>
          {renderSearchForText}
          {isLoading && (
            <LoaderContainer>
              <BouncingLoader />
            </LoaderContainer>
          )}
          {!isLoading && isNilOrEmpty(bookResults) && (
            <EmptyStateContainer>
              {t('bookViewer.glossary.emptyIndex')}
            </EmptyStateContainer>
          )}
          {!isLoading && isNotNilOrEmpty(bookResults) && (
            <ResultsList>{renderBookResults}</ResultsList>
          )}
        </>
      )
    }
  ]

  const windowHeight = window.innerHeight

  const getTopPosition = () => {
    const standardPosition = position.top + 10
    const isUnderWindowEdge = windowHeight - (position.top + 10) < 500
    const positionIfUnderEdge = windowHeight - 500

    return isUnderWindowEdge ? positionIfUnderEdge : standardPosition
  }

  return isNotNilOrEmpty(text) ? (
    <TooltipContainer
      style={{
        top: `${getTopPosition()}px`,
        left: `${middleColumnPosition.left}px`
      }}
    >
      <CloseIconContainer onClick={hideTooltip}>
        <CloseIcon />
      </CloseIconContainer>
      <UnderlinedTabs
        onChange={setCurrentTab}
        activeTab={currentTab}
        tabs={tabs}
        tabContents={tabContents}
      />
    </TooltipContainer>
  ) : (
    <div />
  )
}

export default memo(TooltipGlossary)

const CloseIconContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 16px;
`

const SearchForContainer = styled.div`
  display: inline-flex;
  gap: 6px;
  padding: 8px;

  .label {
    font-size: 12px;
    line-height: 16px;
  }

  .value {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }
`

const TooltipContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.main} !important;
  opacity: 1 !important;
  color: ${({ theme }) => theme.colors.main.text} !important;
  border-radius: 4px !important;
  padding: 16px !important;
  margin-top: 0px !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.48) !important;
  max-width: 725px !important;
  min-width: 725px !important;
  text-align: left !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: -0.1px !important;
  z-index: ${({ theme }) => theme.zIndex.menu} !important;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: fixed;
  min-height: 500px;
`

const PhraseContainer = styled.div`
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  gap: 6px;

  .phrase {
    flex: none;
    width: 100px;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.main.header};
  }

  .explanation {
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.main.text};
  }

  .highlight {
    background: transparent !important;
    font-weight: 700;
  }

  img {
    max-width: 510px !important;
  }
`

const BookPhraseContainer = styled.div`
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  gap: 6px;
  overflow: hidden;
  align-items: center;

  .index-outer {
    flex: none;
  }

  .index {
    display: inline-block;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.main.header};
    /* padding: 4px 8px;
    border: 1px solid ${({ theme }) => theme.colors.main.grey300}; */
    border-radius: 4px;
  }

  .index > * > * {
    margin: 0 2px 0 0 !important;
  }

  .phrase {
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.main.text};
  }

  .highlight {
    background: transparent !important;
    font-weight: 700;
  }
`

const ResultContainer = styled.div`
  display: block;
  line-height: normal;
  min-width: 100%;
  width: 100%;
  padding: 16px 16px 0 16px;
  white-space: nowrap;
`

const ResultsList = styled.div`
  overflow-y: auto;
  max-height: 346px;
  min-height: 20px;
`

const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`
const TagsParent = styled.div`
  pointer-events: none;
`

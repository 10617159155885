import React from 'react'
import styled from 'styled-components'
import { Button, InputField, ReloadIcon } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { validateSMSCodeField, validateSMSCodeValue } from '../ducks/schema'

const ModalVerifySMS = ({
  open,
  handleSendCode,
  handleSendCodeViaEmail,
  handleVerifyCode,
  code,
  setCode,
  isVerified,
  studentPhone
}) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = React.useState(false)

  const handleOnChange = (name, value) => {
    setCode(value)
  }

  React.useEffect(() => {
    if (isVerified === false) {
      handleSendCode()
    }
  }, [])

  React.useEffect(() => {
    validateSMSCodeValue({ '2fa-verification-code': code }, _validateSchema)
  }, [code])

  return (
    <>
      <Form onSubmit={e => handleVerifyCode(e, code)} open={open}>
        <Title>
          <h3>{t('smsVerification.title')} </h3>
          <p>
            {t('smsVerification.description')} {studentPhone}.{' '}
          </p>
        </Title>

        <InputField
          t={t}
          name='2fa-verification-code'
          id='2fa-sms-code'
          label={t('smsVerification.codeForm.inputLabel')}
          value={code}
          onChange={handleOnChange}
          startIcon={<ReloadIcon />}
          validate={validateSMSCodeField({ '2fa-verification-code': code })}
          required
        />
        <BottomElements>
          <ButtonContainer>
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => handleSendCode()}
            >
              {t('smsVerification.codeForm.resendButton')}
            </Button>
            <Button onClick={() => handleVerifyCode(code)} disabled={!valid}>
              {t('smsVerification.codeForm.verifyButton')}
            </Button>
          </ButtonContainer>
          <BottomTextContainer>
            <p>
              {t('smsVerification.cantReceiveSMS')}
              <span onClick={handleSendCodeViaEmail}>
                {t('smsVerification.codeViaEmail')}
              </span>
            </p>
            <p>
              {t('smsVerification.codeLostInfo')}
              <a href='mailto:support@examkrackers.com'>
                {t('smsVerification.customerSupport')}
              </a>
            </p>
          </BottomTextContainer>
        </BottomElements>
      </Form>
    </>
  )
}

export default ModalVerifySMS

const Form = styled.form`
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: 8px;
  padding: 48px;
  max-height: 564px;
  max-width: 610px;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;

  h3 {
    color: ${({ theme }) => theme.colors.main.primary800};
    width: 100%;
    font-size: 42px;
    font-weight: 700;
    line-height: 68px;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;

  button {
    height: 56px;
    width: 249px;
  }
`
const BottomElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const BottomTextContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  a,
  span {
    color: ${({ theme }) => theme.colors.main.primary700};
    text-decoration: underline;
    cursor: pointer;
  }
`

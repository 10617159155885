import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ApexChart from 'react-apexcharts'
import { saltyBucksByCategoryChartOptions } from 'modules/leadershipBoard/utils/SaltyBucksByCategoryChart'
import { formatNumberWithDecimal } from 'utils/numbers'
import { fetchSBByCategory } from 'services/LeaderboardService'
import { SaltyBucksIcon } from 'examkrackers-components'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from '../../../utils/ramda'
import { useSelector } from 'react-redux'
import { getStudent } from '../../auth/ducks/selectors'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
const dummyData = {
  values: {
    good_student: 3623,
    reading: 15600,
    flashcards: 36,
    administration: 500
  },
  categories: [
    'administration',
    'reading',
    'good_student',
    'video',
    'flashcards',
    'content_questions',
    'mcatq',
    'games'
  ]
}
const SaltyBucksByCategory = () => {
  const [data, setData] = useState(dummyData)
  const user = useSelector(getStudent)
  const saltyBucksBalance: number = R.propOr(false, 'salty_bucks_balance', user)
  const { t } = useTranslation()

  const getSBByCategory = () => {
    const handleSuccess = response => {
      setData(propOr([], 'data', response))
    }

    const handleError = response => console.error(response)

    fetchSBByCategory().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    getSBByCategory()
  }, [])

  const chartConfig = saltyBucksByCategoryChartOptions(data)

  return (
    <ChartContainer>
      <TotalContainer>
        <LabelContainer>
          {t('leaderboard.rightColumn.categoriesChart.total')}:
        </LabelContainer>
        <ValueContainer>
          <IconContainer>
            <SaltyBucksIcon />
          </IconContainer>
          <span>{formatNumberWithDecimal(saltyBucksBalance)}</span>
        </ValueContainer>
      </TotalContainer>
      {isNotNilOrEmpty(data) && (
        // @ts-ignore
        <ApexChart
          // @ts-ignore
          options={chartConfig.options}
          series={chartConfig.series}
          type='bar'
          width='404'
          height='168'
        />
      )}
    </ChartContainer>
  )
}

export default SaltyBucksByCategory

const TotalContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
  align-items: center;
  display: flex;
  gap: 6px;
  z-index: 500;
`

const LabelContainer = styled.div`
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.main.grey600};
`

const IconContainer = styled.div`
  font-size: 17px;
`

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: ${({ theme }) => theme.colors.main.currency500};
`

const ChartContainer = styled.div`
  position: relative;

  .apexcharts-tooltip {
    border: none !important;
    box-shadow: none;
    border-radius: unset;
    background: transparent !important;
    overflow: visible !important;
  }

  div.category-tooltip {
    font-size: 11px !important;
    padding: 6px !important;
    display: flex;
    flex-direction: column;
    box-shadow: ${({ theme }) => theme.shadows.mainShadow};
    background: ${({ theme }) => theme.colors.backgrounds.main};
    border-radius: 6px;
  }

  span.category-tooltip-label {
    font-family: ${({ theme }) => theme.typography.fontFamily} !important;
  }

  div.category-tooltip-value {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;

    span.category-tooltip-value-label {
      font-weight: 700 !important;
      color: ${({ theme }) => theme.colors.main.text} !important;
      font-family: ${({ theme }) => theme.typography.fontFamily} !important;
    }

    span.category-tooltip-value-icon {
      color: ${({ theme }) => theme.colors.main.currency500} !important;
      font-size: 11px !important;
    }
  }
`

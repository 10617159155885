import { theme } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'

import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

const CATEGORIES = [
  'PL: 1',
  'PL: 2',
  'PL: 3',
  'PL: 4',
  'PL: 5',
  'PL: 6',
  'PL: 7'
]

export const FlashcardsProgressChart = data => {
  const { t } = useTranslation()

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  const arraysWithFlashCards = data.map(el => el.data.map(e => parseInt(e, 10)))

  const sumInArrays = arraysWithFlashCards.map(subArray => {
    // Use reduce to calculate the sum of the elements in the sub-array
    const sum = subArray.reduce((acc, currentValue) => acc + currentValue, 0)
    return sum
  })
  const filteredData = data.filter((el, index) => sumInArrays[index] > 0)
  return {
    series: filteredData.map(el => {
      return {
        name: el.name.toUpperCase(),
        data: el.data.map(e => parseInt(e, 10))
      }
    }),
    options: {
      chart: {
        id: 'flashcardsChart',
        type: 'heatmap',
        background: 'transparent',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.4,
          radius: 3,
          useFillColorAsStroke: true
        }
      },
      colors: [theme.colors.main.white],
      legend: {
        show: false
      },
      stroke: {
        width: 2
      },
      title: {
        text: t('dashboard.charts.flashCardsProgress'),
        align: 'left',
        style: {
          fontSize: '16',
          fontFamily: 'Arial, sans-serif'
        },
        offsetX: 40,
        offsetY: 10
      },
      tooltip: {
        enabled: false
      },
      grid: {
        show: true,
        borderColor: 'transparent',
        padding: {
          left: 20
        }
      },
      xaxis: {
        type: 'category',
        categories: CATEGORIES,
        offsetX: -1,
        labels: {
          style: {
            colors: theme.colors.dashboard.totalProgress,
            fontFamily: 'Arial, sans-serif'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        labels: {
          align: 'left',
          style: {
            fontWeight: 700,
            fontSize: '12px',
            colors: isDarkTheme ? ['#ffffff'] : ['#000000'],
            fontFamily: 'Arial, sans-serif',
            cssClass: 'apexcharts-heatmap-yaxis-label'
          }
        }
      },
      dataLabels: {
        offsetX: -1,
        offsetY: -0.5,
        style: {
          fontSize: '10px',
          colors: [theme.colors.main.white],
          fontFamily: 'Arial, sans-serif',
          fontWeight: 700
        }
      }
    }
  }
}

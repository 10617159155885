import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { DateInputControllerProps } from 'components/DateInput/DatePicker.model'
import { equals } from 'ramda'

const getHeadErrorOrEmptyObj = R.pipe(
  R.propOr([], 'errors'),
  R.ifElse(R.isEmpty, R.always({}), R.head)
)

const useDateInput = ({
  name,
  value: initialValue,
  validate,
  reset,
  onChange,
  minDate,
  maxDate,
  allowPast = true
}: DateInputControllerProps) => {
  const [selectedDate, setSelectedDate] = useState(initialValue)
  const [touched, _setTouched] = React.useState<boolean>(false)

  const [{ valid, error }, _validate] = React.useState({
    valid: true,
    error: {}
  })

  useEffect(() => {
    if (touched && !reset && validate) {
      validate(name, v => {
        _validate({ valid: v.valid, error: getHeadErrorOrEmptyObj(v) })
      })
    }
  }, [selectedDate, touched, reset])

  useEffect(() => {
    if (reset) {
      setSelectedDate(initialValue || '')
    }
  }, [reset])

  useEffect(() => {
    if (!equals(initialValue, selectedDate)) {
      setSelectedDate(initialValue)
    }
  }, [initialValue])

  const handleFocus = () => _setTouched(true)

  const handleChange = (date: Date) => {
    const day = String(date.getDate())
    const month = String(date.getMonth() + 1)
    const year = String(date.getFullYear())

    const correctedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(
      2,
      '0'
    )}`

    setSelectedDate(date)
    onChange(name, correctedDate)
  }

  const getDateBoundaries = (type: string) => {
    const today = new Date()
    let processedMinDate = minDate
    let processedMaxDate = maxDate

    if (!allowPast) {
      processedMinDate = today
    }

    if (maxDate && processedMaxDate < processedMinDate) {
      processedMaxDate = processedMinDate
    }

    if (type === 'min') {
      return processedMinDate
    } else if (type === 'max') {
      return processedMaxDate
    }

    return undefined
  }

  return {
    selectedDate,
    valid,
    handleChange,
    handleFocus,
    getDateBoundaries,
    error
  }
}

export default useDateInput

import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getChapterByBookOriginalIdAndOrder,
  getCurrentBookChapters,
  getSubchaptersListByChapterId
} from 'modules/books/ducks/selectors'
import { useParams } from 'react-router'
import { Course, RootReducer, Subchapter } from 'types'
import styled, { css } from 'styled-components'
import { pipe, propOr, pathOr } from 'ramda'
import { OpenBookIcon, SingleSelect, Tooltip } from 'examkrackers-components'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import { useTranslation } from 'react-i18next'
import { getCurrentCourse } from '../../courses/ducks/selectors'
import * as R from 'ramda'
import { COURSE_TYPES } from '../../../utils/courses'
import { isNilOrEmpty } from 'utils/ramda'

const NavigationBookViewer = () => {
  const [open, setOpen] = useState(false)
  const [selectedChapter, setSelectedChapter] = useState<any>(null)
  const [selectedSubchapter, setSelectedSubchapter] = useState<any>(null)
  const [initiallySet, setInitiallySet] = useState({
    chapter: false,
    subchapter: false
  })
  const {
    location: { search },
    push
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const { t } = useTranslation()

  const params = useParams()
  const bookId: string = propOr('', 'bookId')(params)
  const chapters = useSelector(getCurrentBookChapters)
  const sectionId = propOr(
    null,
    BOOK_VIEWER_PARAMS.sectionIdContext,
    parsedQuery
  )
  const chapterOrder: number = pipe(propOr('1', 'chapterOrder'), Number)(params)
  const chapter = useSelector((state: RootReducer) =>
    getChapterByBookOriginalIdAndOrder(state, bookId, chapterOrder)
  )
  const chapterId = propOr('', 'id', chapter)
  const subchapters = useSelector(state =>
    getSubchaptersListByChapterId(state, chapterId)
  )

  useEffect(() => {
    const container = document.getElementById('nav-container')
    const trigger = document.getElementById('book-nav-trigger')
    const onClick = e => {
      // @ts-ignore
      const isClickInsideElement = container.contains(e.target)
      // @ts-ignore
      const isClickOnTrigger = trigger.contains(e.target)
      const isSelectOption = pathOr('', ['target', 'id'], e).includes('option')

      if (!isClickInsideElement && !isClickOnTrigger && !isSelectOption) {
        setOpen(false)
      }
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [])

  const toggleOpen = () => setOpen(prev => !prev)

  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const currentImageSlide = pipe(
    propOr('0', BOOK_VIEWER_PARAMS.imageSlide),
    Number
  )(parsedQuery)

  const onChapterChange = option => {
    const isInactive = option.value === 'inactive'

    if (!isInactive) {
      setSelectedChapter(option)
      const newQuery = {
        ...parsedQuery,
        [BOOK_VIEWER_PARAMS.imageSlide]:
          Number(option.value) !== Number(chapterOrder) ? 0 : currentImageSlide
      }
      setSelectedSubchapter(null)
      push(
        `/books/${bookId}/chapter/${option.value}/part/1${qs.stringify(
          newQuery,
          {
            addQueryPrefix: true
          }
        )}`
      )
    }
  }

  const onSubchapterChange = option => {
    // const isInactive = option.part === 'inactive'

    // if (!isInactive) {
    setSelectedSubchapter(option)
    const newQuery = {
      ...parsedQuery,
      [BOOK_VIEWER_PARAMS.sectionIdContext]: option.value
    }
    push(
      `/books/${bookId}/chapter/${chapterOrder}/part/${
        option.part
      }${qs.stringify(newQuery, { addQueryPrefix: true })}`
    )
    setTimeout(() => {
      const anchor = document.getElementById(
        `subchapter-anchor-${option.value}`
      )
      anchor && anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 1500)
    // }
  }

  const subchapterOptions = useMemo(() => {
    return subchapters.map((subchapter: Subchapter, index) => {
      // const isFirstPartOfFirstChapter =
      //   // @ts-ignore
      //   chapterOrder === 1
      // const isFirstPartOfOtherChapter = chapterOrder !== 1
      // @ts-ignore
      // const isInactive = isFreeTrial && isFirstPartOfFirstChapter
      const part = R.propOr('', 'part', subchapter)
      const prevPart = R.propOr('', 'part', subchapters[index - 1])
      const isNewPart = part !== prevPart

      const label = (
        <OptionLabel isInactive={false}>
          {isNewPart && <OptionPart>Part {part}</OptionPart>}
          <div className='label'>
            {`${chapterOrder}.${subchapter.order}. ${subchapter.title}`}
          </div>
        </OptionLabel>
      )

      return {
        label: label,
        // @ts-ignore
        value: subchapter.id,
        // @ts-ignore
        part: subchapter.part
      }
    })
  }, [subchapters, currentCourse])

  const chaptersOptions = useMemo(() => {
    return chapters.map(chapter => {
      // @ts-ignore
      const isInactive = isFreeTrial && chapter.order !== 1

      const label = (
        <OptionLabel
          className='label'
          isInactive={isInactive}
          // @ts-ignore
        >{`${chapter.order}. ${chapter.title}`}</OptionLabel>
      )

      return {
        label: isInactive ? (
          <TooltipContainer data='tooltip-container'>
            <Tooltip
              tooltipContent={t('toast.chapterNotAvailableInFreeTrial')}
              // @ts-ignore
              id={`select-chapter-book-chapter-tooltip-${chapter.order}`}
            >
              {label}
            </Tooltip>
          </TooltipContainer>
        ) : (
          label
        ),
        // @ts-ignore
        value: isInactive ? 'inactive' : chapter.order
      }
    })
  }, [chapters, currentCourse])

  const currentChapter = chaptersOptions.find(
    option => option.value === chapterOrder
  )

  const subchapterId =
    sectionId || R.pathOr(null, [0, 'value'], subchapterOptions)
  const currentSection = subchapterOptions.find(
    option => option.value === subchapterId
  )

  useEffect(() => {
    if (subchapterId && subchapterOptions && isNilOrEmpty(selectedSubchapter)) {
      if (currentSection) {
        // @ts-ignore
        setSelectedSubchapter(currentSection)
        setInitiallySet(prev => ({ ...prev, subchapter: true }))
      }
    }
  }, [search, subchapters, currentChapter])

  useEffect(() => {
    if (chapterOrder && chaptersOptions && !initiallySet.chapter) {
      if (currentChapter) {
        // @ts-ignore
        setSelectedChapter(currentChapter)
        setInitiallySet(prev => ({ ...prev, chapter: true }))
      }
    }
  }, [params, chapters])

  useEffect(() => {
    setSelectedChapter(currentChapter)
    setSelectedSubchapter(currentSection)
  }, [currentChapter, currentSection])

  return (
    <Wrapper>
      <Trigger onClick={toggleOpen} id='book-nav-trigger'>
        <OpenBookIcon style={{ fontSize: 18 }} />
        <TriggerTitle>{t('bookViewer.navigation.browse')}</TriggerTitle>
      </Trigger>
      <Content open={open} id='nav-container'>
        <div className='chapter-select'>
          <SingleSelect
            removeMargin
            label={t('bookViewer.navigation.chapter')}
            size='small'
            // @ts-ignore
            options={chaptersOptions}
            onChange={onChapterChange}
            value={selectedChapter}
            isSearchable={false}
          />
        </div>
        <div className='subchapter-select'>
          <SingleSelect
            removeMargin
            label={t('bookViewer.navigation.section')}
            size='small'
            // @ts-ignore
            options={subchapterOptions}
            onChange={onSubchapterChange}
            isSearchable={false}
            value={selectedSubchapter}
          />
        </div>
      </Content>
    </Wrapper>
  )
}

export default NavigationBookViewer

const Wrapper = styled.div`
  position: relative;
`

const Trigger = styled.div`
  background: ${({ theme }) => theme.colors.books.navigation.backgroundDark};
  padding: 4px 8px;
  border-radius: 4px 4px 0 0;
  font-size: 11px;
  height: 24px;
  position: relative;
  top: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const TriggerTitle = styled.div`
  margin-left: 5px;
`

const OptionLabel = styled.div`
  color: ${({ theme, isInactive }) =>
    isInactive ? theme.colors.main.grey600 : theme.colors.main.text};
  cursor: ${({ isInactive }) => (isInactive ? 'not-allowed' : 'pointer')};
`

const OptionPart = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  cursor: default;
  font-size: 11px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey600};
  font-weight: 700;
`

const Content = styled.div`
  background: ${({ theme }) => theme.colors.books.navigation.backgroundLight};
  transition: all 0.3s;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  width: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.books.navigation.borderDark};
  border-radius: 0 4px 4px 4px;
  box-shadow: ${props => props.theme.shadows.mainShadow};

  & > div {
    margin: 0 5px;
    width: calc(50% - 10px);

    & > div > div > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${({ open }) =>
    open &&
    css`
      bottom: -80px;
      width: 480px;
      height: 80px;
      overflow: visible;
      padding: 25px 10px;
    `}

  .chapter-select, .subchapter-select {
    & > div:first-child > div:nth-child(3) > div {
      overflow: auto;
    }

    .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .chapter-select {
    width: 40%;

    .label {
      max-width: 130px;
    }
  }

  .subchapter-select {
    width: 60%;
    & > div > div:first-of-type > div:first-child > div:first-child {
      ${OptionPart} {
        display: none;
      }
    }

    .label {
      max-width: 215px;
    }

    &
      > div
      > div:first-of-type
      > div:first-child
      > div:first-child
      .label:hover {
      background: none !important;
    }

    & > div > div:first-of-type > div:first-child > div:first-child .label {
      padding: 0 !important;
    }

    // Menu list option
    & > div > div:last-child > div > div {
      padding: 0 !important;

      &:hover {
        background: inherit !important;
      }

      ${OptionPart} {
        padding-top: 5px;
        margin: 0 16px;
      }

      ${OptionLabel} {
        .label {
          padding: 6px 16px;

          &:hover {
            background: ${({ theme }) =>
              theme.colors.selects.option.backgroundActive};
          }
        }
      }
    }
  }
`

const TooltipContainer = styled.div`
  & > div,
  & > div > div:first-child {
    width: 100%;
  }
`

import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import { Button, NavArrowUpIcon, Tooltip } from 'examkrackers-components'

import CourseTopicsRow from './CourseTopicsRow'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { loadPrevPageRoutine } from 'modules/courseTopics/ducks/actions'
import { selectSearchedTopic } from 'modules/courseTopics/ducks/selectors'
import { Course } from '../../../types'
import { getCurrentCourse } from '../../courses/ducks/selectors'
import { isNilOrEmpty } from '../../../utils/ramda'

export const CourseTopicsTable = ({
  header,
  topics,
  stickyHeader,
  isBookView,
  hideLoadMoreButton
}): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchedTopic = useSelector(selectSearchedTopic)
  const searchedTopicId = R.propOr('', 'id', searchedTopic)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const courseTopicsTitle: string = R.propOr(
    '',
    'course_topics_title',
    currentCourse
  )

  const loadPrevPageOfTopics = useCallback(
    () => dispatch(loadPrevPageRoutine()),
    [dispatch]
  )

  const renderRows = topics.map((row, index) => (
    <CourseTopicsRow
      key={`course-topic-row-${index}`}
      topic={row}
      isEven={index % 2 === 0}
      isSearched={row.id === searchedTopicId}
      isBookView={isBookView}
    />
  ))

  const isLoadedFromFirstPage = R.pipe(
    R.head,
    R.propOr(1, 'order'),
    R.equals(1)
  )(topics)

  const LoadPrevPagesButton = (
    <LoadMoreRow>
      <LoadMoreButtonContainer>
        <Button
          id='load-more-topics'
          size='small'
          color='tertiary'
          startIcon={<NavArrowUpIcon />}
          onClick={loadPrevPageOfTopics}
        >
          Load more
        </Button>
      </LoadMoreButtonContainer>
    </LoadMoreRow>
  )

  return (
    <TableContainer>
      <HeaderWrapper stickyHeader={stickyHeader}>
        {header}
        <RowContainer>
          <ConfirmColumnContainer>
            <span>{t('topics.headers.confirm')}</span>
            <Tooltip
              place='bottom'
              info
              tooltipContent={
                <TooltipContent>{t('topics.tooltips.confirm')}</TooltipContent>
              }
              id='confirm-tooltip'
            />
          </ConfirmColumnContainer>
          <TagsColumnContainer>
            <span>{t('topics.headers.chaptersRead')}</span>
            <Tooltip
              place='bottom'
              info
              tooltipContent={
                <TooltipContent>
                  <ul>
                    <li>{t('topics.tooltips.chaptersRead.point1')}</li>
                    <li>{t('topics.tooltips.chaptersRead.point2')}</li>
                  </ul>
                </TooltipContent>
              }
              id='chaptersRead-tooltip'
            />
          </TagsColumnContainer>
          <TopicColumnContainer>
            <span>
              {isNilOrEmpty(courseTopicsTitle)
                ? t('topics.headers.topics')
                : courseTopicsTitle}
            </span>
            <Tooltip
              place='bottom'
              info
              tooltipContent={
                <TooltipContent>{t('topics.tooltips.topics')}</TooltipContent>
              }
              id='topics-tooltip'
            />
          </TopicColumnContainer>
        </RowContainer>
      </HeaderWrapper>
      {!isLoadedFromFirstPage && !hideLoadMoreButton && LoadPrevPagesButton}
      <ResultsContainer>{renderRows}</ResultsContainer>
    </TableContainer>
  )
}

export default memo(CourseTopicsTable)

const TableContainer = styled.div`
  border-radius: 6px;
`

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderWrapper = styled.div`
  background: ${({ theme, stickyHeader }) =>
    stickyHeader ? theme.colors.backgrounds.main : 'transparent'};
  border-radius: 6px;
  padding: 16px 0 16px;
  position: ${({ stickyHeader }) => (stickyHeader ? 'sticky' : 'none')};
  top: ${({ stickyHeader, theme }) =>
    stickyHeader ? theme.dimensions.studentTopNavHeight : 'none'};
  z-index: ${({ theme }) => theme.zIndex.menu};
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  gap: 12px;
`

const ConfirmColumnContainer = styled.div`
  flex: none;
  width: 7%;
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    color: ${({ theme }) => theme.colors.main.grey600};
  }
`

const TagsColumnContainer = styled.div`
  flex: none;
  width: 60%;
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    color: ${({ theme }) => theme.colors.main.grey600};
  }
`

const TooltipContent = styled.div`
  text-align: left;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;

  li {
    list-style-type: disc;
  }
`

const TopicColumnContainer = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  gap: 6px;
  width: 33%;

  svg {
    color: ${({ theme }) => theme.colors.main.grey600};
  }
`

const LoadMoreRow = styled.div`
  display: flex;
  justify-content: flex-end;
`

const LoadMoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
  position: relative;
`

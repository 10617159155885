import React from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { getTheme } from 'examkrackers-components'

export const SBPercentileRankPointer = (): JSX.Element => {
  const theme = getTheme()

  const container = document.getElementById('sb-percentile-rank-container')
  const currentUserRow = document.getElementById('percentile-rank-current-user')
  const percentileRankPosition = document.getElementById(
    'percentile-rank-position'
  )

  const containerHeight = () => {
    // @ts-ignore
    const dimensions = container ? container.getBoundingClientRect() : {}

    return propOr(0, 'height', dimensions)
  }

  const currentUserRowPositionFromTop = () => {
    // @ts-ignore
    const containerDimensions = container
      ? container.getBoundingClientRect()
      : {}
    const containerTop = propOr(0, 'top', containerDimensions)

    // @ts-ignore
    const currentUserRowDimensions = currentUserRow
      ? currentUserRow.getBoundingClientRect()
      : {}
    const currentUserRowTop = propOr(0, 'top', currentUserRowDimensions)
    // @ts-ignore
    return currentUserRowTop - containerTop
  }

  const percentileRankPositionFromTop = () => {
    // @ts-ignore
    const containerDimensions = container
      ? container.getBoundingClientRect()
      : {}
    const containerTop = propOr(0, 'top', containerDimensions)

    // @ts-ignore
    const percentileRankPositionDimensions = percentileRankPosition
      ? percentileRankPosition.getBoundingClientRect()
      : {}
    const percentileRankPositionTop = propOr(
      0,
      'top',
      percentileRankPositionDimensions
    )
    // @ts-ignore
    return percentileRankPositionTop - containerTop
  }

  return (
    <PointerContainer>
      <svg
        width='58px'
        height={`${containerHeight()}px`}
        viewBox={`0 0 42px ${containerHeight()}px`}
      >
        <polygon
          points={`0,${currentUserRowPositionFromTop() + 1} 0,${
            currentUserRowPositionFromTop() + 47.5
          } 47.5,${percentileRankPositionFromTop()}`}
          fill={theme.colors.main.primary200}
          stroke={theme.colors.main.primary500}
          strokeWidth='1'
        />
        <line
          x1='-1'
          y1={currentUserRowPositionFromTop() + 3}
          x2='-1'
          y2={currentUserRowPositionFromTop() + 47}
          stroke={theme.colors.main.primary200}
          strokeWidth='4'
        />
      </svg>
    </PointerContainer>
  )
}

export default SBPercentileRankPointer

const PointerContainer = styled.div`
  width: 42px;
`

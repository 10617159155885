import React, { useContext } from 'react'
import { addDays, format, isSameDay } from 'date-fns'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { ClockIcon, Tooltip } from 'examkrackers-components'
import CalendarDayEventsList from 'modules/calendar/CalendarDayEventsList'
import Holidays from 'date-holidays'
import { useTranslation } from 'react-i18next'
import {
  cutTimeAndFormat,
  formatMinutesToHoursAndMinutes,
  isDateWithinInterval,
  getOnlyDate
} from 'utils/date'
import { getMonthName } from 'utils/calendar'
import McatDaySalty from 'assets/images/Calendar_Salty_MCATDay.svg'
import { CalendarContext } from 'hooks/CalendarContext'
import styled, { css } from 'styled-components'

interface CourseMeta {
  days_amount: string
}

const CalendarRow = ({ row, rowIndex, year, month }) => {
  const { calendar, lists, course, firstVisibleSunday } =
    useContext(CalendarContext)
  const { t } = useTranslation()
  const rowRef = React.useRef(null)
  const rowRange = row[0] + '-' + row[row.length - 1]

  const hd = new Holidays('US')
  const getHolidays = hd.getHolidays()

  const isFreeTrial = course.type === 'free_trial'
  const finalDate = calendar?.exam_at || ''
  const startDate =
    new Date(calendar?.start_at as string) ||
    new Date(course?.accessible_from as string)
  const mcatDate = calendar?.mcat_at || ''

  const checkHoliday = date => {
    const holiday = getHolidays.find(
      h => isSameDay(h.start, date) && ['public', 'bank'].includes(h.type)
    )

    return holiday ? { ...holiday } : null
  }

  return (
    <TableContainer
      className='calendar-row'
      key={rowIndex + rowRange}
      ref={rowRef}
      id={rowRange}
      data-id={`${month.toString().padStart(2, '0')}-${year}-row`}
    >
      {row.map((day, index) => {
        const dayNumber = new Date(day).getDate()
        const month = format(new Date(day), 'MM')
        const year = format(new Date(day), 'yyyy')
        const today = format(new Date(), 'yyyy-MM-dd')

        const currentlyChecked = `${year}-${month}-${dayNumber
          .toString()
          .padStart(2, '0')}`

        const isFirstVisibleSunday = isSameDay(
          new Date(firstVisibleSunday),
          new Date(currentlyChecked)
        )

        const isStartDate =
          new Date(currentlyChecked).toDateString() ===
          new Date(startDate).toDateString()
        const holidays = checkHoliday(new Date(currentlyChecked))
        const dayHolidays = [holidays]
        const isHolidayToday = !!holidays

        const isFinalDate = isSameDay(
          new Date(finalDate),
          new Date(currentlyChecked)
        )
        const isMcatDate = isSameDay(
          new Date(mcatDate),
          new Date(currentlyChecked)
        )
        const isFinalDayAndHaveEvents =
          isFinalDate && isNotNilOrEmpty(lists[currentlyChecked])

        const courseMeta: CourseMeta = JSON.parse(
          course?.metadata || '{}'
        ) as CourseMeta
        // const courseStartDate = new Date(course.accessible_from as string)
        const courseAccssibleFrom = course.accessible_from
          ? course.accessible_from.match(getOnlyDate)
          : new Date()
        const trimCourseAccssibleFrom = courseAccssibleFrom
          ? courseAccssibleFrom[0]
          : ''

        const courseEndDate = new Date(course.accessible_to as string)

        // const getOneDayBeforeCurrentlyChecked = () => {
        //   const currentlyCheckedDate = new Date(currentlyChecked)
        //   const previousDate = new Date(currentlyChecked)
        //   previousDate.setDate(currentlyCheckedDate.getDate() + 1)
        //   return previousDate
        // }
        const isWeekCourse = Number(courseMeta?.days_amount) === 7
        const isWithin =
          isFreeTrial || isWeekCourse
            ? isDateWithinInterval(new Date(currentlyChecked), {
                start: startDate,
                end: addDays(
                  new Date(startDate),
                  Number(courseMeta?.days_amount || '7') - 1
                )
              })
            : isDateWithinInterval(new Date(currentlyChecked), {
                start: new Date(trimCourseAccssibleFrom),
                end: courseEndDate
              })

        const dayEvents = lists[currentlyChecked]
        const isToday = today === currentlyChecked

        const generateTooltipContent = () => {
          if (isStartDate) {
            return 'Start Date'
          }
          if (isToday) {
            return "Today's Date"
          }
          if (isFinalDate) {
            return 'End date'
          }
          if (isMcatDate) {
            return 'MCAT Date'
          }
          return ''
        }

        const totalTime =
          dayEvents?.reduce((acc, { duration }) => {
            return acc + duration
          }, 0) || 0

        const isEmptyMcatDay =
          isNilOrEmpty(lists[currentlyChecked]) && isMcatDate

        const dayWrapperContent = (
          <DayWrapper
            data-testid='CalendarRow-DayWrapper'
            id={`table-cell-${currentlyChecked}`}
            today={today === currentlyChecked}
            isStartDate={isStartDate}
            isFinalDate={isFinalDate}
            isMcatDate={isMcatDate}
            isEmptyMcatDay={isEmptyMcatDay}
            className='day-wrapper'
          >
            <CellHeader data-testid='CalendarRow-CellHeader'>
              {isHolidayToday ? (
                <Tooltip
                  data-testid={`CalendarRow-Tooltip-${
                    dayHolidays[0]?.name || ''
                  }`}
                  tooltipContent={dayHolidays[0]?.name || ''}
                  id={currentlyChecked}
                >
                  <DayNumber
                    data-testid='CalendarRow-DayNumber'
                    holiday={isHolidayToday}
                    isStartDate={isStartDate}
                    isMcatDate={isMcatDate}
                  >
                    {dayNumber === 1
                      ? `${getMonthName(month)} ${dayNumber}`
                      : dayNumber}
                  </DayNumber>
                </Tooltip>
              ) : (
                <DayNumber
                  data-testid={`CalendarRow-DayNumber-${dayNumber}`}
                  today={today === currentlyChecked}
                  isStartDate={isStartDate}
                  isMcatDate={isMcatDate}
                >
                  {isStartDate || isFirstVisibleSunday || dayNumber === 1
                    ? `${getMonthName(month)} ${dayNumber}`
                    : dayNumber}
                  {!isEmptyMcatDay && isMcatDate && (
                    <MacatLabel data-testid='CalendarRow-McatLabel'>
                      MCAT
                    </MacatLabel>
                  )}
                </DayNumber>
              )}
              {totalTime > 0 && (isWithin || isFreeTrial) ? (
                <TotalTime data-testid='CalendarRow-TotalTime'>
                  <StyledClockIcon />
                  {formatMinutesToHoursAndMinutes(totalTime)}
                </TotalTime>
              ) : isMcatDate ? (
                <PartySaltyImage
                  data-testid='CalendarRow-PartSaltyImage'
                  id='party-salty'
                  src={McatDaySalty}
                  alt='Salty celebration'
                />
              ) : (
                <div />
              )}
            </CellHeader>
            {isEmptyMcatDay && (
              <McatEvent data-testid='CalendarRow-McatEvent'>
                <h4>{t('calendar.mcatDay')}</h4>
                <p>{t('calendar.mcatDayContents')}</p>
              </McatEvent>
            )}
            {isWithin || isFreeTrial ? (
              <CalendarDayEventsList
                data-testid='CalendarRow-CalendarDayEventsList'
                currentlyChecked={currentlyChecked}
                shouldDisplayEndDate={isFinalDate && !isFinalDayAndHaveEvents}
              />
            ) : (
              <div />
            )}
          </DayWrapper>
        )

        return (
          <TableCell
            data-testid='CalendarRow-TableCell'
            key={`main-table-cell-${currentlyChecked.replace(
              '00',
              `00${index}`
            )}`}
            disabled={!isWithin && !isMcatDate}
            isFinalDate={isFinalDate}
            isMcatDate={isMcatDate}
            isDayOff={false}
            isFinalDayAndHaveEvents={isFinalDayAndHaveEvents}
          >
            {isMcatDate || isFinalDate || isStartDate || isToday ? (
              <Tooltip
                data-testid='CalendarRow-Tooltip'
                id={`main-table-cell-${currentlyChecked.replace(
                  '00',
                  `00${index}`
                )}`}
                tooltipContent={generateTooltipContent()}
              >
                {dayWrapperContent}
              </Tooltip>
            ) : (
              dayWrapperContent
            )}
          </TableCell>
        )
      })}
    </TableContainer>
  )
}

export default CalendarRow

const TableContainer = styled.tr`
  display: flex;
  background-color: ${({ theme }) => theme.colors.main.grey300};
  gap: 1px;
  min-width: 100%;
`

const TableCell = styled.td<{
  disabled: boolean
  isFinalDate: boolean
  isMcatDate: boolean
  today: boolean
  isDayOff: boolean
  isFinalDayAndHaveEvents: boolean
}>`
  text-align: center;
  height: 106px;
  width: 155px;
  max-width: 155px;
  position: relative;
  transition: all 0.3s;
  vertical-align: top;
  display: flex;

  background-color: ${({ disabled }) => (disabled ? '#f2f2f2' : '#fff')};

  &:has(.day-wrapper) {
    display: grid !important;
    & > div > div {
      display: flex;
    }
  }
`

const DayWrapper = styled.div`
  border-radius: 6px;
  display: flex;
  height: 106px;
  position: relative;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;

  ${({ today }) =>
    today
      ? css`
          border: 1.5px solid #444;
        `
      : null}
  ${({ isStartDate }) =>
    isStartDate
      ? css`
          border: 1.5px solid #119b4c;
        `
      : null}

  ${({ isFinalDate }) =>
    isFinalDate
      ? css`
          border: 1.5px solid #e83e2e;
        `
      : null}
  ${({ isMcatDate }) =>
    isMcatDate
      ? css`
          border: ${({ theme }) =>
            `1.5px solid ${theme?.colors?.calendar.exam.orange.tag}`};
        `
      : null}
  ${({ isEmptyMcatDay }) =>
    isEmptyMcatDay
      ? css`
          background: ${({ theme }) => theme?.colors?.calendar.exam.orange.tag};
        `
      : null}
`

const CellHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DayNumber = styled.div<{
  today: string
  holiday: string
  isStartDate: boolean
  isMcatDate: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: relative;
  z-index: 13;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main.grey600};
  margin: 4px 0 0 4px;

  ${({ today, holiday, isStartDate, isMcatDate }) => {
    if (today && holiday) {
      return css`
        color: #fff;
        min-width: 22px;
        max-width: 22px;
        min-height: 22px;
        max-height: 22px;
        border-radius: 100%;
      `
    } else if (today) {
      return css`
        background-color: ${({ theme }) => theme.colors.main.black};
        color: #fff;
        min-width: 15px;
        padding: 0 4px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 10px;
      `
    } else if (holiday) {
      return css`
        cursor: help;
        color: red;
      `
    } else if (isStartDate) {
      return css`
        background-color: #119b4c;
        color: #fff;
        min-width: 15px;
        padding: 0 4px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 10px;
      `
    } else if (isMcatDate) {
      return css`
        background-color: ${({ theme }) =>
          theme?.colors?.calendar.exam.orange.tag};
        color: #fff;
        min-width: 15px;
        padding: 0 4px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 10px;
      `
    }
  }}
`

const TotalTime = styled.div`
  display: ${({ isMcatDate }) => (isMcatDate ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.main.grey900};
  padding-right: 8px;
  gap: 2px;
`

const StyledClockIcon = styled(ClockIcon)`
  font-size: 16px !important;
`

const McatEvent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  height: 106px;
  max-height: 106px;
  font-weight: bold;
  padding: 10px;
  border: ${({ theme }) =>
    `1px solid ${theme?.colors?.calendar.exam.orange.tag}`};
  background: ${({ theme }) => theme?.colors?.calendar.exam.orange.tag};
  margin: 0;
  color: ${({ theme }) => theme.colors.main.white};

  h4 {
    font-size: 14px;
    margin: 0;
  }

  p {
    font-size: 10px;
    font-weight: normal;
    padding-right: 10px;
  }
`

const PartySaltyImage = styled.img`
  position: absolute;
  top: -10px;
  right: 0;
  width: 120px;
  z-index: 11;
`

const MacatLabel = styled.div`
  margin-left: 5px;
`

import React, { useEffect } from 'react'
import { FetchFlashcardsListPayload } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { fetchArchiveFlashcardsListRoutine } from 'modules/flashcards/ducks/actions'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  getArchiveFlashcardsList,
  getArchiveFlashcardsPagination
} from 'modules/flashcards/ducks/selectors'
import styled from 'styled-components'
import qs from 'qs'
import { Pagination } from 'examkrackers-components'
import { propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import Flashcard from './Flashcard'

const ArchiveFlashcardsList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const flashcards = useSelector(getArchiveFlashcardsList)
  const flashcardsPagination = useSelector(getArchiveFlashcardsPagination)
  const {
    location: { search, pathname },
    replace
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const fetchFlashcards = (payload: FetchFlashcardsListPayload) => {
    dispatch(fetchArchiveFlashcardsListRoutine(payload))
  }

  const handlePageChange = page => {
    const newQuery = {
      ...parsedQuery,
      limit: {
        ...parsedQuery.limit,
        page
      }
    }
    replace(`${pathname}?${qs.stringify(newQuery, { encode: false })}`)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      fetchFlashcards({ query: search })
    } else {
      const defaultFilters = {
        limit: {
          take: 24,
          page: 1
        }
      }
      replace(
        `${pathname}${qs.stringify(defaultFilters, { addQueryPrefix: true })}`
      )
    }
  }, [search])

  return (
    <>
      {isNotNilOrEmpty(flashcards) ? (
        <FlashcardsListWrapper>
          {flashcards.map(flashcard => {
            return (
              <Flashcard
                withSelection
                key={flashcard.id}
                flashcard={flashcard}
              />
            )
          })}
        </FlashcardsListWrapper>
      ) : (
        <EmptyState>{t('flashcards.emptyState')}</EmptyState>
      )}
      <Pagination
        currentPage={propOr(1, 'page', flashcardsPagination)}
        totalPages={propOr(1, 'pagesTotal', flashcardsPagination)}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default ArchiveFlashcardsList

// (container width - paddings) / tiles per row
const TILE_WIDTH = (1260 - 3 * 40) / 4

const FlashcardsListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, ${TILE_WIDTH}px);
  column-gap: 40px;
  row-gap: 16px;

  margin: 20px 0;
  max-width: 100%;
`

const EmptyState = styled.div`
  width: 100%;
  margin-top: 20px;
`

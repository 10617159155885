import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../modules/auth/ducks/selectors'
import { pathOr, pipe } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from '../../utils/ramda'
// import { showToastRoutine } from 'modules/toast/ducks/actions'
// import { SEVERITY } from 'utils/toast'
import { GENERAL_SOCKET_EVENTS } from 'utils/socketEvents'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { Manager } from 'socket.io-client'

export const useSocketProvider = () => {
  const auth = useSelector<any>(selectAuth)
  const isLoggedIn = pipe(pathOr('', ['student', 'id']), isNotNilOrEmpty)(auth)
  // const dispatch = useDispatch()
  const isConnected = pathOr(false, ['socketClient', 'connected'], window)
  const [isLoaded, setIsLoaded] = useState(false)

  // const showToast = useCallback(
  //   payload => dispatch(showToastRoutine(payload)),
  //   [dispatch]
  // )

  const showError = () => {
    // @ts-ignore
    if (isNotNilOrEmpty(window.socketClient) && !isConnected && isLoggedIn) {
      // showToast({
      //   key: 'toast.socketError',
      //   severity: SEVERITY.error
      // })
      setIsLoaded(false)
    }
  }

  const loadSockets = () => {
    // @ts-ignore
    // setIsLoaded(window.socketClient.connected)
  }

  useEffect(() => {
    // @ts-ignore
    if (isNilOrEmpty(window.socketClient) && isLoggedIn) {
      const token = LocalStorageService.get(LOCAL_STORAGE_KEYS.token)
      const manager = new Manager(process.env.REACT_APP_SOCKET_CLIENT_URL, {
        reconnectionDelayMax: 1000
      })
      // @ts-ignore
      const socketClient = manager.socket('/', {
        auth: {
          token: token
        }
      })
      // @ts-ignore
      window.socketClient = socketClient
      setTimeout(loadSockets, 1000)
      socketClient.on(GENERAL_SOCKET_EVENTS.disconnect, showError)
      socketClient.on(GENERAL_SOCKET_EVENTS.connectError, showError)
      socketClient.on(GENERAL_SOCKET_EVENTS.error, showError)
    }

    // @ts-ignore
    if (isNotNilOrEmpty(window.socketClient) && isLoggedIn) {
      // @ts-ignore
      window.socketClient &&
        // @ts-ignore
        window.socketClient.on(GENERAL_SOCKET_EVENTS.connect, loadSockets)
      // @ts-ignore
    }

    return () => {
      // @ts-ignore
      if (isNotNilOrEmpty(window.socketClient)) {
        // @ts-ignore
        window.socketClient.off(GENERAL_SOCKET_EVENTS.disconnect, showError)
        // @ts-ignore
        window.socketClient.off(GENERAL_SOCKET_EVENTS.connect, loadSockets)
      }
    }
  }, [auth])

  useEffect(() => {
    // @ts-ignore
    const socketClient = window.socketClient

    return () => {
      if (isNotNilOrEmpty(socketClient)) {
        socketClient.disconnect()
        // @ts-ignore
        window.socketClient = null
      }
    }
  }, [])
  return { isLoaded }
}

export default useSocketProvider

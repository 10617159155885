import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, EditIcon, IconButton } from 'examkrackers-components'
import { Exam } from 'types'
import FormEditAccessPeriod from 'modules/exams/forms/FormEditAccessPeriod'
import styled from 'styled-components'

interface ModalChangeAccessPeriodProps {
  exam: Exam
}

// Modal that, when visible, allows an impersonated user to change
// the access period of an exam.
const ModalChangeAccessPeriod = (
  props: ModalChangeAccessPeriodProps
): JSX.Element => {
  // Get props
  const { exam } = props

  // Get i18n handler
  const { t } = useTranslation()

  // State for whether the modal is visible or not.
  const [open, setOpen] = React.useState<boolean>(false)

  // Handler function for opening the modal
  const handleOpen = () => setOpen(true)

  // Handler function for closing the modal
  const handleClose = () => setOpen(false)

  return (
    <React.Fragment>
      <ButtonContainer>
        <IconButton
          id={`${exam.id}-change-access-period`}
          name={`${exam.id}-change-access-period`}
          icon={<EditIcon />}
          onClick={handleOpen}
          color='secondary'
        />
      </ButtonContainer>
      <Modal
        title={t('exams.modal.changeAccessPeriod.title')}
        open={open}
        handleClose={handleClose}
      >
        <FormEditAccessPeriod exam={exam} onSubmitSuccess={handleClose} />
      </Modal>
    </React.Fragment>
  )
}

export default ModalChangeAccessPeriod

const ButtonContainer = styled.div`
  button {
    min-width: unset !important;
  }
`

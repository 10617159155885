import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBookById } from 'modules/books/ducks/selectors'
import styled from 'styled-components'
import { propOr, toLower, pipe, head, has, filter, propEq, pathOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import {
  fetchNotesForCourseRoutine,
  fetchNotesForParticularBookRoutine
} from 'modules/notes/ducks/actions'

import { isNotNilOrEmpty, isNilOrEmpty } from 'utils/ramda'
import { useTranslation } from 'react-i18next'
import Section from 'modules/notes/components/Section'
import {
  selectNotesList,
  getNotesPagination
} from 'modules/notes/ducks/selectors'
import FiltersNotes from 'modules/notes/components/FiltersNotes'
import { Pagination, PinIcon, EditIcon } from 'examkrackers-components'
import {
  fetchPinNotesForParticularBook,
  fetchBookPinNotesCount,
  fetchAllPinNotesCount,
  fetchCoursePinNotes
} from 'services/PinNotesService'
import { SORT_DIRECTION } from 'utils/table'
import eventEmitter from '../../../providers/eventEmitter'
import events from '../../books/utils/events'
const BookNotes = ({ id }) => {
  const { t } = useTranslation()
  const [active, setActive] = useState('notes')

  const [sectionsWithPinNotes, setSectionWithPinNotes] = useState([])
  const [paginationPinNotes, setPaginationPinNotes] = useState([])
  const [bookPinNotesCount, setBookPinNotesCount] = useState(0)

  const currentPinNotesSections: [] = propOr([], 'data', sectionsWithPinNotes)
  const book = useSelector(state => selectBookById(state, id))
  const bookId = propOr('', 'id', book)
  const currentNotesSections = useSelector(selectNotesList)
  const dispatch = useDispatch()
  const { push } = useHistory()
  const fetchNotesForBook = useCallback(
    (bookId, query) => {
      dispatch(
        fetchNotesForParticularBookRoutine({
          bookId: bookId,
          query
        })
      )
    },
    [dispatch]
  )
  const fetchNotesForCourse = useCallback(
    query => {
      dispatch(
        fetchNotesForCourseRoutine({
          query
        })
      )
    },
    [dispatch]
  )

  const getBookPinNotesCount = () => {
    const handleSuccess = result => {
      const count = pathOr(0, ['data', 'pin_notes_count'], result)
      setBookPinNotesCount(count)
    }
    const handleError = e => console.error(e)

    fetchBookPinNotesCount({ bookId }).then(handleSuccess).catch(handleError)
  }

  const getAllPinNotesCount = () => {
    const handleSuccess = result => {
      const count = pathOr(0, ['data', 'pin_notes_count'], result)
      setBookPinNotesCount(count)
    }
    const handleError = e => console.error(e)

    fetchAllPinNotesCount().then(handleSuccess).catch(handleError)
  }

  const handleFetchPinNotesCount = () => {
    if (isNotNilOrEmpty(bookId)) {
      getBookPinNotesCount()
    } else {
      getAllPinNotesCount()
    }
  }

  useEffect(() => {
    handleFetchPinNotesCount()
    eventEmitter.on(events.pinNoteDeleted, handleFetchPinNotesCount)

    return () => {
      eventEmitter.off(events.pinNoteDeleted, handleFetchPinNotesCount)
    }
  }, [bookId])

  const fetchPinNotesForBook = async query => {
    try {
      const result = await fetchPinNotesForParticularBook({
        // @ts-ignore
        bookId: bookId,
        query: qs.stringify({
          ...qs.parse(query, { ignoreQueryPrefix: true }),
          order: { by: 'chapter_order_part', dir: SORT_DIRECTION.asc }
        })
      })

      setSectionWithPinNotes(result.data)
      setPaginationPinNotes(result.data.meta)
    } catch (e) {
      console.error(e)
    }
  }

  const fetchPinNotesForCourse = async query => {
    try {
      const result = await fetchCoursePinNotes({
        query: qs.stringify({
          ...qs.parse(query, { ignoreQueryPrefix: true }),
          order: { by: 'chapter_order_part', dir: SORT_DIRECTION.asc }
        })
      })
      setSectionWithPinNotes(propOr([], 'data', result))
      setPaginationPinNotes(pathOr([], ['data', 'meta'], result))
    } catch (e) {
      console.error(e)
    }
  }

  const initialNote = pipe(head, propOr('', 'id'))(currentNotesSections)
  const initialPinNotes = pipe(
    head,
    propOr('', 'subchapter_id')
    // @ts-ignore
  )(currentPinNotesSections)
  const initialPinNoteBookId = pipe(
    head,
    propOr('', 'book_id')
    // @ts-ignore
  )(currentPinNotesSections)
  const initialPinNoteChapterOrder = pipe(
    head,
    propOr('', 'chapter_order')
  )(currentPinNotesSections)
  const initialPinNotePartOrder = pipe(
    head,
    propOr('', 'part')
  )(currentPinNotesSections)
  const {
    location: { search, pathname },
    replace
  } = useHistory()
  const isAllNotesPath = pathname === t('notes.allNotesPath')
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const presentActiveTab = propOr('', 'activeTab', parsedQuery)
  const isNotesActiveTab = presentActiveTab === 'notes'
  const isPinsActiveTab = presentActiveTab === 'pins'

  const newQuery = {
    ...parsedQuery,
    limit: {
      ...parsedQuery.limit,
      take: 9
    }
  }
  const initialQuery = qs.stringify({
    limit: { take: 9, page: 1 },
    order: { by: 'natural', dir: toLower(SORT_DIRECTION.asc) },
    sectionId: initialNote,
    bookId: initialPinNoteBookId
  })
  const selectedNoteId = propOr('', 'sectionId', parsedQuery)

  const isSelectedNote = pipe(
    // @ts-ignore
    filter(propEq('id', selectedNoteId)),
    head
    // @ts-ignore
  )(currentNotesSections)

  const isSelectedPinNote = pipe(
    // @ts-ignore
    filter(propEq('subchapter_id', selectedNoteId)),
    head
    // @ts-ignore
  )(currentPinNotesSections)

  useEffect(() => {
    if (
      isNotesActiveTab &&
      isNilOrEmpty(isSelectedNote) &&
      isNotNilOrEmpty(currentNotesSections)
    ) {
      const queryWithInitialNote = qs.stringify({
        ...parsedQuery,
        sectionId: initialNote,
        bookId: initialPinNoteBookId
      })
      push(`${pathname}?${queryWithInitialNote}`)
    } else if (
      isPinsActiveTab &&
      isNilOrEmpty(isSelectedPinNote) &&
      isNotNilOrEmpty(currentPinNotesSections)
    ) {
      const queryWithInitialNote = qs.stringify({
        ...parsedQuery,
        sectionId: initialPinNotes,
        bookId: initialPinNoteBookId,
        chapterOrder: initialPinNoteChapterOrder,
        partOrder: initialPinNotePartOrder
      })
      push(`${pathname}?${queryWithInitialNote}`)
    }
  }, [currentNotesSections, currentPinNotesSections])

  useEffect(() => {
    const hasPaginationInQuery = has('limit', parsedQuery)
    if (!hasPaginationInQuery) {
      push(`${pathname}?${initialQuery}`)
    }
  }, [])
  const notesPagination = useSelector(getNotesPagination)

  const notesCounter = propOr('', 'recordsTotal', notesPagination)

  const handleActiveTab = value => () => {
    const newQuery = qs.stringify({
      ...parsedQuery,
      limit: { take: 9, page: 1 },
      activeTab: value,
      bookId: initialPinNoteBookId,
      sectionId: initialPinNotes
    })
    push(`${pathname}?${newQuery}`)
    setActive(value)
  }

  const isNotesTabActive = active === 'notes'
  const isPinsTabActive = active === 'pins'

  useEffect(() => {
    const presentActiveTab: string = propOr('', 'activeTab', parsedQuery)
    setActive(presentActiveTab)
  }, [parsedQuery])

  useEffect(() => {
    if (isNotNilOrEmpty(bookId) && !isAllNotesPath && isNotesTabActive) {
      fetchNotesForBook(bookId, qs.stringify(newQuery))
    } else if (isNotNilOrEmpty(bookId) && !isAllNotesPath && isPinsTabActive) {
      fetchPinNotesForBook(qs.stringify(newQuery))
      fetchNotesForBook(bookId, qs.stringify(newQuery))
    } else if (isAllNotesPath) {
      fetchNotesForCourse(qs.stringify(newQuery))
      fetchPinNotesForCourse(qs.stringify(newQuery))
    }
  }, [bookId, search])

  const handlePageChange = page => {
    const newQuery = {
      ...parsedQuery,
      limit: {
        ...parsedQuery.limit,
        take: 9,
        page
      }
    }
    replace(`${pathname}?${qs.stringify(newQuery, { encode: false })}`)
  }
  return (
    <>
      <ContentWrapper>
        <TopWrapper>
          <SectionTitle>
            <TitleWrapper
              onClick={handleActiveTab('notes')}
              active={isNotesTabActive}
            >
              <TitleText>
                <IconWrapper>
                  <EditIcon />
                </IconWrapper>
                {t('notes.titleNote')}
              </TitleText>
              <NotesCounter>
                {/* @ts-ignore */}
                {`(${notesCounter})`}
              </NotesCounter>
            </TitleWrapper>
            <TitleWrapper
              onClick={handleActiveTab('pins')}
              active={isPinsTabActive}
            >
              <TitleText>
                <IconWrapper>
                  <PinIcon />
                </IconWrapper>
                {t('notes.titlePins')}
              </TitleText>
              <NotesCounter>({bookPinNotesCount})</NotesCounter>
            </TitleWrapper>
            <FiltersNotes id={id} active={isNotesTabActive} />
          </SectionTitle>
        </TopWrapper>
        <ChaptersWrapper>
          {isNotesTabActive && currentNotesSections.length === 0 && (
            <EmptyState>{t('notes.emptyStateForChapter')}</EmptyState>
          )}
          {isPinsTabActive && currentPinNotesSections.length === 0 && (
            <EmptyState>{t('notes.emptyStateForChapterPins')}</EmptyState>
          )}
          <div className='__cy-book-notes'>
            <Section
              book={book}
              activeTab={active}
              sectionsWithPinNotes={sectionsWithPinNotes}
            />
          </div>
          <PaginationWrapper>
            {isNotesTabActive ? (
              <Pagination
                currentPage={propOr(1, 'page', notesPagination)}
                totalPages={propOr(1, 'pagesTotal', notesPagination)}
                onPageChange={handlePageChange}
              />
            ) : (
              <Pagination
                currentPage={propOr(1, 'page', paginationPinNotes)}
                totalPages={propOr(1, 'pagesTotal', paginationPinNotes)}
                onPageChange={handlePageChange}
              />
            )}
          </PaginationWrapper>
        </ChaptersWrapper>
      </ContentWrapper>
    </>
  )
}
export default BookNotes
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 554px;
  height: 645px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  margin-left: 16px;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`
const ChaptersWrapper = styled.div`
  width: 522px;
  height: 46px;
  padding-right: 10px;
  box-sizing: border-box;
`
const SectionTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
  padding: 16px 16px 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-weight: bold;
`
const TopWrapper = styled.div`
  display: flex;
  padding-bottom: 12px;
`
const TitleWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-right: 14px;
  color: ${({ theme, active }) =>
    active ? theme.colors.main.primary500 : theme.colors.main.grey600};
  border-bottom: 2px solid
    ${({ theme, active }) =>
      active ? theme.colors.main.primary500 : theme.colors.main.grey300};
`
const TitleText = styled.div`
  margin-right: 5px;
  display: flex;
  justify-content: center;
`
const NotesCounter = styled.div``

const EmptyState = styled.div`
  font-size: 14px;
  height: 522px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const PaginationWrapper = styled.div`
  margin: 10px 0;
`
const IconWrapper = styled.div`
  margin-right: 5px;
`

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as R from 'ramda'

import SiteHeader from 'components/SiteHeader'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import { Loader } from 'examkrackers-components'
import TableScaledScoreReport from 'modules/diagnostics/components/TableScaledScoreReport'

// ExamScoreReport route
//
// This route displays the score report for an exam.
export const ExamScoreReport = (): JSX.Element => {
  // Get URL parameters object
  const params = useParams()

  // Get i18n handler
  const { t } = useTranslation()

  // Get React-Redux dispatcher
  const dispatch = useDispatch()

  const examDetails = useSelector(getExamDetails)
  const id: string = R.propOr('', 'id')(params)
  const title = R.pathOr('', ['exam', 'title'], examDetails)

  const fetchExamDetails = React.useCallback(
    payload => dispatch(fetchExamDetailsRoutine(payload)),
    [dispatch]
  )

  React.useEffect(() => {
    fetchExamDetails({ id })
  }, [params])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.exam.scoreReport', { title })}</title>
      </Helmet>
      {title ? (
        <Container>
          <SiteHeader
            text={t('report.title')}
            salty={
              <Salty>
                <img
                  src='/assets/illustrations/SaltyWooHoo.svg'
                  alt='salty-woo-hoo'
                />
              </Salty>
            }
          />
          <TableScaledScoreReport />
        </Container>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}

const Container = styled.div`
  position: relative;
`

const Salty = styled.div`
  z-index: 1;
  position: absolute;
  display: block;
  top: 0px;
  right: 72px;

  img {
    display: block;
    max-width: 105px;
    transform: scale(-1, 1);
  }
`

export default ExamScoreReport

import * as R from 'ramda'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'

export const useFlashCardsTooltips = () => {
  // @ts-ignore

  const setFlashCardsTooltip = () => {
    const xAxis = document.querySelector(
      `#apexchartsflashcardsChart .apexcharts-xaxis-texts-g`
    )
    const labels = Array.from(R.propOr([], 'children', xAxis))
    labels.forEach(el => {
      // @ts-ignore
      const titleElement = el.getElementsByTagName('title')[0]
      const title: string = R.propOr('', 'innerHTML')(titleElement)
      // @ts-ignore
      el.setAttribute('dataLabel', title)

      if (R.includes(title)) {
        // @ts-ignore
        el.style.cursor = 'pointer'
        // @ts-ignore
        el.onmouseenter = e =>
          eventEmitter.emit(events.plHoverEnter, {
            e,
            title
          })
        // @ts-ignore
        el.onmouseleave = e =>
          eventEmitter.emit(events.plHoverLeave, {
            e,
            title
          })
      }
    })
  }

  return setTimeout(setFlashCardsTooltip, 1000)
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ApexChart from 'react-apexcharts'
import { SBIncomeHistoryChart } from 'modules/dashboard/utils/SBIncomeHistoryChart'
import { isNotNilOrEmpty } from 'utils/ramda'
import * as R from 'ramda'

import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

import {
  SaltyBucksCounterIcon,
  SaltyBucksCalendarIcon,
  ArrowRightIcon
} from 'examkrackers-components'

import { pathOr } from 'ramda'
import { getSaltyBucksIncomeHistoryData } from 'services/BooksService'
import { randomSeconds } from '../../../utils/date'

import { contentTypes } from './TooltipChart'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'

import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'

const RANGE = ['previous_week', 'current_week', 'current_month', 'all_time']

export const SaltyBucksChart = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [currentData, setCurrentData] = useState(1)
  const {
    location: { pathname }
  } = useHistory()

  const isDashboard = pathname === PATHS.dashboard

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  useEffect(() => {
    getSaltyBucksIncomeHistoryData({
      range:
        RANGE[currentData] !== 'all_time'
          ? RANGE[currentData]
          : `${RANGE[currentData]}?limit[take]=1000`
    })
      .then(result => setData(pathOr([], ['data', 'data'], result)))
      .catch(console.error)
  }, [currentData])

  const getProgressTitleChart = () => {
    switch (currentData) {
      case 0:
        return t('dashboard.charts.lastWeek')
      case 1:
        return t('dashboard.charts.currentWeek')
      case 2:
        return t('dashboard.charts.currentMonth')
      case 3:
        return t('dashboard.charts.allTime')
      default:
        return t('dashboard.charts.currentWeek')
    }
  }

  const setXLabelsVisibility = () => {
    const customXLabels = document.querySelector(
      '#salty-bucks-chart .apexcharts-xaxis-texts-g'
    )
    const labels = R.propOr([], 'children', customXLabels)

    if (isNotNilOrEmpty(labels)) {
      // @ts-ignore
      const labelsList = Array.from(labels)

      labelsList.forEach((label, index: number, all) => {
        const number = index + 1
        const isMonthlyChart = currentData === 2
        const isAllTime = currentData === 3

        const isEveryNth = count =>
          number === 1 || number % count === 0 || all.length === number
        const lastDivisibleByNth = count =>
          Math.floor(all.length / count) * count
        const isBeforeLastIndivisibleByNth = count =>
          number === lastDivisibleByNth(count) &&
          lastDivisibleByNth(count) !== all.length

        const allTimeLabelDivider = Math.ceil(all.length / 5)

        if (isMonthlyChart) {
          // @ts-ignore
          label.style.visibility =
            isEveryNth(5) && !isBeforeLastIndivisibleByNth(5)
              ? 'visible'
              : 'hidden'
        } else if (isAllTime) {
          // @ts-ignore
          label.style.visibility =
            isEveryNth(allTimeLabelDivider) &&
            !isBeforeLastIndivisibleByNth(allTimeLabelDivider)
              ? 'visible'
              : 'hidden'
        } else {
          // @ts-ignore
          label.style.visibility = 'visible'
        }
      })
    }
  }

  const timedOutCustomXLabelsVisibility = () =>
    setTimeout(setXLabelsVisibility, 300)

  useEffect(() => {
    timedOutCustomXLabelsVisibility()
  }, [currentData])

  const handleLeftArrow = () => {
    currentData <= 0 ? setCurrentData(3) : setCurrentData(currentData - 1)
  }

  const handleRightArrow = () => {
    currentData >= 3 ? setCurrentData(0) : setCurrentData(currentData + 1)
  }

  const saltyBucksFlowChart = SBIncomeHistoryChart(data, isDashboard)

  const tooltipEvent = e => ({ e, type: contentTypes.saltyBucks })
  const handleEnterTooltip = e =>
    eventEmitter.emit(events.chartTooltipEnter, tooltipEvent(e))
  const handleLeaveTooltip = () => eventEmitter.emit(events.chartTooltipLeave)

  return (
    <>
      <ChartContainer id='salty-bucks-chart' currentData={currentData}>
        {isDashboard && (
          <Icon
            isDashboard={isDashboard}
            onMouseEnter={handleEnterTooltip}
            onMouseLeave={handleLeaveTooltip}
          >
            <SaltyBucksCounterIcon />
          </Icon>
        )}
        {isDashboard ? (
          <TotalProgressOnDashboard
            withBoxShadow={isDashboard}
            isDarkTheme={isDarkTheme}
          >
            <Calendar>
              <SaltyBucksCalendarIcon />
              <p>{getProgressTitleChart()}</p>
            </Calendar>
            <Arrows>
              <ArrowRightIcon
                style={{ transform: 'rotate(180deg)' }}
                onClick={handleLeftArrow}
              />
              <ArrowRightIcon onClick={handleRightArrow} />
            </Arrows>
          </TotalProgressOnDashboard>
        ) : (
          <TotalProgress withBoxShadow={isDashboard}>
            <Calendar>
              <SaltyBucksCalendarIcon />
              <p>{getProgressTitleChart()}</p>
            </Calendar>
            <Arrows>
              <ArrowRightIcon
                style={{ transform: 'rotate(180deg)' }}
                onClick={handleLeftArrow}
              />
              <ArrowRightIcon onClick={handleRightArrow} />
            </Arrows>
          </TotalProgress>
        )}
        {/* @ts-ignore */}
        <ApexChart
          // @ts-ignore
          options={saltyBucksFlowChart}
          series={saltyBucksFlowChart.series}
          type='line'
          width='404'
          height='168'
        />
      </ChartContainer>
    </>
  )
}

const ChartContainer = styled.div`
  position: relative;

  .apexcharts-tooltip {
    text-align: center;
    background: ${({ theme }) => theme.colors.backgrounds.main};
    color: ${({ theme }) => theme.colors.dashboard.sb.mainLine};
    padding: 4px;
    top: -1000px;
    /* align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-size: 11px;
  }

  .apexcharts-xaxis-label {
    visibility: ${({ currentData }) =>
      currentData === 4 || currentData === 3 ? 'hidden' : 'visible'};
  }

  .apexcharts-theme-false {
    transition: none !important;
  }
  .apexcharts-xcrosshairs {
    display: none;
  }

  svg {
    border-radius: 8px;
    box-shadow: '${({ theme }) => theme.shadows.mainShadow}';
    background: ${({ theme }) => theme.colors.backgrounds.main} !important;
    g.apexcharts-datalabels {
      transform: translateX(40px);
    }
  }

  .apexcharts-tooltip.apexcharts-theme-false {
    p {
      color: ${({ theme }) => theme.colors.dashboard.totalProgress};
    }
  }

  .apexcharts-line-series {
    animation: swipe-line ${randomSeconds(300, 2500)}ms ease-in;
  }

  @keyframes swipe-line {
    0% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  .arrow_box {
    font-size: 11px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    span {
      font-family: ${({ theme }) => theme.typography.fontFamily} !important;
      color: ${({ theme }) => theme.colors.main.text};
    }
  }
  .icon-ek-salty-bucks {
    font-size: 11px;
  }

  .apexcharts-yaxis-label {
    position: relative !important;
  }

  .apexcharts-title-text {
    fill: ${({ theme }) => theme.colors.main.heading} !important;
  }

  .apexcharts-xaxistooltip.apexcharts-xaxistooltip-bottom.apexcharts-theme-false.apexcharts-active {
    display: none;
  }
`

const Icon = styled.div`
  position: absolute;
  top: 10px;
  left: 14px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  color: ${({ theme }) => theme.colors.main.white};
  background: ${({ theme, isDashboard }) =>
    isDashboard ? theme.colors.dashboard.totalProgressBackground : 'none'};
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;

  svg {
    font-size: 16px;
    border-radius: unset;
    box-shadow: none;
    background: none !important;
  }
`

const TotalProgressOnDashboard = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  height: 24px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${({ theme, isDarkTheme }) =>
    isDarkTheme
      ? `inset -1px -1px 4px 0px ${theme.colors.dashboard.totalProgressShadowLight},
         inset 1px 1px 4px 0px ${theme.colors.dashboard.totalProgressShadowDark}`
      : 'none'};
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${({ theme, isDarkTheme }) =>
    !isDarkTheme
      ? theme.colors.main.grey200
      : theme.colors.dashboard.speedometer.white};
  color: ${({ theme }) => theme.colors.dashboard.sb.text};
  border: ${({ theme, withBoxShadow }) =>
    withBoxShadow ? 'none' : `1px solid ${theme.colors.dashboard.sb.text}`};
  font-size: 18px;
  width: 166px;

  svg {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};

    border-radius: 0px;
    box-shadow: none;
    background: none !important;
  }
  p {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};
    font-size: ${({ theme }) => theme.typography.fontSizeExtraSmall};
    margin-left: 5px;
    strong {
      color: ${({ theme, isDarkTheme }) =>
        !isDarkTheme
          ? theme.colors.dashboard.totalProgress
          : theme.colors.main.white};
      font-weight: 700;
    }
  }
`
const TotalProgress = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  height: 24px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${({ theme, withBoxShadow }) =>
    withBoxShadow
      ? `
    inset -1px -1px 4px 0px
      ${theme.colors.dashboard.totalProgressShadowLight},
    inset 1px 1px 4px 0px
      ${theme.colors.dashboard.totalProgressShadowDark}
  `
      : 'none'};
  padding: 5px 10px;
  border-radius: 5px;
  background: ${({ theme, withBoxShadow }) =>
    withBoxShadow ? theme.colors.dashboard.totalProgressBackground : 'none'};
  color: ${({ theme }) => theme.colors.dashboard.sb.text};
  border: ${({ theme, withBoxShadow }) =>
    withBoxShadow ? 'none' : `1px solid ${theme.colors.dashboard.sb.text}`};
  font-size: 18px;
  width: 166px;

  svg {
    border-radius: 0px;
    box-shadow: none;
    background: none !important;
  }
  p {
    color: ${({ theme }) => theme.colors.dashboard.totalProgress};
    font-size: ${({ theme }) => theme.typography.fontSizeExtraSmall};
    margin-left: 5px;
    strong {
      color: ${({ theme }) => theme.colors.dashboard.sb.text};
      font-weight: 700;
    }
  }
`

const Calendar = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.dashboard.totalProgress};
  }
`
const Arrows = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  svg {
    cursor: pointer;
    margin: 0 2px;
  }
`

import {
  fetchCategoryImagesRoutine,
  fetchOnboardingCategoriesRoutine
} from 'modules/onboarding/ducks/actions'
import { Action, OnboardingReducer } from 'types'
import { API_STATES } from 'utils/redux'

export const initialState: OnboardingReducer = {
  state: API_STATES.PRISTINE,
  categories: [],
  categoryImages: []
}

export default (state = initialState, action: Action): OnboardingReducer => {
  switch (action.type) {
    case fetchOnboardingCategoriesRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchOnboardingCategoriesRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchOnboardingCategoriesRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        categories: action.payload.data
      }
    case fetchCategoryImagesRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchCategoryImagesRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchCategoryImagesRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        categoryImages: action.payload.data
      }
    default:
      return { ...state }
  }
}

export default {
  exam: '/exam/:id',
  examExplanations: '/exam/:id/explanations',
  examExplanationsQuestion: '/exam/:id/explanations/:questionId',
  examScoreReport: '/exam/:id/score-report',
  examSectionAnswerSheet:
    '/exam/:id/score-report/answers-graph/:sectionId/answer-sheet',
  examSectionDiagnostic:
    '/exam/:id/score-report/answers-graph/:sectionId/diagnostic',
  examScoreProjection: '/exam/:id/score-report/score-projection',
  examSectionScoreProjection:
    '/exam/:id/score-report/score-projection/:sectionId',
  exams: '/exams',
  courseExams: '/course-exams',
  projections: '/projections',
  auth: '/auth/:token',
  authImpersonation: '/auth/impersonation/:token',
  unauthorized: '/unauthorized',
  notFound: '/not-found',
  logout: '/logout',
  previewExam: '/preview/:token/exam/:examId',
  previewBook:
    '/preview/:token/book/:bookId/chapter/:chapterOrder/part/:partOrder',
  dashboard: '/dashboard',
  book: '/books/:bookId/chapter/:chapterOrder/part/:partOrder',
  notes: '/notes/all',
  bookNotes: '/notes/:id',
  videos: '/videos',
  videoDetails: '/videos/:id',
  flashcards: '/flashcards',
  flashcardsBoxes: '/flashcards-boxes',
  flashcardsStudy: '/flashcards/study',
  selectCourse: '/select-course',
  dashboardLoading: '/dashboard/loading',
  examLoading: '/exam/loading',
  courseDeleted: '/course/not-found',
  coursePaused: '/course/paused',
  gameSaltyBlox: '/games/salty-blox',
  gameSaltyBloxLoader: '/games/salty-blox/:token',
  gameRespiration: '/games/aerobic-respiration',
  games: '/games/list',
  flashcardsArchive: '/flashcards/archive',
  leaderboard: '/leaderboard',
  courseTopics: '/course-topics',
  onboarding: '/onboarding',
  gameHangman: '/games/hangman',
  emptyAccount: '/empty-account',
  calendar: '/calendar',
  calendarDaysOff: '/calendar/days-off',
  calendarSettings: '/calendar/settings',
  calendarSettingsInit: '/calendar/settings-init',
  calendarRescheduleSettings: '/calendar/reschedule-settings',
  calendarReorder: '/calendar/reorder',
  calendarReorderForExpiration: '/calendar/reorder-expiration',
  calendarResetForExpiration: '/calendar/reset-expiration',
  calendarReset: '/calendar/reset',
  calendarResetNew: '/calendar/reset-new',
  calendarSetup: '/calendar/setup',
  calendarSetupExpiration: '/calendar/setup-expiration',
  calendarSetupFreeTrial: '/calendar/setup-free-trial'
}

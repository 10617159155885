import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { SaltyBucksCounterIcon, ArrowDownIcon } from 'examkrackers-components'
import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'
import SaltyBucksHistory from './SaltyBucksHistory'
import * as R from 'ramda'
import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'

import useOutsideClick from 'hooks/useOutsideClick'

import { useTranslation } from 'react-i18next'

import { formatNumberWithDecimal } from 'utils/numbers'

export const TotalSaltyBucks = (): JSX.Element => {
  const [isSaltyHistoryOpened, setIsSaltyHistoryOpened] = useState(false)
  const { t } = useTranslation()
  const user = useSelector(getStudent)
  const saltyBucksBalance: number = R.propOr(false, 'salty_bucks_balance', user)

  const handleIsOpened = () => {
    setIsSaltyHistoryOpened(true)
    eventEmitter.emit(events.saltyBucksHistoryOpened)
  }
  const handleIsClosed = () => setIsSaltyHistoryOpened(false)

  const containerRef = useRef(null)
  useOutsideClick(containerRef, handleIsClosed)

  return (
    <div>
      <SaltyBucksContainer isSaltyHistoryOpened={isSaltyHistoryOpened}>
        <SaltyBucksCounterIconContainer>
          <SaltyBucksCounterIcon />
        </SaltyBucksCounterIconContainer>
        <SaltyBucks>
          <TextWrapper>{t('navigation.saltyBucks')}</TextWrapper>
          <SaltyBucksValue>
            {formatNumberWithDecimal(saltyBucksBalance)}
          </SaltyBucksValue>
        </SaltyBucks>
        <ArrowIconContainer
          onClick={() => handleIsOpened()}
          isSaltyHistoryOpened={isSaltyHistoryOpened}
        >
          <ArrowDownIcon />
        </ArrowIconContainer>
      </SaltyBucksContainer>
      <SaltyHistoryWrapper>
        {isSaltyHistoryOpened && (
          <SaltyBucksHistory
            containerRef={containerRef}
            handleClose={handleIsClosed}
            isSaltyHistoryOpened={isSaltyHistoryOpened}
          />
        )}
      </SaltyHistoryWrapper>
    </div>
  )
}

export default TotalSaltyBucks

const SaltyBucksContainer = styled.div`
  display: flex;
  min-width: 105px;
  align-items: center;
  flex-direction: row;
  background: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.background};
  border-radius: 40px;
  height: 40px;
  padding: 3px 6px 3px 3px;
  box-shadow: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.boxShadow};
`
const SaltyBucksCounterIconContainer = styled.div`
  margin-right: 6px;
  display: flex;
  background: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.saltyBucksBackground};
  color: ${({ theme }) => theme.colors.topNav.totalSaltyBucksAndTimer.iconFont};
  font-size: ${({ theme }) => theme.typography.fontSizeBig};
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    color: ${({ theme }) =>
      theme.colors.topNav.totalSaltyBucksAndTimer.iconFont};
  }
`

const SaltyBucks = styled.div`
  align-content: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.main.text};
  font-size: ${({ theme }) => theme.typography.fontSizeSmall};
  font-weight: 600;
  padding: 4px 0;
  line-height: normal;
`

const SaltyBucksValue = styled.p`
  color: ${({ theme }) => theme.colors.main.heading};
  font-size: ${({ theme }) => theme.typography.fontSizeNormal};
  line-height: 14px;
  text-align: left;
`
const ArrowIconContainer = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.main.heading};
  padding-left: 20px;
  transform: ${({ isSaltyHistoryOpened }) =>
    isSaltyHistoryOpened && `translateY(-10%) rotateX(180deg)`};
`
const TextWrapper = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.main.text};
  font-weight: 400;
`
const SaltyHistoryWrapper = styled.div`
  position: relative;
`

import React from 'react'
import styled from 'styled-components'

export const SBPercentileRankPosition = ({ percentile }): JSX.Element => {
  return (
    <PercentileBarContainer>
      <PercentileRank>{percentile} %</PercentileRank>
      <PercentileBar>
        <UserPosition id='percentile-rank-position' percentile={percentile} />
      </PercentileBar>
    </PercentileBarContainer>
  )
}

const PercentileBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const PercentileRank = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.main.currency500};
  white-space: nowrap;
`

const PercentileBar = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.main.currency200};
  border: 1px solid ${({ theme }) => theme.colors.main.currency500};
  border-radius: 16px;
  width: 16px;
  height: 100%;
`

const UserPosition = styled.div`
  background: ${({ theme }) => theme.colors.main.text};
  border-radius: 2px;
  width: 12px;
  height: 2px;
  position: absolute;
  bottom: ${({ percentile }) => percentile}%;
  left: 1px;
`

export default SBPercentileRankPosition

import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import usePrevious from 'hooks/usePrevious'

import { getLearningTimePassedSeconds } from 'modules/learningTime/ducks/selectors'
import {
  setFlashcardsLearningTimePassedSecondsRoutine,
  handleUpdateFlashcardsLearningTimeRoutine
} from 'modules/learningTime/ducks/actions'

import { isNotNilOrEmpty } from 'utils/ramda'

export const FlashcardsLearningTime = ({
  flashcardId,
  isAvailable
}): JSX.Element => {
  const dispatch = useDispatch()

  const increaseFlashcardsLearningTimeSeconds = useCallback(
    () =>
      dispatch(setFlashcardsLearningTimePassedSecondsRoutine({ flashcardId })),
    [dispatch, flashcardId]
  )

  const handleUpdateFlashcardsLearningTime = useCallback(
    () => dispatch(handleUpdateFlashcardsLearningTimeRoutine({ flashcardId })),
    [dispatch, flashcardId]
  )

  // @ts-ignore
  const passedGeneralLearningTimeSeconds: number = useSelector(
    getLearningTimePassedSeconds
  )
  const prevGeneralLearningTimeSeconds = usePrevious(
    passedGeneralLearningTimeSeconds
  )

  useEffect(() => {
    if (
      prevGeneralLearningTimeSeconds !== passedGeneralLearningTimeSeconds &&
      isNotNilOrEmpty(flashcardId)
    ) {
      increaseFlashcardsLearningTimeSeconds()
    }
  }, [passedGeneralLearningTimeSeconds, flashcardId])

  useEffect(() => {
    if (isNotNilOrEmpty(flashcardId) && isAvailable) {
      window.addEventListener(
        'beforeunload',
        handleUpdateFlashcardsLearningTime
      )
    }

    return () => {
      if (isNotNilOrEmpty(flashcardId) && isAvailable) {
        handleUpdateFlashcardsLearningTime()
      }

      window.removeEventListener(
        'beforeunload',
        handleUpdateFlashcardsLearningTime
      )
    }
  }, [flashcardId, isAvailable])

  return <div />
}

export default FlashcardsLearningTime

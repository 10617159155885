import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import * as ExamTypesService from 'services/ExamTypesService'
import * as StudentsService from 'services/StudentService'
import { getStudent } from 'modules/auth/ducks/selectors'
import {
  fetchScoreProjectionDataRoutine,
  fetchExamTypeScoreProjectionsRoutine,
  saveComparedExamRoutine
} from 'modules/diagnostics/ducks/actions'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getComparedExamDetails } from '../../diagnostics/ducks/selectors'
import { errors, getErrorMessage, getErrorName } from 'utils/errors'

// ROUTINES
export const fetchExamTypesRoutine = createRoutine('FETCH_EXAM_TYPES')
export const fetchExamSubtypesRoutine = createRoutine('FETCH_EXAM_SUBTYPES')
export const fetchAvailableExamTypesRoutine = createRoutine(
  'FETCH_AVAILABLE_EXAM_TYPES'
)
export const changeTotalTargetScoreRoutine = createRoutine(
  'CHANGE_TARGET_SCORE_TOTAL'
)
export const changeSectionTargetScoreRoutine = createRoutine(
  'CHANGE_TARGET_SCORE_SECTION'
)
export const setCurrentExamTypeIdRoutine = createRoutine(
  'SET_CURRENT_EXAM_TYPE'
)

// ACTIONS

function* setCurrentExamTypeId({ payload }) {
  yield Effects.put(setCurrentExamTypeIdRoutine.success(payload))
}

function* fetchExamTypes() {
  try {
    const result = yield Effects.call(ExamTypesService.fetchExamsTypes)
    yield Effects.put(fetchExamTypesRoutine.success(result.data.data))
  } catch (e) {
    yield Effects.put(fetchExamTypesRoutine.failure(e))
  }
}

function* fetchAvailableExamTypes() {
  try {
    const result = yield Effects.call(
      ExamTypesService.fetchExamsTypesDictionary
    )
    yield Effects.put(fetchAvailableExamTypesRoutine.success(result.data))
  } catch (e) {
    yield Effects.put(fetchAvailableExamTypesRoutine.failure(e))
  }
}

function* fetchExamSubtypes({ payload }) {
  try {
    const result = yield Effects.call(
      ExamTypesService.fetchExamSubtypesByType,
      payload
    )
    yield Effects.put(fetchExamSubtypesRoutine.success(result.data))
  } catch (e) {
    yield Effects.put(fetchExamSubtypesRoutine.failure(e))
    console.error(e)
  }
}

function* changeTotalTargetScore({
  payload: {
    values = { id: '', value: '' },
    callback = () => {},
    onFailure = () => {}
  }
}) {
  yield Effects.put(changeTotalTargetScoreRoutine.request())

  try {
    const userDetails = yield Effects.select(getStudent)
    const examDetails = yield Effects.select(getExamDetails)
    const impersonating: boolean = R.propOr(
      false,
      'is_impersonated',
      userDetails
    )

    const currentExamId = R.pathOr('', ['exam', 'id'])(examDetails)

    if (impersonating) {
      throw new Error(errors.studentImpersonating)
    }

    const { id } = values

    yield Effects.call(StudentsService.changeTargetScore, values)
    yield Effects.put(changeTotalTargetScoreRoutine.success())

    if (isNotNilOrEmpty(currentExamId)) {
      yield Effects.put(fetchExamDetailsRoutine({ id: currentExamId }))
    }

    yield Effects.put(fetchScoreProjectionDataRoutine({ type_id: id }))
    yield Effects.put(fetchExamTypeScoreProjectionsRoutine({ id }))

    callback()

    yield Effects.put(
      showToastRoutine({
        key: 'toast.targetScoreChanged',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield Effects.put(changeTotalTargetScoreRoutine.failure(e))
    console.error(e)
    onFailure()

    // @ts-ignore
    const msg = getErrorMessage(e)
    // @ts-ignore
    const name = getErrorName(e)

    if (name === errors.entityInvalid || name === errors.targetScoreValue) {
      yield Effects.put(
        showToastRoutine({
          key: 'toast.custom',
          severity: SEVERITY.error,
          options: {
            content: msg
          }
        })
      )
      // @ts-ignore
    } else if (e.message === errors.studentImpersonating) {
      yield Effects.put(
        showToastRoutine({
          key: 'toast.targetScoreImpersonate',
          severity: SEVERITY.error
        })
      )
    } else {
      yield Effects.put(
        showToastRoutine({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error
        })
      )
    }
  }
}

function* changeSectionTargetScore({
  payload: { values = { id: '' }, callback = () => {}, onFailure = () => {} }
}) {
  yield Effects.put(changeSectionTargetScoreRoutine.request(values))

  try {
    const userDetails = yield Effects.select(getStudent)
    const examDetails = yield Effects.select(getExamDetails)
    const comparedExamDetails = yield Effects.select(getComparedExamDetails)
    const comparedExamId = R.pathOr('', ['exam', 'id'], comparedExamDetails)

    const impersonating: boolean = R.propOr(
      false,
      'is_impersonated',
      userDetails
    )
    const currentExamId = R.pathOr('', ['exam', 'id'])(examDetails)

    if (impersonating) {
      throw new Error(errors.studentImpersonating)
    }

    yield Effects.call(StudentsService.changeSectionTargetScore, values)
    yield Effects.put(
      changeSectionTargetScoreRoutine.success({
        score: R.propOr(0, 'value', values),
        order: R.propOr(0, 'value', values)
      })
    )

    if (isNotNilOrEmpty(currentExamId)) {
      yield Effects.put(fetchExamDetailsRoutine({ id: currentExamId }))
    }
    yield Effects.put(fetchScoreProjectionDataRoutine({ type_id: values.id }))
    yield Effects.put(fetchExamTypeScoreProjectionsRoutine({ id: values.id }))

    if (isNotNilOrEmpty(comparedExamId)) {
      yield Effects.put(
        saveComparedExamRoutine({ values: { examId: comparedExamId } })
      )
    }

    callback()

    yield Effects.put(
      showToastRoutine({
        key: 'toast.targetScoreChanged',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield Effects.put(changeSectionTargetScoreRoutine.failure(e))
    onFailure()

    // @ts-ignore
    const msg = getErrorMessage(e)
    // @ts-ignore
    const name = getErrorName(e)

    if (name === errors.entityInvalid || name === errors.targetScoreValue) {
      yield Effects.put(
        showToastRoutine({
          key: 'toast.custom',
          severity: SEVERITY.error,
          options: {
            content: msg
          }
        })
      )
      // @ts-ignore
    } else if (e.message === errors.studentImpersonating) {
      yield Effects.put(
        showToastRoutine({
          key: 'toast.targetScoreImpersonate',
          severity: SEVERITY.error
        })
      )
    } else {
      yield Effects.put(
        showToastRoutine({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error
        })
      )
    }
    console.error(e)
  }
}

// WATCHERS
export function* fetchExamTypesRoutineWatcher() {
  yield Effects.takeLatest(fetchExamTypesRoutine.TRIGGER, fetchExamTypes)
}

export function* setCurrentExamTypeIdWatcher() {
  yield Effects.takeLatest(
    setCurrentExamTypeIdRoutine.TRIGGER,
    setCurrentExamTypeId
  )
}

export function* fetchExamSubtypesWatcher() {
  yield Effects.takeLatest(fetchExamSubtypesRoutine.TRIGGER, fetchExamSubtypes)
}

export function* fetchAvailableExamTypesWatcher() {
  yield Effects.takeLatest(
    fetchAvailableExamTypesRoutine.TRIGGER,
    fetchAvailableExamTypes
  )
}

export function* changeTotalTargetScoreWatcher() {
  yield Effects.takeLatest(
    changeTotalTargetScoreRoutine.TRIGGER,
    changeTotalTargetScore
  )
}

export function* changeSectionTargetScoreWatcher() {
  yield Effects.takeLatest(
    changeSectionTargetScoreRoutine.TRIGGER,
    changeSectionTargetScore
  )
}

// SAGAS
export const examTypesSagas = [
  Effects.fork(setCurrentExamTypeIdWatcher),
  Effects.fork(fetchExamTypesRoutineWatcher),
  Effects.fork(fetchExamSubtypesWatcher),
  Effects.fork(changeTotalTargetScoreWatcher),
  Effects.fork(changeSectionTargetScoreWatcher),
  Effects.fork(fetchAvailableExamTypesWatcher)
]

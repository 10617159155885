import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import QRCode from 'react-qr-code'

import { createGladiatorGameToken } from 'services/AuthService'

import GladiatorsLogo from 'assets/images/GladiatorsLogo.svg'
import { pathOr } from 'ramda'
import { DATE_FORMATS, formatDate, isDateBeforeGivenDate } from 'utils/date'

export const GladiatorGameQR = (): JSX.Element => {
  const [timer, setTimer] = useState(0)
  const [redirectUrl, setRedirectUrl] = useState('')
  const [expiryDate, setExpiryDate] = useState(new Date())

  const handleSetToken = () => {
    const handleSuccess = response => {
      const token = pathOr('', ['data', 'token'], response)
      const expiresAt = pathOr('', ['data', 'expires_at'], response)
      setRedirectUrl(
        `${process.env.REACT_APP_GLADIATOR_GAME_URL}/qr/login/${token}`
      )
      // @ts-ignore
      setExpiryDate(expiresAt)
    }
    const handleError = e => {
      console.error(e)
      setRedirectUrl(`${process.env.REACT_APP_GLADIATOR_GAME_URL}/register`)
    }

    createGladiatorGameToken().then(handleSuccess).catch(handleError)
  }

  const handleCount = () => setTimer(prevState => prevState + 1)

  useEffect(() => {
    const timer = setInterval(handleCount, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    const expiresAt = formatDate(expiryDate, DATE_FORMATS.dashedWithTime)
    const now = formatDate(new Date(), DATE_FORMATS.dashedWithTime)
    const isValid = isDateBeforeGivenDate(now, expiresAt)

    if (!isValid) {
      handleSetToken()
    }
  }, [timer])

  return (
    <GameContainer>
      <GameLogo src={GladiatorsLogo} />
      <QRCode
        size={256}
        style={{ height: 'auto', maxWidth: '100px', width: '100px' }}
        value={redirectUrl}
        viewBox='0 0 256 256'
      />
    </GameContainer>
  )
}

export default GladiatorGameQR

const GameContainer = styled.div`
  border-radius: 8px;
  padding: 32px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  background: ${({ theme }) => theme.colors.backgrounds.main} !important;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

const GameLogo = styled.img`
  max-width: 150px;
`

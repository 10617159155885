import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import SelectProjectionType from 'modules/diagnostics/components/SelectProjectionType'
import TableScoreProjection from 'modules/diagnostics/components/TableScoreProjection'
import ScoreProjectionGraph from 'modules/diagnostics/components/GraphScoreProjection'
import ProjectionSummary from 'modules/diagnostics/components/ProjectionSummary'
import SiteHeader from 'components/SiteHeader'

import { getScoreProjectionData } from 'modules/diagnostics/ducks/selectors'
import { ScoreProjectionDataEntity } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'

import { Heading1 } from 'examkrackers-components'

export const Projections = (): JSX.Element => {
  // Get i18n handler
  const { t } = useTranslation()
  const data: ScoreProjectionDataEntity[] = useSelector(getScoreProjectionData)
  const hasData = isNotNilOrEmpty(data)

  const GraphPlaceholder = (
    <PlaceholderContainer>
      <div>
        <img src='/assets/illustrations/SaltyTime.svg' />
      </div>
      <Heading1 bold>{t('diagnostics.projections_pending')}</Heading1>
    </PlaceholderContainer>
  )

  return (
    <>
      <Helmet>
        <title>{t('pages.exam.projections')}</title>
      </Helmet>
      <SiteHeader
        excludeBorder
        text={t('diagnostics.projections')}
        salty={
          <SaltyHeroFlying>
            <img
              src='/assets/illustrations/SaltyHeroFlying2.svg'
              alt='salty-hero-flying'
            />
          </SaltyHeroFlying>
        }
      />
      <Container>
        <LeftContainer>
          <SelectProjectionType />
          {hasData && (
            <ShadowedContainer>
              <TableScoreProjection />
            </ShadowedContainer>
          )}
        </LeftContainer>
        <RightContainer>
          <Summary>
            <ShadowedContainer>
              <ProjectionSummary />
            </ShadowedContainer>
          </Summary>
          {hasData && (
            <ShadowedContainer>
              <ScoreProjectionGraph />
            </ShadowedContainer>
          )}
          {!hasData && (
            <PlaceholderWrapper>{GraphPlaceholder}</PlaceholderWrapper>
          )}
        </RightContainer>
      </Container>
    </>
  )
}

export default Projections

const Container = styled.div`
  margin-top: 3rem;
  display: flex;
  height: calc(100% - 3rem - 61px);
`

const PlaceholderWrapper = styled.div`
  margin-top: 95px;
  display: flex;
  justify-content: center;
`

const PlaceholderContainer = styled.div`
  width: 100%;
  max-width: 640px;
  max-height: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0 3rem 1.5rem;
  box-shadow: ${props => props.theme.shadows.mainShadow};
  border-radius: ${props => props.theme.shape.borderRadiusBig};
  background-color: ${props => props.theme.colors.backgrounds.main};

  img {
    margin-right: 1rem;
  }
`

const ShadowedContainer = styled.div`
  box-shadow: ${props => props.theme.shadows.mainShadow};
  background-color: ${props => props.theme.colors.backgrounds.main};
  border-radius: ${props => props.theme.shape.borderRadiusBig};
`

const RightContainer = styled.div`
  flex-grow: 1;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const LeftContainer = styled.div`
  margin-right: 2rem;
`

const SaltyHeroFlying = styled.div`
  z-index: 1;
  position: absolute;
  display: block;
  top: 0px;
  left: -20px;

  img {
    display: block;
    position: absolute;
    max-width: 307px;
  }
`

const Summary = styled.div`
  margin-bottom: 2rem;
`

import React, { useCallback, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import qs from 'qs'

import { Input } from 'examkrackers-components'
import { searchCourseTopicsListRoutine } from '../ducks/actions'
import { getCourseTopics } from 'services/CourseTopicsService'
import { pathOr, propOr, sortBy, prop } from 'ramda'
import { ICourseTopic } from 'types/courseTopics'
import { isNilOrEmpty } from 'utils/ramda'
import useOutsideClick from 'hooks/useOutsideClick'

export const CourseTopicsFilters = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [results, setResults] = useState<ICourseTopic[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef(null)

  const handleSearch = useCallback(
    searchedTopic => dispatch(searchCourseTopicsListRoutine({ searchedTopic })),
    [dispatch]
  )

  const handleClose = () => {
    setIsOpen(false)
    setResults([])
  }
  useOutsideClick(containerRef, handleClose)

  const handleFetchResults = searchPhrase => {
    const handleSuccess = response => {
      const data: ICourseTopic[] = pathOr([], ['data', 'data'], response)
      setResults(sortBy(prop('order'), data))
      setIsOpen(true)
    }
    const handleError = () => {}

    const query = qs.stringify({
      limit: { take: 20, page: 1 },
      filter: { search: searchPhrase }
    })

    if (searchPhrase.length >= 3) {
      getCourseTopics({ query }).then(handleSuccess).catch(handleError)
    }
  }

  const debounceHandler = useCallback(
    debounce((e: any) => handleFetchResults(e.target.value), 500),
    []
  )

  const handleSelectResult = topic => () => {
    handleSearch(topic)
    handleClose()
  }

  const renderResults = results.map(topic => (
    <ResultContainer
      key={`topic-search-result-${topic.id}`}
      onClick={handleSelectResult(topic)}
    >
      {propOr('', 'topic', topic)}
    </ResultContainer>
  ))

  return (
    <Container>
      <SearchContainer ref={containerRef}>
        <Input
          size='small'
          type='search'
          onChange={debounceHandler}
          placeholder={t('topics.searchPlaceholder')}
        />
        {isOpen && (
          <ResultsContainer>
            {isNilOrEmpty(results) && (
              <EmptyStateContainer>
                {t('topics.searchEmptyState')}
              </EmptyStateContainer>
            )}
            {renderResults}
          </ResultsContainer>
        )}
      </SearchContainer>
    </Container>
  )
}

export default CourseTopicsFilters

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const SearchContainer = styled.div`
  position: relative;
`

const ResultsContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: ${props => props.theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  z-index: ${({ theme }) => theme.zIndex.menu};
  max-width: 300px !important;
  min-width: 300px !important;
`

const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`

const ResultContainer = styled.div`
  text-align: left;
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.selects.option.font};
  background-color: ${({ theme }) => theme.colors.selects.option.background};
  line-height: 1.5;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.selects.option.backgroundActive};
  }
`

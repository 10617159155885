import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'
import { ExamToolsBar } from 'examkrackers-components'
import { ExamPageConfigProps, RootReducer } from 'types'

import PauseHandler from './PauseHandler'
import { resetTextSelection } from 'utils/exam'

import Timer from 'modules/exam/components/Timer/Timer'
import { toggleQuestionFlag, saveResourceHtml } from 'services/ExamsService'
import {
  addQuestionStateRoutine,
  fetchExamDetailsRoutine,
  fetchQuestionHtmlRoutine
} from 'modules/exam/ducks/actions'

import {
  getQuestion,
  getSectionIdByCurrentPageId,
  getStepHtml
} from 'modules/exam/ducks/selectors'

import { isNotNilOrEmpty } from 'utils/ramda'
import { generateSaveQuestionHtmlPayloads } from 'modules/exam/utils/step'

interface ExamTopComponentsProps {
  currentPage: string
  setCurrentPage: (id: string) => void
  handleTimeEnd: () => any
  currentPageConfig: ExamPageConfigProps
}

export const ExamTopComponents = ({
  setCurrentPage,
  currentPage,
  currentPageConfig,
  handleTimeEnd
}: ExamTopComponentsProps): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()
  const id: string = R.propOr('', 'id')(params)

  const [secondsLeft, setSecondsLeft] = React.useState(0)

  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, currentPage)
  )
  const sectionIdByCurrentPageId = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, currentPage)
  )
  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, currentPage)
  )

  const updateQuestionStep = React.useCallback(
    (payload: any) => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const fetchExamDetails = React.useCallback(
    (id: string) => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  const fetchQuestionHtml = React.useCallback(
    (id: string) => dispatch(fetchQuestionHtmlRoutine({ id })),
    [dispatch]
  )

  const isTutorialPage = R.includes('tutorial', currentPage)

  const handleFlagClick = async () => {
    updateQuestionStep({
      ...questionState,
      flagged: !questionState.flagged
    })

    if (!isTutorialPage) {
      const handleSuccess = () => fetchExamDetails(id)
      const handleError = (e: Error) => console.error(e)
      toggleQuestionFlag({ id: currentPage })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  const handleSaveStepHtml = async () => {
    if (isNotNilOrEmpty(sectionIdByCurrentPageId)) {
      const payloads = generateSaveQuestionHtmlPayloads(stepHtml)
      // @ts-ignore
      await Promise.all(
        // @ts-ignore
        payloads.map(payload => saveResourceHtml({ id, ...payload }))
      )
      if (!currentPageConfig.nextPageId?.includes('review')) {
        fetchQuestionHtml(String(currentPageConfig.nextPageId))
      }
    }

    resetTextSelection()
  }

  const isFinishPage = R.includes('end-exam', currentPage)

  return (
    <>
      <Timer
        setSecondsLeft={setSecondsLeft}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        currentPageConfig={currentPageConfig}
        onTimeEnd={handleTimeEnd}
      />
      <ExamToolsBar
        highlightButton={currentPageConfig.highlightButtonVisibility}
        strikethroughButton={currentPageConfig.strikethroughButtonVisibility}
        flagForReviewButton={currentPageConfig.flagForReviewButtonVisibility}
        onFlagClick={handleFlagClick}
        isFlagged={questionState.flagged}
        afterHtmlChangeCallback={handleSaveStepHtml}
      />
      {!isFinishPage && (
        <PauseHandler
          currentPageConfig={currentPageConfig}
          secondsLeft={secondsLeft}
          currentPage={currentPage}
        />
      )}
    </>
  )
}

export default ExamTopComponents

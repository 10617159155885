import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  fetchFlashcardsStudyListRoutine,
  fetchPlvlStatsRoutine
} from 'modules/flashcards/ducks/actions'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { isNotNilOrEmpty } from 'utils/ramda'

export const QueryListenerFlashcardsStudy = (): JSX.Element => {
  const dispatch = useDispatch()
  const {
    location: { search }
  } = useHistory()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const query = qs.stringify(
    {
      ...parsedQuery,
      shuffle: false
    },
    { addQueryPrefix: true }
  )

  const fetchFlashcardStudy = useCallback(
    query => dispatch(fetchFlashcardsStudyListRoutine({ query })),
    [dispatch]
  )

  const fetchPlvlStats = useCallback(
    query => dispatch(fetchPlvlStatsRoutine({ query })),
    [dispatch]
  )

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      fetchFlashcardStudy(query)
    }
  }, [search])

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      fetchPlvlStats(query)
    }
  }, [])

  return <div />
}

export default QueryListenerFlashcardsStudy

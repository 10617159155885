import { getTheme } from 'examkrackers-components'
import * as R from 'ramda'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/diagnostics/utils/events'
import { EXAM_SECTION_DIFFICULTY_CHART_SERIES } from './ExamSectionDifficultyChart'

export const EXAM_SECTION_TIME_CHART_SERIES = {
  passagesWorking: 'Passage Working',
  passageReading: 'Passage Reading',
  timePerQuestion: 'Time Per Question',
  averageTimePerQuestion: 'Time Per Question - Avg',
  placeholder: 'placeholder'
}

export const getExamSectionTimeSeries = series => [
  {
    name: EXAM_SECTION_TIME_CHART_SERIES.passagesWorking,
    type: 'area',
    data: series.passagesWorking || []
  },
  {
    name: EXAM_SECTION_TIME_CHART_SERIES.passageReading,
    type: 'area',
    data: series.passagesReading || []
  },
  {
    name: EXAM_SECTION_TIME_CHART_SERIES.timePerQuestion,
    type: 'line',
    data: series.timePerQuestion || []
  },
  {
    name: EXAM_SECTION_TIME_CHART_SERIES.averageTimePerQuestion,
    type: 'line',
    data: series.averageTimePerQuestion || []
  },
  // this is a placeholder to have the same chart as difficulty chart
  // which is mixed - I need it to have x axis in the same dimensions
  {
    name: EXAM_SECTION_DIFFICULTY_CHART_SERIES.placeholder,
    type: 'bar',
    data: []
  }
]

export const ExamSectionTimeChart = data => {
  const theme = getTheme()
  const { categories, maxPassageSeconds, maxQuestionSeconds, isDifficultyOn } =
    data

  return {
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      borderColor: theme.colors.main.grey300,
      position: 'front',
      yaxis: {
        lines: {
          show: true
        }
      },
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    subtitle: {
      margin: 0
    },
    title: {
      margin: 0
    },
    chart: {
      offsetY: 25,
      parentHeightOffset: 0,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      },
      events: {
        click: (event, chartContext, config) => {
          const dataPointIndex = R.propOr(-1, 'dataPointIndex', config)

          // @ts-ignore
          if (dataPointIndex >= 0) {
            eventEmitter.emit(events.questionClick, Number(dataPointIndex) + 1)
          }
        },
        mouseMove: (event, chartContext, config) => {
          const dataPointIndex = R.propOr(-1, 'dataPointIndex', config)

          // @ts-ignore
          if (dataPointIndex >= 0) {
            eventEmitter.emit(events.questionHover, Number(dataPointIndex) + 1)
          }
        },
        updated: () => eventEmitter.emit(events.chartUpdated)
      }
    },
    stroke: {
      curve: ['stepline', 'stepline', 'straight', 'straight'],
      dashArray: [0, 0, 0, 4],
      width: 2
    },
    colors: [
      theme.colors.diagnostics.diagnostic.passageWorking.chart,
      theme.colors.diagnostics.diagnostic.passageReading.chart,
      theme.colors.main.text,
      theme.colors.main.text
    ],
    legend: {
      show: false,
      itemMargin: {
        horizontal: 0,
        vertical: 0
      }
    },
    xaxis: {
      title: {
        text: isDifficultyOn ? '' : 'Questions',
        offsetX: 0,
        offsetY: -10,
        style: {
          fontSize: '11px',
          color: theme.colors.main.text
        }
      },
      tickPlacement: 'on',
      overwriteCategories: categories,
      labels: {
        show: !isDifficultyOn,
        hideOverlappingLabels: false,
        rotate: 0,
        style: {
          fontSize: '14px'
        }
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: [
      // passageWorking
      {
        forceNiceScale: true,
        showForNullSeries: true,
        decimalsInFloat: 0,
        seriesName: EXAM_SECTION_TIME_CHART_SERIES.passageReading,
        max: maxPassageSeconds,
        show: true,
        opposite: true,
        labels: {
          offsetY: 5,
          style: {
            fontSize: '11px',
            color: theme.colors.diagnostics.diagnostic.passageWorking.font
          }
        },
        title: {
          offsetX: 5,
          text: 'Time Working Passage (Seconds)',
          style: {
            color: theme.colors.diagnostics.diagnostic.passageWorking.font,
            fontSize: '11px'
          }
        }
      },
      // passageReading
      {
        forceNiceScale: true,
        decimalsInFloat: 0,
        seriesName: EXAM_SECTION_TIME_CHART_SERIES.passagesWorking,
        max: maxPassageSeconds,
        show: false
      },
      // timePerQuestion
      {
        forceNiceScale: true,
        showForNullSeries: true,
        decimalsInFloat: 0,
        seriesName: EXAM_SECTION_TIME_CHART_SERIES.timePerQuestion,
        max: maxQuestionSeconds,
        show: true,
        labels: {
          offsetY: 5,
          style: {
            fontSize: '11px',
            color: theme.colors.main.text
          }
        },
        title: {
          offsetX: -5,
          text: 'Time Working Question (Seconds)',
          style: {
            color: theme.colors.main.text,
            fontSize: '11px'
          }
        }
      },
      // averageTimePerQuestion
      {
        forceNiceScale: true,
        decimalsInFloat: 0,
        seriesName: EXAM_SECTION_TIME_CHART_SERIES.averageTimePerQuestion,
        show: false
      },
      // placeholder
      {
        forceNiceScale: true,
        decimalsInFloat: 0,
        seriesName: EXAM_SECTION_TIME_CHART_SERIES.placeholder,
        show: false
      }
    ],
    tooltip: {
      followCursor: true,
      custom: () =>
        '<div class="tooltip-question-view">Click line to view question</div>',
      marker: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: 10
      }
    }
  }
}

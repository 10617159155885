import React from 'react'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as R from 'ramda'
import PATHS from 'utils/paths'
import { studentLogoutRoutine } from 'modules/auth/ducks/actions'

import { Heading1, WarningReversed } from 'examkrackers-components'

// Logout route
//
// This route is shown when a user logs out.
export const Logout = (): JSX.Element => {
  // Get i18 handler
  const { t } = useTranslation()

  // Get redux dispatcher
  const dispatch = useDispatch()

  const logoutStudent = React.useCallback(
    () => dispatch(studentLogoutRoutine()),
    [dispatch]
  )

  React.useEffect(() => {
    // Revoke JWT
    logoutStudent()

    window.location.replace(
      R.propOr(PATHS.unauthorized, 'REACT_APP_HOME_URL', process.env)
    )
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.general.logout')}</title>
      </Helmet>
      <AuthContainer>
        <WarningReversed />
        <div className='title'>
          <Heading1>{t('logout.title')}</Heading1>
          <Heading1>{t('logout.subtitle')}</Heading1>
        </div>
      </AuthContainer>
    </React.Fragment>
  )
}

const AuthContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

export default Logout

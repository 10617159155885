import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  InputField,
  Modal,
  SingleSelect,
  AddIcon,
  EditIcon
} from 'examkrackers-components'
import {
  createNewCalendarEvent,
  getCalendar,
  updateCalendarEvent
} from 'services/CalendarService'
import { useTranslation } from 'react-i18next'
import {
  validateAddEventField,
  validateAddEventValue
} from 'modules/calendar/schema'
import SaltyImage from 'assets/images/CalendarSalty.svg'

import { hourOptions, minuteOptions, statusOptions } from 'utils/calendar'
import MultiLevelSelect from 'components/MultiLevelSelect/MultiLevelSelect'
import { getExamOptions } from 'modules/calendar/utils/examOptions'
import { getEventPayload } from 'modules/calendar/utils/calendarEventPayload'
import DateInput from 'components/DateInput/DateInput'
import { isNotNilOrEmpty } from 'utils/ramda'
import { CalendarContext } from 'hooks/CalendarContext'

import { getOnlyDate } from 'utils/date'

const initialValues = {
  title: '',
  id: '',
  type: null,
  status: statusOptions()[0].value,
  event_date: new Date(),
  duration: 15,
  description: ''
}

interface AddEventModalValues {
  title: string
  id: string
  status: string
  type: string | null
  event_date: string | Date
  duration: number
  description: string
}

interface AddEventModalProps {
  onSelect?: () => void
  setUpdateStatusEvent?: any
  event?: any
}

const AddOrEditEventModal = ({
  onSelect,
  event,
  setUpdateStatusEvent
}: AddEventModalProps) => {
  const { calendar, attachNewEventToDate, updateEvent, lists } =
    useContext(CalendarContext)
  const { t } = useTranslation()
  const [values, setValues] = useState<AddEventModalValues>(initialValues)
  const [open, setOpen] = useState(false)
  const [valid, setValid] = useState(true)
  const [options, setOptions] = useState([])

  const [durationHours, setDurationHours] = useState(0)
  const [durationMinutes, setDurationMinutes] = useState(0)

  const minDateWithoutTime = calendar?.start_at.match(getOnlyDate)
  const minDate = minDateWithoutTime ? minDateWithoutTime[0] : ''

  const maxDateWithoutTime = calendar?.exam_at.match(getOnlyDate)
  const maxDate = maxDateWithoutTime ? maxDateWithoutTime[0] : ''
  const isEditMode = isNotNilOrEmpty(event)

  const isChosenElementContained = (chosenElement, allElements) => {
    // Iterate over each date in the allElements object
    for (const date in allElements) {
      // Check if the current date has any events
      if (allElements[date].length > 0) {
        // Iterate over each event in the current date
        for (const event of allElements[date]) {
          // Compare the chosen element with the current event

          if (event.title === chosenElement.title) {
            return true // Element found
          }
        }
      }
    }
    return false // Element not found
  }

  useEffect(() => {
    if (event && open) {
      setValues({
        title: event.title,
        id: event.id,
        type: event.type,
        status: event.status,
        event_date: event.event_date,
        duration: event.duration,
        description: event.description
      })
    }
  }, [event, open])

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    if (isEditMode && onSelect) {
      onSelect()
    }
    setValues(initialValues)
    setOpen(false)
  }
  const isLaterThanToday = date => {
    const today = new Date()
    return date > today
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleChangeStatus = option => {
    setValues(prev => ({ ...prev, status: option.value }))
  }

  const handleChangeDescription = option => {
    setValues(prev => ({ ...prev, description: option.value }))
  }

  useEffect(() => {
    setValues(prev => ({ ...prev, duration: durationHours + durationMinutes }))
  }, [durationHours, durationMinutes])

  const handleCalendarFetch = () => {
    getCalendar()
      .then(resp => {
        const calendarDates = resp.data.calendar
        const startDate = new Date(calendarDates.start_at)

        if (isLaterThanToday(startDate)) {
          setValues(prev => {
            return {
              ...prev,
              event_date: startDate
            }
          })
        }

        getExamOptions(setOptions)
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    validateAddEventValue(values, setValid)
  }, [values, open])

  useEffect(() => {
    handleCalendarFetch()
  }, [open])

  const addEventToDate = async () => {
    const payload = getEventPayload(values)

    try {
      // @ts-ignore
      const response = await createNewCalendarEvent(payload)

      if ([200, 201].includes(response.status)) {
        attachNewEventToDate(response.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleEventUpdate = async () => {
    try {
      // @ts-ignore
      const response = await updateCalendarEvent({
        ...values,
        id: event.id
      })

      if ([200, 201].includes(response.status)) {
        updateEvent({ oldEvent: event, newEvent: response.data })
        setUpdateStatusEvent && setUpdateStatusEvent(response.data.status)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleEventTypeChange = eventTypeOption => {
    if (eventTypeOption) {
      const type = eventTypeOption.type
      const duration = eventTypeOption?.duration
        ? eventTypeOption?.duration
        : type === 'custom_event_type'
        ? 375
        : 15

      setValues(prev => ({
        ...prev,
        title: type === 'custom' ? '' : eventTypeOption.label,
        duration,
        type,
        id: eventTypeOption.value,
        description: ''
      }))
    } else {
      setValues(initialValues)
    }
  }

  return (
    <>
      {isEditMode ? (
        <StyledEditIcon
          data-testid='AddOrEditEventModal-StyledEditIcon'
          onClick={handleOpen}
        />
      ) : (
        <Button
          data-testid='AddOrEditEventModal-Button-Add'
          variant='contained'
          color='secondary'
          onClick={handleOpen}
        >
          <AddWrapper>
            <AddIcon data-testid='AddOrEditEventModal-AddIcon' />
            {t('calendar.addCustomEventModal.button')}
          </AddWrapper>
        </Button>
      )}
      <Modal
        data-testid='AddOrEditEventModal-Modal'
        open={open}
        title={
          isEditMode
            ? t('calendar.addCustomEventModal.editTitle')
            : t('calendar.addCustomEventModal.addTitle')
        }
        handleClose={handleClose}
      >
        <ModalContent data-testid='AddOrEditEventModal-ModalContent'>
          <Row data-testid='AddOrEditEventModal-Row'>
            {!isEditMode && (
              <div>
                <DropdownLabel data-testid='AddOrEditEventModal-DropdownLabel'>
                  {t('calendar.addCustomEventModal.eventType')}
                </DropdownLabel>
                <DropdownWrapper>
                  <MultiLevelSelect
                    data-testid='AddOrEditEventModal-MultiLevelSelect'
                    options={options}
                    onChange={handleEventTypeChange}
                  />
                </DropdownWrapper>
              </div>
            )}
            {values.type === 'custom' ? (
              <div>
                <LabelWrapper data-testid='AddOrEditEventModal-LabelWrapper-eventTitle'>
                  {t('calendar.addCustomEventModal.eventTitle')}
                </LabelWrapper>
                <InputField
                  data-testid='AddOrEditEventModal-InputField'
                  type='text'
                  value={values.title}
                  name='title'
                  disabled={[
                    'full_length_exam',
                    'section_exam',
                    'custom_event_type'
                  ].includes(values.type || '')}
                  onChange={handleValueChange}
                  t={t}
                  validate={validateAddEventField(values)}
                  errorText={t('calendar.errorTitle')}
                />
              </div>
            ) : (
              <div style={{ height: '88px' }} />
            )}
          </Row>
          <Row>
            <div>
              <LabelWrapper data-testid='AddOrEditEventModal-LabelWrapper-schedule'>
                {t('calendar.addCustomEventModal.schedule')}
              </LabelWrapper>
              <DateInput
                data-testid='AddOrEditEventModal-DateInput'
                name='event_date'
                // @ts-ignore
                value={values.event_date}
                validate={validateAddEventField(values)}
                onChange={handleValueChange}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
            <SaltyImageWrapper data-testid='AddOrEditEventModal-SaltyImageWrapper'>
              <img src={SaltyImage} alt='calendar salty' />
            </SaltyImageWrapper>
          </Row>
          <SelectContainer>
            <SelectorContainer>
              <LabelWrapper data-testid='AddOrEditEventModal-LabelWrapper-duration'>
                {t('calendar.addCustomEventModal.duration')}
              </LabelWrapper>
              <SelectWrapper>
                <SingleSelect
                  data-testid='AddOrEditEventModal-SingleSelect-hoursOptions'
                  removeMargin
                  label=''
                  size='small'
                  options={hourOptions}
                  disabled={[
                    'full_length_exam',
                    'section_exam',
                    'custom_event_type'
                  ].includes(values.type || '')}
                  onChange={option => setDurationHours(option.value)}
                  value={hourOptions.find(
                    option =>
                      option.value === values.duration - (values.duration % 60)
                  )}
                />
                <SingleSelect
                  data-testid='AddOrEditEventModal-SingleSelect-minuteOptions'
                  removeMargin
                  label=''
                  size='small'
                  options={minuteOptions}
                  disabled={[
                    'full_length_exam',
                    'section_exam',
                    'custom_event_type'
                  ].includes(values.type || '')}
                  onChange={option => setDurationMinutes(option.value)}
                  value={minuteOptions.find(
                    option => option.value === values.duration % 60
                  )}
                />
              </SelectWrapper>
            </SelectorContainer>
            <SelectorContainer>
              <LabelWrapper data-testid='AddOrEditEventModal-LabelWrapper-status'>
                {t('calendar.addCustomEventModal.status')}
              </LabelWrapper>
              <SelectWrapper>
                <SingleSelect
                  data-testid='AddOrEditEventModal-SingleSelect-statusOptions'
                  removeMargin
                  label=''
                  size='small'
                  options={statusOptions(['archived'])}
                  onChange={handleChangeStatus}
                  value={statusOptions(['archived']).find(
                    option => option.value === values.status
                  )}
                />
              </SelectWrapper>
            </SelectorContainer>
          </SelectContainer>
          <LabelWrapper data-testid='AddOrEditEventModal-LabelWrapper-description'>
            {t('calendar.addCustomEventModal.description')}
          </LabelWrapper>
          <TextArea
            onChange={e => handleChangeDescription(e.target)}
            defaultValue={values.description}
            disabled={[
              'full_length_exam',
              'section_exam',
              'custom_event_type'
            ].includes(values.type || '')}
          />
          <ButtonsWrapper>
            <Button
              data-testid='AddOrEditEventModal-Button-cancel'
              variant='outlined'
              onClick={handleClose}
            >
              {t('calendar.button.cancel')}
            </Button>
            <Button
              data-testid='AddOrEditEventModal-Button-save'
              onClick={() => {
                if (isEditMode) {
                  handleEventUpdate()
                } else {
                  addEventToDate()
                }

                handleClose()
              }}
              disabled={!valid}
            >
              {t('calendar.button.save')}
            </Button>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddOrEditEventModal

const ModalContent = styled.div`
  .react-datepicker {
    left: 18px;
    top: 6px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 20px;
`

const SelectContainer = styled.div`
  display: flex;
  gap: 16px;
`
const SelectorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  margin-top: 25px;
  width: 100%;
  height: ${({ theme }) => theme.dimensions.inputHeight};
  display: flex;
  gap: 5px;
`
const LabelWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: -20px;
  text-align: left;
`

const DropdownLabel = styled.div`
  margin-top: 4px;
  margin-bottom: 5px;
  text-align: left;
`

const TextArea = styled.textarea`
  padding: 10px;
  width: 100%;
  height: 80px;
  margin-top: 25px;
  resize: none;
  border: ${({ theme }) => `1px solid  ${theme.colors.main.grey300}`};
  border-radius: 4px;
`
const AddWrapper = styled.div`
  display: flex;
  align-items: center;
`

const DropdownWrapper = styled.div``

const Row = styled.div`
  display: flex;
  gap: 15px;
  position: relative;

  & > div {
    width: 100%;
    text-align: left;
  }
`

const SaltyImageWrapper = styled.div`
  text-align: center !important;
`

const StyledEditIcon = styled(EditIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding-top: 1px;
  transition: all 0.3s;
  color: ${({ theme }) => theme.colors.main.primary500};

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary700};
  }
`

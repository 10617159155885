import {
  pathOr,
  propOr,
  pipe,
  ifElse,
  propEq,
  juxt,
  equals,
  find,
  always,
  not,
  or,
  applySpec,
  identity,
  omit,
  flatten,
  map,
  prop,
  pluck,
  isNil,
  splitWhen,
  drop,
  nth,
  filter,
  head,
  last,
  includes
} from 'ramda'
import {
  ExamQuestionDetails,
  ExamSectionDetails,
  ExamDetails,
  QuestionState
} from 'types'
import { transformById } from 'utils/redux'
import QuestionPage from 'modules/exam/components/QuestionPages/QuestionPage'
import QuestionExplanationPage from 'modules/exam/components/QuestionPages/QuestionExplanationPage'
import SectionIntro from 'modules/exam/components/SectionPages/SectionIntro'
import SectionReview from 'modules/exam/components/SectionPages/SectionReview'
import SectionBreak from 'modules/exam/components/SectionPages/SectionBreak'
import PAGE_IDS from './pageIds'
import { QUESTION_STATUSES, REVIEW_FILTER_TYPES } from './exam'

const generatePageConfigFromSection = examSummary => section => ({
  name: 'Section Introduction',
  id: section.id,
  nextPageId: pipe(
    find(propEq('id', section.id)),
    propOr([], 'questions'),
    head,
    propOr('', 'id')
  )(examSummary),
  prevPageId: null,
  timerVisibility: true,
  pagesVisibility: false,
  totalPages: pathOr(0, ['sectionLength', 'amount'])(section),
  currentPage: 0,
  highlightButtonVisibility: false,
  strikethroughButtonVisibility: false,
  flagForReviewButtonVisibility: false,
  navigationButtons: {
    endExamButton: false,
    endSectionButton: false,
    endTestDayCertification: false,
    navigationButton: false,
    nextButton: true,
    previousButton: false,
    periodicTableButton: false,
    reviewAllButton: false,
    reviewFlaggedButton: false,
    reviewIncompleteButton: false,
    reviewScreenButton: false,
    answerSheetButton: false
  },
  component: SectionIntro
})

const generatePageConfigFromQuestion =
  (filterType = REVIEW_FILTER_TYPES.all) =>
  examSummary =>
  section =>
  question => {
    const prevId = findPrevPageId(examSummary)(
      section.id,
      filterType,
      question.id
    )

    return {
      name: 'Question',
      id: question.id,
      nextPageId: findNextPageId(examSummary)(
        section.id,
        filterType,
        question.id
      ),
      prevPageId: prevId,
      timerVisibility: true,
      pagesVisibility: true,
      totalPages: pathOr(0, ['sectionLength', 'amount'])(section),
      currentPage: propOr(0, 'order')(question),
      highlightButtonVisibility: true,
      strikethroughButtonVisibility: true,
      flagForReviewButtonVisibility: true,
      navigationButtons: {
        endExamButton: false,
        endSectionButton: false,
        endTestDayCertification: false,
        navigationButton: true,
        nextButton: true,
        previousButton: prevId ? not(equals(question.order, 1)) : false,
        periodicTableButton: or(
          equals(section.order, 1),
          equals(section.order, 3)
        ),
        reviewAllButton: false,
        reviewFlaggedButton: false,
        reviewIncompleteButton: false,
        reviewScreenButton: true,
        answerSheetButton: false
      },
      component: QuestionPage
    }
  }

export const generateExplanationsPageConfigFromQuestion =
  (filterType = REVIEW_FILTER_TYPES.all) =>
  examSummary =>
  section =>
  question => {
    const prevId = findPrevPageId(examSummary)(
      section.id,
      filterType,
      question.id
    )
    const nextId = findNextPageId(examSummary)(
      section.id,
      filterType,
      question.id
    )
    const showNext = not(includes('section-review', nextId))

    return {
      id: question.id,
      nextPageId: nextId,
      prevPageId: prevId,
      timerVisibility: false,
      pagesVisibility: true,
      totalPages: pathOr(0, ['sectionLength', 'amount'])(section),
      currentPage: propOr(0, 'order')(question),
      highlightButtonVisibility: false,
      strikethroughButtonVisibility: false,
      flagForReviewButtonVisibility: true,
      navigationButtons: {
        endExamButton: false,
        endSectionButton: false,
        endTestDayCertification: false,
        navigationButton: false,
        nextButton: showNext,
        previousButton: prevId ? not(equals(question.order, 1)) : false,
        periodicTableButton: or(
          equals(section.order, 1),
          equals(section.order, 3)
        ),
        reviewAllButton: false,
        reviewFlaggedButton: false,
        reviewIncompleteButton: false,
        reviewScreenButton: false,
        answerSheetButton: true
      },
      component: QuestionExplanationPage
    }
  }

const generateSectionReviewPageConfig = examSummary => section => ({
  name: 'Section Review',
  id: `section-review-${section.id}`,
  nextPageId: findBreakIdOrEndExam(examSummary)(section.id, section.order),
  prevPageId: null,
  timerVisibility: true,
  pagesVisibility: false,
  totalPages: 0,
  currentPage: 0,
  highlightButtonVisibility: false,
  strikethroughButtonVisibility: false,
  flagForReviewButtonVisibility: false,
  navigationButtons: {
    endExamButton: false,
    endSectionButton: true,
    endTestDayCertification: false,
    navigationButton: false,
    nextButton: false,
    previousButton: false,
    periodicTableButton: false,
    reviewAllButton: true,
    reviewFlaggedButton: true,
    reviewIncompleteButton: true,
    reviewScreenButton: false,
    answerSheetButton: false
  },
  component: SectionReview
})

const generateSectionBreakPageConfig = examSummary => section => ({
  name: 'Section Break',
  id: `section-break-${section.id}`,
  nextPageId: findNextSectionId(examSummary)(section.order),
  prevPageId: null,
  timerVisibility: false,
  pagesVisibility: false,
  totalPages: 0,
  currentPage: 0,
  highlightButtonVisibility: false,
  strikethroughButtonVisibility: false,
  flagForReviewButtonVisibility: false,
  navigationButtons: {
    endExamButton: false,
    endSectionButton: false,
    endTestDayCertification: false,
    navigationButton: false,
    nextButton: false,
    previousButton: false,
    periodicTableButton: false,
    reviewAllButton: false,
    reviewFlaggedButton: false,
    reviewIncompleteButton: false,
    reviewScreenButton: false,
    answerSheetButton: false
  },
  component: SectionBreak
})

const getNextQuestion = id =>
  pipe(
    // @ts-ignore
    splitWhen(propEq('id', id)),
    drop(1),
    flatten,
    nth(1)
  )

const getPrevQuestion = id =>
  pipe(
    // @ts-ignore
    splitWhen(propEq('id', id)),
    head,
    last
  )

const filterQuestions = filterType => questions => {
  switch (filterType) {
    case REVIEW_FILTER_TYPES.flagged:
      return filter(propEq('is_flagged', true))(questions)
    case REVIEW_FILTER_TYPES.incomplete:
      return filter(
        pipe(propEq('question_status', QUESTION_STATUSES.complete), not)
      )(questions)
    case REVIEW_FILTER_TYPES.all:
    default:
      return questions
  }
}

const findNextPageId = examSummary => (sectionId, filterType, id) =>
  pipe(
    find(propEq('id', sectionId)),
    propOr([], 'questions'),
    filterQuestions(filterType),
    getNextQuestion(id),
    ifElse(isNil, always(`section-review-${sectionId}`), propOr(null, 'id'))
  )(examSummary)

const findNextSectionId = examSummary => order =>
  pipe(
    find(propEq('order', order + 1)),
    ifElse(isNil, always(PAGE_IDS.endExam), propOr(null, 'id'))
  )(examSummary)

const findBreakIdOrEndExam = examSummary => (id, order) =>
  pipe(
    find(propEq('order', order + 1)),
    ifElse(isNil, always(PAGE_IDS.endExam), always(`section-break-${id}`))
  )(examSummary)

export const findFirstPageId = examSummary => (sectionId, filterType) =>
  pipe(
    find(propEq('id', sectionId)),
    propOr([], 'questions'),
    filterQuestions(filterType),
    head,
    ifElse(isNil, always(`section-review-${sectionId}`), propOr(null, 'id'))
  )(examSummary)

const findPrevPageId = examSummary => (sectionId, filterType, id) =>
  pipe(
    find(propEq('id', sectionId)),
    propOr([], 'questions'),
    filterQuestions(filterType),
    getPrevQuestion(id),
    propOr(null, 'id')
  )(examSummary)

// @ts-ignore
const pullOutSectionAndQuestions: (arg: ExamSectionDetails) => {
  section: object
  questions: ExamQuestionDetails[]
} = applySpec({
  section: pipe(identity, omit(['questions'])),
  questions: propOr([], 'questions')
})

const createSectionConfigsArray = examSummary =>
  map(generatePageConfigFromSection(examSummary))(examSummary)

const createQuestionConfigsArray = filterType => examSummary =>
  map(
    pipe(pullOutSectionAndQuestions, config =>
      map(
        generatePageConfigFromQuestion(filterType)(examSummary)(config.section)
      )(config.questions)
    )
  )(examSummary)

const createExplanationsConfigsArray = filterType => examSummary =>
  map(
    pipe(pullOutSectionAndQuestions, config =>
      map(
        generateExplanationsPageConfigFromQuestion(filterType)(examSummary)(
          config.section
        )
      )(config.questions)
    )
  )(examSummary)

const createSectionReviewConfigsArray = examSummary =>
  map(generateSectionReviewPageConfig(examSummary))(examSummary)

const createSectionBreakConfigsArray = examSummary =>
  map(generateSectionBreakPageConfig(examSummary))(examSummary)

export const generateDynamicExamPagesConfigs = (
  filterType = REVIEW_FILTER_TYPES.all
) =>
  pipe(
    juxt([
      createSectionConfigsArray,
      createQuestionConfigsArray(filterType),
      createSectionReviewConfigsArray,
      createSectionBreakConfigsArray
    ]),
    flatten
  )

export const generateDynamicExamExplanationPagesConfigs = (
  filterType = REVIEW_FILTER_TYPES.all
) => pipe(createExplanationsConfigsArray(filterType), flatten)

const gatherQuestionIds: (x: ExamSectionDetails) => string[] = pipe(
  propOr([], 'questions'),
  (questions: ExamQuestionDetails[]) => pluck('id', questions)
)

const gatherSectionIdAsArray: (x: ExamSectionDetails) => string[] = pipe(
  prop('id'),
  Array.of
)

const mapAllPageIds = map(
  pipe(juxt([gatherQuestionIds, gatherSectionIdAsArray]), flatten)
)

const mapSectionReviewIds = map(
  pipe(
    (section: ExamSectionDetails) => prop('id', section),
    Array.of,
    id => `section-review-${id}`
  )
)

export const getSectionPagesIds = pipe(
  juxt([mapAllPageIds, mapSectionReviewIds]),
  flatten
)

export const getAllExamQuestionsStates = (examDetails: ExamDetails) => {
  const generateQuestionsState: (x: ExamDetails) => QuestionState[] = pipe(
    propOr([], 'questions'),
    (sections: ExamSectionDetails[]) => pluck('questions', sections),
    flatten,
    map((question: ExamQuestionDetails) =>
      applySpec({
        flagged: (q: ExamQuestionDetails) => propOr(false, 'is_flagged', q),
        answer: (q: ExamQuestionDetails) => propOr(null, 'answer', q),
        id: (q: ExamQuestionDetails) => propOr('', 'id', q),
        status: (q: ExamQuestionDetails) => propOr('', 'question_status', q)
      })(question)
    )
  )

  const allQuestions: QuestionState[] = generateQuestionsState(examDetails)

  return transformById('id')(allQuestions)
}

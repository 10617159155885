import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as R from 'ramda'
import qs from 'qs'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { getBookByOriginalId } from 'modules/books/ducks/selectors'
import { showToastRoutine } from 'modules/toast/ducks/actions'

import { SEVERITY } from 'utils/toast'
import PATHS from 'utils/paths'
import { COURSE_TYPES } from 'utils/courses'

import { Course } from 'types'
import { propOr } from 'ramda'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import { isNotNilOrEmpty } from 'utils/ramda'

export const RedirectHandler = (): JSX.Element => {
  const dispatch = useDispatch()
  const currentCourse: Course = useSelector(getCurrentCourse)
  const params = useParams()
  const {
    push,
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const bookId: string = R.propOr('', 'bookId')(params)
  const partOrder: number = R.pipe(R.propOr('', 'partOrder'), Number)(params)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  const book = useSelector(state => getBookByOriginalId(state, bookId))
  const isBookAvailableInFreeTrial = R.propOr(true, 'is_free_trial', book)

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const isFreeTrialCourse = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  useEffect(() => {
    if (isFreeTrialCourse && !isBookAvailableInFreeTrial) {
      showToast({
        key: 'toast.bookNotAvailableInFreeTrial',
        severity: SEVERITY.error
      })
      push(PATHS.dashboard)
    }

    if (isFreeTrialCourse && isBookAvailableInFreeTrial && partOrder >= 100) {
      showToast({
        key: 'toast.partNotAvailableInFreeTrial',
        severity: SEVERITY.error
      })
      push(PATHS.dashboard)
    }

    if (isFreeTrialCourse && isBookAvailableInFreeTrial && chapterOrder !== 1) {
      showToast({
        key: 'toast.chapterNotAvailableInFreeTrial',
        severity: SEVERITY.error
      })
      push(PATHS.dashboard)
    }
  }, [book, currentCourse, params])

  const sectionId = propOr(
    null,
    BOOK_VIEWER_PARAMS.sectionIdContext,
    parsedQuery
  )

  const contentId = propOr(
    null,
    BOOK_VIEWER_PARAMS.bookContentIdContext,
    parsedQuery
  )

  const scrollToPosition = () => {
    const hasContentId = isNotNilOrEmpty(contentId)
    const hasSectionId = isNotNilOrEmpty(sectionId)

    if (hasContentId) {
      const bookContentAnchor = document.getElementById(
        `book-content-anchor-${contentId}`
      )
      bookContentAnchor &&
        bookContentAnchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (hasSectionId) {
      const subchapterAnchor = document.getElementById(
        `subchapter-anchor-${sectionId}`
      )
      subchapterAnchor &&
        subchapterAnchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  useEffect(() => {
    setTimeout(scrollToPosition, 3000)
  }, [])

  return <div />
}

export default RedirectHandler

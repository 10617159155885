import React, { useState } from 'react'
import styled from 'styled-components'

import { Modal, Button, Input, ImageIcon } from 'examkrackers-components'

export const ModalImageUploader = ({ sketchRef }) => {
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState('')

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleUploadImage = e => {
    e.preventDefault()
    sketchRef.current.addImg(url)
    handleClose()
  }

  const handleInputChange = e => setUrl(e.target.value)

  return (
    <>
      <Button id='color-picker' size='small' onClick={handleOpen}>
        <ImageIcon />
      </Button>
      <Modal title='' open={open} handleClose={handleClose}>
        <ImageUploadContainer>
          <Input onChange={handleInputChange} placeholder='Image url' />
          <Button onClick={handleUploadImage} color='secondary' size='small'>
            Submit
          </Button>
        </ImageUploadContainer>
      </Modal>
    </>
  )
}

export default ModalImageUploader

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-top: 1rem;
  }
`

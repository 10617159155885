import React from 'react'

import { ExamContent } from 'examkrackers-components'

export const BlankPage = (props): JSX.Element => {
  return (
    <ExamContent>
      <div />
    </ExamContent>
  )
}

export default BlankPage

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { propOr } from 'ramda'

import { Button, Heading1, Modal } from 'examkrackers-components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategoryImagesRoutine } from 'modules/onboarding/ducks/actions'
import { selectCategoryImages } from 'modules/onboarding/ducks/selectors'
import { CategoryImage, ModalProps } from 'types/onboarding'
import { isNotNilOrEmpty } from 'utils/ramda'
import { storageUrl } from 'utils/onboarding'
interface ModalCategoryImagesProps extends ModalProps {
  categoryId: string
  categoryTitle: string
}

export const ModalCategoryImages = ({
  open,
  onClose,
  categoryId,
  categoryTitle
}: ModalCategoryImagesProps) => {
  const { t } = useTranslation()
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [isSlideBack, setIsSlideBack] = useState(false)
  const [isSlideNext, setIsSlideNext] = useState(false)

  const dispatch = useDispatch()
  const images = useSelector(selectCategoryImages)

  useEffect(() => {
    dispatch(fetchCategoryImagesRoutine({ categoryId }))
  }, [categoryId])

  const handleNextStep = () => {
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length)
    setIsSlideBack(false)
    setIsSlideNext(true)
  }

  const handlePreviousStep = () => {
    setCurrentImageIndex(
      prevIndex => (prevIndex - 1 + images.length) % images.length
    )
    setIsSlideBack(true)
    setIsSlideNext(false)
  }

  return (
    <Modal open={open} handleClose={onClose} fullscreen>
      {isNotNilOrEmpty(images) ? (
        <Container>
          <HeadingContainer>
            <Heading1 bold>{t('onboarding.title')}&nbsp;/&nbsp;</Heading1>
            <Heading1 bold>{categoryTitle}&nbsp;/&nbsp;</Heading1>
            <Step bold>
              Step {propOr('-', 'order', images[currentImageIndex])}
            </Step>
          </HeadingContainer>
          <ContentContainer>
            {images.map((image: CategoryImage) => {
              return (
                <Image
                  key={image.id}
                  src={storageUrl + propOr('', 'image_url', image)}
                  alt={image.title}
                  loading='lazy'
                  isVisible={image.id === images[currentImageIndex].id}
                  isSlideBack={isSlideBack}
                  isSlideNext={isSlideNext}
                />
              )
            })}
          </ContentContainer>
          <ButtonsContainer>
            <Button color='tertiary' onClick={onClose}>
              {t('onboarding.imageModal.goToOnboarding')}
            </Button>
            <Indicators>
              {images.map((image: CategoryImage) => (
                <Indicator
                  key={image.id}
                  isActive={image.id === images[currentImageIndex].id}
                />
              ))}
            </Indicators>
            <NavigationButtons>
              <Button
                color='tertiary'
                onClick={handlePreviousStep}
                disabled={currentImageIndex === 0}
              >
                {t('onboarding.imageModal.back')}
              </Button>
              {currentImageIndex < images.length - 1 ? (
                <Button color='secondary' onClick={handleNextStep}>
                  {t('onboarding.imageModal.nextStep')}
                </Button>
              ) : (
                <Button color='primary' onClick={onClose}>
                  {t('onboarding.imageModal.finish')}
                </Button>
              )}
            </NavigationButtons>
          </ButtonsContainer>
        </Container>
      ) : (
        <Container>There is no content to display</Container>
      )}
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  height: 100%;
  overflow-x: hidden;
`
const HeadingContainer = styled.div`
  display: flex;
`
const Step = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0;
  margin: 0;
`
const ContentContainer = styled.div`
  display: flex;
  margin: 24px 0;
  height: 80vh;
  position: relative;
`
const Image = styled.img`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.main.grey300};
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ${({ theme }) => theme.transitions.easing.easeInOut};
  animation: ${({ isVisible, isSlideBack, isSlideNext }) => {
      if (isSlideNext) {
        return isVisible ? slideIn : slideOut
      } else if (isSlideBack) {
        return isVisible ? slideInReverse : slideOutReverse
      }
    }}
    0.5s ${({ theme }) => theme.transitions.easing.sharp};
`
const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  button {
    min-width: 160px;
  }
`
const NavigationButtons = styled.div`
  display: flex;
  gap: 16px;
`
const Indicators = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`
const Indicator = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.main.primary500 : theme.colors.main.grey600};
  border-radius: 50%;
`
const slideIn = keyframes`
	  0% {
	    transform: translateX(100%);
	  }
	  100% {
	    transform: translateX(0);
	  }
	`
const slideInReverse = keyframes`
	  0% {
	    transform: translateX(-100%);
	  }
	  100% {
	    transform: translateX(0);
	  }
	`
const slideOut = keyframes`
	  0% {
	    transform: translateX(0);
	  }
	  100% {
	    transform: translateX(-100%);
	  }
	`
const slideOutReverse = keyframes`
	  0% {
	    transform: translateX(0);
	  }
	  100% {
	    transform: translateX(100%);
	  }
	`

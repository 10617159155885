import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import styled from 'styled-components'
import ApexChart from 'react-apexcharts'
import { ReadProgressChart } from 'modules/dashboard/utils/ReadProgressChart'
import { getBookProgressData } from 'services/BooksService'
import { randomSeconds } from 'utils/date'
import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

import {
  BookChartIcon,
  TotalProgressIcon,
  getTheme
} from 'examkrackers-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import FreeTrialChartTooltipProvider from './FreeTrialChartTooltipProvider'
import { contentTypes } from './TooltipChart'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'
import { useHistory } from 'react-router-dom'

export const BookProgressChart = () => {
  const theme = getTheme()
  const { t } = useTranslation()
  const { push } = useHistory()
  const [data, setData] = useState([])

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  useEffect(() => {
    const redirectToBook = index => {
      // @ts-ignore
      const bookUrl = `/books/${data[index].original_book_id}/chapter/1/part/1`
      push(bookUrl)
    }
    const chartContainer = document.getElementById('book-progress-chart')

    chartContainer?.addEventListener('click', event => {
      const target = event.target as Element

      if (target.matches('.apexcharts-backgroundBar')) {
        const bars = Array.from(
          chartContainer.querySelectorAll('.apexcharts-backgroundBar')
        )
        const index = bars.indexOf(target)

        if (index !== -1) {
          redirectToBook(index)
        }
      }
    })
  }, [data])

  useEffect(() => {
    getBookProgressData()
      .then(result => {
        const sortedData = R.pipe(
          R.propOr([], 'data'),
          // @ts-ignore
          R.sortBy(R.prop('title'))
          // @ts-ignore
        )(result)

        // @ts-ignore
        setData(sortedData)
      })
      .catch(console.error)
  }, [])

  const injectDefsForSvgBookRead = () => {
    const bookChartElement = document.getElementById('apexchartsbookChart')

    if (isNotNilOrEmpty(bookChartElement)) {
      // @ts-ignore
      bookChartElement
        .getElementsByTagName('svg')[0]
        .getElementsByTagName('defs')[0].innerHTML += `
        <linearGradient id="GradientBook" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="${theme.colors.dashboard.totalProgressBackground}"></stop>
          <stop offset="2%" stop-color="${theme.colors.dashboard.totalProgressShadowMedium}" stop-opacity="0"></stop>
          <stop offset="100%" stop-color="${theme.colors.dashboard.totalProgressShadowLight}"></stop>
        </linearGradient>
        `
    }
  }

  useEffect(() => {
    setTimeout(() => {
      injectDefsForSvgBookRead()
    }, 1200)
  }, [theme])

  const timedOutInjectDefsForSvgBookRead = () =>
    setTimeout(injectDefsForSvgBookRead, 500)

  useEffect(() => {
    window.addEventListener('resize', timedOutInjectDefsForSvgBookRead)

    return () => {
      window.removeEventListener('resize', timedOutInjectDefsForSvgBookRead)
    }
  }, [])

  const totalProgress = () => {
    if (data.length > 0) {
      const totalCount = data
        // @ts-ignore
        .map(a => parseInt(a.total_count, 10))
        .reduce((a, b) => a + b)
      const readCounter = data
        // @ts-ignore
        .map(a => parseInt(a.seen_count, 10))
        .reduce((a, b) => a + b)

      return (
        (readCounter > 0 && totalCount > 0
          ? Math.round((readCounter * 100) / totalCount)
          : 0) + '%'
      )
    }
    return ''
  }

  const bookReadingProgressChart = ReadProgressChart(data)

  const tooltipEvent = e => ({ e, type: contentTypes.bookProgress })
  const handleEnterTooltip = e =>
    eventEmitter.emit(events.chartTooltipEnter, tooltipEvent(e))
  const handleLeaveTooltip = () => eventEmitter.emit(events.chartTooltipLeave)

  return (
    <ChartContainer>
      <FreeTrialChartTooltipProvider data={data} id='book-progress-chart' />
      <Icon onMouseEnter={handleEnterTooltip} onMouseLeave={handleLeaveTooltip}>
        <BookChartIcon />
      </Icon>
      <TotalProgress isDarkTheme={isDarkTheme}>
        <TotalProgressIcon />
        <p>
          {t('dashboard.charts.totalProgress')}
          <strong>{totalProgress()}</strong>
        </p>
      </TotalProgress>
      {/* @ts-ignore */}
      <ApexChart
        id='book-progress-chart'
        // @ts-ignore
        options={bookReadingProgressChart}
        series={bookReadingProgressChart.series}
        type='bar'
        width='394'
        height='306'
      />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  position: relative;

  .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
    cursor: pointer;
  }
  svg {
    /* color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme ? theme.colors.main.black : theme.colors.main.white}; */
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.mainShadow};
    background: ${({ theme }) => theme.colors.backgrounds.main} !important;
    g.apexcharts-datalabels {
      transform: translateX(40px);
    }
  }

  .apexcharts-bar-area,
  .apexcharts-backgroundBar {
    animation: swipe-bar ${randomSeconds(300, 2500)}ms ease-in;
  }

  .apexcharts-bar-area:nth-child(1),
  .apexcharts-backgroundBar:nth-child(1) {
    animation-duration: 1.3s;
  }

  .apexcharts-bar-area:nth-child(2),
  .apexcharts-backgroundBar:nth-child(2) {
    animation-duration: 1.6s;
  }

  .apexcharts-bar-area:nth-child(3),
  .apexcharts-backgroundBar:nth-child(3) {
    animation-duration: 2s;
  }

  .apexcharts-bar-area:nth-child(4),
  .apexcharts-backgroundBar:nth-child(4) {
    animation-duration: 0.8s;
  }

  .apexcharts-bar-area:nth-child(5),
  .apexcharts-backgroundBar:nth-child(5) {
    animation-duration: 1.5s;
  }

  .apexcharts-bar-area:nth-child(6),
  .apexcharts-backgroundBar:nth-child(6) {
    animation-duration: 1.1s;
  }

  .apexcharts-bar-area:nth-child(7),
  .apexcharts-backgroundBar:nth-child(7) {
    animation-duration: 1.7s;
  }

  .apexcharts-bar-area:nth-child(8),
  .apexcharts-backgroundBar:nth-child(8) {
    animation-duration: 2.2s;
  }

  @keyframes swipe-bar {
    0% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  .apexcharts-title-text {
    fill: ${({ theme }) => theme.colors.main.heading} !important;
  }
  .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
    clip-path: inset(0px 0px 0px 0px round 10px);
  }

  & > .apexcharts-bar-area,
  .apexcharts-backgroundBar {
    cursor: pointer;
  }
`

const Icon = styled.div`
  font-size: 24px;
  position: absolute;
  top: 8px;
  left: 20px;
  z-index: 1000;
  color: ${({ theme }) => theme.colors.main.white};

  cursor: pointer;
`
const TotalProgress = styled.div`
  position: absolute;
  top: 8px;
  right: 30px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: ${({ theme, isDarkTheme }) =>
    isDarkTheme
      ? `inset -1px -1px 4px 0px ${theme.colors.dashboard.totalProgressShadowLight},
         inset 1px 1px 4px 0px ${theme.colors.dashboard.totalProgressShadowDark}`
      : 'none'};
  padding: 5px 10px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: ${({ theme }) => theme.typography.fontSizeSmall};
  background-color: ${({ theme, isDarkTheme }) =>
    !isDarkTheme
      ? theme.colors.main.grey200
      : theme.colors.dashboard.speedometer.white};
  svg {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};

    border-radius: 0px;
    box-shadow: none;
    background: none !important;
  }
  p {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};
    font-size: ${({ theme }) => theme.typography.fontSizeExtraSmall};
    margin-left: 5px;
    strong {
      color: ${({ theme, isDarkTheme }) =>
        !isDarkTheme
          ? theme.colors.dashboard.totalProgress
          : theme.colors.main.white};
      font-weight: 700;
    }
  }
`

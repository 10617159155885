import React, { useState } from 'react'
import debounce from 'lodash.debounce'
import styled from 'styled-components'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/diagnostics/utils/events'
import {
  getAveragePassagesTimings,
  getAverageQuestionsWorkingTimeGraph,
  getPassageNumberByQuestionNumber,
  getPassageReadingTimeGraph,
  getPassageWorkingTimeGraph,
  getQuestionsData,
  getQuestionsDifficultyGraph,
  getQuestionsWorkingTimeGraph
} from 'modules/diagnostics/ducks/selectors'
import { formatSecondsToMinutesAndSeconds } from 'utils/date'

export const TooltipQuestionDetails = (): JSX.Element => {
  const { t } = useTranslation()
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0)

  const handleQuestionHover = questionNumber =>
    setCurrentQuestionNumber(questionNumber)
  const debouncedHandleQuestionHover = debounce(handleQuestionHover)
  const questionDifficultyData = useSelector(getQuestionsDifficultyGraph)
  const passageReadingTimeGraph = useSelector(getPassageReadingTimeGraph)
  const passageWorkingTimeGraph = useSelector(getPassageWorkingTimeGraph)
  const questionWorkingTimeData = useSelector(getQuestionsWorkingTimeGraph)
  const questionsData = useSelector(getQuestionsData)
  const averageQuestionsWorkingTimeData = useSelector(
    getAverageQuestionsWorkingTimeGraph
  )
  const passageAverageTimings = useSelector(getAveragePassagesTimings)
  const passageNumber = useSelector(state =>
    getPassageNumberByQuestionNumber(state, currentQuestionNumber)
  )

  const questionDifficulty = R.pipe(
    R.find(R.propEq('x', currentQuestionNumber)),
    R.propOr(0, 'y')
  )(questionDifficultyData)

  const questionData = R.find(R.propEq('order', currentQuestionNumber))(
    questionsData
  )
  const answer = R.pathOr('', ['answer'], questionData)
  const correctAnswer = R.pathOr('', ['correct_answer'], questionData)
  const isCorrect = answer === correctAnswer

  const isSkipped = R.pipe(
    R.find(R.propEq('order', currentQuestionNumber)),
    R.propOr(null, 'answer'),
    R.equals(null)
  )(questionsData)

  const questionWorkingYou = R.pipe(
    R.find(R.propEq('x', currentQuestionNumber)),
    R.propOr(0, 'y')
  )(questionWorkingTimeData)

  const questionWorkingAvg = R.pipe(
    R.find(R.propEq('x', currentQuestionNumber)),
    R.propOr(0, 'y')
  )(averageQuestionsWorkingTimeData)

  const passageWorkingYou = R.pipe(
    R.find(R.propEq('x', currentQuestionNumber)),
    R.propOr(0, 'y')
  )(passageWorkingTimeGraph)

  const passageWorkingAvg = R.pipe(
    R.find(R.propEq('order', passageNumber)),
    R.pathOr(0, ['timers', 'working_avg'])
  )(passageAverageTimings)

  const passageReadingYou = R.pipe(
    R.find(R.propEq('x', currentQuestionNumber)),
    R.propOr(0, 'y')
  )(passageReadingTimeGraph)

  const passageReadingAvg = R.pipe(
    R.find(R.propEq('order', passageNumber)),
    R.pathOr(0, ['timers', 'reading_avg'])
  )(passageAverageTimings)

  React.useEffect(() => {
    eventEmitter.on(events.questionHover, debouncedHandleQuestionHover)

    return () => {
      eventEmitter.off(events.questionHover, debouncedHandleQuestionHover)
    }
  }, [])

  const getQuestionStatus = () => {
    switch (true) {
      case isCorrect:
        return (
          <Status status='correct'>
            {t('diagnostics.graph.tooltip.questionStatus.correct')}
          </Status>
        )
      case isSkipped:
        return (
          <Status status='skipped'>
            {t('diagnostics.graph.tooltip.questionStatus.skipped')}
          </Status>
        )
      default:
        return (
          <Status status='incorrect'>
            {t('diagnostics.graph.tooltip.questionStatus.incorrect')}
          </Status>
        )
    }
  }

  return (
    <Container isVisible={currentQuestionNumber > 0}>
      <Header>
        <QuestionInfo>
          <Label>
            {t('diagnostics.graph.tooltip.question', {
              number: currentQuestionNumber
            })}
          </Label>
          {getQuestionStatus()}
        </QuestionInfo>
        <Label>
          {t('diagnostics.graph.tooltip.difficulty', {
            value: questionDifficulty
          })}
        </Label>
      </Header>
      <Body>
        <TimeMetricsContainer>
          <TimeMetricsContainerName>
            {t('diagnostics.graph.tooltip.timeMetrics.timeWorkingQuestion')}
          </TimeMetricsContainerName>
          <TimeMetricsYou>
            <div>{t('diagnostics.graph.tooltip.you')}:</div>
            <Time>{formatSecondsToMinutesAndSeconds(questionWorkingYou)}</Time>
          </TimeMetricsYou>
          <TimeMetricsAvg>
            <div>{t('diagnostics.graph.tooltip.avg')}:</div>
            <Time>{formatSecondsToMinutesAndSeconds(questionWorkingAvg)}</Time>
          </TimeMetricsAvg>
        </TimeMetricsContainer>

        <Divider />

        <TimeMetricsContainer>
          <TimeMetricsContainerName>
            {t('diagnostics.graph.tooltip.timeMetrics.passageWorkingTime')}
          </TimeMetricsContainerName>
          <TimeMetricsYou>
            <div>{t('diagnostics.graph.tooltip.you')}:</div>
            <Time>{formatSecondsToMinutesAndSeconds(passageWorkingYou)}</Time>
          </TimeMetricsYou>
          <TimeMetricsAvg>
            <div>{t('diagnostics.graph.tooltip.avg')}:</div>
            <Time>{formatSecondsToMinutesAndSeconds(passageWorkingAvg)}</Time>
          </TimeMetricsAvg>
        </TimeMetricsContainer>

        <Divider />

        <TimeMetricsContainer>
          <TimeMetricsContainerName>
            {t('diagnostics.graph.tooltip.timeMetrics.passageReadingTime')}
          </TimeMetricsContainerName>
          <TimeMetricsYou>
            <div>{t('diagnostics.graph.tooltip.you')}:</div>
            <Time>{formatSecondsToMinutesAndSeconds(passageReadingYou)}</Time>
          </TimeMetricsYou>
          <TimeMetricsAvg>
            <div>{t('diagnostics.graph.tooltip.avg')}:</div>
            <Time>{formatSecondsToMinutesAndSeconds(passageReadingAvg)}</Time>
          </TimeMetricsAvg>
        </TimeMetricsContainer>
      </Body>
    </Container>
  )
}

export default TooltipQuestionDetails

const Container = styled.div`
  position: fixed;
  top: 21px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  z-index: ${({ theme }) => theme.zIndex.mainOverlay};
  border-radius: 6px;
  height: ${({ isVisible }) => (isVisible ? 'auto' : 0)};
  padding: ${({ isVisible }) => (isVisible ? '16px' : 0)};
  min-width: ${({ isVisible }) => (isVisible ? '458px' : 0)};
  overflow: hidden;
  transition: height 800ms ${({ theme }) => theme.transitions.easing.easeInOut},
    min-width 800ms ${({ theme }) => theme.transitions.easing.easeInOut},
    padding 100ms ${({ theme }) => theme.transitions.easing.easeInOut};
`

const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`

const QuestionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Status = styled.div`
  min-width: 76px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  font-weight: 700;
  font-size: 11px;
  background: ${({ theme, status }) =>
    theme.colors.diagnostics.question[`${status}Background`]};
  color: ${({ theme, status }) => theme.colors.diagnostics.question[status]};
  border-radius: 4px;
`

const Body = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 12px;
`

const TimeMetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TimeMetricsContainerName = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
  font-weight: 400;
  font-size: 11px;
  line-height: 21px;
  padding: 0 6px;
`

const TimeMetricsYou = styled.div`
  background: ${({ theme }) => theme.colors.main.primary200};
  border-radius: 2px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  padding: 0 6px;
`

const TimeMetricsAvg = styled.div`
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  padding: 0 6px;
`

const Time = styled.div`
  line-height: 21px;
  font-weight: 700;
  font-size: 11px;
`

const Divider = styled.div`
  display: inline-block;
  height: 61px;
  width: 2px;
  background: ${({ theme }) => theme.colors.main.grey300};
`

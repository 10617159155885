export const getEventPayload = values => {
  if (values) {
    let data = {}
    if (['full_length_exam', 'section_exam'].includes(values.type)) {
      data = {
        title: values.title,
        event_date: values.event_date,
        exam_id: values.id,
        status: values.status
      }
    } else if (values.type === 'custom_event_type') {
      data = {
        title: values.title,
        description: values.description,
        duration: values.duration,
        event_date: values.event_date,
        custom_event_type_id: values.id,
        status: values.status
      }
    } else if (values.type === 'custom') {
      data = {
        title: values.title,
        description: values.description,
        duration: values.duration,
        event_date: values.event_date,
        status: values.status
      }
    }

    return {
      event_type: values.type,
      data
    }
  }

  return null
}

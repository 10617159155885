import {
  resetPassedTimeRoutine,
  countPassedTimeRoutine,
  setCurrentTimersRoutine,
  setQuestionOriginalIdRoutine,
  setPassageOriginalIdRoutine,
  setQuestionIdOnPassageCheckboxRoutine,
  setPassedTimePassageCheckboxRoutine
} from './actions'

import { Action, ExamTimeTrackerReducer } from 'types'

export const initialState: ExamTimeTrackerReducer = {
  passedTime: 0,
  currentTimers: {},
  questionOriginalId: '',
  passageOriginalId: '',
  questionIdOnPassageCheckbox: '',
  passedTimeOnPassageCheckbox: 0
}

export default (
  state = initialState,
  action: Action
): ExamTimeTrackerReducer => {
  switch (action.type) {
    case resetPassedTimeRoutine.SUCCESS:
      return {
        ...state,
        passedTime: 0
      }
    case countPassedTimeRoutine.SUCCESS:
      return {
        ...state,
        passedTime: state.passedTime + 1
      }
    case setCurrentTimersRoutine.SUCCESS:
      return {
        ...state,
        currentTimers: action.payload
      }
    case setQuestionOriginalIdRoutine.SUCCESS:
      return {
        ...state,
        questionOriginalId: action.payload
      }
    case setPassageOriginalIdRoutine.SUCCESS:
      return {
        ...state,
        passageOriginalId: action.payload
      }
    case setQuestionIdOnPassageCheckboxRoutine.SUCCESS:
      return {
        ...state,
        questionIdOnPassageCheckbox: action.payload
      }
    case setPassedTimePassageCheckboxRoutine.SUCCESS:
      return {
        ...state,
        passedTimeOnPassageCheckbox: action.payload
      }
    default:
      return state
  }
}

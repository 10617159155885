import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'
import { BackButton, Heading2 } from 'examkrackers-components'

export const EmptyStudyState = (): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()

  const goToBoxesPage = () => history.push(PATHS.flashcardsBoxes)

  return (
    <Container>
      <SaltyImage src='/assets/illustrations/SaltyScratchHead.png' />
      <Heading2 bold>{t('flashcards.study.empty.title')}</Heading2>
      <div>{t('flashcards.study.empty.description')}</div>
      <BackButton key='back-button' onClick={goToBoxesPage}>
        {t('flashcards.study.empty.CTAButton')}
      </BackButton>
    </Container>
  )
}

export default EmptyStudyState

const Container = styled.div`
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 715px;
  height: 572px;
  flex-direction: column;
  gap: 16px;
`

const SaltyImage = styled.img`
  height: 200px;
`

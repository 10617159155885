import APIService from 'services/APIService'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { timeOptions } from 'utils/calendar'
import { isNilOrEmpty, isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { isEmpty } from 'ramda'

export const getExamOptions = async setFn => {
  const api = APIService()
  const courseId =
    LocalStorageService.get(LOCAL_STORAGE_KEYS.originalCourseId) || null

  if (courseId) {
    const [fullExams, sectionExams, customGroups] = await Promise.all([
      api.get(`/calendar-full-exams/course/${courseId}`),
      api.get(`/calendar-section-exams/course/${courseId}`),
      api.get(`/custom-event-groups?filter[course_id]=${courseId}`)
    ])

    const fullExamsData = fullExams?.data || []
    const sectionExamsData = sectionExams?.data || []
    const customGroupsData = customGroups?.data?.data || []

    const fullExamsOptions = fullExamsData
      .filter(exam => !exam.order)
      .map(exam => ({
        type: 'full_length_exam',
        duration: exam.exam_duration,
        label: exam?.custom_title || exam.title,
        value: exam.exam_id
      }))

    const sectionExamsOptions = sectionExamsData
      // .filter(exam => !exam.order)
      .map(exam => ({
        type: 'section_exam',
        duration: exam.exam_duration,
        label: exam?.custom_title || exam.title,
        value: exam.exam_id
      }))

    const groupsDataOptions = customGroupsData
      .sort((a, b) => a.order - b.order)
      .map(group => ({
        label: group.title,
        options: (group?.customEventTypes || [])
          .sort((a, b) => a.order - b.order)
          .map(type => ({
            type: 'custom_event_type',
            value: type.id,
            label: type.title,
            present_in_calendar: type.present_in_calendar || false
          }))
      }))

    const emptyFiltered = [
      { label: 'Full-length MCAT', options: fullExamsOptions },
      { label: 'MCAT Sectional', options: sectionExamsOptions },
      ...groupsDataOptions
    ].filter(el => isNotNilOrEmpty(el.options))

    const result = [
      ...emptyFiltered,
      { label: 'Custom Event', value: 'custom', type: 'custom' }
    ]

    setFn(result)
  }
}

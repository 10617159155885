import * as R from 'ramda'
import { createSelector } from 'reselect'
import { transformToArray } from 'utils/redux'

export const selectListOfTopics = createSelector(
  R.pathOr({}, ['courseTopics', 'topics']),
  R.pipe(transformToArray, R.sortBy(R.prop('order')))
)

export const selectListOfTopicsPagination = createSelector(
  R.pathOr({}, ['courseTopics', 'pagination']),
  R.identity
)

export const selectListOfTopicsState = createSelector(
  R.pathOr('', ['courseTopics', 'state']),
  R.identity
)

export const selectSearchedTopic = createSelector(
  R.pathOr('', ['courseTopics', 'searchedTopic']),
  R.identity
)

export const BOOK_API_RESPONSE_TO_REDUCER_MAP = {
  book: 'books',
  original_book: 'originalBooks',
  attachments: 'attachments',
  book_contents: 'bookContents',
  chapters: 'chapters',
  chapter_images: 'bookChapterImages',
  content_images: 'bookContentImages',
  content_questions: 'contentQuestions',
  flashcards: 'contentFlashcards',
  resources: 'resources',
  subchapters: 'subchapters',
  topics: 'topics',
  book_content_comments: 'bookContentComments'
}

import * as R from 'ramda'
import { Action, GlossaryReducer } from 'types'
import {
  fetchGlossaryWordsListRoutine,
  resetGlossaryWordsListRoutine
} from 'modules/glossary/ducks/actions'
import { API_STATES } from 'utils/redux'

export const initialState: GlossaryReducer = {
  state: API_STATES.PRISTINE,
  words: [],
  pagination: {
    page: 1,
    take: 10,
    recordsTotal: 0,
    pagesTotal: 1
  }
}

export default (state = initialState, action: Action): GlossaryReducer => {
  switch (action.type) {
    case fetchGlossaryWordsListRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchGlossaryWordsListRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchGlossaryWordsListRoutine.SUCCESS:
      return {
        ...state,
        words: action.payload.data,
        pagination: R.mapObjIndexed(
          value => Number(value),
          action.payload.meta
        ),
        state: API_STATES.DONE
      }
    case resetGlossaryWordsListRoutine.SUCCESS:
      return {
        ...state,
        words: [],
        pagination: {
          page: 1,
          take: 10,
          recordsTotal: 0,
          pagesTotal: 1
        }
      }
    default:
      return { ...state }
  }
}

import React, { useMemo } from 'react'
import { Button, SingleSelect, RadioButton } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import { dissoc, omit, pathOr, prop, propOr, sortBy } from 'ramda'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import {
  changeFlashcardsStudyMode,
  shuffleFlashcards
} from 'services/FlashcardsService'
import {
  getFlashcardsPagination,
  getFlashcardsProficiencyLevelStats,
  getFlashcardsSelectedSide
} from 'modules/flashcards/ducks/selectors'
import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import { findLowestLevelWithFlashcards, FLASHCARD_SIDE } from 'utils/flashcards'
import PATHS from 'utils/paths'
import { getOptionByValue } from 'utils/form'
import { Book, Chapter, Course } from 'types'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import * as R from 'ramda'
import { COURSE_TYPES } from 'utils/courses'
import BookFiltersTag from 'components/BookFiltersTag'

interface FiltersVideosListProps {
  count?: number
  title?: string
  hideStudyBtn?: boolean
  hideSideRadio?: boolean
  hidePlvl?: boolean
  customElements?: string | null | undefined | JSX.Element | JSX.Element[]
}

const FiltersVideosList = (props: FiltersVideosListProps): JSX.Element => {
  const {
    title,
    hideStudyBtn,
    hideSideRadio,
    hidePlvl,
    customElements,
    count
  } = props
  const {
    replace,
    push,
    location: { pathname, search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const selectedBookId = pathOr('', ['filter', 'b.id'], parsedQuery)
  const selectedBookChapterId = pathOr('', ['filter', 'bcc.id'], parsedQuery)
  const selectedProfLevel = pathOr(
    '',
    ['filter', 'f.proficiency_level'],
    parsedQuery
  )
  const books = useSelector(selectAllBooks)

  // @ts-ignore
  const selectedBook: Book = R.find(R.propEq('id', selectedBookId))(books)
  // @ts-ignore
  const chapters: Chapter[] = R.pipe(
    R.find(R.propEq('id', selectedBookId)),
    R.propOr([], 'chapters')
  )(books)
  // @ts-ignore
  const selectedChapter: Chapter = R.find(
    R.propEq('id', selectedBookChapterId)
  )(chapters)

  const { t } = useTranslation()
  const sortByOrder = sortBy(prop('order'))
  const flashcardSideToDisplay = useSelector(getFlashcardsSelectedSide)
  const sortedBookChapters = sortByOrder(chapters)
  const dispatch = useDispatch()
  const flashcardsPagination = useSelector(getFlashcardsPagination)
  const totalRecords = propOr(0, 'recordsTotal', flashcardsPagination)
  const proficiencyStats = useSelector(getFlashcardsProficiencyLevelStats)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const booksOptions = useMemo(() => {
    return books.map(book => {
      return {
        label: propOr('', 'title', book),
        value: propOr('', 'id', book),
        chapters: propOr('', 'chapters', book),
        tag: propOr('', 'tag', book),
        tag_colour: propOr('', 'tag_colour', book),
        id: propOr('', 'id', book)
      }
    })
  }, [books, currentCourse])

  const proficiencyLevelOptions = useMemo(() => {
    return Array.from(Array(7)).map((_, index) => ({
      label: index + 1,
      value: index + 1
    }))
  }, [])

  const chapterOptions = useMemo(() => {
    return sortedBookChapters.map(chapter => {
      return {
        label: propOr('', 'title', chapter),
        value: propOr('', 'id', chapter)
      }
    })
  }, [chapters, currentCourse, selectedBook])

  const handleModeChange = e => {
    const handleSuccess = () => {
      dispatch(studentFetchDetailsRoutine())
    }
    if (flashcardSideToDisplay !== e) {
      changeFlashcardsStudyMode({ side: e }).then(handleSuccess)
    }
  }

  const redirectToStudyView = () => {
    const query = qs.stringify(
      {
        ...parsedQuery,
        filter: {
          ...parsedQuery.filter,
          'f.proficiency_level': pathOr(
            findLowestLevelWithFlashcards(proficiencyStats),
            ['filter', 'f.proficiency_level'],
            parsedQuery
          )
        },
        limit: {
          take: 1,
          page: 1
        }
      },
      {
        encode: false,
        addQueryPrefix: true
      }
    )

    const handleSuccess = () => {
      push(`${PATHS.flashcardsStudy}${query}`, {
        bookTitle: R.propOr(null, 'title', selectedBook),
        chapterTitle: R.propOr(null, 'title', selectedChapter),
        from: `${pathname}${search}`
      })
    }
    const handleError = e => console.error(e)

    shuffleFlashcards().then(handleSuccess).catch(handleError)
  }

  const handleChapterChange = option => {
    if (option) {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...dissoc('part', parsedQuery.filter),
          'bcc.id': option.value
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...omit(['bcc.id', 'part'], parsedQuery.filter)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { encode: false })}`)
    }
  }

  const handleProficiencyLevelChange = option => {
    if (option) {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...parsedQuery.filter,
          'f.proficiency_level': option.value
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...dissoc('f.proficiency_level', parsedQuery.filter)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }

  const freeTrialOptions = R.pipe(
    // @ts-ignore
    R.filter(R.propEq('is_available', true)),
    R.map(
      R.applySpec({
        label: R.propOr('', 'title'),
        value: R.propOr('', 'id')
      })
    ),
    R.prepend({
      label: 'All',
      value: ''
    })
    // @ts-ignore
  )(books)

  const handleChangeFreeTrialFilter = option => {
    const optionBookId = R.propOr('', 'value', option)
    const firstChapterId = R.pipe(
      R.find(R.propEq('id', optionBookId)),
      R.propOr([], 'chapters'),
      R.find(R.propEq('order', 1)),
      R.propOr('', 'id')
    )(books)

    if (isNotNilOrEmpty(optionBookId)) {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...omit(['bcc.id', 'part', 'b.id'], parsedQuery.filter),
          'b.id': optionBookId,
          'bcc.id': firstChapterId
        },
        part: 1
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...omit(['part'], parsedQuery),
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...omit(['bcc.id', 'b.id'], parsedQuery.filter)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }

  const FreeTrialFilters = (
    <StyledSelect
      removeMargin
      id='select-book'
      label={t('flashcards.filters.book')}
      placeholder={t('flashcards.filters.book')}
      size='small'
      // @ts-ignore
      options={freeTrialOptions}
      onChange={handleChangeFreeTrialFilter}
      // @ts-ignore
      value={getOptionByValue(selectedBookId)(freeTrialOptions)}
    />
  )

  const plvlSelectedOption = getOptionByValue(Number(selectedProfLevel))(
    // @ts-ignore
    proficiencyLevelOptions
  )

  const filtersBook = booksOptions.map(book => (
    // @ts-ignore
    <BookFiltersTag key={`book-filter-${book.id}`} book={book} />
  ))

  return (
    <Row>
      <PageTitle>
        {title || t('flashcards.title')} (
        {isNilOrEmpty(count) ? totalRecords : count})
      </PageTitle>
      <FiltersWrapperOuter>
        <FiltersWrapper>
          {isFreeTrial ? (
            FreeTrialFilters
          ) : (
            <>
              <BookFilterWrapper>{filtersBook}</BookFilterWrapper>
              <StyledSelect
                removeMargin
                id='select-chapter'
                label={t('flashcards.filters.chapter')}
                placeholder={t('flashcards.filters.chapter')}
                size='small'
                // @ts-ignore
                options={chapterOptions}
                disabled={isNilOrEmpty(selectedBookId)}
                onChange={handleChapterChange}
                value={getOptionByValue(selectedBookChapterId)(
                  // @ts-ignore
                  chapterOptions
                )}
              />
            </>
          )}
          {!hidePlvl && (
            <StyledSelect
              removeMargin
              id='select-proficiency-level'
              label={t('flashcards.filters.proficiencyLevel')}
              placeholder={t('flashcards.filters.proficiencyLevel')}
              size='small'
              // @ts-ignore
              options={proficiencyLevelOptions}
              onChange={handleProficiencyLevelChange}
              value={plvlSelectedOption}
              isClearable
            />
          )}
          {!hideSideRadio && (
            <SwitchWrapper>
              <RadioButton
                alignTop
                label={t('flashcards.filters.front')}
                name='change-calculation-method'
                value={FLASHCARD_SIDE.front}
                isChecked={flashcardSideToDisplay === FLASHCARD_SIDE.front}
                onChange={handleModeChange}
              />
              <RadioButton
                alignTop
                label={t('flashcards.filters.back')}
                name='answerCode'
                value={FLASHCARD_SIDE.back}
                isChecked={flashcardSideToDisplay === FLASHCARD_SIDE.back}
                onChange={handleModeChange}
              />
            </SwitchWrapper>
          )}
        </FiltersWrapper>
      </FiltersWrapperOuter>
      <CreateButtonWrapper>
        {!hideStudyBtn && (
          <Button
            color='secondary'
            size='small'
            id='flashcards-start-studying'
            // @ts-ignore
            disabled={totalRecords < 1}
            onClick={redirectToStudyView}
          >
            {t('flashcards.startStudying')}
          </Button>
        )}
        {customElements && customElements}
      </CreateButtonWrapper>
    </Row>
  )
}

export default FiltersVideosList

const Row = styled.div`
  width: 100%;
  margin-top: 8px;
  position: relative;
  min-height: 32px;
`

const PageTitle = styled.div`
  position: absolute;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
`

const FiltersWrapperOuter = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  /* right: 130px; */
`

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
  justify-content: center;
`

const BookFilterWrapper = styled.div`
  max-width: 400px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 3px;
  }
`

const StyledSelect = styled(SingleSelect)`
  max-width: 154px;

  div {
    overflow-x: hidden;
  }
`

const CreateButtonWrapper = styled.div`
  position: absolute;
  right: 0;
`

const SwitchWrapper = styled.div`
  display: flex;
  height: ${({ theme }) => theme.dimensions.inputSmallHeight};
  align-items: center;
  gap: 10px;
  margin: 0;

  .radio-label {
    margin-left: 8px;
  }
`

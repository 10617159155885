import React, { useState, useRef } from 'react'

// TODO: change it to react-sketch when issue will be resolved
// https://github.com/tbolis/react-sketch/issues/129
// @ts-ignore
import { SketchField, Tools } from 'react-sketch2'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'types'

import { getStepHtml } from 'modules/exam/ducks/selectors'

import CanvasVisibilityManager from 'modules/markExam/components/CanvasVisibilityManager'
import CanvasTools from 'modules/markExam/components/CanvasTools'
import * as R from 'ramda'
import { useSelector } from 'react-redux'

export const DrawingOnCurrentPage = ({ setOpenHandler }): JSX.Element => {
  const sketchRef = useRef(null)
  const params = useParams()

  const [backgroundImage, setBackgroundImage] = useState(null)
  const [tool, setTool] = useState(Tools.Pencil)
  const [color, setColor] = useState('#000')
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)

  const questionId: string = R.propOr('', 'questionId', params)
  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, questionId)
  )

  const defaultCanvas = R.propOr({}, 'canvas', stepHtml)

  const resetCanvas = () => setBackgroundImage(null)
  const selectNewTool = newTool => () => setTool(newTool)

  const updateUndoAndRedo = () => {
    // @ts-ignore
    setCanUndo(sketchRef.current.canUndo())
    // @ts-ignore
    setCanRedo(sketchRef.current.canRedo())
  }

  const onClear = () => {
    setCanUndo(false)
    setCanRedo(false)
  }

  return (
    <div>
      <CanvasVisibilityManager
        setBackgroundImage={setBackgroundImage}
        setOpenHandler={setOpenHandler}
      />
      {stepHtml && backgroundImage && (
        <>
          <div>
            <SketchResultContainer id='sketch-result-container'>
              <BackgroundContainer
                style={{ backgroundImage: `url(${backgroundImage})` }}
              />
              <SketchContainer>
                <SketchField
                  ref={sketchRef}
                  width='100vw'
                  height='100vh'
                  lineWidth={3}
                  tool={tool}
                  lineColor={color}
                  fillColors={color}
                  defaultValue={defaultCanvas}
                  onChange={updateUndoAndRedo}
                />
              </SketchContainer>
            </SketchResultContainer>
            <CanvasTools
              tool={tool}
              sketchRef={sketchRef}
              setNewTool={selectNewTool}
              setNewColor={setColor}
              color={color}
              canUndo={canUndo}
              canRedo={canRedo}
              updateUndoAndRedo={updateUndoAndRedo}
              onClear={onClear}
              onClose={resetCanvas}
              backgroundImage={backgroundImage}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default DrawingOnCurrentPage

const SketchContainer = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.modal};
  top: 0;
  left: 0;
`

const BackgroundContainer = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.modal};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
`

const SketchResultContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: ${props => props.theme.zIndex.appBar};
  top: 0;
  left: 0;
`

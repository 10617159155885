import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import * as notificationService from 'services/NotificationService'
import { ActionPayload } from 'types'

// ROUTINES
export const fetchNotificationsRoutine = createRoutine('FETCH_NOTIFICATIONS')

// ACTIONS

function* fetchNotifications({ payload }: ActionPayload) {
  yield Effects.put(fetchNotificationsRoutine.request())
  try {
    const result = yield Effects.call(
      notificationService.fetchNotifications,
      payload
    )
    yield Effects.put(
      fetchNotificationsRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchNotificationsRoutine.failure(e))
  }
}

// WATCHERS

// watcher FETCH_NOTIFICATIONS
export function* fetchNotificationsWatcher() {
  yield Effects.takeLatest(
    fetchNotificationsRoutine.TRIGGER,
    fetchNotifications
  )
}

// SAGAS
export const notificationsSagas = [Effects.fork(fetchNotificationsWatcher)]

import React, { useEffect, useCallback } from 'react'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import PATHS from 'utils/paths'

import { studentLogoutRoutine } from 'modules/auth/ducks/actions'

import { refreshTokenStudent } from 'services/AuthService'

export const minutesToMilliseconds = minutes => minutes * 60000
const intervalTimePeriod = minutesToMilliseconds(10)

export const RefreshTokenProvider = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  const logoutStudent = useCallback(
    () => dispatch(studentLogoutRoutine()),
    [dispatch]
  )

  const handleRefreshToken = async () => {
    const currentToken = LocalStorageService.get(LOCAL_STORAGE_KEYS.token)

    if (isNotNilOrEmpty(currentToken)) {
      try {
        const result = await refreshTokenStudent()
        LocalStorageService.set(LOCAL_STORAGE_KEYS.token, result.data.token)
      } catch (e) {
        history.push(PATHS.unauthorized)
        logoutStudent()
        console.error(e)
      }
    }
  }

  useEffect(() => {
    const refreshToken = setInterval(handleRefreshToken, intervalTimePeriod)

    return () => {
      clearInterval(refreshToken)
    }
  }, [])

  return <div />
}

export default RefreshTokenProvider

import { createSelector } from 'reselect'
import * as R from 'ramda'

export const getVideosPagination = createSelector(
  R.pathOr(
    {
      page: 1,
      take: 10,
      recordsTotal: 0,
      pagesTotal: 1
    },
    ['videos', 'pagination']
  ),
  R.identity
)

export const getVideosList = createSelector(
  R.pathOr([], ['videos', 'videos']),
  R.identity
)

export const selectVideosState = createSelector(
  R.pathOr('', ['videos', 'state']),
  R.identity
)

export const selectBookVideosState = createSelector(
  R.pathOr('', ['videos', 'bookVideosState']),
  R.identity
)

import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Heading1, WarningReversed } from 'examkrackers-components'

// Account with no products route
//
// This route is shown when the student with no products logs in.
export const EmptyAccount = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.general.emptyAccount')}</title>
      </Helmet>
      <AuthContainer>
        <WarningReversed />
        <TitleWrapper className='title'>
          <Heading1>{t('emptyAccount.title')}</Heading1>
          <Heading1>{t('emptyAccount.subtitle')}</Heading1>
          <PurchasePageLink href='https://examkrackers.com/product/kracku/'>
            <Button>{t('emptyAccount.button')}</Button>
          </PurchasePageLink>
        </TitleWrapper>
      </AuthContainer>
    </React.Fragment>
  )
}

export default EmptyAccount

const AuthContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  gap: 20px;

  h1 {
    line-height: 1.8;
  }
`

const PurchasePageLink = styled.a`
  .children-container {
    overflow: visible !important;
  }
`

import { showToastRoutine, hideToastRoutine } from 'modules/toast/ducks/actions'
import { Action, ToastProps } from 'types'

export const initialState: ToastProps = {
  visible: false,
  key: '',
  options: {},
  severity: ''
}

export default (state = initialState, action: Action): ToastProps => {
  switch (action.type) {
    case showToastRoutine.SUCCESS:
      return {
        ...state,
        ...action.payload,
        visible: true
      }
    case hideToastRoutine.SUCCESS:
      return {
        ...state,
        visible: false
      }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { fetchSBPercentileRank } from 'services/LeaderboardService'
import SBPercentileRankPosition from './SBPercentileRankPosition'
import SBPercentileRankTable from './SBPercentileRankTable'
import SBPercentileRankPointer from './SBPercentileRankPointer'

const LeaderboardMiddleColumn = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [allStudents, setAllStudents] = useState([])
  const [currentStudent, setCurrentStudent] = useState({})

  const saveLeadershipPercentileRank = () => {
    const handleSuccess = response => {
      const allStudentsData = R.pathOr([], ['data', 'all_students'], response)
      const currentStudentData = R.pathOr(
        [],
        ['data', 'current_student'],
        response
      )
      // @ts-ignore
      setAllStudents(allStudentsData)
      setCurrentStudent(currentStudentData)
      setTimeout(() => setIsLoading(false), 200)
    }
    const handleError = e => {
      console.error(e)
      setIsLoading(false)
    }

    fetchSBPercentileRank().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    saveLeadershipPercentileRank()
  }, [])

  return (
    <Container>
      <Heading>{t('leaderboard.middleColumn.title')}</Heading>
      <Body id='sb-percentile-rank-container'>
        <SBPercentileRankTable
          allStudents={allStudents}
          currentStudent={currentStudent}
        />
        {!isLoading && <SBPercentileRankPointer />}
        <SBPercentileRankPosition
          percentile={R.propOr(0, 'percentile_rank', currentStudent)}
        />
      </Body>
    </Container>
  )
}

export default LeaderboardMiddleColumn

const Container = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  overflow: hidden;
  padding: 16px;
  display: flex;
  flex-direction: column;
  max-height: 582px;
`

const Heading = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.heading};
`

const Body = styled.div`
  max-height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`

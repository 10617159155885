// modules/students/ducks/reducer.ts - Student Reducer

import { API_STATES } from 'utils/redux'
import { studentFetchNavigationRoutine } from './actions'
import { Action, StudentReducer } from 'types'

// Initial student state
export const initialState: StudentReducer = {
  state: API_STATES.PRISTINE,
  navigation: []
}

// Student reducer
export default (state = initialState, action: Action): StudentReducer => {
  switch (action.type) {
    case studentFetchNavigationRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case studentFetchNavigationRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        navigation: action.payload
      }
    case studentFetchNavigationRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    default:
      return state
  }
}

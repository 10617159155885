import React from 'react'
import styled from 'styled-components'
import { BouncingLoader } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'

const FlashcardsBoxTooltipContents = ({ isLoading, pLevelStats }) => {
  const { t } = useTranslation()

  return (
    <>
      <PLeveLStatsWrapper isLoading={isLoading}>
        <Hint>{t('flashcards.studyTooltip')}</Hint>
        {isLoading ? (
          <BouncingLoader size={10} />
        ) : (
          pLevelStats?.map((plvl, index) => (
            <PlvlStatsItem className='to-dziala?' key={`plvl-${index}`}>
              <span>{`PL${plvl.proficiency_level}:`}</span>
              <Count>{`${plvl.count}`}</Count>
            </PlvlStatsItem>
          ))
        )}
      </PLeveLStatsWrapper>
    </>
  )
}

export default FlashcardsBoxTooltipContents

const PLeveLStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-height: 120px;
  min-width: 50px;
  font-size: 11px;
`

const PlvlStatsItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 50px;
  gap: 16px;
`
const Count = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: end;
`
const Hint = styled.div`
  font-weight: 600;
  font-size: 11px;
  margin: 6px 0;
`

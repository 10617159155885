// module/exams/ducks/reducer.ts - Exam Saga Reducer

import * as R from 'ramda'
import { Action, VideosReducer } from 'types'
import { API_STATES } from 'utils/redux'
import {
  fetchVideosByCategoriesRoutine,
  fetchVideosListRoutine,
  fetchVideosListWithInfiniteScrollRoutine,
  resetVideosListRoutine
} from 'modules/videos/ducks/actions'
import { mapObjIndexed } from 'ramda'
import { updateArrayOfVideos } from 'utils/videos'

export const initialState: VideosReducer = {
  state: API_STATES.PRISTINE,
  bookVideosState: API_STATES.PRISTINE,
  videos: [],
  pagination: {
    page: 1,
    take: 10,
    recordsTotal: 0,
    pagesTotal: 1
  }
}

export default (state = initialState, action: Action): VideosReducer => {
  switch (action.type) {
    case fetchVideosListRoutine.REQUEST:
      return {
        ...state,
        bookVideosState: API_STATES.IN_PROGRESS
      }
    case fetchVideosListWithInfiniteScrollRoutine.REQUEST:
    case fetchVideosByCategoriesRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchVideosListRoutine.FAILURE:
      return {
        ...state,
        bookVideosState: API_STATES.DONE
      }
    case fetchVideosListWithInfiniteScrollRoutine.FAILURE:
    case fetchVideosByCategoriesRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchVideosListRoutine.SUCCESS:
      return {
        ...state,
        bookVideosState: API_STATES.DONE,
        videos: updateArrayOfVideos(state.videos, action.payload.data),
        pagination: R.mapObjIndexed(value => Number(value), action.payload.meta)
      }
    case fetchVideosListWithInfiniteScrollRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        videos: updateArrayOfVideos(state.videos, action.payload.data),
        pagination: mapObjIndexed(value => Number(value), action.payload.meta)
      }
    case resetVideosListRoutine.SUCCESS:
      return {
        ...state,
        videos: [],
        state: API_STATES.PRISTINE,
        pagination: {
          page: 1,
          take: 10,
          recordsTotal: 0,
          pagesTotal: 1
        }
      }
    case fetchVideosByCategoriesRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        videos: updateArrayOfVideos(state.videos, action.payload)
      }
    default:
      return state
  }
}

import React from 'react'
import styled from 'styled-components'

export const Footer = (): JSX.Element => {
  return <Container />
}

export default Footer

const Container = styled.div`
  height: ${({ theme }) => theme.dimensions.footerHeight};
  flex: none;
  width: 100%;
`

import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { propOr, pipe, equals } from 'ramda'
import { useTranslation } from 'react-i18next'

import bookPlaceholder from 'assets/images/book_placeholder.png'
import { Book, Course } from 'types'
import PATHS from 'utils/paths'
import qs from 'qs'
import { Tooltip } from 'examkrackers-components'

import { selectAllBooks } from 'modules/books/ducks/selectors'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { COURSE_TYPES } from 'utils/courses'

import { getStudent } from 'modules/auth/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

const BooksColumn = () => {
  const { t } = useTranslation()
  const { push, location } = useHistory()
  const { pathname, search } = location
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const isAllNotesPath = pathname === t('notes.allNotesPath')
  const params = useParams()
  const id = propOr('', 'id', params)
  const books: Book[] = useSelector(selectAllBooks)
  const user = useSelector(getStudent)

  const currentCourse: Course = useSelector(getCurrentCourse)

  const isFreeTrial = pipe(
    propOr('', 'type'),
    equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const query = qs.stringify({
    ...parsedQuery,
    // @ts-ignore
    limit: { ...parsedQuery.limit, page: 1, take: 10 },
    // @ts-ignore
    order: { ...parsedQuery.order, by: 'natural', dir: 'asc' }
  })
  const allQuery = qs.stringify({
    // @ts-ignore
    limit: { ...parsedQuery.limit, page: 1, take: 10 },
    // @ts-ignore
    order: { ...parsedQuery.order, by: 'natural', dir: 'asc' },
    activeTab: 'notes'
  })

  return (
    <>
      <BookItemAll
        key='book-select-all'
        onClick={() => {
          AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeNotespage, {
            'Course type': currentCourse?.type || false,
            'Course name': currentCourse?.title || false,
            'Course expiration date': currentCourse?.accessible_to || false,
            'Note Tag': 'ALL'
          })
          push(
            `${PATHS.bookNotes.replace(
              ':id',
              `${t('notes.allNotes')}`
            )}?${allQuery}`
          )
        }}
        active={isAllNotesPath}
      >
        <BookTitle>{t('notes.allNotes').toUpperCase()}</BookTitle>
        <ImageContainer>
          <img
            // @ts-ignore
            src='/assets/illustrations/SaltyAllNotes.svg'
            alt='book-cover-all'
          />
        </ImageContainer>
      </BookItemAll>
      {books.map(book => {
        const isFreeTrialAndIsAvailable = () => {
          const isBookAvailableInFreeTrial = book?.is_available
          if (isFreeTrial) {
            return isBookAvailableInFreeTrial
          }
          return true
        }
        return (
          <React.Fragment key={`book-select-${book.id}`}>
            {!isFreeTrialAndIsAvailable() ? (
              <Tooltip
                tooltipContent={t('notes.notesNotAvailableInFreeTrial')}
                id='notes-notesNotAvailableInFreeTrial-tooltip'
              >
                <BookItem
                  key={`book-select-${book.id}`}
                  onClick={() => {
                    if (isFreeTrialAndIsAvailable()) {
                      push(
                        `${PATHS.bookNotes.replace(':id', book.id)}?${query}`
                      )
                    }
                  }}
                  active={id === book.id}
                  isBookAvailableInFreeTrial={isFreeTrialAndIsAvailable()}
                >
                  <BookTitle>{book.tag}</BookTitle>
                  <ImageContainer>
                    <img
                      // @ts-ignore
                      src={book.image_url || bookPlaceholder}
                      alt={`book-cover-${book.title}`}
                    />
                  </ImageContainer>
                </BookItem>
              </Tooltip>
            ) : (
              <BookItem
                key={`book-select-${book.id}`}
                onClick={() => {
                  AnalyticsService(user).logEvent(
                    ANALYTICS_EVENTS.seeNotespage,
                    {
                      'Course type': currentCourse?.type || false,
                      'Course name': currentCourse?.title || false,
                      'Course expiration date':
                        currentCourse?.accessible_to || false,
                      'Note Tag': book.tag || false
                    }
                  )

                  if (isFreeTrialAndIsAvailable()) {
                    push(`${PATHS.bookNotes.replace(':id', book.id)}?${query}`)
                  }
                }}
                active={id === book.id}
                isBookAvailableInFreeTrial={isFreeTrialAndIsAvailable()}
                isFreeTrial={isFreeTrial}
              >
                <BookTitle>{book.tag}</BookTitle>
                <ImageContainer>
                  <img
                    // @ts-ignore
                    src={book.image_url || bookPlaceholder}
                    alt={`book-cover-${book.title}`}
                  />
                </ImageContainer>
              </BookItem>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default BooksColumn

const BookItemAll = styled.div`
  cursor: pointer;
  width: 142px;
  height: 56px;
  padding: 0 12px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BookItem = styled.div`
  cursor: ${({ isBookAvailableInFreeTrial }) =>
    isBookAvailableInFreeTrial ? 'pointer' : 'not-allowed'};
  width: 142px;
  height: 56px;
  padding: 0 12px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ isBookAvailableInFreeTrial }) =>
    isBookAvailableInFreeTrial ? 1 : 0.5};

  img {
    max-width: 88px;
    max-height: 50px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.main.primary200};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.main.primary200};
    `}
`

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BookTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`

import * as R from 'ramda'

export const checkUrlExtension = (prop, entity) =>
  R.pipe(R.propOr('', prop), R.split('.'), R.last, R.split('?'), R.head)(entity)

export const getFileExtensionFromUrl = url => {
  if (!R.isNil(url)) {
    return R.pipe(R.split('.'), R.last, R.split('?'), R.head)(url)
  } else {
    return null
  }
}

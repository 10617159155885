import APIService from 'services/APIService'
import {
  FetchFlashcardsListPayload,
  CreateCustomBoxPayload,
  DeleteCustomBoxPayload,
  AddToArchivePayload,
  BulkUnarchiveFlashcardsPayload,
  getFlashcardsByPLevelPayload,
  AddFlashcardToCustomBoxPayload,
  BulkRemoveFlashcardsFromCustomBoxPayload
} from 'types'

const api = APIService()

export const fetchFlashcardsList = (payload: FetchFlashcardsListPayload) =>
  api.get(`/student-book-content-flashcards${payload.query}`)

export const fetchArchiveFlashcardsList = (
  payload: FetchFlashcardsListPayload
) => api.get(`/student-book-content-flashcards/archived${payload.query}`)

export const changeFlashcardsStudyMode = payload =>
  api.patch(`/students/flashcard-study-mode/${payload.side}`)

export const resetFlashcardsProficiencyLevel = payload =>
  api.patch(`/student-book-content-flashcards/reset${payload.query}`)

export const fetchFlashcardsStudyList = payload =>
  api.get(`/student-book-content-flashcards/study-optimized${payload.query}`)

export const fetchPlvlStats = payload =>
  api.get(`/student-book-content-flashcards/p-lvl-stats${payload.query}`)

export const answerFlashcard = payload =>
  api.patch(`/student-book-content-flashcards/${payload.id}/answer`, {
    is_correct: payload.isCorrect
  })

export const shuffleFlashcards = () =>
  api.post('/student-book-content-flashcards/shuffle')

export const createCustomBox = (payload: CreateCustomBoxPayload) =>
  api.post('/student-flashcard-boxes', payload)

export const fetchCustomBoxes = () => api.get('/student-flashcard-boxes')

export const deleteCustomBox = (payload: DeleteCustomBoxPayload) =>
  api.delete(`/student-flashcard-boxes/${payload.id}`)

export const addToArchive = (payload: AddToArchivePayload) =>
  api.patch(`/student-flashcard-archive/${payload.id}/archive`)

export const bulkUnArchiveFlashcards = (
  payload: BulkUnarchiveFlashcardsPayload
) => api.patch('/student-flashcard-archive/bulk-unarchive', payload.ids)

export const addFlashcardToCustomBox = (
  payload: AddFlashcardToCustomBoxPayload
) =>
  api.post(
    `/student-box-flashcards/box/${payload.box_id}/flashcard/${payload.student_flashcard_id}`
  )

export const getFlashcardsPLevelStats = (
  payload: getFlashcardsByPLevelPayload
) =>
  api.get(
    `/student-book-content-flashcards/p-lvl-stats?limit[take]=1&limit[page]=1&shuffle=false&filter[${payload.filter}]=${payload.filter_id}`
  )

export const bulkRemoveFlashcardsFromCustomBox = (
  payload: BulkRemoveFlashcardsFromCustomBoxPayload
) =>
  api.patch(
    `/student-box-flashcards/box/${payload.box_id}/bulk-delete`,
    payload.ids
  )

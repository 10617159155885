import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  fetchExamTypesRoutine,
  fetchExamSubtypesRoutine,
  fetchAvailableExamTypesRoutine,
  setCurrentExamTypeIdRoutine
} from 'modules/examTypes/ducks/actions'
import { fetchScoreProjectionDataRoutine } from 'modules/diagnostics/ducks/actions'

import {
  getExamTypesAsOptions,
  getExamSubtypesAsOptions,
  getExamTypes,
  getDefaultExamType
} from 'modules/examTypes/ducks/selectors'

import { SingleSelect } from 'examkrackers-components'

export const SelectProjectionType = (): JSX.Element => {
  // Get React-Redux dispatcher
  const dispatch = useDispatch()

  // Get i18n handler
  const { t } = useTranslation()

  const [type, setType] = React.useState(null)
  const [subtype, setSubtype] = React.useState(null)

  const fetchExamTypes = React.useCallback(
    () => dispatch(fetchExamTypesRoutine()),
    [dispatch]
  )

  const fetchAvailableExamTypes = React.useCallback(
    () => dispatch(fetchAvailableExamTypesRoutine()),
    [dispatch]
  )

  const fetchExamSubtypes = React.useCallback(
    type => dispatch(fetchExamSubtypesRoutine({ type })),
    [dispatch]
  )

  const fetchScoreProjectionData = React.useCallback(
    payload => dispatch(fetchScoreProjectionDataRoutine(payload)),
    [dispatch]
  )

  const setCurrentExamTypeId = React.useCallback(
    payload => dispatch(setCurrentExamTypeIdRoutine(payload)),
    [dispatch]
  )

  const examTypes = useSelector(getExamTypes)
  const defaultExamType = useSelector(getDefaultExamType)

  const typesOptions: { label: any; value: any }[] = useSelector(
    getExamTypesAsOptions
  )
  const subtypesOptions: { label: string; value: string }[] = useSelector(
    getExamSubtypesAsOptions
  )

  React.useEffect(() => {
    fetchExamTypes()
    fetchAvailableExamTypes()
  }, [])

  React.useEffect(() => {
    const defaultType = R.propOr('', 'type', defaultExamType)

    if (isNotNilOrEmpty(defaultType)) {
      // @ts-ignore
      setType(defaultType)
      fetchExamSubtypes(defaultType)
    }
  }, [defaultExamType])

  React.useEffect(() => {
    const defaultSubtypeOption = R.head(subtypesOptions)
    if (isNotNilOrEmpty(defaultSubtypeOption) && isNotNilOrEmpty(type)) {
      handleSubtypeChange(defaultSubtypeOption)
    }
  }, [defaultExamType, type, subtypesOptions])

  const handleTypeChange = option => {
    setSubtype(null)
    setType(option.value)
    fetchExamSubtypes(option.value)
  }
  const handleSubtypeChange = option => {
    const examTypeId = R.pipe(
      R.find(
        R.allPass([R.propEq('type', type), R.propEq('subtype', option.value)])
      ),
      R.propOr('', 'id')
    )(examTypes)
    setSubtype(option.value)

    setCurrentExamTypeId(examTypeId)
    if (isNotNilOrEmpty(examTypeId)) {
      fetchScoreProjectionData({
        type_id: examTypeId
      })
    }
  }

  return (
    <Container>
      <SelectContainer>
        <SingleSelect
          size='small'
          label={t('diagnostics.labels.examType')}
          value={{ value: type, label: type }}
          options={typesOptions}
          onChange={handleTypeChange}
        />
      </SelectContainer>
      <SelectContainer>
        <SingleSelect
          size='small'
          label={t('diagnostics.labels.examSubtype')}
          value={{ value: subtype, label: subtype }}
          options={subtypesOptions}
          onChange={handleSubtypeChange}
          disabled={isNilOrEmpty(type)}
        />
      </SelectContainer>
    </Container>
  )
}

export default SelectProjectionType

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const SelectContainer = styled.div`
  min-width: 130px;

  & + & {
    margin-left: 10px;
  }
`

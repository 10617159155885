import { Action, NotesReducer } from 'types'
import { mapObjIndexed } from 'ramda'
// import { getAllExamQuestionsStates } from 'modules/exam/utils/examPagesConfig'
import { API_STATES } from 'utils/redux'
import {
  fetchNotesForCourseRoutine,
  fetchNotesForParticularBookRoutine
} from 'modules/notes/ducks/actions'

export const initialState: NotesReducer = {
  state: API_STATES.PRISTINE,
  notes: [],
  pagination: {
    page: 1,
    take: 10,
    recordsTotal: 0,
    pagesTotal: 1
  }
}

export default (state = initialState, action: Action): NotesReducer => {
  switch (action.type) {
    case fetchNotesForCourseRoutine.REQUEST:
    case fetchNotesForParticularBookRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchNotesForCourseRoutine.FAILURE:
    case fetchNotesForParticularBookRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchNotesForCourseRoutine.SUCCESS:
    case fetchNotesForParticularBookRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        notes: action.payload.data,
        pagination: mapObjIndexed(value => Number(value), action.payload.meta)
      }
    default:
      return state
  }
}

import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Modal, Button } from 'examkrackers-components'

import { deleteCustomBox } from 'services/FlashcardsService'
import { propOr } from 'ramda'
import { useDispatch } from 'react-redux'
import { showToastRoutine } from '../../toast/ducks/actions'
import { fetchCustomBoxesRoutine } from '../ducks/actions'
import { SEVERITY } from '../../../utils/toast'

export const ModalDeleteCustomBox = ({ open, handleClose, box }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const id: string = propOr('', 'id', box)
  const title: string = propOr('', 'title', box)
  const dispatch = useDispatch()

  const showToast = React.useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const fetchCustomBoxes = React.useCallback(
    () => dispatch(fetchCustomBoxesRoutine()),
    [dispatch]
  )

  const onDelete = e => {
    e.preventDefault()
    setIsLoading(true)

    const handleSuccess = () => {
      fetchCustomBoxes()
      showToast({
        key: 'toast.customBoxDeleteSuccess',
        severity: SEVERITY.success,
        options: {}
      })
      setIsLoading(false)
    }
    const handleError = () => {
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error,
        options: {}
      })
      setIsLoading(false)
    }

    deleteCustomBox({ id }).then(handleSuccess).catch(handleError)
  }

  return (
    <Modal
      title={t('flashcards.removeCustomBox.title')}
      open={open}
      handleClose={handleClose}
    >
      <>
        <div>{t('flashcards.removeCustomBox.description', { title })}</div>
        <ButtonsContainer>
          <Button
            size='small'
            color='secondary'
            id='delete-submit'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onDelete}
          >
            {t('flashcards.removeCustomBox.submitCTA')}
          </Button>
          <Button
            size='small'
            id='delete-cancel'
            disabled={isLoading}
            onClick={handleClose}
            type='button'
            color='tertiary'
            variant='contained'
            isLoading={isLoading}
          >
            {t('flashcards.removeCustomBox.cancelCTA')}
          </Button>
        </ButtonsContainer>
      </>
    </Modal>
  )
}

export default ModalDeleteCustomBox

const ButtonsContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  button {
    min-width: 154px;
  }
`

import { useEffect, useCallback } from 'react'
import { arrayContainsFullArray, arrayContainsPartArray } from 'utils/array'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedItems } from 'ducks/selection/selectors'
import {
  handleSelectCollectionRoutine,
  handleSelectItemRoutine,
  handleResetSelectionRoutine,
  handleSelectEntityRoutine,
  handleSelectEntitiesRoutine
} from 'ducks/selection/actions'
import { includes } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export default (visibleItems: any[], preventUnmount = false) => {
  const dispatch = useDispatch()
  const selectedItems = useSelector(getSelectedItems)
  const hasSomeItemSelected =
    // @ts-ignore
    arrayContainsPartArray(selectedItems)(visibleItems)
  const hasAllVisibleItemsSelected =
    arrayContainsFullArray(selectedItems)(visibleItems) &&
    isNotNilOrEmpty(visibleItems)

  const handleSelectItem = useCallback(
    item => dispatch(handleSelectItemRoutine({ item })),
    [dispatch]
  )

  const handleSelectEntity = useCallback(
    entity => dispatch(handleSelectEntityRoutine({ entity })),
    [dispatch]
  )

  const handleSelectCollection = useCallback(
    collection => dispatch(handleSelectCollectionRoutine({ collection })),
    [dispatch]
  )

  const handleSelectEntities = useCallback(
    entities => dispatch(handleSelectEntitiesRoutine({ entities })),
    [dispatch]
  )

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  // @ts-ignore
  const isItemSelected: (e) => boolean = (item: any) =>
    includes(item, selectedItems)

  useEffect(() => {
    return () => {
      !preventUnmount && handleResetSelection()
    }
  }, [])

  return {
    hasSomeItemSelected,
    hasAllVisibleItemsSelected,
    isItemSelected,
    handleSelectItem: item => () => handleSelectItem(item),
    handleSelectCollection: () => handleSelectCollection(visibleItems),
    handleSelectMultiple: collection => handleSelectCollection(collection),
    handleResetSelection,
    handleSelectEntity,
    handleSelectEntities
  }
}

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { BookContent, Subchapter } from 'types'
import { getBookContentsList } from 'modules/books/ducks/selectors'
import { useHistory } from 'react-router-dom'
import { SuspenseFadeIn } from 'examkrackers-components'

const BookContentPreview = React.lazy(
  () => import('modules/books/components/BookContentPreview')
)

interface BookContentsListProps {
  subchapter: Subchapter
}

export const BookContentsList = ({
  subchapter
}: BookContentsListProps): JSX.Element => {
  const bookContents: BookContent[] = useSelector(getBookContentsList)
  const subchapterId: string = R.propOr('', 'id')(subchapter)

  const {
    location: { search }
  } = useHistory()

  // @ts-ignore
  const getSubchapterBookContents: BookContent[] = R.pipe(
    R.filter(R.propEq('subchapter_id', subchapterId)),
    // @ts-ignore
    R.sortBy(R.prop('order'))
    // @ts-ignore
  )(bookContents)

  const renderList = useMemo(() => {
    return getSubchapterBookContents.map((bookContent: BookContent) => (
      <BookContentPreview
        key={`book-content-${bookContent.id}`}
        bookContent={bookContent}
      />
    ))
  }, [bookContents, subchapter, search])

  return <SuspenseFadeIn minHeight='700px'>{renderList}</SuspenseFadeIn>
}

export default BookContentsList

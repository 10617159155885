import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import { ActionPayload } from 'types'
import * as FlashcardsService from 'services/FlashcardsService'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/flashcards/utils/events'
import {
  startProficiencyLevelAnimation,
  startSkippedAnimation
} from 'utils/flashcards'

export const fetchFlashcardsListRoutine = createRoutine('FETCH_FLASHCARDS_LIST')
export const fetchArchiveFlashcardsListRoutine = createRoutine(
  'FETCH_ARCHIVE_FLASHCARDS_LIST'
)
export const fetchFlashcardsStudyListRoutine = createRoutine(
  'FETCH_FLASHCARDS_STUDY_LIST'
)

export const fetchPlvlStatsRoutine = createRoutine('FETCH_PLVL_STATS_ROUTINE')

export const updatePlvlStatsManuallyRoutine = createRoutine(
  'UPDATE_PLVL_STATS_MANUALLY'
)

export const skipCardRoutine = createRoutine('SKIP_CARD')

export const resetSkippedCardsRoutine = createRoutine('RESET_SKIPPED_CARDS')

export const fetchCustomBoxesRoutine = createRoutine('FETCH_CUSTOM_BOXES')

export const updatePlvlStatsAfterArchiveRoutine = createRoutine(
  'UPDATE_PLVL_STATS_AFTER_ARCHIVE'
)

function* fetchFlashcardsList({ payload }: ActionPayload) {
  yield Effects.put(fetchFlashcardsListRoutine.request())

  try {
    const result = yield Effects.call(
      FlashcardsService.fetchFlashcardsList,
      payload
    )
    yield Effects.put(
      fetchFlashcardsListRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchFlashcardsListRoutine.failure(e))
    console.error(e)
  }
}

function* fetchArchiveFlashcardsList({ payload }: ActionPayload) {
  yield Effects.put(fetchArchiveFlashcardsListRoutine.request())

  try {
    const result = yield Effects.call(
      FlashcardsService.fetchArchiveFlashcardsList,
      payload
    )
    yield Effects.put(
      fetchArchiveFlashcardsListRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchArchiveFlashcardsListRoutine.failure(e))
    console.error(e)
  }
}

function* fetchCustomBoxes() {
  yield Effects.put(fetchCustomBoxesRoutine.request())

  try {
    const result = yield Effects.call(FlashcardsService.fetchCustomBoxes)
    yield Effects.put(
      fetchCustomBoxesRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchCustomBoxesRoutine.failure(e))
    console.error(e)
  }
}

function* fetchFlashcardsStudyList({ payload }: ActionPayload) {
  yield Effects.put(fetchFlashcardsStudyListRoutine.request())
  const { callback = () => {} } = payload
  try {
    const result = yield Effects.call(
      FlashcardsService.fetchFlashcardsStudyList,
      R.dissoc('callback', payload)
    )
    const data = R.pathOr([], ['data'], result)
    yield Effects.put(fetchFlashcardsStudyListRoutine.success(data))
    callback()
    eventEmitter.emit(events.loadingFlashcardAnswer, false)
  } catch (e) {
    yield Effects.put(fetchFlashcardsStudyListRoutine.failure(e))
    console.error(e)
    callback()
  }
}

function* fetchPlvlStats({ payload }) {
  yield Effects.put(fetchPlvlStatsRoutine.request())
  try {
    const result = yield Effects.call(FlashcardsService.fetchPlvlStats, payload)
    const data = R.pathOr([], ['data'], result)
    yield Effects.put(fetchPlvlStatsRoutine.success(data))
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchPlvlStatsRoutine.failure(e))
  }
}

function* updatePlvlStatsManually({ payload }) {
  const { from, to } = payload

  try {
    startProficiencyLevelAnimation(from, to)
    yield Effects.delay(3000)
    yield Effects.put(updatePlvlStatsManuallyRoutine.success({ from, to }))
  } catch (e) {
    console.error(e)
  }
}

function* skipCard({ payload }) {
  const { plvl } = payload
  try {
    startSkippedAnimation(plvl)
    yield Effects.delay(3000)
    yield Effects.put(skipCardRoutine.success({ plvl }))
  } catch (e) {
    console.error(e)
  }
}

function* resetSkippedCards({ payload }) {
  const { plvl } = payload

  try {
    yield Effects.put(resetSkippedCardsRoutine.success({ plvl }))
  } catch (e) {
    console.error(e)
  }
}

function* updatePlvlStatsAfterArchive({
  payload: { values = {}, callback = () => {} }
}) {
  // @ts-ignore
  const { plvl } = values
  try {
    yield Effects.put(updatePlvlStatsAfterArchiveRoutine.success({ plvl }))
    callback()
  } catch (e) {
    console.error(e)
  }
}

export function* fetchFlashcardsListWatcher() {
  yield Effects.takeLatest(fetchFlashcardsListRoutine, fetchFlashcardsList)
}

export function* fetchArchiveFlashcardsListWatcher() {
  yield Effects.takeLatest(
    fetchArchiveFlashcardsListRoutine,
    fetchArchiveFlashcardsList
  )
}

export function* fetchFlashcardsStudyListWatcher() {
  yield Effects.takeLatest(
    fetchFlashcardsStudyListRoutine,
    fetchFlashcardsStudyList
  )
}

export function* updatePlvlStatsManuallyWatcher() {
  yield Effects.takeLatest(
    updatePlvlStatsManuallyRoutine,
    updatePlvlStatsManually
  )
}

export function* fetchPlvlStatsWatcher() {
  yield Effects.takeLatest(fetchPlvlStatsRoutine, fetchPlvlStats)
}

export function* skipCardWatcher() {
  yield Effects.takeEvery(skipCardRoutine, skipCard)
}

export function* resetSkippedCardsWatcher() {
  yield Effects.takeLatest(resetSkippedCardsRoutine, resetSkippedCards)
}

export function* fetchCustomBoxesWatcher() {
  yield Effects.takeLatest(fetchCustomBoxesRoutine, fetchCustomBoxes)
}

export function* updatePlvlStatsAfterArchiveWatcher() {
  yield Effects.takeEvery(
    updatePlvlStatsAfterArchiveRoutine,
    updatePlvlStatsAfterArchive
  )
}

export const flashcardsSagas = [
  Effects.fork(fetchFlashcardsListWatcher),
  Effects.fork(fetchArchiveFlashcardsListWatcher),
  Effects.fork(fetchFlashcardsStudyListWatcher),
  Effects.fork(updatePlvlStatsManuallyWatcher),
  Effects.fork(fetchPlvlStatsWatcher),
  Effects.fork(skipCardWatcher),
  Effects.fork(resetSkippedCardsWatcher),
  Effects.fork(fetchCustomBoxesWatcher),
  Effects.fork(updatePlvlStatsAfterArchiveWatcher)
]

import APIService from 'services/APIService'
import {
  FetchVideosListPayload,
  SaveVideoProgressPayload,
  CreateVideoRatePayload,
  FetchVideoRatePayload
} from 'types/videos'

const api = APIService()

export const fetchVideosList = (payload: FetchVideosListPayload) =>
  api.get(
    `/student-videos?filter%5Bcategory%5D=all&limit[page]=${payload.page}&limit[take]=${payload.take}`
  )

export const fetchBookVideosList = (payload: FetchVideosListPayload) =>
  api.get(
    `/student-videos/books?filter%5Bcategory%5D=all&limit[page]=${payload.page}&limit[take]=${payload.take}`
  )

export const saveVideoProgress = (payload: SaveVideoProgressPayload) =>
  api.patch(`/student-videos/${payload.id}`, {
    video_timestamp: payload.video_timestamp
  })

export const fetchVideoDetails = payload =>
  api.get(`/student-videos/${payload.id}`)

export const createVideoRate = (payload: CreateVideoRatePayload) =>
  api.post(`/student-video-ratings/${payload.id}`, {
    rating: payload.rating
  })

export const fetchVideoRate = (payload: FetchVideoRatePayload) =>
  api.get(`/student-video-ratings/${payload.videoId}`)

export const markVideoAsFavorite = payload =>
  api.post(`/student-favourite-videos/resource/${payload.id}/mark-as-favourite`)

export const unmarkVideoAsFavorite = payload =>
  api.post(
    `/student-favourite-videos/resource/${payload.id}/unmark-as-favourite`
  )

export const toggleFavouriteStateOfNonBookVideos = payload =>
  api.patch(`/favourite-videos/${payload.id}`)

export const fetchFavoriteVideosCount = () =>
  api.get('/student-favourite-videos/count')

export const fetchVideosByCategory = payload =>
  api.get(
    `/student-videos/category/foobar?filter[category]=${payload.category}`
  )

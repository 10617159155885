import { object, string } from 'yup'
import { validateValues, validateField } from 'utils/form'

const usernameRegex = /^([A-Za-z0-9_.-]{3,15})$/
const codeRegex = /^([0-9_.-]{6,6})$/

export const changeUsernameSchema = object().shape({
  username: string()
    .matches(usernameRegex, () => ({
      key: 'form.username.errors.regex'
    }))
    .required(() => ({
      key: 'form.username.errors.required'
    }))
})

export const validateChangeUsernameField = values =>
  validateField(changeUsernameSchema, values)
export const validateChangeUsernameValue = validateValues(changeUsernameSchema)

export const smsCodeSchema = object().shape({
  '2fa-verification-code': string()
    .matches(codeRegex, () => ({
      key: 'smsVerification.errors.regex'
    }))
    .required(() => ({
      key: 'smsVerification.errors.required'
    }))
})

export const validateSMSCodeField = code => validateField(smsCodeSchema, code)
export const validateSMSCodeValue = validateValues(smsCodeSchema)

import APIService from 'services/APIService'

const api = APIService()

export const getGettingStartedLink = () =>
  api.get('/support-tab/getting-started-link')

export const getVideoTutorialsLink = () =>
  api.get('/support-tab/training-tutorials-link')

export const getCommunityLink = () =>
  api.get('/support-tab/share-with-community-link')

export const getStatusPageLink = () => api.get('/support-tab/status-page-link')

export const getContactUsLink = () => api.get('/support-tab/contact-us-link')

export const getHelpCenterLink = () => api.get('/support-tab/help-center-link')

export const getAllSupportTabLinks = () => api.get('/support-tab')

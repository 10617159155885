import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import styled from 'styled-components'

import { getExamDetails } from 'modules/exam/ducks/selectors'
import { ExamSectionScoreDetails } from 'types'

import {
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHeader,
  Button,
  WarningReversed,
  ArrowNav,
  Heading1,
  Text
} from 'examkrackers-components'
import { SORT_DIRECTION } from 'utils/table'
import {
  EXAM_SCORES_STATUS,
  getSectionFullTitleFromExamDetails
} from 'utils/exam'

export const TableScaledScoreReport = (): JSX.Element => {
  const params = useParams()
  const id: string = R.propOr('', 'id')(params)
  const history = useHistory()
  const { t } = useTranslation()
  const examDetails = useSelector(getExamDetails)
  const scores = R.pathOr([], ['exam', 'scores', 'sections'])(examDetails)
  const hasScoresCalculated = R.pipe(
    // @ts-ignore
    R.pathOr(null, ['exam', 'scores_status']),
    R.equals(EXAM_SCORES_STATUS.calculated)
    // @ts-ignore
  )(examDetails)

  const examPercentileRank = R.pathOr(
    [],
    ['exam', 'scores', 'exam', 'percentile_rank']
  )(examDetails)
  const sumMappedProp = (
    propName: 'scaled_score' | 'amount_correct' | 'total_amount'
  ) =>
    R.pipe(
      (scores: ExamSectionScoreDetails[]) => R.map(R.prop(propName))(scores),
      (arr: number[]) => R.sum(arr)
    )(scores)

  const examScaledScore: number = sumMappedProp('scaled_score')
  const examCorrectAnswers: number = sumMappedProp('amount_correct')
  const examTotalAnswers: number = sumMappedProp('total_amount')

  const redirectToExams = () => () => history.push('/exams')

  const redirectToAnswerSheet = (sectionId: string) => () =>
    history.push(
      `/exam/${id}/score-report/answers-graph/${sectionId}/answer-sheet`
    )

  const redirectToDiagnostics = (sectionId: string) => () =>
    history.push(
      `/exam/${id}/score-report/answers-graph/${sectionId}/diagnostic`
    )

  const rows = R.map((score: ExamSectionScoreDetails) => ({
    id: `row-section-${score.title}`,
    cells: [
      {
        children: (
          <Text bold={false} size='s'>
            {getSectionFullTitleFromExamDetails(score.id)(examDetails)}
          </Text>
        ),
        columnId: 'title'
      },
      {
        children: (
          <Text bold={false} size='s'>
            {hasScoresCalculated ? score.scaled_score : '-'}
          </Text>
        ),
        columnId: 'scaled_score'
      },
      {
        children: (
          <Text bold={false} size='s'>
            {hasScoresCalculated ? `${score.percentile_rank}%` : '-'}
          </Text>
        ),
        columnId: 'percentile_rank'
      },
      {
        children: (
          <Text bold={false} size='s'>
            {score.amount_correct} of {score.total_amount}
          </Text>
        ),
        columnId: 'amount_correct'
      },
      {
        children: (
          <ButtonsContainer>
            <Button
              id='score-answers-cta'
              onClick={redirectToAnswerSheet(score.id)}
              size='small'
              color='tertiary'
              variant='contained'
            >
              {t('diagnostics.actions.answers')}
            </Button>
            <Button
              id='diagnostics-cta'
              onClick={redirectToDiagnostics(score.id)}
              size='small'
              color='tertiary'
              variant='contained'
            >
              {t('diagnostics.actions.diagnostics')}
            </Button>
          </ButtonsContainer>
        ),
        columnId: 'action'
      }
    ]
  }))(scores)

  const headers = [
    {
      columnId: 'title',
      sortable: false,
      id: 'title',
      children: (
        <Text bold size='s'>
          {t('diagnostics.table.headers.title')}
        </Text>
      )
    },
    {
      columnId: 'scaled_score',
      sortable: false,
      id: 'scaled_score',
      children: (
        <Text bold size='s'>
          {t('diagnostics.table.headers.scaled_score')}
        </Text>
      )
    },
    {
      columnId: 'percentile_rank',
      sortable: false,
      id: 'percentile_rank',
      children: (
        <Text bold size='s'>
          {t('diagnostics.table.headers.percentile_rank')}
        </Text>
      )
    },
    {
      columnId: 'amount_correct',
      sortable: false,
      id: 'amount_correct',
      children: (
        <Text bold size='s'>
          {t('diagnostics.table.headers.amount_correct')}
        </Text>
      )
    },
    {
      columnId: 'action',
      sortable: false,
      id: 'action',
      children: (
        <Text bold size='s'>
          {t('diagnostics.table.headers.action')}
        </Text>
      )
    }
  ]

  const renderEmptyState = (
    <TableRow>
      <TableCell colSpan={headers.length}>
        <TableEmptyState>
          <WarningReversed />
          {t('diagnostics.table.emptyState')}
        </TableEmptyState>
      </TableCell>
    </TableRow>
  )

  const renderHeaders = headers.map(header => (
    <TableHeader
      key={header.columnId}
      sortable={header.sortable}
      sortDirection={SORT_DIRECTION.asc}
      columnId={header.columnId}
      id={header.id}
      sticky={false}
      isSortActive={false}
      onChangeSort={() => {}}
    >
      {header.children}
    </TableHeader>
  ))

  const renderRows = rows.map(row => (
    <TableRow key={row.id} id={row.id} className='summary__section-row'>
      {row.cells.map(cell => (
        <TableCell key={cell.columnId}>{cell.children}</TableCell>
      ))}
    </TableRow>
  ))

  return (
    <Container>
      <Arrows>
        <ArrowNav
          direction='left'
          text={t('exams.title')}
          onClick={redirectToExams()}
        />
        <ArrowNav
          direction='right'
          text={t('report.answers')}
          onClick={redirectToAnswerSheet(R.propOr('', 'id', scores[0]))}
        />
      </Arrows>
      <TableHeaderContainer>
        <TableCell>
          <CellContainer>
            <Heading1 bold>
              {R.pathOr('-', ['exam', 'title'], examDetails)}
            </Heading1>
          </CellContainer>
        </TableCell>
        {hasScoresCalculated && (
          <>
            <TableCell>
              <CellContainer>
                <Text bold={false} size='xs'>
                  {t('diagnostics.table.headers.scaled_score')}
                </Text>
                <Heading1 bold>{examScaledScore}</Heading1>
              </CellContainer>
            </TableCell>
            <TableCell>
              <CellContainer>
                <Text bold={false} size='xs'>
                  {t('diagnostics.table.headers.percentile_rank')}
                </Text>
                <Heading1 bold>{examPercentileRank}%</Heading1>
              </CellContainer>
            </TableCell>
            <TableCell>
              <CellContainer>
                <Text bold={false} size='xs'>
                  {t('diagnostics.table.headers.amount_correct')}
                </Text>
                <Heading1 bold>
                  {examCorrectAnswers}{' '}
                  <ColorSpan>of {examTotalAnswers}</ColorSpan>
                </Heading1>
              </CellContainer>
            </TableCell>
            <TableCell />
          </>
        )}
        {!hasScoresCalculated && (
          <>
            <TableCell>
              <PendingLabelsContainer>
                <div className='pending'>
                  <Text bold={false} size='xs'>
                    {t('diagnostics.table.headers.scaled_score')}
                  </Text>
                  <Text bold={false} size='xs'>
                    {t('diagnostics.table.headers.percentile_rank')}
                  </Text>
                </div>
                <Text bold={false} size='xs'>
                  {t('diagnostics.table.headers.amount_correct')}
                </Text>
              </PendingLabelsContainer>
              <PendingTextContainer>
                <div className='pending'>
                  <Text size='xs' bold>
                    {t('report.pending_1')}
                  </Text>
                  <Text size='xs' bold>
                    {t('report.pending_2')}
                  </Text>
                </div>
                <div>
                  <Heading1 bold>
                    {examCorrectAnswers}{' '}
                    <ColorSpan>of {examTotalAnswers}</ColorSpan>
                  </Heading1>
                </div>
              </PendingTextContainer>
            </TableCell>
          </>
        )}
      </TableHeaderContainer>

      <TableContainer>
        <Table celpadding='0' celspacing='0'>
          <TableBody>
            <TableRow className='summary__headers'>{renderHeaders}</TableRow>
            {scores.length > 0 ? renderRows : renderEmptyState}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

const CellContainer = styled.div`
  text-align: center;

  * {
    white-space: nowrap;
  }
`

const ColorSpan = styled.span`
  color: ${({ theme }) => theme.colors.main.secondary500};
`

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 48px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.main.grey600};
  max-width: 720px;
  padding: 24px 48px 0px 48px;

  td {
    padding: 8px 0px;
    border: none !important;

    &:first-child {
      text-align: left;
      white-space: nowrap;
    }
  }
`

const TableContainer = styled.div`
  padding: 16px;
  background-color: ${props => props.theme.colors.backgrounds.main};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`

const Container = styled.div`
  margin-top: 50px;

  table {
    padding: 0;
    overflow: hidden;
    border-collapse: collapse;

    td {
      line-height: 1;
      text-align: center;

      &:first-child {
        text-align: left;
        padding: 0 2em;
      }
    }

    tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
    }

    h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th {
      border: none;
      text-align: center;
    }
  }

  .summary__section-row {
    td {
      border: 0px solid transparent;
      padding: 1em;

      &:first-child {
        width: 35%;
      }
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

const Arrows = styled.div`
  display: flex;
  justify-content: space-between;
`

const TableEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.fontSizeSmall};
  padding: 2rem 0;

  svg {
    color: ${({ theme }) => theme.colors.main.error500};
    margin-right: 10px;
    font-size: 20px;
  }
`

const PendingLabelsContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 289px;
  justify-content: space-between;
  margin-bottom: 0.8rem;

  .pending {
    min-width: 175px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  & div:last-child {
    margin-left: 1rem;
  }
`

const PendingTextContainer = styled.div`
  min-width: 289px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pending {
    min-width: 175px;
  }

  & div:last-child {
    margin-left: 1rem;
  }
`

export default TableScaledScoreReport

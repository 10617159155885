import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Heading2 } from 'examkrackers-components'

import { selectListOfTopics } from 'modules/courseTopics/ducks/selectors'

import CourseTopicsTable from 'modules/courseTopics/components/CourseTopicsTable'
import ListOfTopicsInfiniteScroller from 'modules/courseTopics/components/CourseTopicsInfiniteScroll'
import CourseTopicsFilters from 'modules/courseTopics/components/CourseTopicsFilters'

import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const CourseTopics = (): JSX.Element => {
  const { t } = useTranslation()
  const topics = useSelector(selectListOfTopics)
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const [seeAAMCChecklistPage, setSeeAAMCChecklistPage] = useState(true)

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && seeAAMCChecklistPage) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeAAMCChecklistPage, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
      setSeeAAMCChecklistPage(false)
    }
  }, [currentCourse, seeAAMCChecklistPage])

  return (
    <Container>
      <br />
      <CourseTopicsTable
        hideLoadMoreButton={false}
        isBookView={false}
        topics={topics}
        stickyHeader
        header={
          <HeadingContainer>
            <Heading2 bold>{t('topics.titleList')}</Heading2>
            <CourseTopicsFilters />
          </HeadingContainer>
        }
      />
      <ListOfTopicsInfiniteScroller />
    </Container>
  )
}

export default CourseTopics

const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`
const Container = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border-radius: 6px;
`

// module/exams/ducks/reducer.ts - Exam Saga Reducer

import * as R from 'ramda'
import { Action, FlashcardsReducer } from 'types'
import { API_STATES } from 'utils/redux'
import {
  fetchFlashcardsListRoutine,
  fetchFlashcardsStudyListRoutine,
  updatePlvlStatsManuallyRoutine,
  fetchPlvlStatsRoutine,
  resetSkippedCardsRoutine,
  skipCardRoutine,
  fetchCustomBoxesRoutine,
  fetchArchiveFlashcardsListRoutine,
  updatePlvlStatsAfterArchiveRoutine
} from 'modules/flashcards/ducks/actions'

export const initialState: FlashcardsReducer = {
  state: API_STATES.PRISTINE,
  flashcards: [],
  flashcardsArchive: [],
  studyList: [],
  proficiencyLevelStats: [],
  customBoxes: [],
  skippedCards: 0,
  studyListPagination: {
    is_last_flashcard: false
  },
  pagination: {
    page: 1,
    take: 10,
    recordsTotal: 0,
    pagesTotal: 1
  },
  flashcardsArchivePagination: {
    page: 1,
    take: 10,
    recordsTotal: 0,
    pagesTotal: 1
  }
}

const countNumber = stat => Number(R.propOr(0, 'count', stat))

const updateStatIfNeeded = (stat, from, to) => {
  switch (true) {
    case R.propOr(0, 'proficiency_level', stat) === from:
      return {
        ...stat,
        count: countNumber(stat) > 0 ? countNumber(stat) - 1 : 0
      }
    case R.propOr(0, 'proficiency_level', stat) === to:
      return {
        ...stat,
        count: countNumber(stat) + 1
      }
    default:
      return stat
  }
}

const subtractStatIfNeeded = (stat, plvl) => {
  switch (true) {
    case R.propOr(0, 'proficiency_level', stat) === Number(plvl):
      return {
        ...stat,
        count: countNumber(stat) - 1
      }
    default:
      return stat
  }
}

const addStatIfNeeded = (stat, plvl, skippedCount) => {
  switch (true) {
    case R.propOr(0, 'proficiency_level', stat) === Number(plvl):
      return {
        ...stat,
        count: countNumber(stat) + Number(skippedCount)
      }
    default:
      return stat
  }
}

const updatePlvlStats = (stats, from, to) =>
  R.map(stat => updateStatIfNeeded(stat, from, to))(stats)

const subtractFromPlvl = (stats, plvl) =>
  R.map(stat => subtractStatIfNeeded(stat, plvl))(stats)

const addToPlvl = (stats, plvl, skippedCount) =>
  plvl > 0
    ? R.map(stat => addStatIfNeeded(stat, plvl, skippedCount))(stats)
    : stats

// Exams Reducer
export default (state = initialState, action: Action): FlashcardsReducer => {
  switch (action.type) {
    case fetchFlashcardsListRoutine.REQUEST:
    case fetchFlashcardsStudyListRoutine.REQUEST:
    case fetchArchiveFlashcardsListRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchFlashcardsListRoutine.FAILURE:
    case fetchFlashcardsStudyListRoutine.FAILURE:
    case fetchArchiveFlashcardsListRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchFlashcardsListRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        flashcards: action.payload.data,
        pagination: R.mapObjIndexed(value => Number(value), action.payload.meta)
      }
    case fetchFlashcardsStudyListRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        studyList: action.payload.data,
        studyListPagination: {
          is_last_flashcard: action.payload.is_last_flashcard
        }
      }
    case fetchArchiveFlashcardsListRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        flashcardsArchive: action.payload.data,
        flashcardsArchivePagination: R.mapObjIndexed(
          value => Number(value),
          action.payload.meta
        )
      }
    case updatePlvlStatsManuallyRoutine.SUCCESS:
      return {
        ...state,
        proficiencyLevelStats: updatePlvlStats(
          state.proficiencyLevelStats,
          action.payload.from,
          action.payload.to
        )
      }
    case fetchPlvlStatsRoutine.SUCCESS:
      return {
        ...state,
        proficiencyLevelStats: action.payload
      }
    case resetSkippedCardsRoutine.SUCCESS:
      return {
        ...state,
        skippedCards: 0,
        proficiencyLevelStats: addToPlvl(
          state.proficiencyLevelStats,
          action.payload.plvl,
          state.skippedCards
        )
      }
    case skipCardRoutine.SUCCESS:
      return {
        ...state,
        skippedCards: state.skippedCards + 1,
        proficiencyLevelStats: subtractFromPlvl(
          state.proficiencyLevelStats,
          action.payload.plvl
        )
      }
    case fetchCustomBoxesRoutine.SUCCESS:
      return {
        ...state,
        customBoxes: action.payload.data
      }
    case updatePlvlStatsAfterArchiveRoutine.SUCCESS:
      return {
        ...state,
        proficiencyLevelStats: subtractFromPlvl(
          state.proficiencyLevelStats,
          action.payload.plvl
        )
      }
    default:
      return state
  }
}

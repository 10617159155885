import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  validateCreateCustomBoxField,
  validateCreateCustomBoxValues
} from 'modules/flashcards/ducks/schema'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { createCustomBox } from 'services/FlashcardsService'

import { InputField, Button } from 'examkrackers-components'
import { SEVERITY } from 'utils/toast'
import { Book } from 'types'
import { propOr } from 'ramda'
import { fetchCustomBoxesRoutine } from '../ducks/actions'
import { getErrorName, errors } from 'utils/errors'

const emptyValues = {
  title: ''
}

interface FormCreateCustomBoxProps {
  book: Book
  handleClose: () => any
}

export const FormCreateCustomBox = ({
  handleClose,
  book
}: FormCreateCustomBoxProps): JSX.Element => {
  const tag = propOr('', 'tag', book)
  const bookId: string = propOr('', 'id', book)

  const [valid, _validateSchema] = React.useState(false)
  const [values, setValues] = React.useState(emptyValues)
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const showToast = React.useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const fetchCustomBoxes = React.useCallback(
    () => dispatch(fetchCustomBoxesRoutine()),
    [dispatch]
  )

  const handleOnChange = (name: string, value: any) =>
    setValues({ ...values, [name]: value })

  useEffect(() => {
    setValues({
      title: `${tag}_`
    })
  }, [book])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)

    const handleSuccess = () => {
      showToast({
        key: 'toast.customBoxCreateSuccess',
        severity: SEVERITY.success,
        options: {}
      })
      fetchCustomBoxes()
      setIsLoading(false)
      handleClose()
    }

    const handleError = e => {
      if (getErrorName(e) === errors.flashcardBoxExists) {
        showToast({
          key: 'toast.customBoxNameAlreadyExists',
          severity: SEVERITY.error,
          options: { title: values.title }
        })
      } else {
        showToast({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error,
          options: {}
        })
      }
      setIsLoading(false)
    }

    const payload = {
      title: values.title,
      student_book_id: bookId
    }

    createCustomBox(payload).then(handleSuccess).catch(handleError)
  }

  React.useEffect(() => {
    validateCreateCustomBoxValues(values, _validateSchema)
  }, [values])

  return (
    <Form onSubmit={handleSubmit}>
      <InputContainer>
        <InputField
          t={t}
          required
          value={values.title}
          name='title'
          id='create-custom-box-title'
          label={t('form.customBox.label')}
          onChange={handleOnChange}
          validate={validateCreateCustomBoxField(values)}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          type='submit'
          id='create-custom-box-submit'
          disabled={isLoading || !valid}
          isLoading={isLoading}
          onClick={handleSubmit}
          color='secondary'
          size='small'
        >
          {t('flashcards.customBox.submitCTA')}
        </Button>
        <Button
          type='button'
          id='create-custom-box-cancel'
          isLoading={isLoading}
          onClick={handleClose}
          color='secondary'
          size='small'
          variant='outlined'
        >
          {t('flashcards.customBox.cancelCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default FormCreateCustomBox

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

const InputContainer = styled.div`
  max-width: 120px;
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;

  button {
    min-width: 158px !important;
    flex: none;
  }
`

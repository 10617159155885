import React, { useMemo, useState } from 'react'
import { pipe, propOr } from 'ramda'
import { addDaysToDate, getUniversalDate, isBeforeToday } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import { addDays, subDays } from 'date-fns'

interface UseCalendarSettingsValidationForExpirationProps {
  course: any
  values: any
  mcatDateOptions: any
  columnsData: any
  isReorder: boolean
  canCalendarHaveSpaceForPreReading?: boolean
}

const useCalendarSettingsValidationForExpiration = ({
  course,
  values,
  mcatDateOptions,
  columnsData,
  isReorder,
  canCalendarHaveSpaceForPreReading
}: UseCalendarSettingsValidationForExpirationProps) => {
  const [touchedFields, setTouchedFields] = useState([])

  const hasStudentPreReading = course.is_pre_reading

  const mcatDate = useMemo(() => {
    const mcatDateOption =
      mcatDateOptions.find(el => el.value === values.mcat_at)?.label || null
    return mcatDateOption
      ? new Date(
          mcatDateOption.slice(6, 10) +
            '-' +
            mcatDateOption.slice(0, 2) +
            '-' +
            mcatDateOption.slice(3, 5)
        )
      : ''
  }, [values])

  const courseEndDate = useMemo(() => {
    const accessibleTo = propOr('', 'accessible_to', course)

    const daysAmount = pipe(
      propOr('{}', 'metadata'),
      JSON.parse,
      propOr('0', 'days_amount'),
      Number
    )(course)

    const calculatedAccessibleTo = addDaysToDate(new Date(), daysAmount)

    return isNotNilOrEmpty(accessibleTo)
      ? new Date(accessibleTo as string)
      : calculatedAccessibleTo
  }, [course])

  const errors = useMemo(() => {
    const result = {}

    const hasStudyDaysSelected = values.prioriDays?.some(el =>
      [1, 2, 3, 4, 5, 6, 7].includes(el)
    )
    const mcatDateOption =
      mcatDateOptions.find(el => el.value === values.mcat_at)?.label || null
    const mcatDate = mcatDateOption
      ? new Date(
          mcatDateOption.slice(6, 10) +
            '-' +
            mcatDateOption.slice(0, 2) +
            '-' +
            mcatDateOption.slice(3, 5)
        ).getTime()
      : 0

    const startDate = getUniversalDate(values?.calendar_start_at).getTime()
    const endDate = getUniversalDate(values?.exam_at).getTime()
    const courseEndDateUniversal = getUniversalDate(courseEndDate)

    if (hasStudentPreReading) {
      if (!isReorder) {
        if (!startDate) {
          result['startDate'] = 'Please select start date.'
        } else if (startDate && courseEndDateUniversal.getTime() < startDate) {
          result['startDate'] =
            'Start date must be before your course end date.'
        } else if (startDate && endDate < startDate) {
          result['startDate'] = 'Start date must be before end date.'
        } else if (startDate && mcatDate < startDate) {
          result['startDate'] = 'Start date must be before MCAT date.'
        } else if (startDate && isBeforeToday(startDate)) {
          result['startDate'] = 'The start date cannot be in the past.'
        }
      }
      if (!endDate) {
        result['endDate'] = 'Please select end date.'
      } else if (endDate && courseEndDateUniversal.getTime() < endDate) {
        result['endDate'] = 'End date must be before your course end date.'
      } else if (endDate && endDate < startDate) {
        result['endDate'] = 'End date must be after start date.'
      } else if (endDate && mcatDate < endDate) {
        result['endDate'] = 'End date must be before MCAT date.'
      }

      if (!hasStudyDaysSelected) {
        result['hasStudyDaysSelected'] =
          'At least one study day must be selected.'
      }
    }
    if (!isReorder) {
      if (!startDate) {
        result['startDate'] = 'Please select start date.'
      } else if (startDate && courseEndDateUniversal.getTime() < startDate) {
        result['startDate'] = 'Start date must be before your course end date.'
      } else if (startDate && endDate < startDate) {
        result['startDate'] = 'Start date must be before end date.'
      } else if (startDate && mcatDate < startDate) {
        result['startDate'] = 'Start date must be before MCAT date.'
      } else if (startDate && isBeforeToday(startDate)) {
        result['startDate'] = 'The start date cannot be in the past.'
      }
    }
    if (canCalendarHaveSpaceForPreReading) {
      if (!endDate && !isReorder) {
        result['endDate'] = 'Please select end date.'
      } else if (endDate && courseEndDateUniversal.getTime() < endDate) {
        result['endDate'] = 'End date must be before your course end date.'
      } else if (endDate && endDate < startDate) {
        result['endDate'] = 'End date must be after start date.'
      } else if (endDate && mcatDate < endDate) {
        result['endDate'] = 'End date must be before MCAT date.'
      }
      if (!hasStudyDaysSelected && !isReorder) {
        result['hasStudyDaysSelected'] =
          'At least one study day must be selected.'
      }
    }

    return result
  }, [values, columnsData, courseEndDate, mcatDateOptions])

  const examDateBoundaries = useMemo(() => {
    const mcatTimestamp = mcatDate !== '' ? mcatDate.getTime() : null
    const courseEndDateTimestamp = courseEndDate.getTime()

    const max =
      mcatTimestamp && mcatTimestamp < courseEndDateTimestamp
        ? mcatDate
        : courseEndDate

    const min = isNotNilOrEmpty(values.calendar_start_at)
      ? new Date(values.calendar_start_at)
      : null

    return {
      min: min ? addDays(min, 1) : '',
      max: subDays(max, 1)
    }
  }, [mcatDate, courseEndDate, values])

  const startDateMax = useMemo(() => {
    const mcatTimestamp = mcatDate !== '' ? mcatDate.getTime() : null
    const courseEndDateTimestamp = courseEndDate.getTime()

    const examDate = isNotNilOrEmpty(values.exam_at)
      ? new Date(values.exam_at)
      : null

    const properDate =
      examDate ||
      (mcatTimestamp && mcatTimestamp < courseEndDateTimestamp
        ? mcatDate
        : courseEndDate)

    return subDays(properDate, 1)
  }, [mcatDate, courseEndDate, values])

  const dndErrors = [errors?.hasStudyDaysSelected].filter(el =>
    isNotNilOrEmpty(el)
  )

  return {
    dndErrors,
    startDateMax,
    examDateBoundaries,
    errors,
    touchedFields,
    setTouchedFields
  }
}

export default useCalendarSettingsValidationForExpiration

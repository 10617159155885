import APIService from 'services/APIService'

const api = APIService()

export const fetchClassDays = (id: string) =>
  api.get(`/end-date-days/end-date/${id}`)

export const fetchClassMeetingLink = (id: string) =>
  api.get(`/course-end-dates/course/${id}?order=desc`)

export const getClassInfo = (id: string) =>
  api.get(`/student-courses/${id}/class`)

import APIService from 'services/APIService'
import {
  GetNotesForParticularBookPayload,
  GetNotesForCoursePayload
} from 'types'

const api = APIService()

export const fetchNotesForCourse = (payload: GetNotesForCoursePayload) =>
  api.get(`/student-book-subchapter/course/notes?${payload.query}`)

export const fetchNotesForParticularBook = (
  payload: GetNotesForParticularBookPayload
) =>
  api.get(
    `/student-book-subchapter/book/${payload.bookId}/notes?${payload.query}`
  )

import * as R from 'ramda'
import { createSelector, OutputSelector } from 'reselect'
import { RootReducer } from 'types'

// Selector
export const selectExamTypes = (state: RootReducer) => state.examTypes

export const getExamTypes: OutputSelector<any, any, (res: any) => any> =
  createSelector(selectExamTypes, R.propOr([], 'examTypes'))

export const getExamAvailableTypes: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(selectExamTypes, R.propOr([], 'availableTypes'))

export const getExamSubtypes: OutputSelector<any, any, (res: any) => any> =
  createSelector(selectExamTypes, R.propOr([], 'examSubtypes'))

export const getCurrentExamTypeId: OutputSelector<any, any, (res: any) => any> =
  createSelector(selectExamTypes, R.propOr([], 'currentTypeId'))

export const getExamTypesAsOptions: OutputSelector<
  any,
  { label: ReturnType<<T>(a: T) => T>; value: ReturnType<<T>(a: T) => T> }[],
  (
    res1: any,
    res2: any
  ) => {
    label: ReturnType<<T>(a: T) => T>
    value: ReturnType<<T>(a: T) => T>
  }[]
> = createSelector(
  getExamTypes,
  getExamAvailableTypes,
  (types, availableTypes) =>
    R.pipe(
      R.map(R.prop('type')),
      R.uniq,
      R.filter(type => R.includes(type, availableTypes)),
      R.map(
        R.applySpec({
          label: R.identity,
          value: R.identity
        })
      )
    )(types)
)

export const getExamSubtypesAsOptions: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  getExamSubtypes,
  R.pipe(
    R.map(
      R.applySpec({
        label: R.identity,
        value: R.identity
      })
    )
  )
)

export const getDefaultExamType: OutputSelector<
  any,
  unknown,
  (res1: any, res2: any) => unknown
> = createSelector(
  getExamTypes,
  getExamAvailableTypes,
  (types, availableTypes) =>
    R.pipe(
      // @ts-ignore
      R.filter(type => R.includes(R.prop('type', type), availableTypes)),
      R.head
      // @ts-ignore
    )(types)
)

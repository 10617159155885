import React from 'react'
import ApexChart from 'react-apexcharts'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ScoreProjectionDataEntity, ScoreProjectionDetail } from 'types'
import { ToggleSwitch } from 'examkrackers-components'

import {
  projectedScoreGraphOptions,
  projectedScoreGraphSeries
} from 'utils/chart'
import { findScoresByPropEq } from 'utils/diagnostics'

import { getScoreProjectionData } from 'modules/diagnostics/ducks/selectors'
import { toggleExamExclusionRoutine } from 'modules/diagnostics/ducks/actions'

export const GraphScoreProjection = (): JSX.Element => {
  // Get URL parameters
  const params = useParams()

  // Get React-Redux dispatcher
  const dispatch = useDispatch()

  // Handle dispatch calls to toggle the exclusion of an exam.
  const toggleExamExclusion = React.useCallback(
    (id: string) => dispatch(toggleExamExclusionRoutine({ values: { id } })),
    [dispatch]
  )

  const [xLabelPositions, setXLabelPositions] = React.useState([])

  const scoreProjectionData: ScoreProjectionDataEntity[] = useSelector(
    getScoreProjectionData
  )

  // Handler for toggling exam exclusion
  const onToggle = (id: string) => toggleExamExclusion(id)

  // Get graph data from score projection
  const getGraphData = () => {
    // @ts-ignore
    const exam: ScoreProjectionDetail[] = findScoresByPropEq(
      'name',
      'total'
    )(scoreProjectionData)

    return exam
  }

  const data = getGraphData()

  const saveXLabelPositions = () => {
    const xAxis =
      document.getElementsByClassName(
        'apexcharts-text apexcharts-xaxis-label'
      ) || []
    const elements = Array.from(xAxis)
    const positions = elements.map(element => element.getAttribute('x'))
    // @ts-ignore
    setXLabelPositions(positions)
  }

  const handleToggle = (id: string, disabled: boolean) => () =>
    disabled ? null : onToggle(id)

  const renderToggles = data.map((point, index) =>
    index === data.length - 1 ? (
      <TargetLabel x={xLabelPositions[index]} key={`${index}-col-toggle`}>
        MCAT
        <br />
        Target Score
      </TargetLabel>
    ) : (
      // @ts-ignore
      <ToggleContainer x={xLabelPositions[index]} key={`${index}-col-toggle`}>
        <ToggleSwitch
          checked={!point.is_excluded_from_pts}
          value={!point.is_excluded_from_pts}
          disabled={!point.completed}
          label='on'
          name={`toggle-exam-${point.title}`}
          id={`toggle-exam-${point.title}`}
          onChange={handleToggle(point.id, !point.completed)}
        />
      </ToggleContainer>
    )
  )

  React.useEffect(() => {
    saveXLabelPositions()
  }, [scoreProjectionData, params])

  return (
    <ChartContainer id='chart'>
      {/* @ts-ignore */}
      <ApexChart
        // @ts-ignore
        options={projectedScoreGraphOptions(data)}
        series={projectedScoreGraphSeries(data)}
        type='line'
        width='100%'
      />
      <div className='score-projection-graph__toggles'>{renderToggles}</div>
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  padding: 1.0526rem 2.1053rem;
  position: relative;

  svg {
    overflow: visible !important;

    g[seriesName='TargetxScore'] {
      path {
        stroke-dasharray: 0 !important;
      }
    }

    g.apexcharts-datalabels g:last-child text {
      fill: ${({ theme }) =>
        theme.colors.diagnostics.projections.scaled} !important;
      font-weight: bold;
    }

    g.apexcharts-datalabels:last-child g:last-child text {
      fill: ${({ theme }) =>
        theme.colors.diagnostics.projections.target} !important;
      font-weight: bold;
    }
  }

  .score-projection__target-score {
    padding: 0.5263rem;
  }

  .score-projection-graph__toggles {
    width: 100%;
    position: absolute;
    min-height: 30px;
    bottom: 0;
  }
`

const TargetLabel = styled.div`
  display: inline-block;
  position: absolute;
  left: ${({ x }) => x - 30}px;
  top: -25px;
  white-space: nowrap;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
`

const ToggleContainer = styled.div`
  display: inline-block;
  position: absolute;
  left: ${({ x }) => x - 10}px;
`

export default GraphScoreProjection

import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'

import * as Effects from 'utils/saga'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import * as CourseService from 'services/CourseService'
import LocalStorageService from 'services/LocalStorageService'

export const fetchCoursesListRoutine = createRoutine('FETCH_COURSES_LIST')
export const fetchCurrentCourseRoutine = createRoutine('FETCH_CURRENT_COURSE')
export const removeCurrentCourseRoutine = createRoutine('REMOVE_CURRENT_COURSE')

function* fetchCoursesList() {
  yield Effects.put(fetchCoursesListRoutine.request())

  try {
    const result = yield Effects.call(CourseService.fetchAllCourses)
    yield Effects.put(
      fetchCoursesListRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchCoursesListRoutine.failure(e))
    console.error(e)
  }
}

function* fetchCurrentCourse() {
  const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)

  yield Effects.put(fetchCurrentCourseRoutine.request())

  try {
    const result = yield Effects.call(CourseService.fetchSingleCourse, {
      id: courseId
    })
    yield Effects.put(fetchCurrentCourseRoutine.success(result))
  } catch (e) {
    yield Effects.put(fetchCurrentCourseRoutine.failure(e))
    console.error(e)
  }
}

function* removeCurrentCourse() {
  yield Effects.put(removeCurrentCourseRoutine.success())
}

export function* fetchCoursesListWatcher() {
  yield Effects.takeLatest(fetchCoursesListRoutine, fetchCoursesList)
}

export function* fetchCurrentCourseWatcher() {
  yield Effects.takeLatest(fetchCurrentCourseRoutine, fetchCurrentCourse)
}

export function* removeCurrentCourseWatcher() {
  yield Effects.takeLatest(removeCurrentCourseRoutine, removeCurrentCourse)
}

export const courseSagas = [
  Effects.fork(fetchCoursesListWatcher),
  Effects.fork(fetchCurrentCourseWatcher),
  Effects.fork(removeCurrentCourseWatcher)
]

import React from 'react'

export const SpeedometerBackLightTheme = ({
  orangeBlurDeviation,
  yellowBlurDeviation,
  acceleration
}): JSX.Element => {
  return (
    <svg
      width='412'
      height='301'
      viewBox='0 0 412 301'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      id='speedometer-back'
    >
      <g filter='url(#filter0_dii_7306_75173)'>
        <path
          d='M365 169C365 232.513 293.813 284 206 284C118.187 284 47 232.513 47 169C47 105.487 118.187 54 206 54C293.813 54 365 105.487 365 169Z'
          fill='url(#paint0_linear_7306_75173)'
        />
      </g>
      <g filter='url(#filter21_dii_7306_75173)'>
        <circle
          cx='76.5'
          cy='220.5'
          r='73.5'
          fill='url(#paint26_linear_7306_75173)'
        />
      </g>
      <g>
        <circle
          cx='76.5'
          cy='220.5'
          r='72.5'
          fill='url(#paint26_linear_7306_75173)'
        />
      </g>
      <g>
        <circle
          cx='76.5'
          cy='220.5'
          r='66.5'
          fill='url(#paint0_linear_7306_75173)'
        />
      </g>
      <g filter='url(#filter3_i_7306_75173)'>
        <circle cx='76.5' cy='220.5' r='66.5' />
      </g>
      <path
        d='M85.4688 252.393C85.3366 252.376 85.2022 252.39 85.0767 252.435C84.9512 252.48 84.8384 252.555 84.7476 252.653C84.5561 252.833 84.3758 253.036 84.173 253.205C83.6925 253.667 83.0526 253.924 82.387 253.924C81.7213 253.924 81.0814 253.667 80.601 253.205C80.4094 253.036 80.2291 252.844 80.0488 252.664C79.8912 252.493 79.6724 252.392 79.4404 252.382C79.4404 252.382 79.3164 252.314 79.3164 252.28C79.3164 251.807 79.3164 251.333 79.3164 250.803C79.5822 250.845 79.8455 250.901 80.1052 250.972C80.5294 251.104 80.9157 251.336 81.232 251.649C81.3793 251.779 81.5185 251.919 81.6489 252.066C81.8573 252.26 82.131 252.367 82.4151 252.367C82.6993 252.367 82.973 252.26 83.1814 252.066C83.4081 251.811 83.6611 251.58 83.9363 251.378C84.3766 251.023 84.9261 250.832 85.4913 250.837L85.4688 252.393Z'
        fill='black'
      />
      <path
        d='M76.9936 241.586H80.0698V243.131H77.0161V244.666H80.0698V246.166H77.0048V247.711H80.0698V249.234H77.0161C77.0161 249.302 77.0161 249.347 77.0161 249.392C77.0274 249.584 77.0274 249.776 77.0161 249.967C77.0022 250.094 77.0184 250.223 77.0634 250.342C77.1085 250.462 77.181 250.569 77.2753 250.655C77.4505 250.839 77.5868 251.057 77.6758 251.295C77.7648 251.533 77.8047 251.787 77.7931 252.041C77.7815 252.295 77.7187 252.544 77.6084 252.773C77.4981 253.002 77.3426 253.207 77.1513 253.374C76.7718 253.713 76.273 253.888 75.7648 253.86C75.2566 253.831 74.7805 253.602 74.4413 253.222C74.1021 252.842 73.9276 252.342 73.9561 251.834C73.9847 251.325 74.2139 250.848 74.5935 250.509C74.6358 250.473 74.6692 250.428 74.6907 250.377C74.7123 250.326 74.7214 250.271 74.7174 250.215C74.7174 247.207 74.7174 244.222 74.7174 241.259C74.6914 240.996 74.7584 240.732 74.9066 240.514C75.0549 240.295 75.275 240.136 75.5287 240.063C75.6973 240.004 75.8775 239.986 76.0545 240.011C76.2314 240.035 76.4 240.101 76.5464 240.204C76.6928 240.306 76.8127 240.442 76.8963 240.6C76.98 240.758 77.0249 240.934 77.0274 241.112C77.0048 241.293 76.9936 241.428 76.9936 241.586Z'
        fill='black'
      />
      <path
        d='M67.7565 254.819L68.1959 255.022C68.6012 255.255 68.9634 255.557 69.2664 255.913C69.3638 256.015 69.4812 256.095 69.611 256.15C69.7409 256.204 69.8806 256.231 70.0213 256.229C70.1722 256.237 70.323 256.212 70.4632 256.155C70.6033 256.099 70.7294 256.012 70.8327 255.902C71.0852 255.634 71.3604 255.389 71.6552 255.169C72.0795 254.855 72.5929 254.686 73.1201 254.686C73.6473 254.686 74.1607 254.855 74.5849 255.169C74.8761 255.381 75.1476 255.619 75.3962 255.879C75.5112 256.001 75.6517 256.095 75.8076 256.156C75.9635 256.216 76.1309 256.241 76.2977 256.229C76.5297 256.225 76.7513 256.132 76.9174 255.97C77.1053 255.767 77.3047 255.574 77.5147 255.394C77.9913 254.935 78.6275 254.678 79.2894 254.678C79.9513 254.678 80.5874 254.935 81.0641 255.394C81.2591 255.563 81.4435 255.744 81.6163 255.936C81.8219 256.117 82.0862 256.216 82.3599 256.216C82.6337 256.216 82.898 256.117 83.1036 255.936C83.4125 255.626 83.744 255.339 84.0952 255.079C84.2759 254.961 84.4685 254.862 84.6699 254.785V255.011C84.6699 255.394 84.6699 255.778 84.6699 256.139C84.6775 256.262 84.6559 256.386 84.607 256.5C84.558 256.613 84.4831 256.714 84.3882 256.793C83.9574 257.254 83.3944 257.569 82.7769 257.695C82.4068 257.764 82.0259 257.748 81.6629 257.649C81.2999 257.549 80.9642 257.368 80.681 257.12C80.4445 256.934 80.2257 256.726 80.0274 256.5C79.8204 256.313 79.5514 256.209 79.2725 256.209C78.9936 256.209 78.7246 256.313 78.5175 256.5C78.3398 256.694 78.1477 256.875 77.9428 257.041C77.4697 257.49 76.8426 257.741 76.1906 257.741C75.5387 257.741 74.9116 257.49 74.4385 257.041C74.2334 256.872 74.0413 256.687 73.8638 256.489C73.6567 256.301 73.3877 256.198 73.1088 256.198C72.8299 256.198 72.5609 256.301 72.3539 256.489C72.1754 256.693 71.9793 256.882 71.7679 257.053C71.2923 257.497 70.6661 257.743 70.0157 257.743C69.3653 257.743 68.7392 257.497 68.2635 257.053C67.7227 256.59 67.7227 256.59 67.7227 255.925V254.898L67.7565 254.819Z'
        fill='black'
      />
      <path
        d='M67 252.384V250.838C67.3898 250.832 67.7758 250.917 68.1268 251.086C68.5587 251.319 68.9414 251.633 69.2536 252.011C69.3847 252.157 69.5513 252.265 69.737 252.327C69.9227 252.388 70.1211 252.4 70.3128 252.361C70.5132 252.323 70.6954 252.22 70.8311 252.068C71.1016 251.82 71.3495 251.571 71.6425 251.346C71.8756 251.195 72.1163 251.055 72.3636 250.929C72.368 251.007 72.368 251.086 72.3636 251.165C72.3636 251.617 72.3636 252.079 72.3636 252.53C72.3628 252.635 72.3225 252.736 72.2509 252.812C71.9778 253.084 71.6797 253.33 71.3608 253.546C70.8977 253.835 70.3525 253.965 69.8088 253.914C69.2652 253.863 68.7534 253.634 68.3522 253.264C68.1944 253.139 68.0592 253.004 67.9127 252.869C67.8103 252.721 67.674 252.6 67.5152 252.515C67.3564 252.431 67.1798 252.386 67 252.384Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M107.485 261.343L111.173 266.117C104.86 270.932 97.6231 274.402 89.9076 276.308L88.453 270.454C95.3572 268.749 101.834 265.647 107.485 261.343ZM86.5679 270.882C79.5952 272.331 72.3977 272.331 65.423 270.881L64.1908 276.786C71.9784 278.405 80.0148 278.405 87.8001 276.787L86.5679 270.882ZM62.0834 276.307L63.538 270.453C56.6365 268.748 50.1596 265.646 44.5073 261.34L40.82 266.115C47.1336 270.931 54.3708 274.401 62.0834 276.307ZM39.1298 264.775C33.0042 259.731 27.9827 253.487 24.3711 246.433L29.7596 243.721C32.9997 250.037 37.502 255.627 42.9925 260.141L39.1298 264.775Z'
        fill='url(#paint3_radial_7306_75173)'
      />
      <path
        d='M66.8813 182.482H63.8359L63.1001 186H61.478L63.127 178.126H64.7437L64.1099 181.166H67.1553L67.7944 178.126H69.4111L67.7676 186H66.1455L66.8813 182.482Z'
        fill='black'
      />
      <path
        d='M104.155 253.164L105.788 253.411C105.469 254.296 104.991 254.972 104.354 255.441C103.717 255.907 102.97 256.14 102.114 256.14C101.151 256.14 100.397 255.85 99.853 255.27C99.3123 254.689 99.042 253.848 99.042 252.745C99.042 251.85 99.2228 251.032 99.5845 250.291C99.9497 249.546 100.455 248.976 101.099 248.583C101.744 248.189 102.445 247.992 103.205 247.992C104.057 247.992 104.746 248.221 105.272 248.679C105.799 249.134 106.109 249.755 106.202 250.543L104.649 250.693C104.563 250.235 104.397 249.904 104.15 249.7C103.906 249.492 103.581 249.388 103.172 249.388C102.725 249.388 102.306 249.524 101.916 249.796C101.529 250.069 101.219 250.495 100.986 251.075C100.754 251.651 100.637 252.228 100.637 252.804C100.637 253.434 100.788 253.921 101.088 254.265C101.393 254.609 101.771 254.781 102.222 254.781C102.641 254.781 103.024 254.645 103.371 254.373C103.722 254.1 103.983 253.698 104.155 253.164Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.01 221.586L24.5336 221.466C24.5277 221.146 24.5247 220.825 24.5247 220.503C24.5247 220.181 24.5277 219.86 24.5336 219.539L18.01 219.42C18.0033 219.78 18 220.141 18 220.503C18 220.865 18.0033 221.226 18.01 221.586ZM23.2696 196.436L29.1983 199.146C29.4664 198.566 29.7452 197.991 30.0343 197.422L24.2121 194.492C23.8862 195.133 23.5719 195.781 23.2696 196.436ZM38.9945 176.066L43.16 181.063C43.654 180.655 44.1558 180.256 44.6651 179.867L40.6894 174.719C40.1159 175.158 39.5508 175.607 38.9945 176.066ZM62.0313 164.482L63.5972 170.784C64.2183 170.631 64.8439 170.489 65.4738 170.359L64.1476 164.003C63.4373 164.15 62.7318 164.309 62.0313 164.482Z'
        fill='black'
      />
      <g filter='url(#filter21_dii_7306_75173)'>
        <circle
          r='72.5'
          transform='matrix(-1 0 0 1 335.5 220.5)'
          fill='white'
        />
      </g>
      <g filter='white'>
        <circle
          r='66.5'
          transform='matrix(-1 0 0 1 335.5 220.5)'
          fill='white'
        />
      </g>
      <g>
        <circle
          r='66.5'
          transform='matrix(-1 0 0 1 335.5 220.5)'
          fill='url(#paint9_radial_7306_75173)'
        />
      </g>
      <g clipPath='url(#clip0_7306_75173)'>
        <path
          d='M345.905 249.5C345.905 249.5 347.905 251.67 347.905 253C347.905 253.53 347.694 254.039 347.319 254.414C346.944 254.789 346.435 255 345.905 255C345.375 255 344.866 254.789 344.491 254.414C344.116 254.039 343.905 253.53 343.905 253C343.905 251.67 345.905 249.5 345.905 249.5ZM329.905 243H333.905C334.17 243 334.425 243.105 334.612 243.293C334.8 243.48 334.905 243.735 334.905 244C334.905 244.265 334.8 244.52 334.612 244.707C334.425 244.895 334.17 245 333.905 245H332.905V247H334.905C335.645 247 336.295 247.4 336.635 248L343.145 244.24L346.405 246.13C346.905 246.4 347.045 247 346.775 247.5C346.495 247.97 345.905 248.14 345.405 247.86L343.305 246.65L339.655 252.97C339.315 253.58 338.655 254 337.905 254H328.905C328.375 254 327.866 253.789 327.491 253.414C327.116 253.039 326.905 252.53 326.905 252V249C326.905 248.47 327.116 247.961 327.491 247.586C327.866 247.211 328.375 247 328.905 247H330.905V245H329.905C329.64 245 329.385 244.895 329.198 244.707C329.01 244.52 328.905 244.265 328.905 244C328.905 243.735 329.01 243.48 329.198 243.293C329.385 243.105 329.64 243 329.905 243ZM328.905 249V252H337.905L339.965 248.43L336.505 250.43L335.595 249H328.905ZM324.285 246.21L325.995 244.5C326.405 244.11 327.015 244.11 327.405 244.5C327.795 244.89 327.795 245.5 327.405 245.91L325.695 247.62C325.305 248 324.675 248 324.285 247.62C323.905 247.23 323.905 246.6 324.285 246.21Z'
          fill='black'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M304.513 261.343L300.825 266.117C307.138 270.932 314.375 274.402 322.09 276.308L323.545 270.454C316.641 268.749 310.164 265.647 304.513 261.343ZM325.43 270.882C332.403 272.331 339.6 272.331 346.575 270.881L347.807 276.786C340.02 278.405 331.983 278.405 324.198 276.787L325.43 270.882ZM349.915 276.307L348.46 270.453C355.362 268.748 361.838 265.646 367.491 261.34L371.178 266.115C364.864 270.931 357.627 274.401 349.915 276.307ZM372.868 264.775C378.994 259.731 384.015 253.487 387.627 246.433L382.238 243.721C378.998 250.037 374.496 255.627 369.006 260.141L372.868 264.775Z'
        fill='url(#paint10_radial_7306_75173)'
      />
      <path
        d='M348.881 182.482H345.836L345.1 186H343.478L345.127 178.126H346.744L346.11 181.166H349.155L349.794 178.126H351.411L349.768 186H348.146L348.881 182.482Z'
        fill='black'
      />
      <path
        d='M306.494 256L308.143 248.126H309.76L308.39 254.684H312.397L312.123 256H306.494Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M393.99 221.586L387.466 221.466C387.472 221.146 387.475 220.825 387.475 220.503C387.475 220.181 387.472 219.86 387.466 219.539L393.99 219.42C393.997 219.78 394 220.141 394 220.503C394 220.865 393.997 221.226 393.99 221.586ZM388.73 196.436L382.802 199.146C382.534 198.566 382.255 197.991 381.966 197.422L387.788 194.492C388.114 195.133 388.428 195.781 388.73 196.436ZM373.005 176.066L368.84 181.063C368.346 180.655 367.844 180.256 367.335 179.867L371.311 174.719C371.884 175.158 372.449 175.607 373.005 176.066ZM349.969 164.482L348.403 170.784C347.782 170.631 347.156 170.489 346.526 170.359L347.852 164.003C348.563 164.15 349.268 164.309 349.969 164.482Z'
        fill='black'
      />
      <g filter='url(#filter21_dii_7306_75173)'>
        <path
          d='M332 135C332 204.588 275.588 261 206 261C136.412 261 80 204.588 80 135C80 65.4121 136.412 9 206 9C275.588 9 332 65.4121 332 135Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_dii_7306_75173'
          x='34'
          y='45'
          width='344'
          height='256'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='6.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_innerShadow_7306_75173'
            result='effect3_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter21_dii_7306_75173'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='3.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_innerShadow_7306_75173'
            result='effect3_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter1_dii_7306_75173'
          x='0'
          y='148'
          width='153'
          height='153'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='4' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' />
          <feGaussianBlur stdDeviation='4.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_innerShadow_7306_75173'
            result='effect3_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter2_i_7306_75173'
          x='10'
          y='154'
          width='133'
          height='133'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          {/* Here is the orange 'blur' responsible for ORANGE glowing around the LEFT circle */}
          <feGaussianBlur stdDeviation={orangeBlurDeviation} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.956863 0 0 0 0 0.427451 0 0 0 0 0.054902 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter3_i_7306_75173'
          x='10'
          y='154'
          width='133'
          height='133'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          {/* Here is the orange 'blur' responsible for YELLOW glowing around the LEFT circle */}
          <feGaussianBlur stdDeviation={yellowBlurDeviation} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 0.85098 0 0 0 0 0 0 0 0 1 0'
          />
          {/* This thing is hiding the orange blur */}
          {/* <feBlend */}
          {/* mode='normal' */}
          {/* in2='shape' */}
          {/* result='effect1_innerShadow_7306_75173' */}
          {/* /> */}
        </filter>
        <filter
          id='filter4_dd_7306_75173'
          x='28.9805'
          y='211.443'
          width='55.5273'
          height='54.9766'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.913725 0 0 0 0 0 0 0 0 0 0.109804 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_7306_75173'
            result='effect2_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_7306_75173'
            result='shape'
          />
        </filter>
        <filter
          id='filter5_di_7306_75173'
          x='59'
          y='203'
          width='38'
          height='38'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' dy='-2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter6_di_7306_75173'
          x='64'
          y='208'
          width='28'
          height='28'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' dy='-2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter7_dii_7306_75173'
          x='259'
          y='148'
          width='153'
          height='153'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='4' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' />
          <feGaussianBlur stdDeviation='4.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_innerShadow_7306_75173'
            result='effect3_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter8_i_7306_75173'
          x='269'
          y='154'
          width='133'
          height='133'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          {/* Here is the orange 'blur' responsible for ORANGE glowing around the RIGHT circle */}
          <feGaussianBlur stdDeviation={orangeBlurDeviation} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.956863 0 0 0 0 0.427451 0 0 0 0 0.054902 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter9_i_7306_75173'
          x='269'
          y='154'
          width='133'
          height='133'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          {/* Here is the orange 'blur' responsible for YELLOW glowing around the RIGHT circle */}
          <feGaussianBlur stdDeviation={yellowBlurDeviation} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 0.85098 0 0 0 0 0 0 0 0 1 0'
          />
          {/* This thing is hiding the orange blur */}
          {/* <feBlend */}
          {/* mode='normal' */}
          {/* in2='shape' */}
          {/* result='effect1_innerShadow_7306_75173' */}
          {/* /> */}
        </filter>
        <filter
          id='filter10_dd_7306_75173'
          x='327.924'
          y='178.715'
          width='59.5996'
          height='50.2285'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.913725 0 0 0 0 0 0 0 0 0 0.109804 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_7306_75173'
            result='effect2_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_7306_75173'
            result='shape'
          />
        </filter>
        <filter
          id='filter11_di_7306_75173'
          x='319'
          y='203'
          width='38'
          height='38'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' dy='-2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter12_di_7306_75173'
          x='324'
          y='208'
          width='28'
          height='28'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' dy='-2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter13_dii_7306_75173'
          x='67'
          y='0'
          width='278'
          height='278'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='6.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='4' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 0 0.411765 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' />
          <feGaussianBlur stdDeviation='4.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_innerShadow_7306_75173'
            result='effect3_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter14_i_7306_75173'
          x='88'
          y='17'
          width='236'
          height='236'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          {/* Here is the orange 'blur' responsible for ORANGE glowing around the MIDDLE circle */}
          <feGaussianBlur stdDeviation={orangeBlurDeviation} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.956863 0 0 0 0 0.427451 0 0 0 0 0.054902 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter15_i_7306_75173'
          x='88'
          y='17'
          width='236'
          height='236'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          {/* Here is the orange 'blur' responsible for YELLOW glowing around the MIDDLE circle */}
          <feGaussianBlur stdDeviation={yellowBlurDeviation} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 0.85098 0 0 0 0 0 0 0 0 1 0'
          />
          {/* This thing is hiding the orange blur */}
          {/* <feBlend */}
          {/* mode='normal' */}
          {/* in2='shape' */}
          {/* result='effect1_innerShadow_7306_75173' */}
          {/* /> */}
        </filter>
        <filter
          id='filter16_ii_7306_75173'
          x='136.279'
          y='191.773'
          width='139.557'
          height='55.2266'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' dy='-2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.286275 0 0 0 0 0.286275 0 0 0 0 0.298039 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_innerShadow_7306_75173'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter17_di_7306_75173'
          x='200'
          y='226'
          width='12'
          height='25'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-1' dy='-1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.121569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter18_di_7306_75173'
          x='255.76'
          y='209.76'
          width='14.4395'
          height='18.127'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-1' dy='-1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.121569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter19_di_7306_75173'
          x='143.76'
          y='210.762'
          width='18.4395'
          height='23.127'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-1' dy='-1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.121569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter20_d_7306_75173'
          x='184.727'
          y='209.527'
          width='37.8516'
          height='14.4727'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.980392 0 0 0 0 0.639216 0 0 0 0 0.027451 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
        </filter>
        <filter
          id='filter21_ii_7306_75173'
          x='154'
          y='153'
          width='104'
          height='37'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' dy='-2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.286275 0 0 0 0 0.286275 0 0 0 0 0.298039 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_innerShadow_7306_75173'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter22_d_7306_75173'
          x='169.414'
          y='161.291'
          width='71.4121'
          height='19.709'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.980392 0 0 0 0 0.639216 0 0 0 0 0.027451 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
        </filter>
        <filter
          id='filter23_dd_7306_75173'
          x='199.604'
          y='55.0469'
          width='86.3496'
          height='86.3516'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.913725 0 0 0 0 0 0 0 0 0 0.109804 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_7306_75173'
            result='effect2_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_7306_75173'
            result='shape'
          />
        </filter>
        <filter
          id='filter24_di_7306_75173'
          x='189'
          y='118.002'
          width='38'
          height='38'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' dy='-2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter25_di_7306_75173'
          x='194'
          y='123.002'
          width='28'
          height='28'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' dy='-2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_7306_75173'
          />
        </filter>
        <filter
          id='filter26_d_7306_75173'
          x='87'
          y='16'
          width='172.352'
          height='198.707'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='4.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.121569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
        </filter>
        <filter
          id='filter27_d_7306_75173'
          x='241.674'
          y='26.5605'
          width='83.3262'
          height='188.146'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='4.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.121569 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7306_75173'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7306_75173'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_7306_75173'
          x1='206'
          y1='54'
          x2='206'
          y2='284'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFFFFF' />
          <stop offset='1' stopColor='#E3E5EA' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_7306_75173'
          x1='76.5'
          y1='148'
          x2='76.5'
          y2='293'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#101112' />
          <stop offset='1' stopColor='#49494C' />
        </linearGradient>
        <radialGradient
          id='paint2_radial_7306_75173'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(56.4219 205.323) rotate(49.3987) scale(40.6344)'
        >
          <stop stopColor='#3A3A3A' />
          <stop offset='1' stopColor='#1B1C1F' />
        </radialGradient>
        <radialGradient
          id='paint3_radial_7306_75173'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(31.4981 254.001) rotate(7.16936) scale(80.1265 202.891)'
        >
          <stop stopColor='#EC0823' />
          <stop offset='1' stopColor='#C5021A' />
        </radialGradient>
        <linearGradient
          id='paint4_linear_7306_75173'
          x1='33.9785'
          y1='261.419'
          x2='75.9998'
          y2='220.004'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF2943' />
          <stop offset='1' stopColor='#E9001C' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_7306_75173'
          x1='76'
          y1='207'
          x2='76'
          y2='233'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#49494C' />
          <stop offset='1' stopColor='#101112' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_7306_75173'
          x1='76'
          y1='212'
          x2='76'
          y2='228'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#49494C' />
          <stop offset='1' stopColor='#101112' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_7306_75173'
          x1='76'
          y1='212'
          x2='76'
          y2='228'
          gradientUnits='userSpaceOnUse'
        >
          <stop />
          <stop offset='1' stopColor='#545557' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_7306_75173'
          x1='72.5'
          y1='0'
          x2='72.5'
          y2='145'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#101112' />
          <stop offset='1' stopColor='#49494C' />
        </linearGradient>
        <radialGradient
          id='paint9_radial_7306_75173'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(46.4219 51.3233) rotate(49.3987) scale(40.6344)'
        >
          <stop stopColor='#FFFFFF' />
          <stop offset='1' stopColor='#E3E5EA' />
        </radialGradient>
        <radialGradient
          id='paint10_radial_7306_75173'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(380.5 254.001) rotate(172.831) scale(80.1265 202.891)'
        >
          <stop stopColor='#EC0823' />
          <stop offset='1' stopColor='#C5021A' />
        </radialGradient>
        <linearGradient
          id='paint11_linear_7306_75173'
          x1='382.523'
          y1='183.716'
          x2='336'
          y2='220.001'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF2943' />
          <stop offset='1' stopColor='#E9001C' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_7306_75173'
          x1='13'
          y1='0'
          x2='13'
          y2='26'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#49494C' />
          <stop offset='1' stopColor='#101112' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_7306_75173'
          x1='8'
          y1='0'
          x2='8'
          y2='16'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#49494C' />
          <stop offset='1' stopColor='#101112' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_7306_75173'
          x1='8'
          y1='0'
          x2='8'
          y2='16'
          gradientUnits='userSpaceOnUse'
        >
          <stop />
          <stop offset='1' stopColor='#545557' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_7306_75173'
          x1='206'
          y1='9'
          x2='206'
          y2='261'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#101112' />
          <stop offset='1' stopColor='#49494C' />
        </linearGradient>
        <radialGradient
          id='paint16_radial_7306_75173'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(167 93.5) rotate(54.92) scale(80.039)'
        >
          <stop stopColor='#3A3A3A' />
          <stop offset='1' stopColor='#1B1C1F' />
        </radialGradient>
        <linearGradient
          id='paint17_linear_7306_75173'
          x1='206'
          y1='58.3828'
          x2='206'
          y2='178.324'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFD900' />
          <stop offset='1' stopColor='#E9001C' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_7306_75173'
          x1='250.5'
          y1='225.5'
          x2='206'
          y2='235'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={acceleration > 0 ? '#00F68D' : '#BC0017'} />
          <stop offset='1' stopColor='#00B166' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint19_linear_7306_75173'
          x1='280.954'
          y1='60.0473'
          x2='206'
          y2='135.001'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF2943' />
          <stop offset='1' stopColor='#E9001C' />
        </linearGradient>
        <linearGradient
          id='paint20_linear_7306_75173'
          x1='206'
          y1='122.002'
          x2='206'
          y2='148.002'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#49494C' />
          <stop offset='1' stopColor='#101112' />
        </linearGradient>
        <linearGradient
          id='paint21_linear_7306_75173'
          x1='206'
          y1='127.001'
          x2='206'
          y2='143.001'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#49494C' />
          <stop offset='1' stopColor='#101112' />
        </linearGradient>
        <linearGradient
          id='paint22_linear_7306_75173'
          x1='206'
          y1='127.001'
          x2='206'
          y2='143.001'
          gradientUnits='userSpaceOnUse'
        >
          <stop />
          <stop offset='1' stopColor='#545557' />
        </linearGradient>
        <linearGradient
          id='paint23_linear_7306_75173'
          x1='206'
          y1='25'
          x2='206'
          y2='245'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#ED0823' />
          <stop offset='1' stopColor='#BC0017' />
        </linearGradient>
        <linearGradient
          id='paint24_linear_7306_75173'
          x1='205.999'
          y1='25'
          x2='205.999'
          y2='245'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00FB90' />
          <stop offset='1' stopColor='#00A05C' />
        </linearGradient>
        <linearGradient
          id='paint26_linear_7306_75173'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFFFFF' />
          <stop offset='1' stopColor='#CAD0DB' />
        </linearGradient>
        <clipPath id='clip0_7306_75173'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='matrix(-1 0 0 1 348 237)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SpeedometerBackLightTheme

import { values, pipe, juxt, pluck, identity, apply, zipObj } from 'ramda'

export const API_STATES = {
  PRISTINE: 'pristine',
  IN_PROGRESS: 'in-progress',
  DONE: 'done'
}

export const transformById =
  (idKey = 'id') =>
  (collection: any[]) =>
    pipe(juxt([pluck(idKey), identity]), apply(zipObj))(collection)

export const transformToArray = values

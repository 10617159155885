import { propOr } from 'ramda'
import * as Reselect from 'reselect'

export const selectOnboarding = state => state.onboarding

export const selectOnboardingCategories: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectOnboarding, propOr([], 'categories'))

export const selectCategoryImages: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectOnboarding, propOr([], 'categoryImages'))

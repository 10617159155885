import React from 'react'
import styled from 'styled-components'
import { Heading1 } from 'examkrackers-components'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { getLearningTimeDetails } from 'modules/learningTime/ducks/selectors'
import { COURSE_LEARNING_TIME_STATUS } from 'utils/courses'

interface SiteHeaderProps {
  text: string
  excludeBorder?: boolean
  salty: JSX.Element
}

const SiteHeader = (props: SiteHeaderProps): JSX.Element => {
  const { text, salty, excludeBorder } = props

  const learningTimeDetails = useSelector(getLearningTimeDetails)

  const learningTimeState: string = R.propOr(
    COURSE_LEARNING_TIME_STATUS.started,
    'state',
    learningTimeDetails
  )

  const isPaused = learningTimeState === COURSE_LEARNING_TIME_STATUS.paused

  return (
    <React.Fragment>
      <Heading isPaused={isPaused} excludeBorder={excludeBorder}>
        <Heading1 bold>{text}</Heading1>
        {salty}
      </Heading>
    </React.Fragment>
  )
}

const Heading = styled.div`
  position: relative;
  padding: 16px 0px;
  max-width: 440px;

  &::after {
    position: absolute;
    bottom: 2px;
    height: 2px;
    width: 400px;
    content: '';
    opacity: ${({ excludeBorder }) => (excludeBorder ? 0 : 1)};
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.main.text},
      rgba(255, 255, 255, 0)
    );
  }

  img {
    z-index: ${({ isPaused, theme }) =>
      isPaused ? theme.zIndex.navigation - 1 : theme.zIndex.navigation};
  }
`

export default SiteHeader

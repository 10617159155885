import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Heading1 } from 'examkrackers-components'
import { ListCategories } from 'modules/onboarding/components/ListCategories'
import PATHS from 'utils/paths'

const Onboarding = () => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const redirectToVideos = () => {
    push(PATHS.videos)
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.onboarding')}</title>
      </Helmet>
      <br />
      <HeadingWrapper>
        <Heading1 bold>{t('onboarding.title')}</Heading1>
        <Button onClick={redirectToVideos} size='small' color='secondary'>
          {t('onboarding.videoTutorials')}
        </Button>
      </HeadingWrapper>
      <br />
      <ListCategories />
    </>
  )
}
export default Onboarding

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'examkrackers-components'
import useOutsideClickWithoutTimeout from 'hooks/useOutsideClickWithoutTimeout'
import { useSelector } from 'react-redux'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'

import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'

interface DisplayFreeTrialLockedEventModalProps {
  type?: string
  open
  setOpen
  containerPosition: any
}

const DisplayFreeTrialLockedEventModal: React.FC<
  DisplayFreeTrialLockedEventModalProps
> = ({ open, setOpen, containerPosition }) => {
  const modalRef = useRef<HTMLDivElement>(null!)
  const [_, setClose] = useState(false)

  const top = containerPosition?.top || 0
  const left = containerPosition?.left || 0

  const handleClose = async () => {
    await setClose(true)
    setOpen(false)
  }

  useOutsideClickWithoutTimeout(modalRef, handleClose)

  const currentCourse: Course = useSelector(getCurrentCourse)
  const isProduction = process.env.RELEASE_TYPE === 'production'
  const haveTransactionId = isNotNilOrEmpty(currentCourse.transaction_id)
  const transactionId = propOr('', 'transaction_id', currentCourse)
  const openPurchasePortal = () => {
    // window.open(EK_PURCHASE_PORTAL_PAGE, '_blank')
    if (isProduction && haveTransactionId) {
      window.open(
        `https://examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!isProduction && haveTransactionId) {
      window.open(
        `https://test.examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!haveTransactionId && !isProduction) {
      window.open(
        'https://test.examkrackers.com/my-account?panel=orders',
        '_blank'
      )
    } else if (!haveTransactionId && isProduction) {
      window.open('https://examkrackers.com/my-account?panel=orders', '_blank')
    }
    handleClose()
  }

  return (
    <EventCard
      open={open}
      top={top}
      left={left}
      ref={modalRef}
      data-testid='DisplayFreeTrialLockedEventModal-EventCard'
    >
      <Container data-testid='DisplayFreeTrialLockedEventModal-Container'>
        <ModalText data-testid='DisplayFreeTrialLockedEventModal-ModalText'>
          This task is available with any paid subscription. Would you like to
          gain access by purchasing a subscription?
        </ModalText>
        <ButtonsWrapper data-testid='DisplayFreeTrialLockedEventModal-ButtonsWrapper'>
          <Button
            data-testid='DisplayFreeTrialLockedEventModal-Button-PurchaseSubscription'
            variant='contained'
            color='secondary'
            size='small'
            onClick={openPurchasePortal}
          >
            Purchase Subscription
          </Button>
        </ButtonsWrapper>
      </Container>
      <ArrowIconWrapper
        data-testid='DisplayFreeTrialLockedEventModal-ArrowIconWrapper'
        top={top}
      />
    </EventCard>
  )
}

export default DisplayFreeTrialLockedEventModal

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`
const ArrowIconWrapper = styled.div`
  position: absolute;

  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 0;
  border-top: 15px solid white;
  ${({ top }) =>
    top < 300
      ? css`
          top: -24px;
          transform: rotate(180deg);
        `
      : 'bottom: -24px;'}
`

const EventCard = styled.div<{ open: boolean; left: number; top: number }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  left: ${({ left }) => left - 105}px;
  top: ${({ top }) => (top < 300 ? top + 24 : top - 150)}px;
  width: 340px;
  color: ${({ theme }) => theme.colors.main.grey600};
  z-index: 999;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
`

const ModalText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.main.text};
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 8px;
`

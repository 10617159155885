import APIService from 'services/APIService'
import {
  GetPinNotesForParticularBookPayload,
  GetPinNotesForParticularSubChapterPayload
} from 'types'

const api = APIService()

// pins

export const fetchPinNotesForParticularBook = (
  payload: GetPinNotesForParticularBookPayload
) =>
  api.get(`/student-book-content-pins/book/${payload.bookId}?${payload.query}`)

export const fetchPinNotesForParticularSubChapters = (
  payload: GetPinNotesForParticularSubChapterPayload
) =>
  api.get(
    `/student-book-content-pins/book/${payload.bookId}/subchapter/${payload.subChapterId}?limit[page]=1&limit[take]=1000`
  )

export const fetchBookPinNotesCount = payload =>
  api.get(`/student-book-content-pins/book/${payload.bookId}/count`)

export const fetchAllPinNotesCount = () =>
  api.get('/student-book-content-pins/count')

export const fetchCoursePinNotes = payload =>
  api.get(`/student-book-content-pins/course?${payload.query}`)

import React from 'react'
import debounce from 'lodash.debounce'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/diagnostics/utils/events'

import { isNotNilOrEmpty } from 'utils/ramda'

export const useQuestionHover = id => {
  const handleQuestionHover = questionNumber => {
    const linesContainer = document.querySelector(
      `#${id} .apexcharts-gridlines-vertical`
    )
    const lines = linesContainer ? linesContainer.children : []
    const currentLine = document.querySelector(
      `#${id} .apexcharts-gridline[dataX="${questionNumber}"]`
    )

    if (isNotNilOrEmpty(lines)) {
      // @ts-ignore
      const linesList = Array.from(lines)
      linesList.forEach(line => {
        line.classList.remove('grid-hover-active')
      })
    }
    if (isNotNilOrEmpty(currentLine)) {
      // @ts-ignore
      currentLine.classList.add('grid-hover-active')
    }
  }

  const debouncedHandleQuestionHover = debounce(handleQuestionHover)

  const handleMouseLeave = () => eventEmitter.emit(events.questionHover, 0)

  const setOnMouseLeave = () => {
    const chartContainer = document.getElementById(id)
    // @ts-ignore
    chartContainer.onmouseleave = handleMouseLeave
  }

  React.useEffect(() => {
    setOnMouseLeave()
    eventEmitter.on(events.questionHover, debouncedHandleQuestionHover)

    return () => {
      eventEmitter.off(events.questionHover, debouncedHandleQuestionHover)
    }
  }, [])
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import qs from 'qs'
import {
  VIDEOS_QUERY_PARAMS,
  videoStringTags,
  videoMinutesDuration
} from 'utils/videos'
import { useTranslation } from 'react-i18next'
import PATHS from 'utils/paths'

import {
  BouncingLoader,
  Button,
  NavArrowLeftIcon,
  Tag
} from 'examkrackers-components'
import { fetchVideoDetails } from '../services/VideosService'
import VideoDetailsBookFilters from '../modules/videos/components/VideoDetailsBookFilters'
import VideoDetailsList from '../modules/videos/components/VideoDetailsList'
import { useSelector } from 'react-redux'
import { selectAllBooks } from '../modules/books/ducks/selectors'
import VideoDetailsMainVideo from '../modules/videos/components/VideoDetailsMainVideo'
import VideoWatchingTime from '../modules/learningTime/components/VideoWatchingTime'
import { ResponseStatus, Nullable, Course } from 'types'
import { VideoSubchapterNote } from 'modules/videos/components/VideoSubchapterNote'
import { fetchNotesForSubchapter } from 'services/BooksService'
import { MixedVideo, Notes } from 'types/videos'

import { getStudent } from 'modules/auth/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
interface IParsedQuery extends qs.ParsedQs {
  filter?: {
    search: string
    category: string
    'b.id': string
    'bcc.id': string
    'bsubc.id': string
    'sfv.id': string
    __is_watched: string
  }
}

type SelectedCategoryType =
  | 'Bonus Videos'
  | 'books'
  | 'reviews '
  | 'onboarding'
  | string

type BookTagColorType =
  | 'orange'
  | 'green'
  | 'blue'
  | 'red'
  | 'purple'
  | 'brown'
  | 'mathPurple'
  | 'aquamarine'
  | 'turquoise'
  | 'yellow'
  | 'grey'
  | 'terraCotta'
  | 'gold'
  | 'tangerine'
  | 'guacamole'
  | 'ultramarine'
  | 'grape'
  | 'moss'
  | 'slate'
  | undefined

export const VideoDetails = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const {
    push,
    replace,
    location: { pathname, search }
  } = useHistory()
  const [loadingState, setLoadingState] = useState<ResponseStatus>(
    ResponseStatus.initial
  )

  const parsedQuery: IParsedQuery = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  const selectedCategory: SelectedCategoryType =
    parsedQuery.filter?.category === 'medreel'
      ? 'Bonus Videos'
      : parsedQuery.filter?.category === 'recordings'
      ? 'Classes'
      : R.pathOr('all', ['filter', VIDEOS_QUERY_PARAMS.category], parsedQuery)

  const videoId: string = R.propOr('', 'id', params)
  const [video, setVideo] = useState<MixedVideo>()
  const [notes, setNotes] = useState<Nullable<Notes>>(null)
  const [viewVideoDetails, setViewVideoDetails] = useState(false)
  const [currentVideoDetailsAmplitiude, setCurrentVideoDetailsAmplitiude] =
    useState('')
  const books = useSelector(selectAllBooks)
  const tags = R.propOr([], 'tags', video)
  const duration = R.propOr([], 'duration', video)
  const bookTag = R.pathOr({}, [0, 'tag'], tags)
  const bookId = R.pathOr('', [0, 'book_id'], tags)
  const bookTagColor = R.pathOr({}, [0, 'tag_colour'], tags)
  const category = R.pathOr('all', ['category'], video)

  const studentBookId = books.find(book => book.book_id === bookId)?.id || ''

  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)

  const loadVideoDetails = async () => {
    setLoadingState(ResponseStatus.pending)

    try {
      const response = await fetchVideoDetails({ id: videoId })
      const _video = response.data

      if (_video.student_subchapter_id) {
        const notesResponse = await fetchNotesForSubchapter({
          sectionId: _video.student_subchapter_id
        })

        setNotes(notesResponse.data[0])
      } else {
        setNotes(null) // reset notes for video without subchapter
      }

      setVideo(_video)

      setLoadingState(ResponseStatus.fulfilled)
    } catch (error) {
      console.error('Failed to load video', error)
      setLoadingState(ResponseStatus.rejected)
    }
  }

  const setBookIdFilterIfNeeded = () => {
    if (studentBookId) {
      const filterQuery = R.propOr<qs.ParsedQs, qs.ParsedQs, qs.ParsedQs>(
        {},
        'filter',
        parsedQuery
      )

      const newQuery = {
        ...parsedQuery,
        filter: {
          ...filterQuery,
          'b.id': bookId
        }
      }

      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }

  useEffect(() => {
    loadVideoDetails()
  }, [videoId])

  useEffect(() => {
    if (
      isNotNilOrEmpty(currentCourse.id) &&
      (viewVideoDetails || currentVideoDetailsAmplitiude !== videoId)
    ) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.viewVideoDetails, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Video title': video?.title || false,
        'Video category': video?.category || false,
        'Book tag': videoStringTags(tags),
        'Video lenght': videoMinutesDuration(duration)
      })
      setViewVideoDetails(false)
      setCurrentVideoDetailsAmplitiude(videoId)
    }
  }, [currentCourse, viewVideoDetails, currentVideoDetailsAmplitiude, videoId])

  useEffect(() => {
    setBookIdFilterIfNeeded()
  }, [video?.id])

  const goBackToVideos = () => {
    const filterQuery = R.propOr({}, 'filter', parsedQuery)

    const currentQueryWithoutBookFilter = {
      ...parsedQuery,
      filter: {
        ...R.omit(['b.id', 'bcc.id'], filterQuery)
      }
    }

    push(
      `${PATHS.videos}${qs.stringify(currentQueryWithoutBookFilter, {
        addQueryPrefix: true
      })}`
    )
  }

  const hasBooksCategorySelected = selectedCategory === 'books'

  if ([ResponseStatus.initial, ResponseStatus.pending].includes(loadingState)) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <Container>
      <VideoWatchingTime />
      <VideoDetailsLayout>
        <VideosLeftColumn>
          <BackButtonContainer>
            <Button
              size='small'
              color='transparent'
              variant='contained'
              startIcon={<NavArrowLeftIcon />}
              onClick={goBackToVideos}
            >
              {t('videos.backToVideos')}
            </Button>
          </BackButtonContainer>
          {video ? <VideoDetailsMainVideo video={video} /> : null}
          {video?.category === 'books' ? (
            <VideoSubchapterNote notes={notes} video={video} />
          ) : null}
        </VideosLeftColumn>
        <VideosRightColumn>
          <OtherVideosHeadingContainer>
            <HeadingTitleContainer>
              <HeadingTitle>{t('videos.otherVideosFrom')}</HeadingTitle>
              <VideoCategory>
                {selectedCategory === 'all'
                  ? video?.category
                  : selectedCategory}
              </VideoCategory>
              {hasBooksCategorySelected && (
                <VideoTags>
                  <Tag
                    text={bookTag as string}
                    color={bookTagColor as BookTagColorType}
                    isStatic
                  />
                </VideoTags>
              )}
            </HeadingTitleContainer>
          </OtherVideosHeadingContainer>
          {video && hasBooksCategorySelected && (
            <VideoBookFiltersContainer>
              <VideoDetailsBookFilters />
            </VideoBookFiltersContainer>
          )}
          <OtherVideosList>
            {video && <VideoDetailsList category={category} />}
          </OtherVideosList>
        </VideosRightColumn>
      </VideoDetailsLayout>
    </Container>
  )
}

export default VideoDetails

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  margin-top: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`

const VideoDetailsLayout = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  flex-grow: 1;
`

const VideosLeftColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const VideosRightColumn = styled.div`
  height: 100%;
  width: 400px;
  flex: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const VideoCategory = styled.div`
  min-width: 72px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 11px;
  background: ${({ theme }) => theme.colors.main.grey200};
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.main.text};
  text-transform: capitalize;
`

const BackButtonContainer = styled.div``

const OtherVideosHeadingContainer = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
`

const HeadingTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const HeadingTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.main.grey600};
`

const VideoTags = styled.div``

const VideoBookFiltersContainer = styled.div``

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OtherVideosList = styled.div`
  flex-grow: 1;
  max-height: calc(100% - 90px);
`

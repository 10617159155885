import { Action, LearningTimeReducer } from 'types'
import {
  fetchCourseTimeStateRoutine,
  setLearningTimePassedSecondsRoutine,
  resetInactiveTimePassedSecondsRoutine,
  setChapterReadingTimePassedSecondsRoutine,
  resetChapterReadingTimePassedSecondsRoutine,
  setFlashcardsLearningTimePassedSecondsRoutine,
  resetFlashcardsLearningTimePassedSecondsRoutine,
  setVideoWatchingTimePassedSecondsRoutine,
  resetVideoWatchingTimePassedSecondsRoutine,
  setInactiveTimePassedSecondsRoutine
} from 'modules/learningTime/ducks/actions'
import { API_STATES } from 'utils/redux'
import { propOr, pathOr } from 'ramda'

export const initialState: LearningTimeReducer = {
  state: API_STATES.PRISTINE,
  details: {},
  passedTime: 0,
  inactiveTime: 0,
  chapterReadingTime: 0,
  flashcardLearningTime: {},
  videoWatchingTime: {}
}

export default (state = initialState, action: Action): LearningTimeReducer => {
  switch (action.type) {
    case fetchCourseTimeStateRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchCourseTimeStateRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchCourseTimeStateRoutine.SUCCESS:
      return {
        ...state,
        details: action.payload,
        state: API_STATES.DONE,
        // set clock default value when learningTimeDetails is updated
        passedTime: propOr(0, 'seconds', action.payload)
      }
    case setLearningTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        passedTime: state.passedTime + 1
      }
    case setInactiveTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        inactiveTime: state.inactiveTime + 1
      }
    case resetInactiveTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        inactiveTime: 0
      }
    case setChapterReadingTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        chapterReadingTime: state.chapterReadingTime + 1
      }
    case resetChapterReadingTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        chapterReadingTime: 0
      }
    case setFlashcardsLearningTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        flashcardLearningTime: {
          ...state.flashcardLearningTime,
          [action.payload.flashcardId]:
            pathOr(0, ['flashcardLearningTime', action.payload.flashcardId])(
              state
            ) + 1
        }
      }
    case resetFlashcardsLearningTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        flashcardLearningTime: {
          ...state.flashcardLearningTime,
          [action.payload.flashcardId]: 0
        }
      }
    case setVideoWatchingTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        videoWatchingTime: {
          ...state.videoWatchingTime,
          [action.payload.videoId]:
            pathOr(0, ['videoWatchingTime', action.payload.videoId])(state) + 1
        }
      }
    case resetVideoWatchingTimePassedSecondsRoutine.SUCCESS:
      return {
        ...state,
        videoWatchingTime: {
          ...state.videoWatchingTime,
          [action.payload.videoId]: 0
        }
      }
    default:
      return { ...state }
  }
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as R from 'ramda'
import { DatePicker, Button } from 'examkrackers-components'
import { Exam, FetchExamsListPayload } from 'types'
import { fetchExamsListRoutine } from 'modules/exams/ducks/actions'
import * as ExamsService from 'services/ExamsService'
import { formatToISO } from 'utils/date'

import {
  validateEditAccessField,
  validateEditAccessValues
} from 'modules/exams/ducks/schema'

import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { getEditAccessPeriodError } from 'modules/exams/ducks/errors'

const emptyValues = {
  expiration_date: new Date()
}

interface FormEditAccessPeriodProps {
  exam: Exam
  onSubmitSuccess: () => any
}

const FormEditAccessPeriod = (
  props: FormEditAccessPeriodProps
): JSX.Element => {
  const { exam, onSubmitSuccess } = props
  const { t } = useTranslation()
  const { location } = useHistory()
  const { search } = location

  const accessPeriod: string = R.propOr('', 'accessible_to', exam)

  const dispatch = useDispatch()

  const [valid, _validateSchema] = React.useState<boolean>(false)

  const [values, setValues] = React.useState(emptyValues)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const refreshExamsList = React.useCallback(
    (payload: FetchExamsListPayload) =>
      dispatch(fetchExamsListRoutine(payload)),
    [dispatch]
  )

  const showToast = React.useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  React.useEffect(() => {
    setValues({
      expiration_date: new Date(accessPeriod || emptyValues.expiration_date)
    })
  }, [exam])

  React.useEffect(() => {
    validateEditAccessValues(values, _validateSchema)
  }, [values])

  const handleOnChange = (name: string, value: any) => {
    setValues({ ...values, [name]: value })
  }

  function onSubmit(e: any) {
    setIsLoading(true)
    e.preventDefault()

    const onSuccess = (): void => {
      refreshExamsList({ query: search })
      onSubmitSuccess()
      setIsLoading(false)

      showToast({
        key: 'toast.accessPeriodUpdated',
        severity: SEVERITY.success,
        options: { expiration_date: values.expiration_date }
      })
    }

    const onError = (e: Error): void => {
      console.error(e)
      setIsLoading(false)
      showToast(getEditAccessPeriodError(e))
    }

    ExamsService.changeExamAccessPeriod({
      id: exam.id,
      data: {
        expiration_date: formatToISO(values.expiration_date)
      }
    })
      .then(onSuccess)
      .catch(onError)
  }

  return (
    <AccessPeriodForm onSubmit={onSubmit} method='PATCH'>
      <DatePicker
        id='edit-exam-access-period'
        name='expiration_date'
        required
        t={t}
        allowPast={false}
        value={values.expiration_date}
        onChange={handleOnChange}
        validate={validateEditAccessField({
          expiration_date: values.expiration_date
        })}
      />
      <ButtonContainer>
        <Button
          id={`${exam.id}-submit-access-period`}
          type='submit'
          disabled={isLoading || !valid}
          isLoading={isLoading}
          onClick={onSubmit}
          size='small'
          color='secondary'
        >
          {t('exams.modal.changeAccessPeriod.button')}
        </Button>
        <Button
          id={`${exam.id}-cancel-access-period`}
          type='button'
          disabled={isLoading || !valid}
          isLoading={isLoading}
          size='small'
          color='tertiary'
          onClick={onSubmitSuccess}
        >
          {t('exams.modal.changeAccessPeriod.cancel')}
        </Button>
      </ButtonContainer>
    </AccessPeriodForm>
  )
}

const AccessPeriodForm = styled.form`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.form`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  button {
    min-width: 160px;
  }
`

export default FormEditAccessPeriod

import i18n from 'i18next'

import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import enJSON from 'locales/en.json'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import LocalStorageService from 'services/LocalStorageService'
import { LANGUAGES } from 'utils/locales'

const selectedLanguage =
  LocalStorageService.get(LOCAL_STORAGE_KEYS.selectedLanguage) || LANGUAGES.en

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources: {
      [LANGUAGES.en]: { translation: enJSON }
    },
    lng: selectedLanguage,
    fallbackLng: LANGUAGES.en,
    interpolation: {
      escapeValue: false
    }
  })

export const changeLanguage = (lng: string) => {
  LocalStorageService.set(LOCAL_STORAGE_KEYS.selectedLanguage, lng)
  i18n.changeLanguage(lng)
}

export default i18n

import React from 'react'
import { Switch, BrowserRouter, Redirect } from 'react-router-dom'
import RouteExam from 'components/RouteExam'
import RoutePrivate from 'components/RoutePrivate'
import RoutePublic from 'components/RoutePublic'
import RouteGame from 'components/RouteGame'
import RefreshTokenProvider from 'providers/RefreshTokenProvider'
import Exam from 'pages/Exam'
import ExamExplanations from 'pages/ExamExplanations'
import ExamScoreReport from 'pages/ExamScoreReport'
import ExamScoreReportAnswers from 'pages/ExamScoreReportAnswers'
import ExamSectionDiagnostic from 'pages/ExamSectionDiagnostic'
import Exams from 'pages/Exams'
import Dashboard from 'pages/Dashboard'
import Projections from 'pages/Projections'
import Auth from 'pages/Auth'
import AuthImpersonation from 'pages/AuthImpersonation'
import NotFound from 'pages/NotFound'
import Unauthorized from 'pages/Unauthorized'
import EmptyAccount from 'pages/EmptyAccount'
import PreviewExam from 'pages/PreviewExam'
import PreviewBook from 'pages/PreviewBook'
import Logout from 'pages/Logout'
import BookViewer from 'pages/BookViewer'
import PATHS from 'utils/paths'
import Toast from 'modules/toast/Toast'

import { STUDENT_ROLES } from 'utils/user'
import Notes from 'pages/Notes'
import Videos from 'pages/Videos'
import Flashcards from 'pages/Flashcards'
import FlashcardsStudy from 'pages/FlashcardsStudy'
import SelectCourse from 'pages/SelectCourse'
import DashboardLoading from 'pages/DashboardLoading'
import CourseDeleted from 'pages/CourseDeleted'
import CoursePaused from './pages/CoursePaused'
import SaltyBlox from './pages/SaltyBlox'
import SaltyBloxRespiration from './pages/SaltyBloxRespiration'
import GamesView from './pages/Games'
import FlashcardBoxes from './pages/FlashcardBoxes'
import FlashcardsArchive from './pages/FlashcardsArchive'
import Leaderboard from './pages/Leaderboard'
import CourseTopics from './pages/CourseTopics'
import VideoDetails from './pages/VideoDetails'
import Onboarding from 'pages/Onboarding'
import Hangman from 'pages/Hangman'
import EventCalendar from 'pages/EventCalendar'
import SaltyBloxLoader from 'pages/SaltyBloxLoader'
import ExamLoading from 'pages/ExamLoading'
import CalendarSettings from 'pages/CalendarSettings'
import CalendarSettingsInit from 'pages/CalendarSettingsInit'
import CalendarRescheduleSettings from 'pages/CalendarRescheduleSettings'
import ResetCalendarModalNew from 'modules/calendar/ResetCalendarModalNew'
import ReorderCalendarModalNew from 'modules/calendar/ReorderCalendarModalNew'
import CalendarInit from 'modules/calendar/setup/CalendarInit'
import CalendarInitForExpiration from 'modules/calendar/setup/CalendarInitForExpiration'
import ReorderCalendarForExpiration from 'modules/calendar/ReorderCalendarForExpiration'
import ResetCalendarForExpiration from 'modules/calendar/ResetCalendarForExpiration'
import CalendarSetupForFreeTrial from 'modules/calendar/setup/CalendarSetupForFreeTrial'

function App(): JSX.Element {
  const disableRightClick = e => e.preventDefault()

  const disableDevToolShortcuts = e => {
    const windowsDevTools = e.ctrlKey && e.shiftKey && e.keyCode === 73
    const macBookDevTools = e.altKey && e.metaKey && e.keyCode === 73
    const f12key = e.keyCode === 123

    if (windowsDevTools || macBookDevTools || f12key) {
      e.preventDefault()
    }
  }

  const disableCopy = e => e.preventDefault()

  // Disable right click and dev tools shortcuts
  React.useEffect(() => {
    document.addEventListener('contextmenu', disableRightClick)
    document.addEventListener('keydown', disableDevToolShortcuts)
    document.addEventListener('copy', disableCopy)

    return () => {
      document.removeEventListener('contextmenu', disableRightClick)
      document.removeEventListener('keydown', disableDevToolShortcuts)
      document.removeEventListener('copy', disableCopy)
    }
  }, [])

  return (
    <BrowserRouter>
      <Toast />
      <RefreshTokenProvider />
      <Switch>
        <RoutePublic
          exact
          path={PATHS.authImpersonation}
          component={AuthImpersonation}
        />
        <RoutePublic exact path={PATHS.auth} component={Auth} />
        <RoutePublic exact path={PATHS.unauthorized} component={Unauthorized} />
        <RoutePublic exact path={PATHS.emptyAccount} component={EmptyAccount} />
        <RoutePublic exact path={PATHS.notFound} component={NotFound} />
        <RoutePublic exact path={PATHS.logout} component={Logout} />
        <RoutePublic
          exact
          path={PATHS.courseDeleted}
          component={CourseDeleted}
        />
        <RoutePublic exact path={PATHS.coursePaused} component={CoursePaused} />
        <RoutePrivate
          exact
          path={PATHS.exams}
          component={Exams}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.dashboard}
          component={Dashboard}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.videos}
          component={Videos}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.videoDetails}
          component={VideoDetails}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.projections}
          component={Projections}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.examSectionDiagnostic}
          component={ExamSectionDiagnostic}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.examSectionAnswerSheet}
          component={ExamScoreReportAnswers}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.examScoreReport}
          component={ExamScoreReport}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.book}
          component={BookViewer}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.notes}
          component={Notes}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.flashcardsBoxes}
          component={FlashcardBoxes}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.flashcards}
          component={Flashcards}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.flashcardsStudy}
          component={FlashcardsStudy}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.bookNotes}
          component={Notes}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.leaderboard}
          component={Leaderboard}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.selectCourse}
          component={SelectCourse}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePublic
          exact
          path={PATHS.examLoading}
          component={ExamLoading}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePublic
          exact
          path={PATHS.dashboardLoading}
          component={DashboardLoading}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RouteExam
          exact
          path={PATHS.exam}
          component={Exam}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.games}
          component={GamesView}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.flashcardsArchive}
          component={FlashcardsArchive}
          allowedRoles={[STUDENT_ROLES.course]}
        />
        <RouteExam
          exact
          path={PATHS.examExplanations}
          component={ExamExplanations}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RouteExam
          exact
          path={PATHS.examExplanationsQuestion}
          component={ExamExplanations}
          allowedRoles={[STUDENT_ROLES.exam, STUDENT_ROLES.course]}
        />
        <RouteGame
          exact
          path={PATHS.gameSaltyBlox}
          component={SaltyBlox}
          allowedRoles={[STUDENT_ROLES.course]}
        />
        <RoutePublic
          exact
          path={PATHS.gameSaltyBloxLoader}
          component={SaltyBloxLoader}
        />
        <RouteGame
          exact
          path={PATHS.gameRespiration}
          component={SaltyBloxRespiration}
          allowedRoles={[STUDENT_ROLES.course]}
        />
        <RouteGame
          exact
          path={PATHS.gameHangman}
          component={Hangman}
          allowedRoles={[STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.courseTopics}
          component={CourseTopics}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.onboarding}
          component={Onboarding}
          allowedRoles={[STUDENT_ROLES.course]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendar}
          component={EventCalendar}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        {/* <RoutePrivate
          exact
          path={PATHS.calendarDaysOff}
          component={DaysOffCalendar}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        /> */}
        <RoutePrivate
          exact
          path={PATHS.calendarSettings}
          component={CalendarSettings}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendarSettingsInit}
          component={CalendarSettingsInit}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendarReorder}
          component={ReorderCalendarModalNew}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendarReorderForExpiration}
          component={ReorderCalendarForExpiration}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendarResetForExpiration}
          component={ResetCalendarForExpiration}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        {/* <RoutePrivate
          exact
          path={PATHS.calendarReset}
          component={ResetCalendarModal}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        /> */}
        <RoutePrivate
          exact
          path={PATHS.calendarReset}
          component={ResetCalendarModalNew}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendarRescheduleSettings}
          component={CalendarRescheduleSettings}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendarSetup}
          component={CalendarInit}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendarSetupFreeTrial}
          component={CalendarSetupForFreeTrial}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePrivate
          exact
          path={PATHS.calendarSetupExpiration}
          component={CalendarInitForExpiration}
          allowedRoles={[STUDENT_ROLES.course, STUDENT_ROLES.exam]}
        />
        <RoutePublic exact path={PATHS.previewExam} component={PreviewExam} />
        <RoutePublic exact path={PATHS.previewBook} component={PreviewBook} />
        <Redirect to={PATHS.exams} />
      </Switch>
    </BrowserRouter>
  )
}

export default App

import React, { useCallback, useEffect } from 'react'
import { BackButton, Button, RadioButton } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import { pathOr, pipe, propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import {
  changeFlashcardsStudyMode,
  shuffleFlashcards
} from 'services/FlashcardsService'
import {
  getCustomBoxes,
  getFlashcardsPagination,
  getFlashcardsProficiencyLevelStats,
  getFlashcardsSelectedSide
} from 'modules/flashcards/ducks/selectors'
import { findLowestLevelWithFlashcards, FLASHCARD_SIDE } from 'utils/flashcards'
import PATHS from 'utils/paths'
import { Book, Chapter } from 'types'
import * as R from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from '../../../utils/ramda'
import {
  fetchCustomBoxesRoutine,
  fetchPlvlStatsRoutine
} from '../ducks/actions'
import { studentFetchDetailsRoutine } from '../../auth/ducks/actions'
import ModalBulkRemoveFromCustomBox from './ModalBulkRemoveFromCustomBox'

const ButtonRedirectToStudyView = () => {
  const {
    push,
    location: { pathname, search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const selectedBookId = pathOr('', ['filter', 'b.id'], parsedQuery)
  const selectedBookChapterId = pathOr('', ['filter', 'bcc.id'], parsedQuery)
  const books = useSelector(selectAllBooks)
  const dispatch = useDispatch()

  // @ts-ignore
  const selectedBook: Book = R.find(R.propEq('id', selectedBookId))(books)
  // @ts-ignore
  const chapters: Chapter[] = R.pipe(
    R.find(R.propEq('id', selectedBookId)),
    R.propOr([], 'chapters')
  )(books)
  // @ts-ignore
  const selectedChapter: Chapter = R.find(
    R.propEq('id', selectedBookChapterId)
  )(chapters)

  const { t } = useTranslation()
  const flashcardsPagination = useSelector(getFlashcardsPagination)
  const totalRecords = propOr(0, 'recordsTotal', flashcardsPagination)
  const proficiencyStats = useSelector(getFlashcardsProficiencyLevelStats)
  const flashcardSideToDisplay = useSelector(getFlashcardsSelectedSide)

  const redirectToFlashcardBoxes = () => push(PATHS.flashcardsBoxes)

  const fetchCustomBoxes = React.useCallback(
    () => dispatch(fetchCustomBoxesRoutine()),
    [dispatch]
  )

  const bookTitle = propOr('', 'tag', selectedBook)
  const chapterTitle = propOr('', 'title', selectedChapter)

  const fetchPlvlStats = useCallback(
    query => dispatch(fetchPlvlStatsRoutine({ query })),
    [dispatch]
  )

  const handleFetchPlvls = () => {
    const query = qs.stringify(
      {
        ...parsedQuery,
        filter: parsedQuery.filter,
        limit: {
          take: 1,
          page: 1
        }
      },
      {
        encode: false,
        addQueryPrefix: true
      }
    )

    fetchPlvlStats(query)
  }

  useEffect(() => {
    handleFetchPlvls()
  }, [])

  const redirectToStudyView = () => {
    const query = qs.stringify(
      {
        ...parsedQuery,
        filter: {
          ...parsedQuery.filter,
          'f.proficiency_level': pathOr(
            findLowestLevelWithFlashcards(proficiencyStats),
            ['filter', 'f.proficiency_level'],
            parsedQuery
          )
        },
        limit: {
          take: 1,
          page: 1
        }
      },
      {
        encode: false,
        addQueryPrefix: true
      }
    )

    const handleSuccess = () => {
      push(`${PATHS.flashcardsStudy}${query}`, {
        bookTitle: R.propOr(null, 'title', selectedBook),
        chapterTitle: R.propOr(null, 'title', selectedChapter),
        from: `${pathname}${search}`
      })
    }
    const handleError = e => console.error(e)

    shuffleFlashcards().then(handleSuccess).catch(handleError)
  }

  const selectedCustomBoxId = pathOr(
    '',
    ['filter', 'custom_box_id'],
    parsedQuery
  )
  const allCustomBoxes = useSelector(getCustomBoxes)
  const selectedCustomBox = R.find(R.propEq('id', selectedCustomBoxId))(
    allCustomBoxes
  )
  const customBoxTitle = R.propOr('', 'title', selectedCustomBox)

  useEffect(() => {
    if (isNilOrEmpty(allCustomBoxes)) {
      fetchCustomBoxes()
    }
  }, [allCustomBoxes])

  const handleModeChange = e => {
    const handleSuccess = () => {
      dispatch(studentFetchDetailsRoutine())
    }
    if (flashcardSideToDisplay !== e) {
      changeFlashcardsStudyMode({ side: e }).then(handleSuccess)
    }
  }

  const isCustomBoxPage = pipe(
    pathOr('', ['filter', 'custom_box_id']),
    isNotNilOrEmpty
  )(parsedQuery)

  return (
    <Container>
      <PageTitle>
        {isNotNilOrEmpty(selectedCustomBoxId) && customBoxTitle}
        {isNotNilOrEmpty(selectedBook) && bookTitle}
        {isNotNilOrEmpty(selectedChapter) && ` ${chapterTitle}`}{' '}
        {t('flashcards.title')} ({totalRecords})
      </PageTitle>
      <SwitchWrapper>
        <RadioButton
          alignTop
          label={t('flashcards.filters.front')}
          name='change-calculation-method'
          value={FLASHCARD_SIDE.front}
          isChecked={flashcardSideToDisplay === FLASHCARD_SIDE.front}
          onChange={handleModeChange}
        />
        <RadioButton
          alignTop
          label={t('flashcards.filters.back')}
          name='answerCode'
          value={FLASHCARD_SIDE.back}
          isChecked={flashcardSideToDisplay === FLASHCARD_SIDE.back}
          onChange={handleModeChange}
        />
      </SwitchWrapper>
      <ButtonsContainer>
        {isCustomBoxPage && <ModalBulkRemoveFromCustomBox />}
        <Button
          color='secondary'
          size='small'
          id='flashcards-start-studying'
          // @ts-ignore
          disabled={totalRecords < 1}
          onClick={redirectToStudyView}
        >
          {t('flashcards.startStudying')}
        </Button>
        <BackButton key='back-button' onClick={redirectToFlashcardBoxes}>
          {t('flashcards.archive.backCTA')}
        </BackButton>
      </ButtonsContainer>
    </Container>
  )
}

export default ButtonRedirectToStudyView

const PageTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
`

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`

const SwitchWrapper = styled.div`
  display: flex;
  height: ${({ theme }) => theme.dimensions.inputSmallHeight};
  align-items: center;
  gap: 10px;
  margin: 0;

  .radio-label {
    margin-left: 8px;
  }
`

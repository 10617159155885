export const eventColors = (color: string) => {
  switch (color) {
    case 'ultramarine':
      return 'red'
    case 'gold':
      return 'orange'
    case 'mathPurple':
      return 'purple'
    case 'green':
      return 'green'
    case 'blue':
      return 'blue'
    case 'brown':
      return 'brown'
    case 'turquoise':
      return 'turquoise'
    default:
      return 'linear-gradient(180deg, #FFEEDF 0%, #FFDCBB 100%)'
  }
}

export const getEventColours = (aamcColors, event) => {
  for (const obj of aamcColors) {
    const matchingCustomEventType = obj.customEventTypes.find(
      customEventType => customEventType.title === event.title
    )

    if (matchingCustomEventType) {
      return {
        colour_gradient_start: obj.colour_gradient_start,
        colour_gradient_end: obj.colour_gradient_end,
        colour_font: obj.colour_font
      }
    }
  }

  return {}
}

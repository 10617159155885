import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import usePrevious from 'hooks/usePrevious'
import styled from 'styled-components'

import {
  ExamFrame,
  ExamAnswers,
  ExamCheckboxDanger
} from 'examkrackers-components'
import {
  fetchQuestionHtmlRoutine,
  saveQuestionAnswerRoutine,
  saveQuestionStatusRoutine
} from 'modules/exam/ducks/actions'

import {
  getExamDetails,
  getQuestion,
  getStepHtml,
  getQuestionOriginalOrder,
  getSectionIdByCurrentPageId
} from 'modules/exam/ducks/selectors'

import { RootReducer } from 'types'
import { QUESTION_STATUSES, replaceOwnTags } from 'modules/exam/utils/exam'
import TimeTracking from 'modules/exam/components/Timer/TimeTracking'
import { pathOr, pipe, propOr } from 'ramda'
import { isNilOrEmpty } from 'utils/ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import {
  getAccountType,
  getIsPreviewAdmin,
  getStudent
} from 'modules/auth/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

// @ts-ignore
export const QuestionPage = (props: any): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(getStudent)
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)

  const {
    setCurrentPage,
    currentPage,
    currentPageConfig,
    setNavigationHandlers
  } = props

  const examDetails = useSelector(getExamDetails)
  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, currentPage)
  )
  const accountType: string = useSelector(getAccountType)

  const type_title = pathOr('', ['type', 'title'], examDetails)
  const title = pathOr('', ['exam', 'title'], examDetails)
  const questionNumber = R.propOr(0, 'currentPage', currentPageConfig)
  const sectionIdByCurrentPageId: string = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, currentPage)
  )

  const sectionNumber = R.pipe(
    R.propOr([], 'questions'),
    R.findIndex(R.propEq('id', sectionIdByCurrentPageId)),
    R.add(1)
  )(examDetails)

  const passageId = R.propOr('', 'passageId', stepHtml)
  const passages = R.pathOr(
    [],
    ['questions', sectionNumber - 1, 'passages'],
    examDetails
  )

  const passage = R.find(R.propEq('id', passageId), passages)

  const passageNumber = R.propOr('standalone', 'realOrder', passage)

  const prevExamDetails = usePrevious(examDetails)
  const prevPage = usePrevious(currentPage)

  const prevStepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, prevPage)
  )

  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, currentPage)
  )

  const questionOriginalOrder = useSelector((state: RootReducer) =>
    getQuestionOriginalOrder(state, currentPage)
  )

  const fetchQuestionHtml = React.useCallback(
    (id: string) => dispatch(fetchQuestionHtmlRoutine({ id })),
    [dispatch]
  )

  const saveQuestionAnswer = React.useCallback(
    payload => dispatch(saveQuestionAnswerRoutine(payload)),
    [dispatch]
  )

  const saveQuestionStatus = React.useCallback(
    payload => dispatch(saveQuestionStatusRoutine(payload)),
    [dispatch]
  )

  // Check if the current page is false.
  const isFalsePassage = R.propOr(false, 'isFalsePassage', stepHtml)
  const passageOriginalId: string = R.propOr('', 'passageOriginalId', stepHtml)
  const prevPassageOriginalId: string = R.propOr(
    '',
    'passageOriginalId',
    prevStepHtml
  )

  const passageTimerCheckboxes: any = pipe(
    pathOr('{}', ['exam', 'timer_checkboxes']),
    JSON.parse,
    propOr({}, 'isPassageReadingTimeStopped')
  )(examDetails)
  const isPassageMarkedAsRead: boolean = R.propOr(
    false,
    passageOriginalId
  )(passageTimerCheckboxes)

  // Selected answer state
  const [selectedAnswer, setSelectedAnswer] = React.useState('')
  const [passageRead, setPassageRead] = React.useState(isPassageMarkedAsRead)
  const [questionReload, setQuestionReload] = React.useState(false)

  React.useEffect(() => {
    if (currentPage !== prevPage && questionReload === false) {
      setQuestionReload(true)
    }
  }, [currentPage])

  React.useEffect(() => {
    if (questionReload) {
      setQuestionReload(false)
    }
  }, [questionReload])

  // Navigation handler functions
  const handleNext = () => setCurrentPage(currentPageConfig.nextPageId)
  const handlePrevious = () => setCurrentPage(currentPageConfig.prevPageId)

  const handleCheckbox = (checked: boolean) => {
    if (checked) {
      AnalyticsService(user).logEvent(
        ANALYTICS_EVENTS.passageReadingTimeChecked,
        {
          Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
          'Exam Title': title,
          'Exam format': type_title,
          'Account type': accountType,
          'Question number': questionNumber,
          'Passage number': passageNumber,
          'Section number': sectionNumber
        }
      )
      setPassageRead(true)
    }
  }

  // Automatically scroll to the top of the page.
  const scrollPassageToTop = () => {
    const passageContainer = document.getElementById('exam-left-panel')

    if (passageContainer) {
      passageContainer.scrollTop = 0
    }
  }

  // Automatically scroll to the top of the page.
  const scrollQuestionToTop = () => {
    const questionContainer = document.getElementById('exam-right-panel')

    if (questionContainer) {
      questionContainer.scrollTop = 0
    }
  }

  // Change the status of the question on the current page.
  const handleChangeQuestionStatus = (status: string) => {
    saveQuestionStatus({ status, id: currentPage })
  }

  // Submit an answer for the question on the current page.
  const handleAnswerQuestion = async (answer: string) => {
    const newAnswer = answer === selectedAnswer ? '' : answer
    const newStatus = isNilOrEmpty(newAnswer)
      ? QUESTION_STATUSES.incomplete
      : QUESTION_STATUSES.complete

    setSelectedAnswer(newAnswer)
    const handleSuccess = () => {
      handleChangeQuestionStatus(newStatus)
    }

    saveQuestionAnswer({
      values: { answer: newAnswer, id: currentPage },
      callback: handleSuccess
    })
  }

  const nextPageIsNotReviewPage = R.pipe(
    R.propOr('section-review-', 'nextPageId'),
    R.includes('section-review-'),
    R.not
  )(currentPageConfig)

  // Set up the page every time it updates.
  React.useEffect(() => {
    handleChangeQuestionStatus(
      questionState.status === QUESTION_STATUSES.unseen
        ? QUESTION_STATUSES.incomplete
        : questionState.status
    )

    setNavigationHandlers((prevState: any) => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    // Scroll back to top if the passage has changed.
    if (prevPassageOriginalId !== passageOriginalId) {
      scrollPassageToTop()
    }

    // If the page has changed, fetch the required resources and set up
    // the new page.
    if (prevPage !== currentPage) {
      scrollQuestionToTop()
      fetchQuestionHtml(currentPage)
      nextPageIsNotReviewPage && fetchQuestionHtml(currentPageConfig.nextPageId)
      setSelectedAnswer(R.propOr('', 'answer', questionState))
    }
  }, [currentPage])

  React.useEffect(() => {
    if (R.not(R.equals(prevExamDetails, examDetails))) {
      setPassageRead(isPassageMarkedAsRead)
    }
  }, [examDetails, currentPage])

  React.useEffect(() => {
    if (passageOriginalId !== prevPassageOriginalId) {
      setPassageRead(isPassageMarkedAsRead)
    }

    if (isPassageMarkedAsRead && !passageRead) {
      setPassageRead(isPassageMarkedAsRead)
    }
  }, [stepHtml, examDetails])

  const answerA = R.pipe(
    R.pathOr('A', ['answers', 'A']),
    replaceOwnTags
  )(stepHtml)

  const answerB = R.pipe(
    R.pathOr('B', ['answers', 'B']),
    replaceOwnTags
  )(stepHtml)

  const answerC = R.pipe(
    R.pathOr('C', ['answers', 'C']),
    replaceOwnTags
  )(stepHtml)

  const answerD = R.pipe(
    R.pathOr('D', ['answers', 'D']),
    replaceOwnTags
  )(stepHtml)

  const questionAnswers = [
    {
      answerCode: 'A',
      answerContent: (
        <div
          dangerouslySetInnerHTML={{
            __html: answerA
          }}
        />
      )
    },
    {
      answerCode: 'B',
      answerContent: (
        <div
          dangerouslySetInnerHTML={{
            __html: answerB
          }}
        />
      )
    },
    {
      answerCode: 'C',
      answerContent: (
        <div
          dangerouslySetInnerHTML={{
            __html: answerC
          }}
        />
      )
    },
    {
      answerCode: 'D',
      answerContent: (
        <div
          dangerouslySetInnerHTML={{
            __html: answerD
          }}
        />
      )
    }
  ]

  // Content for left and right sides of the screen
  const passageContent = R.pipe(
    R.propOr('', 'passage'),
    replaceOwnTags
  )(stepHtml)
  const questionContent = R.pipe(
    R.propOr('', 'question'),
    replaceOwnTags
  )(stepHtml)

  // Left side of the screen
  const leftContent = (
    <PassageContainer isFalsePassage={isFalsePassage}>
      <div id='step-passage'>
        <div dangerouslySetInnerHTML={{ __html: passageContent }} />
      </div>
      {!isFalsePassage && (
        <div className='step-passage__checkbox'>
          <ExamCheckboxDanger
            name={`passage-${passageOriginalId}`}
            isSelected={passageRead}
            onChange={handleCheckbox}
            label={t('exam.timingBox')}
          />
        </div>
      )}
    </PassageContainer>
  )

  // This is only for cypress e2e tests to determine which question is the first one
  const firsQuestionDebug =
    questionOriginalOrder === 1 && !isFalsePassage ? (
      <div id='first-question' />
    ) : (
      <div />
    )

  const correctAnswer: string = R.propOr('', 'correct_answer', stepHtml)

  const isCorrect: boolean = selectedAnswer === correctAnswer
  const isSkipped: boolean = selectedAnswer === ''

  // Right side of the screen
  const rightContent = (
    <QuestionContainer>
      {firsQuestionDebug}
      <div className='step-question__title'>
        <h3>
          {t('exam.questionNo', {
            number: R.propOr(0, 'currentPage', currentPageConfig)
          })}
        </h3>
      </div>
      <div id='step-question'>
        <div
          className='step-question__content'
          dangerouslySetInnerHTML={{ __html: questionContent }}
        />
      </div>
      <div>
        {!questionReload && (
          <ExamAnswers
            answers={questionAnswers}
            onSelectAnswer={handleAnswerQuestion}
            selectedAnswerCode={selectedAnswer}
          />
        )}
        {isPreviewAdmin && (
          <div>
            <ExplanationHeader isCorrect={isCorrect} isSkipped={isSkipped}>
              {t('exam.explanation')}
            </ExplanationHeader>
            <span>
              <strong>Solution: </strong>The correct answer is
              <strong> {correctAnswer}</strong>.
            </span>
            <br />
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: R.pipe(
                  R.propOr('', 'explanation'),
                  replaceOwnTags
                )(stepHtml)
              }}
            />
          </div>
        )}
      </div>
    </QuestionContainer>
  )

  return (
    <>
      <ExamFrame left={leftContent} right={rightContent} />
      <TimeTracking
        currentPageConfig={currentPageConfig}
        currentPage={currentPage}
        passageRead={passageRead}
      />
    </>
  )
}

export default React.memo(QuestionPage)

const ExplanationHeader = styled.div`
  background-color: ${({ theme, isCorrect, isSkipped }) => {
    switch (true) {
      case isSkipped:
        return theme.colors.diagnostics.question.skipped
      case isCorrect:
        return theme.colors.diagnostics.question.correct
      default:
        return theme.colors.diagnostics.question.incorrect
    }
  }};
  color: ${({ theme }) => theme.colors.main.white};
  padding: 0.5rem;
  font-weight: bold;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin: 0;
  }
`

const QuestionContainer = styled.div`
  font-size: 16px !important;
  line-height: 1.5;

  .exam-caption {
    display: flex;
  }

  .exam-caption-main {
    width: 80px;
    flex: none;
    font-weight: bold;
  }

  .exam-caption-secondary {
    padding: 0 4px;
  }

  .exam-indent {
    text-indent: 0.25in;
    margin: 0 !important;
  }

  .exam-left {
    display: inline-block;
    text-align: left;
  }

  .exam-center {
    text-align: center;
  }

  .exam-right {
    display: inline-block;
    text-align: right;
  }

  .exam-roman {
    display: inline-block;
    text-align: right;
    min-width: 30px;
    margin-right: 5px;
  }

  .exam-small {
    font-size: 8pt;
    line-height: 8pt;

    span {
      font-size: 8pt !important;
      line-height: 8pt !important;
    }
  }

  span {
    margin-bottom: 10px;
    font-size: 16px !important;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  b {
    font-weight: 600;
  }

  .step-question__title {
    position: relative;
    display: flex;
    align-items: flex-start;

    h3 {
      padding: 4px 0px 0px;
      margin: 0px 0px 10px;
      text-rendering: optimizelegibility;
      vertical-align: middle;
      line-height: 1.2;
    }
  }

  .step-question__content {
    margin-bottom: 9px;
    line-height: 1.5;

    img {
      display: block;
      margin: 0 auto;
      width: auto;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    width: auto;
  }
`

const PassageContainer = styled.div`
  font-size: 16px !important;
  line-height: 1.5;
  font-weight: ${({ isFalsePassage }) => (isFalsePassage ? 'bold' : 'auto')};

  #step-passage {
    .step-passage__checkbox {
      display: none;
    }
  }

  br {
    line-height: 0;
    display: block;
    margin: 10px 0;
    height: 0;
    content: '';
  }

  .exam-caption {
    display: flex;
  }

  .exam-caption-main {
    width: 80px;
    flex: none;
    font-weight: bold;
  }

  .exam-caption-secondary {
    padding: 0 4px;
  }

  .exam-indent {
    text-indent: 0.25in;
    margin: 0 !important;
  }

  .exam-left {
    display: inline-block;
    text-align: left;
  }

  .exam-center {
    text-align: center;
  }

  .exam-right {
    display: inline-block;
    text-align: right;
  }

  .exam-roman {
    display: inline-block;
    text-align: right;
    min-width: 30px;
    margin-right: 5px;
  }

  .exam-small {
    font-size: 8pt !important;
    line-height: 8pt !important;
    margin-top: 68px;

    span {
      font-size: 8pt !important;
      line-height: 8pt !important;
    }
  }

  span {
    margin-bottom: 10px;
    font-size: 16px !important;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  b {
    font-weight: 600;
  }

  img {
    display: block;
    margin: 0 auto;
    width: auto;
  }

  .step-passage__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 21px;
  }
`

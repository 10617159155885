import React from 'react'
import styled from 'styled-components'
import qs from 'qs'
import {
  filter,
  find,
  includes,
  pipe,
  prop,
  propEq,
  propOr,
  sortBy
} from 'ramda'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { QUERY_PARAMS } from 'utils/flashcards'

import FlashcardsFilterBox from 'modules/flashcards/components/FlashcardsFilterBox'
import FlashcardFilterCustomBox from 'modules/flashcards/components/FlashcardFilterCustomBox'
import AddCustomBox from 'modules/flashcards/components/FlashcardsFiltersAddCustomBox'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { getCustomBoxes } from 'modules/flashcards/ducks/selectors'

import { Course } from 'types'

export const FlashcardFiltersBook = ({ book }): JSX.Element => {
  const bookId = propOr('', 'id', book)
  const tag: string = propOr('', 'tag', book)
  const tagColor: string = propOr('', 'tag_colour', book)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const allCustomBoxes: Course = useSelector(getCustomBoxes)

  const bookCustomBoxes = filter(
    propEq('student_book_id', bookId)
    // @ts-ignore
  )(allCustomBoxes)

  // @ts-ignore
  const flashcardsAmount: number = pipe(
    propOr('{}', 'flashcard_snapshot'),
    JSON.parse,
    propOr([], 'books'),
    // @ts-ignore
    find(b => propEq('id', bookId)(b)),
    propOr('0', 'amount')
  )(currentCourse)

  const flashcardsChaptersAmount = pipe(
    propOr('{}', 'flashcard_snapshot'),
    JSON.parse,
    propOr([], 'books'),
    // @ts-ignore
    find(b => propEq('id', bookId)(b)),
    propOr([], 'chapters')
  )(currentCourse)

  const chapters = pipe(
    propOr([], 'chapters'),
    // @ts-ignore
    sortBy(prop('order'))
    // @ts-ignore
  )(book)

  const {
    location: { search }
  } = useHistory()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const currentExpandedBookLevels: string[] = propOr(
    [],
    QUERY_PARAMS.expandedBookLevels,
    parsedQuery
  )

  const isSelected = includes(bookId, currentExpandedBookLevels)

  const findChapterFlashcardsAmount = chapterId =>
    pipe(
      // @ts-ignore
      find(c => propEq('id', chapterId)(c)),
      propOr('0', 'amount')
      // @ts-ignore
    )(flashcardsChaptersAmount)

  const bookFilters = {
    'b.id': bookId
  }

  const chapterFilters = id => ({
    'b.id': bookId,
    'bcc.id': id
  })

  const renderChapters =
    // @ts-ignore
    chapters.map(chapter => (
      <FlashcardsFilterBox
        smallTitle
        query={qs.stringify(chapterFilters(propOr('', 'id', chapter)))}
        // @ts-ignore
        count={findChapterFlashcardsAmount(propOr('', 'id', chapter))}
        color={tagColor}
        key={`flashcards-box-${propOr('', 'id', chapter)}`}
        name={`${tag}_${propOr('', 'order', chapter)}`}
        filter='bcc.id'
        filterId={propOr('', 'id', chapter)}
      />
    ))

  const renderCustomBoxes =
    // @ts-ignore
    bookCustomBoxes.map(box => (
      <FlashcardFilterCustomBox
        customBox={box}
        key={`custom-box-${propOr('', 'id', box)}`}
        name={propOr('', 'title', box)}
        color={tagColor}
        filter='custom_box_id'
        filterId={propOr('', 'id', box)}
      />
    ))

  return (
    <Row>
      <FlashcardsFilterBox
        query={qs.stringify(bookFilters)}
        count={flashcardsAmount}
        color={tagColor}
        name={tag}
        filter='b.id'
        filterId={bookId}
      />
      {isSelected && <Divider />}
      {isSelected && (
        <ChaptersWrapper>
          {renderChapters}
          {renderCustomBoxes}
          <AddCustomBox book={book} />
        </ChaptersWrapper>
      )}
    </Row>
  )
}

export default FlashcardFiltersBook

export const Row = styled.div`
  display: inline-flex;
  flex: none;
  gap: 16px;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
`

export const ChaptersWrapper = styled.div`
  display: inline-flex;
  flex: none;
  gap: 8px;
  flex-wrap: wrap;
  width: calc(100% - 140px);
  align-items: center;
`

const Divider = styled.div`
  width: 16px;
  height: 1px;
  background: ${({ theme }) => theme.colors.main.grey600};
`

import { createRoutine } from 'redux-saga-routines'
import * as Effects from 'utils/saga'

//
// Routines
//

export const handleSelectItemRoutine = createRoutine('HANDLE_SELECT_ITEM')
export const handleSelectEntityRoutine = createRoutine('HANDLE_SELECT_ENTITY')
export const handleSelectCollectionRoutine = createRoutine(
  'HANDLE_SELECT_COLLECTION'
)

export const handleSelectEntitiesRoutine = createRoutine(
  'HANDLE_SELECT_ENTITIES'
)
export const handleResetSelectionRoutine = createRoutine(
  'HANDLE_RESET_SELECTION'
)

//
// Actions
//

// Select one item that will be stored in the selected items array
function* handleSelectItem({ payload: { item } }) {
  yield Effects.put(handleSelectItemRoutine.request())
  try {
    yield Effects.put(handleSelectItemRoutine.success({ item }))
  } catch (e) {
    yield Effects.put(handleSelectItemRoutine.failure(e))
  }
}

function* handleSelectEntity({ payload: { entity } }) {
  yield Effects.put(handleSelectEntityRoutine.request())
  try {
    yield Effects.put(handleSelectEntityRoutine.success({ entity }))
  } catch (e) {
    yield Effects.put(handleSelectEntityRoutine.failure(e))
  }
}

// Select multiple items that will be stored in the selected items array
function* handleSelectCollection({ payload: { collection } }) {
  yield Effects.put(handleSelectCollectionRoutine.request())
  try {
    yield Effects.put(handleSelectCollectionRoutine.success({ collection }))
  } catch (e) {
    yield Effects.put(handleSelectCollectionRoutine.failure(e))
  }
}

function* handleSelectEntities({ payload: { entities } }) {
  yield Effects.put(handleSelectEntitiesRoutine.request())
  try {
    yield Effects.put(handleSelectEntitiesRoutine.success({ entities }))
  } catch (e) {
    yield Effects.put(handleSelectEntitiesRoutine.failure(e))
  }
}

// Set selected items array as empty array
function* handleResetSelection({ payload }) {
  yield Effects.put(handleResetSelectionRoutine.request())
  try {
    yield Effects.put(handleResetSelectionRoutine.success(payload))
  } catch (e) {
    yield Effects.put(handleResetSelectionRoutine.failure(e))
  }
}

//
// Watchers
//

export function* handleSelectItemWatcher() {
  yield Effects.takeLatest(handleSelectItemRoutine.TRIGGER, handleSelectItem)
}

export function* handleSelectEntityWatcher() {
  yield Effects.takeLatest(
    handleSelectEntityRoutine.TRIGGER,
    handleSelectEntity
  )
}

export function* handleSelectEntitiesWatcher() {
  yield Effects.takeLatest(
    handleSelectEntitiesRoutine.TRIGGER,
    handleSelectEntities
  )
}

export function* handleSelectCollectionWatcher() {
  yield Effects.takeLatest(
    handleSelectCollectionRoutine.TRIGGER,
    handleSelectCollection
  )
}

export function* handleResetSelectionWatcher() {
  yield Effects.takeLatest(
    handleResetSelectionRoutine.TRIGGER,
    handleResetSelection
  )
}

//
// Sagas
//

export const selectionSagas = [
  Effects.fork(handleSelectItemWatcher),
  Effects.fork(handleSelectCollectionWatcher),
  Effects.fork(handleResetSelectionWatcher),
  Effects.fork(handleSelectEntityWatcher),
  Effects.fork(handleSelectEntitiesWatcher)
]

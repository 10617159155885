import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import ApexChart from 'react-apexcharts'
import { FlashcardsProgressChart } from 'modules/dashboard/utils/FlashcardsProgressChart'

import { getFlashcardsChartData } from 'services/BooksService'

import { useFlashCardsTooltips } from '../hooks/useFlashCardsTooltips'

import useOutsideClick from 'hooks/useOutsideClick'

import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

import {
  getTheme,
  FlashcardsIcon,
  TotalProgressIcon,
  ArrowDownIcon
} from 'examkrackers-components'
import FreeTrialChartTooltipProvider from 'modules/dashboard/components/FreeTrialChartTooltipProvider'
import { contentTypes } from './TooltipChart'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'

const NUMBER_OF_COLORS = 5
const NUMBER_OF_PROFICIENCY_LEVELS = 7

export const FlashcardsChart = () => {
  const { t } = useTranslation()
  const theme = getTheme()
  const {
    push,
    location: { pathname }
  } = useHistory()
  const [data, setData] = useState([])
  const [flashcardsModal, setFlashcardsModal] = useState(false)
  const [allElements, setAllElements] = useState([])
  const [allSum, setAllSum] = useState<number>(0)
  const [largest, setLargest] = useState<number>()

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  useEffect(() => {
    getFlashcardsChartData()
      .then(result => {
        const dataWithoutSummary = R.pipe(
          R.propOr([], 'data'),

          R.filter(R.pipe(R.propEq('name', 'ALL'), R.not)),
          // @ts-ignore
          R.sortBy(R.prop('name')),
          R.reverse
        )(result)
        const summaryData = R.find(R.propEq('name', 'ALL'))(result.data)
        // @ts-ignore
        setData(dataWithoutSummary)
        // @ts-ignore
        setAllElements(summaryData)
        // @ts-ignore
        setAllSum(summaryData.data.reduce((a, b) => a + b, 0))
      })
      .catch(console.error)
  }, [])

  const injectGradientDefinitions = () => {
    // @ts-ignore
    document
      .getElementById('apexchartsflashcardsChart')
      .getElementsByTagName('svg')[0]
      .getElementsByTagName('defs')[0].innerHTML += `
        <linearGradient id="GradientFlashcards0" x1="0" y1="0" x2="0" y2="1">
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards5A}" offset="0"></stop>
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards5B}" offset="1"></stop>
        </linearGradient>
        <linearGradient id="GradientFlashcards1" x1="0" y1="0" x2="0" y2="1">
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards5A}" offset="0"></stop>
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards5B}" offset="1"></stop>
        </linearGradient>
        <linearGradient id="GradientFlashcards2" x1="0" y1="0" x2="0" y2="1">
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards4A}" offset="0"></stop>
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards4B}" offset="1"></stop>
        </linearGradient>
        <linearGradient id="GradientFlashcards3" x1="0" y1="0" x2="0" y2="1">
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards3A}" offset="0"></stop>
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards3B}" offset="1"></stop>
        </linearGradient>
        <linearGradient id="GradientFlashcards4" x1="0" y1="0" x2="0" y2="1">
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards2A}" offset="0"></stop>
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards2B}" offset="1"></stop>
        </linearGradient>
        <linearGradient id="GradientFlashcards5" x1="0" y1="0" x2="0" y2="1">
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards1A}" offset="0"></stop>
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards1B}" offset="1"></stop>
        </linearGradient>
        <linearGradient id="GradientFlashcards6" x1="0" y1="0" x2="0" y2="1">
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards1A}" offset="0"></stop>
          <stop stop-color="${theme.colors.dashboard.flashcards.GradientFlashcards1B}" offset="1"></stop>
        </linearGradient>
        `
  }

  useFlashCardsTooltips()

  const getCellColor = (row: SVGGElement, cellValue: string) => {
    const _cellValue = parseInt(cellValue, 10)
    const _seriesName = row.getAttribute('seriesName')

    const _relevantData = flashcardsProgress.series.find(
      el => el.name === _seriesName
    )

    const _total = _relevantData.data.reduce((a, b) => a + b, 0)

    if (_cellValue >= _total * 0.9) {
      return 6
    }

    if (_cellValue >= _total * 0.8) {
      return 5
    }

    if (_cellValue >= _total * 0.6) {
      return 4
    }

    if (_cellValue >= _total * 0.4) {
      return 3
    }

    if (_cellValue >= _total * 0.2) {
      return 2
    }
    return 1
  }

  const injectGradientsForCells = () => {
    const findMaxFromDatasExlcudingAll = R.pipe(
      // @ts-ignore
      R.map(R.prop('data')),
      R.flatten,
      R.reduce(R.max, -Infinity)
    )

    // @ts-ignore
    setLargest(data.length > 0 ? findMaxFromDatasExlcudingAll(data) : largest)
    const multiplier = largest ? Math.floor(largest / NUMBER_OF_COLORS) : 1

    const heatmapRows = document.getElementsByClassName(
      'apexcharts-heatmap-series'
    )

    const profLevels = Array.from(Array(NUMBER_OF_PROFICIENCY_LEVELS))

    const createFlaschcardLink = (proficiencyLevel, row, data) => {
      const name = row.getAttribute('seriesName')

      const flashcard = data.filter(el => {
        return el.name.toUpperCase() === name.toUpperCase()
      })
      const flashcardUrl = `/flashcards/study?filter[b.id]=${flashcard[0].id}&filter[f.proficiency_level]=${proficiencyLevel}&limit[take]=1&limit[page]=1`

      push(flashcardUrl, {
        from: `${pathname}`
      })
    }

    const setGradientForEachCell = row => (column, columnIndex) => {
      const cellElement = row.getElementsByTagName('rect')[columnIndex]

      const cellValue = cellElement.getAttribute('val')

      const randomSeconds = (min, max) =>
        Math.floor(Math.random() * (max - min + 1) + min)

      const addGradientClass = () => {
        cellElement.classList.add(
          `flashcard-gradient-${getCellColor(row, cellValue)}`
        )
      }

      setTimeout(addGradientClass, randomSeconds(300, 2500))

      cellElement.style.cursor = 'auto'

      if (cellValue > 0) {
        cellElement.style.cursor = 'pointer'
        cellElement.onclick = () => {
          createFlaschcardLink(columnIndex + 1, row, data)
        }
      }
    }

    const setGradientForEachColumnInRow = row =>
      R.addIndex(R.forEach)(setGradientForEachCell(row))(profLevels)

    const setGradientsForHeatmapTable = () =>
      // @ts-ignore
      R.forEach(setGradientForEachColumnInRow)(heatmapRows)
    if (largest) {
      setGradientsForHeatmapTable()
    }
  }

  const correctCellHeights = () => {
    const profLevels = Array.from(Array(NUMBER_OF_PROFICIENCY_LEVELS))
    const heatmapRows = document.getElementsByClassName(
      'apexcharts-heatmap-series'
    )

    if (heatmapRows) {
      const setHeightForEachCell = row => (column, columnIndex) => {
        const cellElement = row.getElementsByTagName('rect')[columnIndex]
        const cellHeight = cellElement.getAttribute('height')
        cellElement.setAttribute('height', cellHeight - 2)
      }

      const setHeightsForEachColumnInRow = row => {
        R.addIndex(R.forEach)(setHeightForEachCell(row))(profLevels)
      }

      const setHeightsForHeatmapTable = () => {
        // @ts-ignore
        R.forEach(setHeightsForEachColumnInRow)(heatmapRows)
      }

      setHeightsForHeatmapTable()
    }
  }

  const timedOutInjectDefsForSvgFlashcards = () =>
    setTimeout(injectGradientsForCells, 800)
  const timedOutHeightOfFlashcardsBar = () =>
    setTimeout(correctCellHeights, 500)

  useEffect(() => {
    injectGradientDefinitions()
    timedOutInjectDefsForSvgFlashcards()
  }, [theme])

  useEffect(() => {
    timedOutHeightOfFlashcardsBar()
    window.addEventListener('resize', timedOutInjectDefsForSvgFlashcards)

    return () => {
      window.removeEventListener('resize', timedOutInjectDefsForSvgFlashcards)
    }
  }, [])

  const calculatePercentagesFromAllElements = () => {
    const percentages = allElements.data.map(el => {
      const percentage = (el * 100) / allSum
      const roundedPercentage = Math.floor(percentage * 10) / 10
      return roundedPercentage
    })

    const calculateDifference = () => 100 - percentages.reduce((a, b) => a + b)
    const maxNumber = Math.max(...percentages)

    return percentages.map(num =>
      num === maxNumber ? num + calculateDifference() : num
    )
  }

  const flashcardsRef = useRef(null)
  useOutsideClick(flashcardsRef, () => setFlashcardsModal(false))

  const flashcardsProgress = FlashcardsProgressChart(data)

  const tooltipEvent = e => ({ e, type: contentTypes.flashcards })
  const handleEnterTooltip = e =>
    eventEmitter.emit(events.chartTooltipEnter, tooltipEvent(e))
  const handleLeaveTooltip = () => eventEmitter.emit(events.chartTooltipLeave)

  return (
    <>
      <FreeTrialChartTooltipProvider data={data} id='flashcards-chart' />
      <ChartContainer>
        <Icon
          onMouseEnter={handleEnterTooltip}
          onMouseLeave={handleLeaveTooltip}
        >
          <FlashcardsIcon />
        </Icon>
        <TotalProgress isDarkTheme={isDarkTheme}>
          <TotalProgressIcon />
          <p>{t('dashboard.charts.totalProgress')}</p>
          {flashcardsModal ? (
            <ArrowDownIcon
              style={{
                cursor: 'pointer',
                transform: 'rotate(180deg)'
              }}
            />
          ) : (
            <ArrowDownIcon
              onClick={() => setFlashcardsModal(true)}
              style={{
                cursor: 'pointer'
              }}
            />
          )}
        </TotalProgress>
        {/* @ts-ignore */}
        <ApexChart
          id='flashcards-chart'
          // @ts-ignore
          options={flashcardsProgress.options}
          series={flashcardsProgress.series}
          type='heatmap'
          width='404'
          height='224'
        />
        {flashcardsModal ? (
          <TotalProgressTable ref={flashcardsRef}>
            <TotalsHeader>
              <p>{t('dashboard.charts.forAllBooks')}</p>
            </TotalsHeader>
            <TotalProgressHeader>
              <p>{t('dashboard.charts.proficiencyLevel')}</p>
              <p>{t('dashboard.charts.percentage')}</p>
            </TotalProgressHeader>
            {allElements
              ? // @ts-ignore
                calculatePercentagesFromAllElements().map((el, idx) => {
                  const formattedPercentage =
                    el % 1 === 0 ? el.toFixed(0) : el.toFixed(1)
                  return (
                    <TotalProgressElement key={idx}>
                      <span />
                      <TotalProgressPl>
                        <TotalProgressText>{idx + 1}</TotalProgressText>
                        <TotalProgressText>
                          <p>{`${formattedPercentage}%`}</p>
                        </TotalProgressText>
                      </TotalProgressPl>
                    </TotalProgressElement>
                  )
                })
              : null}
          </TotalProgressTable>
        ) : null}
      </ChartContainer>
    </>
  )
}

const ChartContainer = styled.div`
  position: relative;

  svg {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.mainShadow};
    background: ${({ theme }) => theme.colors.backgrounds.main} !important;

    g.apexcharts-datalabels {
      transform: translateX(40px);
    }

    .apexcharts-heatmap-yaxis-label {
      transform: translate(30px, 3px);
    }

    .apexcharts-heatmap-rect {
      transition: opacity 300ms
        ${({ theme }) => theme.transitions.easing.easeInOut};
      fill: url(#GradientFlashcards0);
      opacity: 0;
      cursor: pointer;
    }

    .apexcharts-data-labels {
      transition: opacity 300ms
        ${({ theme }) => theme.transitions.easing.easeInOut};
      opacity: 0;
    }

    .flashcard-gradient-0 {
      fill: url(#GradientFlashcards0);
      opacity: 1;

      & + .apexcharts-data-labels {
        opacity: 1;
      }
    }

    .flashcard-gradient-1 {
      fill: url(#GradientFlashcards1);
      opacity: 1;

      & + .apexcharts-data-labels {
        opacity: 1;
      }
    }

    .flashcard-gradient-2 {
      fill: url(#GradientFlashcards2);
      opacity: 1;

      & + .apexcharts-data-labels {
        opacity: 1;
      }
    }

    .flashcard-gradient-3 {
      fill: url(#GradientFlashcards3);
      opacity: 1;

      & + .apexcharts-data-labels {
        opacity: 1;
      }
    }

    .flashcard-gradient-4 {
      fill: url(#GradientFlashcards4);
      opacity: 1;

      & + .apexcharts-data-labels {
        opacity: 1;
      }
    }

    .flashcard-gradient-5 {
      fill: url(#GradientFlashcards5);
      opacity: 1;

      & + .apexcharts-data-labels {
        opacity: 1;
      }
    }

    .flashcard-gradient-6 {
      fill: url(#GradientFlashcards6);
      opacity: 1;

      & + .apexcharts-data-labels {
        opacity: 1;
      }
    }
  }

  .apexcharts-title-text {
    fill: ${({ theme }) => theme.colors.main.heading} !important;
  }

  .apexcharts-heatmap-rect {
    stroke: transparent !important;
    width: 42.61607142857143px;
  }
`

const Icon = styled.div`
  position: absolute;
  top: 10px;
  left: 14px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  color: ${({ theme }) => theme.colors.main.white};
  background: ${({ theme }) => theme.colors.dashboard.totalProgressBackground};
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;

  svg {
    font-size: 16px;
    border-radius: unset;
    box-shadow: none;
    background: none !important;
  }
`

const TotalProgress = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  z-index: 1000;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: ${({ theme, isDarkTheme }) =>
    isDarkTheme
      ? `inset -1px -1px 4px 0px ${theme.colors.dashboard.totalProgressShadowLight},
         inset 1px 1px 4px 0px ${theme.colors.dashboard.totalProgressShadowDark}`
      : 'none'};
  padding: 5px 10px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: ${({ theme }) => theme.typography.fontSizeSmall};
  background-color: ${({ theme, isDarkTheme }) =>
    !isDarkTheme
      ? theme.colors.main.grey200
      : theme.colors.dashboard.speedometer.white};
  svg {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};
    border-radius: 0px;
    box-shadow: none;
    background: none !important;
  }
  p {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};
    font-size: ${({ theme }) => theme.typography.fontSizeExtraSmall};
    margin-left: 5px;
  }
`

const TotalProgressTable = styled.div`
  position: absolute;
  top: 34px;
  right: 12px;
  width: 380px;
  height: 190px;
  z-index: 1;
  background: ${({ theme }) => theme.colors.dashboard.totalProgressBackground};
  border-radius: 8px;
  box-shadow: 4px 4px 18px 0px #000;
`
const TotalProgressHeader = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  p {
    display: block;
    width: 50%;
    font-size: 11px;
    color: ${({ theme }) => theme.colors.dashboard.totalProgress};
    font-weight: 700;
    padding: 1px 18px;
    &:nth-child(1) {
    }
    &:nth-child(2) {
      padding-left: 26px;
    }
  }
`

const TotalProgressElement = styled.div`
  span {
    display: flex;
    align-content: center;
    align-items: center;
    height: 1px;
    width: 350px;
    margin: 0px 15px;
    background: ${({ theme }) => theme.colors.dashboard.chartsGridLine};
  }
`

const TotalProgressPl = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`

const TotalProgressText = styled.div`
  display: block;
  width: 50%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.white};
  font-weight: 400;
  padding: 4px 18px;
  &:nth-child(1) {
  }
  &:nth-child(2) {
    padding-left: 26px;
  }
  p {
    display: block;
    width: 24px;
    text-align: right;
  }
`
const TotalsHeader = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  padding-top: 1px;
`

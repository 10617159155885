import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TableExamLogs from 'modules/exams/components/TableExamLogs'
import { Modal, Button, Link } from 'examkrackers-components'
import { Exam } from 'types'

interface ModalExamLogsProps {
  exam: Exam
}

const ModalExamLogs = (props: ModalExamLogsProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <TriggerContainer>
      <LinkContainer id='exam-history' onClick={handleOpen}>
        <Link isActive={false} name={t('exams.logsTable.logsCTA')} />
      </LinkContainer>
      <Modal
        title={t('exams.logsTable.logsTitle')}
        open={open}
        handleClose={handleClose}
      >
        <ModalContainer>
          <TableExamLogs id={props.exam.id} />
          <ButtonsContainer>
            <Button
              color='tertiary'
              size='small'
              id='exam-history-close'
              onClick={handleClose}
            >
              {t('exams.logsTable.closeCTA')}
            </Button>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
    </TriggerContainer>
  )
}

export default ModalExamLogs

const ButtonsContainer = styled.div`
  margin: 30px 0 0;

  button {
    min-width: 130px;
  }
`

const ModalContainer = styled.div`
  margin: 30px 0 0;
  min-height: 425px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const LinkContainer = styled.div`
  display: inline-block;
  min-width: 69px;
  max-width: 69px;
`

const TriggerContainer = styled.div`
  line-height: 1;
`

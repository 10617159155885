import APIService from 'services/APIService'
// import { FetchStudyTimeDataPayload } from 'types'

const api = APIService()

export const fetchStudyTimeData = () =>
  // api.get(`/dashboard/graphs/student-course/${payload.id}/study-time`)
  api.get('dashboard/graphs/study-time')

export const fetchStudentCompletionMeter = () =>
  api.get('/dashboard/completion-meter')

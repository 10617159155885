import * as R from 'ramda'
import { isInArray } from 'utils/ramda'

export const arrayContainsPartArray = array =>
  R.pipe(
    // @ts-ignore
    R.filter(isInArray(array)),
    // @ts-ignore
    R.length,
    R.lt(0)
  )

const prepareArray = R.pipe(R.map(R.toLower), R.sortBy(R.identity))

export const areStringArraysEqual = R.curry((a, b) =>
  R.pipe(prepareArray, R.equals(prepareArray(b)))(a)
)

export const arrayContainsFullArray = baseArray => arrayToCompare =>
  R.pipe(
    // @ts-ignore
    R.filter(isInArray(arrayToCompare)),
    // @ts-ignore
    R.length,
    filteredLength => R.equals(filteredLength, R.length(arrayToCompare))
    // @ts-ignore
  )(baseArray)

export const reactToItemSelection = item =>
  R.ifElse(
    R.includes(item),
    R.filter(R.pipe(R.equals(item), R.not)),
    R.append(item)
  )

export const reactToArraySelection = collection =>
  R.ifElse(
    arrayContainsPartArray(collection),
    R.without(collection),
    R.pipe(R.append(collection), R.flatten, R.uniq)
  )

export const addToArrayIfNotIncluded = item =>
  R.ifElse(R.includes(item), R.identity, R.append(item))

export const replaceItemInListById = item => baseArray =>
  R.map(el => (R.propOr('', 'id', el) === item.id ? item : el))(baseArray)

export const listElementsWithDecimalAndSeparatorAtTheEnd = list => {
  const moreThanTwoElements = list.map((el, index, all) =>
    index + 1 === all.length ? `and ${el}` : `${el},`
  )
  const twoElements = R.join(' and ', list)
  const oneElement = R.head(list)

  const elementsLength = list.length

  switch (elementsLength) {
    case 1:
      return oneElement
    case 2:
      return twoElements
    default:
      return R.join(' ', moreThanTwoElements)
  }
}

import React, { useContext, useState } from 'react'
import { Button, BouncingLoader } from 'examkrackers-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// import { useSelector } from 'react-redux'
// import { propOr } from 'ramda'

import SaltyOneWeekSub from 'assets/images/Salty_One_Week_Sub.svg'
import { CalendarContext, CalendarContextProvider } from 'hooks/CalendarContext'
// import { getStudent } from 'modules/auth/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'
import {
  getMcatDates,
  setExamDate,
  setPrioriDays
} from 'services/CalendarService'
import {
  addDaysToDate,
  findNearestMcatDate,
  getUniversalDateString
} from 'utils/date'
import { parseISO } from 'date-fns'

const initialValues = {
  mcat_at: '',
  calendar_start_at: '',
  exam_at: '',
  prioriDays: [7, 1, 2, 3, 4, 0, 6]
}

// const typeOfProduct = {
//   course: 'course',
//   exam: 'exam'
// }

enum LoadingState {
  pristine = 'pristine',
  loading = 'loading',
  success = 'success',
  error = 'error'
}

const WrappedCalendarInit = () => {
  return (
    <CalendarContextProvider>
      <CalendarSetupForFreeTrial />
    </CalendarContextProvider>
  )
}

const CalendarSetupForFreeTrial = () => {
  const [values, _] = useState(initialValues)
  const [isLoading, setIsLoading] = React.useState<LoadingState>(
    LoadingState.pristine
  )
  const { t } = useTranslation()
  const { course } = useContext(CalendarContext)

  const { push } = useHistory()

  const courseId = course.book_course_id
  const courseStudentId = course.id ? course.id : ''

  // const isReady = propOr(false, 'is_ready', course)
  // const user = useSelector(getStudent)

  const redirectToCourseCalendar = prioriDays => {
    // saveCourseInLocalStorage()
    const isPrioriDays = isNotNilOrEmpty(prioriDays)

    isPrioriDays ? push(PATHS.calendar) : push(PATHS.calendarRescheduleSettings)
  }

  const handleStartFreeTrialCalendar = () => {
    setIsLoading(LoadingState.loading)
    const handleSuccess = async dates => {
      const startDate = parseISO(course.accessible_from as string)
      const mcatDate = findNearestMcatDate(dates)
      const endDate = addDaysToDate(
        parseISO(course.accessible_to as string),
        90
      )

      const result = {
        startDate: getUniversalDateString(startDate),
        endDate: getUniversalDateString(endDate),
        mcatDateId: (mcatDate as any).id
      }

      try {
        await setPrioriDays({
          id: courseStudentId,
          prioridays: [7, 1, 2, 3, 4, 0, 6]
        })
        await setExamDate({
          calendar_start_at: result.startDate,
          exam_at: result.endDate,
          course_id: courseStudentId,
          mcat_date_id: result.mcatDateId
        })
        await redirectToCourseCalendar(values.prioriDays)
      } catch (err) {
        console.error('Error setting dates:', err)
      }
    }

    if (courseId) {
      getMcatDates({ id: courseId })
        .then(resp => {
          const dates = resp.data.data
          handleSuccess(dates)
        })
        .catch(err => {
          console.error('Error fetching MCAT dates:', err)
        })
    }
  }

  if (isLoading === LoadingState.loading) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <Container>
      <TitleWrapper data-testid='FreeTrialModal-TitleWrapper'>
        {t('calendar.freeTrialModal.title')}
      </TitleWrapper>
      <MainWrapper data-testid='FreeTrialModal-MainWrapper'>
        <SubtitleWrapper data-testid='FreeTrialModal-DescriptionWrapper'>
          {t('calendar.freeTrialModal.description')}
        </SubtitleWrapper>

        <SaltyContainer data-testid='FreeTrialModal-SaltyContainer'>
          <OneWeekSaltyImage
            data-testid='FreeTrialModal-OneWeekSaltyImage'
            id='reorganize-salty'
            src={SaltyOneWeekSub}
            alt='Salty reorganize'
          />
        </SaltyContainer>
      </MainWrapper>
      <ButtonWrapper
        color='secondary'
        onClick={handleStartFreeTrialCalendar}
        data-testid='FreeTrialModal-ButtonWrapper-buttonOk'
      >
        {t('calendar.buttonOk')}
      </ButtonWrapper>
    </Container>
  )
}

export default WrappedCalendarInit

const TitleWrapper = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 132px;
  font-size: 12px;
  padding-bottom: 30px;
`
const SubtitleWrapper = styled.div`
  text-align: left;
  font-size: 14px;
  width: 416px;
  line-height: 20px;
`

const ButtonWrapper = styled(Button)`
  width: 158px;
  padding: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;

  justify-content: center;
`

const SaltyContainer = styled.div`
  margin-top: 20px;
  display: flex;
  margin-bottom: 25px;
  margin-left: 50px;
`
const OneWeekSaltyImage = styled.img`
  height: 121px;
  width: 165px;
`
const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 50px;
`
const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import * as R from 'ramda'
import {
  getAverageQuestionsWorkingTimeGraph,
  getDiagnosticLegendState,
  getGraphCategories,
  getPassageReadingTimeGraph,
  getPassageWorkingTimeGraph,
  getQuestionsWorkingTimeGraph
} from 'modules/diagnostics/ducks/selectors'

import ApexChart from 'react-apexcharts'
import {
  EXAM_SECTION_TIME_CHART_SERIES,
  ExamSectionTimeChart,
  getExamSectionTimeSeries
} from 'modules/diagnostics/utils/ExamSectionTimeChart'
import { useCustomGrid } from 'modules/diagnostics/hooks/useCustomGrid'
import { useQuestionHover } from 'modules/diagnostics/hooks/useQuestionHover'
import { useCustomXLabels } from 'modules/diagnostics/hooks/useCustomXLabels'
import { EXAM_SECTION_DIFFICULTY_CHART_SERIES } from '../utils/ExamSectionDifficultyChart'

export const TimeChartExamSectionDiagnostics = (): JSX.Element => {
  const params = useParams()
  const sectionId: string = R.propOr('', 'sectionId')(params)
  useCustomGrid('time-chart-exam-section')
  useQuestionHover('time-chart-exam-section')
  useCustomXLabels('time-chart-exam-section')

  const passageReadingTimeGraph = useSelector(getPassageReadingTimeGraph)
  const passageWorkingTimeGraph = useSelector(getPassageWorkingTimeGraph)
  const graphCategories = useSelector(state =>
    getGraphCategories(state, sectionId)
  )
  const questionWorkingTimeData = useSelector(getQuestionsWorkingTimeGraph)
  const averageQuestionsWorkingTimeData = useSelector(
    getAverageQuestionsWorkingTimeGraph
  )
  const diagnosticLegendState = useSelector(getDiagnosticLegendState)

  const findPassageMaxSeconds = () => {
    const readingMax = R.pipe(
      // @ts-ignore
      R.map(R.pipe(R.prop('y'), Number)),
      arr => Math.max(...arr),
      R.add(20),
      num => Math.floor(num / 10) * 10
      // @ts-ignore
    )(passageReadingTimeGraph)
    const workingMax = R.pipe(
      // @ts-ignore
      R.map(R.pipe(R.prop('y'), Number)),
      arr => Math.max(...arr),
      R.add(20),
      num => Math.floor(num / 10) * 10
      // @ts-ignore
    )(passageWorkingTimeGraph)

    return Math.max(readingMax, workingMax)
  }

  const findQuestionsMaxSeconds = () => {
    const secondsData = {
      timePerQuestion: questionWorkingTimeData,
      averageTimePerQuestion: averageQuestionsWorkingTimeData
    }

    return R.pipe(
      R.values,
      // @ts-ignore
      R.flatten,
      R.map(R.pipe(R.prop('y'), Number)),
      arr => Math.max(...arr),
      R.add(20),
      num => Math.floor(num / 10) * 10
      // @ts-ignore
    )(secondsData)
  }

  const isSeriesDataOn = seriesName =>
    R.propOr(true, seriesName, diagnosticLegendState)
  const isDifficultyOn = R.propOr(
    true,
    EXAM_SECTION_DIFFICULTY_CHART_SERIES.difficulty,
    diagnosticLegendState
  )

  const series = {
    passagesWorking: isSeriesDataOn(
      EXAM_SECTION_TIME_CHART_SERIES.passagesWorking
    )
      ? passageWorkingTimeGraph
      : [],
    passagesReading: isSeriesDataOn(
      EXAM_SECTION_TIME_CHART_SERIES.passageReading
    )
      ? passageReadingTimeGraph
      : [],
    timePerQuestion: isSeriesDataOn(
      EXAM_SECTION_TIME_CHART_SERIES.timePerQuestion
    )
      ? questionWorkingTimeData
      : [],
    averageTimePerQuestion: isSeriesDataOn(
      EXAM_SECTION_TIME_CHART_SERIES.averageTimePerQuestion
    )
      ? averageQuestionsWorkingTimeData
      : []
  }

  const chartOptions = ExamSectionTimeChart({
    categories: graphCategories,
    maxPassageSeconds: findPassageMaxSeconds(),
    maxQuestionSeconds: findQuestionsMaxSeconds(),
    isDifficultyOn
  })
  const chartSeries = getExamSectionTimeSeries(series)

  return (
    <Container isDifficultyOn={isDifficultyOn}>
      {/* @ts-ignore */}
      <ApexChart
        // @ts-ignore
        options={chartOptions}
        series={chartSeries}
        width='100%'
        height='100%'
        id='time-chart-exam-section'
      />
    </Container>
  )
}

export default TimeChartExamSectionDiagnostics

const Container = styled.div`
  flex-grow: 1;
  margin-bottom: ${({ isDifficultyOn }) => (isDifficultyOn ? '0' : '25px')};

  .apexcharts-yaxis[rel='0'] {
    text {
      fill: ${({ theme }) =>
        theme.colors.diagnostics.diagnostic.passageWorking.font};
    }
  }
`

import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ArchiveFlashcardsList from 'modules/flashcards/components/ArchiveFlashcardsList'
import FiltersFlashcards from 'modules/flashcards/components/FiltersFlashcards'
import { useSelector } from 'react-redux'
import { getSelectedItems } from '../ducks/selection/selectors'
import { Text, BackButton } from 'examkrackers-components'
import ModalBulkUnarchiveFlashcards from '../modules/flashcards/components/ModalBulkUnarchiveFlashcards'
import { getArchiveFlashcardsPagination } from '../modules/flashcards/ducks/selectors'
import { propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'

export const FlashcardsArchive = (): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()

  const selectedItems = useSelector(getSelectedItems)
  const flashcardsPagination = useSelector(getArchiveFlashcardsPagination)

  const redirectToFlashcards = () => history.push(PATHS.flashcardsBoxes)

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.flashcards.archive')}</title>
      </Helmet>
      <HeadingContainer>
        <FiltersFlashcards
          customElements={
            <ButtonsContainer>
              <ModalBulkUnarchiveFlashcards />
              <BackButton key='back-button' onClick={redirectToFlashcards}>
                {t('flashcards.archive.backCTA')}
              </BackButton>
            </ButtonsContainer>
          }
          title={t('flashcards.archive.title')}
          hideStudyBtn
          hidePlvl
          count={propOr(0, 'recordsTotal', flashcardsPagination)}
        />
      </HeadingContainer>
      <Text size='xs' bold>
        {t('flashcards.archive.selected', { count: selectedItems.length })}
      </Text>
      <ArchiveFlashcardsList />
    </React.Fragment>
  )
}

export default FlashcardsArchive

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Heading2, CloseIcon } from 'examkrackers-components'
import ErratasList from './ErratasList'
import { useTranslation } from 'react-i18next'

import { getAllExamErrata, getBookErratas } from 'services/ErrataService'
import { useSelector } from 'react-redux'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import { Book } from './ErrataTypes'
import PATHS from 'utils/paths'
import { useRouteMatch } from 'react-router-dom'
import { getStudent } from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'
import { Subchapter } from 'types'
import { fetchSubchaptersByBookId } from 'services/BooksService'

const Erratas = ({ erratasOpen, setErratasOpen }) => {
  const { t } = useTranslation()
  const [selectedErrata, setSelectedErrata] = useState()
  const [subchapterList, setSubchapterList] = useState<Subchapter[]>([])

  const { path } = useRouteMatch()
  const isDashboard = path === PATHS.dashboard
  const studentDetails = useSelector(getStudent)
  const dashboardTheme = propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  const handleCloseErratas = () => {
    setErratasOpen(false)
  }

  const books: any[] = useSelector(selectAllBooks)

  const [selectedBook, setSelectedBook] = useState<Book | undefined>(books[0])
  // @ts-ignore
  const selectedBookId = selectedBook.book_id as string
  const [erratasList, setErratasList] = useState<any[]>([])

  const getSubchapters = () => {
    const handleSuccess = response => {
      setSubchapterList(response.data.subchapters)
    }
    const handleError = response => {
      console.error(response)
    }

    fetchSubchaptersByBookId({ id: selectedBookId })
      .then(handleSuccess)
      .catch(handleError)
  }

  const fetchBookErratas = () => {
    const handleSuccess = response => setErratasList(response.data)
    const handleError = response => {
      console.error(response)
    }

    getBookErratas({ bookId: selectedBookId })
      .then(handleSuccess)
      .catch(handleError)
  }

  const fetchExamErratas = () => {
    const handleSuccess = response => setErratasList(response.data)
    const handleError = response => {
      console.error(response)
    }
    setErratasList([])
    getAllExamErrata().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    if (selectedBookId) {
      getSubchapters()
    }
  }, [selectedBookId])

  useEffect(() => {
    setSelectedErrata(erratasList[0])
  }, [erratasList])

  useEffect(() => {
    fetchBookErratas()
  }, [selectedBook])

  return (
    <ErrataContainer>
      <StyledModal isDashboard={isDashboard} isDarkTheme={isDarkTheme}>
        <Title>
          <Heading2 bold>{t('erratas.title')}</Heading2>
          <CloseIcon onClick={handleCloseErratas} />
        </Title>
        <ErratasWrapper>
          <ErratasList
            setSelectedErrata={setSelectedErrata}
            selectedErrata={selectedErrata}
            erratasList={erratasList}
            fetchBookErratas={fetchBookErratas}
            fetchExamErratas={fetchExamErratas}
            selectedBook={selectedBook}
            setSelectedBook={setSelectedBook}
            books={books}
            subchapterList={subchapterList}
          />
        </ErratasWrapper>
      </StyledModal>
    </ErrataContainer>
  )
}

export default Erratas

const ErrataContainer = styled.div`
  z-index: ${({ theme }) => theme.zIndex.menu + 1};
  width: 715px;
  height: 600px;
  position: absolute;
  top: 28px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  transition: all 300ms;
`

const StyledModal = styled.div`
  border: 1px solid ${props => props.theme.colors.errata.stroke} !important;
  width: 740px;
  height: 600px;
  max-height: 600px;
  padding: 10px;
  ${({ isDashboard, isDarkTheme }) =>
    isDashboard && isDarkTheme
      ? css`
          background-image: ${({ theme }) => theme.colors.backgrounds.main};
        `
      : css`
          background-color: ${({ theme }) => theme.colors.main.primary100};
        `}
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
`
const ErratasWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  text-align: left;
  h2 {
    font-size: 16px;
  }
`

import React from 'react'
import styled from 'styled-components'
import { ExamFrame, ExamAnswers, ExamCheckbox } from 'examkrackers-components'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from 'hooks/usePrevious'
import { useTranslation } from 'react-i18next'

import { fetchQuestionHtmlRoutine } from 'modules/exam/ducks/actions'
import {
  getExamDetails,
  getQuestion,
  getStepHtml
} from 'modules/exam/ducks/selectors'
import { RootReducer, SingleQuestionMetrics } from 'types'
import { getSingleQuestionMetrics } from 'modules/diagnostics/ducks/selectors'
import { fetchSingleQuestionMetricsRoutine } from 'modules/diagnostics/ducks/actions'
import * as R from 'ramda'
import { formatSecondsToMinutesAndSeconds } from 'utils/date'
import { replaceOwnTags } from 'modules/exam/utils/exam'

export const QuestionPage = (props): JSX.Element => {
  // Get i18n handler
  const { t } = useTranslation()

  // Get React-Redux dispatcher
  const dispatch = useDispatch()

  const {
    setCurrentPage,
    currentPage,
    currentPageConfig,
    setNavigationHandlers
  } = props

  // Get the previous page state.
  const prevPage = usePrevious(currentPage)
  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, currentPage)
  )

  const prevStepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, prevPage)
  )

  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, currentPage)
  )

  // Exam object
  const examDetails = useSelector(getExamDetails)
  const metrics: SingleQuestionMetrics = useSelector(getSingleQuestionMetrics)
  const questionWorkingAverage = R.pathOr(
    1,
    ['studentsQuestionMetrics', 'timers', 'working'],
    metrics
  )

  const isFalsePassage = R.propOr(false, 'isFalsePassage', stepHtml)
  const passageOriginalId: string = R.propOr('', 'passageOriginalId', stepHtml)
  const prevPassageOriginalId: string = R.propOr(
    '',
    'passageOriginalId',
    prevStepHtml
  )

  const selectedQuestionsForTimers: any = R.pipe(
    R.pathOr('{}', ['exam', 'timer_checkboxes']),
    JSON.parse,
    R.keys
  )(examDetails)

  // @ts-ignore
  const isPassageCheckboxSelected: boolean = R.includes(
    currentPage,
    selectedQuestionsForTimers
  )

  const handleNext = () => setCurrentPage(currentPageConfig.nextPageId)
  const handlePrevious = () => setCurrentPage(currentPageConfig.prevPageId)

  const fetchQuestionHtml = React.useCallback(
    (id: string) => dispatch(fetchQuestionHtmlRoutine({ id })),
    [dispatch]
  )

  const fetchMetrics = React.useCallback(
    (questionId: string) =>
      dispatch(fetchSingleQuestionMetricsRoutine({ id: questionId })),
    [dispatch]
  )

  const nextPageIsNotReviewPage = R.pipe(
    R.propOr('section-review-', 'nextPageId'),
    R.includes('section-review-'),
    R.not
  )(currentPageConfig)

  const selectedAnswer: string = R.propOr('', 'answer', questionState)
  const correctAnswer: string = R.propOr('', 'correct_answer', stepHtml)

  // Flag for whether the answer provided by the student was correct.
  const isCorrect: boolean = selectedAnswer === correctAnswer
  const isSkipped: boolean = selectedAnswer === ''

  // Automatically scroll to the top of the page.
  const scrollPassageToTop = () => {
    const passageContainer = document.getElementById('exam-left-panel')

    if (passageContainer) {
      passageContainer.scrollTop = 0
    }
  }

  // Automatically scroll to the top of the page.
  const scrollQuestionToTop = () => {
    const questionContainer = document.getElementById('exam-right-panel')

    if (questionContainer) {
      questionContainer.scrollTop = 0
    }
  }

  const questionDistribution = code =>
    R.pathOr(0, ['distribution', code, 'percentage'], stepHtml)

  const answerA = R.pipe(
    R.pathOr('A', ['answers', 'A']),
    replaceOwnTags
  )(stepHtml)

  const answerB = R.pipe(
    R.pathOr('B', ['answers', 'B']),
    replaceOwnTags
  )(stepHtml)

  const answerC = R.pipe(
    R.pathOr('C', ['answers', 'C']),
    replaceOwnTags
  )(stepHtml)

  const answerD = R.pipe(
    R.pathOr('D', ['answers', 'D']),
    replaceOwnTags
  )(stepHtml)

  const questionAnswers = [
    {
      answerCode: 'A',
      answerContent: (
        <div>
          <AnswerOption
            isSelected={selectedAnswer === 'A'}
            isCorrect={correctAnswer === 'A'}
            dangerouslySetInnerHTML={{
              __html: answerA
            }}
          />
          <DistributionLine
            distribution={questionDistribution('A')}
            isCorrect={correctAnswer === 'A'}
          >
            <span>{questionDistribution('A')}%</span>
            <div className='line' />
          </DistributionLine>
        </div>
      )
    },
    {
      answerCode: 'B',
      answerContent: (
        <div>
          <AnswerOption
            isSelected={selectedAnswer === 'B'}
            isCorrect={correctAnswer === 'B'}
            dangerouslySetInnerHTML={{
              __html: answerB
            }}
          />
          <DistributionLine
            distribution={questionDistribution('B')}
            isCorrect={correctAnswer === 'B'}
          >
            <span>{questionDistribution('B')}%</span>
            <div className='line' />
          </DistributionLine>
        </div>
      )
    },
    {
      answerCode: 'C',
      answerContent: (
        <div>
          <AnswerOption
            isSelected={selectedAnswer === 'C'}
            isCorrect={correctAnswer === 'C'}
            dangerouslySetInnerHTML={{
              __html: answerC
            }}
          />
          <DistributionLine
            distribution={questionDistribution('C')}
            isCorrect={correctAnswer === 'C'}
          >
            <span>{questionDistribution('C')}%</span>
            <div className='line' />
          </DistributionLine>
        </div>
      )
    },
    {
      answerCode: 'D',
      answerContent: (
        <div>
          <AnswerOption
            isSelected={selectedAnswer === 'D'}
            isCorrect={correctAnswer === 'D'}
            dangerouslySetInnerHTML={{
              __html: answerD
            }}
          />
          <DistributionLine
            distribution={questionDistribution('D')}
            isCorrect={correctAnswer === 'D'}
          >
            <span>{questionDistribution('D')}%</span>
            <div className='line' />
          </DistributionLine>
        </div>
      )
    }
  ]

  const passageContent = R.pipe(
    R.propOr('', 'passage'),
    replaceOwnTags
  )(stepHtml)

  const questionContent = R.pipe(
    R.propOr('', 'question'),
    replaceOwnTags
  )(stepHtml)

  const leftContent = (
    <PassageContainer isFalsePassage={isFalsePassage}>
      <div id='step-passage'>
        <div dangerouslySetInnerHTML={{ __html: passageContent }} />
      </div>
    </PassageContainer>
  )

  const getCorrectLabel = () => {
    switch (true) {
      case isCorrect:
        return 'correct'
      case isSkipped:
        return 'skipped'
      default:
        return 'incorrect'
    }
  }

  const rightContent = (
    <QuestionContainer>
      <div className='step-question__title'>
        <h3>
          {t('exam.questionNo', {
            number: R.propOr(0, 'currentPage', currentPageConfig)
          })}{' '}
          <AnswerCorrect isCorrect={isCorrect} isSkipped={isSkipped}>
            {t(`diagnostics.${getCorrectLabel()}`)}
          </AnswerCorrect>
        </h3>
        <div className='step-question__checkbox'>
          <ExamCheckbox
            name={`passage-${passageOriginalId}`}
            isSelected={isPassageCheckboxSelected}
            onChange={() => {}}
          />
        </div>
      </div>
      <div id='step-question'>
        <div
          className='step-question__content'
          dangerouslySetInnerHTML={{ __html: questionContent }}
        />
      </div>
      <div>
        <ExamAnswers
          answers={questionAnswers}
          onSelectAnswer={() => {}}
          selectedAnswerCode={R.propOr('', 'answer', questionState)}
        />
        <SkippedAnswers distribution={questionDistribution('no-answer-given')}>
          <div className='label'>{t('diagnostics.skipped')}:</div>
          <div className='container'>
            <div className='value'>
              {questionDistribution('no-answer-given')}%
            </div>
            <div className='line' />
          </div>
        </SkippedAnswers>
        <div>
          <ExplanationHeader isCorrect={isCorrect} isSkipped={isSkipped}>
            <span>{t('exam.explanation')}</span>
            <span>
              {t('diagnostics.questionWorkingTime')}:{' '}
              {formatSecondsToMinutesAndSeconds(questionWorkingAverage)}
            </span>
          </ExplanationHeader>
          <span>
            <strong>Solution: </strong>The correct answer is
            <strong> {correctAnswer}</strong>.
          </span>
          <br />
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: R.pipe(
                R.propOr('', 'explanation'),
                replaceOwnTags
              )(stepHtml)
            }}
          />
        </div>
      </div>
    </QuestionContainer>
  )

  React.useEffect(() => {
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    if (prevPassageOriginalId !== passageOriginalId) {
      scrollPassageToTop()
    }

    if (prevPage !== currentPage) {
      scrollQuestionToTop()
      fetchQuestionHtml(currentPage)
      fetchMetrics(currentPage)
      nextPageIsNotReviewPage && fetchQuestionHtml(currentPageConfig.nextPageId)
    }
  }, [currentPage])

  return (
    <>
      <ExamFrame left={leftContent} right={rightContent} />
    </>
  )
}

const QuestionContainer = styled.div`
  font-size: 16px !important;
  line-height: 1.5;

  .exam-caption {
    display: flex;
  }

  .exam-caption-main {
    width: 80px;
    font-weight: bold;
    flex: none;
  }

  .exam-caption-secondary {
    padding: 0 4px;
  }

  .exam-indent {
    text-indent: 0.25in;
    margin: 0 !important;
  }

  .exam-left {
    display: inline-block;
    text-align: left;
  }

  .exam-center {
    text-align: center;
  }

  .exam-right {
    display: inline-block;
    text-align: right;
  }

  .exam-roman {
    display: inline-block;
    text-align: right;
    min-width: 30px;
    margin-right: 5px;
  }

  .exam-small {
    font-size: 8pt;
    line-height: 8pt;

    span {
      font-size: 8pt !important;
      line-height: 8pt !important;
    }
  }

  span {
    margin-bottom: 10px;
    font-size: 16px !important;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  b {
    font-weight: 600;
  }

  .step-question__title {
    position: relative;
    display: flex;
    align-items: flex-start;

    h3 {
      padding: 4px 0px 0px;
      margin: 0px 0px 10px;
      text-rendering: optimizelegibility;
      vertical-align: middle;
      line-height: 1.2;
    }
  }

  .step-question__content {
    margin-bottom: 9px;
    line-height: 1.5;

    img {
      display: block;
      margin: 0 auto;
      width: auto;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    width: auto;
  }

  .step-question__checkbox {
    position: absolute;
    left: -25px;
    top: 5px;
    // TODO: remove display:none when the timing box will be revreted
    display: none;
  }
`

const PassageContainer = styled.div`
  font-size: 16px !important;
  line-height: 1.5;
  font-weight: ${({ isFalsePassage }) => (isFalsePassage ? 'bold' : 'auto')};

  br {
    line-height: 0;
    display: block;
    margin: 10px 0;
    height: 0;
    content: '';
  }

  .exam-caption {
    display: flex;
  }

  .exam-caption-main {
    width: 80px;
    flex: none;
    font-weight: bold;
  }

  .exam-caption-secondary {
    padding: 0 4px;
  }

  .exam-indent {
    text-indent: 0.25in;
    margin: 0 !important;
  }

  .exam-left {
    display: inline-block;
    text-align: left;
  }

  .exam-center {
    text-align: center;
  }

  .exam-right {
    display: inline-block;
    text-align: right;
  }

  .exam-roman {
    display: inline-block;
    text-align: right;
    min-width: 30px;
    margin-right: 5px;
  }

  .exam-small {
    font-size: 8pt !important;
    line-height: 8pt !important;
    margin-top: 68px;

    span {
      font-size: 8pt !important;
      line-height: 8pt !important;
    }
  }

  span {
    margin-bottom: 10px;
    font-size: 16px !important;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  b {
    font-weight: 600;
  }

  img {
    display: block;
    margin: 0 auto;
    width: auto;
  }
`

const ExplanationHeader = styled.div`
  background-color: ${({ theme, isCorrect, isSkipped }) => {
    switch (true) {
      case isSkipped:
        return theme.colors.diagnostics.question.skipped
      case isCorrect:
        return theme.colors.diagnostics.question.correct
      default:
        return theme.colors.diagnostics.question.incorrect
    }
  }};
  color: ${({ theme }) => theme.colors.main.white};
  padding: 0.5rem;
  font-weight: bold;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin: 0;
  }
`

const AnswerCorrect = styled.span`
  font-weight: normal;
  color: ${({ theme, isCorrect, isSkipped }) => {
    switch (true) {
      case isSkipped:
        return theme.colors.diagnostics.question.skipped
      case isCorrect:
        return theme.colors.diagnostics.question.correct
      default:
        return theme.colors.diagnostics.question.incorrect
    }
  }};
  margin-left: 2rem;
`

const SkippedAnswers = styled.div`
  margin-left: 1.7rem;
  margin-top: 0.7rem;
  width: 300px;

  .container {
    display: flex;
    align-items: center;
    margin-left: 1.7rem;
  }

  .label {
    font-weight: bold;
  }

  .value {
    color: ${({ theme }) => theme.colors.main.grey600};
  }

  .line {
    width: ${({ distribution }) => distribution}%;
    background-color: ${({ theme }) =>
      theme.colors.diagnostics.question.skipped};
    height: 2px;
    margin-left: 10px;
  }
`

const AnswerOption = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -5rem;
    top: -0.5rem;
    width: 0.5rem;
    height: 1.125rem;
    border-color: ${({ theme, isCorrect, isSelected }) =>
      isSelected && isCorrect
        ? theme.colors.diagnostics.question.correct
        : 'transparent'};
    visibility: ${({ isCorrect, isSelected }) =>
      isSelected && isCorrect ? 'visible' : 'hidden'};
    border-style: solid;
    border-width: 0 3px 3px 0;
    border-radius: 1px;
    transform: rotate(45deg);
    margin: 0 6px;
  }

  &::after {
    content: '✕';
    position: absolute;
    left: -5.1rem;
    top: -0.3rem;
    font-size: 1.5rem;
    color: ${({ theme, isCorrect, isSelected }) =>
      isSelected && !isCorrect
        ? theme.colors.diagnostics.question.incorrect
        : 'transparent'};
    visibility: ${({ isCorrect, isSelected }) =>
      isSelected && !isCorrect ? 'visible' : 'hidden'};
  }
`

const DistributionLine = styled.div`
  display: flex;
  align-items: center;
  width: 300px;

  span {
    margin: 0;
    color: ${({ theme }) => theme.colors.main.grey600};
    text-shadow: none;
    font-family: ${({ theme }) => theme.typography.fontFamilyTertiary};
  }

  .line {
    width: ${({ distribution }) => distribution}%;
    background-color: ${({ theme, isCorrect }) =>
      isCorrect
        ? theme.colors.diagnostics.question.correct
        : theme.colors.diagnostics.question.incorrect};
    height: 2px;
    margin-left: 10px;
  }
`

export default React.memo(QuestionPage)

import React, { useEffect, useState } from 'react'
import { ExamContent, EndExamModal } from 'examkrackers-components'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PageIds from 'modules/exam/utils/pageIds'
import { getAccountType, getStudent } from '../../../auth/ducks/selectors'
import { pathOr, propOr } from 'ramda'
import PATHS from 'utils/paths'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import { DATE_FORMATS, formatDate } from 'utils/date'

export const ConfirmID = (props): JSX.Element => {
  const history = useHistory()
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const [endExamModalOpen, setEndExamModalVisibility] = useState(false)
  const user = useSelector(getStudent)

  const accountType: string = useSelector(getAccountType)
  const examDetails = useSelector(getExamDetails)
  const type_title = pathOr('', ['type', 'title'], examDetails)
  const title = pathOr('', ['exam', 'title'], examDetails)

  const closeEndExamModal = () => setEndExamModalVisibility(false)
  const openEndExamModal = () => setEndExamModalVisibility(true)

  const handleEndExamButtonOnClick = () => openEndExamModal()
  const handleNext = () => setCurrentPage(PageIds.certification)
  const redirectToExamsList = () => history.push(PATHS.exams)

  const confirmEndExam = () => {
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.examEnded, {
      Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
      'Exam Title': title,
      'Exam format': type_title,
      'Account type': accountType
    })
    redirectToExamsList()
  }

  useEffect(() => {}, [])

  useEffect(() => {
    setOnTimeEnd(handleNext)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      endExamButtonOnClick: handleEndExamButtonOnClick
    }))
  }, [])

  return (
    <ExamContent>
      <ConfirmIdContainer>
        <div className='center'>
          <img
            className='examkrackers__logo'
            alt='Examkrackers logo'
            src='/assets/logo/ExamsLogoLightBg.svg'
          />
        </div>
        <p className='center'>
          MCAT
          <sup>® </sup>
          is a program of the Association of American Medical Colleges
        </p>
        <p>
          <strong>
            During the actual MCAT
            <sup>®</sup> exam, you will be asked to review the name and picture.
          </strong>
        </p>
        <ul>
          <li>
            If your name and photograph are correct, select&nbsp;
            <strong>
              <span className='underline'>N</span>
              <span>ext</span>
            </strong>{' '}
            to advance to the Test-Day Certification.
          </li>
          <li>
            If your name and/or photograph are NOT correct, raise your hand to
            contact the test administrator, and select&nbsp;
            <strong>
              <span className='underline'>E</span>
              <span>nd Exam.</span>
            </strong>
          </li>
        </ul>
        <p>
          <strong>
            The test administrator will provide you with instructions.
          </strong>
        </p>
        <div className='user-info-container'>
          {/* TODO: add username and avatar here */}
          <div className='user-info-container__fullname'>
            {propOr('', 'name', user)}
          </div>
          <div className='user-info-container__avatar'>
            <img alt='user avatar' src='/assets/exam/AvatarPlaceholder.png' />
          </div>
        </div>
        <p>
          Select&nbsp;
          <strong>
            <span className='underline'>N</span>
            <span>ext</span>
          </strong>{' '}
          to continue with the test.
        </p>
        <EndExamModal
          initHeight={390}
          open={endExamModalOpen}
          handleClose={closeEndExamModal}
          handleConfirm={confirmEndExam}
        />
      </ConfirmIdContainer>
    </ExamContent>
  )
}

export default ConfirmID

export const ConfirmIdContainer = styled.div`
  padding: 80px 160px;

  ul,
  p {
    margin-bottom: 25.888px;
  }

  .examkrackers__logo {
    margin-top: 55px;
  }

  .user-info-container {
    text-align: center;
    border: 1px solid rgb(210, 210, 210);
    padding: 5px;
  }

  .user-info-container__fullname {
    font-size: 16px;
    padding: 5px 0;
  }

  .user-info-container__avatar {
    margin: 0 auto;

    img {
      width: 200px;
      height: 200px;
    }
  }
`

import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import useOutsideClick from 'hooks/useOutsideClick'
import { ArrowDownIcon, ArrowRightIcon } from 'examkrackers-components'

const MultiLevelSelect = ({ options, onChange }) => {
  const [open, setOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [hoveredOption, setHoveredOption] = useState(null)
  const containerRef = useRef(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useOutsideClick(containerRef, handleClose)

  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption])

  const handleOptionClick = option => () => {
    const isUsedInCalendar = option.present_in_calendar
    if (!isUsedInCalendar && !(option.options && option.options.length > 0)) {
      setSelectedOption(option)
      handleClose()
    }
  }

  return (
    <Wrapper ref={containerRef}>
      <Input onClick={open ? handleClose : handleOpen} open={open}>
        {selectedOption ? selectedOption?.label || '' : 'Choose Task'}
        <ToggleIcon open={open} />
      </Input>
      {open && (
        <Options>
          {options.map(option => {
            return (
              <SingleOption
                key={option.value}
                onMouseEnter={() => setHoveredOption(option)}
                onMouseLeave={() => setHoveredOption(null)}
                onClick={handleOptionClick(option)}
                selected={
                  selectedOption?.label === option.label ||
                  option?.options?.some(
                    el => el.label === selectedOption?.label
                  )
                }
              >
                {option.label}
                {option.options && (
                  <>
                    <RightChevron />
                    {hoveredOption?.label === option.label && (
                      <NestedOptions>
                        {option.options.map(nestedOption => (
                          <SingleNestedOption
                            isUsedInCalendar={nestedOption.present_in_calendar}
                            key={option.value}
                            onClick={handleOptionClick(nestedOption)}
                            selected={
                              selectedOption?.label === nestedOption.label
                            }
                          >
                            {nestedOption.label}
                          </SingleNestedOption>
                        ))}
                      </NestedOptions>
                    )}
                  </>
                )}
              </SingleOption>
            )
          })}
        </Options>
      )}
    </Wrapper>
  )
}

export default MultiLevelSelect

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0;
`

const Input = styled.div<{
  open: boolean
}>`
  width: 100%;
  height: 48px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddfe1;
  cursor: pointer;
  transition: all 0.3s;
  color: #444;
  border-radius: 4px;
  text-align: left;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border-color: #444;
  }

  ${({ open }) =>
    open &&
    css`
      border-color: ${({ theme }) => theme.colors.main.primary500};
      background: ${({ theme }) => theme.colors.main.primary500};
      color: #fff;

      &:hover {
        border-color: ${({ theme }) => theme.colors.main.primary500};
      }
    `}
`

const ToggleIcon = styled(ArrowDownIcon)<{
  open: boolean
}>`
  transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
  font-size: 24px;
`

const Options = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 48px;
  z-index: 10;
  background: ${({ theme }) => theme.colors.main.primary200};
  padding: 5px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const NestedOptions = styled.div`
  width: fit-content;
  min-width: 150px;
  position: absolute;
  left: 100%;
  right: 0;
  top: 0;
  z-index: 10;
  background: ${({ theme }) => theme.colors.main.primary200};
  padding: 5px 0;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
`

const SingleOption = styled.div`
  text-align: left;
  padding: 5px 16px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  position: relative;

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary500};
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.main.primary500};
    `}
`

const SingleNestedOption = styled.div`
  text-align: left;
  padding: 5px 16px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  position: relative;
  color: #444 !important;
  background-color: ${({ theme, isUsedInCalendar }) =>
    isUsedInCalendar && theme.colors.main.grey300};
  cursor: ${({ isUsedInCalendar }) => isUsedInCalendar && 'not-allowed'};

  &:hover {
    color: ${({ theme, isUsedInCalendar }) =>
      !isUsedInCalendar && theme.colors.main.primary500} !important;
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.main.primary500} !important;
    `}
`

const RightChevron = styled(ArrowRightIcon)<{
  open: boolean
}>`
  font-size: 24px;
`

const EmptyNestedOptions = styled.div`
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444;
`

import * as R from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

export const isNotEmptyDeltaObject = deltaObject => {
  const deltaWithRemovedAttributes = R.pipe(
    R.propOr([], 'ops'),
    R.map(el => ({ insert: R.propOr('\n', 'insert', el) })),
    R.objOf('ops')
  )(deltaObject)

  const emptyObj = {
    ops: [
      {
        insert: '\n'
      }
    ]
  }

  return R.not(R.equals(deltaWithRemovedAttributes, emptyObj))
}

export const isValidDeltaObject = deltaObject =>
  isNotEmptyDeltaObject(deltaObject) && isNotNilOrEmpty(deltaObject)

export const isEmptyRawText = raw => isNilOrEmpty(raw) || raw === '\n'

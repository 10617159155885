import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { contentTypes } from './TooltipChart'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'
import { InfoCircleIcon, playSound } from 'examkrackers-components'
// @ts-ignore
import EditCompletionDate from './EditCompletionDate'
import * as R from 'ramda'

import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

import SpeedometerBack from './Speedometer/SpeedometerBack'
import SpeedometerFront from './Speedometer/SpeedometerFront'
import SpeedometerBackLightTheme from './Speedometer/SpeedometerBackLightTheme'
import SpeedometerFrontLightTheme from './Speedometer/SpeedometerFrontLightTheme'
import SpeedometerVelocityLines from './Speedometer/SpeedometerVelocityLines'

const initialValues = {
  speed: 0,
  temperature: 4,
  oil: 6,
  mileage: 2342,
  acceleration: 643,
  velocityPercentage: 60
}

const SETTINGS = {
  speedMaxValue: 500,
  accelerationMaxValue: 50,
  accelerationMinValue: -50,
  temperatureMaxValue: 4, // 0 is default value
  temperatureMinValue: -4,
  oilMaxValue: 0, // 0 is default value
  oilMinValue: -10,
  mileageMaxValue: 10000
}

const Speedometer = ({
  speed,
  temperature,
  oil,
  mileage,
  acceleration,
  velocityPercentage
}) => {
  const [values, setValues] = useState(initialValues)
  const canvasRef = useRef()
  const soundRef = useRef()
  const [yellowBlurDeviation, setYellowBlurDeviation] = useState(0)
  const [orangeBlurDeviation, setOrangeBlurDeviation] = useState(0)

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  useEffect(() => {
    setValues({
      speed,
      temperature,
      oil,
      mileage,
      acceleration,
      velocityPercentage
    })
  }, [speed, temperature, oil, mileage, acceleration, velocityPercentage])

  // remove sound effect EK-2826
  // const handlePlaySound = () => playSound('/assets/sounds/start-engine.mp3')

  // remove sound effect EK-2826
  // const playSoundOnMount = () => {
  //   const soundBtn = soundRef.current
  //   if (soundBtn) {
  //     // @ts-ignore
  //     soundBtn.click()
  //   }
  // }

  // remove sound effect EK-2826
  // useEffect(() => {
  //   setTimeout(playSoundOnMount, 1000)
  // }, [])

  useEffect(() => {
    // this is responsible for animating the "turn the light on" effect
    setTimeout(() => setOrangeBlurDeviation(10), 1000)
    setTimeout(() => setOrangeBlurDeviation(11), 1200)
    setTimeout(() => setOrangeBlurDeviation(12), 1400)
    setTimeout(() => setOrangeBlurDeviation(13), 1600)
    setTimeout(() => setOrangeBlurDeviation(14), 1800)
    setTimeout(() => setOrangeBlurDeviation(15), 2000)
    setTimeout(() => setOrangeBlurDeviation(16), 2200)

    setTimeout(() => setYellowBlurDeviation(0.5), 2000)
    setTimeout(() => setYellowBlurDeviation(1.0), 2200)
    setTimeout(() => setYellowBlurDeviation(1.5), 2400)
    setTimeout(() => setYellowBlurDeviation(2.0), 2500)
    setTimeout(() => setYellowBlurDeviation(2.5), 2600)
  }, [])

  useEffect(() => {
    // lowest rotation value = 185, highest rotation value = 447
    // difference = 262
    const divider = SETTINGS.speedMaxValue / 262
    const value = Math.max(Math.min(SETTINGS.speedMaxValue, speed), 0)
    // given speed / divider - difference between 0 and lowest value
    const countSpeed = value / divider + 185

    setValues(prev => ({ ...prev, speed: countSpeed }))
  }, [speed])

  useEffect(() => {
    // lowest value = 277deg, highest value = 477deg
    // middle value = 377
    const pointsRange = R.range(
      SETTINGS.temperatureMinValue,
      SETTINGS.temperatureMaxValue + 1
    )
    const oneRange = (477 - 277) / pointsRange.length - 1
    const mappedPointsWithPositions = pointsRange.map((point, index) => ({
      point,
      position: (index + 1) * oneRange
    }))

    // @ts-ignore
    const findPositionByPoint: number = R.pipe(
      R.find(R.propEq('point', temperature)),
      R.propOr(0, 'position')
    )(mappedPointsWithPositions)

    setValues(prev => ({ ...prev, temperature: 277 + findPositionByPoint }))
  }, [temperature])

  useEffect(() => {
    // lowest value = 523, highest value = 323
    // middle value = 423 - this is the value of the first min point
    const pointsRange = R.range(SETTINGS.oilMinValue, SETTINGS.oilMaxValue + 1)
    const oneRange = (423 - 523) / pointsRange.length - 1
    const mappedPointsWithPositions = pointsRange.map((point, index) => ({
      point,
      position: (pointsRange.length - (index + 1)) * oneRange
    }))

    // @ts-ignore
    const findPositionByPoint: number = R.pipe(
      R.find(R.propEq('point', oil)),
      R.propOr(0, 'position')
    )(mappedPointsWithPositions)

    setValues(prev => ({ ...prev, oil: 423 - findPositionByPoint }))
  }, [oil])

  useEffect(() => {
    const value = Math.max(Math.min(SETTINGS.mileageMaxValue, mileage), 0)
    setValues(prev => ({ ...prev, mileage: value }))
  }, [mileage])

  const shakeAnimation = currentSpeed => {
    const numberSpeed = Number(currentSpeed)
    return keyframes`
      0% {transform: rotate(${numberSpeed - 0.3}deg);}
      50% {transform: rotate(${numberSpeed + 0.3}deg);}
      100% {transform: rotate(${numberSpeed - 0.3}deg);}
    `
  }

  useEffect(() => {
    if (canvasRef) {
      const isNegative = Number(acceleration) < 0
      // found by trial and error method
      const startAngle = isNegative ? 1.58 : 1.546
      const accelerationValue = Math.max(
        Math.min(SETTINGS.accelerationMaxValue, Number(acceleration)),
        SETTINGS.accelerationMinValue
      )
      setValues(prev => ({ ...prev, acceleration: accelerationValue }))
      const accelerationAbsValue = Math.abs(accelerationValue)

      // found by trial and error method
      const minMaxDifference = isNegative ? 0.52 : 0.555
      const step = minMaxDifference / SETTINGS.accelerationMaxValue

      const progress = accelerationAbsValue * step
      const endAngle = isNegative
        ? startAngle + progress
        : startAngle - progress

      // @ts-ignore
      const canvas: HTMLCanvasElement = document.querySelector('canvas')
      let ctx: CanvasRenderingContext2D | null
      if (!(ctx = canvas.getContext('2d'))) {
        throw new Error(
          `2d context not supported or canvas already initialized`
        )
      }

      const getGradient = () => {
        if (isNegative) {
          // @ts-ignore
          const gradient = ctx.createLinearGradient(135, 100, 280, 100)
          gradient.addColorStop(0, '#ED0823')
          gradient.addColorStop(1, 'rgba(0, 0, 0, 0)')
          return gradient
        } else {
          // @ts-ignore
          const gradient = ctx.createLinearGradient(150, 100, 280, 100)
          gradient.addColorStop(0, 'rgba(0, 0, 0, 0)')
          gradient.addColorStop(1, '#00FB90')
          return gradient
        }
      }

      canvas.width = 412
      canvas.height = 304
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.beginPath()
      ctx.lineWidth = 12
      ctx.strokeStyle = getGradient()
      ctx.arc(205, 134, 101, startAngle, endAngle, !isNegative)
      ctx.stroke()
    }
  }, [acceleration])

  const tooltipEvent = e => ({ e, type: contentTypes.speedometer })
  const handleEnterTooltip = e =>
    eventEmitter.emit(events.chartTooltipEnter, tooltipEvent(e))
  const handleLeaveTooltip = () => eventEmitter.emit(events.chartTooltipLeave)

  return (
    <Wrapper
      speed={values.speed || -175}
      temperature={values.temperature}
      oil={values.oil}
      shakeAnimation={shakeAnimation}
    >
      {/* remove sound effect EK-2826 */}
      {/* <SoundTrigger
        ref={soundRef}
        onClick={handlePlaySound}
        id='start-engine-sound'
      /> */}
      <ChartWrapper>
        <InfoButton
          isDarkTheme={isDarkTheme}
          onMouseEnter={handleEnterTooltip}
          onMouseLeave={handleLeaveTooltip}
        >
          <IconContainer>
            <InfoCircleIcon />
          </IconContainer>
        </InfoButton>
        <EditCompletionDate />
        {isDarkTheme ? (
          <>
            <SpeedometerBack
              orangeBlurDeviation={orangeBlurDeviation}
              yellowBlurDeviation={yellowBlurDeviation}
              acceleration={values.acceleration}
            />
            <SpeedometerFront
              orangeBlurDeviation={orangeBlurDeviation}
              yellowBlurDeviation={yellowBlurDeviation}
              acceleration={values.acceleration}
            />
          </>
        ) : (
          <>
            <SpeedometerBackLightTheme
              orangeBlurDeviation={orangeBlurDeviation}
              yellowBlurDeviation={yellowBlurDeviation}
              acceleration={values.acceleration}
            />
            <SpeedometerFrontLightTheme
              orangeBlurDeviation={orangeBlurDeviation}
              yellowBlurDeviation={yellowBlurDeviation}
              acceleration={values.acceleration}
            />
          </>
        )}
        <SpeedometerVelocityLines
          percent={velocityPercentage}
          orangeBlurDeviation={orangeBlurDeviation}
          yellowBlurDeviation={yellowBlurDeviation}
          acceleration={values.acceleration}
        />
        <Canvas ref={canvasRef} />
        <MainCounter key='mileage-counter' id='mileage-counter'>
          {String(values.mileage).padStart(5, '0')}
        </MainCounter>
        <AccelerationBar>
          <div />
        </AccelerationBar>
        <Acceleration>
          {values.acceleration > 0 ? '+' : ''}
          {values.acceleration}
        </Acceleration>
      </ChartWrapper>
    </Wrapper>
  )
}

Speedometer.defaultPros = {
  speed: 0,
  temperature: 0,
  acceleration: 0,
  mileage: 0,
  oil: 0
}

export default Speedometer

const ChartWrapper = styled.div`
  position: relative;
  width: 412px;
  height: 304px;
  font-family: ${({ theme }) => theme.typography.fontFamilyDigital};

  #speedometer-front,
  #speedometer-back,
  #velocity-lines {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const MainCounter = styled.div`
  position: absolute;
  color: #faa307;
  left: 171px;
  top: 159px;
  font-size: 18px;
`

const Acceleration = styled.div`
  position: absolute;
  color: #faa307;
  left: 50%;
  transform: translateX(-50%);
  top: 210px;
  font-size: 11px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  #main-pointer {
    transition: all 0.3s;
    transform-origin: 208px 133px;
    transform: rotate(185deg);
    animation: speedometer-start 1500ms ease-in-out 1000ms,
      speedometer-gas 8000ms ease-out 2500ms,
      speedometer-shake 0.2s infinite 10500ms;

    // MIN ROTATE: 185deg
    // MAX ROTATE: 447deg
    @keyframes speedometer-start {
      0% {
        transform: rotate(185deg);
      }
      50% {
        transform: rotate(447deg);
      }
      100% {
        transform: rotate(185deg);
      }
    }

    @keyframes speedometer-gas {
      0% {
        transform: rotate(185deg);
      }
      100% {
        transform: ${({ speed }) => `rotate(${speed}deg)`};
      }
    }

    @keyframes speedometer-shake {
      0% {
        transform: ${({ speed }) => `rotate(${speed - 0.5}deg)`};
      }
      50% {
        transform: ${({ speed }) => `rotate(${speed + 0.5}deg)`};
      }
      100% {
        transform: ${({ speed }) => `rotate(${speed - 0.5}deg)`};
      }
    }
  }

  // MIN ROTATE: 277deg
  // MAX ROTATE: 477deg
  #left-pointer {
    transition: all 0.3s;
    transform-origin: 77px 220px;
    transform: rotate(377deg);
    animation: temperature-start 1500ms ease-in-out 1000ms,
      temperature-gas 100ms infinite 2500ms;

    @keyframes temperature-start {
      0% {
        transform: rotate(377deg);
      }
      100% {
        transform: ${({ temperature }) => `rotate(${temperature}deg)`};
      }
    }

    @keyframes temperature-gas {
      0% {
        transform: ${({ temperature }) => `rotate(${temperature}deg)`};
      }
      100% {
        transform: ${({ temperature }) => `rotate(${temperature}deg)`};
      }
    }
  }

  // MIN ROTATE: -39deg
  // MAX ROTATE: 163deg
  #right-pointer {
    transition: all 0.3s;
    transform-origin: 335px 220px;
    transform: rotate(423deg);
    animation: oil-start 1500ms ease-in-out 1000ms,
      oil-gas 100ms infinite 2500ms;

    @keyframes oil-start {
      0% {
        transform: rotate(423deg);
      }
      100% {
        transform: ${({ oil }) => `rotate(${oil}deg)`};
      }
    }

    @keyframes oil-gas {
      0% {
        transform: ${({ oil }) => `rotate(${oil}deg)`};
      }
      100% {
        transform: ${({ oil }) => `rotate(${oil}deg)`};
      }
    }
  }
`

const AccelerationBar = styled.div`
  position: absolute;
  top: 28px;
  right: 97px;
  bottom: 63px;
  left: 98px;
  display: none;

  div {
    border: 12px solid red;
    border-radius: 100%;
    width: 100%;
    height: 100%;
  }
`

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const InfoButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: -8px;
  left: 0px;
  height: 24px;
  padding: 0 10px;
  border: 1px solid
    ${({ theme }) => theme.colors.dashboard.speedometer.infoButtonBorder};
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.dashboard.speedometer.buttonText};
  background: ${({ theme, isDarkTheme }) =>
    !isDarkTheme
      ? theme.colors.main.white
      : theme.colors.dashboard.speedometer.infoButtonBackground};
  z-index: 1;
`

const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.heading};
  display: flex;
  justify-content: center;
  align-items: center;
`

const SoundTrigger = styled.button`
  display: none;
`

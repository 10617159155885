import * as yup from 'yup'
import { validateValues, validateField } from 'utils/form'

// Update access period form validator
export const createAccessSchema = yup.object().shape({
  expiration_date: yup
    .date()
    .required(() => ({ key: 'form.expirationDate.errors.required' }))
})

// Create a validator for the expiration date field.
export const validateEditAccessField = (values: any) =>
  validateField(createAccessSchema, values)

// Create a validator for the expiration date value.
export const validateEditAccessValues = validateValues(createAccessSchema)

import React, { useEffect, useState, memo } from 'react'
import ContentQuestionAnimation from 'modules/books/components/ContentQuestionAnimation'
import { getFileContent } from 'services/FilesService'
import { getRandomContentQuestionAnimation } from 'services/BooksService'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

const emptyAnimationData = {
  correct: {
    animation: null,
    sound: null
  },
  incorrect: {
    animation: null,
    sound: null
  }
}

export const ContentQuestionAnimationHandler = (): JSX.Element => {
  const [animations, setAnimations] = useState(emptyAnimationData)

  const handleSuccess = async response => {
    const correctAnimationUrl = response.data.correct.animation_url
    const incorrectAnimationUrl = response.data.incorrect.animation_url
    const correctSoundUrl = response.data.correct.sound_url
    const incorrectSoundUrl = response.data.incorrect.sound_url
    let correctJSON, incorrectJSON
    await getFileContent(correctAnimationUrl).then(res => {
      correctJSON = res.data
    })
    await getFileContent(incorrectAnimationUrl).then(res => {
      incorrectJSON = res.data
    })
    const newAnimationsState = {
      correct: {
        animation: correctJSON,
        sound: correctSoundUrl
      },
      incorrect: {
        animation: incorrectJSON,
        sound: incorrectSoundUrl
      }
    }
    setAnimations(newAnimationsState)
  }

  const handleError = e => {
    console.error(e)
  }

  const handleFetchAnimation = () => {
    getRandomContentQuestionAnimation().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    handleFetchAnimation()
    eventEmitter.on(events.showCQAnimation, handleFetchAnimation)

    return () => {
      eventEmitter.off(events.showCQAnimation, handleFetchAnimation)
    }
  }, [])

  return <ContentQuestionAnimation animations={animations} />
}

export default memo(ContentQuestionAnimationHandler)

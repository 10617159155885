import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import usePrevious from 'hooks/usePrevious'
import * as R from 'ramda'

import { getLearningTimePassedSeconds } from 'modules/learningTime/ducks/selectors'
import {
  setChapterReadingTimePassedSecondsRoutine,
  handleUpdateChapterReadingTimeRoutine
} from 'modules/learningTime/ducks/actions'
import { getChapterByBookOriginalIdAndOrder } from 'modules/books/ducks/selectors'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/learningTime/utils/events'

import { RootReducer } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  getIsOrdinaryStudent,
  isAuthLoaded
} from 'modules/auth/ducks/selectors'

export const ChapterReadingTime = (): JSX.Element => {
  const dispatch = useDispatch()
  const [shouldCountTime, setShouldCountTime] = useState(true)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const params = useParams()
  const bookId: string = R.propOr('', 'bookId')(params)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)

  const chapter = useSelector((state: RootReducer) =>
    getChapterByBookOriginalIdAndOrder(state, bookId, chapterOrder)
  )

  const chapterId = R.propOr('', 'id', chapter)

  const increaseChapterReadingTimeSeconds = useCallback(
    () => dispatch(setChapterReadingTimePassedSecondsRoutine()),
    [dispatch]
  )

  const handleUpdateChapterReadingTime = useCallback(
    () => dispatch(handleUpdateChapterReadingTimeRoutine({ chapterId })),
    [dispatch, chapterId]
  )

  // @ts-ignore
  const passedGeneralLearningTimeSeconds: number = useSelector(
    getLearningTimePassedSeconds
  )
  const prevGeneralLearningTimeSeconds = usePrevious(
    passedGeneralLearningTimeSeconds
  )

  useEffect(() => {
    if (
      shouldCountTime &&
      prevGeneralLearningTimeSeconds !== passedGeneralLearningTimeSeconds
    ) {
      increaseChapterReadingTimeSeconds()
    }
  }, [passedGeneralLearningTimeSeconds, shouldCountTime])

  const handlePauseTimer = () => setShouldCountTime(false)
  const handleStartTimer = () => setShouldCountTime(true)

  useEffect(() => {
    eventEmitter.on(events.chapterReadingTimePause, handlePauseTimer)
    eventEmitter.on(events.chapterReadingTimeStart, handleStartTimer)

    return () => {
      eventEmitter.off(events.chapterReadingTimePause, handlePauseTimer)
      eventEmitter.off(events.chapterReadingTimeStart, handleStartTimer)
    }
  }, [])

  useEffect(() => {
    if (isNotNilOrEmpty(chapterId) && isOrdinaryStudent && isAuthFetched) {
      window.addEventListener('beforeunload', handleUpdateChapterReadingTime)
    }

    return () => {
      if (isNotNilOrEmpty(chapterId) && isOrdinaryStudent && isAuthFetched) {
        handleUpdateChapterReadingTime()
      }
      window.removeEventListener('beforeunload', handleUpdateChapterReadingTime)
    }
  }, [chapterId, isOrdinaryStudent, isAuthFetched])

  return <div />
}

export default ChapterReadingTime

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import styled from 'styled-components'

import { HeartOutlinedIcon, HeartContainedIcon } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/videos/utils/events'
import { getIsImpersonate, getStudent } from 'modules/auth/ducks/selectors'

import {
  markVideoAsFavorite,
  toggleFavouriteStateOfNonBookVideos,
  unmarkVideoAsFavorite
} from 'services/VideosService'

import { videoStringTags, videoMinutesDuration } from 'utils/videos'
import { Course } from 'types'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const AddToFavorites = ({ video, variant = 'short' }): JSX.Element => {
  const isInFavourites = R.propOr(false, 'is_favourite', video)
  const tags = R.propOr([], 'tags', video)
  const duration = R.propOr([], 'duration', video)

  const resourceId = R.propOr(false, 'resource_id', video)
  const { t } = useTranslation()

  const [isSelected, setIsSelected] = useState(isInFavourites)

  const isImpersonate = useSelector(getIsImpersonate)
  const isNotImpersonate = !isImpersonate

  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)

  const handleIsFavouriteToggle = e => {
    if (!isSelected) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.addVideoToFavourites, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Video title': video?.title || false,
        'Video category': video?.category || false,
        'Book tag': videoStringTags(tags),
        'Video lenght': videoMinutesDuration(duration)
      })
    }

    e.preventDefault()
    e.stopPropagation()
    setIsSelected(prevState => {
      const handleSuccess = () => {
        eventEmitter.emit(events.favoriteToggle, !prevState)
      }

      const handleError = e => console.error(e)

      if (video.category !== 'books') {
        return toggleFavouriteStateOfNonBookVideos({ id: video.id })
          .then(handleSuccess)
          .catch(handleError)
      }

      prevState
        ? unmarkVideoAsFavorite({ id: resourceId })
            .then(handleSuccess)
            .catch(handleError)
        : markVideoAsFavorite({ id: resourceId })
            .then(handleSuccess)
            .catch(handleError)

      return !prevState
    })
  }

  const labelText = isSelected
    ? t('videos.favorite')
    : t('videos.addToFavorite')

  const updateStarFavouriteState = videoIcon => {
    switch (variant) {
      case 'short':
        return (
          <ShortButtonContainer
            onClick={isNotImpersonate && handleIsFavouriteToggle}
            isNotImpersonate={isNotImpersonate}
          >
            {VideoIcon}
          </ShortButtonContainer>
        )
      default:
        return (
          <ButtonContainer
            onClick={isNotImpersonate && handleIsFavouriteToggle}
            isNotImpersonate={isNotImpersonate}
          >
            {VideoIcon} {labelText}
          </ButtonContainer>
        )
    }
  }

  const VideoIcon = (
    <IconContainer isSelected={isSelected}>
      <StyledOutlinedHeart />
      <StyledHoveredHeart />
      <StyledContainedHeart />
    </IconContainer>
  )
  useEffect(() => {
    setIsSelected(isInFavourites)
  }, [video])

  return <div>{updateStarFavouriteState(VideoIcon)}</div>
}

export default AddToFavorites

const StyledOutlinedHeart = styled(HeartOutlinedIcon)`
  color: ${({ theme }) => theme.colors.main.grey300};
  font-size: 20px;
`

const StyledHoveredHeart = styled(HeartContainedIcon)`
  color: ${({ theme }) => theme.colors.main.grey600};
  font-size: 20px;
`

const StyledContainedHeart = styled(HeartContainedIcon)`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 20px;
`

const IconContainer = styled.div`
  ${StyledOutlinedHeart} {
    display: ${({ isSelected }) => (isSelected ? 'none' : 'block')};
  }

  ${StyledHoveredHeart} {
    display: none;
  }

  ${StyledContainedHeart} {
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
  }

  &:hover {
    ${StyledOutlinedHeart} {
      display: none !important;
    }

    ${StyledHoveredHeart} {
      display: block !important;
    }

    ${StyledContainedHeart} {
      display: none !important;
    }
  }
`

const ShortButtonContainer = styled.div`
  cursor: ${({ isNotImpersonate }) =>
    isNotImpersonate ? 'pointer' : 'not-allowed'};
`

const ButtonContainer = styled.div`
  cursor: ${({ isNotImpersonate }) =>
    isNotImpersonate ? 'pointer' : 'not-allowed'};
  display: flex;
  gap: 10px;
  color: ${({ theme }) => theme.colors.main.grey600};
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  white-space: nowrap;
`

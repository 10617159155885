import * as R from 'ramda'

export const secondsToTime = (secs: number) => {
  const hours: number = Math.floor(secs / (60 * 60))

  const divisiorForMinutes: number = secs % (60 * 60)
  const minutes = Math.floor(divisiorForMinutes / 60)

  const divisiorForSeconds: number = divisiorForMinutes % 60
  const seconds: number = Math.ceil(divisiorForSeconds)

  return {
    h: hours < 10 ? `0${hours}` : hours,
    m: minutes < 10 ? `0${minutes}` : minutes,
    s: seconds < 10 ? `0${seconds}` : seconds
  }
}

export const getStartSeconds = timeConfigs => pageId =>
  R.pipe(
    // @ts-ignore
    R.find(R.pipe(R.propOr([], 'pageIds'), arr => R.includes(pageId, arr))),
    R.propOr(0, 'startSeconds')
    // @ts-ignore
  )(timeConfigs)

export const daysOfWeek = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday'
}

export const formatTime = timeStr => {
  const [hours, minutes] = timeStr.split(':').map(Number)
  const period = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours === 0 || hours === 12 ? 12 : hours % 12
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  return `${formattedHours}:${formattedMinutes} ${period}`
}

export const formatMeetingTime = (classTime, classTimeEnd, weekday) => {
  const formattedStart = formatTime(classTime)
  const formattedEnd = formatTime(classTimeEnd)

  const startTime = classTime.includes(':00')
    ? formattedStart.replace(':00', '')
    : formattedStart
  const endTime = classTimeEnd.includes(':00')
    ? formattedEnd.replace(':00', '')
    : formattedEnd

  const formattedWeekday = daysOfWeek[weekday]

  return `${formattedWeekday} ${startTime} - ${endTime} EST`
}

import PageIds from 'modules/exam/utils/pageIds'
import * as IntroPages from 'modules/exam/components/IntroPages'
import * as TutorialPages from 'modules/exam/components/TutorialPages'
import BlankPage from 'modules/exam/components/QuestionPages/BlankPage'
import FinishExamPage from 'modules/exam/components/ExamElements/FinishExam'
import GoogleFormSurvey from 'modules/exam/components/ExamElements/GoogleFormSurvey'

import { ExamPageConfigProps } from 'types'

export const examWizard: ExamPageConfigProps[] = [
  {
    name: 'Start',
    id: PageIds.start,
    nextPageId: PageIds.confirmId,
    prevPageId: null,
    timerVisibility: false,
    pagesVisibility: false,
    totalPages: 1,
    currentPage: 1,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: false,
      nextButton: true,
      previousButton: false,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: IntroPages.DifferenceBetweenOfficialAndPracticeMCAT
  },
  {
    name: 'Confirm ID',
    id: PageIds.confirmId,
    nextPageId: PageIds.certification,
    prevPageId: null,
    timerVisibility: true,
    pagesVisibility: false,
    totalPages: 1,
    currentPage: 1,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: true,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: false,
      nextButton: true,
      previousButton: false,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: IntroPages.ConfirmID
  },
  {
    name: 'Certification',
    id: PageIds.certification,
    nextPageId: PageIds.tutorialStart,
    prevPageId: null,
    timerVisibility: true,
    pagesVisibility: false,
    totalPages: 1,
    currentPage: 1,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: false,
      nextButton: true,
      previousButton: false,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: IntroPages.Certification
  },
  {
    name: 'Certification Decline',
    id: PageIds.certificationDecline,
    nextPageId: null,
    prevPageId: null,
    timerVisibility: true,
    pagesVisibility: false,
    totalPages: 1,
    currentPage: 1,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: true,
      navigationButton: false,
      nextButton: false,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: IntroPages.CertificationDecline
  },
  {
    name: 'Start Tutorial',
    id: PageIds.tutorialStart,
    nextPageId: PageIds.tutorialNavigation,
    prevPageId: null,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 1,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: false,
      nextButton: true,
      previousButton: false,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialStart
  },
  {
    name: 'Tutorial Navigation',
    id: PageIds.tutorialNavigation,
    nextPageId: PageIds.tutorialAnswer,
    prevPageId: PageIds.tutorialStart,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 2,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: true,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialNavigation
  },
  {
    name: 'Tutorial Answer',
    id: PageIds.tutorialAnswer,
    nextPageId: PageIds.tutorialScroll,
    prevPageId: PageIds.tutorialNavigation,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 3,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: true,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialAnswer
  },
  {
    name: 'Tutorial Scroll',
    id: PageIds.tutorialScroll,
    nextPageId: PageIds.tutorialHighlight,
    prevPageId: PageIds.tutorialAnswer,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 4,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: true,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialScroll
  },
  {
    name: 'Tutorial Highlight',
    id: PageIds.tutorialHighlight,
    nextPageId: PageIds.tutorialStrikethrough,
    prevPageId: PageIds.tutorialScroll,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 5,
    highlightButtonVisibility: true,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: true,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialHighlight
  },
  {
    name: 'Tutorial Strikethrough',
    id: PageIds.tutorialStrikethrough,
    nextPageId: PageIds.tutorialPeriodicTable,
    prevPageId: PageIds.tutorialHighlight,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 6,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: true,
    flagForReviewButtonVisibility: true,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialStrikethrough
  },
  {
    name: 'Tutorial Periodic Table',
    id: PageIds.tutorialPeriodicTable,
    nextPageId: PageIds.tutorialFlagForReview,
    prevPageId: PageIds.tutorialStrikethrough,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 7,
    highlightButtonVisibility: true,
    strikethroughButtonVisibility: true,
    flagForReviewButtonVisibility: true,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: true,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialPeriodicTable
  },
  {
    name: 'Tutorial Flag for review',
    id: PageIds.tutorialFlagForReview,
    nextPageId: PageIds.tutorialSectionReview,
    prevPageId: PageIds.tutorialPeriodicTable,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 8,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: true,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialFlagForReview
  },
  {
    name: 'Tutorial Section review',
    id: PageIds.tutorialSectionReview,
    nextPageId: PageIds.tutorialEnd,
    prevPageId: PageIds.tutorialFlagForReview,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 9,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: true,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialSectionReview
  },
  {
    name: 'End Tutorial',
    id: PageIds.tutorialEnd,
    nextPageId: null,
    prevPageId: PageIds.tutorialSectionReview,
    timerVisibility: true,
    pagesVisibility: true,
    totalPages: 10,
    currentPage: 10,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: false,
      nextButton: true,
      previousButton: true,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: TutorialPages.TutorialEnd
  },
  {
    id: PageIds.blankPage,
    nextPageId: null,
    prevPageId: null,
    timerVisibility: false,
    pagesVisibility: false,
    totalPages: 10,
    currentPage: 10,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: true,
      nextButton: true,
      previousButton: true,
      periodicTableButton: true,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: BlankPage
  },
  {
    name: 'End Exam',
    id: PageIds.endExam,
    nextPageId: null,
    prevPageId: null,
    timerVisibility: true,
    pagesVisibility: false,
    totalPages: 1,
    currentPage: 1,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: false,
      nextButton: true,
      previousButton: false,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: FinishExamPage
  },
  {
    name: 'Google Form',
    id: PageIds.googleForm,
    nextPageId: null,
    prevPageId: null,
    timerVisibility: true,
    pagesVisibility: false,
    totalPages: 1,
    currentPage: 1,
    highlightButtonVisibility: false,
    strikethroughButtonVisibility: false,
    flagForReviewButtonVisibility: false,
    navigationButtons: {
      endExamButton: false,
      endSectionButton: false,
      endTestDayCertification: false,
      navigationButton: false,
      nextButton: false,
      previousButton: false,
      periodicTableButton: false,
      reviewAllButton: false,
      reviewFlaggedButton: false,
      reviewIncompleteButton: false,
      reviewScreenButton: false,
      answerSheetButton: false
    },
    component: GoogleFormSurvey
  }
]

import PageIds from 'modules/exam/utils/pageIds'

export const timeConfigs = [
  {
    pageIds: [PageIds.start],
    startSeconds: 0
  },
  {
    pageIds: [PageIds.confirmId],
    startSeconds: 60
  },
  {
    pageIds: [PageIds.certification],
    startSeconds: 240
  },
  {
    pageIds: [
      PageIds.tutorialStart,
      PageIds.tutorialNavigation,
      PageIds.tutorialAnswer,
      PageIds.tutorialScroll,
      PageIds.tutorialHighlight,
      PageIds.tutorialStrikethrough,
      PageIds.tutorialPeriodicTable,
      PageIds.tutorialFlagForReview,
      PageIds.tutorialSectionReview,
      PageIds.tutorialEnd
    ],
    startSeconds: 540
  },
  {
    pageIds: [PageIds.endExam],
    startSeconds: 180
  }
]

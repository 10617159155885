import { getIncludedScoresData, getTargetScoreData } from 'utils/diagnostics'
import * as R from 'ramda'
import { theme } from 'examkrackers-components'

const ellipsisLabelIfNeeded = label =>
  typeof label === 'string' && label.length > 7
    ? R.take(5, label) + '...'
    : label

export const projectedScoreGraphOptions = data => {
  const categoriesData = data.map(point => point.title)
  const categoriesColors = data.map((point, index) =>
    index === data.length - 1 ? 'transparent' : theme.colors.main.text
  )

  const TargetAnnotationPoint = R.pipe(
    // @ts-ignore
    R.takeLast(1),
    R.head,
    R.applySpec({
      x: R.prop('title'),
      y: R.prop('target_score'),
      label: R.always({
        borderColor: 'transparent',
        text: 'Target',
        offsetX: 30,
        offsetY: 15,
        style: {
          background: 'transparent',
          color: theme.colors.diagnostics.projections.target,
          fontWeight: 'strong',
          fontSize: 14
        }
      }),
      marker: R.always({
        size: 0,
        fillColor: 'transparent',
        strokeColor: 'transparent',
        strokeWidth: 0,
        shape: 'circle',
        radius: 0,
        OffsetX: 0,
        OffsetY: 0,
        cssClass: ''
      })
    })
    // @ts-ignore
  )(data)

  const ScoreAnnotationPoint = R.pipe(
    // @ts-ignore
    R.takeLast(1),
    R.head,
    R.applySpec({
      x: R.prop('title'),
      y: R.prop('pts'),
      label: R.always({
        borderColor: 'transparent',
        text: 'You',
        offsetX: 25,
        offsetY: 15,
        style: {
          background: 'transparent',
          color: theme.colors.diagnostics.projections.scaled,
          fontWeight: 'strong',
          fontSize: 14
        }
      }),
      marker: R.always({
        size: 0,
        fillColor: 'transparent',
        strokeColor: 'transparent',
        strokeWidth: 0,
        shape: 'circle',
        radius: 0,
        OffsetX: 0,
        OffsetY: 0,
        cssClass: ''
      })
    })
    // @ts-ignore
  )(data)

  const scores = data.map(R.prop('scaled_score'))
  const dataLabelsColors = data.map(score => opts => {
    switch (true) {
      case score.is_excluded_from_pts:
        return 'transparent'
      default:
        return theme.colors.main.grey200
    }
  })

  const scoreMarkers = data.map((score, index) =>
    score.is_excluded_from_pts
      ? {
          seriesIndex: 0,
          dataPointIndex: index,
          fillColor: 'transparent',
          strokeColor: 'transparent',
          size: 0,
          shape: 'circle'
        }
      : {
          seriesIndex: 0,
          dataPointIndex: index,
          fillColor: theme.colors.diagnostics.projections.scaled,
          strokeColor: theme.colors.diagnostics.projections.scaled,
          size: 2,
          strokeWidth: 0,
          shape: 'circle'
        }
  )

  const targetScoreMarkers = data.map((score, index) =>
    index === data.length - 1
      ? {
          seriesIndex: 1,
          dataPointIndex: index,
          fillColor: theme.colors.diagnostics.projections.target,
          strokeColor: theme.colors.diagnostics.projections.target,
          size: 2,
          strokeWidth: 0,
          shape: 'circle'
        }
      : {
          seriesIndex: 1,
          dataPointIndex: index,
          fillColor: 'transparent',
          strokeColor: 'transparent',
          size: 0,
          strokeWidth: 0,
          shape: 'circle'
        }
  )

  const markers = [...scoreMarkers, ...targetScoreMarkers]

  const dataLabelsFormatter = (value, props) => {
    const { dataPointIndex, seriesIndex } = props

    const isHidden = R.pipe(
      // @ts-ignore
      R.find(
        m =>
          m.dataPointIndex === dataPointIndex && m.seriesIndex === seriesIndex
      ),
      R.ifElse(R.isNil, R.always(true), R.propEq('fillColor', 'transparent'))
      // @ts-ignore
    )(markers)

    return isHidden ? '' : value
  }

  const getMinYValue = () => {
    const minValue = Math.min(...scores)
    return minValue - 50
  }

  const getMaxYValue = () => {
    const maxValue = Math.max(...scores)
    return maxValue + 50
  }

  const forecastCount = R.pipe(
    R.filter(R.propEq('completed', false)),
    // @ts-ignore
    R.length
    // @ts-ignore
  )(data)

  return {
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      },
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    annotations: {
      points: [TargetAnnotationPoint, ScoreAnnotationPoint]
    },
    forecastDataPoints: {
      count: forecastCount
    },
    colors: [
      theme.colors.diagnostics.projections.scaled,
      theme.colors.diagnostics.projections.target
    ],
    stroke: {
      width: [2, 2]
    },
    dataLabels: {
      formatter: dataLabelsFormatter,
      enabled: true,
      offsetY: 10,
      offsetX: 0,
      distributed: true,
      style: {
        fontSize: '1em',
        fontFamily: 'Arial, sans-serif',
        fontWeight: 'normal',
        colors: dataLabelsColors
      },
      background: {
        enabled: true,
        foreColor: theme.colors.main.text
      }
    },
    chart: {
      id: 'score-projection-chart',
      type: 'line',
      animations: {
        enabled: true
      },
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    markers: {
      size: 5,
      discrete: markers
    },
    xaxis: {
      categories: categoriesData,
      labels: {
        offsetY: 2,
        formatter: ellipsisLabelIfNeeded,
        style: {
          fontSize: '1em',
          fontWeight: 'bold',
          colors: categoriesColors
        }
      }
    },
    yaxis: [
      {
        min: getMinYValue(),
        max: getMaxYValue(),
        seriesName: 'Score',
        show: false,
        title: {
          text: 'Score'
        }
      },
      {
        min: getMinYValue(),
        max: getMaxYValue(),
        seriesName: 'Target Score',
        show: false,
        title: {
          text: 'Target Score'
        }
      }
    ],
    tooltip: {
      enabled: false
    },
    legend: {
      show: false
    }
  }
}

export const projectedScoreGraphSeries = data => {
  const scoresWithProjections = getIncludedScoresData(data)
  const targetScore = getTargetScoreData(data)

  return [
    {
      data: scoresWithProjections,
      name: 'Score',
      type: 'line'
    },
    {
      data: targetScore,
      name: 'Target Score',
      type: 'line'
    }
  ]
}

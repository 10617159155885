import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import * as R from 'ramda'

import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import { authStudent } from 'services/AuthService'

import { Heading1 } from 'examkrackers-components'

export const PreviewBook = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const { push } = useHistory()

  const token = R.propOr('', 'token', params)
  const bookId = R.propOr('', 'bookId', params)
  const partOrder = R.propOr('', 'partOrder', params)
  const chapterOrder = R.propOr('', 'chapterOrder', params)

  const handleRedirect = () => {
    push(`/books/${bookId}/chapter/${chapterOrder}/part/${partOrder}`)
  }

  const handleAuth = async () => {
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.token)
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)

    try {
      const result = await authStudent({ token })
      LocalStorageService.set(LOCAL_STORAGE_KEYS.token, result.data.token)
      handleRedirect()
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    handleAuth()
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.preview')}</title>
      </Helmet>
      <AuthContainer>
        <div className='title'>
          <Heading1>{t('preview.title')}...</Heading1>
        </div>
      </AuthContainer>
    </React.Fragment>
  )
}

const AuthContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

export default PreviewBook

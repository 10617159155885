import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { RouteProps } from 'types'

import LayoutPublic from 'components/LayoutPublic'
import {
  ThemeProvider,
  GlobalStyles,
  setLightTheme
} from 'examkrackers-components'

export const RoutePublic = (props: RouteProps) => {
  const { component, path, location, ...rest } = props

  useEffect(() => {
    setLightTheme()
  }, [path])

  const Component = component

  const WrappedComponent = props => (
    <ThemeProvider>
      <>
        <GlobalStyles />
        <LayoutPublic>
          <Component {...props} />
        </LayoutPublic>
      </>
    </ThemeProvider>
  )

  return <Route {...rest} component={WrappedComponent} />
}

export default RoutePublic

export const ellipsis = (string, length) => {
  return string.length > length ? string.substr(0, length) + '\u2026' : string
}

export const highlightText = (string, value) => {
  const regex = new RegExp(value, 'gi')
  return string.replace(
    regex,
    match => `<mark class="highlight">${match}</mark>`
  )
}

export const shortenTitle = (title: string): string => {
  let parts = title.split('_')
  let shortenedTitle = parts[0].slice(0, 3)
  if (parts.length > 1) {
    shortenedTitle += ' ' + parts[1]
  }
  return shortenedTitle
}

export const removeFLFromTitle = (title: string) => {
  return title.replace(/FL /g, '')
}

import styled from 'styled-components'
import { Course } from 'types'

export interface DateInputProps {
  name: string
  id?: string
  label?: string
  disabled?: boolean
  allowPast?: boolean
  value: Date | string
  isClearable?: boolean
  withExtendModal?: boolean
  required?: boolean
  validate?: (name: string, v: any) => any
  reset?: boolean
  onChange: (name: string, value: any) => void
  size?: string
  minDate?: Date | string
  maxDate?: Date | string
  course?: Course
  popperPlacement?: string
}

export interface DateInputControllerProps {
  name: string
  allowPast?: boolean
  value: Date
  validate?: (name: string, v: any) => any
  reset?: boolean
  onChange: (name: string, value: any) => void
  minDate?: Date
  maxDate?: Date
}

export const DatePickerWrapper = styled.div`
  width: 100%;
  align-items: center;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.inputs.disabled.background
      : theme.colors.inputs.input.background};
  box-sizing: border-box;
  color: ${({ theme, error, disabled }) => {
    switch (true) {
      case disabled:
        return theme.colors.inputs.disabled.font
      case error:
        return theme.colors.main.error500
      default:
        return theme.colors.inputs.input.font
    }
  }};
  display: inline-flex;
  border-color: ${({ error, disabled, theme }) => {
    switch (true) {
      case error:
        return theme.colors.main.error500
      case disabled:
        return theme.colors.inputs.disabled.border
      default:
        return theme.colors.inputs.input.border
    }
  }};
  border-radius: ${({ theme }) => theme.shape.borderRadiusNormal};
  border-style: solid;
  border-width: 1px;

  font-family: ${({ theme }) => theme.typography.fontFamily};
  height: ${({ theme, size }) =>
    size === 'normal'
      ? theme.dimensions.inputHeight
      : theme.dimensions.inputSmallHeight};
  margin: 25px 0 15px;
  padding: 0 10px;
  position: relative;
  transition: all 200ms ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;

  &:hover {
    border-color: ${({ error, disabled, theme }) => {
      switch (true) {
        case error:
          return theme.colors.main.error500
        case disabled:
          return theme.colors.inputs.disabled.borderActive
        default:
          return theme.colors.inputs.input.borderActive
      }
    }};
  }

  &:focus-within {
    border-width: 1px;
    border-color: ${({ theme, error, disabled }) => {
      switch (true) {
        case disabled:
          return theme.colors.inputs.disabled.borderActive
        case error:
          return theme.colors.main.error500
        default:
          return theme.colors.inputs.input.borderActive
      }
    }};
    color: ${({ theme, error, disabled }) => {
      switch (true) {
        case disabled:
          return theme.colors.inputs.disabled.fontActive
        case error:
          return theme.colors.main.error500
        default:
          return theme.colors.inputs.input.fontActive
      }
    }};
  }

  input {
    height: 100%;
    position: relative;
    font-size: ${({ size, theme }) => {
      switch (true) {
        case size === 'small':
          return theme.typography.inputSmall
        default:
          return theme.typography.fontSizeNormal
      }
    }};
    background-color: transparent;
    border: none;
    color: ${({ theme, error, disabled }) => {
      switch (true) {
        case disabled:
          return theme.colors.inputs.disabled.font
        case error:
          return theme.colors.main.error500
        default:
          return theme.colors.inputs.input.font
      }
    }};
    padding: 0;
    margin: 0;
    outline: none;
    cursor: text;
    width: 100%;

    font-size: 14px !important;

    &:-webkit-autofill {
      color: ${({ theme }) => theme.colors.inputs.input.font} !important;
      background-color: unset;
      box-shadow: 0 0 0px 1000px
        ${({ theme }) => theme.colors.inputs.input.background} inset;
      height: 100%;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.inputs.disabled.font} !important;
      cursor: not-allowed;
    }

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) =>
        theme.colors.inputs.input.fontPlaceholder} !important;
      font-size: 11px;
      line-height: 16px;
    }
  }
`

export const DatePickerContainer = styled.div`
  display: inline-block;
  position: relative;

  label {
    margin: 0;
    top: -21px;
    background: #fff;
  }

  .react-datepicker-popper {
    z-index: 3000;
  }

  .react-datepicker-wrapper {
    height: 100%;
  }

  .react-datepicker-popper {
    padding: 0;
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    border-color: ${({ theme }) => theme.colors.datePicker.border};
    background: ${({ theme }) => theme.colors.datePicker.background};
    border-radius: 8px;
    top: 100%;
  }

  .react-datepicker {
    color: ${({ theme }) => theme.colors.datePicker.font};

    .react-datepicker__day.react-datepicker__day--disabled {
      color: ${({ theme }) => theme.colors.datePicker.font};
      opacity: 0.3;

      &:hover {
        color: inherit !important;
        background: inherit !important;
      }
    }

    .react-datepicker__day.react-datepicker__day--weekend:hover,
    .react-datepicker__day.react-datepicker__day--disabled.react-datepicker__day--weekend:hover {
      color: ${({ theme }) => theme.colors.datePicker.weekendFont} !important;
    }

    .react-datepicker__day {
      outline: none !important;
      color: ${({ theme }) => theme.colors.datePicker.fontActive};
      border: 1px solid transparent;

      &:hover {
        border-color: ${({ theme }) =>
          theme.colors.datePicker.weekendFont} !important;
        background: transparent;
      }
    }

    .react-datepicker__day.react-datepicker__day--outside-month {
      color: ${({ theme }) => theme.colors.datePicker.font};
    }

    .react-datepicker__day--today {
      color: ${({ theme }) => theme.colors.datePicker.todayFont};
    }

    .react-datepicker__day.react-datepicker__day--outside-month.react-datepicker__day--weekend,
    .react-datepicker__day.react-datepicker__day--disabled.react-datepicker__day--weekend,
    .react-datepicker__day--weekend {
      color: ${({ theme }) => theme.colors.datePicker.weekendFont};
    }

    .react-datepicker__day.react-datepicker__day--outside-month.react-datepicker__day--weekend,
    .react-datepicker__day.react-datepicker__day--weekend {
      &:hover {
        border-color: ${({ theme }) =>
          theme.colors.datePicker.weekendFont} !important;
        background: transparent;
      }
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected,
    .react-datepicker__day.react-datepicker__day--selected {
      background: ${({ theme }) => theme.colors.datePicker.selectedBackground};
      color: ${({ theme }) => theme.colors.datePicker.fontSelected} !important;

      &:hover {
        color: ${({ theme }) =>
          theme.colors.datePicker.fontSelected} !important;
        background: ${({ theme }) =>
          theme.colors.datePicker.selectedBackgroundHover};
      }
    }
  }

  .react-datepicker__header {
    background: ${({ theme }) => theme.colors.datePicker.background};
    border: none;
    padding: 0;
    border-radius: 8px;

    .react-datepicker__current-month {
      margin-bottom: 4px;
      background: ${({ theme }) => theme.colors.main.primary200};
    }
  }

  .react-datepicker__day-names {
    margin-bottom: 2px;
  }

  .react-datepicker__month {
    margin: 0;
    padding: 0 8px;
  }

  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    width: 4px;
    height: 4px;
    top: 0;
    border-color: ${({ theme }) => theme.colors.main.primary800};
  }
`

export const DatePickerLabel = styled.label`
  margin: 0;
  box-sizing: border-box;
  color: ${({ theme, error, isDisabled }) => {
    switch (true) {
      case isDisabled:
        return theme.colors.inputs.disabled.font
      case error:
        return theme.colors.main.error500
      default:
        return theme.colors.inputs.input.font
    }
  }};
  position: absolute;
  font-size: ${({ hasValue, size }) => {
    switch (true) {
      case hasValue:
        return '12px'
      case size === 'small':
      default:
        return '14px'
    }
  }};
  line-height: ${({ hasValue }) => (hasValue ? '12px' : '14px')};
  left: ${({ hasValue }) => {
    switch (true) {
      case hasValue:
        return '-6px'
      default:
        return '14px'
    }
  }};
  top: ${({ hasValue, size }) => {
    switch (true) {
      case hasValue:
        return '-19px'
      case size === 'small':
        return '8px'
      default:
        return '14px'
    }
  }};
  z-index: 1;
  padding: ${({ hasValue }) => (hasValue ? '0 5px' : '0')};
  background-color: transparent;
  transition: all 200ms ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;
`

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  validateChangeTargetScoreField,
  validateChangeTargetScoreValues
} from 'modules/examTypes/ducks/schema'

import {
  changeTotalTargetScoreRoutine,
  changeSectionTargetScoreRoutine
} from 'modules/examTypes/ducks/actions'
import { getCurrentExamTypeId } from 'modules/examTypes/ducks/selectors'

import { InputField, Button } from 'examkrackers-components'
import { isNotNilOrEmpty } from 'utils/ramda'

interface FormChangeTargetScoreProps {
  sectionOrder?: number
  onSubmitSuccess: () => any
  initialValue: number
}

const emptyValues = {
  value: 0
}

export const FormChangeTargetScore = (
  props: FormChangeTargetScoreProps
): JSX.Element => {
  const { onSubmitSuccess, initialValue, sectionOrder } = props
  const dispatch = useDispatch()
  const examTypeId = useSelector(getCurrentExamTypeId)

  const { t } = useTranslation()
  const [valid, _validateSchema] = React.useState(false)
  const [values, setValues] = React.useState(emptyValues)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    setValues({
      value: initialValue
    })
  }, [])

  React.useEffect(() => {
    validateChangeTargetScoreValues(values, _validateSchema)
  }, [values])

  const changeTotalTargetScore = React.useCallback(
    payload => dispatch(changeTotalTargetScoreRoutine(payload)),
    [dispatch]
  )

  const changeSectionTargetScore = React.useCallback(
    payload => dispatch(changeSectionTargetScoreRoutine(payload)),
    [dispatch]
  )

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)

    const onSuccess = () => {
      onSubmitSuccess()
      setIsLoading(false)
    }

    const onFailure = () => {
      setIsLoading(false)
    }

    if (isNotNilOrEmpty(sectionOrder)) {
      changeSectionTargetScore({
        values: {
          id: examTypeId,
          order: sectionOrder,
          value: values.value
        },
        callback: onSuccess,
        onFailure
      })
    } else {
      changeTotalTargetScore({
        values: {
          id: examTypeId,
          value: values.value
        },
        callback: onSuccess,
        onFailure
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        t={t}
        name='value'
        value={values.value}
        id='change-target-score-value'
        label={t('form.targetScore.label')}
        onChange={handleOnChange}
        validate={validateChangeTargetScoreField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='save-default-access-period-submit'
          disabled={isLoading || !valid}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {t('diagnostics.changeTargetScore.CTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default FormChangeTargetScore

const ButtonContainer = styled.div`
  margin-top: 20px;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
`

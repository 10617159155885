import React, { useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { propOr } from 'ramda'

import {
  SupportTabIcon,
  CloseIcon,
  IconButton,
  Heading2,
  Button,
  // VideoTutorialsIcon,
  AddErrataIcon,
  OnboardingIcon
} from 'examkrackers-components'
import { getAllSupportTabLinks } from 'services/SupportTabService'
import useOutsideClick from 'hooks/useOutsideClick'
import PATHS from 'utils/paths'
import { getStudent } from 'modules/auth/ducks/selectors'

const SupportTab = ({ setErratasOpen }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [allLinks, setAllLinks] = useState<any>('')
  const isEmail = (str: string): boolean => {
    const emailRegex = /\S+@\S+\.\S+/
    return emailRegex.test(str)
  }
  const isContactUsEmail = isEmail(allLinks.contact_us_link)
  const rightHrefForContactUsLink = isContactUsEmail
    ? `mailto:${allLinks.contact_us_link}`
    : allLinks.contact_us_link
  const isHelpCenterEmail = isEmail(allLinks.help_center_link)
  const rightHrefForHelpCenterEmailLink = isHelpCenterEmail
    ? `mailto:${allLinks.help_center_link}`
    : allLinks.help_center_link
  const { path } = useRouteMatch()
  const isDashboard = path === PATHS.dashboard
  const studentDetails = useSelector(getStudent)
  const dashboardTheme = propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  useEffect(() => {
    const fetchLinksFromSingleEP = async () => {
      const allLinksResponse = await getAllSupportTabLinks()
      setAllLinks(allLinksResponse.data)
    }

    fetchLinksFromSingleEP()
  }, [])

  const handleOpen = () => {
    setOpen(open => !open)
    setErratasOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenErratas = () => {
    setErratasOpen(true)
    setOpen(false)
    handleClose()
  }
  const ExternalLinkButton = ({ link, icon, description }) => {
    return (
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
        className='support-tab-btn'
      >
        {icon}
        {description}
      </a>
    )
  }
  const containerRef = useRef(null)
  useOutsideClick(containerRef, handleClose)

  return (
    <SupportTabWrapper>
      {open && (
        <SupportTabContents
          ref={containerRef}
          isDashboard={isDashboard}
          isDarkTheme={isDarkTheme}
        >
          <Header>
            <Heading2 bold>{t('supportTab.title')}</Heading2>
            <IconButton
              icon={<CloseIcon />}
              color='black'
              size='medium'
              onClick={handleOpen}
            />
          </Header>
          {/* <GettingStartedWrapper>
            <p>{t('supportTab.description')}</p>
            <ExternalLinkButton
              link={allLinks.getting_started_link}
              icon={<RocketIcon />}
              description={t('supportTab.gettingStarted')}
            />
          </GettingStartedWrapper> */}
          <OtherItemsWrapper>
            {/* <Heading2 bold>{t('supportTab.other')}</Heading2> */}
            {/* <ExternalLinkButton
              link={allLinks.training_tutorials_link}
              icon={<VideoTutorialsIcon />}
              description={t('supportTab.videoTutorials')}
            /> */}
            <button className='support-tab-btn' onClick={handleOpenErratas}>
              <AddErrataIcon />
              {t('supportTab.errata')}
            </button>
            {/* <Erratas /> */}
            <ExternalLinkButton
              link='https://player.vimeo.com/video/875669860?h=9b65721285'
              icon={<OnboardingIcon />}
              description={t('supportTab.onboarding')}
            />
            {/* <ExternalLinkButton
              link={allLinks.status_page_link}
              icon={<StatusPageIcon />}
              description={t('supportTab.statusPage')}
            /> */}
            {/* <ExternalLinkButton
              link={allLinks.share_with_community_link}
              icon={<CommunityIcon />}
              description={t('supportTab.community')}
            /> */}
          </OtherItemsWrapper>
          <BottomCTAWrapper>
            <a
              href={rightHrefForContactUsLink}
              target='_blank'
              rel='noreferrer'
            >
              <Button color='tertiary'>{t('supportTab.contactUs')}</Button>
            </a>
            <a
              href={rightHrefForHelpCenterEmailLink}
              target='_blank'
              rel='noreferrer'
            >
              <Button color='tertiary'>{t('supportTab.helpCenter')}</Button>
            </a>
          </BottomCTAWrapper>
        </SupportTabContents>
      )}
      <IconContainer>
        <IconButton
          icon={<SupportTabIcon />}
          size='medium'
          variant='filled'
          color='black'
          onClick={handleOpen}
        />
      </IconContainer>
    </SupportTabWrapper>
  )
}

export default SupportTab

const SupportTabWrapper = styled.div`
  z-index: ${({ theme }) => theme.zIndex.menu};
  display: flex;
  align-items: flex-end;
  gap: 8px;
  position: fixed;
  bottom: 8px;
  right: 8px;
`

const SupportTabContents = styled.div`
  width: 480px;
  padding: 16px;
  ${({ isDashboard, isDarkTheme }) =>
    isDashboard && isDarkTheme
      ? css`
          background-image: ${({ theme }) => theme.colors.backgrounds.main};
        `
      : css`
          background-color: ${({ theme }) => theme.colors.main.primary100};
        `}
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.main.primary500};

  animation: easeing-in 300ms ease-out;
  @keyframes easeing-in {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    70% {
      opacity: 1;
      transform: translateY(Opx);
    }
    100% {
      opacity: 1;
    }
  }

  .support-tab-btn {
    border: none;
    background-color: transparent;
    height: 32px;
    width: 100%;
    padding-left: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.notifications.active};
      border-radius: 6px;
    }

    svg {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.main.grey600};
    }
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
`

// const GettingStartedWrapper = styled.div`
//   height: 84px;
//   padding: 12px 0;
//   border: solid ${({ theme }) => theme.colors.main.grey300};
//   border-width: 1px 0 1px 0;

//   p {
//     font-size: 12px;
//   }
// `

const OtherItemsWrapper = styled.div`
  padding-bottom: 12px; // add top padding when other support tab items are ready
  h2 {
    font-size: 12px;
    line-height: 16px;
  }
`

const BottomCTAWrapper = styled.div`
  width: 100%;
  border: solid ${({ theme }) => theme.colors.main.grey300};
  border-width: 1px 0 0 0;
  padding-top: 12px;
  display: flex;
  gap: 12px;

  a {
    width: 50%;
    button {
      width: 100%;
    }
  }
`

const IconContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;

  svg {
    font-size: 20px;
  }
`

import PATHS from 'utils/paths'
export const isPathToNotCountStudyTime = path => {
  return (
    path === PATHS.examSectionAnswerSheet ||
    path === PATHS.examSectionDiagnostic ||
    path === PATHS.exams ||
    path === PATHS.calendar ||
    path === PATHS.dashboard ||
    path === PATHS.examScoreProjection ||
    path === PATHS.projections
  )
}

import APIService from 'services/APIService'
const api = APIService()

export const getContentTopics = payload =>
  api.get(`/student-course-topics/content/${payload.contentId}`)
export const getCourseTopics = payload =>
  api.get(`/student-course-topics?${payload.query}`)

export const checkCourseTopic = payload =>
  api.patch(`/student-course-topics/${payload.topic_id}/is-mastered`, {
    is_mastered: true
  })
export const uncheckCourseTopic = payload =>
  api.patch(`/student-course-topics/${payload.topic_id}/is-mastered`, {
    is_mastered: false
  })

export const markTopicCheckbox = payload =>
  api.patch(
    `/student-content-topics/${payload.student_content_topic_id}/read`,
    { is_read: true }
  )
export const unmarkTopicCheckbox = payload =>
  api.patch(
    `/student-content-topics/${payload.student_content_topic_id}/read`,
    { is_read: false }
  )

export const getTopicsComment = payload =>
  api.get(`/student-book-content-comments/${payload.book_content_id}`)

export const markCommentAsRead = payload =>
  api.patch(`/student-book-content-comments/${payload.book_content_id}/read`)

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Course } from 'types'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import * as R from 'ramda'
import { COURSE_TYPES } from 'utils/courses'
import { getIsImpersonate, isAuthLoaded } from 'modules/auth/ducks/selectors'

import { setThemeNotificationVisibility } from 'examkrackers-components'

// The purpose of this component is to reduce the number
// of re-renders for each change of "currentCourse" in redux store.
//   Instead of calling it directly in the PrivateRoute component,
//   it was separated, so that a change in "currentCourse" does not
// re-render the whole page that is rendered by the Router

export const FreeTrialNotificationHandler = (): JSX.Element => {
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isImpersonate = useSelector(getIsImpersonate)

  useEffect(() => {
    if (isAuthFetched && !isImpersonate) {
      setThemeNotificationVisibility(isFreeTrial)
    }
  }, [currentCourse, isAuthFetched, isImpersonate])

  return <div />
}

export default FreeTrialNotificationHandler

import React, { useState } from 'react'
import styled from 'styled-components'

import { Modal, Button, PaletteIcon } from 'examkrackers-components'
import { CompactPicker } from 'react-color'

export const ModalColorPicker = ({ setColor, currentColor }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleSelectColor = color => {
    setColor(color.hex)
    handleClose()
  }

  return (
    <>
      <Button id='color-picker' size='small' onClick={handleOpen}>
        <div style={{ color: currentColor }}>
          <PaletteIcon />
        </div>
      </Button>
      <Modal title='' open={open} handleClose={handleClose}>
        <ColorPickerContainer>
          <CompactPicker
            id='color-picker-compact'
            color={currentColor}
            onChange={handleSelectColor}
          />
        </ColorPickerContainer>
      </Modal>
    </>
  )
}

export default ModalColorPicker

const ColorPickerContainer = styled.div`
  text-align: center;
`

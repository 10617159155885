import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { BouncingLoader } from 'examkrackers-components'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const withSettingsPageWrapper = WrappedComponent => {
  return props => {
    const { location } = useHistory()
    const course = location?.state?.course || null

    const currentCourse = useSelector(getCurrentCourse)

    return isNotNilOrEmpty(currentCourse?.id) || isNotNilOrEmpty(course) ? (
      <WrappedComponent
        {...props}
        data-testid='withSettingsPageWrapper-WrappedComponent'
      />
    ) : (
      <LoaderContainer data-testid='withSettingsPageWrapper-LoaderContainer'>
        <BouncingLoader data-testid='withSettingsPageWrapper-BouncingLoader' />
      </LoaderContainer>
    )
  }
}

export default withSettingsPageWrapper

const LoaderContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

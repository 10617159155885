import { reactToItemSelection, reactToArraySelection } from 'utils/array'
import {
  handleSelectItemRoutine,
  handleSelectCollectionRoutine,
  handleResetSelectionRoutine,
  handleSelectEntityRoutine,
  handleSelectEntitiesRoutine
} from 'ducks/selection/actions'
import { API_STATES, transformById } from 'utils/redux'
import { Action, SelectionReducer } from 'types'

export const initialState: SelectionReducer = {
  state: API_STATES.PRISTINE,
  selectedItems: [],
  selectedEntities: {}
}

export default (state = initialState, action: Action): SelectionReducer => {
  switch (action.type) {
    case handleSelectItemRoutine.SUCCESS:
      return {
        ...state,
        selectedItems: reactToItemSelection(action.payload.item)(
          state.selectedItems
        )
      }
    case handleSelectCollectionRoutine.SUCCESS:
      return {
        ...state,
        selectedItems: reactToArraySelection(action.payload.collection)(
          state.selectedItems
        )
      }
    case handleResetSelectionRoutine.SUCCESS:
      return {
        ...state,
        selectedItems: [],
        selectedEntities: {}
      }
    case handleSelectEntityRoutine.SUCCESS:
      return {
        ...state,
        selectedEntities: {
          ...state.selectedEntities,
          [action.payload.entity.id]: action.payload.entity
        }
      }
    case handleSelectEntitiesRoutine.SUCCESS:
      return {
        ...state,
        selectedEntities: {
          ...state.selectedEntities,
          ...transformById('id')(action.payload.entities)
        }
      }
    default:
      return state
  }
}

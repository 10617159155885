import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { selectBookById } from 'modules/books/ducks/selectors'

import { Input, SingleSelect } from 'examkrackers-components'
import { getOptionByValue } from 'utils/form'

import { sortingOptions } from 'utils/notes'

import { omit, pathOr, prop, propOr, sortBy, pipe, dissoc, propEq } from 'ramda'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import debounce from 'lodash.debounce'
import { isNotNilOrEmpty } from 'utils/ramda'

import { SORT_DIRECTION } from 'utils/table'

interface FiltersNotesPayload {
  id: string
  active?: boolean
}

const FiltersNotes = ({ id, active }: FiltersNotesPayload) => {
  const [searchQuery, setSearchQuery] = useState('')
  const { t } = useTranslation()
  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const isPinsActiveTab = propEq('activeTab', 'pins', parsedQuery)

  const isAllNotesPath = pathname === t('notes.allNotesPath')

  const selectedBookChapterId = pathOr('', ['filter', 'bc.id'], parsedQuery)
  const selectedSortingOption = pathOr('', ['order', 'by'], parsedQuery)

  const book = useSelector(state => selectBookById(state, id))

  const chapters = useMemo(() => {
    return pipe(
      propOr([], 'chapters'),
      // @ts-ignore
      sortBy(prop('order'))
      // @ts-ignore
    )(book)
  }, [book])

  const chaptersOptions = useMemo(() => {
    // @ts-ignore
    return chapters?.map(chapter => {
      const option = (
        <OptionWrapper>
          <OptionText>
            {/* @ts-ignore */}
            {chapter.order}. {chapter.title}
          </OptionText>
        </OptionWrapper>
      )

      return {
        label: option,
        // @ts-ignore
        value: propOr('', 'id', chapter)
      }
    })
  }, [chapters])

  const debounceHandler = useCallback(
    debounce((e: any) => setSearchQuery(e.target.value), 500),
    []
  )

  const handleChapterChange = option => {
    if (option) {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          'bc.id': option.value
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...omit(['bc.id'], parsedQuery.filter)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }

  const handleSortOptionChange = option => {
    const optionLabel = propOr('', 'label', option)
    const properOrder =
      optionLabel === 'Latest' ? SORT_DIRECTION.desc : SORT_DIRECTION.asc
    if (option) {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        order: {
          by: option.value,
          dir: properOrder
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        order: {
          ...omit(['by'], parsedQuery.order)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }
  const onSortingOptionChange = getOptionByValue(selectedSortingOption)(
    // @ts-ignore
    sortingOptions()
  )

  useEffect(() => {
    if (searchQuery.length === 0) {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...dissoc('search', parsedQuery.filter)
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    } else {
      const newQuery = {
        ...parsedQuery,
        limit: { ...parsedQuery.limit, page: 1 },
        filter: {
          ...parsedQuery.filter,
          search: searchQuery
        }
      }
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
    }
  }, [searchQuery])

  const isClearable = isNotNilOrEmpty(searchQuery)

  return (
    <SectionWrapper>
      <Container active={active}>
        <SearchInputContainer isAllNotesPath={isAllNotesPath}>
          <Input
            size='small'
            type='search'
            onChange={debounceHandler}
            hasClearType={isClearable}
          />
        </SearchInputContainer>
        <SelectContainer>
          <SingleSelect
            removeMargin
            removeTopLabel
            label={t('notes.sort')}
            size='small'
            // @ts-ignore
            options={sortingOptions()}
            onChange={handleSortOptionChange}
            value={onSortingOptionChange}
            isSearchable={false}
          />
        </SelectContainer>
      </Container>
      {!isAllNotesPath && !isPinsActiveTab ? (
        <Trying>
          <SelectContainer>
            <SingleSelect
              removeMargin
              removeTopLabel
              label={t('bookViewer.navigation.chapter')}
              size='small'
              // @ts-ignore
              options={chaptersOptions}
              onChange={handleChapterChange}
              value={getOptionByValue(selectedBookChapterId)(chaptersOptions)}
              isClearable
            />
          </SelectContainer>
        </Trying>
      ) : null}
    </SectionWrapper>
  )
}

export default FiltersNotes

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
`

const SearchInputContainer = styled.div`
  position: relative;
  width: ${({ isAllNotesPath }) => (isAllNotesPath ? '180px' : '165px')};
  padding-left: 8px;
  margin-bottom: -16px;
  margin-top: -8px;
  display: flex;
  /* justify-content: center; */
  align-items: center;

  & input {
    width: 100%;
  }
`

const OptionWrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const OptionText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
`
const SelectContainer = styled.div`
  width: 128px;
  margin: -8px 0px -16px 8px;
`
const Trying = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`

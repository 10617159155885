import APIService from 'services/APIService'
import {
  FetchGlossaryWordByIdPayload,
  FetchGlossaryWordsListPayload,
  FetchPhraseInBookPayload
} from 'types'

const api = APIService()

export const fetchGlossaryWordById = (payload: FetchGlossaryWordByIdPayload) =>
  api.get(`/glossary/${payload.id}`)

export const fetchGlossaryWordsList = (
  payload: FetchGlossaryWordsListPayload
) => api.get(`/glossary/search${payload.query}`)

export const fetchPhraseInBookList = (payload: FetchPhraseInBookPayload) =>
  api.post(`/student-books/${payload.id}/search`, { search: payload.search })

export const fetchPhraseInCourseList = (payload: FetchPhraseInBookPayload) =>
  api.post(`/student-courses/${payload.id}/search`, { search: payload.search })

import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  ExamContent,
  ExamCollapse,
  ExamQuestionsReviewList,
  EndSectionModal,
  NoFlaggedQuestionsModal,
  NoIncompleteQuestionsModal
} from 'examkrackers-components'
import { useParams } from 'react-router-dom'
import {
  last,
  propOr,
  split,
  pipe,
  filter,
  propEq,
  not,
  length,
  ifElse,
  isNil,
  always,
  identity
} from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  getQuestionStepStates,
  getExamDetails,
  getSectionFullTitleById,
  getExamQuestionsMap
} from 'modules/exam/ducks/selectors'

import {
  toggleQuestionFlag,
  saveSectionStatus,
  endSection
} from 'services/ExamsService'
import {
  getQuestionNavItems,
  getSectionExamStepsById,
  QUESTION_STATUSES,
  REVIEW_FILTER_TYPES,
  SECTION_STATUSES,
  hasIncomplete,
  hasFlagged
} from 'modules/exam/utils/exam'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import { RootReducer } from 'types'
import { findFirstPageId } from '../../utils/examPagesConfig'
import { getIsPreviewAdmin } from '../../../auth/ducks/selectors'

interface QuestionItemProps {
  displayName: string
  order: number
  onClickHandler: () => void
  onFlagClickHandler: () => void
  flagged: boolean
  status: string
}

export const SectionReview = ({
  setCurrentPage,
  currentPage,
  currentPageConfig,
  setQuestionFilterType,
  setNavigationHandlers
}): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)

  const [endSectionModalOpen, setEndSectionModalVisibility] = useState(false)
  const [isNoFlaggedModalOpen, setNoFlaggedModalVisibility] =
    React.useState(false)
  const [isNoIncompleteModalOpen, setNoIncompleteModalVisibility] =
    React.useState(false)

  const openEndSectionModal = () => setEndSectionModalVisibility(true)
  const closeEndSectionModal = () => setEndSectionModalVisibility(false)

  const handleCloseNoFlaggedModal = () => setNoFlaggedModalVisibility(false)
  const handleCloseNoIncompleteModal = () =>
    setNoIncompleteModalVisibility(false)

  const id: string = propOr('', 'id')(params)

  const allQuestionStates = useSelector(getQuestionStepStates)
  const sectionId: string = pipe(
    split('section-review-'),
    last,
    ifElse(isNil, always(''), identity)
  )(currentPage)
  const examDetails = useSelector(getExamDetails)
  const sectionFullTitle = useSelector((state: RootReducer) =>
    getSectionFullTitleById(state, sectionId)
  )

  const examPagesSummary = useSelector(getExamQuestionsMap)

  const hasFlaggedQuestions = hasFlagged(sectionId, examPagesSummary)
  const hasIncompleteQuestions = hasIncomplete(sectionId, examPagesSummary)

  const setQuestionFilter = (filterType: any) => () => {
    const newPageId = findFirstPageId(examPagesSummary)(sectionId, filterType)
    setCurrentPage(newPageId)
    setQuestionFilterType(filterType)
  }

  const handleReviewFlagged = () => {
    if (hasFlaggedQuestions) {
      setQuestionFilter(REVIEW_FILTER_TYPES.flagged)()
    } else {
      setNoFlaggedModalVisibility(true)
    }
  }

  const handleReviewIncomplete = () => {
    if (hasIncompleteQuestions) {
      setQuestionFilter(REVIEW_FILTER_TYPES.incomplete)()
    } else {
      setNoIncompleteModalVisibility(true)
    }
  }

  const nextPageHandler = id => () => {
    setCurrentPage(id)
    setQuestionFilterType(REVIEW_FILTER_TYPES.all)
  }
  const stepsList = getSectionExamStepsById(examDetails, sectionId)

  const fetchExamDetails = useCallback(
    payload => dispatch(fetchExamDetailsRoutine(payload)),
    [dispatch]
  )

  const [isEndSectionLoading, setEndSectionLoading] = useState(false)

  const setNextPageOnEndSection = () => {
    setEndSectionLoading(true)
    const payload = {
      id,
      section_id: sectionId
    }

    const handleError = e => {
      console.error(e)
      setEndSectionLoading(false)
    }
    const handleSuccess = () => {
      // Do not remove this state refresh! Without that we will loose timers tracking
      fetchExamDetails({ id })
      setCurrentPage(currentPageConfig.nextPageId)
      setEndSectionLoading(false)
    }

    if (!isPreviewAdmin) {
      endSection(payload).then(handleSuccess).catch(handleError)
    } else {
      setCurrentPage(currentPageConfig.nextPageId)
    }
  }

  const handleFlagClick = questionId => async () => {
    const handleSuccess = () => fetchExamDetails({ id })
    const handleError = e => console.error(e)
    toggleQuestionFlag({ id: questionId })
      .then(handleSuccess)
      .catch(handleError)
  }

  // @ts-ignore
  const questionItems: QuestionItemProps[] = getQuestionNavItems(
    nextPageHandler,
    allQuestionStates,
    stepsList,
    handleFlagClick
  )

  // @ts-ignore
  const toCompleteCount: number = pipe(
    filter(pipe(propEq('status', QUESTION_STATUSES.complete), not)),
    // @ts-ignore
    length
    // @ts-ignore
  )(questionItems)

  const reviewHeader = (
    <div className='exam-review__questions-header'>
      <div>{sectionFullTitle}</div>
      <div>{toCompleteCount} (Unseen/Incomplete)</div>
    </div>
  )

  useEffect(() => {
    saveSectionStatus({ id: sectionId, status: SECTION_STATUSES.review })
  }, [])

  useEffect(() => {
    setNavigationHandlers(prevState => ({
      ...prevState,
      endSectionButtonOnClick: openEndSectionModal,
      reviewAllButtonOnClick: setQuestionFilter(REVIEW_FILTER_TYPES.all),
      reviewFlaggedButtonOnClick: handleReviewFlagged,
      reviewIncompleteButtonOnClick: handleReviewIncomplete
    }))
  }, [allQuestionStates, examPagesSummary])

  // @ts-ignore
  return (
    <SectionReviewContainer>
      <div className='section-review__header'>
        <h1 className='centered'>Section Review</h1>
      </div>
      <div>
        <ExamCollapse
          header='Instructions'
          content={
            <div className='exam-review__instructions'>
              <p>
                The table below is a summary of your answers in this section.
                You can review your answers in four different ways using the
                features at the bottom of the Section Review:
              </p>
              <ol>
                <li>
                  Select Review All or the Alt+A keyboard shortcut to review all
                  the questions and answers from the beginning, starting with
                  Question 1.
                </li>
                <li>
                  Select Review Incomplete or the Alt+I keyboard shortcut to
                  review only the questions that are Incomplete or Unseen.
                </li>
                <li>
                  Select Review Flagged or the Alt+R keyboard shortcut to review
                  only the questions that are flagged for review.
                </li>
                <li>
                  Select an individual question number to review a question of
                  your choice. This will take you directly to the question.
                  After you’ve reviewed the question, select Review Screen or
                  the Alt+W keyboard shortcut at the bottom of the question to
                  return to the Section Review.
                </li>
              </ol>
              <p>
                If you do not wish to review any of your answers, or you’ve
                finished reviewing your answers, select End Section or the Alt+E
                keyboard shortcut.
              </p>
            </div>
          }
        />
      </div>
      <div className='exam-review__questions'>
        <ExamCollapse
          header={reviewHeader}
          content={<ExamQuestionsReviewList items={questionItems} />}
        />
      </div>
      <EndSectionModal
        incomplete={toCompleteCount}
        handleConfirm={setNextPageOnEndSection}
        handleClose={closeEndSectionModal}
        open={endSectionModalOpen}
        isLoading={isEndSectionLoading}
      />
      <NoIncompleteQuestionsModal
        open={isNoIncompleteModalOpen}
        handleClose={handleCloseNoIncompleteModal}
      />
      <NoFlaggedQuestionsModal
        open={isNoFlaggedModalOpen}
        handleClose={handleCloseNoFlaggedModal}
      />
    </SectionReviewContainer>
  )
}

export default SectionReview

const SectionReviewContainer = styled(ExamContent)`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;

  h2 {
    margin: 0 !important;
  }

  .exam-review__questions {
    flex-grow: 1;
    max-height: 100%;
    overflow: hidden;

    .collapse_header h2 {
      flex-grow: 1;
    }
  }

  .exam-review__questions-header {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    font-size: 16px;
    line-height: 24.27px;
  }

  .section-review__header h1 {
    font-family: 'Arial', 'Helvetica', 'sans-serif';
    font-size: 18px;
    font-weight: 300 !important;
    line-height: 24px;
    padding-top: 15px;
    padding-bottom: 10px;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    color: #353535;
  }

  .exam-review__instructions {
    flex-grow: 1;

    p {
      padding: 5px 2px;
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 16px;
    }

    p:last-child {
      margin-bottom: 5px;
    }

    ol {
      margin-left: 40px;
      margin-bottom: 16px;
    }

    li {
      list-style: decimal outside;
      line-height: 20.7104px;
      margin-top: 5.1776px;
    }

    li:first-child {
      margin-top: 0;
    }
  }
`

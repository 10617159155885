import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import { addQuestionStateRoutine } from 'modules/exam/ducks/actions'
import { getFirstSectionId, getQuestion } from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'

export const TutorialSectionReview = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const dispatch = useDispatch()
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialSectionReview)
  )
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )
  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    setCurrentPage(PageIds.tutorialEnd)
  }

  const handlePrevious = () => {
    setCurrentPage(PageIds.tutorialFlagForReview)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? ''
          : questionState.status,
      id: PageIds.tutorialSectionReview
    })
  }, [])

  return (
    <ExamContent>
      <TutorialSectionReviewContainer>
        <div>
          <div className='heavy-bordered'>
            <h2>Use the Section Review Feature</h2>
            <h3>Description</h3>
            <p>
              When you advance past the last question of each section and if
              there is time remaining, you can use the Section Review to review
              questions. The Section Review indicates which questions are
              incomplete, unseen, or marked with the flag icon. The flag icon
              next to the question number will be filled in blue, if you flagged
              that question previously.
            </p>
            <h3>Instructions</h3>
            <ul className='numeric'>
              <li>
                There are four ways to review questions.
                <ul className='alphabetical'>
                  <li>
                    Selecting{' '}
                    <strong>
                      <span>Review </span>
                      <span className='underline'>A</span>ll
                    </strong>{' '}
                    at the bottom of the screen or the Alt+A keyboard shortcut
                    will allow you to review all questions beginning at question
                    one in the section.
                  </li>
                  <li>
                    Selecting{' '}
                    <strong>
                      <span>Review </span>
                      <span className='underline'>I</span>ncomplete
                    </strong>{' '}
                    at the bottom of the screen or the Alt+I keyboard shortcut
                    will allow you to review only questions marked incomplete or
                    unseen.
                  </li>
                  <li>
                    Selecting{' '}
                    <strong>
                      <span className='underline'>R</span>eview Flagged
                    </strong>{' '}
                    at the bottom of the screen or the Alt+R keyboard shortcut
                    will allow you to review only questions you marked with the
                    flag icon.
                  </li>
                  <li>
                    Selecting an individual question number allows you to review
                    individual questions. After you’ve reviewed the question,
                    select{' '}
                    <strong>
                      Revie<span className='underline'>w</span> Screen
                    </strong>{' '}
                    or the Alt+W keyboard shortcut at the bottom left of the
                    question to return to the Section Review.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span className='underline'>E</span>nd Section
                </strong>
                at the bottom of the screen or the Alt+E keyboard shortcut
                allows you to confirm that you are ready to end the section and
                advance to the next exam section.
              </li>
            </ul>
            <div style={{ textAlign: 'center' }}>
              <img
                alt='section review screenshot'
                src='https://s3.amazonaws.com/benchprep-public/aamc/tutorial-candidate-name-section-review.png'
                style={{ width: '575px', display: 'inline' }}
              />
            </div>
            <h3>Practice</h3>
            <p>
              Review the <strong>Section Review</strong> image provided.
              Identify the three columns in the table as well as the{' '}
              <strong>
                Review <span className='underline'>A</span>ll,
              </strong>{' '}
              <strong>
                Review <span className='underline'>I</span>ncomplete,
              </strong>{' '}
              and{' '}
              <strong>
                <span className='underline'>R</span>eview Flagged
              </strong>{' '}
              features at the bottom of the table.
            </p>
            <p>
              The{' '}
              <strong>
                Revie<span className='underline'>w</span> Screen
              </strong>{' '}
              functions similarly to the{' '}
              <strong>
                Na<span className='underline'>v</span>igation
              </strong>{' '}
              feature, which is no longer available once the Section Review
              screen has been reached.
            </p>
          </div>
          <p>
            Select{' '}
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page.
          </p>
        </div>
      </TutorialSectionReviewContainer>
    </ExamContent>
  )
}

export default TutorialSectionReview

const TutorialSectionReviewContainer = styled.div`
  p {
    margin: 16px 0;
    line-height: 22.4px;
  }

  ul,
  li {
    line-height: 22.4px;
  }

  li {
    margin-bottom: 2px !important;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }

  .indent {
    text-indent: 30px;
  }

  .answer {
    margin-top: 20px !important;
  }
`

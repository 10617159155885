import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Modal, SaltyBucksIcon, EntitiesList } from 'examkrackers-components'
import { map, pathOr, toLower } from 'ramda'
import { SORT_DIRECTION } from 'utils/table'
import { fetchEarningsForLeaderboard } from 'services/LeaderboardService'
import qs from 'qs'
import { formatNumberWithDecimal } from 'utils/numbers'

interface WalletLog {
  id: string
  amount: number
  reference_id: string
  reference_type: string
  operation_type: string
  metadata: string
}

const ModalLastEarnings = ({ handleClose, open }) => {
  const { t } = useTranslation()
  const [earnings, setEarnings] = useState([])
  const [pagination, setPagination] = useState(1)

  const initialQuery = qs.stringify({
    order: { by: 'created_at', dir: toLower(SORT_DIRECTION.desc) },
    limit: { take: 10, page: 1 }
  })

  const parsedQuery = qs.parse(initialQuery, { ignoreQueryPrefix: true })

  const handleEarningsAndPaginationData = result => {
    setEarnings(pathOr([], ['data', 'data'], result))
    setPagination(result.data.meta.pagesTotal)
  }

  const getEarnings = query => {
    fetchEarningsForLeaderboard(query)
      .then(result => handleEarningsAndPaginationData(result))
      .catch(console.error)
  }

  useEffect(() => {
    getEarnings({ query: initialQuery })
  }, [])

  const rows = map((log: WalletLog) => ({
    id: `row-wallet-logs-${log.id}`,
    cells: [
      {
        children: (
          <>
            {t(
              `navigation.saltyBucksDropDown.logs.${pathOr(
                'unknown',
                ['reference_type'],
                log
              )}.${pathOr('unknown', ['operation_subtype'], log)}`
            )}
          </>
        ),
        columnId: 'reference_type',
        cellProps: {}
      },
      {
        children: (
          <AmountContainer>
            {
              // @ts-ignore
              pathOr('', ['operation_type'], log) === 'income' ? '+' : '-'
            }
            <SaltyBucksIcon />
            {formatNumberWithDecimal(log.amount)}
          </AmountContainer>
        ),
        columnId: 'amount',
        cellProps: { width: '20%', align: 'center' }
      }
    ]
  }))(earnings)

  const headers = [
    {
      columnId: 'reference_type',
      sortable: false,
      sticky: true,
      id: 'reference_type',
      children: t('navigation.saltyBucksDropDown.reason')
    },
    {
      columnId: 'amount',
      sortable: false,
      sticky: true,
      id: 'amount',
      align: 'center',
      children: t('navigation.saltyBucksDropDown.amount')
    }
  ]

  const handleTableStateChange = ({ dir, page }) => {
    const query = qs.stringify({
      order: { by: 'created_at', dir: toLower(dir) },
      limit: { take: 10, page: page }
    })

    getEarnings({ query: query })
  }

  return (
    <Modal
      title={t('leaderboard.rightColumn.earnings.title')}
      open={open}
      handleClose={handleClose}
    >
      <HistoryWrapper>
        <EntitiesList
          size='xs'
          headers={headers}
          rows={rows}
          totalPages={pagination}
          emptyStateText={t('wallets.logsTable.emptyState')}
          defaultPage={pathOr(1, ['limit', 'page'], parsedQuery)}
          defaultSortColumnId='reference_type'
          defaultSortDirection={pathOr(
            SORT_DIRECTION.desc,
            ['order', 'dir'],
            parsedQuery
          )}
          onTableStateChange={handleTableStateChange}
        />
      </HistoryWrapper>
    </Modal>
  )
}

export default ModalLastEarnings

const HistoryWrapper = styled.div`
  .rows-per-page {
    display: none;
  }
`

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.main.currency500};
  font-weight: 700;
`

import React, { useContext, useState } from 'react'
import Calendar from './Calendar'
import styled from 'styled-components'
import LeftColumnCalendar from './LeftColumnCalendar'
import ReorganizeCalendarModal from './ReorganizeCalendarModal'
import AddOrEditEventModal from 'modules/calendar/AddOrEditEventModal'
import ManualCalendarTasks from 'modules/calendar/ManualCalendarTasks'

import { CalendarContext } from 'hooks/CalendarContext'

import { countStudyDaysInRangeForExpiration } from 'utils/calendar'

const CalendarController = () => {
  const { courseDaysAmount, course } = useContext(CalendarContext)
  const [manualOpen, setManualOpen] = useState(false)
  const isLiveCourse = course?.type === 'live_course'
  const startDateClasses = isLiveCourse ? course?.endDate.start_date : ''

  const studyDaysForExpiration = countStudyDaysInRangeForExpiration(
    course?.accessible_from,
    startDateClasses
  )

  const daysAmount = isLiveCourse
    ? studyDaysForExpiration
    : course.metadata
    ? courseDaysAmount
    : 0

  const handleManualTaskOpenStateChange = (isOpen: boolean) => {
    setManualOpen(isOpen)
  }

  return (
    <>
      <Wrapper data-testid='CalendarController-Wrapper'>
        <LeftColumn data-testid='CalendarController-LeftColumn'>
          <CalendarActions data-testid='CalendarController-CalendarActions'>
            <AddOrEditEventModal data-testid='CalendarController-AddOrEditEventModal' />
            {/* Stays hide until reset an reorder features are fixed */}
            <ReorganizeCalendarModal />
          </CalendarActions>
          <LeftColumnCalendar data-testid='CalendarController-LeftColumnCalendar' />
        </LeftColumn>
        <MainCalendar
          id='calendar-container'
          data-testid='CalendarController-MainCalendar'
        >
          <Calendar
            manualOpen={manualOpen}
            data-testid='CalendarController-Calendar'
          />
          <ManualCalendarTasks
            data-testid='CalendarController-ManualCalendarTasks'
            onOpenStateChange={handleManualTaskOpenStateChange}
          />
        </MainCalendar>
      </Wrapper>
    </>
  )
}

export default CalendarController

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

const MainCalendar = styled.div`
  position: relative;
`

const LeftColumn = styled.div``

const CalendarActions = styled.div`
  display: flex;
  gap: 6px;
  padding: 8px 0;

  button {
    padding: 0 8px !important;
    min-width: 0 !important;
    font-size: 12px !important;
    max-height: 32px !important;
  }
`

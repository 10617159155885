import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ArrowUpFilledIcon, IconButton } from 'examkrackers-components'
import { getMonthName, getMonthNumberByName } from 'utils/calendar'
import { getVisibleDates } from './ducks/selectors'
import { useSelector } from 'react-redux'
import { CalendarContext } from 'hooks/CalendarContext'

const MonthsToggle = () => {
  const { calendar } = useContext(CalendarContext)
  const visibleDates = useSelector(getVisibleDates)
  const headerRef = React.useRef(null)

  const startDate = calendar?.start_at || ''
  const startYear = new Date(startDate).getFullYear()
  const startMonth = new Date(startDate).getMonth() + 1

  const [currentMonth, setCurrentMonth] = React.useState({
    month: getMonthName(new Date(startDate).getMonth() + 1),
    year: new Date(startDate).getFullYear()
  })

  const { month, year } = currentMonth

  const onScrollEnd = () => {
    const currentVisibleMonth =
      new Date((visibleDates as Date[])[10]).getMonth() + 1
    const currentVisibleYear = new Date(
      (visibleDates as Date[])[10]
    ).getFullYear()

    setCurrentMonth({
      month: getMonthName(currentVisibleMonth.toString().padStart(2, '0')),
      year: currentVisibleYear
    })
  }

  const scrollToNextMonth = () => {
    const monthNumber = parseInt(getMonthNumberByName(month.toLowerCase()))

    const nextMonth = monthNumber === 12 ? 1 : monthNumber + 1
    const nextYear = monthNumber === 12 ? year + 1 : year

    const nextMonthElement = document.querySelectorAll(
      `[data-id='${String(nextMonth).padStart(2, '0')}-${nextYear}-row']`
    )[0]

    if (nextMonthElement) {
      nextMonthElement.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        setCurrentMonth({ month: getMonthName(nextMonth), year: nextYear })
      }, 500) // Update the visibleDate state
    }
  }

  const scrollToPreviousMonth = () => {
    const { month, year } = currentMonth
    const monthNumber = parseInt(getMonthNumberByName(month.toLowerCase()))

    const prevMonth = monthNumber === 1 ? 12 : monthNumber - 1
    const prevYear = monthNumber === 1 ? year - 1 : year

    const prevMonthElement = document.querySelectorAll(
      `[data-id='${String(prevMonth).padStart(2, '0')}-${prevYear}-row']`
    )[0]

    if (prevMonthElement) {
      prevMonthElement.scrollIntoView({ behavior: 'smooth' })
      setTimeout(
        () =>
          setCurrentMonth({ month: getMonthName(prevMonth), year: prevYear }),
        500
      )
    } else {
      const startingMonthElement = document.querySelectorAll(
        `[id*='${startMonth}-0${startYear}-row']`
      )[0]

      startingMonthElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }
  }

  useEffect(() => {
    onScrollEnd()
  }, [visibleDates])

  return (
    <MonthsToggleWrapper
      ref={headerRef}
      data-testid='MonthsToggle-MonthsToggleWrapper'
    >
      <ArrowLeft data-testid='MonthsToggle-ArrpwLeft'>
        <IconButton
          data-testid='MonthsToggle-IconButton-Left'
          icon={<ArrowUpFilledIcon color='tertiary' />}
          onClick={scrollToPreviousMonth}
        />
      </ArrowLeft>
      <p>
        <span id='visible-month-name'>{month}</span> {year}
      </p>
      <ArrowRight data-testid='MonthsToggle-ArrowRight'>
        <IconButton
          data-testid='MonthsToggle-IconButton-Right'
          icon={<ArrowUpFilledIcon color='secondary' />}
          onClick={scrollToNextMonth}
        />
      </ArrowRight>
    </MonthsToggleWrapper>
  )
}

export default MonthsToggle

const MonthsToggleWrapper = styled.div`
  display: flex;
  /* gap: 10px; */
  align-items: center;
  justify-content: space-between;
  width: 185px;
`

const ArrowLeft = styled.div`
  transform: rotate(-90deg);
`

const ArrowRight = styled.div`
  transform: rotate(90deg);
`

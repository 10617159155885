import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { catchRoundNumber } from 'utils/flashcards'

export const FlashcardsRound = (): JSX.Element => {
  const [round, setRound] = useState(1)

  useEffect(() => {
    catchRoundNumber(setRound)
  }, [])

  return (
    <Container round={round}>
      <Line />
      <Label>Round {round.toString()}</Label>
    </Container>
  )
}

export default FlashcardsRound

const Container = styled.div`
  position: absolute;
  left: -35px;
  top: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: ${({ round }) => round * 64}px;
  transition: height 200ms ${({ theme }) => theme.transitions.easing.easeInOut};
`

const Line = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.main.primary500};
  width: 4px;
  border-radius: 4px;
  height: 100%;
  top: 0;
  right: 0;
`

const Label = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.main.primary500};
  transform: rotate(-90deg) translateY(10px);
`

import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Modal, Button, RedoIcon } from 'examkrackers-components'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedItems } from 'ducks/selection/selectors'
import { bulkUnArchiveFlashcards } from 'services/FlashcardsService'
import { useHistory } from 'react-router-dom'
import { FetchFlashcardsListPayload } from '../../../types'
import { fetchArchiveFlashcardsListRoutine } from '../ducks/actions'

export const ModalBulkUnarchiveFlashcards = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const selectedItems = useSelector(getSelectedItems)
  const {
    location: { search }
  } = useHistory()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const fetchFlashcards = (payload: FetchFlashcardsListPayload) => {
    dispatch(fetchArchiveFlashcardsListRoutine(payload))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)

    const handleSuccess = () => {
      fetchFlashcards({ query: search })
      handleClose()
      setIsLoading(false)
    }
    const handleError = () => {
      setIsLoading(false)
    }

    bulkUnArchiveFlashcards({ ids: selectedItems })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <ModalContainer>
      <Button
        size='small'
        color='secondary'
        id='delete-submit'
        isLoading={isLoading}
        disabled={isLoading || selectedItems.length < 1}
        onClick={handleOpen}
        startIcon={<RedoIcon />}
      >
        {t('flashcards.unArchive.title')}
      </Button>
      <Modal
        title={t('flashcards.unArchive.title')}
        open={open}
        handleClose={handleClose}
      >
        <>
          <div>
            {t('flashcards.unArchive.description', {
              count: selectedItems.length
            })}
          </div>
          <ButtonsContainer>
            <Button
              size='small'
              color='secondary'
              id='delete-submit'
              isLoading={isLoading}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {t('flashcards.unArchive.submitCTA')}
            </Button>
            <Button
              size='small'
              id='delete-cancel'
              disabled={isLoading}
              color='tertiary'
              variant='contained'
              onClick={handleClose}
            >
              {t('flashcards.unArchive.cancelCTA')}
            </Button>
          </ButtonsContainer>
        </>
      </Modal>
    </ModalContainer>
  )
}

export default ModalBulkUnarchiveFlashcards

const ButtonsContainer = styled.div`
  margin: 30px 0 0;

  button + button {
    margin-left: 10px;
  }
`

const ModalContainer = styled.div`
  line-height: 1;
`

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getMcatChartData } from 'services/BooksService'
import styled from 'styled-components'
import ApexChart from 'react-apexcharts'
import { McatProgressChart } from 'modules/dashboard/utils/McatProgressChart'

import { McatForChartIcon, TotalProgressIcon } from 'examkrackers-components'
import { contentTypes } from './TooltipChart'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'
import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

export const McatChart = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [bordersReplaced, setBordersReplaced] = useState(false)
  const hasData = isNotNilOrEmpty(data)
  const totalProgress =
    // @ts-ignore
    data.mcatData ? data.mcatData.reduce((a, b) => a + b) : 0

  useEffect(() => {
    getMcatChartData()
      .then(result => setData(result.data))
      .catch(console.error)
  }, [])

  const mcatChartData = McatProgressChart(data)

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  const tooltipEvent = e => ({ e, type: contentTypes.exam })
  const handleEnterTooltip = e =>
    eventEmitter.emit(events.chartTooltipEnter, tooltipEvent(e))
  const handleLeaveTooltip = () => eventEmitter.emit(events.chartTooltipLeave)

  // This is a workaround fix that pulls out the graph borders renderer
  // which obscures the datalabels that are displayed on it
  const pullOutGridBorders = () => {
    if (hasData && !bordersReplaced) {
      const chartContent = document.querySelector(
        '#MCAT-chart-container .apexcharts-inner.apexcharts-graphical'
      )
      const gridBorders = document.querySelector(
        '#MCAT-chart-container .apexcharts-grid-borders'
      )

      // @ts-ignore
      const childrenCollection = [...chartContent.children]
      // @ts-ignore
      const collectionWithoutGridBorders = childrenCollection.filter(
        e => ![...e.classList].includes('apexcharts-grid-borders')
      )

      const newChildrenCollection = [
        gridBorders,
        ...collectionWithoutGridBorders
      ]

      // @ts-ignore
      newChildrenCollection.forEach(element => {
        // @ts-ignore
        document
          .querySelector(
            '#MCAT-chart-container .apexcharts-inner.apexcharts-graphical'
          )
          // @ts-ignore
          .appendChild(element)
      })
      setBordersReplaced(true)
    }
  }

  useEffect(() => {
    document.addEventListener('mousemove', pullOutGridBorders)

    return () => {
      document.removeEventListener('mousemove', pullOutGridBorders)
    }
  }, [data, bordersReplaced])

  return (
    <>
      <ChartContainer id='MCAT-chart-container'>
        <Icon
          onMouseEnter={handleEnterTooltip}
          onMouseLeave={handleLeaveTooltip}
        >
          <McatForChartIcon />
        </Icon>
        <TotalProgress isDarkTheme={isDarkTheme}>
          <TotalProgressIcon />
          <p>
            {t('dashboard.charts.totalProgress')}
            <strong>{totalProgress}</strong>
          </p>
        </TotalProgress>
        {hasData && (
          <>
            {/* @ts-ignore */}
            <ApexChart
              // @ts-ignore
              options={mcatChartData.options}
              // @ts-ignore
              series={mcatChartData.series}
              type='bar'
              width='404'
              height='180'
            />
          </>
        )}
      </ChartContainer>
    </>
  )
}

const ChartContainer = styled.div`
  position: relative;
  svg {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.mainShadow};
    background: ${({ theme }) => theme.colors.backgrounds.main} !important;
    g.apexcharts-datalabels {
      transform: translateX(40px);
    }
  }

  .apexcharts-title-text {
    fill: ${({ theme }) => theme.colors.main.heading} !important;
  }
`

const Icon = styled.div`
  position: absolute;
  top: 12px;
  left: 14px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  color: ${({ theme }) => theme.colors.main.white};
  background: ${({ theme }) => theme.colors.dashboard.totalProgressBackground};
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  svg {
    font-size: 16px;
    border-radius: unset;
    box-shadow: none;
    background: none !important;
  }
`

const TotalProgress = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: ${({ theme, isDarkTheme }) =>
    isDarkTheme
      ? `inset -1px -1px 4px 0px ${theme.colors.dashboard.totalProgressShadowLight},
         inset 1px 1px 4px 0px ${theme.colors.dashboard.totalProgressShadowDark}`
      : 'none'};
  padding: 5px 10px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: ${({ theme }) => theme.typography.fontSizeSmall};
  background-color: ${({ theme, isDarkTheme }) =>
    !isDarkTheme
      ? theme.colors.main.grey200
      : theme.colors.dashboard.speedometer.white};
  svg {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};
    border-radius: 0px;
    box-shadow: none;
    background: none !important;
  }
  p {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};
    font-size: ${({ theme }) => theme.typography.fontSizeExtraSmall};
    margin-left: 5px;
    strong {
      color: ${({ theme, isDarkTheme }) =>
        !isDarkTheme
          ? theme.colors.dashboard.totalProgress
          : theme.colors.main.white};
      font-weight: 700;
    }
  }
`

export const LOCAL_STORAGE_KEYS = {
  selectedLanguage: 'selected-language',
  token: 'student-auth-token',
  studentCourseId: 'student-course-id',
  originalCourseId: 'original-course-id',
  studentCourseEndDateId: 'end-date-id',
  studentCourseType: 'student-course-type',
  bookScrollDebug: 'book-scroll-debug',
  gameStudentDetails: 'GAME_STUDENT_DETAILS',
  ekTheme: 'ek-theme',
  gamesExitKrackU: 'games-exit-kracku'
}

import React, { useEffect } from 'react'
import html2canvas from 'html2canvas'
import { useSelector } from 'react-redux'
import { RootReducer } from 'types'
import { propOr } from 'ramda'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'

import {
  getExamDetails,
  getSectionIdByCurrentPageId,
  getStepHtml
} from 'modules/exam/ducks/selectors'
import AnalyticsService from '../../../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../../../utils/analytics'
import { getAccountType, getStudent } from '../../auth/ducks/selectors'

export const DrawingOnCurrentPage = ({
  setBackgroundImage,
  setOpenHandler
}): JSX.Element => {
  const params = useParams()
  const questionId: string = R.propOr('', 'questionId', params)
  const student = useSelector(getStudent)
  const accountType: string = useSelector(getAccountType)

  const examDetails = useSelector(getExamDetails)
  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, questionId)
  )
  const passageId = R.propOr('', 'passageId', stepHtml)

  const questionOrder = R.pipe(
    R.propOr([], 'questions'),
    // @ts-ignore
    R.map(R.prop('questions')),
    R.flatten,
    R.find(R.propEq('id', questionId)),
    R.propOr('-', 'order')
    // @ts-ignore
  )(examDetails)

  const passageOrder = R.pipe(
    R.propOr([], 'questions'),
    // @ts-ignore
    R.map(R.prop('passages')),
    R.flatten,
    R.find(R.propEq('id', passageId)),
    R.ifElse(
      R.propEq('is_false_passage', false),
      R.propOr(0, 'realOrder'),
      R.always('DQ')
    )
    // @ts-ignore
  )(examDetails)

  const sectionIdByCurrentPageId = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, questionId)
  )

  const sectionTitle = R.pipe(
    R.propOr([], 'questions'),
    R.find(R.propEq('id', sectionIdByCurrentPageId)),
    R.propOr('-', 'title')
  )(examDetails)

  const sectionOrder = R.pipe(
    R.propOr([], 'questions'),
    R.find(R.propEq('id', sectionIdByCurrentPageId)),
    R.propOr(0, 'order')
  )(examDetails)

  const defaultBackgroundImage: string = propOr('', 'backgroundImage', stepHtml)

  const handleSaveScreenshot = () => {
    AnalyticsService(student).logEvent(ANALYTICS_EVENTS.markedOnExam, {
      'Exam Title': R.pathOr('', ['exam', 'title'], examDetails),
      'Exam format': R.pathOr('', ['type', 'title'], examDetails),
      'Section Title': sectionTitle,
      'Section Number': sectionOrder,
      'Account type': accountType,
      'Passage Number': passageOrder,
      'Question Number': questionOrder
    })

    const body = document.querySelector('#root')
    // @ts-ignore
    html2canvas(body).then(canvas => {
      const canvasToImg = canvas.toDataURL('image/png')
      setBackgroundImage(defaultBackgroundImage || canvasToImg)
    })
  }

  useEffect(() => {
    setOpenHandler(handleSaveScreenshot)
  }, [examDetails, stepHtml])

  return <div />
}

export default DrawingOnCurrentPage

import React, { useEffect } from 'react'
import { FetchFlashcardsListPayload } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFlashcardsListRoutine } from 'modules/flashcards/ducks/actions'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  getFlashcardsList,
  getFlashcardsPagination
} from 'modules/flashcards/ducks/selectors'
import styled from 'styled-components'
import qs from 'qs'
import Flashcard from 'modules/flashcards/components/Flashcard'
import { Pagination } from 'examkrackers-components'
import { pathOr, pipe, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'

const ListFlashcards = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const flashcards = useSelector(getFlashcardsList)
  const flashcardsPagination = useSelector(getFlashcardsPagination)
  const {
    location: { search, pathname },
    replace
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const fetchFlashcards = (payload: FetchFlashcardsListPayload) => {
    dispatch(fetchFlashcardsListRoutine(payload))
  }

  const handlePageChange = page => {
    const newQuery = {
      ...parsedQuery,
      limit: {
        ...parsedQuery.limit,
        page
      }
    }
    replace(`${pathname}?${qs.stringify(newQuery, { encode: false })}`)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      fetchFlashcards({ query: search })
    } else {
      const defaultFilters = {
        limit: {
          take: 24,
          page: 1
        }
      }
      replace(
        `${pathname}${qs.stringify(defaultFilters, { addQueryPrefix: true })}`
      )
    }
  }, [search])

  const isCustomBoxPage = pipe(
    pathOr('', ['filter', 'custom_box_id']),
    isNotNilOrEmpty
  )(parsedQuery)

  return (
    <>
      {isNotNilOrEmpty(flashcards) ? (
        <FlashcardsListWrapper>
          {flashcards.map(flashcard => {
            return (
              <Flashcard
                withSelection={isCustomBoxPage}
                key={flashcard.id}
                flashcard={flashcard}
              />
            )
          })}
        </FlashcardsListWrapper>
      ) : (
        <EmptyState>{t('flashcards.emptyState')}</EmptyState>
      )}
      <Pagination
        currentPage={propOr(1, 'page', flashcardsPagination)}
        totalPages={propOr(1, 'pagesTotal', flashcardsPagination)}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default ListFlashcards

// (container width - paddings) / tiles per row
const TILE_WIDTH = (1260 - 7 * 16) / 8

const FlashcardsListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, ${TILE_WIDTH}px);
  column-gap: 16px;
  row-gap: 16px;
  margin: 20px 0;
  max-width: 100%;
`

const EmptyState = styled.div`
  width: 100%;
  margin-top: 20px;
`

import React from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import { Button, Modal } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'utils/ramda'

const ModalCalendarImage = ({ isOpen, handleClose, imageUrl }) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('notifications.calendarModalTitle')}
    >
      <Wrapper>
        {isNotNilOrEmpty(imageUrl) && <Image url={imageUrl} />}
        <Button onClick={handleClose}>{t('notifications.close')}</Button>
      </Wrapper>
    </Modal>
  )
}

export default ModalCalendarImage

const Wrapper = styled.div`
  button {
    margin-top: 12px;
  }
`

import React from 'react'
import { propOr, pipe } from 'ramda'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { isNotNilOrEmpty } from 'utils/ramda'
import {
  DATE_FORMATS,
  daysFromToday,
  formatDate,
  isDateBeforeToday
} from 'utils/date'

export const LabelCourseAccess = ({ course }): JSX.Element => {
  const { t } = useTranslation()

  const accessibleTo = propOr('', 'accessible_to', course)
  const isStarted = isNotNilOrEmpty(accessibleTo)
  const isPaused = propOr(false, 'is_paused', course)
  const isExpired = isDateBeforeToday(accessibleTo)
  const daysAmount = pipe(
    propOr('{}', 'original_metadata'),
    JSON.parse,
    propOr('0', 'days_amount'),
    Number
  )(course)

  const unStartedLabel = (
    <UnStartedLabelContainer>
      <div>{t('courses.daysLength', { count: daysAmount })}</div>
      <UnStartedLabel>{t('courses.unStarted')}</UnStartedLabel>
    </UnStartedLabelContainer>
  )

  const getStartedLabel = () => (
    <AccessibleToLabel>
      {t('courses.daysLeft', {
        daysLeft: daysFromToday(accessibleTo),
        date: formatDate(accessibleTo, DATE_FORMATS.dashed)
      })}
    </AccessibleToLabel>
  )

  const getExpiredLabel = () => (
    <ExpiredLabel>{t('courses.expired')}</ExpiredLabel>
  )

  const PausedLabel = () => <ExpiredLabel>{t('courses.paused')}</ExpiredLabel>

  switch (true) {
    case isPaused:
      return PausedLabel()
    case isExpired:
      return getExpiredLabel()
    case isStarted:
      return getStartedLabel()
    default:
      return <>{unStartedLabel}</>
  }
}

export default LabelCourseAccess

const AccessibleToLabel = styled.div`
  padding: 4px 12px;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.colors.main.primary200};
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.main.primary600};
  font-size: 14px;
  line-height: 16px;
`

const ExpiredLabel = styled.div`
  padding: 4px 12px;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.colors.main.error200};
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.main.error500};
  font-size: 14px;
  line-height: 16px;
`

const UnStartedLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const UnStartedLabel = styled.div`
  padding: 4px 12px;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.colors.main.grey300};
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
`

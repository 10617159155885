import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { propOr } from 'ramda'

import {
  CloseIcon,
  IconButton,
  SettingsIcon,
  ToggleSwitch,
  setDarkTheme,
  setLightTheme
} from 'examkrackers-components'
import useOutsideClick from 'hooks/useOutsideClick'
import { useDispatch, useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'
import {
  updateBackgroundMusicSettingRoutine,
  updateCQAnimationsSettingRoutine,
  updateDashboardThemeRoutine
} from 'modules/auth/ducks/actions'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import LocalStorageService from 'services/LocalStorageService'
import PATHS from 'utils/paths'

const SettingsDropdown = () => {
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const studentDetails = useSelector(getStudent)
  const dashboardTheme = propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  // EK-2966 - disable background music setting until videos without music are available
  const videoMusicInitialStatus = studentDetails.video_bg_music_enabled
  const cqAnimationsInitialStatus = studentDetails.cq_animations_enabled
  const studentTheme = LocalStorageService.get(LOCAL_STORAGE_KEYS.ekTheme)
  const [theme, setTheme] = useState(studentTheme)
  const { location } = useHistory()
  const { pathname } = location

  const handleOpen = () => setOpen(open => !open)
  const handleClose = () => setOpen(false)
  useOutsideClick(containerRef, handleClose)

  // EK-2966 - disable background music setting until videos without music are available
  const handleBackgroundMusicToggle = () => {
    if (videoMusicInitialStatus === true) {
      dispatch(updateBackgroundMusicSettingRoutine(false))
    } else {
      dispatch(updateBackgroundMusicSettingRoutine(true))
    }
  }

  const handleAnimationsToggle = () => {
    if (cqAnimationsInitialStatus === true) {
      dispatch(updateCQAnimationsSettingRoutine(false))
    } else {
      dispatch(updateCQAnimationsSettingRoutine(true))
    }
  }

  const handleThemeToggle = () => {
    if (theme === 'dark') {
      dispatch(updateDashboardThemeRoutine('light'))
      setTheme('light')
    } else {
      setTheme('dark')
      dispatch(updateDashboardThemeRoutine('dark'))
    }
  }

  const debounceBackgroundMusicToggle = debounce(
    () => handleBackgroundMusicToggle(),
    600
  )

  const debounceAnimationsToggle = debounce(() => handleAnimationsToggle(), 600)

  const debounceThemeToggle = () => handleThemeToggle()

  useEffect(() => {
    if (pathname === PATHS.dashboard) {
      if (isDarkTheme) {
        setDarkTheme()
      } else {
        setLightTheme()
      }
    }
  }, [isDarkTheme, pathname])

  return (
    <Container ref={containerRef}>
      <StyledIconButton
        icon={<SettingsIcon />}
        variant='transparent'
        tooltip={t('navigation.settings.tooltip')}
        tooltipId='settings-dropdown-button'
        size='medium'
        onClick={handleOpen}
      />
      <Menu open={open}>
        <div className='top-label'>
          {t('navigation.settings.title')}
          <IconButton icon={<CloseIcon />} onClick={handleClose} />
        </div>
        {/* EK-2966 - disable background music setting until videos without music are available */}
        <SettingsItem>
          <SettingLabel>
            {t('navigation.settings.backgroundMusic.label')}
            <SettingsDescription>
              {t('navigation.settings.backgroundMusic.description')}
            </SettingsDescription>
          </SettingLabel>
          <ToggleContainer>
            <ToggleSwitch
              name='settings-background-music'
              id='settings-background-music'
              checked={videoMusicInitialStatus}
              value={videoMusicInitialStatus}
              onChange={debounceBackgroundMusicToggle}
              statusLabel
            />
          </ToggleContainer>
        </SettingsItem>
        <SettingsItem>
          <SettingLabel>
            {t('navigation.settings.animations.label')}
            <SettingsDescription>
              {t('navigation.settings.animations.description')}
            </SettingsDescription>
          </SettingLabel>
          <ToggleContainer>
            <ToggleSwitch
              name='settings-animations'
              id='settings-animations'
              checked={cqAnimationsInitialStatus}
              value={cqAnimationsInitialStatus}
              onChange={debounceAnimationsToggle}
              statusLabel
            />
          </ToggleContainer>
        </SettingsItem>
        <SettingsItem>
          <SettingLabel>
            {t('navigation.settings.theme.label')}
            <SettingsDescription>
              {t('navigation.settings.theme.description')}
            </SettingsDescription>
          </SettingLabel>
          <ToggleContainer>
            <ToggleSwitch
              name='settings-theme'
              id='settings-theme'
              checked={isDarkTheme}
              value={isDarkTheme}
              onChange={debounceThemeToggle}
              statusLabel
            />
          </ToggleContainer>
        </SettingsItem>
      </Menu>
    </Container>
  )
}

export default SettingsDropdown

const Container = styled.div`
  margin: 0 4px;
  position: relative;
`

const Menu = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 100%;
  right: 0;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.mainShadow};
  border: 1px solid ${props => props.theme.colors.main.grey300};
  min-width: 385px;
  animation: fadeIn 300ms ease-out;
  padding: 16px;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .top-label {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const SettingsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SettingLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`

const SettingsDescription = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.main.grey600};
`

const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.main.text};
  :hover {
    color: ${({ theme }) => theme.colors.main.primary500}!important;
    background-color: ${({ theme }) => theme.colors.main.grey300}!important;
  }
`

const ToggleContainer = styled.div`
  display: flex;
  justify-content: end;
`

import React from 'react'
import styled from 'styled-components'
import FlashcardsProficiencyDrawers from 'modules/flashcards/components/FlashcardsProficiencyDrawers'

const ColumnLeftFlashcardsStudy = () => {
  return (
    <LeftColumnWrapper>
      <FlashcardsProficiencyDrawers />
    </LeftColumnWrapper>
  )
}

export default ColumnLeftFlashcardsStudy

const LeftColumnWrapper = styled.div`
  min-width: 244px;
  max-width: 244px;
  border-radius: 8px;
  height: 572px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  padding: 10px 15px;
`

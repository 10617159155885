import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DotMenuIcon, StarIcon, Tooltip } from 'examkrackers-components'
import qs from 'qs'
import PATHS from 'utils/paths'

import useOutsideClick from 'hooks/useOutsideClick'
import { isNotNilOrEmpty } from 'utils/ramda'

import ModalDeleteCustomBox from './ModalDeleteCustomBox'
import { propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import {
  shuffleFlashcards,
  getFlashcardsPLevelStats
} from '../../../services/FlashcardsService'
import FlashcardsBoxTooltipContents from './FlashcardsBoxTooltipContents'
import { findLowestLevelWithFlashcards } from '../../../utils/flashcards'

interface IFlashcardsFilterCustomBoxProps {
  name: string | JSX.Element
  color?: string
  customBox: any
  filter: string
  filterId: string
}

export const FlashcardsFilterCustomBox = ({
  name,
  color = '',
  customBox,
  filter,
  filterId
}: IFlashcardsFilterCustomBoxProps): JSX.Element => {
  const {
    push,
    location: { pathname }
  } = useHistory()
  const { t } = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false)
  const containerRef = useRef(null)
  const count = Number(propOr('0', 'flashcard_amount', customBox))
  const customBoxId = propOr('', 'id', customBox)

  const handleToggle = () => setOpen(prevState => !prevState)
  const handleClose = () => setOpen(false)

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true)
    handleClose()
  }
  const handleCloseDeleteModal = () => setDeleteModalOpen(false)

  const redirectToList = () => {
    const newQuery = {
      limit: { take: 24, page: 1 },
      filter: {
        custom_box_id: customBoxId
      }
    }

    return push(`${PATHS.flashcards}?${qs.stringify(newQuery)}`)
  }

  const redirectToStudyView = plvl => {
    const query = qs.stringify(
      {
        filter: {
          custom_box_id: customBoxId,
          'f.proficiency_level': plvl
        },
        limit: {
          take: 1,
          page: 1
        }
      },
      {
        encode: false,
        addQueryPrefix: true
      }
    )

    const handleSuccess = () => {
      push(`${PATHS.flashcardsStudy}${query}`, {
        from: pathname
      })
    }
    const handleError = e => console.error(e)

    shuffleFlashcards().then(handleSuccess).catch(handleError)
  }

  const getPlvlsAndRedirectToStudyView = () => {
    const handleSuccess = response => {
      const { data } = response
      redirectToStudyView(findLowestLevelWithFlashcards(data))
    }
    const handleError = e => console.error(e)

    // @ts-ignore
    getFlashcardsPLevelStats({ filter: filter, filter_id: filterId })
      .then(handleSuccess)
      .catch(handleError)
  }

  useOutsideClick(containerRef, handleClose)

  const pages = count =>
    [
      <Page1 key={`${name}-page-1`} />,
      <Page2 key={`${name}-page-2`} />,
      <Page3 key={`${name}-page-3`} />
    ].slice(0, count)

  const [pLevelStats, setPLevelStats] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const fetchAllPLevelStats = () => {
    setIsLoading(true)
    const handleSuccess = response => {
      setPLevelStats(response.data)
      setIsLoading(false)
    }

    const handleError = e => {
      console.error(e)
      setIsLoading(false)
    }
    // @ts-ignore
    getFlashcardsPLevelStats({ filter: filter, filter_id: filterId })
      .then(handleSuccess)
      .catch(handleError)
  }

  const handlePLevelTooltip = () => {
    fetchAllPLevelStats()
  }

  return (
    <Container color={color}>
      <Title>{name}</Title>
      <Tooltip
        tooltipContent={
          <FlashcardsBoxTooltipContents
            isLoading={isLoading}
            pLevelStats={pLevelStats}
          />
        }
        id={`plvl-${filter}-${filterId}`}
        place='bottom'
        afterShow={handlePLevelTooltip}
      >
        <Wrapper onClick={getPlvlsAndRedirectToStudyView}>
          <Back color={color} />
          <SideL color={color} />
          <SideR color={color} />
          <Front color={color}>
            <StarIcon />
          </Front>
          {pages(count)}
        </Wrapper>
      </Tooltip>
      <Footer>
        <Count>({count})</Count>
        <Actions ref={containerRef}>
          <DropdownTrigger onClick={handleToggle}>
            <DotMenuIcon />
          </DropdownTrigger>
          <Menu open={open}>
            {count > 0 && (
              <>
                <MenuItem onClick={redirectToList}>
                  <span>{t('flashcards.boxActions.view')}</span>
                </MenuItem>
              </>
            )}
            <MenuItem onClick={handleOpenDeleteModal}>
              <span>{t('flashcards.boxActions.remove')}</span>
            </MenuItem>
          </Menu>
        </Actions>
      </Footer>
      <ModalDeleteCustomBox
        open={deleteModalOpen}
        handleClose={handleCloseDeleteModal}
        box={customBox}
      />
    </Container>
  )
}

export default FlashcardsFilterCustomBox

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: ${props => props.theme.colors.backgrounds.main};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  border-radius: ${({ theme }) => theme.shape.borderRadiusBig};
  padding: 8px;
  max-width: 78px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 11px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`

export const Count = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
  font-size: 11px;
  line-height: 16px;
`

export const Actions = styled.div`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`

const Wrapper = styled.div`
  min-width: 60px;
  min-height: 44px;
  position: relative;
  cursor: pointer;
`

const Back = styled.div`
  position: absolute;
  width: 48px;
  height: 28px;
  background: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].background
      : theme.colors.flashcards.filters.default.background};
  color: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].border
      : theme.colors.flashcards.filters.default.border};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  border-radius: 4px;
  z-index: 0;
  top: 0;
  right: 0;
`

const SideL = styled.div`
  background: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].background
      : theme.colors.flashcards.filters.default.background};
  color: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].border
      : theme.colors.flashcards.filters.default.border};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  position: absolute;
  width: 21px;
  height: 11px;
  left: -3px;
  top: 17px;
  transform: rotate(90deg) skew(54deg, 0deg);
  z-index: 0;
`

const SideR = styled.div`
  background: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].background
      : theme.colors.flashcards.filters.default.background};
  color: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].border
      : theme.colors.flashcards.filters.default.border};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  position: absolute;
  width: 21px;
  height: 13px;
  left: 43px;
  top: 17px;
  transform: rotate(90deg) skew(54deg, 0deg);
  z-index: 5;
`

const Front = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 28px;
  background: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].background
      : theme.colors.flashcards.filters.default.background};
  color: ${({ theme, color }) =>
    isNotNilOrEmpty(color)
      ? theme.colors.flashcards.filters[color].border
      : theme.colors.flashcards.filters.default.border};
  border: 1px solid
    ${({ theme, color }) =>
      isNotNilOrEmpty(color)
        ? theme.colors.flashcards.filters[color].border
        : theme.colors.flashcards.filters.default.border};
  border-radius: 4px;
  z-index: 4;
  bottom: 0;
  left: 0;
  font-weight: bold;
  font-size: 20px;
`

const DropdownTrigger = styled.div``

const Menu = styled.div`
  background: ${({ theme }) => theme.colors.selects.option.background};
  border-radius: ${({ theme }) => theme.shape.borderRadiusSmall};
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  box-shadow: ${({ theme }) => theme.shadows.darkShadow} !important;
  max-height: ${({ open }) => (open ? '300px' : '0')};
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  cursor: pointer;
  z-index: ${({ theme }) => theme.zIndex.menu};
  transition: opacity 700ms ${({ theme }) => theme.transitions.easing.easeInOut};
  min-width: 55px;
  width: fit-content;
  overflow-y: auto;
  border-radius: 4px;
`

const MenuItem = styled.div`
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.selects.option.font};
  background-color: ${({ theme }) => theme.colors.selects.option.background};
  line-height: 16px;
  font-size: 11px;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.colors.selects.option.backgroundActive};
  }
`

const Page1 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 3;
  left: 3px;
  bottom: 4px;
  border-radius: 4px;
`

const Page2 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 2;
  left: 6px;
  bottom: 8px;
  border-radius: 4px;
`

const Page3 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 1;
  left: 9px;
  bottom: 12px;
  border-radius: 4px;
`

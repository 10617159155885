import { mapObjIndexed, propOr, map, pathOr, propEq, all, pipe } from 'ramda'
import { Action, CourseTopicsReducer } from 'types'
import { API_STATES, transformById } from 'utils/redux'
import {
  fetchCourseTopicsRoutine,
  resetCourseTopicsListRoutine,
  confirmCourseTopicRoutine,
  uncheckCourseTopicRoutine,
  unmarkTopicCheckboxRoutine,
  markTopicCheckboxRoutine,
  fetchContentTopicsRoutine,
  searchCourseTopicsListRoutine,
  resetSearchCourseTopicsListRoutine,
  loadPrevPageRoutine
} from 'modules/courseTopics/ducks/actions'
import { IBookContentCourseTopic, ICourseTopic } from 'types/courseTopics'

export const initialState: CourseTopicsReducer = {
  state: API_STATES.PRISTINE,
  searchedTopic: {},
  topics: {},
  pagination: {
    page: 1,
    take: 10,
    recordsTotal: 0,
    pagesTotal: 1
  }
}

const updateBookContentCourseTopic = (
  payload: { data: IBookContentCourseTopic },
  state: CourseTopicsReducer
) => {
  const { data } = payload
  const bookContentCourseTopicId: string = propOr('', 'id', data)
  const topicId: string = propOr('', 'student_course_topic_id', data)
  // @ts-ignore
  const topic: ICourseTopic = pathOr({}, ['topics', topicId], state)
  const bookContentCourseTopics: IBookContentCourseTopic[] = propOr(
    [],
    'bookContentCourseTopics',
    topic
  )

  const updatedBookContentCourseTopics = pipe(
    map((contentCourseTopic: IBookContentCourseTopic) =>
      contentCourseTopic.id === bookContentCourseTopicId
        ? data
        : contentCourseTopic
    )
    // @ts-ignore
  )(bookContentCourseTopics)

  // @ts-ignore
  const isTopicMastered = all(
    // @ts-ignore
    propEq('is_read', true),
    updatedBookContentCourseTopics
  )

  const updatedTopic = {
    ...topic,
    is_mastered: isTopicMastered,
    bookContentCourseTopics: updatedBookContentCourseTopics
  }

  return {
    [topicId]: updatedTopic
  }
}

export default (state = initialState, action: Action): CourseTopicsReducer => {
  switch (action.type) {
    case loadPrevPageRoutine.SUCCESS:
      return {
        ...state,
        searchedTopic: {},
        topics: {
          ...state.topics,
          ...transformById('id')(action.payload.data.data)
        }
      }
    case searchCourseTopicsListRoutine.SUCCESS:
      return {
        ...state,
        searchedTopic: action.payload.searchedTopic,
        topics: transformById('id')(action.payload.data.data),
        pagination: mapObjIndexed(
          value => Number(value),
          action.payload.data.meta
        )
      }
    case resetSearchCourseTopicsListRoutine.SUCCESS:
      return {
        ...state,
        searchedTopic: {}
      }
    case resetCourseTopicsListRoutine.SUCCESS:
      return {
        ...state,
        topics: {},
        state: API_STATES.PRISTINE,
        pagination: {
          page: 1,
          take: 10,
          recordsTotal: 0,
          pagesTotal: 1
        }
      }
    case fetchContentTopicsRoutine.SUCCESS:
      return {
        ...state,
        topics: {
          ...state.topics,
          ...transformById('id')(action.payload.data)
        }
      }
    case fetchCourseTopicsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        topics: {
          ...state.topics,
          ...transformById('id')(action.payload.data)
        },
        pagination: mapObjIndexed(value => Number(value), action.payload.meta)
      }
    case confirmCourseTopicRoutine.SUCCESS:
    case uncheckCourseTopicRoutine.SUCCESS:
      return {
        ...state,
        topics: {
          ...state.topics,
          [action.payload.data.id]: action.payload.data
        }
      }
    case unmarkTopicCheckboxRoutine.SUCCESS:
    case markTopicCheckboxRoutine.SUCCESS:
      return {
        ...state,
        topics: {
          ...state.topics,
          ...updateBookContentCourseTopic(action.payload, state)
        }
      }
    default:
      return state
  }
}

import APIService from 'services/APIService'

const api = APIService()

export const fetchTop10 = period =>
  api.get(
    `/leaderboard?limit[take]=10&limit[page]=1&order[by]=balance${period}&order[dir]=desc&order[nullsLast]=true`
  )

export const fetchSBPercentileRank = () =>
  api.get('/leaderboard/percentile-rank')

export const fetchSBByCategory = () =>
  api.get('/leaderboard/salty-bucks-categories-chart')

interface EarningsPayload {
  query: string
}

export const fetchEarningsForLeaderboard = (payload: EarningsPayload) =>
  api.get(`students/salty-bucks-logs?${payload.query}`)

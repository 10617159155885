import * as Reselect from 'reselect'
import { propOr } from 'ramda'

export const selectNotes = state => state.notes

export const selectNotesList: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectNotes, propOr([], 'notes'))

export const getNotesPagination: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(
  selectNotes,
  propOr(
    {
      page: 1,
      take: 10,
      recordsTotal: 0,
      pagesTotal: 1
    },
    'pagination'
  )
)

import React from 'react'
import styled, { css } from 'styled-components'
import { getNumberOfPagesInProficiencyLevelDrawer } from 'utils/flashcards'
import { getSkippedCount } from 'modules/flashcards/ducks/selectors'
import { pathOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/flashcards/utils/events'
import { useSelector } from 'react-redux'

const FlashcardsDrawer = ({ drawer, id }) => {
  const pagesCount = getNumberOfPagesInProficiencyLevelDrawer(drawer.count)
  const {
    location: { search, pathname },
    replace
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const hasFlashcards = drawer.count > 0
  const skippedLength: number = useSelector(getSkippedCount)
  const skippedCount = getNumberOfPagesInProficiencyLevelDrawer(skippedLength)

  const currentProficiencyLevel = pathOr(
    null,
    ['filter', 'f.proficiency_level'],
    parsedQuery
  )

  const pages = count =>
    [
      <Page1 key={`${drawer.label}-page-1`} />,
      <Page2 key={`${drawer.label}-page-2`} />,
      <Page3 key={`${drawer.label}-page-3`} />
    ].slice(0, count)

  const isActive = Number(currentProficiencyLevel) === Number(drawer.level)

  const handleProfLevelChange = () => {
    if (hasFlashcards) {
      eventEmitter.emit(events.changePlvlManually, drawer.level)
      const query = qs.stringify(
        {
          limit: {
            take: 1,
            page: 1
          },
          filter: {
            ...parsedQuery.filter,
            'f.proficiency_level': drawer.level
          }
        },
        { addQueryPrefix: true }
      )
      replace(`${pathname}${query}`)
    }
  }

  return (
    <ItemWrapper
      isActive={isActive}
      onClick={handleProfLevelChange}
      hasFlashcards={hasFlashcards}
    >
      <LevelLabel isActive={isActive}>{drawer.label}</LevelLabel>
      <Wrapper id={id}>
        <Back />
        <SideL />
        <SideR />
        <Front>{drawer.count}</Front>
        {pagesCount > 0 && pages(pagesCount).map(page => page)}
      </Wrapper>
      {isActive && (
        <SkippedContainer>
          <Wrapper id={`skipped-${id}`}>
            <Back />
            <SideL />
            <SideR />
            <SkippedLabelContainer>
              <div className='label'>Skipped</div>
              <div className='value'>{skippedLength.toString()}</div>
            </SkippedLabelContainer>
            {skippedCount > 0 && pages(skippedCount).map(page => page)}
          </Wrapper>
        </SkippedContainer>
      )}
    </ItemWrapper>
  )
}

export default FlashcardsDrawer

const SkippedContainer = styled.div`
  position: absolute;
  right: -60px;
`

const SkippedLabelContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 28px;
  background: ${({ theme }) => theme.colors.flashcards.boxes.background};
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  color: ${({ theme }) => theme.colors.flashcards.boxes.font};
  border-radius: 4px;
  z-index: 4;
  bottom: 0;
  left: 0;
  font-weight: bold;
  flex-direction: column;

  .label {
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    color: ${({ theme }) => theme.colors.main.primary800};
  }

  .value {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.1px;
  }
`

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 16px;
  cursor: ${({ hasFlashcards }) => (hasFlashcards ? 'pointer' : 'default')};
  padding-left: 22px;

  ${({ isActive }) =>
    isActive &&
    css`
      &::before {
        content: '';
        background: ${({ theme }) => theme.colors.flashcards.boxes.active};
        position: absolute;
        left: 0;
        right: -80px;
        border-radius: 8px;
        z-index: 0;
        height: 55px;
        max-width: 210px;
        margin: 0 auto;
      }
    `};
`

const LevelLabel = styled.div`
  position: relative;
  z-index: 1;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'default')};
`

const Wrapper = styled.div`
  width: 60px;
  height: 44px;
  position: relative;
`

const Front = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 28px;
  background: ${({ theme }) => theme.colors.flashcards.boxes.background};
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  color: ${({ theme }) => theme.colors.flashcards.boxes.font};
  border-radius: 4px;
  z-index: 4;
  bottom: 0;
  left: 0;
  font-weight: bold;
`

const Back = styled.div`
  position: absolute;
  width: 48px;
  height: 28px;
  background: ${({ theme }) => theme.colors.flashcards.boxes.background};
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  border-radius: 4px;
  z-index: 0;
  top: 0;
  right: 0;
`

const SideR = styled.div`
  background: ${({ theme }) => theme.colors.flashcards.boxes.background};
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  width: 21px;
  height: 13px;
  left: 43px;
  top: 17px;
  transform: rotate(90deg) skew(54deg, 0deg);
  z-index: 5;
`

const SideL = styled.div`
  background: ${({ theme }) => theme.colors.flashcards.boxes.background};
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  width: 21px;
  height: 11px;
  left: -3px;
  top: 17px;
  transform: rotate(90deg) skew(54deg, 0deg);
  z-index: 0;
`

const Page1 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 3;
  left: 3px;
  bottom: 4px;
  border-radius: 4px;
`

const Page2 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 2;
  left: 6px;
  bottom: 8px;
  border-radius: 4px;
`

const Page3 = styled.div`
  width: 46px;
  height: 28px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.flashcards.boxes.border};
  position: absolute;
  z-index: 1;
  left: 9px;
  bottom: 12px;
  border-radius: 4px;
`

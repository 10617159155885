import { createRoutine } from 'redux-saga-routines'
import * as Effects from '@redux-saga/core/effects'
import * as StudentService from 'services/StudentService'

// ROUTINES
export const studentFetchNavigationRoutine = createRoutine(
  'FETCH_STUDENT_NAVIGATION'
)

// ACTIONS

// Get student navigation data to build the student side navbar.
function* studentFetchNavigation() {
  try {
    const result = yield Effects.call(StudentService.fetchNavigation)
    yield Effects.put(studentFetchNavigationRoutine.success(result.data))
  } catch (e) {
    yield Effects.put(studentFetchNavigationRoutine.failure(e))
  }
}

// WATCHERS
export function* studentFetchNavigationWatcher() {
  yield Effects.takeLatest(
    studentFetchNavigationRoutine.TRIGGER,
    studentFetchNavigation
  )
}

// SAGAS
export const studentSagas = [Effects.fork(studentFetchNavigationWatcher)]

import React from 'react'
import styled from 'styled-components'
import { SaltyBucksChart } from 'modules/dashboard/components/SaltyBucksChart'
import LastEarnings from './LastEarnings'
import SaltyBucksByCategory from './SaltyBucksByCategory'

const LeaderboardRightColumn = () => {
  return (
    <Container>
      <InnerContainer className='sb-chart'>
        <SaltyBucksChart />
      </InnerContainer>
      <InnerContainer>
        <SaltyBucksByCategory />
      </InnerContainer>
      <InnerContainer>
        <LastEarnings />
      </InnerContainer>
    </Container>
  )
}

export default LeaderboardRightColumn

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 582px;
  /* background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow}; */
  border-radius: 6px;
  position: relative;
`

const InnerContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  position: relative;
  overflow: hidden;
`

import React, { useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  createCalendarRows,
  generateCalendar,
  getCalendarMonthCaption,
  getMonthName,
  getMonthNumberByName,
  getStartDayOfMonth,
  weekdayNames
} from 'utils/calendar'

import {
  ArrowUpFilledIcon,
  BouncingLoader,
  IconButton
} from 'examkrackers-components'
import { getVisibleDates } from './ducks/selectors'

import LeftColumnCalendarRow from './LeftColumnCalendarRow'
import { useSelector } from 'react-redux'
import { getUniversalDate } from 'utils/date'
import { CalendarContext } from 'hooks/CalendarContext'

const LeftColumnCalendar = () => {
  const { calendar } = useContext(CalendarContext)
  const [isLoading, _] = useState(false)
  const visibleDates = useSelector(getVisibleDates)

  const calendarDays = calendar?.calendarDays || []
  const startDate = calendar?.start_at || ''

  const [calendarRange, setCalendarRange] = useState({
    month: getMonthName(getUniversalDate(new Date()).getMonth() + 2), // Adding 2 months to offset the generating function to show the current month initially
    year: getUniversalDate(startDate).getFullYear()
  })

  const getFirstLetterOfDay = (day: string) => day.charAt(0)

  const handleChangeCalendarRange = operation => {
    const generateMoreMonthsBeforeExistingRange = operation === 'prev'
    const generateMoreMonthsAfterExistingRange = operation === 'next'

    if (generateMoreMonthsBeforeExistingRange) {
      setCalendarRange({
        month:
          calendarRange.month === 'January'
            ? 'December'
            : getMonthName(
                parseInt(
                  getMonthNumberByName(calendarRange.month.toLowerCase())
                ) - 1
              ),
        year:
          calendarRange.month === 'January'
            ? calendarRange.year - 1
            : calendarRange.year
      })
    } else if (generateMoreMonthsAfterExistingRange) {
      setCalendarRange({
        month:
          calendarRange.month === 'December'
            ? 'January'
            : getMonthName(
                parseInt(
                  getMonthNumberByName(calendarRange.month.toLowerCase())
                ) + 1
              ),
        year:
          calendarRange.month === 'December'
            ? calendarRange.year + 1
            : calendarRange.year
      })
    }
  }

  const updateVisibleMonth = () => {
    const visibleMonthNumber =
      new Date((visibleDates as Date[])[10]).getMonth() + 1
    const visibleYear = new Date((visibleDates as Date[])[10]).getFullYear()

    return setCalendarRange({
      month: getMonthName(visibleMonthNumber),
      year: visibleYear
    })
  }

  const scrollToSelectedMonth = month => {
    const monthNumber = getMonthNumberByName(month)
    const selectedMonth = document.querySelectorAll(
      `[data-id*='${monthNumber.padStart(2, '0')}-${calendarRange.year}-row']`
    )[0]

    if (selectedMonth) {
      selectedMonth.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    updateVisibleMonth()
  }, [visibleDates])

  if (isLoading) {
    return (
      <LoaderContainer data-testid='LeftColumnCalendar-LoaderContainer'>
        <BouncingLoader data-testid='LeftColumnCalendar-BouncingLoader' />
      </LoaderContainer>
    )
  }

  return (
    <LeftColumnCalendarWrapper data-testid='LeftColumnCalendar-LeftColumnCalendarWrapper'>
      <CalendarContainer data-testid='LeftColumnCalendar-CalendarContainer'>
        {calendarDays &&
          Object.entries(
            generateCalendar(
              calendarRange.year,
              calendarRange.month.toLowerCase()
            )
          ).map(([year, months], calendarIndex) =>
            Object.entries(months).map(([month, monthDates], index) => (
              <Table
                key={`${month}-${year}`}
                data-testid={`LeftColumnCalendar-Table-${month}-${year}`}
              >
                <thead>
                  <Caption data-testid='LeftColumnCalendar-Caption'>
                    <td>
                      {index === 0 && calendarIndex === 0 ? (
                        <FirstMonthCaption data-testid='LeftColumnCalendar-FirstMonthCation'>
                          <ArrowLeft data-testid='LeftColumnCalendar-ArrowLeft'>
                            <IconButton
                              data-testid='LeftColumnCalendar-IconButton'
                              icon={<ArrowUpFilledIcon />}
                              onClick={() => handleChangeCalendarRange('prev')}
                            />
                          </ArrowLeft>
                          <button onClick={() => scrollToSelectedMonth(month)}>
                            {getCalendarMonthCaption(month, year)}
                          </button>
                          <ArrowRight data-testid='LeftColumnCalendar-ArrowRight'>
                            <IconButton
                              data-testid='LeftColumnCalendar-IconButton'
                              icon={<ArrowUpFilledIcon />}
                              onClick={() => handleChangeCalendarRange('next')}
                            />
                          </ArrowRight>
                        </FirstMonthCaption>
                      ) : (
                        <button onClick={() => scrollToSelectedMonth(month)}>
                          {getCalendarMonthCaption(month, year)}
                        </button>
                      )}
                    </td>
                  </Caption>
                  <tr>
                    {weekdayNames.map(day => (
                      <TableHeader
                        key={day}
                        data-testid={`LeftColumnCalendar-TableHeader-day-${day}`}
                      >
                        {getFirstLetterOfDay(day)}
                      </TableHeader>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createCalendarRows(
                    monthDates as string[],
                    getStartDayOfMonth(Number(year), month)
                  ).map((row, rowIndex) => {
                    return (
                      <LeftColumnCalendarRow
                        data-testid={`LeftColumnCalendar-LeftColumnCalendarRow-${year}-${month}-row-${rowIndex}`}
                        key={`${year}-${month}-row-${rowIndex}`}
                        row={row}
                        rowIndex={rowIndex}
                        year={year}
                        month={month}
                      />
                    )
                  })}
                </tbody>
              </Table>
            ))
          )}
      </CalendarContainer>
    </LeftColumnCalendarWrapper>
  )
}

export default LeftColumnCalendar

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LeftColumnCalendarWrapper = styled.div`
  position: relative;
  width: 207px;
  flex: 0 0 207px;
  height: calc(100vh - 158px);
  /* overflow-y: scroll; */
  overflow: hidden;

  background-color: #fff;
  padding: 0 4px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.19);
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`

const Table = styled.table`
  background-color: #fff;

  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }

  td,
  th {
    text-align: left;
  }

  width: 100%;
  table-layout: fixed;
  transition: all 0.3s ease;
  tbody {
    animation: ${fadeIn} 0.5s ease;
  }
`

const Caption = styled.tr`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  /* padding: 16px 0; */
  height: 15px;
  width: 198px;
  margin-top: 12px;
  background-color: ${({ theme }) => theme.colors.main.primary200};

  button {
    background-color: transparent;
    border: none;
    font-weight: bold;
    cursor: pointer;
  }
`

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  position: sticky;
  bottom: 0;
  font-size: 10px;
`

const ArrowLeft = styled.div`
  transform: rotate(-90deg);
`

const ArrowRight = styled.div`
  transform: rotate(90deg);
`

const FirstMonthCaption = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  width: 155px;

  svg {
    color: #7e441e;
    font-size: 10px;
  }

  p {
    text-align: center;
  }
`

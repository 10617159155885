import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Prompt } from 'react-router-dom'
import { pipe, propOr, pathOr, multiply } from 'ramda'

import {
  getSectionDetailsById,
  getSectionIdByCurrentPageId,
  getSectionsIdsWithCurrentTime
} from 'modules/exam/ducks/selectors'

import { getSavedTimeOrDefault } from 'modules/exam/utils/examTimeConfig'
import { saveExamState } from 'services/ExamsService'

import { RootReducer } from 'types'
import { getIsPreviewAdmin } from 'modules/auth/ducks/selectors'

export const AbandonBreakHandler = (props): JSX.Element => {
  const params = useParams()
  const id: string = propOr('', 'id')(params)
  const [isLeaving, setIsLeaving] = useState(false)
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)

  const onbeforeunloadFn = () => setIsLeaving(true)

  const { currentPageConfig } = props

  const nextSectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, currentPageConfig.nextPageId)
  )
  const sectionIdsWithCurrentTime = useSelector(getSectionsIdsWithCurrentTime)
  const sectionIdByCurrentPageId: string = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, currentPageConfig.nextPageId)
  )

  const nextSectionSeconds = pipe(
    pathOr(0, ['sectionLength', 'sectionMinutes']),
    multiply(60)
  )(nextSectionDetails)

  const saveExam = async (callback = () => {}) => {
    const payload = {
      id,
      data: {
        section_id: sectionIdByCurrentPageId,
        exam_seconds_left: getSavedTimeOrDefault(
          sectionIdsWithCurrentTime,
          currentPageConfig.nextPageId,
          nextSectionSeconds
        ),
        current_page: currentPageConfig.nextPageId
      }
    }

    const handleError = e => console.error(e)
    saveExamState(payload).then(callback).catch(handleError)
  }

  const handlePrompt = (location, action) => {
    if (action === 'POP') saveExam()
    return 'Are you sure you want to leave the exam?'
  }

  useEffect(() => {
    if (isLeaving && !isPreviewAdmin) {
      saveExam()
    }
    if (isLeaving && isPreviewAdmin) {
      window.location.href = `${process.env.REACT_APP_ADMIN_URL}/exams`
    }
  }, [isLeaving, isPreviewAdmin])

  useEffect(() => {
    if (!isPreviewAdmin) {
      window.onbeforeunload = () => true
    }

    window.addEventListener('beforeunload', onbeforeunloadFn)
    return () => {
      window.onbeforeunload = null
      window.removeEventListener('beforeunload', onbeforeunloadFn)
    }
  }, [])

  return <div>{!isPreviewAdmin && <Prompt message={handlePrompt} />}</div>
}

export default AbandonBreakHandler

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useWindowDimensions } from 'hooks/useWindowDimensions'

import { Modal, Button } from 'examkrackers-components'

const ModalNarrowScreen = () => {
  const { width } = useWindowDimensions()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (width < 1120) {
      setOpen(true)
    }
  }, [])

  const { t } = useTranslation()
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      title={t('narrowScreenModal.title')}
      open={open}
      handleClose={handleClose}
    >
      <StyledDiv>
        {t('narrowScreenModal.description')}
        <ButtonContainer>
          <ModalButton id='close-modal' color='secondary' onClick={handleClose}>
            {t('narrowScreenModal.button')}
          </ModalButton>
        </ButtonContainer>
      </StyledDiv>
    </Modal>
  )
}

export default ModalNarrowScreen

const ButtonContainer = styled.div`
  margin: 40px 0 0;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  button {
    min-width: 160px;
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;
`

const ModalButton = styled(Button)`
  width: 159px;
  height: 32px;
`

import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import * as R from 'ramda'
import { ScoreProjectionDataEntity } from 'types'
import { organiseTableScoreProjectionData } from 'utils/diagnostics'

import { getScoreProjectionData } from 'modules/diagnostics/ducks/selectors'

import ModalChangeTargetScore from 'modules/examTypes/components/ModalChangeTargetScore'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableHeader
} from 'examkrackers-components'

// Score Projection Table Component
export const TableScoreProjection = (): JSX.Element => {
  const data: ScoreProjectionDataEntity[] = useSelector(getScoreProjectionData)

  const targetScores = R.pipe(
    // @ts-ignore
    R.map(t => ({ ...t, order: t.order || 999 })),
    R.sortBy(R.prop('order')),
    R.map(
      R.pipe(
        R.propOr('', 'scores'),
        R.find(R.has('pts')),
        R.propOr(0, 'target_score')
      )
    ),
    // @ts-ignore
    R.addIndex(R.map)((e, i) => ({ target_score: e, order: i + 1 }))
    // @ts-ignore
  )(data)

  const sectionHeaders = R.pipe(
    // @ts-ignore
    R.map(R.applySpec({ name: R.prop('name'), order: R.propOr(999, 'order') })),
    // @ts-ignore
    R.sortBy(R.prop('order')),
    // @ts-ignore
    R.map(R.prop('name')),
    // @ts-ignore
    R.prepend('')
    // @ts-ignore
  )(data)

  // @ts-ignore
  const headers = sectionHeaders.map(
    // @ts-ignore
    (sectionName: string) => (
      <TableHeader
        id={`table-score-projection-section-${sectionName}`}
        key={`table-score-projection-section-${sectionName}`}
        sticky={false}
        columnId={sectionName}
        sortable={false}
      >
        {sectionName}
      </TableHeader>
    )
  )

  const organiseRowsData = organiseTableScoreProjectionData(data)

  const dataRows = organiseRowsData.map((row, index) => (
    <TableRow key={`score-data-table-row-${index}`}>
      {row.map((cell, cellIndex) => (
        <React.Fragment key={`score-data-table-row-${index}-cell-${cellIndex}`}>
          {cellIndex === 0 && <TableCell>{cell.title}</TableCell>}
          <TableCell>
            {cell.scaled_score === 0 ? 'NA' : cell.scaled_score}
          </TableCell>
        </React.Fragment>
      ))}
    </TableRow>
  ))

  // Cells for the target score row
  const targetScoreCells = R.pipe(
    // @ts-ignore
    R.dropLast(1),
    R.map(section => {
      const sectionId: string = R.propOr('', 'order')(section)
      const targetScore: number = R.propOr(0, 'target_score')(section)
      return (
        <TableCell key={sectionId}>
          <div className='summary-cell'>
            <div className='summary-cell-text'>{targetScore}</div>
            <ModalChangeTargetScore
              sectionOrder={R.propOr('', 'order')(section)}
              initialValue={targetScore}
            />
          </div>
        </TableCell>
      )
    })
    // @ts-ignore
  )(targetScores)

  const totalTargetScore = R.pipe(
    R.last,
    R.propOr(0, 'target_score'),
    R.toString
    // @ts-ignore
  )(targetScores)

  return (
    <TableScoreProjectionContainer>
      <Table>
        <TableHead>
          <TableRow>{headers}</TableRow>
        </TableHead>
        <TableBody>
          {dataRows}
          <TableRow className='summary-row'>
            <TableCell>Target Score</TableCell>
            {targetScoreCells}
            <TableCell>
              <div className='summary-cell'>
                <div className='summary-cell-text'>{totalTargetScore}</div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableScoreProjectionContainer>
  )
}

const TableScoreProjectionContainer = styled.div`
  padding: 1rem 1.786rem 2.643rem 1.786rem;
  width: 100%;

  table {
    padding: 0;
    border-radius: 0;
    overflow: hidden;

    th {
      padding: 0.5263rem 0 1.0526rem 0;
      vertical-align: top;
    }

    td,
    th {
      width: 10%;
      font-size: 1rem;
      text-align: center;
      padding: 0.7368rem 0.5rem;
      border: 0 0 2px 0 solid ${({ theme }) => theme.colors.main.grey300};
      line-height: 27px !important;

      &:first-child {
        width: 30%;
        text-align: left;
        padding-left: 6px;
        white-space: nowrap;
      }

      &:last-child {
        font-weight: bold;
      }
    }

    th:last-child {
      text-transform: capitalize;
    }
  }

  .summary-row {
    td {
      background-color: ${({ theme }) => theme.colors.main.primary200};
    }

    td:last-child {
      .summary-cell-text {
        font-weight: bold;
        font-size: 1.2632rem;
      }
    }
  }

  .summary-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .summary-cell-text {
    margin-right: 3px;
    font-size: 1rem;
  }
`

export default TableScoreProjection

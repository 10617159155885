// This is for updating the up-to-date timers when the last question is unmounted
import * as R from 'ramda'
import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { SECTION_STATUSES } from 'modules/exam/utils/exam'

// This is for updating the up-to-date timers when the last question is unmounted
export const preparePayloadOnLastQuestionUnmount = ({
  passedTime,
  timers,
  passageOriginalId,
  questionOriginalId,
  isFalsePassage,
  questionIdOnPassageCheckbox,
  passedTimeOnPassageCheckbox,
  sectionStatus,
  timerCheckboxes
}) => {
  const isPassagePreviouslyStopped = R.pathOr(false, [
    'isPassageReadingTimeStopped',
    passageOriginalId
  ])(timerCheckboxes)
  const isQuestionMarkedAsToCalculate = R.pathOr(false, [
    'isQuestionWorkingTimeToCalculate',
    questionOriginalId
  ])(timerCheckboxes)

  const passageReading = pathOr(0, [passageOriginalId, 'reading'], timers)
  const passageWorking = pathOr(0, [passageOriginalId, 'working'], timers)
  const passageWorkingTemp = pathOr(
    0,
    [passageOriginalId, 'working_temp'],
    timers
  )
  const passageChecking = pathOr(0, [passageOriginalId, 'checking'], timers)

  const questionWorking = pathOr(0, [questionOriginalId, 'working'], timers)
  const questionWorkingTemp = pathOr(
    0,
    [questionOriginalId, 'working_temp'],
    timers
  )
  const questionChecking = pathOr(0, [questionOriginalId, 'checking'], timers)

  const passageReadingTime = isPassagePreviouslyStopped
    ? passageReading
    : passageReading + passedTime

  const isQuestionAlreadyTrackedInTimers = R.pipe(
    R.propOr('', questionOriginalId),
    isNotNilOrEmpty
  )(timers)

  const passageWorkingTimeOnUnmount = () => {
    switch (true) {
      // Add passed time on PHASE1 when page is last page of an exam or exam is about to pause
      case sectionStatus === SECTION_STATUSES.phase1:
        return passageWorking + passedTime
      default:
        return passageWorking
    }
  }

  const passageCheckingTimeOnUnmount = () => {
    switch (true) {
      // Add passed time on PHASE1 when page is last page of an exam or exam is about to pause
      case sectionStatus === SECTION_STATUSES.review:
        return passageChecking + passedTime
      default:
        return passageChecking
    }
  }

  const questionWorkingTimeOnUnmount = () => {
    switch (true) {
      // Passage Reading Time was not saved yet, question is not saved in timers, and question was not marked as "to calculate"
      case sectionStatus === SECTION_STATUSES.phase1 &&
        !isPassagePreviouslyStopped &&
        !isQuestionAlreadyTrackedInTimers &&
        !isQuestionMarkedAsToCalculate &&
        !isFalsePassage:
        return 0
      // Passage Reading time was saved on this specific question (manually checked by the student) & question is not saved in timers
      case sectionStatus === SECTION_STATUSES.phase1 &&
        isPassagePreviouslyStopped &&
        questionOriginalId === questionIdOnPassageCheckbox &&
        !isQuestionMarkedAsToCalculate:
        return questionWorking + passedTime - passedTimeOnPassageCheckbox
      // Passage Reading Time was saved before (but not on this question) & question is not marked as "to calculate"
      case sectionStatus === SECTION_STATUSES.phase1 &&
        isPassagePreviouslyStopped &&
        !isQuestionMarkedAsToCalculate:
      case sectionStatus === SECTION_STATUSES.phase1 && isFalsePassage:
        return questionWorking + passedTime
      default:
        return questionWorking
    }
  }

  const questionTempWorkingTimeOnUnmount = () => {
    switch (true) {
      // Passage Reading Time was previously saved on this question, question was marked as "to calculate" and student returns into this question
      case sectionStatus === SECTION_STATUSES.phase1 &&
        isPassagePreviouslyStopped &&
        isQuestionAlreadyTrackedInTimers &&
        isQuestionMarkedAsToCalculate:
        return questionWorkingTemp + passedTime
      default:
        return questionWorkingTemp
    }
  }

  const questionCheckingTimeOnUnmount = () => {
    switch (true) {
      // Add passed time on PHASE2 when page is last page of an exam or exam is about to pause
      case sectionStatus === SECTION_STATUSES.review:
        return questionChecking + passedTime
      default:
        return questionChecking
    }
  }

  const payload = {
    [passageOriginalId]: {
      resource_type: 'passage',
      reading: isFalsePassage ? 0 : passageReadingTime,
      working: passageWorkingTimeOnUnmount(),
      working_temp: passageWorkingTemp,
      checking: passageCheckingTimeOnUnmount()
    },
    [questionOriginalId]: {
      resource_type: 'question',
      reading: 0,
      working: questionWorkingTimeOnUnmount(),
      working_temp: questionTempWorkingTimeOnUnmount(),
      checking: questionCheckingTimeOnUnmount()
    }
  }

  return payload
}

import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Modal, Button } from 'examkrackers-components'

import { addToArchive } from 'services/FlashcardsService'
import { useDispatch } from 'react-redux'
import { showToastRoutine } from '../../toast/ducks/actions'
import { SEVERITY } from '../../../utils/toast'
import { updatePlvlStatsAfterArchiveRoutine } from '../ducks/actions'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/flashcards/utils/events'

import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const ModalAddCardToArchive = ({
  open,
  handleClose,
  flashcardId,
  proficiencyLevel,
  user,
  flashcard,
  currentCourse,
  flashcardBoxTag
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const showToast = React.useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const updatePlvlStatsAfterArchive = React.useCallback(
    payload => dispatch(updatePlvlStatsAfterArchiveRoutine(payload)),
    [dispatch]
  )

  const handleAddToArchive = e => {
    e.preventDefault()
    setIsLoading(true)

    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.flashcardArchived, {
      'Course type': currentCourse.type || false,
      'Course name': currentCourse.title || false,
      'Course expiration date': currentCourse?.accessible_to || false,
      'Flashcard Proficiency Level': flashcard?.proficiency_level || false,
      'Flashcard # id': flashcard?.id || false,
      'Flashcard box tag': flashcardBoxTag
    })

    const handleSuccess = () => {
      showToast({
        key: 'toast.addedToArchive',
        severity: SEVERITY.success,
        options: {}
      })
      updatePlvlStatsAfterArchive({
        values: { plvl: proficiencyLevel },
        callback: () => {
          setIsLoading(false)
          handleClose()
          eventEmitter.emit(events.flashcardArchived)
        }
      })
    }
    const handleError = () => {
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error,
        options: {}
      })
      setIsLoading(false)
    }

    addToArchive({ id: flashcardId }).then(handleSuccess).catch(handleError)
  }
  return (
    <Modal
      title={t('flashcards.archive.addModal.title')}
      open={open}
      handleClose={handleClose}
    >
      <>
        <div>{t('flashcards.archive.addModal.description')}</div>
        <ButtonsContainer>
          <Button
            size='small'
            color='secondary'
            id='delete-submit'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleAddToArchive}
          >
            {t('flashcards.archive.addModal.submitCTA')}
          </Button>
          <Button
            size='small'
            id='delete-cancel'
            disabled={isLoading}
            onClick={handleClose}
            type='button'
            color='tertiary'
            variant='contained'
            isLoading={isLoading}
          >
            {t('flashcards.archive.addModal.cancelCTA')}
          </Button>
        </ButtonsContainer>
      </>
    </Modal>
  )
}

export default ModalAddCardToArchive

const ButtonsContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  button {
    min-width: 154px;
  }
`

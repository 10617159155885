import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as R from 'ramda'

import { fetchSingleQuestionMetricsRoutine } from 'modules/diagnostics/ducks/actions'
import { getSingleQuestionMetrics } from 'modules/diagnostics/ducks/selectors'
import { RootReducer, SingleQuestionMetrics } from 'types'
import { getQuestion, getStepHtml } from 'modules/exam/ducks/selectors'
import { formatSecondsToMinutesAndSeconds } from 'utils/date'

import { Tooltip, Text } from 'examkrackers-components'

export const QuestionExplanationMetrics = ({ isOpen }): JSX.Element => {
  // Get URL parameters
  const params = useParams()

  // Get React-Redux dispatcher
  const dispatch = useDispatch()

  // Get i18n handler
  const { t } = useTranslation()

  const metrics: SingleQuestionMetrics = useSelector(getSingleQuestionMetrics)
  const questionId: string = R.propOr('', 'questionId', params)

  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, questionId)
  )

  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, questionId)
  )

  const selectedAnswer: string = R.propOr('', 'answer', questionState)
  const correctAnswer: string = R.propOr('', 'correct_answer', stepHtml)
  const isCorrect: boolean = selectedAnswer === correctAnswer
  const isSkipped: boolean = selectedAnswer === ''

  const fetchMetrics = React.useCallback(
    () => dispatch(fetchSingleQuestionMetricsRoutine({ id: questionId })),
    [dispatch, questionId]
  )

  React.useEffect(() => {
    fetchMetrics()
  }, [params])

  const tooltipContent = (
    title: string,
    you: number,
    target: number,
    average: number
  ) => {
    return (
      <div>
        <p>{title}</p>
        <div className='time-label'>
          <span>{t('diagnostics.graph.legend.you')}:</span>{' '}
          <span>{formatSecondsToMinutesAndSeconds(you)}</span>
        </div>
        <div className='time-label'>
          <span>{t('diagnostics.graph.legend.target')}:</span>{' '}
          <span>{formatSecondsToMinutesAndSeconds(target)}</span>
        </div>
        <div className='time-label'>
          <span>{t('diagnostics.graph.legend.average')}:</span>{' '}
          <span>{formatSecondsToMinutesAndSeconds(average)}</span>
        </div>
      </div>
    )
  }

  const passageReadingYou: number = R.pathOr(
    0,
    ['studentsPassageMetrics', 'timers', 'reading'],
    metrics
  )

  const passageReadingTarget: number = R.pathOr(
    0,
    ['passageMetrics', 'timers', 'reading_avg'],
    metrics
  )

  const passageReadingAverage: number = R.pathOr(
    0,
    ['passageMetricsAvg', 'timers', 'reading_avg'],
    metrics
  )

  const passageReadingMax: number = Math.max(
    R.pathOr(0, ['studentsPassageMetrics', 'max_passage_reading'], metrics),
    R.pathOr(0, ['passageMetrics', 'max_reading_avg'], metrics),
    R.pathOr(0, ['passageMetricsAvg', 'max_reading_avg'], metrics)
  )

  const passageWorkingYou: number = R.pathOr(
    0,
    ['studentsPassageMetrics', 'timers', 'working'],
    metrics
  )

  const passageWorkingTarget: number = R.pathOr(
    0,
    ['passageMetrics', 'timers', 'working_avg'],
    metrics
  )

  const passageWorkingAverage: number = R.pathOr(
    0,
    ['passageMetricsAvg', 'timers', 'working_avg'],
    metrics
  )

  const passageWorkingMax: number = Math.max(
    R.pathOr(0, ['studentsPassageMetrics', 'max_passage_working'], metrics),
    R.pathOr(0, ['passageMetrics', 'max_working_avg'], metrics),
    R.pathOr(0, ['passageMetricsAvg', 'max_working_avg'], metrics)
  )

  const passageCheckingYou: number = R.pathOr(
    0,
    ['studentsPassageMetrics', 'timers', 'checking'],
    metrics
  )

  const passageCheckingTarget: number = R.pathOr(
    0,
    ['passageMetrics', 'timers', 'checking_avg'],
    metrics
  )

  const passageCheckingAverage: number = R.pathOr(
    0,
    ['passageMetricsAvg', 'timers', 'checking_avg'],
    metrics
  )

  const passageCheckingMax: number = Math.max(
    R.pathOr(0, ['studentsPassageMetrics', 'max_passage_checking'], metrics),
    R.pathOr(0, ['passageMetrics', 'max_checking_avg'], metrics),
    R.pathOr(0, ['passageMetricsAvg', 'max_checking_avg'], metrics)
  )

  const questionWorkingYou: number = R.pathOr(
    0,
    ['studentsQuestionMetrics', 'timers', 'working'],
    metrics
  )

  const questionWorkingTarget: number = R.pathOr(
    0,
    ['questionMetrics', 'timers', 'working_avg'],
    metrics
  )

  const questionWorkingAverage: number = R.pathOr(
    0,
    ['questionMetricsAvg', 'timers', 'working_avg'],
    metrics
  )

  const questionWorkingMax: number = Math.max(
    R.pathOr(0, ['studentsQuestionMetrics', 'max_question_working'], metrics),
    R.pathOr(0, ['questionMetrics', 'max_working_avg'], metrics),
    R.pathOr(0, ['questionMetricsAvg', 'max_working_avg'], metrics)
  )

  const questionCheckingYou: number = R.pathOr(
    0,
    ['studentsQuestionMetrics', 'timers', 'checking'],
    metrics
  )

  const questionCheckingTarget: number = R.pathOr(
    0,
    ['questionMetrics', 'timers', 'checking_avg'],
    metrics
  )

  const questionCheckingAverage: number = R.pathOr(
    0,
    ['questionMetricsAvg', 'timers', 'checking_avg'],
    metrics
  )

  const questionCheckingMax: number = Math.max(
    R.pathOr(0, ['studentsQuestionMetrics', 'max_question_checking'], metrics),
    R.pathOr(0, ['questionMetrics', 'max_checking_avg'], metrics),
    R.pathOr(0, ['questionMetricsAvg', 'max_checking_avg'], metrics)
  )

  return (
    <Container isOpen={isOpen}>
      <ContainerInner>
        <SaltyPeaky>
          <img
            src='/assets/illustrations/SaltyExplanations.svg'
            alt='salty-explanations'
          />
        </SaltyPeaky>

        {/* Passage metrics */}
        <div className='passage-metrics_container'>
          <div className='passage-metrics'>
            <Tooltip
              id='tooltip-passage-reading'
              tooltipContent={tooltipContent(
                t('diagnostics.passageReadingTime'),
                passageReadingYou,
                passageReadingTarget,
                passageReadingAverage
              )}
            >
              <Legend
                title={t('diagnostics.passageReadingTime')}
                isCorrect={isCorrect}
                isPassage
                solidLineText={t('diagnostics.graph.legend.you')}
                solidLineWidth={`${
                  (passageReadingYou / passageReadingMax) * 100
                }%`}
                dashedLineText={t('diagnostics.graph.legend.target')}
                dashedLineWidth={`${
                  (passageReadingTarget / passageReadingMax) * 100
                }%`}
                dottedLineText={t('diagnostics.graph.legend.average')}
                dottedLineWidth={`${
                  (passageReadingAverage / passageReadingMax) * 100
                }%`}
              />
            </Tooltip>
          </div>
          <div className='passage-metrics'>
            <Tooltip
              id='tooltip-passage-working'
              tooltipContent={tooltipContent(
                t('diagnostics.passageWorkingTime'),
                passageWorkingYou,
                passageWorkingTarget,
                passageWorkingAverage
              )}
            >
              <Legend
                title={t('diagnostics.passageWorkingTime')}
                isCorrect={isCorrect}
                isPassage
                solidLineWidth={`${
                  (passageWorkingYou / passageWorkingMax) * 100
                }%`}
                dashedLineWidth={`${
                  (passageWorkingTarget / passageWorkingMax) * 100
                }%`}
                dottedLineWidth={`${
                  (passageWorkingAverage / passageWorkingMax) * 100
                }%`}
              />
            </Tooltip>
          </div>
          <div className='passage-metrics'>
            <Tooltip
              id='tooltip-passage-checking'
              tooltipContent={tooltipContent(
                t('diagnostics.passageCheckingTime'),
                passageCheckingYou,
                passageCheckingTarget,
                passageCheckingAverage
              )}
            >
              <Legend
                title={t('diagnostics.passageCheckingTime')}
                isCorrect={isCorrect}
                isPassage
                solidLineWidth={`${
                  (passageCheckingYou / passageCheckingMax) * 100
                }%`}
                dashedLineWidth={`${
                  (passageCheckingTarget / passageCheckingMax) * 100
                }%`}
                dottedLineWidth={`${
                  (passageCheckingAverage / passageCheckingMax) * 100
                }%`}
              />
            </Tooltip>
          </div>
        </div>

        {/* Question metrics */}

        <div className='question-metrics_container'>
          <div className='question-metrics'>
            <Tooltip
              id='question-passage-working'
              tooltipContent={tooltipContent(
                t('diagnostics.questionWorkingTime'),
                questionWorkingYou,
                questionWorkingTarget,
                questionWorkingAverage
              )}
            >
              <Legend
                title={t('diagnostics.questionWorkingTime')}
                isCorrect={isCorrect}
                isSkipped={isSkipped}
                solidLineText={t('diagnostics.graph.legend.you')}
                solidLineWidth={`${
                  (questionWorkingYou / questionWorkingMax) * 100
                }%`}
                dashedLineText={t('diagnostics.graph.legend.target')}
                dashedLineWidth={`${
                  (questionWorkingTarget / questionWorkingMax) * 100
                }%`}
                dottedLineText={t('diagnostics.graph.legend.average')}
                dottedLineWidth={`${
                  (questionWorkingAverage / questionWorkingMax) * 100
                }%`}
              />
            </Tooltip>
          </div>
          <div className='question-metrics'>
            <Tooltip
              id='question-passage-checking'
              tooltipContent={tooltipContent(
                t('diagnostics.questionCheckingTime'),
                questionCheckingYou,
                questionCheckingTarget,
                questionCheckingAverage
              )}
            >
              <Legend
                title={t('diagnostics.questionCheckingTime')}
                isCorrect={isCorrect}
                isSkipped={isSkipped}
                solidLineWidth={`${
                  (questionCheckingYou / questionCheckingMax) * 100
                }%`}
                dashedLineWidth={`${
                  (questionCheckingTarget / questionCheckingMax) * 100
                }%`}
                dottedLineWidth={`${
                  (questionCheckingAverage / questionCheckingMax) * 100
                }%`}
              />
            </Tooltip>
          </div>
        </div>
      </ContainerInner>
    </Container>
  )
}

const Legend = ({
  title,
  solidLineText,
  solidLineWidth,
  dashedLineText,
  dashedLineWidth,
  dottedLineText,
  dottedLineWidth,
  isCorrect,
  isSkipped,
  isPassage
}): JSX.Element => {
  return (
    <LegendContainer>
      <LineLegend>
        <LegendTitle>
          <LegendLabel />
          <Text bold size='xs'>
            {title}
          </Text>
        </LegendTitle>
      </LineLegend>
      <LineLegend>
        <LegendLabel width={solidLineText ? '3.6rem' : '0px'}>
          <Text bold size='xs'>
            {solidLineText}
          </Text>
        </LegendLabel>
        <SolidLine
          width={solidLineWidth}
          isCorrect={isCorrect}
          isSkipped={isSkipped}
          isPassage={isPassage}
        />
      </LineLegend>
      <LineLegend>
        <LegendLabel width={dashedLineText ? '3.6rem' : '0px'}>
          <Text bold size='xs'>
            {dashedLineText}
          </Text>
        </LegendLabel>
        <DashedLine width={dashedLineWidth} />
      </LineLegend>
      <LineLegend>
        <LegendLabel width={dottedLineText ? '3.6rem' : '0px'}>
          <Text bold size='xs'>
            {dottedLineText}
          </Text>
        </LegendLabel>
        <DottedLine width={dottedLineWidth} />
      </LineLegend>
    </LegendContainer>
  )
}

Legend.defaultProps = {
  solidLineText: '',
  dashedLineText: '',
  dottedLineText: '',
  isSkipped: false,
  isPassage: false,
  isCorrect: false
}

const LegendContainer = styled.div`
  flex-direction: column;
  padding: 1em;
  width: 200px;
  font-family: ${({ theme }) => theme.typography.fontFamilyTertiary} !important;

  * {
    font-family: ${({ theme }) =>
      theme.typography.fontFamilyTertiary} !important;
  }
`

const LineLegend = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  min-height: 16px;
  align-items: center;
`

const LegendLabel = styled.div`
  min-width: ${({ width }) => width};
`

const LegendTitle = styled.div`
  min-width: 70px;
`

const Line = styled.div`
  height: 2px;
  max-width: 100% !important;
  border-width: 0 0 3px 0;
  border-color: ${({ theme }) => theme.colors.main.text};
`

const SolidLine = styled(Line)`
  border-style: solid;
  border-color: ${({ theme, isCorrect, isSkipped, isPassage }) => {
    switch (true) {
      case isPassage:
        return theme.colors.main.text
      case isSkipped:
        return theme.colors.diagnostics.question.skipped
      case isCorrect:
        return theme.colors.diagnostics.question.correct
      default:
        return theme.colors.diagnostics.question.incorrect
    }
  }};

  width: ${({ width }) => (width === '0%' ? '1%' : width)};
`

const DashedLine = styled(Line)`
  border-style: dashed;
  width: ${({ width }) => (width === '0%' ? '1%' : width)};
`

const DottedLine = styled(Line)`
  border-style: dotted;
  width: ${({ width }) => (width === '0%' ? '1%' : width)};
`

const Container = styled.div`
  overflow: hidden;
  position: fixed;
  width: 100%;
  max-height: ${({ isOpen }) => (isOpen ? '150px' : 0)};
  z-index: 9998;
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.typography.fontFamilyTertiary} !important;
  text-shadow: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
  transition: max-height 300ms
    ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;

  a,
  p,
  div {
    text-shadow: none !important;
  }

  .__react_component_tooltip {
    font-family: ${({ theme }) =>
      theme.typography.fontFamilyTertiary} !important;
  }

  .time-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0;
  }
`

const SaltyPeaky = styled.div`
  z-index: 1;
  display: block;
  z-index: ${({ theme }) => theme.zIndex.modal};
  height: 100%;
  width: 5%;

  img {
    display: block;
    position: absolute;
    width: 56px;
    user-select: none;
  }
`

const ContainerInner = styled.div`
  margin: 22px 19px 10px 19px;
  display: flex;
  width: 100%;

  .passage-metrics {
  }

  .question-metrics {
  }

  .passage-metrics_container {
    min-width: 45%;
    display: flex;
    justify-content: space-between;
  }

  .question-metrics_container {
    min-width: 40%;
    display: flex;
    justify-content: space-around;
  }
`

export default QuestionExplanationMetrics

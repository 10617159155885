import { includes, mergeDeepRight, pathOr } from 'ramda'
import axios from 'axios'
import LocalStorageService from 'services/LocalStorageService'
import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { errors, getErrorName, getErrorMessage } from 'utils/errors'

const apiUrl = process.env.REACT_APP_API_URL

export const mergeConfig = (config = {}) => {
  const authToken = LocalStorageService.get(LOCAL_STORAGE_KEYS.token)
  const studentCourseId =
    LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId) || ''
  const authHeader = authToken
    ? { Authorization: authToken, 'x-student-course-id': studentCourseId }
    : {}

  const defaultConfig = {
    headers: {
      Pragma: 'no-cache',
      ...authHeader
    }
  }

  return mergeDeepRight(defaultConfig)(config)
}

const get =
  (instance: any) =>
  async (url: string, config = {}) => {
    return instance.get(url, mergeConfig(config))
  }

const post =
  (instance: any) =>
  async (url: string, data = {}, config = {}) => {
    return instance.post(url, data, mergeConfig(config))
  }

const put =
  (instance: any) =>
  async (url: string, data = {}, config = {}) => {
    return instance.put(url, data, mergeConfig(config))
  }

const patch =
  (instance: any) =>
  async (url: string, data = {}, config = {}) => {
    return instance.patch(url, data, mergeConfig(config))
  }

const handleDelete =
  (instance: any) =>
  async (url: string, config = {}) => {
    return instance.delete(url, mergeConfig(config))
  }

export default (baseURL = apiUrl) => {
  const instance = axios.create({ baseURL })

  instance.interceptors.response.use(
    successRes => successRes,
    error => {
      const url: string = pathOr('', ['config', 'url'], error)

      if (getErrorName(error) === errors.courseNotFound) {
        eventEmitter.emit(events.courseDeleted)
      }

      if (getErrorName(error) === errors.coursePaused) {
        eventEmitter.emit(events.coursePaused)
      }

      if (
        getErrorName(error) === errors.accountInactive ||
        getErrorName(error) === errors.tokenExpired ||
        getErrorMessage(error) === 'jwt expired'
      ) {
        eventEmitter.emit(events.logoutUser)
      }

      const currentToken = LocalStorageService.get(LOCAL_STORAGE_KEYS.token)
      const prevToken = pathOr(
        '',
        ['config', 'headers', 'Authorization'],
        error
      )
      const config = pathOr('', ['config'], error)

      // This part is intended to repeat the request if the user has made
      // a request to the api at the time of the refresh token
      if (
        currentToken !== prevToken &&
        getErrorName(error) === errors.accountUnauthenticated
      ) {
        config.repeat(1)
      }

      const isBookContentCommentEndpoint = includes(
        'student-book-content-comments',
        url
      )

      const isStudentVideoRatingEndpoint = includes(
        'student-video-ratings/',
        url
      )

      if (
        getErrorName(error) === errors.entityNotFound ||
        (getErrorName(error) === errors.accountUnauthenticated &&
          currentToken === prevToken)
      ) {
        // Workaround of API throwing 404 where there are no exams for student
        url !== '/exam-types/dictionary' &&
          !isStudentVideoRatingEndpoint &&
          !isBookContentCommentEndpoint &&
          eventEmitter.emit(events.notFound)
      }

      return Promise.reject(error)
    }
  )

  return {
    get: get(instance),
    post: post(instance),
    put: put(instance),
    patch: patch(instance),
    delete: handleDelete(instance)
  }
}

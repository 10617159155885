import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import debounce from 'lodash.debounce'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'

export const TooltipFreeTrial = (): JSX.Element => {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })

  const handleShowTooltip = data => {
    const event: MouseEvent = R.propOr({ x: 0, y: 0 }, 'e', data)
    setIsVisible(true)
    setPosition({ top: event.y, left: event.x })
  }

  const handleHideTooltip = () => {
    setIsVisible(false)
    setPosition({ top: 0, left: 0 })
  }

  const debouncedMouseEnter = debounce(handleShowTooltip)
  const debouncedMouseLeave = debounce(handleHideTooltip)

  useEffect(() => {
    eventEmitter.on(
      events.unavailableForFreeTrialHoverEnter,
      debouncedMouseEnter
    )
    eventEmitter.on(
      events.unavailableForFreeTrialHoverLeave,
      debouncedMouseLeave
    )

    return () => {
      eventEmitter.off(
        events.unavailableForFreeTrialHoverEnter,
        debouncedMouseEnter
      )
      eventEmitter.off(
        events.unavailableForFreeTrialHoverLeave,
        debouncedMouseLeave
      )
    }
  }, [])

  return isVisible ? (
    <Container
      style={{ top: `${position.top - 30}px`, left: `${position.left}px` }}
      position={position}
    >
      {t('toast.bookNotAvailableInFreeTrial')}
    </Container>
  ) : (
    <div />
  )
}

export default TooltipFreeTrial

const Container = styled.div`
  position: fixed;
  padding: 8px;
  border-radius: 8px;
  opacity: 0.8;
  font-size: 12px;
  z-index: ${({ theme }) => theme.zIndex.navigation + 1} !important;
  background: ${props => props.theme.colors.tooltip.background} !important;
  color: ${props => props.theme.colors.tooltip.font} !important;
  font-family: ${props => props.theme.typography.fontFamily} !important;
  z-index: ${({ theme }) => theme.zIndex.navigation + 1} !important;
`

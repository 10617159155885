import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SaltyBucksIcon } from 'examkrackers-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import VideoIconStar from './VideoIconStar'
import { createVideoRate, fetchVideoDetails } from 'services/VideosService'
import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/videos/utils/events'
import { pathOr } from 'ramda'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

interface VideoRateSystemProps {
  videoId: string
  videoRate: number
  earningForVideoRating: number
}
const convertIndexToRating = (index: number) => {
  return index === 0 ? 1 : index + 1
}

const convertRatingToIndex = (rating: number) => {
  return rating === 0 ? 1 : rating - 1
}
const VideoRateSystem = (props: VideoRateSystemProps): JSX.Element => {
  const { videoId, videoRate, earningForVideoRating } = props
  const { t } = useTranslation()
  const [fill, setFill] = useState('#DDDFE1')
  const [rating, setRating] = useState(convertRatingToIndex(videoRate))
  const [hoverFill, setHoverFill] = useState(-1)
  const dispatch = useDispatch()

  const saveVideoDetails = () => {
    const handleSuccess = response => {
      const videoRating = pathOr('', ['data', 'rating'], response)
      eventEmitter.emit(events.videoRating, videoRating)
      eventEmitter.emit(events.videoId, videoId)
    }
    const handleError = e => console.error(e)

    fetchVideoDetails({ id: videoId }).then(handleSuccess).catch(handleError)
  }

  const fetchStudentDetails = useCallback(() => {
    dispatch(studentFetchDetailsRoutine())
  }, [dispatch])

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleClick = async (newRating: number) => {
    const convertedRating = convertIndexToRating(newRating)

    try {
      await createVideoRate({
        id: videoId,
        rating: convertedRating
      })
      await Promise.all([fetchStudentDetails(), saveVideoDetails()])
      setRating(newRating)
      eventEmitter.emit(events.rateToggle, false)
    } catch (error) {
      console.error(error)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }
  }

  const getRatingDescription = (rating: number) => {
    switch (true) {
      case videoRate === 0 && hoverFill === -1:
        return t('videos.rating.defaultRate')
      case (rating === 0 && hoverFill === rating) ||
        hoverFill === 0 ||
        (rating === 0 && hoverFill === -1):
        return t('videos.rating.notInteresting')
      case (rating === 1 && hoverFill === rating) ||
        hoverFill === 1 ||
        (rating === 1 && hoverFill === -1):
        return t('videos.rating.needsImprovement')
      case (rating === 2 && hoverFill === rating) ||
        hoverFill === 2 ||
        (rating === 2 && hoverFill === -1):
        return t('videos.rating.acceptable')
      case (rating === 3 && hoverFill === rating) ||
        hoverFill === 3 ||
        (rating === 3 && hoverFill === -1):
        return t('videos.rating.good')
      case (rating === 4 && hoverFill === rating) ||
        hoverFill === 4 ||
        (rating === 4 && hoverFill === -1):
        return t('videos.rating.great')
      default:
        return t('videos.rating.defaultRate')
    }
  }

  const handleMouseEnter = (index: number) => {
    const newFill = index <= rating ? fill : '#ffc107'
    setFill(newFill)
    setHoverFill(index)
  }

  const handleMouseLeave = (index: number) => {
    const newFill = videoRate > 0 ? fill : '#DDDFE1'
    setFill(newFill)
    setHoverFill(-1)
  }

  const isVideoRated = videoRate > 0

  useEffect(() => {
    // @ts-ignore
    const newFill = videoRate > 0 ? '#ffc107' : fill
    setFill(newFill)
    setRating(videoRate === 0 ? 0 : videoRate - 1)
  }, [videoRate])

  return (
    <RatingContainer>
      <RatingHeader>
        {isVideoRated ? (
          <RatingTitle>{t('videos.rating.thanksForRating')}</RatingTitle>
        ) : (
          <>
            <RatingTitle>{t('videos.rating.title')}</RatingTitle>
            <SaltyBucksWrapper>
              <IconWrapper>
                <SaltyBucksIcon />
              </IconWrapper>
              <AmountWrapper>{earningForVideoRating}</AmountWrapper>
            </SaltyBucksWrapper>
          </>
        )}
      </RatingHeader>
      <StarWrapper>
        <StarContainer>
          {[...Array(5)].map((_, index) => (
            <VideoIconStar
              key={index}
              index={index}
              rating={rating}
              fillTheStar={fill}
              onMouseEnterEvent={() => handleMouseEnter(index)}
              onMouseLeaveEvent={() => handleMouseLeave(index)}
              handleClickFunction={handleClick}
              hoverFillStar={hoverFill}
            />
          ))}
        </StarContainer>
        <RatingDescription>{getRatingDescription(rating)}</RatingDescription>
      </StarWrapper>
    </RatingContainer>
  )
}

const RatingContainer = styled.div`
  width: 266px;
  height: 84px;
  background-color: ${({ theme }) => theme.colors.main.white};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 4px;
  position: absolute;
  top: -90px;
  right: 0;
`

const RatingHeader = styled.div`
  display: flex;
`

const RatingTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin: 16px 0 16px 16px;
  color: ${({ theme }) => theme.colors.main.text};
`

const StarContainer = styled.div`
  display: flex;
  margin-left: 16px;
  cursor: pointer;
`

const RatingDescription = styled.p`
  front-size: 11px;
  font-weight: 400;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 3px;
  color: ${({ theme }) => theme.colors.main.grey600};
`

export default VideoRateSystem

const SaltyBucksWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.tags.guacamole.backgroundActive};
`

const IconWrapper = styled.div`
  margin-top: 1px;
  margin-left: 6px;
`
const AmountWrapper = styled.div``

const StarWrapper = styled.div`
  display: flex;
`

import PAGE_IDS from 'modules/exam/utils/pageIds'
import {
  map,
  pathOr,
  propEq,
  propOr,
  find,
  applySpec,
  pipe,
  prop,
  replace,
  not,
  filter,
  length,
  lt,
  split,
  addIndex,
  join
} from 'ramda'

export const QUESTION_STATUSES = {
  incomplete: 'incomplete',
  complete: 'complete',
  unseen: 'unseen'
}

export const REVIEW_FILTER_TYPES = {
  all: 'all',
  incomplete: 'incomplete',
  flagged: 'flagged'
}

export const SECTION_STATUSES = {
  review: 'review',
  phase1: 'phase_1',
  phase3: 'phase_3'
}

export const tutorialExamSteps = [
  {
    id: PAGE_IDS.tutorialNavigation,
    order: 1,
    displayName: 'Navigate the Questions 2'
  },
  {
    id: PAGE_IDS.tutorialAnswer,
    order: 2,
    displayName: 'Select an Answer 3'
  },
  {
    id: PAGE_IDS.tutorialScroll,
    order: 3,
    displayName: 'Use the Scroll Feature 4'
  },
  {
    id: PAGE_IDS.tutorialHighlight,
    order: 4,
    displayName: 'Use the Highlight Feature 5'
  },
  {
    id: PAGE_IDS.tutorialStrikethrough,
    order: 5,
    displayName: 'Use the Strikethrough Feature 6'
  },
  {
    id: PAGE_IDS.tutorialPeriodicTable,
    order: 6,
    displayName: 'Refer to the Periodic Table 7'
  },
  {
    id: PAGE_IDS.tutorialFlagForReview,
    order: 7,
    displayName: 'Use the Flag for Review Feature 8'
  },
  {
    id: PAGE_IDS.tutorialSectionReview,
    order: 8,
    displayName: 'Use the Section Review Feature 9'
  }
]

export const getSectionExamStepsById = (examDetails, sectionId) => {
  return pipe(
    propOr([], 'questions'),
    find(propEq('id', sectionId)),
    propOr([], 'questions'),
    map(
      applySpec({
        id: prop('id'),
        order: prop('order'),
        displayName: q => `Question ${q.order}`
      })
    )
  )(examDetails)
}

export const getQuestionNavItems = (
  nextHandler,
  questionStates,
  stepsList,
  flagHandler = id => {}
) => {
  return map((step: { id: string; order: number; displayName: string }) => ({
    displayName: step.displayName,
    order: step.order,
    onClickHandler: nextHandler(step.id),
    onFlagClickHandler: flagHandler(step.id),
    flagged: pathOr(false, [step.id, 'flagged'])(questionStates),
    status: pathOr(QUESTION_STATUSES.unseen, [step.id, 'status'])(
      questionStates
    )
  }))(stepsList)
}

export const replaceOwnTags = content => {
  const cleanContent = pipe(
    replace(/&lt;/g, '<'),
    replace(/&gt;/g, '>')
  )(content)

  return pipe(
    replace(/<indent>/g, "<div class='exam-indent'>"),
    replace(/<\/indent>/g, '</div>'),
    replace(/<left>/g, "<div class='exam-left'>"),
    replace(/<\/left>/g, '</div>'),
    replace(/<right>/g, "<div class='exam-right'>"),
    replace(/<\/right>/g, '</div>'),
    replace(/<center>/g, "<div class='exam-center'>"),
    replace(/<\/center>/g, '</div>'),
    replace(/<roman>/g, "<div class='exam-roman'>"),
    replace(/<\/roman>/g, '</div>'),
    // @ts-ignore
    replace(/<small>/g, "<div class='exam-small'>"),
    replace(/<\/small>/g, '</div>'),
    replace(/<caption>/g, "<div class='exam-caption'>"),
    replace(/<\/caption>/g, '</div>'),
    replace(/<captionMain>/g, "<div class='exam-caption-main'>"),
    replace(/<\/captionMain>/g, '</div>'),
    replace(/<captionSecondary>/g, "<div class='exam-caption-secondary'>"),
    replace(/<\/captionSecondary>/g, '</div>')
    // @ts-ignore
  )(cleanContent)
}

const filterIncomplete = filter(
  pipe(propEq('question_status', QUESTION_STATUSES.complete), not)
)

const filterFlagged = filter(pipe(propEq('is_flagged', true)))

export const hasIncomplete = (sectionId, examSummary) =>
  pipe(
    find(propEq('id', sectionId)),
    propOr([], 'questions'),
    // @ts-ignore
    filterIncomplete,
    length,
    lt(0)
    // @ts-ignore
  )(examSummary)

export const hasFlagged = (sectionId, examSummary) =>
  pipe(
    find(propEq('id', sectionId)),
    propOr([], 'questions'),
    // @ts-ignore
    filterFlagged,
    length,
    lt(0)
    // @ts-ignore
  )(examSummary)

export const boldFirstLine = pipe(
  split('<br/>'),
  addIndex(map)((line, index) => (index === 0 ? `<b>${line}</b>` : line)),
  join('<br/>')
)

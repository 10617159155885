import React, { useEffect } from 'react'
import * as R from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import debounce from 'lodash.debounce'

import { secondsToTime } from 'modules/exam/utils/time'

import { useRouteMatch } from 'react-router-dom'

import { COURSE_LEARNING_TIME_STATUS } from 'utils/courses'
import { isNotNilOrEmpty } from 'utils/ramda'

import { useTranslation } from 'react-i18next'

import usePrevious from 'hooks/usePrevious'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import ActiveOnSite from 'components/ActiveOnSite'

import {
  fetchCourseTimeStateRoutine,
  setLearningTimePassedSecondsRoutine,
  handleUpdateCourseTimeStateRoutine,
  resetInactiveTimePassedSecondsRoutine,
  setInactiveTimePassedSecondsRoutine
} from 'modules/learningTime/ducks/actions'
import { fetchCourseSettingsRoutine } from 'modules/settings/ducks/actions'

import {
  getLearningTimeDetails,
  getLearningTimePassedSeconds,
  getInactiveTimePassedSeconds
} from 'modules/learningTime/ducks/selectors'
import { selectAutoPauseDelay } from 'modules/settings/ducks/selectors'
import {
  getIsOrdinaryStudent,
  getStudent,
  isAuthLoaded
} from 'modules/auth/ducks/selectors'

import { Course } from 'types'

import { isPathToNotCountStudyTime } from '../utils/CountTime'

export const LearningTimeAmount = ({ isPaused }) => {
  const { t } = useTranslation()
  const [isActive, setIsActive] = React.useState(true)
  const dispatch = useDispatch()
  const user = useSelector(getStudent)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const { path } = useRouteMatch()

  const prevIsActive = usePrevious(isActive)
  // @ts-ignore
  const learningTimeDetails = useSelector(getLearningTimeDetails)
  // @ts-ignore
  const passedSeconds: number = useSelector(getLearningTimePassedSeconds)
  const autoPauseDelay: number = useSelector(selectAutoPauseDelay)
  // @ts-ignore
  const inactiveTimePassedSeconds: number = useSelector(
    getInactiveTimePassedSeconds
  )

  const handleUpdateCourseTimeState = React.useCallback(
    (payload = {}) => dispatch(handleUpdateCourseTimeStateRoutine(payload)),
    [dispatch]
  )

  const fetchCourseTimeState = React.useCallback(
    () => dispatch(fetchCourseTimeStateRoutine()),
    [dispatch]
  )

  const fetchCourseSettings = React.useCallback(
    () => dispatch(fetchCourseSettingsRoutine()),
    [dispatch]
  )

  const resetInactiveTimePassedSeconds = React.useCallback(
    () => dispatch(resetInactiveTimePassedSecondsRoutine()),
    [dispatch]
  )

  const debouncedResetInactiveTime = debounce(
    resetInactiveTimePassedSeconds,
    300
  )

  useEffect(() => {
    if (inactiveTimePassedSeconds === autoPauseDelay && autoPauseDelay > 0) {
      handleUpdateCourseTimeState({
        status: COURSE_LEARNING_TIME_STATUS.paused
      })
    }
  }, [inactiveTimePassedSeconds, autoPauseDelay])

  useEffect(() => {
    window.addEventListener('mousemove', debouncedResetInactiveTime)
    window.addEventListener('mousedown', debouncedResetInactiveTime)
    window.addEventListener('keydown', debouncedResetInactiveTime)

    return () => {
      window.removeEventListener('mousemove', debouncedResetInactiveTime)
      window.removeEventListener('mousedown', debouncedResetInactiveTime)
      window.removeEventListener('keydown', debouncedResetInactiveTime)
    }
  }, [])

  const currentCourse: Course = useSelector(getCurrentCourse)
  const hasCourseFetched = R.pipe(
    R.propOr('', 'id'),
    isNotNilOrEmpty
  )(currentCourse)

  const learningTimeState: string = R.propOr(
    COURSE_LEARNING_TIME_STATUS.started,
    'state',
    learningTimeDetails
  )
  const setPassedSeconds = React.useCallback(
    () => dispatch(setLearningTimePassedSecondsRoutine()),
    [dispatch]
  )

  const setPassedInactiveSeconds = React.useCallback(
    () => dispatch(setInactiveTimePassedSecondsRoutine()),
    [dispatch]
  )

  const countSeconds = () => {
    setIsActive(!document.hidden)

    if (
      !document.hidden &&
      learningTimeState === COURSE_LEARNING_TIME_STATUS.started
    ) {
      setPassedSeconds()
    }

    if (learningTimeState === COURSE_LEARNING_TIME_STATUS.started) {
      setPassedInactiveSeconds()
    }
  }

  React.useEffect(() => {
    let timer

    if (
      !isPathToNotCountStudyTime(path) &&
      isOrdinaryStudent &&
      isAuthFetched
    ) {
      timer = setInterval(countSeconds, 1000)
    } else {
      clearInterval(timer)
    }

    return () => {
      clearInterval(timer)
    }
  }, [learningTimeDetails, path, user, isAuthFetched])

  React.useEffect(() => {
    fetchCourseSettings()
  }, [])

  React.useEffect(() => {
    if (!isActive && isActive !== prevIsActive && hasCourseFetched) {
      // save counted seconds in the database when user goes out from the tab/browser
      handleUpdateCourseTimeState()
    }

    if (isActive) {
      // refresh clock state in case if page was opened in other tab or browser
      setTimeout(() => fetchCourseTimeState(), 1000)
    }
  }, [isActive, hasCourseFetched])

  const handleBeforeUnload = e => {
    e.preventDefault()
    handleUpdateCourseTimeState()
  }

  React.useEffect(() => {
    window.onbeforeunload = () => true

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      handleUpdateCourseTimeState()
      window.onbeforeunload = null
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const getTimeElements = secondsToTime(passedSeconds)

  return (
    <LearningTimeAmountContainer paused={isPaused} className='time-value'>
      <TextWrapper>{t('navigation.studyTime')}</TextWrapper>
      {`${getTimeElements.h}:${getTimeElements.m}`}
      <ActiveOnSite
        isActive={isActive}
        isPaused={isPaused}
        isCorrectPath={!isPathToNotCountStudyTime(path)}
      />
    </LearningTimeAmountContainer>
  )
}

const LearningTimeAmountContainer = styled.div`
  font-weight: bold;
  padding: 4px 0px 4px 0px;
  font-size: ${({ theme }) => theme.typography.fontSizeNormal};
  color: ${({ theme }) => theme.colors.main.heading};
`
const TextWrapper = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.main.text};
  font-weight: 400;
`

import React from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as R from 'ramda'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getExamDetails } from 'modules/exam/ducks/selectors'

import ExamScoreTabs from 'modules/diagnostics/components/ExamScoreTabs'
import TabExamSectionDiagnostics from 'modules/diagnostics/components/TabExamSectionDiagnostics'
import TooltipQuestionDetails from 'modules/diagnostics/components/TooltipQuestionDetails'
import {
  BackButton,
  CorrectAnswersIcon,
  InfoTile,
  McatForChartIcon,
  PercentileRankIcon,
  YourScoreIcon
} from 'examkrackers-components'
import PATHS from 'utils/paths'
import { pathOr, propOr } from 'ramda'
import { SCORE_CALCULATION_METHOD } from '../utils/exam'

export const ExamSectionDiagnostic = (): JSX.Element => {
  const params = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const sectionId: string = R.propOr('', 'sectionId')(params)

  const examDetails = useSelector(getExamDetails)
  const questions = R.propOr([], 'questions')(examDetails)
  // @ts-ignore
  const sectionDetails = R.find(R.propEq('id', sectionId))(questions)
  const scores = R.pathOr([], ['exam', 'scores', 'sections'])(examDetails)
  const sectionScoreDetails = R.find(R.propEq('id', sectionId))(scores)

  const sectionTitle = propOr('', 'title', sectionDetails)
  const examName = pathOr('', ['exam', 'title'], examDetails)
  const scaledScore = propOr(0, 'scaled_score', sectionScoreDetails)
  const percentileRank = propOr(0, 'percentile_rank', sectionScoreDetails)
  const totalAnswersAmount = propOr(0, 'total_amount', sectionScoreDetails)
  const correctAnswersAmount = propOr(0, 'amount_correct', sectionScoreDetails)
  const scoreCalculationMethod = pathOr(
    '',
    ['exam', 'originalExam', 'score_calculation_method'],
    examDetails
  )

  const hideScaledScore =
    scoreCalculationMethod === SCORE_CALCULATION_METHOD.percentile

  const redirectToExamsList = () => history.push(PATHS.exams)

  const scoreTile = hideScaledScore
    ? [
        <BackButton key='back-button' onClick={redirectToExamsList}>
          {t('diagnostics.answerSheet.backButton')}
        </BackButton>
      ]
    : [
        <InfoTile
          key='score-tile'
          icon={<YourScoreIcon />}
          content={
            <TileContent>
              <span>{t('diagnostics.answerSheet.scaled')}: </span>
              {scaledScore}
            </TileContent>
          }
        />,
        <BackButton key='back-button' onClick={redirectToExamsList}>
          {t('diagnostics.answerSheet.backButton')}
        </BackButton>
      ]

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {t('pages.exam.sectionDiagnostic', {
            section: R.propOr('', 'title', sectionDetails)
          })}
        </title>
      </Helmet>
      <Container>
        <TooltipQuestionDetails />
        <ExamScoreTabs
          activeTab='diagnostics'
          tiles={[
            <InfoTile
              key='section-title-tile'
              icon={<McatForChartIcon />}
              // @ts-ignore
              content={
                <TileContent>{`${examName}: ${sectionTitle}`}</TileContent>
              }
            />,
            <InfoTile
              key='rank-tile'
              icon={<PercentileRankIcon />}
              content={
                <TileContent>
                  <span>{t('diagnostics.answerSheet.rank')}: </span>
                  {percentileRank}%
                </TileContent>
              }
            />,
            <InfoTile
              key='raw-tile'
              icon={<CorrectAnswersIcon />}
              content={
                <TileContent>
                  <span>{t('diagnostics.answerSheet.raw')}: </span>
                  {correctAnswersAmount} of {totalAnswersAmount}
                </TileContent>
              }
            />,
            ...scoreTile
          ]}
        >
          <TabExamSectionDiagnostics />
        </ExamScoreTabs>
      </Container>
    </React.Fragment>
  )
}

export default ExamSectionDiagnostic

const Container = styled.div`
  position: relative;
`

const TileContent = styled.div`
  font-weight: bold;
  font-size: 18px;

  span {
    font-weight: normal;
    font-size: 14px;
  }
`

import React, { useState } from 'react'
import styled from 'styled-components'

import { Modal, Button, Input, TextIcon } from 'examkrackers-components'

export const ModalTextUploader = ({ sketchRef }) => {
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleUploadImage = e => {
    e.preventDefault()
    sketchRef.current.addText(text)
    handleClose()
  }

  const handleInputChange = e => setText(e.target.value)

  return (
    <>
      <Button id='color-picker' size='small' onClick={handleOpen}>
        <TextIcon />
      </Button>
      <Modal title='' open={open} handleClose={handleClose}>
        <TextUploadContainer>
          <Input onChange={handleInputChange} placeholder='Text' />
          <Button onClick={handleUploadImage} color='secondary' size='small'>
            Submit
          </Button>
        </TextUploadContainer>
      </Modal>
    </>
  )
}

export default ModalTextUploader

const TextUploadContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-top: 1rem;
  }
`

import i18n from 'providers/i18n'

export const BOOK_VIEWER_PARAMS = {
  resourceId: 'resourceId',
  contentId: 'contentId',
  sectionIdContext: 'sectionIdContext',
  bookContentIdContext: 'bookContentIdContext',
  previewType: 'previewType',
  highlightMode: 'highlightMode',
  rightTopExpand: 'rightTopExpand',
  rightBottomExpand: 'rightBottomExpand',
  rightTopActive: 'rightTopActive',
  rightBottomActive: 'rightBottomActive',
  imageSlide: 'imageSlide',
  chapterImageSlide: 'chapterImageSlide',
  contentQuestionFilter: 'contentQuestionFilter'
}

export const BOOK_CONTENT_TYPES = {
  file: 'file',
  mainText: 'main_text',
  saltyText: 'salty_comment'
}

export const BOOK_PREVIEW_TYPES = {
  resourcePreview: 'resource_preview'
}

export const RESOURCE_TYPES = {
  video: 'video',
  tmi: 'tmi',
  clinicalContext: 'clinical_context',
  mcatThink: 'mcat_think',
  topics: 'topics'
}

export const MOCKED_TOPICS_RESOURCE = (id, isRead) => ({
  content_id: id,
  delta_object: '{}',
  id: `${id}-mocked-resource`,
  is_read: isRead || false,
  order: 1,
  original_resource_id: '00000000-0000-0000-0000-000000000000',
  raw: '',
  type: RESOURCE_TYPES.topics,
  video_description: null,
  video_duration: null,
  video_id: null,
  video_source: null,
  video_thumbnail: null,
  video_title: null
})

export const ATTACHMENT_TYPES = {
  file: 'file',
  mainText: 'main_text',
  saltyText: 'salty_comment'
}

export const HIGHLIGHT_MODES = {
  yours: 'yours',
  admin: 'ek',
  none: 'none'
}

export const QUESTION_FILTERS = {
  all: 'all',
  answered: 'answered',
  unanswered: 'unanswered'
}

export const contentQuestionFilterOptions = () => [
  {
    label: i18n.t('bookViewer.contentQuestions.filter.all'),
    value: QUESTION_FILTERS.all
  },
  {
    label: i18n.t('bookViewer.contentQuestions.filter.answered'),
    value: QUESTION_FILTERS.answered
  },
  {
    label: i18n.t('bookViewer.contentQuestions.filter.unanswered'),
    value: QUESTION_FILTERS.unanswered
  }
]

export const VIDEO_PROGRESS_REQUIRED_TO_EARN_SB = 90

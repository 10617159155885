import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ExamContent, ExamAnswers } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import {
  addQuestionStateRoutine,
  saveStepHtmlRoutine
} from 'modules/exam/ducks/actions'
import {
  getFirstSectionId,
  getQuestion,
  getStepHtml
} from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'
import {
  generateSaveStepHtmlPayload,
  getSavedHtmlByPathOrDefault
} from 'modules/exam/utils/step'
import { propOr } from 'ramda'
import { isNilOrEmpty } from '../../../../utils/ramda'

export const TutorialStrikethrough = (props): JSX.Element => {
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialStrikethrough)
  )
  const currentAnswer: string = propOr('', 'answer', questionState)
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const [selectedAnswer, setSelectedAnswer] = useState(currentAnswer)
  const dispatch = useDispatch()
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const stepSavedHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, PageIds.tutorialStrikethrough)
  )
  const saveStepHtml = useCallback(
    payload =>
      dispatch(
        saveStepHtmlRoutine({ ...payload, id: PageIds.tutorialStrikethrough })
      ),
    [dispatch]
  )

  const handleSaveStepHtml = () => saveStepHtml(generateSaveStepHtmlPayload())

  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    handleSaveStepHtml()
    setCurrentPage(PageIds.tutorialPeriodicTable)
  }

  const handlePrevious = () => {
    handleSaveStepHtml()
    setCurrentPage(PageIds.tutorialHighlight)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? QUESTION_STATUSES.incomplete
          : questionState.status,
      id: PageIds.tutorialStrikethrough
    })
  }, [])

  const handleSelectAnswer = answer => {
    const newAnswer = answer === selectedAnswer ? '' : answer
    const newStatus = isNilOrEmpty(newAnswer)
      ? QUESTION_STATUSES.incomplete
      : QUESTION_STATUSES.complete

    updateQuestionStep({
      ...questionState,
      status: newStatus,
      id: PageIds.tutorialStrikethrough,
      answer: newAnswer
    })
    setSelectedAnswer(newAnswer)
  }

  const strikethroughAnswers = [
    {
      answerCode: 'A',
      answerContent: (
        <>
          {getSavedHtmlByPathOrDefault(
            ['answers', 'A'],
            'Purple',
            stepSavedHtml
          )}
        </>
      )
    },
    {
      answerCode: 'B',
      answerContent: (
        <>
          {getSavedHtmlByPathOrDefault(
            ['answers', 'B'],
            'Yellow',
            stepSavedHtml
          )}
        </>
      )
    },
    {
      answerCode: 'C',
      answerContent: (
        <>
          {getSavedHtmlByPathOrDefault(
            ['answers', 'C'],
            'Green',
            stepSavedHtml
          )}
        </>
      )
    },
    {
      answerCode: 'D',
      answerContent: (
        <>
          {getSavedHtmlByPathOrDefault(['answers', 'D'], 'Blue', stepSavedHtml)}
        </>
      )
    }
  ]

  const stepInitialQuestion = (
    <>
      <h2>Use the Strikethrough Feature</h2>
      <h3>Description</h3>
      <p>
        You can use the strikethrough feature to draw a line through answer
        options and visually eliminate them from consideration. You can also use
        the strikethrough feature on passage text. The strikethrough will remain
        visible as you navigate through the exam unless you remove the
        strikethrough.
      </p>
      <h3>Instructions</h3>
      <p>
        Locate{' '}
        <strong>
          <span className='underline'>S</span>trikethrough
        </strong>{' '}
        on the top left corner of your screen.
      </p>
      <ul>
        <li>
          <strong>
            <span className='underline'>S</span>trikethrough
          </strong>{' '}
          or the Alt+S keyboard shortcut allows you to draw a line through any
          text.
        </li>
        <li>
          It also allows you to remove the line you previously added to text.
        </li>
      </ul>
      <p>
        <strong>Important:</strong> Make sure that you have not accidentally
        selected or deselected an answer option while using the strikethrough
        feature.
      </p>
      <h3>Practice</h3>
      <ul className='numeric'>
        <li>
          Select the text you wish to draw a line through by holding down the
          left button on the mouse as you drag the mouse over the text.
        </li>
        <li>
          Select the{' '}
          <strong>
            <span className='underline'>S</span>trikethrough
          </strong>{' '}
          button on the top left side of the screen or use the Alt+S keyboard
          shortcut. This will draw a line through the desired text.
        </li>
        <li>
          To remove the line, repeat the same procedure used to add the
          strikethrough.
        </li>
      </ul>
      <p>What is the color of the sky?</p>
    </>
  )

  return (
    <ExamContent>
      <TutorialStrikethroughContainer>
        <div>
          <div className='heavy-bordered'>
            <div id='step-question'>
              {getSavedHtmlByPathOrDefault(
                ['question'],
                stepInitialQuestion,
                stepSavedHtml
              )}
            </div>
            <ExamAnswers
              answers={strikethroughAnswers}
              onSelectAnswer={handleSelectAnswer}
              selectedAnswerCode={selectedAnswer}
            />
          </div>
          <p>
            Select{' '}
            <strong>
              <span className='underline'>N</span>ext
            </strong>{' '}
            to advance to the next page.
          </p>
        </div>
      </TutorialStrikethroughContainer>
    </ExamContent>
  )
}

export default TutorialStrikethrough

const TutorialStrikethroughContainer = styled.div`
  p {
    margin: 16px 0;
    line-height: 22.4px;
  }

  ul,
  li {
    line-height: 22.4px;
  }

  li {
    margin-bottom: 2px !important;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }

  .indent {
    text-indent: 30px;
  }

  .answer {
    margin-top: 20px !important;
  }
`

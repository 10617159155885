import * as R from 'ramda'

import {
  createSelector,
  OutputSelector,
  OutputParametricSelector
} from 'reselect'

import { API_STATES } from 'utils/redux'
import { selectExam } from 'modules/exam/ducks/selectors'
import { isNotNilOrEmpty, mapIndexed } from 'utils/ramda'

import { ScoreProjectionDataEntity, ScoreProjectionDetail } from 'types'

export const selectDiagnostics = state => state.diagnostic

export const getIsDiagnosticsLoading = createSelector(
  selectDiagnostics,
  R.pipe(R.propOr([], 'state'), R.equals(API_STATES.IN_PROGRESS))
)

export const getScoreProjectionData: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(selectDiagnostics, R.propOr([], 'scoreProjectionData'))

export const getExamTypeProjections: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(selectDiagnostics, R.propOr([], 'examTypeProjections'))

export const getPassageReadingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.propOr([], 'passageReadingTimeGraph'),
    mapIndexed((point, index) => ({
      x: index + 1,
      y: R.propOr(false, 'isFalsePassage', point) ? 0 : R.propOr(0, 'y', point),
      isFalsePassage: R.propOr(false, 'isFalsePassage', point),
      wordsPerMinute: R.propOr(0, 'wordsPerMinute', point)
    })),
    // @ts-ignore
    R.sortBy(R.prop('x'))
  )
)

export const getPassageWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.propOr([], 'passageWorkingTimeGraph'),
    mapIndexed((point, index) => ({
      x: index + 1,
      y: R.propOr(false, 'isFalsePassage', point) ? 0 : R.propOr(0, 'y', point),
      isFalsePassage: R.propOr(false, 'isFalsePassage', point),
      wordsPerMinute: R.propOr(0, 'wordsPerMinute', point)
    })),
    // @ts-ignore
    R.sortBy(R.prop('x'))
  )
)

export const getPassageTimers: OutputSelector<any, any, (res: any) => any> =
  createSelector(selectDiagnostics, R.propOr([], 'passageTimers'))

export const getComparedPassageTimers: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pathOr([], ['comparedExam', 'passageTimers'])
)

export const getPassageGraphLines: OutputParametricSelector<
  any,
  any,
  any[],
  (res1: any, res2: any) => any[]
> = createSelector(
  selectExam,
  (state, sectionId: string) => sectionId,
  (examDetails, sectionId: string) => {
    const findSection = R.pipe(
      R.propOr({}, 'examDetails'),
      R.propOr([], 'questions'),
      R.find(R.propEq('id', sectionId))
    )

    const getSectionPassageFirstPoints = R.ifElse(
      R.isNil,
      R.always([]),
      R.pipe(
        R.propOr([], 'questions'),
        // @ts-ignore
        R.filter(R.propEq('original_order', 1)),
        // @ts-ignore
        R.map(R.prop('order'))
      )
    )

    const getLastPointOfLastPassage = R.pipe(
      R.ifElse(
        R.isNil,
        R.always(0),
        R.pipe(R.propOr([], 'questions'), R.last, R.propOr(0, 'order'))
      ),
      R.of
    )

    return R.pipe(
      findSection,
      R.juxt([getSectionPassageFirstPoints, getLastPointOfLastPassage]),
      R.flatten
    )(examDetails)
  }
)

export const getGraphCategories: OutputParametricSelector<
  any,
  any,
  any[],
  (res1: any, res2: any) => any[]
> = createSelector(
  selectExam,
  (state, sectionId: string) => sectionId,
  (examDetails, sectionId) =>
    R.pipe(
      R.propOr({}, 'examDetails'),
      R.propOr([], 'questions'),
      R.find(R.propEq('id', sectionId)),
      R.ifElse(
        R.isNil,
        R.always([]),
        R.pipe(
          R.propOr([], 'questions'),
          // @ts-ignore
          R.map(R.prop('order'))
        )
      )
    )(examDetails)
)

export const getQuestionsWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.propOr([], 'questionsGraphData'),
    // @ts-ignore
    R.filter(R.has('order')),
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['timers', 'working'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getTargetQuestionsWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['targetQuestionsGraphData']),
    // @ts-ignore
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['working_avg'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getComparedTargetQuestionsWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'targetQuestionsGraphData']),
    // @ts-ignore
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['working_avg'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getAverageQuestionsWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['averageQuestionsGraphData', 'timings']),
    // @ts-ignore
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['timers', 'working_avg'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getComparedAverageQuestionsWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'averageQuestionsGraphData', 'timings']),
    // @ts-ignore
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['timers', 'working_avg'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getTargetPassagesWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['targetPassagesGraphData']),
    // @ts-ignore
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['working_avg'])
      })
    ),
    // @ts-ignore
    R.sortBy(R.prop('x'))
  )
)

export const getTargetPassagesTimings: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['targetPassagesGraphData']),
    R.map(
      R.applySpec({
        order: R.propOr(0, 'order'),
        timers: R.applySpec({
          reading_avg: R.propOr(0, 'reading_avg'),
          working_avg: R.propOr(0, 'working_avg'),
          checking_avg: R.propOr(0, 'checking_avg')
        })
      })
    )
  )
)

export const getComparedTargetPassagesWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'targetPassagesGraphData']),
    // @ts-ignore
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['working_avg'])
      })
    ),
    // @ts-ignore
    R.sortBy(R.prop('x'))
  )
)

export const getComparedTargetPassagesTimings: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'targetPassagesGraphData']),
    R.map(
      R.applySpec({
        order: R.propOr(0, 'order'),
        timers: R.applySpec({
          reading_avg: R.propOr(0, 'reading_avg'),
          working_avg: R.propOr(0, 'working_avg'),
          checking_avg: R.propOr(0, 'checking_avg')
        })
      })
    )
  )
)

export const getAveragePassagesWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['averagePassagesGraphData', 'timings']),
    // @ts-ignore
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['timers', 'working_avg'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getAveragePassagesTimings: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pathOr([], ['averagePassagesGraphData', 'timings'])
)

export const getComparedAveragePassagesWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'averagePassagesGraphData', 'timings']),
    // @ts-ignore
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['timers', 'working_avg'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getComparedAveragePassagesTimings: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pathOr([], ['comparedExam', 'averagePassagesGraphData', 'timings'])
)

export const getQuestionsDifficultyGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.propOr([], 'questionsGraphData'),
    // @ts-ignore
    R.filter(R.has('order')),
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['original_question', 'difficulty_percentage'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getQuestionsData: OutputSelector<any, any, (res: any) => any> =
  createSelector(
    selectDiagnostics,
    R.pipe(
      R.propOr([], 'questionsGraphData'),
      // @ts-ignore
      R.filter(R.has('order')),
      R.sortBy(R.prop('order'))
    )
  )

export const getComparedSectionOptions: OutputParametricSelector<
  any,
  any,
  any[],
  (res1: any, res2: any, res3: any) => any[]
> = createSelector(
  getScoreProjectionData,
  (state: any, order: number) => order,
  (state: any, order: number, examId: string) => examId,
  (data, order: number, examId: string) =>
    R.pipe(
      (data: ScoreProjectionDataEntity[]) => R.nth(order - 1, data),
      R.propOr([], 'scores'),
      (scores: ScoreProjectionDetail[]) =>
        R.filter(
          R.allPass([
            R.pipe(
              (data: ScoreProjectionDetail) => R.prop('id', data),
              isNotNilOrEmpty
            ),
            R.pipe(
              (data: ScoreProjectionDetail) => R.propEq('id', examId)(data),
              R.not
            )
          ])
        )(scores),
      (scores: ScoreProjectionDetail[]) =>
        R.map(
          R.applySpec({
            label: R.propOr('', 'title'),
            value: R.applySpec({
              sectionId: R.propOr('', 'section_id'),
              examId: R.propOr('', 'id')
            })
          }),
          scores
        )
    )(data)
)

export const getComparedPassageReadingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'passageReadingTimeGraph']),
    mapIndexed((point, index) => ({
      x: index + 1,
      y: R.propOr(false, 'isFalsePassage', point) ? 0 : R.propOr(0, 'y', point),
      isFalsePassage: R.propOr(false, 'isFalsePassage', point),
      wordsPerMinute: R.propOr(0, 'wordsPerMinute', point)
    })),
    // @ts-ignore
    R.sortBy(R.prop('x'))
  )
)

export const getComparedPassageWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'passageWorkingTimeGraph']),
    mapIndexed((point, index) => ({
      x: index + 1,
      y: R.propOr(false, 'isFalsePassage', point) ? 0 : R.propOr(0, 'y', point),
      isFalsePassage: R.propOr(false, 'isFalsePassage', point),
      wordsPerMinute: R.propOr(0, 'wordsPerMinute', point)
    })),
    // @ts-ignore
    R.sortBy(R.prop('x'))
  )
)

export const getComparedPassageGraphLines: OutputParametricSelector<
  any,
  any,
  any[],
  (res1: any, res2: any) => any[]
> = createSelector(
  selectDiagnostics,
  (state, sectionId: string) => sectionId,
  (diagnostics, sectionId) => {
    const examDetails = R.pathOr({}, ['comparedExam', 'examDetails'])(
      diagnostics
    )

    const findSection = R.pipe(
      R.propOr([], 'questions'),
      R.find(R.propEq('id', sectionId))
    )

    const getSectionPassageFirstPoints = R.ifElse(
      R.isNil,
      R.always([]),
      R.pipe(
        R.propOr([], 'questions'),
        // @ts-ignore
        R.filter(R.propEq('original_order', 1)),
        // @ts-ignore
        R.map(R.prop('order'))
      )
    )

    const getLastPointOfLastPassage = R.pipe(
      R.ifElse(
        R.isNil,
        R.always(0),
        R.pipe(R.propOr([], 'questions'), R.last, R.propOr(0, 'order'))
      ),
      R.of
    )

    return R.pipe(
      findSection,
      R.juxt([getSectionPassageFirstPoints, getLastPointOfLastPassage]),
      R.flatten
    )(examDetails)
  }
)

export const getComparedGraphCategories: OutputParametricSelector<
  any,
  any,
  any[],
  (res1: any, res2: any) => any[]
> = createSelector(
  selectDiagnostics,
  (state, sectionId: string) => sectionId,
  (diagnostics, sectionId) =>
    R.pipe(
      R.pathOr({}, ['comparedExam', 'examDetails']),
      R.propOr([], 'questions'),
      R.find(R.propEq('id', sectionId)),
      R.ifElse(
        R.isNil,
        R.always([]),
        R.pipe(
          R.propOr([], 'questions'),
          // @ts-ignore
          R.map(R.prop('order'))
        )
      )
    )(diagnostics)
)

export const getComparedQuestionsWorkingTimeGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'questionsGraphData']),
    // @ts-ignore
    R.filter(R.has('order')),
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['timers', 'working'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getComparedQuestionsDifficultyGraph: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'questionsGraphData']),
    // @ts-ignore
    R.filter(R.has('order')),
    R.map(
      R.applySpec({
        x: R.propOr(0, 'order'),
        y: R.pathOr(0, ['original_question', 'difficulty_percentage'])
      })
    ),
    R.sortBy(R.prop('x'))
  )
)

export const getComparedQuestionsData: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pipe(
    R.pathOr([], ['comparedExam', 'questionsGraphData']),
    // @ts-ignore
    R.filter(R.has('order'))
  )
)

export const getComparedExamDetails: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectDiagnostics,
  R.pathOr({}, ['comparedExam', 'examDetails'])
)

export const getSingleQuestionMetrics: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(selectDiagnostics, R.propOr({}, 'singleQuestionMetrics'))

export const getMaxWorkingQuestionAmount: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(selectDiagnostics, diagnostics => {
  const you = R.pipe(
    R.propOr([], 'questionsGraphData'),
    R.map(R.pathOr(0, ['timers', 'working']))
  )(diagnostics)

  const target = R.pipe(
    R.pathOr([], ['targetQuestionsGraphData', 'timings']),
    R.map(R.pathOr(0, ['timers', 'working_avg']))
  )(diagnostics)

  const average = R.pipe(
    R.pathOr([], ['averageQuestionsGraphData', 'timings']),
    R.map(R.pathOr(0, ['timers', 'working_avg']))
  )(diagnostics)

  // @ts-ignore
  return Math.max(...you, ...target, ...average)
})

export const getDiagnosticLegendState = createSelector(
  selectDiagnostics,
  R.propOr(
    {
      timePerQuestion: true,
      targetTimePerQuestion: true,
      averageTimePerQuestion: true,
      passages: true,
      difficulty: true
    },
    'diagnosticGraphLegendState'
  )
)

export const getPassageNumberByQuestionNumber = createSelector(
  getPassageWorkingTimeGraph,
  (state, questionNumber) => questionNumber,
  (passageWorkingTimeGraph, questionNumber) => {
    const passageWorkingGraphWithPassageOrders =
      // @ts-ignore
      R.reduce(
        // @ts-ignore
        (acc, entry) => {
          // @ts-ignore
          const prevEntry = R.last(acc)
          const prevPassageOrder: number = R.propOr(
            1,
            'passageOrder',
            prevEntry
          )
          const currentY: number = R.propOr(0, 'y', entry)
          const prevY: number = R.propOr(currentY, 'y', prevEntry)
          const passageOrder =
            currentY === prevY ? prevPassageOrder : prevPassageOrder + 1

          return [
            // @ts-ignore
            ...acc,
            {
              // @ts-ignore
              ...entry,
              passageOrder
            }
          ]
        },
        []
      )(passageWorkingTimeGraph)

    return R.pipe(
      R.find(R.propEq('x', questionNumber)),
      R.propOr(1, 'passageOrder')
    )(passageWorkingGraphWithPassageOrders)
  }
)

import APIService from 'services/APIService'
import { ExamSubtypesPayload } from 'types'

const api = APIService()

export const fetchExamsTypes = () =>
  api.get('/exam-types?limit[page]=1&limit[take]=9999')
export const fetchExamsTypesDictionary = () => api.get('/exam-types/dictionary')
export const fetchExamSubtypesByType = (payload: ExamSubtypesPayload) =>
  api.get(`/exam-types/dictionary/${payload.type}`)
export const fetchExamTypeScoreProjections = (payload: { id: string }) =>
  api.get(`/student-exams/${payload.id}/target-score`)

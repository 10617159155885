import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { ExamHeader, ExamToolsBar } from 'examkrackers-components'
import { ExamPageConfigProps, RootReducer } from 'types'
import { useDispatch, useSelector } from 'react-redux'

import { toggleQuestionFlag } from 'services/ExamsService'
import {
  addQuestionStateRoutine,
  fetchExamDetailsRoutine
} from 'modules/exam/ducks/actions'
import { getExamDetails, getQuestion } from 'modules/exam/ducks/selectors'
import { pathOr, propOr } from 'ramda'
import { getStudent } from 'modules/auth/ducks/selectors'

interface ExamExplanationsTopComponentsProps {
  currentPage: string
  currentPageConfig: ExamPageConfigProps
}

export const ExamExplanationsTopComponents = ({
  currentPage,
  currentPageConfig
}: ExamExplanationsTopComponentsProps): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()
  const id: string = propOr('', 'id')(params)

  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, currentPage)
  )
  const examDetails = useSelector(getExamDetails)
  const user = useSelector(getStudent)

  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const fetchExamDetails = useCallback(
    payload => dispatch(fetchExamDetailsRoutine(payload)),
    [dispatch]
  )

  const handleFlagClick = async () => {
    updateQuestionStep({
      ...questionState,
      flagged: !questionState.flagged
    })

    const handleSuccess = () => fetchExamDetails({ id })
    const handleError = e => console.error(e)
    toggleQuestionFlag({ id: currentPage })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <ExamHeader
        title={`${pathOr('', ['exam', 'title'], examDetails)} - ${propOr(
          '',
          'name',
          user
        )}`}
        timer='00:00:00'
        currentPage={currentPageConfig.currentPage}
        totalPages={currentPageConfig.totalPages}
        timerVisibility={currentPageConfig.timerVisibility}
        pagesVisibility={currentPageConfig.pagesVisibility}
      />
      <ExamToolsBar
        highlightButton={currentPageConfig.highlightButtonVisibility}
        strikethroughButton={currentPageConfig.strikethroughButtonVisibility}
        flagForReviewButton={currentPageConfig.flagForReviewButtonVisibility}
        onFlagClick={handleFlagClick}
        isFlagged={questionState.flagged}
        afterHtmlChangeCallback={() => {}}
      />
    </>
  )
}

export default ExamExplanationsTopComponents

import React from 'react'
import { propOr } from 'ramda'
import styled from 'styled-components'
import Tags from 'components/Tags'
import { TABS } from 'modules/books/components/BookRightColumn'
import ScaledFlashcard from './ScaledFlashcard'
import { useSelector } from 'react-redux'
import { getArchiveFlashcardsList } from '../ducks/selectors'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'
import useSelectionSaga from '../../../hooks/useSelectionSaga'
import { Checkbox } from 'examkrackers-components'

const Flashcard = ({ flashcard, withSelection = false }): JSX.Element => {
  const {
    location: { pathname }
  } = useHistory()

  const archivedFlashcards = useSelector(getArchiveFlashcardsList)
  const availableFlashcards = useSelector(getArchiveFlashcardsList)

  const isArchivePath = pathname === PATHS.flashcardsArchive

  const flashcardsListToSelect = isArchivePath
    ? archivedFlashcards
    : availableFlashcards

  const { isItemSelected, handleSelectItem } = useSelectionSaga(
    flashcardsListToSelect
  )

  const flashcardId = propOr('', 'student_flashcard_id', flashcard)
  const isSelected = isItemSelected(flashcardId)
  const handleSelect = handleSelectItem(flashcardId)

  return (
    <FlashcardWrapper>
      <FlashcardCode>#{flashcard.code}</FlashcardCode>
      <ContentWrapper>
        <ScaledFlashcard scale={0.29} flashcard={flashcard} />
      </ContentWrapper>
      <FlashcardFooter>
        <Tags
          tags={propOr([], 'tags', flashcard)}
          id={flashcard.id}
          activeTopTab={TABS.flashcards}
        />
        {withSelection && (
          <CheckBoxWrapper onClick={e => e.stopPropagation()}>
            <Checkbox
              name='select'
              isSelected={isSelected}
              onChange={handleSelect}
            />
          </CheckBoxWrapper>
        )}
      </FlashcardFooter>
    </FlashcardWrapper>
  )
}

export default Flashcard

const FlashcardWrapper = styled.div``

const FlashcardCode = styled.div`
  font-size: 10px;
  margin-bottom: 5px;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgrounds.main};
`

const FlashcardFooter = styled.div`
  margin-left: -4px;
  display: flex;
  justify-content: space-between;
  gap: -8px;
  align-items: flex-start;

  * {
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const CheckBoxWrapper = styled.div`
  height: fit-content;
  z-index: ${({ theme }) => theme.zIndex.drawer + 10};
  padding: 8px 0;
`

import { errors, getErrorName } from 'utils/errors'
import { SEVERITY } from 'utils/toast'

export const getEditAccessPeriodError = (error: Error) => {
  let errorTranslationKey

  switch (getErrorName(error)) {
    case errors.dateFromPast:
      errorTranslationKey = 'toast.pastDate'
      break
    case errors.entityInvalid:
      errorTranslationKey = 'toast.invalidDate'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error }
}

export const getStartExamError = (error: Error) => {
  let errorTranslationKey

  switch (getErrorName(error)) {
    case errors.examAlreadyCompleted:
      errorTranslationKey = 'toast.examAlreadyCompleted'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error }
}

import { Heading2, Tag, WysiwygViewer } from 'examkrackers-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatDate } from 'utils/date'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import { useHistory } from 'react-router-dom'
import * as R from 'ramda'
import { Book, Errata, TagColor } from './ErrataTypes'
import { Subchapter } from 'types'

interface ErrataDetailsProps {
  selectedBook: Book | undefined
  selectedErrata: Errata | undefined
  subchapterList: Subchapter[]
}

const ErrataDetails = ({
  selectedErrata,
  selectedBook,
  subchapterList
}: ErrataDetailsProps) => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const studentSubchaterId = originalSubchapterId => {
    const subchapter = subchapterList.find(
      subchapter => subchapter.original_subchapter_id === originalSubchapterId
    )
    return subchapter?.id
  }
  const handleScrollToSubchapter = subchapterId => {
    const studentSubchapterAnchor = document.getElementById(
      `subchapter-anchor-${subchapterId}`
    )

    studentSubchapterAnchor &&
      studentSubchapterAnchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  const redirectToBookContent = () => {
    // @ts-ignore
    const link = `/books/${selectedErrata.book_id}/chapter/${selectedErrata.chapter_order}/part/${selectedErrata.part}?${BOOK_VIEWER_PARAMS.sectionIdContext}=${selectedErrata.subchapter_id}`
    push(link)
    setTimeout(() => {
      handleScrollToSubchapter(
        studentSubchaterId(selectedErrata?.subchapter_id)
      )
    }, 3000)
  }

  const errataDeltaObject = R.pipe(
    R.propOr('{}', 'content_delta_object'),
    JSON.parse
  )(selectedErrata)

  const errataTypeToString =
    selectedErrata?.type && (selectedErrata?.type.replace('_', ' ') as string)

  return (
    <ErrataDetailsWrapper>
      {selectedErrata ? (
        <React.Fragment>
          <Heading2 bold>{t('erratas.details')}</Heading2>
          <ErrataMetaInfo>
            {selectedErrata.type && (
              <>
                <Tag
                  text={`${selectedErrata.tag} ${selectedErrata.chapter_order}.${selectedErrata.subchapter_order}`}
                  color={selectedBook?.tag_colour as TagColor}
                  onClick={redirectToBookContent}
                />
                <ErrataTypeTag>{errataTypeToString}</ErrataTypeTag>
              </>
            )}
            <CreatedAt>
              {t('erratas.createdAt')}
              {formatDate(selectedErrata.created_at, 'MM/dd/yyyy')}
            </CreatedAt>
          </ErrataMetaInfo>
          <ErrataContents>
            {/* {selectedErrata && (
              <WysiwygViewer
                id={`selected-errata-contents-${selectedErrata.id}`}
                value={errataDeltaObject}
              />
            )} */}
          </ErrataContents>
        </React.Fragment>
      ) : (
        <EmptyList>
          <Heading2>{t('erratas.emptyList')}</Heading2>
        </EmptyList>
      )}
    </ErrataDetailsWrapper>
  )
}

export default ErrataDetails

const ErrataDetailsWrapper = styled.div`
  margin: 0 auto;
  padding: 0px 0 12px 8px;
  text-align: left;
  width: 100%;

  h2 {
    font-size: 16px;
    line-height: 24px;
  }
`

const ErrataContents = styled.div`
  max-height: 350px;
  padding-right: 12px;
  overflow-y: scroll;
`

const EmptyList = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ErrataMetaInfo = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0 20px;
`

const CreatedAt = styled.p`
  color: ${({ theme }) => theme.colors.main.grey600};
  font-size: 11px;
  line-height: 16px;
`

const ErrataTypeTag = styled.div`
  min-width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 11px;
  background: ${({ theme }) => theme.colors.main.grey200};
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.main.text};
  text-transform: capitalize;
`

import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'

import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'

import FormEditUsername from '../forms/FormEditUsername'
import { Modal } from 'examkrackers-components'

const ModalEditUsername = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleClose = () => {
    setOpen(false)
  }

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )

  useEffect(() => {
    if (open) {
      fetchStudentDetails()
      eventEmitter.emit(events.modalEditUsernameOpened)
    }
  }, [open])

  return (
    <Modal
      title={t('navigation.profile.modalTitle')}
      open={open}
      handleClose={handleClose}
    >
      <FormEditUsername onSubmitSuccess={handleClose} />
    </Modal>
  )
}

export default ModalEditUsername

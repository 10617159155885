import React from 'react'
import eventEmitter from '../../../providers/eventEmitter'
import events from '../utils/events'

export const useCustomXLabels = id => {
  const setLineVisibility = labels => {
    const labelsList = Array.from(labels)

    labelsList.forEach((label, index: number, all) => {
      const number = index + 1
      const isVisible =
        number === 1 || number === all.length || number % 5 === 0
      // @ts-ignore
      label.style.visibility = isVisible ? 'visible' : 'hidden'
    })
  }

  const customLabels = () => {
    // @ts-ignore
    const linesContainer = document.querySelector(
      `#${id} .apexcharts-xaxis-texts-g`
    )

    linesContainer && setLineVisibility(linesContainer.children)
  }

  const timeOutCustomLabels = () => setTimeout(customLabels, 1200)

  React.useEffect(() => {
    timeOutCustomLabels()
  }, [])

  React.useEffect(() => {
    eventEmitter.on(events.chartUpdated, customLabels)

    return () => {
      eventEmitter.off(events.chartUpdated, customLabels)
    }
  }, [])
}

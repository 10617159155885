import { createRoutine } from 'redux-saga-routines'
import * as ExamsService from 'services/ExamsService'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import {
  QuestionActionPayload,
  ExamDetailsActionPayload,
  QuestionDetailsActionPayload,
  QuestionStepHtmlPayload,
  ExamSectionDetails,
  ActionPayload
} from 'types'
import { addRealOrderToPassages } from 'utils/exam'

import { getIsPreviewAdmin } from 'modules/auth/ducks/selectors'
import { setCurrentExamTypeIdRoutine } from 'modules/examTypes/ducks/actions'

//
// Routines
//
export const addQuestionStateRoutine = createRoutine('ADD_QUESTION_STATE')
export const saveStepHtmlRoutine = createRoutine('SAVE_STEP_HTML')
export const saveQuestionAnswerRoutine = createRoutine('SAVE_QUESTION_ANSWER')
export const saveQuestionStatusRoutine = createRoutine('SAVE_QUESTION_STATUS')
export const fetchExamDetailsRoutine = createRoutine('FETCH_EXAM_DETAILS')
export const resetExamDetailsRoutine = createRoutine('RESET_EXAM_DETAILS')
export const fetchQuestionHtmlRoutine = createRoutine('FETCH_QUESTION_HTML')

//
// Actions
//
function* addQuestionState({ payload }: QuestionActionPayload) {
  const isPreviewAdmin = yield Effects.select(getIsPreviewAdmin)

  if (!isPreviewAdmin) {
    yield Effects.put(addQuestionStateRoutine.request())
    try {
      yield Effects.put(addQuestionStateRoutine.success(payload))
    } catch (e) {
      yield Effects.put(addQuestionStateRoutine.failure(e))
    }
  }
}

function* saveStepHtml({ payload }: QuestionStepHtmlPayload) {
  const isPreviewAdmin = yield Effects.select(getIsPreviewAdmin)
  if (!isPreviewAdmin) {
    yield Effects.put(saveStepHtmlRoutine.request())

    try {
      yield Effects.put(saveStepHtmlRoutine.success(payload))
    } catch (e) {
      yield Effects.put(saveStepHtmlRoutine.failure(e))
    }
  }
}

// Submit an answer for an exam question
function* saveQuestionAnswer({
  payload: { values = {}, callback = () => {} }
}: ActionPayload) {
  const isPreviewAdmin = yield Effects.select(getIsPreviewAdmin)

  if (!isPreviewAdmin) {
    yield Effects.put(saveQuestionAnswerRoutine.request())
    try {
      yield Effects.call(ExamsService.answerQuestion, values)
      yield Effects.put(saveQuestionAnswerRoutine.success(values))
      callback()
    } catch (e) {
      yield Effects.put(saveQuestionAnswerRoutine.failure(e))
    }
  }
}

// Submit an answer for an exam question
function* saveQuestionStatus({ payload }: ActionPayload) {
  const isPreviewAdmin = yield Effects.select(getIsPreviewAdmin)

  if (!isPreviewAdmin) {
    yield Effects.put(saveQuestionStatusRoutine.request())

    try {
      yield Effects.call(ExamsService.changeQuestionStatus, payload)
      yield Effects.put(saveQuestionStatusRoutine.success(payload))
    } catch (e) {
      yield Effects.put(saveQuestionStatusRoutine.failure(e))
    }
  }
}

function* fetchQuestionHtml({ payload }: QuestionDetailsActionPayload) {
  yield Effects.put(fetchQuestionHtmlRoutine.request())
  try {
    const result = yield Effects.call(
      ExamsService.fetchQuestionDetails,
      payload
    )

    const generateQuestionHtml = R.pipe(
      R.propOr({}, 'data'),
      R.applySpec({
        id: R.propOr('', 'id'),
        passageId: R.propOr('', 'student_passage_id'),
        passageOriginalId: R.pathOr('', [
          'passage',
          'original_exam_passage_id'
        ]),
        question: R.propOr('', 'question_content'),
        questionOriginalId: R.propOr('', 'original_exam_question_id'),
        passage: R.pathOr('', ['passage', 'content']),
        answers: R.propOr({}, 'answer_definition'),
        explanation: R.propOr('', 'explanation'),
        answer: R.propOr('', 'answer'),
        correct_answer: R.propOr('', 'correct_answer'),
        canvas: R.pipe(R.pathOr({}, ['canvas'])),
        backgroundImage: R.pathOr('', ['background_image']),
        distribution: R.pathOr({}, ['originalQuestion', 'answer_distribution']),
        isFalsePassage: R.pathOr(false, ['passage', 'is_false_passage'])
      })
    )

    yield Effects.put(
      fetchQuestionHtmlRoutine.success(generateQuestionHtml(result))
    )
  } catch (e) {
    yield Effects.put(fetchQuestionHtmlRoutine.failure(e))
  }
}

// Get an exam object
function* fetchExamDetails({ payload }: ExamDetailsActionPayload) {
  yield Effects.put(fetchExamDetailsRoutine.request(payload))
  try {
    const result = yield Effects.call(ExamsService.fetchExamsDetails, payload)

    const exam_type_id = R.pathOr('', ['data', 'exam', 'exam_type_id'], result)

    yield Effects.put(setCurrentExamTypeIdRoutine(exam_type_id))

    const setRealOrdersToPassages = R.pipe(
      R.pathOr([], ['data', 'questions']),
      R.map((question: ExamSectionDetails) => ({
        ...question,
        passages: addRealOrderToPassages(question.passages)
      }))
    )(result)

    const organisedExamDetails = {
      ...R.pathOr([], ['data'], result),
      questions: setRealOrdersToPassages
    }

    yield Effects.put(fetchExamDetailsRoutine.success(organisedExamDetails))
  } catch (e) {
    yield Effects.put(fetchExamDetailsRoutine.failure(e))
  }
}

// Reset an exam object
function* resetExamDetails() {
  yield Effects.put(resetExamDetailsRoutine.success())
}

//
// Watchers
//
export function* addQuestionStateWatcher() {
  yield Effects.takeLatest(addQuestionStateRoutine.TRIGGER, addQuestionState)
}

export function* saveStepHtmlWatcher() {
  yield Effects.takeLatest(saveStepHtmlRoutine.TRIGGER, saveStepHtml)
}

export function* saveQuestionAnswerWatcher() {
  yield Effects.takeLatest(
    saveQuestionAnswerRoutine.TRIGGER,
    saveQuestionAnswer
  )
}

export function* saveQuestionStatusWatcher() {
  yield Effects.takeLatest(
    saveQuestionStatusRoutine.TRIGGER,
    saveQuestionStatus
  )
}

export function* fetchExamDetailsWatcher() {
  yield Effects.takeEvery(fetchExamDetailsRoutine.TRIGGER, fetchExamDetails)
}

export function* resetExamDetailsWatcher() {
  yield Effects.takeLatest(resetExamDetailsRoutine.TRIGGER, resetExamDetails)
}

export function* fetchQuestionHtmlWatcher() {
  yield Effects.takeEvery(fetchQuestionHtmlRoutine.TRIGGER, fetchQuestionHtml)
}

//
// Sagas
//
export const examSagas = [
  Effects.fork(addQuestionStateWatcher),
  Effects.fork(saveStepHtmlWatcher),
  Effects.fork(saveQuestionAnswerWatcher),
  Effects.fork(saveQuestionStatusWatcher),
  Effects.fork(fetchQuestionHtmlWatcher),
  Effects.fork(fetchExamDetailsWatcher),
  Effects.fork(resetExamDetailsWatcher)
]

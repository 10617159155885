import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'

import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import AnswerSheet from 'modules/diagnostics/components/AnswerSheet'

export const ExamScoreReportAnswers = (): JSX.Element => {
  const params = useParams()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const examId: string = R.propOr('', 'id')(params)
  const sectionId: string = R.propOr('', 'sectionId')(params)

  const examDetails = useSelector(getExamDetails)
  const questions = R.propOr([], 'questions')(examDetails)
  // @ts-ignore
  const sectionDetails = R.find(R.propEq('id', sectionId))(questions)

  const fetchExamDetails = React.useCallback(
    (id: string) => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  React.useEffect(() => {
    fetchExamDetails(examId)
  }, [pathname])

  return (
    <>
      <Helmet>
        <title>
          {t('pages.exam.scoreReportAnswers', {
            section: R.propOr('', 'title', sectionDetails)
          })}
        </title>
      </Helmet>
      <AnswerSheet />
    </>
  )
}

export default ExamScoreReportAnswers

import { API_STATES } from 'utils/redux'
import { fetchCourseSettingsRoutine } from './actions'
import { Action, SettingsReducer } from 'types'

export const initialState: SettingsReducer = {
  state: API_STATES.PRISTINE,
  courses: []
}

export default (state = initialState, action: Action): SettingsReducer => {
  switch (action.type) {
    case fetchCourseSettingsRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchCourseSettingsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        courses: action.payload.data
      }
    case fetchCourseSettingsRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    default:
      return state
  }
}

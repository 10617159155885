import React from 'react'
import { Button, Modal } from 'examkrackers-components'
import styled from 'styled-components'

import PreReadingSalty from 'assets/images/Calendar_Salty_LiveClassPreReadingPopup.svg'
// import { CalendarContext } from 'hooks/CalendarContext'
import { useTranslation } from 'react-i18next'

const WithoutPreReadingModal = ({ open, setOpen, setWithoutPreReading }) => {
  // const { course } = useContext(CalendarContext)
  const { t } = useTranslation()

  // console.group('PreReadingModal')
  // console.log('course', course)
  // console.log('open', open)

  // console.groupEnd()

  const handleClose = () => {
    setOpen(false)
    setWithoutPreReading(true)
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      hasBorder
      data-testid='WithoutPreReadingModal-Modal'
    >
      <>
        <MainWrapper data-testid='WithoutPreReadingModal-MainWrapper'>
          <HideCloseButtonWrapper data-testid='WithoutPreReadingModal-HideCloseButtonWrapper' />
          <Container data-testid='WithoutPreReadingModal-Container'>
            <MainSectionWrapper data-testid='WithoutPreReadingModal-MainSectionWrapper'>
              <TextWrapper data-testid='WithoutPreReadingModal-TextWrapper'>
                <SectionWrapper data-testid='WithoutPreReadingModal-SectionWrapper-titleFirstPart'>
                  <SectionTitle data-testid='WithoutPreReadingModal-SectionTitle-titleFirstPart'>
                    {t('calendar.preReadingModal.insufficientTime')}
                  </SectionTitle>
                </SectionWrapper>
              </TextWrapper>
              <SaltyContainer data-testid='WithoutPreReadingModal-SaltyContainer'>
                <PreReadingSaltyImage
                  data-testid='WithoutPreReadingModal-PreReadingSaltyImage'
                  id='reorganize-salty'
                  src={PreReadingSalty}
                  alt='Salty reorganize'
                />
              </SaltyContainer>
            </MainSectionWrapper>
          </Container>
          <ButtonsWrapper data-testid='WithoutPreReadingModal-ButtonsWrapper'>
            <ButtonWrapper
              color='tertiary'
              onClick={handleClose}
              data-testid='WithoutPreReadingModal-ButtonWrapper-ButtonDecline'
            >
              {t('calendar.preReadingModal.insufficientTimeButton')}
            </ButtonWrapper>
          </ButtonsWrapper>
        </MainWrapper>
      </>
    </Modal>
  )
}

export default WithoutPreReadingModal

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 56px;
`

const SectionWrapper = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  &:first-child {
    margin-bottom: 20px;
  }

  &:last-child {
    font-weight: bold;
  }
`

const SectionTitle = styled.div`
  font-size: 16px;
  width: 460px;
  text-align: left;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 65px;
  gap: 16px;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
  padding: 12px;
`

const MainSectionWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const SaltyContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`
const PreReadingSaltyImage = styled.img`
  height: 121px;
  width: 165px;
`
const MainWrapper = styled.div`
  min-width: 690px;
  max-width: 690px;
  position: relative;
`

const HideCloseButtonWrapper = styled.div`
  position: absolute;
  top: -48px;
  right: -20px;
  width: 80px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.main.white};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
  margin-bottom: 14px;
`

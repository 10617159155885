import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import qs from 'qs'
import { FetchExamLogsListPayload, PaginationProps, ExamLogs } from 'types'
import styled from 'styled-components'
import { EntitiesList } from 'examkrackers-components'
import { DEFAULT_ROWS_PER_PAGE, SORT_DIRECTION } from 'utils/table'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { fetchExamLogsListRoutine } from 'modules/exams/ducks/actions'
import {
  getExamLogsList,
  getExamLogsPagination
} from 'modules/exams/ducks/selectors'

interface TableExamLogsProps {
  id: string
}

// Table for displaying the logs for an exam.
const TableExamLogs = (props: TableExamLogsProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const { id } = props

  // Get i18n handler
  const { t } = useTranslation()

  // Get React-Redux dispatcher
  const dispatch = useDispatch()

  // Get exam logs from Redux.
  const fetchExamLogs = React.useCallback(
    (payload: FetchExamLogsListPayload) =>
      dispatch(fetchExamLogsListRoutine(payload)),
    [dispatch]
  )

  // Initial query string values.
  const initialQuery = qs.stringify({
    order: { by: 'created_at', dir: R.toLower(SORT_DIRECTION.desc) },
    limit: { take: 5, page: 1 }
  })

  // Parse the URL into query strings.
  const parsedQuery = qs.parse(initialQuery, { ignoreQueryPrefix: true })

  // Get the initial list of exam logs.
  React.useEffect(() => {
    fetchExamLogs({ query: `?${initialQuery}`, id })
  }, [])

  // Pagination state for exam logs
  const examLogsPagination: PaginationProps = useSelector(getExamLogsPagination)

  // Exam logs
  const examLogsList: ExamLogs[] = useSelector(getExamLogsList)

  // Table rows
  const rows = R.map((log: ExamLogs) => ({
    id: `row-exam-logs-${log.id}`,
    cells: [
      {
        children: (
          <>
            {log.created_at
              ? formatDate(log.created_at, DATE_FORMATS.dashedWithTime)
              : '-'}
          </>
        ),
        columnId: 'created_at',
        cellProps: {}
      },
      {
        children: <>{t(`exams.logsTable.log.${R.propOr('-', 'type', log)}`)}</>,
        columnId: 'type',
        cellProps: {}
      },
      {
        children: <>{R.pathOr('-', ['admin', 'email'], log)}</>,
        columnId: 'email',
        cellProps: {}
      }
    ]
  }))(examLogsList)

  // Table header columns
  const headers = [
    {
      columnId: 'created_at',
      sortable: false,
      id: 'created_at',
      children: t('exams.logsTable.headers.createdAt')
    },
    {
      columnId: 'type',
      sortable: false,
      id: 'type',
      children: t('exams.logsTable.headers.type')
    },
    {
      columnId: 'email',
      sortable: false,
      id: 'email',
      children: t('exams.logsTable.headers.author')
    }
  ]

  // Handle changes in the table state.
  function handleTableStateChange({ sortBy, dir, page }): void {
    const query = qs.stringify({
      order: { by: sortBy, dir: R.toLower(dir) },
      limit: { take: 5, page }
    })

    fetchExamLogs({ query: `?${query}`, id })
  }

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 100)
  }, [])

  const defaultRowsPerPage: number = R.pipe(
    R.pathOr(DEFAULT_ROWS_PER_PAGE, ['limit', 'take']),
    Number
  )(parsedQuery)

  return isLoading ? (
    <div />
  ) : (
    <TableContainer>
      <EntitiesList
        size='xs'
        resultsText={t('exams.logsTable.results', {
          count: examLogsPagination.recordsTotal
        })}
        headers={headers}
        rows={rows}
        totalPages={examLogsPagination.pagesTotal}
        emptyStateText={t('exams.logsTable.emptyState')}
        defaultPage={R.pathOr(1, ['limit', 'page'], parsedQuery)}
        defaultSortColumnId='created_at'
        defaultSortDirection={R.pathOr(
          SORT_DIRECTION.desc,
          ['order', 'dir'],
          parsedQuery
        )}
        defaultRowsPerPage={defaultRowsPerPage as 10 | 50 | 100}
        onTableStateChange={handleTableStateChange}
      />
    </TableContainer>
  )
}

const TableContainer = styled.div`
  table {
    padding: 0;
  }
`

export default TableExamLogs

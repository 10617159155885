import APIService from 'services/APIService'
import {
  FetchBookPartDetailsPayload,
  GetChapterSectionsWithNotesPayload,
  GetNotesForSubchapterPayload,
  UpsertHighlightsPayload,
  UpsertNoteForSubchapterPayload,
  TrackStudentBookProgressPayload,
  TrackResourcePreviewPayload,
  AnswerContentQuestionPayload,
  SaveBookReadingProgressPayload,
  AddBookMarkPayload,
  RemoveBookMarkPayload,
  ResetSingleContentQuestionPayload,
  ResetChapterContentQuestionsPayload,
  ResetChapterContentQuestionsCostPayload,
  AddPinNoteBodyPayload,
  AddPinNoteTitlePayload,
  FetchPinNotesPayload,
  DeletePinNotePayload,
  UpdatePinNotePayload,
  fetchPinVariantsPayload,
  saveRightColumnStatePayload
} from 'types'
import { dissoc } from 'ramda'

const api = APIService()

export const fetchBooks = () =>
  api.get('/student-books?limit[page]=1&limit[take]=100')

export const fetchBookPartDetails = (payload: FetchBookPartDetailsPayload) =>
  api.get(
    `/student-books/${payload.bookId}/details/${payload.chapterOrder}/${payload.partOrder}`
  )

export const fetchSubchaptersByBookId = payload =>
  api.get(`/student-books/${payload.id}/details/1/1/partial/subchapters`)

export const fetchBookContentsPartial = payload =>
  api.get(
    `/student-books/${payload.id}/details/${payload.chapterOrder}/${payload.partOrder}/partial/contents`
  )

export const fetchBookChaptersWithExams = payload =>
  api.get(`/student-books/${payload.id}/chapters`)

export const upsertBookContentHighlightsAPICall = (
  payload: UpsertHighlightsPayload
) =>
  api.patch(`/student-book-contents/${payload.id}`, {
    delta_object: payload.delta_object
  })

export const upsertBookContentAttachmentHighlights = (
  payload: UpsertHighlightsPayload
) =>
  api.patch(`/student-book-content-attachments/${payload.id}`, {
    delta_object: payload.delta_object
  })

export const fetchNotesForSubchapter = (
  payload: GetNotesForSubchapterPayload
) => api.get(`/student-book-subchapter/${payload.sectionId}/notes`)

export const fetchChapterSectionsWithNotes = (
  payload: GetChapterSectionsWithNotesPayload
) => api.get(`/student-book-chapters/${payload.chapterId}/notes`)

export const upsertNoteForSubchapter = (
  payload: UpsertNoteForSubchapterPayload
) =>
  api.post(
    `/student-book-subchapter/${payload.sectionId}/notes`,
    dissoc('sectionId', payload)
  )

export const upsertBookResourcesHighlights = (
  payload: UpsertHighlightsPayload
) =>
  api.patch(`/student-book-content-resources/${payload.id}`, {
    delta_object: payload.delta_object
  })

export const trackStudentBookProgress = (
  payload: TrackStudentBookProgressPayload
) =>
  api.patch(
    `/student-book-chapters/${payload.chapterId}/part/${payload.partOrder}/read`
  )

export const trackResourcePreview = (payload: TrackResourcePreviewPayload) =>
  api.patch(`/student-book-content-resources/${payload.resourceId}/read`)

export const answerContentQuestion = (payload: AnswerContentQuestionPayload) =>
  api.patch(`/student-book-content-questions/${payload.id}/answer`, {
    answers: payload.answers
  })

export const getRandomContentQuestionAnimation = () =>
  api.get(`/content-question-reactions/random`)

// dashboard charts

export const getMcatChartData = () => api.get('/dashboard/graphs/mcat')

export const getFlashcardsChartData = () =>
  api.get('/dashboard/graphs/flashcard-proficiency')

export const getQuestionsChartData = () =>
  api.get('/dashboard/graphs/content-question-progress')

export const getSaltyBucksIncomeHistoryData = payload =>
  api.get(`/dashboard/graphs/salty-bucks/${payload.range}`)

export const getBookProgressData = () =>
  api.get('/dashboard/graphs/book-progress')

export const saveBookReadingProgress = (
  payload: SaveBookReadingProgressPayload
) =>
  api.patch(
    `/student-books/${payload.bookId}/last-read/${payload.chapterOrder}/${payload.partOrder}`
  )

export const addBookMark = (payload: AddBookMarkPayload) =>
  api.post(`/student-book-chapters/${payload.chapterId}/bookmark`, {
    student_book_content_id: payload.studentBookContentId
  })

export const removeBookMark = (payload: RemoveBookMarkPayload) =>
  api.delete(`/student-book-chapters/${payload.chapterId}/bookmark`)

export const resetSingleContentQuestion = (
  payload: ResetSingleContentQuestionPayload
) => api.patch(`/student-book-content-questions/${payload.id}/reset`)

export const resetChapterContentQuestions = (
  payload: ResetChapterContentQuestionsPayload
) =>
  api.post(`student-book-content-questions/chapter-reset/${payload.chapterId}`)

export const getResetChapterContentQuestionsCost = (
  payload: ResetChapterContentQuestionsCostPayload
) =>
  api.get(
    `student-book-content-questions/chapter-reset-cost/${payload.chapterId}`
  )

export const fetchAppSettingByNameSpace = () =>
  api.get(`/app-settings/namespace/salty_bucks`)

// pinNotes

export const addPinNoteBody = (payload: AddPinNoteBodyPayload) =>
  api.post(`/student-book-content-pins`, {
    content_id: payload.studentBookContentId,
    variant: payload.variant,
    note: payload.note
  })
export const addPinNoteTitle = (payload: AddPinNoteTitlePayload) =>
  api.post(`/student-pin-variants`, {
    student_book_id: payload.studentBookId,
    variant: payload.variant,
    title: payload.title
  })

export const fetchPinNotes = (payload: FetchPinNotesPayload) =>
  api.get(`/student-book-content-pins/content/${payload.id}`)

export const deletePinNote = (payload: DeletePinNotePayload) =>
  api.delete(`/student-book-content-pins/${payload.id}`)

export const updatePinNote = (payload: UpdatePinNotePayload) =>
  api.patch(`/student-book-content-pins/${payload.id}`, {
    note: payload.note
  })

export const fetchPinVariants = (payload: fetchPinVariantsPayload) =>
  api.get(`student-pin-variants/${payload.studentBookId}`)

// BookRightColumn state

export const saveRightColumnState = (payload: saveRightColumnStatePayload) =>
  api.patch(`student-books/${payload.studentBookId}/preview-state`, {
    preview_state: payload.preview_state
  })

export const postAIChatMessage = payload => api.post('/chat-bot/send', payload)

export const postAIChatMessageStream = payload =>
  api.post('/chat-bot/send-stream', payload)

import { object, number } from 'yup'
import { validateValues, validateField } from 'utils/form'

// Create Exam Type form validator

export const changeTargetScoreSchema = object().shape({
  value: number().required(() => ({ key: 'form.targetScore.errors.required' }))
})

export const validateChangeTargetScoreField = values =>
  validateField(changeTargetScoreSchema, values)
export const validateChangeTargetScoreValues = validateValues(
  changeTargetScoreSchema
)

import React, { useState } from 'react'
import { Button, Modal } from 'examkrackers-components'
import styled from 'styled-components'

import SaltyOneWeekSub from 'assets/images/Salty_One_Week_Sub.svg'

import { useTranslation } from 'react-i18next'

const OneWeekSubscriptionModal = () => {
  const [open, setOpen] = useState(true)

  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      hasBorder
      data-testid='OneWeekSubscriptionModal-Modal'
    >
      <>
        <MainWrapper data-testid='OneWeekSubscriptionModal-MainWrapper'>
          <HideCloseButtonWrapper data-testid='OneWeekSubscriptionModal-HideCloseButtonWrapper' />
          <Container data-testid='OneWeekSubscriptionModal-Container'>
            <SectionTitle data-testid='OneWeekSubscriptionModal-SectionTitle'>
              {t('calendar.oneWeekSubscriptionInfoModal')}
            </SectionTitle>

            <SaltyContainer data-testid='OneWeekSubscriptionModal-SaltyContainer'>
              <OneWeekSaltyImage
                data-testid='OneWeekSubscriptionModal-OneWeekSaltyImage'
                id='reorganize-salty'
                src={SaltyOneWeekSub}
                alt='Salty reorganize'
              />
            </SaltyContainer>
          </Container>

          <ButtonWrapper
            color='secondary'
            onClick={handleClose}
            data-testid='OneWeekSubscriptionModal-ButtonWrapper-buttonOk'
          >
            {t('calendar.buttonOk')}
          </ButtonWrapper>
        </MainWrapper>
      </>
    </Modal>
  )
}

export default OneWeekSubscriptionModal

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 30px;
`

const SectionTitle = styled.div`
  font-size: 16px;
  width: 300px;
  text-align: left;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  align-items: center;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
  padding: 12px;
  margin-top: 10px;
`

const SaltyContainer = styled.div`
  display: flex;
`
const OneWeekSaltyImage = styled.img`
  height: 121px;
  width: 165px;
`
const MainWrapper = styled.div`
  position: relative;
`

const HideCloseButtonWrapper = styled.div`
  position: absolute;
  top: -48px;
  right: -20px;
  width: 80px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.main.white};
`

import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ExamContent } from 'examkrackers-components'
import PageIds from 'modules/exam/utils/pageIds'
import { RootReducer } from 'types'

import { useDispatch, useSelector } from 'react-redux'
import {
  addQuestionStateRoutine,
  saveStepHtmlRoutine
} from 'modules/exam/ducks/actions'
import {
  getFirstSectionId,
  getQuestion,
  getStepHtml
} from 'modules/exam/ducks/selectors'
import { QUESTION_STATUSES } from 'modules/exam/utils/exam'
import {
  generateSaveStepHtmlPayload,
  getSavedHtmlByPathOrDefault
} from 'modules/exam/utils/step'
import { resetTextSelection } from 'utils/exam'

export const TutorialHighlight = (props): JSX.Element => {
  const { setCurrentPage, setOnTimeEnd, setNavigationHandlers } = props
  const dispatch = useDispatch()
  const questionState = useSelector((state: RootReducer) =>
    getQuestion(state, PageIds.tutorialHighlight)
  )
  const updateQuestionStep = useCallback(
    payload => dispatch(addQuestionStateRoutine(payload)),
    [dispatch]
  )

  const stepSavedHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, PageIds.tutorialHighlight)
  )
  const saveStepHtml = useCallback(
    payload =>
      dispatch(
        saveStepHtmlRoutine({ ...payload, id: PageIds.tutorialHighlight })
      ),
    [dispatch]
  )

  const handleSaveStepHtml = () => {
    saveStepHtml(generateSaveStepHtmlPayload())
    resetTextSelection()
  }

  const firstSectionId = useSelector(getFirstSectionId)

  const moveToExamSection = () => setCurrentPage(firstSectionId)

  const handleNext = () => {
    handleSaveStepHtml()
    setCurrentPage(PageIds.tutorialStrikethrough)
  }

  const handlePrevious = () => {
    handleSaveStepHtml()
    setCurrentPage(PageIds.tutorialScroll)
  }

  useEffect(() => {
    setOnTimeEnd(moveToExamSection)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))

    updateQuestionStep({
      ...questionState,
      status:
        questionState.status === QUESTION_STATUSES.unseen
          ? ''
          : questionState.status,
      id: PageIds.tutorialHighlight
    })
  }, [])

  const stepInitialQuestion = (
    <>
      <h2>Use the Highlight Feature</h2>
      <h3>Description</h3>
      <p>
        You can highlight text that you think is important. The highlight will
        remain visible as you navigate through the exam unless you remove the
        highlight.
      </p>
      <h3>Instructions</h3>
      <p>
        Locate{' '}
        <strong>
          <span className='underline'>H</span>ighlight
        </strong>{' '}
        in the top left corner of your screen.
      </p>
      <ul>
        <li>
          <strong>
            <span className='underline'>H</span>ighlight
          </strong>{' '}
          or the Alt+H keyboard shortcut allows you to highlight text in yellow.
        </li>
        <li>
          It also allows you to remove the yellow highlight you previously added
          to the text.
        </li>
      </ul>
      <h3>Practice</h3>
      <ul className='numeric'>
        <li>
          Select the text you wish to highlight by holding down the left button
          on the mouse as you drag the mouse over the text.
        </li>
        <li>
          Select the{' '}
          <strong>
            <span className='underline'>H</span>ighlight
          </strong>{' '}
          button on the top left side of the screen using the left mouse button,
          or use the Alt+H keyboard shortcut. This applies a yellow highlight
          over the desired text.
        </li>
        <li>
          To remove the highlight, select the text from which you want to remove
          the highlight, select the drop-down arrow or the yellow square, and
          select <strong>Remove Highlight</strong>. Then select the text from
          which you want to remove the highlight using your mouse and press
          Alt+H.
        </li>
        <li>
          Alternately, in this Practice exam, to remove highlight using the
          Alt+H keyboard shortcut, press Alt+H, and then press the down arrow
          key twice and press the spacebar to select{' '}
          <strong>Remove Highlight</strong>. Then select the text from which you
          want to remove the highlight using your mouse and press Alt+H.
        </li>
        <li>
          To switch between highlight and remove highlight using keyboard
          shortcuts, use Alt+H, the down arrow key to move between{' '}
          <strong>
            <span className='underline'>H</span>ighlight
          </strong>{' '}
          and <strong>Remove Highlight</strong>, and use your spacebar to
          select.
        </li>
      </ul>
      <p>
        Note that in the actual exam, to remove highlight using the Alt+H
        keyboard shortcut, press Alt+H, select the text from which you want to
        remove the highlight using your mouse, press the down arrow key twice to
        select <strong className='important'>Remove Highlight</strong>, and
        press the spacebar to remove the highlight.
      </p>
      <p className='indent'>
        Amines play important roles in many biochemical processes. Many methods
        of preparing amines have been explored. Alkylation of primary amines
        with haloalkanes is a well-established procedure in synthesizing
        secondary amines. Unfortunately, this method is limited by the risk of
        further alkylation to tertiary amines and subsequently to quarternary
        ammonium compounds. A selective synthesis, shown in Scheme I, in which
        ethanolamine reacts with carbonyl compounds via an imine intermediate
        that equilibrates with an oxazolidine ring (Compound <strong>1</strong>
        ), does not experience the same problem.
      </p>
    </>
  )

  return (
    <ExamContent>
      <TutorialHighlightContainer>
        <div>
          <div>
            <div className='heavy-bordered'>
              <div id='step-question'>
                {getSavedHtmlByPathOrDefault(
                  ['question'],
                  stepInitialQuestion,
                  stepSavedHtml
                )}
              </div>
            </div>
            <p>
              Select{' '}
              <strong>
                <span className='underline'>N</span>ext
              </strong>{' '}
              to advance to the next page.
            </p>
          </div>
        </div>
      </TutorialHighlightContainer>
    </ExamContent>
  )
}

export default TutorialHighlight

const TutorialHighlightContainer = styled.div`
  p {
    margin: 16px 0;
    line-height: 22.4px;
  }

  ul,
  li {
    line-height: 22.4px;
  }

  li {
    margin-bottom: 2px !important;
  }

  .tutorial__question {
    margin-bottom: 28px !important;
  }

  .indent {
    text-indent: 30px;
  }
`

import { createRoutine } from 'redux-saga-routines'
import * as R from 'ramda'
import * as Effects from 'utils/saga'
import * as notesService from 'services/NotesService'
import { ActionPayload } from 'types'

// ROUTINES
export const fetchNotesForCourseRoutine = createRoutine(
  'FETCH_NOTES_FOR_COURSE'
)
export const fetchNotesForParticularBookRoutine = createRoutine(
  'FETCH_NOTES_FOR_PARTICULAR_BOOK'
)

// ACTIONS
function* fetchNotesForCourse({ payload }: ActionPayload) {
  yield Effects.put(fetchNotesForCourseRoutine.request())
  try {
    const result = yield Effects.call(notesService.fetchNotesForCourse, payload)
    yield Effects.put(
      fetchNotesForCourseRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchNotesForCourseRoutine.failure(e))
  }
}

function* fetchNotesForParticularBook({ payload }: ActionPayload) {
  yield Effects.put(fetchNotesForParticularBookRoutine.request())

  try {
    const result = yield Effects.call(
      notesService.fetchNotesForParticularBook,
      payload
    )
    yield Effects.put(
      fetchNotesForParticularBookRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    console.error(e)
    yield Effects.put(fetchNotesForParticularBookRoutine.failure(e))
  }
}

// WATCHERS

// watcher FETCH_NOTES_FOR_PARTICULAR_BOOK
export function* fetchNotesForCourseWatcher() {
  yield Effects.takeLatest(
    fetchNotesForCourseRoutine.TRIGGER,
    fetchNotesForCourse
  )
}
// watcher FETCH_NOTES_FOR_PARTICULAR_BOOK
export function* fetchNotesForParticularBookWatcher() {
  yield Effects.takeLatest(
    fetchNotesForParticularBookRoutine.TRIGGER,
    fetchNotesForParticularBook
  )
}
// SAGAS
export const notesSagas = [
  Effects.fork(fetchNotesForCourseWatcher),
  Effects.fork(fetchNotesForParticularBookWatcher)
]

import {
  not,
  isNil,
  isEmpty,
  addIndex,
  map,
  includes,
  or,
  equals,
  sort
} from 'ramda'

export const isNotNil = (value: any) => not(isNil(value))
export const isNotEmpty = (value: any) => not(isEmpty(value))
export const isNotNilOrEmpty = (value: any) =>
  isNotNil(value) && isNotEmpty(value)
export const isNilOrEmpty = (value: any) => or(isNil(value), isEmpty(value))
export const notEquals = (value1: any) => value2 => not(equals(value1, value2))
export const mapIndexed = addIndex(map)
export const isInArray = array => item => includes(item, array)

const diff = (a, b) => a - b
export const sortAlphabetically = array => sort(diff, array)

import React, { useState } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { CalendarIcon, ArrowDownIcon, Calendar } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { setCourseEndDate } from 'services/CourseService'
import { formatToISO } from 'utils/date'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { Course } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { fetchCurrentCourseRoutine } from 'modules/courses/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'
import { getStudent } from 'modules/auth/ducks/selectors'

export const EditCompletionDate = (): JSX.Element => {
  const dispatch = useDispatch()
  const [isFocused, setIsFocused] = useState(false)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const { t } = useTranslation()
  const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
  const course: Course = useSelector(getCurrentCourse)
  const endDate = R.propOr('', 'expected_end_date', course)
  const accessibleTo = propOr('', 'accessible_to', course)
  // @ts-ignore
  const initialValue = isNotNilOrEmpty(endDate) ? new Date(endDate) : new Date()
  const studentDetails = useSelector(getStudent)
  const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'
  const maxDate = isNotNilOrEmpty(accessibleTo)
    ? // @ts-ignore
      new Date(accessibleTo)
    : new Date()

  const fetchCurrentCourse = React.useCallback(
    () => dispatch(fetchCurrentCourseRoutine()),
    [dispatch]
  )

  const handleMouseLeave = () => {
    setIsFocused(false)
    setIsCalendarOpen(false)
  }

  const handleMouseEnter = () => {
    setIsFocused(true)
  }

  const handleOnChange = date => {
    const onSuccess = () => {
      setIsCalendarOpen(false)
      setTimeout(() => setIsFocused(false), 200)
      fetchCurrentCourse()
    }
    const onError = e => {
      console.error(e)
    }

    setCourseEndDate({ id: courseId, expected_end_date: formatToISO(date) })
      .then(onSuccess)
      .catch(onError)
  }

  return (
    <Container
      isCalendarOpen={isCalendarOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* @ts-ignore */}
      <Calendar
        selected={initialValue}
        id='course-end-date'
        onChange={handleOnChange}
        maxDate={maxDate}
        minDate={new Date()}
      >
        <InfoButton
          isDarkTheme={isDarkTheme}
          isFocused={isFocused}
          onClick={() => setIsCalendarOpen(true)}
        >
          <IconContainer>
            <CalendarIcon />
          </IconContainer>
          <Label isFocused={isFocused} isCalendarOpen={isCalendarOpen}>
            <span>{t('dashboard.speedometer.targetCompletionDate')}</span>
            <ArrowDownIcon />
          </Label>
        </InfoButton>
      </Calendar>
    </Container>
  )
}

export default EditCompletionDate

const Label = styled.div`
  width: ${({ isFocused }) => (isFocused ? 'auto' : 0)};
  opacity: ${({ isFocused }) => (isFocused ? 1 : 0)};
  transition: opacity 800ms ${({ theme }) => theme.transitions.easing.easeInOut};
  white-space: nowrap;
  overflow: hidden;
  padding-left: ${({ isFocused }) => (isFocused ? '6px' : 0)};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    transform: translateY(1px);
    font-size: 16px;
    color: ${({ theme }) => theme.colors.main.heading};
    transform: ${({ isCalendarOpen }) =>
      isCalendarOpen ? 'rotate(180deg)' : 'none'};
    transition: transform 800ms
      ${({ theme }) => theme.transitions.easing.easeInOut};
  }
`

const Container = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  top: -8px;
  right: 0px;
  font-family: ${({ theme }) => theme.typography.fontFamily} !important;

  .react-datepicker__header * {
    color: ${({ theme }) => theme.colors.main.heading};
  }

  .react-datepicker {
    display: ${({ isCalendarOpen }) =>
      isCalendarOpen ? 'inline-block' : 'none'};
  }
`

const InfoButton = styled.div`
  cursor: pointer;
  height: 24px;
  width: ${({ isFocused }) => (isFocused ? '170px' : '32px')};
  transition: width 800ms ${({ theme }) => theme.transitions.easing.easeInOut};
  padding: 0 10px;
  border: 1px solid
    ${({ theme }) => theme.colors.dashboard.speedometer.infoButtonBorder};
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.dashboard.speedometer.buttonText};
  background: ${({ theme, isDarkTheme }) =>
    !isDarkTheme
      ? theme.colors.main.white
      : theme.colors.dashboard.speedometer.infoButtonBackground};
`

const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.heading};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`

import * as R from 'ramda'
import { isInArray, isNotNilOrEmpty } from './ramda'

export const isElementAvailable = (isFreeTrial, element, availableBookTags) => {
  const isInAvailablePartByTags = R.pipe(
    R.propOr([], 'tags'),
    R.any(
      tag =>
        // @ts-ignore
        isInArray(availableBookTags)(tag.tag) &&
        // @ts-ignore
        tag.part === 1 &&
        // @ts-ignore
        tag.chapter_order === 1
    )
  )(element)

  const isInAvailablePartByProps = R.pipe(
    R.ifElse(
      isNotNilOrEmpty,
      R.pipe(
        R.pick(['part', 'chapter_order']),
        // @ts-ignore
        R.values,
        R.all(R.equals(1))
        // @ts-ignore
      ),
      R.always(false)
    )
  )(element)

  const isInAvailablePart = isInAvailablePartByTags || isInAvailablePartByProps

  return isFreeTrial ? isInAvailablePart : true
}

export type ValueOf<T> = T[keyof T]

export type Nullable<T> = T | null

export interface PaginationProps {
  page: number
  take: number
  recordsTotal: number
  pagesTotal: number
}

export const ResponseStatus = {
  initial: 'initial',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected'
} as const

export type ResponseStatus = ValueOf<typeof ResponseStatus>

// module/exams/ducks/selectors.ts - Exam Saga Selectors
import * as R from 'ramda'
import * as Reselect from 'reselect'
import { RootReducer } from 'types'
import { createSelector } from 'reselect'
import { API_STATES } from '../../../utils/redux'

export const selectFlashcards = (state: any) => state.flashcards
const selectAuth = (state: RootReducer) => state.auth

export const getFlashcardsPagination: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(
  selectFlashcards,
  R.propOr(
    {
      page: 1,
      take: 10,
      recordsTotal: 0,
      pagesTotal: 1
    },
    'pagination'
  )
)

export const getArchiveFlashcardsPagination: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(
  selectFlashcards,
  R.propOr(
    {
      page: 1,
      take: 10,
      recordsTotal: 0,
      pagesTotal: 1
    },
    'flashcardsArchivePagination'
  )
)

export const getFlashcardsList: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectFlashcards, R.propOr([], 'flashcards'))

export const getArchiveFlashcardsList: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectFlashcards, R.propOr([], 'flashcardsArchive'))

export const getFlashcardsStudyList: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(selectFlashcards, R.propOr([], 'studyList'))

export const getFlashcardsProficiencyLevelStats: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(
  state => state.flashcards.proficiencyLevelStats,
  R.sortBy(R.prop('proficiency_level'))
)

export const getFlashcardsSelectedSide = createSelector(
  selectAuth,
  R.pathOr('front', ['student', 'flashcard_study_mode'])
)

export const getFlashcardsStudyListPagination: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = Reselect.createSelector(
  selectFlashcards,
  R.propOr(
    {
      is_last_flashcard: false
    },
    'studyListPagination'
  )
)

export const isFlashcardsLoading = createSelector(
  selectFlashcards,
  R.pipe(
    R.propOr(API_STATES.PRISTINE, 'state'),
    R.equals(API_STATES.IN_PROGRESS)
  )
)

export const getSkippedCount: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(selectFlashcards, R.propOr(0, 'skippedCards'))

export const getCustomBoxes: Reselect.OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectFlashcards,
  R.pipe(
    R.propOr([], 'customBoxes'),
    R.sort((a, b) =>
      // @ts-ignore
      a.title.localeCompare(b.title, undefined, { numeric: true })
    )
  )
)

export const getCustomBoxesOptionsByBook = createSelector(
  getCustomBoxes,
  (state, book_id) => book_id,
  (customBoxes, book_id) =>
    R.pipe(
      // @ts-ignore
      R.filter(R.propEq('student_book_id', book_id)),
      // @ts-ignore
      R.map(
        // @ts-ignore
        R.applySpec({
          // @ts-ignore
          label: R.prop('title'),
          value: R.prop('id')
        })
      )
      // @ts-ignore
    )(customBoxes)
)

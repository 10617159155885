import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import ColumnLeftFlashcardsStudy from 'modules/flashcardsStudy/ColumnLeftFlashcardsStudy'
import ColumnRightFlashcardsStudy from 'modules/flashcardsStudy/ColumnRightFlashcardsStudy'
import styled from 'styled-components'
import QueryListenerFlashcardsStudy from 'modules/flashcardsStudy/QueryListenerFlashcardsStudy'
import HeaderFlashcardsStudy from 'modules/flashcardsStudy/HeaderFlashcardsStudy'

export const FlashcardsStudy = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.flashcards.study')}</title>
      </Helmet>
      <QueryListenerFlashcardsStudy />
      <Wrapper>
        <HeaderFlashcardsStudy />
        <ContentWrapper>
          <ColumnLeftFlashcardsStudy />
          <ColumnRightFlashcardsStudy />
        </ContentWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

export default FlashcardsStudy

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ theme }) =>
    `calc(100vh - 8px - ${theme.dimensions.studentTopNavHeightMenu} - ${theme.dimensions.footerHeight})`};
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

// pages/Exam.tsx - Exam route

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Exam from 'modules/exam/components/Exam'
import {
  disableCopyPaste,
  enableCopyPaste,
  preloadExamImages
} from 'utils/exam'

// Exam route
//
// This route serves the exam content.
export const ExamRoute = (): JSX.Element => {
  // Get i18n handler
  const { t } = useTranslation()

  React.useEffect(() => {
    disableCopyPaste()
    preloadExamImages()

    return () => {
      enableCopyPaste()
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.exam.main')}</title>
      </Helmet>
      <Exam />
    </React.Fragment>
  )
}

export default ExamRoute

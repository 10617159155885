import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CalendarIcon, NewTabIcon, Tooltip } from 'examkrackers-components'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'
import { fetchTutoringDays } from 'services/TutoringScheduleRepository'
import { formatTime } from 'modules/exam/utils/time'
import { useTranslation } from 'react-i18next'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/notifications/utils/events'

interface NotificationCalendarProps {
  groupTitle: string
  buttonText: string
}

interface ITutoringDay {
  id: string
  class_time: string
  class_time_end: string
  course_id: string
  class_date: Date
  class_topic: string
  class_topic_number: string
  meeting_url: string
  weekday: string
  weekday_end: string
}

const NotificationCalendarTutoring = ({
  groupTitle,
  buttonText
}: NotificationCalendarProps) => {
  const { t } = useTranslation()
  const [tutoringDays, setTutoringDays] = useState([])
  const hasTutoring = tutoringDays.length > 0
  const currentCourse: Course = useSelector(getCurrentCourse)
  const courseId = currentCourse?.book_course_id

  const fetchList = async () => {
    const response = await fetchTutoringDays(courseId as string)
    const data = await response.data
    setTutoringDays(data)
  }

  useEffect(() => {
    if (courseId !== null && courseId !== undefined && courseId !== '') {
      fetchList()
    }
  }, [courseId])

  const handleClick = url => {
    if (url) {
      return window.open(url, '_blank', 'noopener,noreferrer')
    }
    return () => {}
  }

  const formatTopic = (topic, topicNumber) => {
    const value = `${topic || ' '}  ${topicNumber || ' '}`
    return value.trim() || ''
  }

  const displayFormattedTime = (startTime, endTime) => {
    const formattedStartTime = formatTime(startTime)
    const formattedEndTime = formatTime(endTime)

    if (formattedEndTime.includes('AM') && formattedStartTime.includes('AM')) {
      return `${formattedStartTime.replace('AM', '')} - ${formattedEndTime}`
    }
    if (formattedEndTime.includes('PM') && formattedStartTime.includes('PM')) {
      return `${formattedStartTime.replace('PM', '')} - ${formattedEndTime}`
    }

    return `${formattedStartTime} - ${formattedEndTime}`
  }

  const handleFetchList = isOpen => {
    !isOpen && fetchList()
  }

  useEffect(() => {
    eventEmitter.on(events.fetchList, handleFetchList)
    return () => {
      eventEmitter.off(events.fetchList, handleFetchList)
    }
  }, [])

  return (
    <>
      {!hasTutoring ? (
        <div />
      ) : (
        <Container>
          <CalendarGroupWrapper>
            <IconContainer>
              <CalendarIcon />
            </IconContainer>
            <GroupTitle>
              {groupTitle}&nbsp;
              <span> {t('notifications.timezone')}</span>
            </GroupTitle>
          </CalendarGroupWrapper>
          <SubTitle>{t('notifications.nextDays')}</SubTitle>
          <ContentWrapper>
            {tutoringDays.map((tutoringDay: ITutoringDay) => {
              const hasMeetingUrl =
                tutoringDay?.meeting_url !== '' &&
                tutoringDay?.meeting_url !== null
              return (
                <ClassesWrapper
                  key={`index-${tutoringDay.id}`}
                  meetingUrl={tutoringDay.meeting_url}
                  onClick={() => {
                    handleClick(tutoringDay.meeting_url)
                  }}
                >
                  {hasMeetingUrl ? (
                    <TooltipContainer data='tooltip-container'>
                      <Tooltip
                        tooltipContent={t('notifications.tooltip')}
                        id={tutoringDay.id}
                      >
                        <ClassWrapper>
                          <ClassTime>
                            <p>
                              {`${tutoringDay.weekday} ${displayFormattedTime(
                                tutoringDay.class_time,
                                tutoringDay.class_time_end
                              )} ${formatTopic(
                                tutoringDay.class_topic,
                                tutoringDay.class_topic_number
                              )}`}
                            </p>
                          </ClassTime>
                          <IconWrapper>
                            {tutoringDay.meeting_url ? (
                              <NewTabIcon width='10px' height='10px' />
                            ) : null}
                          </IconWrapper>
                        </ClassWrapper>
                      </Tooltip>
                    </TooltipContainer>
                  ) : (
                    <ClassWrapper>
                      <ClassTime>
                        <p>
                          {`${tutoringDay.weekday} ${displayFormattedTime(
                            tutoringDay.class_time,
                            tutoringDay.class_time_end
                          )} ${formatTopic(
                            tutoringDay.class_topic,
                            tutoringDay.class_topic_number
                          )}`}
                        </p>
                      </ClassTime>
                      <IconWrapper>
                        {tutoringDay.meeting_url ? (
                          <NewTabIcon width='10px' height='10px' />
                        ) : null}
                      </IconWrapper>
                    </ClassWrapper>
                  )}
                </ClassesWrapper>
              )
            })}
          </ContentWrapper>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.main.grey300};
  border-radius: 6px;
  padding: 8px;
  flex-direction: column;
  min-width: 178px;
  width: 100%;
`

const CalendarGroupWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  gap: 4px;
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.heading};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const GroupTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 4px;
  > span {
    align-self: center;
    font-size: 9px;
    font-weight: 400;
  }
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 10px !important;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
`

const ClassesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: ${({ meetingUrl }) => (meetingUrl ? 'pointer' : 'unset')};
`
const ClassWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.main.grey300};
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`
const ClassTime = styled.div`
  color: ${({ theme }) => theme.colors.main.text};
  font-size: 11px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 8px;
`

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

const ContentWrapper = styled.div`
  overflow: auto;
  max-height: 200px;
`

const TooltipContainer = styled.div`
  & > div,
  & > div > div:first-child {
    width: 100%;
    cursor: pointer;
  }
`

export default NotificationCalendarTutoring

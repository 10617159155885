import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { saveCanvasToQuestion } from 'services/ExamsService'
import { SEVERITY } from 'utils/toast'

import { showToastRoutine } from 'modules/toast/ducks/actions'
import { fetchQuestionHtmlRoutine } from 'modules/exam/ducks/actions'

import {
  Button,
  EditIcon,
  SelectIcon,
  PolygonIcon,
  LineIcon,
  SaveIcon,
  CircleIcon,
  RedoIcon,
  UndoIcon,
  CloseIcon,
  TrashIcon
} from 'examkrackers-components'
import ModalColorPicker from 'modules/markExam/components/ModalColorPicker'
import ModalImageUploader from 'modules/markExam/components/ModalImageUploader'
import ModalTextUploader from 'modules/markExam/components/ModalTextUploader'

// TODO: change it to react-sketch when issue will be resolved
// https://github.com/tbolis/react-sketch/issues/129
// @ts-ignore
import { Tools } from 'react-sketch2'
import * as R from 'ramda'

export const CanvasTools = ({
  canUndo,
  canRedo,
  updateUndoAndRedo,
  onClear,
  tool,
  setNewTool,
  color,
  setNewColor,
  sketchRef,
  onClose,
  backgroundImage
}): JSX.Element => {
  const dispatch = useDispatch()
  const params = useParams()
  const [isSaving, setIsSaving] = useState(false)

  const questionId: string = R.propOr('', 'questionId', params)

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const fetchQuestionHtml = useCallback(
    () => dispatch(fetchQuestionHtmlRoutine({ id: questionId })),
    [dispatch]
  )

  const handleUndo = () => {
    sketchRef.current.undo()
    updateUndoAndRedo()
  }

  const handleRedo = () => {
    sketchRef.current.redo()
    updateUndoAndRedo()
  }

  useEffect(() => {
    setTimeout(updateUndoAndRedo, 300)
  }, [sketchRef])

  const handleClear = () => {
    sketchRef.current.clear()
    onClear()
  }

  const saveCanvasInDatabase = (callback = () => {}) => {
    const canvasJSON = sketchRef.current.toJSON()
    setIsSaving(true)

    const onSuccess = () => {
      showToast({
        key: 'toast.examMarksSaved',
        severity: SEVERITY.success
      })
      callback()
      fetchQuestionHtml()
      setIsSaving(false)
    }
    const onError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
      setIsSaving(false)
    }

    saveCanvasToQuestion({
      id: questionId,
      canvas: canvasJSON,
      backgroundImage: backgroundImage
    })
      .then(onSuccess)
      .catch(onError)
  }

  const handleSaveCanvas = () => saveCanvasInDatabase()

  const handleCloseCanvas = () => {
    onClose()
    saveCanvasInDatabase()
  }

  return (
    <ToolsContainer tool={tool}>
      <div className='tools-container'>
        <Button
          id={Tools.Select}
          size='small'
          onClick={setNewTool(Tools.Select)}
        >
          <SelectIcon />
        </Button>
        <Button
          id={Tools.Pencil}
          size='small'
          onClick={setNewTool(Tools.Pencil)}
        >
          <EditIcon />
        </Button>
        <Button id={Tools.Line} size='small' onClick={setNewTool(Tools.Line)}>
          <LineIcon />
        </Button>
        <Button
          id={Tools.Rectangle}
          size='small'
          onClick={setNewTool(Tools.Rectangle)}
        >
          <PolygonIcon />
        </Button>
        <Button
          id={Tools.Circle}
          size='small'
          onClick={setNewTool(Tools.Circle)}
        >
          <CircleIcon />
        </Button>
        <ModalColorPicker currentColor={color} setColor={setNewColor} />
        <ModalImageUploader sketchRef={sketchRef} />
        <ModalTextUploader sketchRef={sketchRef} />
      </div>
      <div className='tools-container'>
        <Button size='small' disabled={!canUndo} onClick={handleUndo}>
          <UndoIcon />
        </Button>
        <Button size='small' disabled={!canRedo} onClick={handleRedo}>
          <RedoIcon />
        </Button>
        <Button size='small' onClick={handleClear}>
          <TrashIcon />
        </Button>
        <Button
          size='small'
          onClick={handleSaveCanvas}
          disabled={isSaving}
          isLoading={isSaving}
        >
          <SaveIcon />
        </Button>
        <Button
          size='small'
          onClick={handleCloseCanvas}
          disabled={isSaving}
          isLoading={isSaving}
        >
          <CloseIcon />
        </Button>
      </div>
    </ToolsContainer>
  )
}

export default CanvasTools

const ToolsContainer = styled.div`
  height: 40px;
  z-index: ${props => props.theme.zIndex.modal};
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  width: 100%;
  padding: 0 0.5rem;

  .tools-container {
    display: flex;
    align-items: center;
  }

  button {
    margin-right: 0.5rem;
  }

  ${props => `#${props.tool}`} {
    background-color: ${({ theme }) => theme.colors.main.primary500};
  }
`

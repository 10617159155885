import React, { useEffect, useState, memo } from 'react'
import styled from 'styled-components'
import { Modal, RadioButton } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import FormAddToCustomBox from 'modules/flashcards/forms/FormAddToCustomBox'
import FormCreateAndAddToCustomBox from 'modules/flashcards/forms/FormCreateAndAddToCustomBox'
import { useDispatch } from 'react-redux'
import { fetchCustomBoxesRoutine } from 'modules/flashcards/ducks/actions'
import { isNilOrEmpty } from '../../../utils/ramda'

export const ModalAddToCustomBox = ({
  flashcard,
  open,
  handleClose
}): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState('')

  const fetchCustomBoxes = React.useCallback(
    () => dispatch(fetchCustomBoxesRoutine()),
    [dispatch]
  )

  const options = {
    createNew: {
      label: t('flashcards.addToCustomBox.radioButtons.new'),
      value: 'create_new_custom_box'
    },
    select: {
      label: t('flashcards.addToCustomBox.radioButtons.select'),
      value: 'select_custom_box'
    }
  }

  useEffect(() => {
    if (isNilOrEmpty(options)) {
      fetchCustomBoxes()
    }
  }, [options])

  const handleChange = value => setSelectedOption(value)

  const getFormByOption = () => {
    switch (selectedOption) {
      case options.createNew.value:
        return (
          <FormCreateAndAddToCustomBox
            flashcard={flashcard}
            handleClose={handleClose}
          />
        )
      case options.select.value:
        return (
          <FormAddToCustomBox flashcard={flashcard} handleClose={handleClose} />
        )
      default:
        return <div />
    }
  }

  return (
    <Modal
      title={t('flashcards.addToCustomBox.title')}
      open={open}
      handleClose={handleClose}
    >
      <>
        <RadioButtonsContainer>
          <RadioButton
            label={options.createNew.label}
            value={options.createNew.value}
            isChecked={selectedOption === options.createNew.value}
            onChange={handleChange}
          />
          <RadioButton
            label={options.select.label}
            value={options.select.value}
            isChecked={selectedOption === options.select.value}
            onChange={handleChange}
          />
        </RadioButtonsContainer>
        <FormContainer>{getFormByOption()}</FormContainer>
      </>
    </Modal>
  )
}

export default memo(ModalAddToCustomBox)

const RadioButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`

import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { WysiwygEditorField } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchNotesForSubchapterRoutine,
  upsertNoteForSubchapterRoutine
} from 'modules/books/ducks/actions'
import debounce from 'lodash.debounce'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getCurrentSubchapterNotes } from 'modules/books/ducks/selectors'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'
import { BOOK_VIEWER_PARAMS } from 'utils/books'

export const NotesPreview = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notes = useSelector(getCurrentSubchapterNotes)
  const parsedNotes = JSON.parse(propOr(null, 'delta_object', notes))

  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const fetchNotesForSubchapter = useCallback(
    sectionId => dispatch(fetchNotesForSubchapterRoutine({ sectionId })),
    [dispatch]
  )

  const currentSectionId = propOr(
    '',
    BOOK_VIEWER_PARAMS.sectionIdContext,
    parsedQuery
  )

  useEffect(() => {
    if (isNotNilOrEmpty(currentSectionId)) {
      fetchNotesForSubchapter(currentSectionId)
    }
  }, [search])

  const handleSuccess = () => {
    setTimeout(() => eventEmitter.emit(events.notesLoading, false), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedSuccess, true), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedSuccess, false), 2000)
    // fetchNotesForSubchapter(currentSectionId)
  }

  const handleError = () => {
    setTimeout(() => eventEmitter.emit(events.notesLoading, false), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedFailure, true), 1000)
    setTimeout(() => eventEmitter.emit(events.notesSavedFailure, false), 2000)
  }

  const handleSaveNote = useCallback(
    payload => dispatch(upsertNoteForSubchapterRoutine(payload)),
    [dispatch]
  )

  const handleOnChange = (name: string, value: any) => {
    eventEmitter.emit(events.notesLoading, true)

    const delta_object = value.getContents()
    const raw = value.getText()

    const payload = {
      values: {
        delta_object,
        raw,
        sectionId: currentSectionId
      },
      callback: handleSuccess,
      onFailure: handleError
    }

    handleSaveNote(payload)
  }

  const debounceHandler = debounce(handleOnChange, 300)

  const editor = (
    <WysiwygEditorField
      key='note-editor'
      id='create-subchapter-note'
      t={t}
      name='delta_object'
      onChange={debounceHandler}
      validate={() => true}
      initialValue={parsedNotes}
      formats={{
        size: true,
        header: true,
        bold: true,
        italic: true,
        underline: true,
        orderedList: true,
        bulletList: true,
        align: true,
        scriptSuper: true,
        scriptSub: true,
        blockquote: true,
        formula: true,
        clean: true
      }}
    />
  )

  return <EditorWrapper>{editor}</EditorWrapper>
}

export default NotesPreview

const EditorWrapper = styled.form`
  border-radius: 8px;
  overflow: hidden;
  height: 100%;

  #create-subchapter-note {
    flex-grow: 1;
  }

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

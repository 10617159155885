import { map, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getTheme } from 'examkrackers-components'
import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

export const ReadProgressChart = data => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const theme = getTheme()

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  // @ts-ignore
  const percentageOfReadBook = map(propOr('', ['percent']))(data) || []
  // @ts-ignore
  const booksTags: [string] = map(propOr('', ['book_tag']))(data) || []

  const redirectToBook = dataPointIndex => {
    const bookDetails = data[dataPointIndex]
    const bookId = propOr('', 'original_book_id', bookDetails)
    const chapterOrder = propOr('', 'last_chapter', bookDetails)
    const partOrder = propOr('', 'last_part', bookDetails)
    const bookUrl = `/books/${bookId}/chapter/${chapterOrder}/part/${partOrder}`

    push(bookUrl)
  }

  const booksTagsToUpperCase = booksTags?.map(el => el.toUpperCase())
  return {
    series: [
      {
        data: percentageOfReadBook
      }
    ],
    legend: {
      show: false
    },
    chart: {
      id: 'bookChart',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const { dataPointIndex } = config
          redirectToBook(dataPointIndex)
        }
      },
      background: theme.colors.main.white,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          position: 'top'
        },
        horizontal: true,
        columnWidth: '25px',
        barHeight: '25px',
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        colors: {
          backgroundBarColors: ['url(#GradientBook)'],
          backgroundBarRadius: 5
        }
      }
    },
    title: {
      text: t('dashboard.charts.bookProgress'),
      align: 'left',
      style: {
        fontSize: '16',
        fontFamily: 'Arial, sans-serif'
      },
      margin: 20,
      offsetX: 42
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: theme.colors.dashboard.barsFrom,
            opacity: 1
          },
          {
            offset: 100,
            color: theme.colors.dashboard.barsTo,
            opacity: 1
          }
        ]
      }
    },
    dataLabels: {
      textAnchor: 'start',
      enabled: true,
      offsetX: 500,
      style: {
        fontSize: '12',
        // @ts-ignore
        colors: [theme.colors.dashboard.dataLabels],
        fontFamily: 'Arial, sans-serif',
        fontWeight: 400
      },
      formatter: val => val + '%'
    },
    stroke: {
      show: false
    },
    grid: {
      show: false,
      padding: {
        top: -20,
        right: 80,
        bottom: 0,
        left: 30
      }
    },
    tooltip: {
      enabled: false
    },
    xaxis: {
      show: false,
      categories: booksTagsToUpperCase,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      max: 100,
      labels: {
        show: true,
        align: 'left',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: isDarkTheme ? '#ffffff' : '#000000',
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          fontWeight: 700,
          cssClass: 'apexcharts-yaxis-label'
        },
        offsetX: -10,
        offsetY: 3,
        rotate: 0
      },
      axisBorder: {
        show: false
      },
      tooltip: {
        enabled: true,
        offsetX: 0
      }
    }
  }
}

import { EventEmitter } from 'events'
import * as R from 'ramda'
export const proficiencyLevelAnimationEmitter = new EventEmitter()

const ANIMATION_EVENTS = {
  startAnimation: 'start-proficiency-level-animation',
  startSkippedAnimation: 'start-skipped-animation',
  setRoundOffset: 'set-round-offset'
}

const calculateAnimationDimensions = directions => {
  const wrapperElement = document.getElementById('wrapper')
  const fromElement = document.getElementById(`drawer-${directions.from}`)
  const toElement = document.getElementById(`drawer-${directions.to}`)
  // @ts-ignore
  const wrapperRect = wrapperElement.getBoundingClientRect()
  // @ts-ignore
  const fromRect = fromElement.getBoundingClientRect()
  // @ts-ignore
  const toRect = toElement.getBoundingClientRect()

  const fromOffset = fromRect.top - wrapperRect.top
  const toOffset = toRect.top - wrapperRect.top - fromOffset - 10

  return {
    toOffset,
    top: fromRect.top - wrapperRect.top,
    left: fromRect.left - wrapperRect.left + 3
  }
}

const calculateSkippedAnimationDimensions = box => {
  const wrapperElement = document.getElementById('wrapper')
  const fromElement = document.getElementById(`drawer-${box}`)
  // @ts-ignore
  const wrapperRect = wrapperElement.getBoundingClientRect()
  // @ts-ignore
  const fromRect = fromElement.getBoundingClientRect()
  // @ts-ignore

  return {
    top: fromRect.top - wrapperRect.top,
    left: fromRect.left - wrapperRect.left + 3
  }
}

export const startProficiencyLevelAnimation = (from, to) => {
  proficiencyLevelAnimationEmitter.emit(
    ANIMATION_EVENTS.startAnimation,
    calculateAnimationDimensions({
      from: from - 1,
      to: to - 1
    })
  )
}

export const catchProficiencyLevelAnimationTrigger = callback => {
  proficiencyLevelAnimationEmitter.on(ANIMATION_EVENTS.startAnimation, callback)
}

export const startSkippedAnimation = plvl => {
  proficiencyLevelAnimationEmitter.emit(
    ANIMATION_EVENTS.startSkippedAnimation,
    calculateSkippedAnimationDimensions(plvl - 1)
  )
}

export const catchSkippedAnimationTrigger = callback => {
  proficiencyLevelAnimationEmitter.on(
    ANIMATION_EVENTS.startSkippedAnimation,
    callback
  )
}

export const setRoundNumber = round => {
  proficiencyLevelAnimationEmitter.emit(ANIMATION_EVENTS.setRoundOffset, round)
}

export const catchRoundNumber = callback => {
  proficiencyLevelAnimationEmitter.on(ANIMATION_EVENTS.setRoundOffset, callback)
}

export const FLASHCARD_SIDE = {
  front: 'front',
  back: 'back'
}

export const getNumberOfPagesInProficiencyLevelDrawer = flashcardsCount => {
  switch (true) {
    case flashcardsCount > 0 && flashcardsCount <= 9:
      return 1
    case flashcardsCount >= 10 && flashcardsCount <= 99:
      return 2
    case flashcardsCount > 99:
      return 3
    default:
      return 0
  }
}

export const statsWithSkippedIncluded = (stats, plvl, skippedCount) => {
  return R.map((stat: { proficiency_level: number; count: number }) =>
    stat.proficiency_level === Number(plvl)
      ? { ...stat, count: stat.count + skippedCount }
      : stat
  )(stats)
}

export const findLowestLevelWithFlashcards = stats => {
  const levelsWithCards = stats
    .filter(stat => stat.count > 0)
    .map(stat => stat.proficiency_level)
  return R.head(levelsWithCards) || 1
}

export const findNextLevelWithFlashcards = (stats, currentLevel) => {
  const levelsWithCards = stats
    .filter(stat => stat.count > 0)
    .filter(stat => stat.proficiency_level > Number(currentLevel))
    .map(stat => stat.proficiency_level)
  return R.head(levelsWithCards) || 1
}

export const checkIfNextLevelEmpty = (stats, currentLevel) => {
  const nextProficiencyLevelStats = stats
    .filter(stat => stat.proficiency_level === Number(currentLevel) + 1)
    .map(stat => stat.proficiency_level)
  return R.propOr(0, 'count', nextProficiencyLevelStats) === 0
}

export const BOX_LEVELS = {
  master: 'master',
  book: 'book'
}

export const QUERY_PARAMS = {
  mainExpandState: 'mainExpandState',
  expandedBookLevels: 'expandedBookLevels'
}

import {
  fetchScoreProjectionDataRoutine,
  toggleExamExclusionRoutine,
  fetchPassageReadingTimeGraphRoutine,
  fetchQuestionsGraphDataRoutine,
  fetchTargetQuestionsGraphDataRoutine,
  fetchAverageQuestionsGraphDataRoutine,
  fetchTargetPassagesGraphDataRoutine,
  fetchAveragePassagesGraphDataRoutine,
  fetchPassageTimersRoutine,
  fetchSingleQuestionMetricsRoutine,
  fetchExamTypeScoreProjectionsRoutine,
  saveComparedExamRoutine,
  saveComparedExamQuestionsRoutine,
  saveComparedExamPassagesRoutine,
  fetchComparedTargetQuestionsGraphDataRoutine,
  fetchComparedAverageQuestionsGraphDataRoutine,
  fetchComparedTargetPassagesGraphDataRoutine,
  fetchComparedAveragePassagesGraphDataRoutine,
  fetchPassageWorkingTimeGraphRoutine,
  saveComparedExamPassagesWorkingRoutine,
  resetComparedExamRoutine,
  saveDiagnosticGraphLegendStateRoutine,
  fetchComparedPassageTimersRoutine
} from 'modules/diagnostics/ducks/actions'
import { Action, DiagnosticReducer } from 'types'
import { API_STATES } from 'utils/redux'
import * as R from 'ramda'

export const initialState: DiagnosticReducer = {
  state: API_STATES.PRISTINE,
  scoreProjectionData: [],
  passageReadingTimeGraph: [],
  passageWorkingTimeGraph: [],
  passageTimers: [],
  questionsGraphData: [],
  targetQuestionsGraphData: {
    id: '',
    section_order: 0,
    section_score: 0,
    timings: []
  },
  averageQuestionsGraphData: {
    id: '',
    section_order: 0,
    timings: []
  },
  targetPassagesGraphData: {
    id: '',
    section_order: 0,
    section_score: 0,
    timings: []
  },
  averagePassagesGraphData: {
    id: '',
    section_order: 0,
    timings: []
  },
  singleQuestionMetrics: {
    questionMetrics: {
      timers: {
        checking_sum: 0,
        checking_divisor: 0,
        reading_sum: 0,
        reading_divisor: 0,
        working_sum: 0,
        working_divisor: 0,
        checking_avg: 0,
        reading_avg: 0,
        working_avg: 0
      },
      max_working_avg: 0,
      max_reading_avg: 0
    },
    passageMetrics: {
      timers: {
        checking_sum: 0,
        checking_divisor: 0,
        reading_sum: 0,
        reading_divisor: 0,
        working_sum: 0,
        working_divisor: 0,
        checking_avg: 0,
        reading_avg: 0,
        working_avg: 0
      },
      max_working_avg: 0,
      max_reading_avg: 0,
      max_checking_avg: 0
    },
    questionMetricsAvg: {
      timers: {
        checking_sum: 0,
        checking_divisor: 0,
        reading_sum: 0,
        reading_divisor: 0,
        working_sum: 0,
        working_divisor: 0,
        checking_avg: 0,
        reading_avg: 0,
        working_avg: 0
      },
      max_working_avg: 0,
      max_reading_avg: 0
    },
    passageMetricsAvg: {
      timers: {
        checking_sum: 0,
        checking_divisor: 0,
        reading_sum: 0,
        reading_divisor: 0,
        working_sum: 0,
        working_divisor: 0,
        checking_avg: 0,
        reading_avg: 0,
        working_avg: 0
      },
      max_working_avg: 0,
      max_reading_avg: 0,
      max_checking_avg: 0
    },
    studentsQuestionMetrics: {
      timers: {
        reading: 0,
        working: 0,
        checking: 0
      },
      max_question_working: 0,
      max_question_checking: 0
    },
    studentsPassageMetrics: {
      timers: {
        reading: 0,
        working: 0,
        checking: 0
      },
      max_passage_reading: 0,
      max_passage_working: 0,
      max_passage_checking: 0
    }
  },
  comparedExam: {
    passageTimers: [],
    passageReadingTimeGraph: [],
    passageWorkingTimeGraph: [],
    questionsGraphData: [],
    targetQuestionsGraphData: {
      id: '',
      section_order: 0,
      timings: []
    },
    averageQuestionsGraphData: {
      id: '',
      section_order: 0,
      timings: []
    },
    targetPassagesGraphData: {
      id: '',
      section_order: 0,
      timings: []
    },
    averagePassagesGraphData: {
      id: '',
      section_order: 0,
      timings: []
    },
    examDetails: {
      exam: {
        id: '',
        layout_id: '',
        student_id: '',
        exam_id: '',
        external_id: '',
        title: '',
        created_at: '',
        accessible_from: null,
        accessible_to: null,
        is_active: true,
        access_period: 0,
        status: '',
        is_intact: false,
        completed_at: null,
        timers: '',
        time_option: '',
        break_definition: null,
        completed_as: null,
        scores: {
          sections: [],
          exam: {
            percentile_rank: '0.00'
          }
        },
        is_excluded_from_pts: false,
        timer_checkboxes: null,
        exam_type_id: '',
        originalExam: {
          id: '',
          layout_id: '',
          title: '',
          file_name: '',
          created_at: '',
          uploaded_by: '',
          is_active: false,
          external_id: '',
          access_period: 0,
          exam_length: '',
          deleted_at: '',
          exam_type_id: '',
          google_form_url: null
        }
      },
      questions: [],
      state: {
        exam_seconds_left: null,
        current_page: null
      },
      type: {
        title: '',
        type: '',
        subtype: ''
      },
      layout: {
        id: '',
        title: ''
      },
      score_projection: []
    }
  },
  examTypeProjections: [],
  diagnosticGraphLegendState: {
    timePerQuestion: true,
    targetTimePerQuestion: true,
    averageTimePerQuestion: true,
    passages: true,
    difficulty: true
  }
}

// NOTE(Brett): REQUEST types will trigger loaders, place them with care.
export default (state = initialState, action: Action): DiagnosticReducer => {
  switch (action.type) {
    //
    // fetchScoreProjectionDataRoutine
    //
    case fetchScoreProjectionDataRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchScoreProjectionDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE,
        scoreProjectionData: []
      }
    case fetchScoreProjectionDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        scoreProjectionData: action.payload
      }

    //
    // toggleExamExclusionRoutine
    //
    case toggleExamExclusionRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        scoreProjectionData: action.payload
      }

    //
    // fetchPassageReadingTimeGraphRoutine
    //
    case fetchPassageReadingTimeGraphRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchPassageReadingTimeGraphRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchPassageReadingTimeGraphRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        passageReadingTimeGraph: action.payload
      }

    //
    // fetchPassageWorkingTimeGraphRoutine
    //
    case fetchPassageWorkingTimeGraphRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchPassageWorkingTimeGraphRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchPassageWorkingTimeGraphRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        passageWorkingTimeGraph: action.payload
      }

    //
    // fetchQuestionsGraphDataRoutine
    //
    case fetchQuestionsGraphDataRoutine.REQUEST:
      return {
        ...state,
        state:
          action.payload.id !==
          R.pathOr(
            '',
            ['passage', 'student_section_id'],
            state.questionsGraphData[0]
          )
            ? API_STATES.IN_PROGRESS
            : state.state
      }
    case fetchQuestionsGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchQuestionsGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        questionsGraphData: action.payload
      }

    //
    // fetchComparedExamData Routines
    //
    case saveComparedExamRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          examDetails: action.payload
        }
      }

    case saveComparedExamQuestionsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          questionsGraphData: action.payload
        }
      }

    case saveComparedExamPassagesRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          passageReadingTimeGraph: action.payload
        }
      }

    case saveComparedExamPassagesWorkingRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          passageWorkingTimeGraph: action.payload
        }
      }

    //
    // fetchTargetQuestionsGraphDataRoutine
    //
    case fetchTargetQuestionsGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchTargetQuestionsGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        targetQuestionsGraphData: action.payload
      }

    //
    // fetchAverageQuestionsGraphDataRoutine
    //
    case fetchAverageQuestionsGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchAverageQuestionsGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        averageQuestionsGraphData: action.payload
      }

    //
    // fetchTargetPassagesGraphDataRoutine
    //
    case fetchTargetPassagesGraphDataRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchTargetPassagesGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchTargetPassagesGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        targetPassagesGraphData: action.payload
      }

    //
    // fetchAveragePassagesGraphDataRoutine
    //
    case fetchAveragePassagesGraphDataRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchAveragePassagesGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchAveragePassagesGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        averagePassagesGraphData: action.payload
      }

    //
    // fetchPassageTimersRoutine
    //
    case fetchPassageTimersRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchPassageTimersRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchPassageTimersRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        passageTimers: action.payload
      }

    //
    // fetchSingleQuestionMetricsRoutine
    //
    case fetchSingleQuestionMetricsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        singleQuestionMetrics: action.payload
      }

    case fetchExamTypeScoreProjectionsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        examTypeProjections: action.payload
      }

    //
    // fetchComparedTargetQuestionsGraphDataRoutine
    //
    case fetchComparedTargetQuestionsGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchComparedTargetQuestionsGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          targetQuestionsGraphData: action.payload
        }
      }

    //
    // fetchComparedAverageQuestionsGraphDataRoutine
    //
    case fetchComparedAverageQuestionsGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchComparedAverageQuestionsGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          averageQuestionsGraphData: action.payload
        }
      }

    //
    // fetchComparedTargetPassagesGraphDataRoutine
    //
    case fetchComparedTargetPassagesGraphDataRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchComparedTargetPassagesGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchComparedTargetPassagesGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          targetPassagesGraphData: action.payload
        }
      }

    //
    // fetchComparedAveragePassagesGraphDataRoutine
    //
    case fetchComparedAveragePassagesGraphDataRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case fetchComparedAveragePassagesGraphDataRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case fetchComparedAveragePassagesGraphDataRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          averagePassagesGraphData: action.payload
        }
      }

    //
    // resetComparedExamData
    //
    case fetchComparedPassageTimersRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        comparedExam: {
          ...state.comparedExam,
          passageTimers: action.payload
        }
      }
    //
    // resetComparedExamData
    //
    case resetComparedExamRoutine.SUCCESS:
      return {
        ...state,
        comparedExam: {
          passageTimers: [],
          passageReadingTimeGraph: [],
          passageWorkingTimeGraph: [],
          questionsGraphData: [],
          targetQuestionsGraphData: {
            id: '',
            section_order: 0,
            timings: []
          },
          averageQuestionsGraphData: {
            id: '',
            section_order: 0,
            timings: []
          },
          targetPassagesGraphData: {
            id: '',
            section_order: 0,
            timings: []
          },
          averagePassagesGraphData: {
            id: '',
            section_order: 0,
            timings: []
          },
          examDetails: {
            exam: {
              id: '',
              layout_id: '',
              student_id: '',
              exam_id: '',
              external_id: '',
              title: '',
              created_at: '',
              accessible_from: null,
              accessible_to: null,
              is_active: true,
              access_period: 0,
              status: '',
              is_intact: false,
              completed_at: null,
              timers: '',
              time_option: '',
              break_definition: null,
              completed_as: null,
              scores: {
                sections: [],
                exam: {
                  percentile_rank: '0.00'
                }
              },
              is_excluded_from_pts: false,
              timer_checkboxes: null,
              exam_type_id: '',
              originalExam: {
                id: '',
                layout_id: '',
                title: '',
                file_name: '',
                created_at: '',
                uploaded_by: '',
                is_active: false,
                external_id: '',
                access_period: 0,
                exam_length: '',
                deleted_at: '',
                exam_type_id: '',
                google_form_url: null
              }
            },
            questions: [],
            state: {
              exam_seconds_left: null,
              current_page: null
            },
            type: {
              title: '',
              type: '',
              subtype: ''
            },
            layout: {
              id: '',
              title: ''
            },
            score_projection: []
          }
        }
      }

    //
    case saveDiagnosticGraphLegendStateRoutine.SUCCESS:
      return {
        ...state,
        diagnosticGraphLegendState: action.payload.payload
      }
    default:
      return state
  }
}

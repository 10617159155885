import React, { useEffect, useState } from 'react'
import Speedometer from 'modules/dashboard/components/Speedometer'
import * as R from 'ramda'

import { fetchStudentCompletionMeter } from 'services/DashboardService'

export const SpeedometerController = (): JSX.Element => {
  const [isFinished, setIsFinished] = useState(false)
  const [values, setValues] = useState({
    speed: 0,
    temperature: 0,
    oil: 0,
    mileage: 0,
    acceleration: 0,
    velocityPercentage: 0
  })

  useEffect(() => {
    const handleSuccess = response => {
      const oil: number = R.pathOr(0, ['data', 'oil', 'oil_level'], response)
      const temperature: number = R.pathOr(
        0,
        ['data', 'temperature', 'temperature_value'],
        response
      )
      const mileage: number = R.pathOr(
        0,
        ['data', 'mileage', 'mileage'],
        response
      )
      const speed: number = R.pathOr(
        0,
        ['data', 'velocity', 'average_velocity'],
        response
      )
      const speedGreen: number = R.pathOr(
        0,
        ['data', 'velocity', 'red_green_velocity'],
        response
      )
      const acceleration: number = R.pathOr(
        0,
        ['data', 'acceleration', 'acceleration_value'],
        response
      )
      const velocityPercentage: number = (speedGreen / 500) * 100

      setValues({
        speed,
        temperature,
        oil,
        mileage,
        acceleration,
        velocityPercentage
      })
      setIsFinished(true)
    }
    const handleError = e => console.error(e)

    fetchStudentCompletionMeter().then(handleSuccess).catch(handleError)
  }, [])

  return isFinished ? (
    <Speedometer
      speed={values.speed}
      acceleration={values.acceleration}
      temperature={values.temperature}
      oil={values.oil}
      mileage={values.mileage}
      velocityPercentage={values.velocityPercentage}
    />
  ) : (
    <div />
  )
}

export default SpeedometerController

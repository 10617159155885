import { createRoutine } from 'redux-saga-routines'
import * as Effects from 'utils/saga'
import * as courseService from 'services/CourseService'
import * as timersService from 'services/TimersService'
import { ActionPayload } from 'types'
import {
  getChapterReadingTimePassedSeconds,
  getFlashcardsLearningTimePassedSeconds,
  getLearningTimeDetails,
  getLearningTimePassedSeconds,
  getVideoWatchingTimePassedSeconds
} from 'modules/learningTime/ducks/selectors'
import * as R from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { COURSE_LEARNING_TIME_STATUS } from 'utils/courses'
import { getIsOrdinaryStudent } from 'modules/auth/ducks/selectors'

export const fetchCourseTimeStateRoutine = createRoutine(
  'FETCH_COURSE_TIME_STATE'
)

export const setLearningTimePassedSecondsRoutine = createRoutine(
  'SET_LEARNING_TIME_PASSED_SECONDS'
)

export const setInactiveTimePassedSecondsRoutine = createRoutine(
  'SET_INACTIVE_TIME_PASSED_SECONDS'
)

export const handleUpdateCourseTimeStateRoutine = createRoutine(
  'UPDATE_COURSE_TIME_STATE'
)

export const resetInactiveTimePassedSecondsRoutine = createRoutine(
  'RESET_INACTIVE_TIME_PASSED_SECONDS'
)

export const setChapterReadingTimePassedSecondsRoutine = createRoutine(
  'SET_CHAPTER_READING_TIME_PASSED_SECONDS'
)

export const resetChapterReadingTimePassedSecondsRoutine = createRoutine(
  'RESET_CHAPTER_READING_TIME_PASSED_SECONDS'
)

export const handleUpdateChapterReadingTimeRoutine = createRoutine(
  'UPDATE_CHAPTER_READING_TIME'
)

export const setFlashcardsLearningTimePassedSecondsRoutine = createRoutine(
  'SET_FLASHCARDS_LEARNING_PASSED_SECONDS'
)

export const resetFlashcardsLearningTimePassedSecondsRoutine = createRoutine(
  'RESET_FLASHCARDS_LEARNING_PASSED_SECONDS'
)

export const handleUpdateFlashcardsLearningTimeRoutine = createRoutine(
  'UPDATE_FLASHCARDS_LEARNING'
)

export const setVideoWatchingTimePassedSecondsRoutine = createRoutine(
  'SET_VIDEO_WATCHING_PASSED_SECONDS'
)

export const resetVideoWatchingTimePassedSecondsRoutine = createRoutine(
  'RESET_VIDEO_WATCHING_PASSED_SECONDS'
)

export const handleUpdateVideoWatchingTimeRoutine = createRoutine(
  'UPDATE_VIDEO_WATCHING'
)

function* fetchCourseTimeState() {
  yield Effects.put(fetchCourseTimeStateRoutine.request())

  try {
    const result = yield Effects.call(courseService.fetchCourseTimeState)
    yield Effects.put(
      fetchCourseTimeStateRoutine.success(R.pathOr([], ['data'], result))
    )
  } catch (e) {
    yield Effects.put(fetchCourseTimeStateRoutine.failure(e))
    console.error(e)
  }
}

function* setLearningTimePassedSeconds() {
  const isOrdinaryStudent = yield Effects.select(getIsOrdinaryStudent)

  if (isOrdinaryStudent) {
    yield Effects.put(setLearningTimePassedSecondsRoutine.success())
  }
}

function* setInactiveTimePassedSeconds() {
  const isOrdinaryStudent = yield Effects.select(getIsOrdinaryStudent)

  if (isOrdinaryStudent) {
    yield Effects.put(setInactiveTimePassedSecondsRoutine.success())
  }
}

function* resetInactiveTimePassedSeconds() {
  yield Effects.put(resetInactiveTimePassedSecondsRoutine.success())
}

function* handleUpdateCourseTimeState(action: ActionPayload) {
  const { payload } = action
  const isOrdinaryStudent = yield Effects.select(getIsOrdinaryStudent)
  const isUnpause = R.propOr(false, 'unpause', payload)

  if (isOrdinaryStudent || isUnpause) {
    const manualStatus = R.propOr(null, 'status', payload)
    yield Effects.put(handleUpdateCourseTimeStateRoutine.request())

    const learningTimeDetails = yield Effects.select(getLearningTimeDetails)
    const passedSeconds = yield Effects.select(getLearningTimePassedSeconds)

    const hasManualStatus = isNotNilOrEmpty(manualStatus)

    const currentStatus = R.propOr(
      COURSE_LEARNING_TIME_STATUS.started,
      'state',
      learningTimeDetails
    )
    const currentSeconds: number = R.propOr(0, 'seconds', learningTimeDetails)

    const newStatus = hasManualStatus ? manualStatus : currentStatus

    try {
      yield Effects.call(courseService.updateCourseTimeState, {
        seconds: passedSeconds - currentSeconds,
        state: newStatus
      })
      yield Effects.put(fetchCourseTimeStateRoutine())
      yield Effects.put(handleUpdateCourseTimeStateRoutine.success())
    } catch (e) {
      yield Effects.put(handleUpdateCourseTimeStateRoutine.failure(e))
      console.error(e)
    }
  }
}

function* setChapterReadingTimePassedSeconds() {
  yield Effects.put(setChapterReadingTimePassedSecondsRoutine.success())
}

function* resetChapterReadingTimePassedSeconds() {
  yield Effects.put(resetChapterReadingTimePassedSecondsRoutine.success())
}

function* handleUpdateChapterReadingTime(action: ActionPayload) {
  const { payload } = action
  yield Effects.put(handleUpdateChapterReadingTimeRoutine.request())

  const passedSeconds = yield Effects.select(getChapterReadingTimePassedSeconds)
  try {
    yield Effects.call(timersService.updateChapterReadingTime, {
      seconds: passedSeconds,
      chapterId: R.propOr('', 'chapterId', payload)
    })
    yield Effects.put(resetChapterReadingTimePassedSecondsRoutine())
    yield Effects.put(handleUpdateChapterReadingTimeRoutine.success())
  } catch (e) {
    yield Effects.put(handleUpdateChapterReadingTimeRoutine.failure(e))
    console.error(e)
  }
}

function* setFlashcardsLearningTimePassedSeconds(action: ActionPayload) {
  const { payload } = action

  yield Effects.put(
    setFlashcardsLearningTimePassedSecondsRoutine.success({
      flashcardId: R.propOr('', 'flashcardId', payload)
    })
  )
}

function* resetFlashcardsLearningTimePassedSeconds(action: ActionPayload) {
  const { payload } = action
  yield Effects.put(
    resetFlashcardsLearningTimePassedSecondsRoutine.success({
      flashcardId: R.propOr('', 'flashcardId', payload)
    })
  )
}

function* handleUpdateFlashcardsLearningTime(action: ActionPayload) {
  const { payload } = action
  const flashcardId = R.propOr('', 'flashcardId', payload)
  yield Effects.put(handleUpdateFlashcardsLearningTimeRoutine.request())

  const passedSeconds = yield Effects.select(state =>
    getFlashcardsLearningTimePassedSeconds(state, flashcardId)
  )
  try {
    yield Effects.call(timersService.updateFlashcardsLearningTime, {
      seconds: passedSeconds,
      flashcardId
    })
    yield Effects.put(
      resetFlashcardsLearningTimePassedSecondsRoutine({ flashcardId })
    )
    yield Effects.put(handleUpdateFlashcardsLearningTimeRoutine.success())
  } catch (e) {
    yield Effects.put(handleUpdateFlashcardsLearningTimeRoutine.failure(e))
    console.error(e)
  }
}

function* setVideoWatchingTimePassedSeconds(action: ActionPayload) {
  const { payload } = action

  yield Effects.put(
    setVideoWatchingTimePassedSecondsRoutine.success({
      videoId: R.propOr('', 'videoId', payload)
    })
  )
}

function* resetVideoWatchingTimePassedSeconds(action: ActionPayload) {
  const { payload } = action
  yield Effects.put(
    resetVideoWatchingTimePassedSecondsRoutine.success({
      videoId: R.propOr('', 'videoId', payload)
    })
  )
}

function* handleUpdateVideoWatchingTime(action: ActionPayload) {
  const { payload } = action
  const videoId = R.propOr('', 'videoId', payload)
  yield Effects.put(handleUpdateVideoWatchingTimeRoutine.request())

  const passedSeconds = yield Effects.select(state =>
    getVideoWatchingTimePassedSeconds(state, videoId)
  )
  try {
    yield Effects.call(timersService.updateVideoWatchingTime, {
      seconds: passedSeconds,
      videoId
    })
    yield Effects.put(resetVideoWatchingTimePassedSecondsRoutine({ videoId }))
    yield Effects.put(handleUpdateVideoWatchingTimeRoutine.success())
  } catch (e) {
    yield Effects.put(handleUpdateVideoWatchingTimeRoutine.failure(e))
    console.error(e)
  }
}

function* fetchCourseTimeStateWatcher() {
  yield Effects.takeLatest(
    fetchCourseTimeStateRoutine.TRIGGER,
    fetchCourseTimeState
  )
}

function* setLearningTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    setLearningTimePassedSecondsRoutine.TRIGGER,
    setLearningTimePassedSeconds
  )
}

function* setInactiveTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    setInactiveTimePassedSecondsRoutine.TRIGGER,
    setInactiveTimePassedSeconds
  )
}

function* resetInactiveTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    resetInactiveTimePassedSecondsRoutine.TRIGGER,
    resetInactiveTimePassedSeconds
  )
}

function* handleUpdateCourseTimeStateWatcher() {
  yield Effects.takeLatest(
    handleUpdateCourseTimeStateRoutine.TRIGGER,
    handleUpdateCourseTimeState
  )
}

function* setChapterReadingTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    setChapterReadingTimePassedSecondsRoutine.TRIGGER,
    setChapterReadingTimePassedSeconds
  )
}

function* resetChapterReadingTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    resetChapterReadingTimePassedSecondsRoutine.TRIGGER,
    resetChapterReadingTimePassedSeconds
  )
}

function* handleUpdateChapterReadingTimeWatcher() {
  yield Effects.takeLatest(
    handleUpdateChapterReadingTimeRoutine.TRIGGER,
    handleUpdateChapterReadingTime
  )
}

function* setFlashcardsLearningTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    setFlashcardsLearningTimePassedSecondsRoutine.TRIGGER,
    setFlashcardsLearningTimePassedSeconds
  )
}

function* resetFlashcardsLearningTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    resetFlashcardsLearningTimePassedSecondsRoutine.TRIGGER,
    resetFlashcardsLearningTimePassedSeconds
  )
}

function* handleUpdateFlashcardsLearningTimeWatcher() {
  yield Effects.takeLatest(
    handleUpdateFlashcardsLearningTimeRoutine.TRIGGER,
    handleUpdateFlashcardsLearningTime
  )
}

function* setVideoWatchingTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    setVideoWatchingTimePassedSecondsRoutine.TRIGGER,
    setVideoWatchingTimePassedSeconds
  )
}

function* resetVideoWatchingTimePassedSecondsWatcher() {
  yield Effects.takeLatest(
    resetVideoWatchingTimePassedSecondsRoutine.TRIGGER,
    resetVideoWatchingTimePassedSeconds
  )
}

function* handleUpdateVideoWatchingTimeWatcher() {
  yield Effects.takeLatest(
    handleUpdateVideoWatchingTimeRoutine.TRIGGER,
    handleUpdateVideoWatchingTime
  )
}

export const learningTimeSagas = [
  Effects.fork(fetchCourseTimeStateWatcher),
  Effects.fork(setLearningTimePassedSecondsWatcher),
  Effects.fork(setInactiveTimePassedSecondsWatcher),
  Effects.fork(handleUpdateCourseTimeStateWatcher),
  Effects.fork(resetInactiveTimePassedSecondsWatcher),
  Effects.fork(setChapterReadingTimePassedSecondsWatcher),
  Effects.fork(resetChapterReadingTimePassedSecondsWatcher),
  Effects.fork(handleUpdateChapterReadingTimeWatcher),
  Effects.fork(setFlashcardsLearningTimePassedSecondsWatcher),
  Effects.fork(resetFlashcardsLearningTimePassedSecondsWatcher),
  Effects.fork(handleUpdateFlashcardsLearningTimeWatcher),
  Effects.fork(setVideoWatchingTimePassedSecondsWatcher),
  Effects.fork(resetVideoWatchingTimePassedSecondsWatcher),
  Effects.fork(handleUpdateVideoWatchingTimeWatcher)
]

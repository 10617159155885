import { createSelector, OutputSelector } from 'reselect'
import { propOr } from 'ramda'
import { RootReducer, ToastProps } from 'types'

export const selectToast = (state: RootReducer) => state.toast

export const isToastVisible: OutputSelector<
  RootReducer,
  any,
  (res: ToastProps) => any
> = createSelector(selectToast, propOr(false, 'visible'))

export const getToastKey: OutputSelector<
  RootReducer,
  any,
  (res: ToastProps) => any
> = createSelector(selectToast, propOr('', 'key'))

export const getToastOptions: OutputSelector<
  RootReducer,
  any,
  (res: ToastProps) => any
> = createSelector(selectToast, propOr({}, 'options'))

export const getToastSeverity: OutputSelector<
  RootReducer,
  any,
  (res: ToastProps) => any
> = createSelector(selectToast, propOr('', 'severity'))

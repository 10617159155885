import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { isEmpty, pipe, propOr, find, propEq, any, head, filter } from 'ramda'

import { selectNotesList } from 'modules/notes/ducks/selectors'
import { selectAllBooks } from 'modules/books/ducks/selectors'

import { formatDate, DATE_FORMATS } from 'utils/date'

import NotesLoadingState from 'modules/books/components/NotesLoadingState'
import NoteEditor from 'modules/notes/components/NoteEditor'
import { Button } from 'examkrackers-components'

import { Book } from 'types'

const Note = ({ sectionId }) => {
  const sectionsWithNotes = useSelector(selectNotesList)

  // @ts-ignore
  const note = pipe(
    find(propEq('id', sectionId))
    // @ts-ignore
  )(sectionsWithNotes)

  const sectionInfo = pipe(
    find(propEq('id', sectionId))
    // @ts-ignore
  )(sectionsWithNotes)

  const books: Book[] = useSelector(selectAllBooks)

  const chapterId = propOr('', 'chapter_id', note)

  const hasChapter = pipe(
    // @ts-ignore
    filter(pipe(propOr([], 'chapters'), any(propEq('id', chapterId)))),
    head
    // @ts-ignore
  )(books)
  // @ts-ignore

  const bookId = propOr('', 'book_id', hasChapter)

  // @ts-ignore
  const bookChapters = propOr('', 'chapters', hasChapter)

  const chapterOrder = pipe(
    find(pipe(propEq('id', chapterId))),
    propOr('', 'order')
    // @ts-ignore
  )(bookChapters)

  const { t } = useTranslation()
  const { push } = useHistory()
  const noteDate = propOr('', 'updated_at', note)

  const sectionOrder = propOr('', 'order', sectionInfo)
  const sectionPart = propOr('', 'part', sectionInfo)
  const sectionTitle = propOr('', 'title', sectionInfo)
  const redirectToProperSection = () => {
    push(
      // @ts-ignore
      `/books/${bookId}/chapter/${chapterOrder}/part/${sectionPart}?sectionIdContext=${note.id}&rightBottomActive=notes`
    )
  }

  return (
    <>
      {noteDate ? (
        <Container>
          {!isEmpty(sectionId) ? (
            <>
              <TopInfoBox>
                <InfoWrapper>
                  <NotesLoaderWrapper>
                    <NotesLoadingState />
                  </NotesLoaderWrapper>
                  <NoteDate>
                    {noteDate &&
                      formatDate(noteDate, DATE_FORMATS.withoutDashed)}
                  </NoteDate>
                  <EllipsisTitle>
                    {' '}
                    {chapterOrder}.{sectionOrder} {sectionTitle}
                  </EllipsisTitle>
                </InfoWrapper>
                <ButtonWrapper>
                  <Button
                    color='secondary'
                    size='small'
                    id='page-redirect'
                    // @ts-ignore
                    onClick={redirectToProperSection}
                  >
                    {t('notes.goToPage')}
                  </Button>
                </ButtonWrapper>
              </TopInfoBox>
              <Line />
              <NoteEditor sectionId={sectionId} note={note} />
            </>
          ) : (
            <div />
          )}
        </Container>
      ) : (
        <div />
      )}
    </>
  )
}
export default Note
const Container = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  background: #fff;
  border-radius: 6px;
`

const NotesLoaderWrapper = styled.div`
  position: relative;
  top: 48px;
  right: -145px;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  flex-grow: 1;
`
const NoteDate = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.main.grey600};
  display: flex;
  margin-bottom: 5px;
`
const EllipsisTitle = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`
const TopInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonWrapper = styled.div`
  padding: 12px 16px;
`
const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
`

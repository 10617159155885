import React from 'react'
import styled from 'styled-components'
import { examTimeOptions, EXAM_STATUS } from 'utils/exam'
import { getOptionByValue } from 'utils/form'

import { SingleSelect } from 'examkrackers-components'

export const SelectTimeOption = ({
  timeOption,
  examStatus,
  examId,
  onChange,
  disabled
}): JSX.Element => {
  const defaultOption = getOptionByValue(timeOption)(examTimeOptions())
  const [selectedOption, setSelectedOption] = React.useState(defaultOption)

  const handleChange = (e: any) => {
    onChange(e)
    setSelectedOption(e)
  }

  return (
    <SelectTimeOptionContainer
      data-exam-time-option-id={examId}
      data-exam-time-option-value={selectedOption?.value}
    >
      <SingleSelect
        id={`${examId}-select-time-option`}
        label=''
        size='small'
        isSearchable={false}
        options={examTimeOptions()}
        disabled={disabled || examStatus !== EXAM_STATUS.scheduled}
        onChange={handleChange}
        value={selectedOption}
      />
    </SelectTimeOptionContainer>
  )
}

const SelectTimeOptionContainer = styled.div`
  * {
    margin: 0 !important;
  }

  line-height: 1;
`

export default SelectTimeOption

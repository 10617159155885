import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'

// import { EK_PURCHASE_PORTAL_PAGE } from 'utils/links'
import { daysFromToday } from 'utils/date'
import { NavArrowRightIcon } from 'examkrackers-components'

import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'

export const NotificationFreeTrial = (): JSX.Element => {
  const { t } = useTranslation()
  const currentCourse: Course = useSelector(getCurrentCourse)
  const accessibleTo = R.propOr('', 'accessible_to', currentCourse)

  const isProduction = process.env.RELEASE_TYPE === 'production'
  const haveTransactionId = isNotNilOrEmpty(currentCourse.transaction_id)
  const transactionId = R.propOr('', 'transaction_id', currentCourse)

  const openPurchasePortal = () => {
    // window.open(EK_PURCHASE_PORTAL_PAGE, '_blank')
    if (isProduction && haveTransactionId) {
      window.open(
        `https://examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!isProduction && haveTransactionId) {
      window.open(
        `https://test.examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!haveTransactionId && !isProduction) {
      window.open(
        'https://test.examkrackers.com/my-account?panel=orders',
        '_blank'
      )
    } else if (!haveTransactionId && isProduction) {
      window.open('https://examkrackers.com/my-account?panel=orders', '_blank')
    }
  }

  return (
    <Container>
      <span>
        {t('courses.freeTrialNotification', {
          formattedDays: isNotNilOrEmpty(accessibleTo)
            ? daysFromToday(accessibleTo)
            : 0
        })}
      </span>
      <SubscribeBtn onClick={openPurchasePortal}>
        <span>{t('courses.freeTrialNotificationCTA')}</span>
        <NavArrowRightIcon />
      </SubscribeBtn>
    </Container>
  )
}

export default NotificationFreeTrial

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  gap: 10px;
  color: ${({ theme }) => theme.colors.topNav.freeTrialFont};

  button {
    height: 24px;
    padding: 0 8px;
    background-color: ${({ theme }) => theme.colors.topNav.freeTrialFont};

    &:hover:enabled,
    &:active:enabled {
      background: ${({ theme }) => theme.colors.main.black};
      border-color: ${({ theme }) => theme.colors.main.black};
    }
  }
`

const SubscribeBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.main.primary500};
  gap: 6px;
  cursor: pointer;
  transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary600};
  }
`

import React, { useState, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { propOr } from 'ramda'
import PATHS from 'utils/paths'

import saltyCalendarInit from 'assets/images/saltyCalendarInit.svg'

import {
  Button,
  SingleSelect,
  BouncingLoader,
  CalendarIcon,
  Tooltip
} from 'examkrackers-components'

import { DATE_FORMATS, formatDate } from 'utils/date'

import {
  columnsOrderForExpiration,
  countStudyDaysInRange,
  daysOfWeek,
  prevStateOfCalendarColumnsForExpiration
  // firstClassDateCalendar
} from 'utils/calendar'
import { getOptionByValue } from 'utils/form'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

import {
  getMcatDates,
  setExamDate,
  postBuildLiveClasses
} from 'services/CalendarService'

import DateInput from 'components/DateInput/DateInput'
import DayListItem from 'modules/calendar/setup/DayListItem'
import LeftColumnQuestions from 'modules/calendar/setup/LeftColumnQuestions'
import useCalendarSettingsValidationForExpiration from 'hooks/useCalendarSettingsValidationForExpiration'

import { isAfter } from 'date-fns'
import PreReadingModal from 'modules/calendar/PreReadingModal'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'

import ResetCalendarConfirmationModal from './ResetCalendarConfirmationModal'
import withSettingsPageWrapper from 'modules/calendar/SettingsPageWrapper'

interface McatDate {
  id: string
  course_id: string
  mcat_date: string
}

const initialValues = {
  mcat_at: '',
  calendar_start_at: '',
  exam_at: '',
  prioriDays: [0, 0, 0, 0, 0, 0, 0]
}

const ResetCalendarForExpiration: React.FC = () => {
  const [values, setValues] = useState(initialValues)
  const [open, setOpen] = useState(true)

  const [datesMcat, setDatesMcat] = useState<McatDate[]>([])
  const tryChanges = prevStateOfCalendarColumnsForExpiration(
    initialValues.prioriDays
  )
  const [columnsData, setColumnsData] = useState(tryChanges)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [withoutPreReading, setWithoutPreReading] = useState<boolean>(false)

  const { t } = useTranslation()

  const history = useHistory()
  const { push } = useHistory()

  const currentCourse = useSelector(getCurrentCourse)
  const firstClassDate = currentCourse.endDateFirstDay?.class_date

  const isLiveCourse = propOr('', 'type', currentCourse) === 'live_course'

  const accessibleTo = propOr('', 'accessible_to', currentCourse)
  const formatAccessibleTo = accessibleTo
    ? formatDate(accessibleTo, DATE_FORMATS.yearFirst)
    : ''

  const courseId = propOr('', 'book_course_id', currentCourse)
  const courseStudentId = propOr('', 'id', currentCourse)

  const mcatDateOptions: { label: string; value: string }[] = useMemo(() => {
    return datesMcat
      .filter(date => isAfter(new Date(date.mcat_date), new Date()))
      .sort(
        (a, b) =>
          new Date(a.mcat_date).getTime() - new Date(b.mcat_date).getTime()
      )
      .map(date => ({
        label: formatDate(date.mcat_date, DATE_FORMATS.slash),
        value: propOr('', 'id', date)
      }))
  }, [datesMcat])

  const {
    dndErrors,
    startDateMax,
    examDateBoundaries,
    errors,
    touchedFields,
    setTouchedFields
  } = useCalendarSettingsValidationForExpiration({
    course: currentCourse,
    values,
    mcatDateOptions,
    columnsData,
    isReorder: false
  })

  const handleDragEnd = result => {
    const { source, destination, draggableId } = result
    setTouchedFields(prev => [...prev, 'priori_days'])

    if (destination.droppableId === 'prioritySix') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 6
    } else if (destination.droppableId === 'prioritySeven') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 7
    } else if (destination.droppableId === 'priorityOne') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 1
    } else if (destination.droppableId === 'priorityTwo') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 2
    } else if (destination.droppableId === 'priorityThree') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 3
    } else if (destination.droppableId === 'priorityFour') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 4
    } else if (destination.droppableId === 'priorityFive') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 5
    } else {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 0
    }

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const startColumn = columnsData[source.droppableId]
    const finishColumn = columnsData[destination.droppableId]

    if (startColumn === finishColumn) {
      const newDay = Array.from(startColumn.day)
      const [removed] = newDay.splice(0, 1)
      newDay.splice(destination.index, 0, removed)

      const newColumn = {
        ...startColumn,
        day: newDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newColumn
      }
      setColumnsData(newState)
    } else {
      const startNewDay = startColumn.day
      const [removed] = startNewDay.splice(0, 1)
      const newStartColumn = {
        ...startColumn,
        day: startNewDay
      }
      const finishNewDay = Array.from(finishColumn.day)
      finishNewDay.splice(destination.index, 0, removed)
      const newFinishColumn = {
        ...finishColumn,
        day: finishNewDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newStartColumn,
        [destination.droppableId]: newFinishColumn
      }

      setColumnsData(newState)
    }
  }

  const handleChangeMcatDate = option => {
    setValues(prev => ({ ...prev, mcat_at: option.value }))
    setTouchedFields(prev => [...prev, 'mcat_at'])
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
    setTouchedFields(prev => [...prev, name])
  }

  const studyDaysCount = countStudyDaysInRange(
    values.calendar_start_at,
    values.exam_at,
    values.prioriDays
  )

  const redirectToCourseCalendar = () => {
    push(PATHS.calendar)
  }

  const handleSaveDateWithoutPreReading = async () => {
    setIsLoading(true)
    try {
      await setExamDate({
        calendar_start_at: values.calendar_start_at,
        exam_at: formatAccessibleTo,
        course_id: courseStudentId,
        mcat_date_id: values.mcat_at,
        build_calendar: -3
      })
      // for testing purposes
      // await postBuildLiveClasses()

      setIsLoading(false)
      redirectToCourseCalendar()
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleCancel = () => {
    history.goBack()
  }

  useEffect(() => {
    courseId &&
      getMcatDates({ id: courseId })
        .then(resp => {
          setDatesMcat(resp.data.data)
        })
        .catch(err => {
          console.error(err)
        })
  }, [])

  const { calendar_start_at, mcat_at, exam_at, prioriDays } = values

  const shouldShowPreReadingModal =
    isNotNilOrEmpty(mcat_at) &&
    isNotNilOrEmpty(calendar_start_at) &&
    isNilOrEmpty(exam_at)

  useEffect(() => {
    setOpen(shouldShowPreReadingModal)
  }, [shouldShowPreReadingModal])

  useEffect(() => {
    const handleAsyncEffect = async () => {
      if (withoutPreReading === true) {
        await handleSaveDateWithoutPreReading()
      }
    }
    handleAsyncEffect()
  }, [withoutPreReading])

  if (isLoading) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  const handleCalendarManualOpen = (id: string) => () => {
    const el = document.getElementById(id)

    if (el) {
      el.click()
    }
  }

  return (
    <Container data-testid='ResetCalendarForExpiration-Container'>
      <PreReadingModal
        data-testid='ResetCalendarForExpiration-PreReadingModal'
        open={open}
        setOpen={setOpen}
        setWithoutPreReading={setWithoutPreReading}
      />
      <TitleWrapper data-testid='ResetCalendarForExpiration-TitleWrapper'>
        {t('calendar.reset.title')}
      </TitleWrapper>
      <SubtitleWrapper data-testid='ResetCalendarForExpiration-SubtitleWrapper-description'>
        {t('calendar.setup.description')}
      </SubtitleWrapper>
      <ColumnsWrapper data-testid='ResetCalendarForExpiration-ColumnsWrapper-description'>
        <LeftColumn data-testid='ResetCalendarForExpiration-LeftColumn'>
          <QuestionContainer data-testid='ResetCalendarForExpiration-QuestionContainer'>
            <DescWrapper data-testid='ResetCalendarForExpiration-DescWrapper'>
              <QuestionTitle data-testid='ResetCalendarForExpiration-QuestionTitle-mcat'>
                {t('calendar.setup.mcat.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ResetCalendarForExpiration-QuestionDescription-mcat'>
                {t('calendar.setup.mcat.tooltip')}
              </QuestionDescription>
            </DescWrapper>
            <SelectWrapper data-testid='ResetCalendarForExpiration-SelectWrapper-mcat'>
              <StyledSelect
                data-testid='ResetCalendarForExpiration-StyledSelect-mcat'
                removeMargin
                label=''
                size='small'
                options={mcatDateOptions}
                onChange={handleChangeMcatDate}
                disabled={isNilOrEmpty(mcatDateOptions)}
                value={getOptionByValue(values.mcat_at)(mcatDateOptions)}
              />
            </SelectWrapper>
          </QuestionContainer>
          {errors.mcatDate && touchedFields.includes('mcat_at') && (
            <Error data-testid='ResetCalendarForExpiration-Error-mcatDate'>
              {errors.mcatDate}
            </Error>
          )}
          <QuestionContainer
            disabled={isNilOrEmpty(values.mcat_at)}
            data-testid='ResetCalendarForExpiration-QuestionContainer-start_at'
          >
            <DescWrapper data-testid='ResetCalendarForExpiration-DescWrapper-start_at'>
              <QuestionTitle data-testid='ResetCalendarForExpiration-QuestionTitle-start_at'>
                {t('calendar.setup.start.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ResetCalendarForExpiration-QuestionDescription-start_at'>
                {t('calendar.setup.start.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper data-testid='ResetCalendarForExpiration-PickerWrapper-start_at'>
              <DateInput
                data-testid='ResetCalendarForExpiration-DateInput-start_at'
                name='calendar_start_at'
                id='calendar_start_at_input'
                value={values.calendar_start_at}
                validate={() => true}
                size='small'
                onChange={handleValueChange}
                minDate={new Date()}
                maxDate={firstClassDate}
              />
            </PickerWrapper>

            <IconContainer data-testid='ResetCalendarForExpiration-IconContainer-start_at'>
              <CalendarIcon
                data-testid='ResetCalendarForExpiration-CalendarIcon-start_at'
                onClick={handleCalendarManualOpen('calendar_start_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.startDate && touchedFields.includes('calendar_start_at') && (
            <Error data-testid='ResetCalendarForExpiration-Error-start_at'>
              {errors.startDate}
            </Error>
          )}

          <QuestionContainer
            data-testid='ResetCalendarForExpiration-QuestionContainer-end'
            disabled={isNilOrEmpty(values.calendar_start_at) || open}
          >
            <DescWrapper data-testid='ResetCalendarForExpiration-DescWrapper-end'>
              <QuestionTitle data-testid='ResetCalendarForExpiration-QuestionTitle-end'>
                {t('calendar.setupForExpiration.end.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='ResetCalendarForExpiration-QuestionDescription-end'>
                {t('calendar.setupForExpiration.end.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper data-testid='ResetCalendarForExpiration-PickerWrapper-exam_at'>
              <DateInput
                data-testid='ResetCalendarForExpiration-DateInput-exam_at'
                name='exam_at'
                id='exam_at_input'
                value={values.exam_at}
                validate={() => true}
                size='small'
                // t={t}
                onChange={handleValueChange}
                minDate={examDateBoundaries.min}
                maxDate={firstClassDate}
              />
            </PickerWrapper>
            <IconContainer data-testid='ResetCalendarForExpiration-IconContainer-calendar-exam_at'>
              <CalendarIcon
                onClick={handleCalendarManualOpen('exam_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.endDate && touchedFields.includes('exam_at') && (
            <Error data-testid='ResetCalendarForExpiration-Error-end'>
              {errors.endDate}
            </Error>
          )}
          <LeftColumnQuestions
            data-testid='ResetCalendarForExpiration-LeftColumnQuestions'
            disabled={isNilOrEmpty(values.exam_at) || open}
            isLiveCourse
          />
        </LeftColumn>
        <RightColumn data-testid='ResetCalendarForExpiration-RightColumn'>
          <ImageContainer>
            <img src={saltyCalendarInit} alt='book-cover-all' />
          </ImageContainer>
          <DragDropContext
            onDragEnd={handleDragEnd}
            data-testid='ResetCalendarForExpiration-DragDropContext'
          >
            <WrapperDnd
              disabled={isNilOrEmpty(values.exam_at)}
              data-testid='ResetCalendarForExpiration-WrapperDnd'
            >
              <WeekDaysColumn data-testid='ResetCalendarForExpiration-WeekDaysColumn'>
                <>
                  <ColumnTitle data-testid='ResetCalendarForExpiration-ColumnTitle-mandatoryDaysOff'>
                    {t('calendar.setup.mandatoryDaysOff')}
                  </ColumnTitle>
                  {columnsOrderForExpiration.map((columnId, index) => {
                    const column = columnsData[columnId]
                    if (index <= 6) {
                      return (
                        <DropZoneWrapper
                          key={column.id}
                          data-testid={`ResetCalendarForExpiration-DropZoneWrapper-${column.id}`}
                        >
                          <Droppable
                            data-testid={`ResetCalendarForExpiration-Droppable-${column.id}`}
                            droppableId={column.id}
                            isDropDisabled={
                              column.day && column.day.length === 1
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <SingleDropZone
                                  data-testid={`ResetCalendarForExpiration-SingleDropZone-${column.id}`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <DropContainer
                                    data-testid={`ResetCalendarForExpiration-DropContainer-${column.tile}`}
                                  >
                                    {column.title}
                                  </DropContainer>

                                  {isNotNilOrEmpty(column.day) && (
                                    <DayListItem
                                      data-testid={`ResetCalendarForExpiration-DayListItem-${column.day[0]}`}
                                      day={column.day[0]}
                                      index={index}
                                    />
                                  )}
                                  {provided.placeholder}
                                </SingleDropZone>
                              )
                            }}
                          </Droppable>
                        </DropZoneWrapper>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              </WeekDaysColumn>
              <PrioriColumn data-testid='ResetCalendarForExpiration-PrioriColumn'>
                <>
                  <ColumnTitle data-testid='ResetCalendarForExpiration-ColumnTitle-studyDays'>
                    {t('calendar.setup.studyDays')}
                  </ColumnTitle>
                  {columnsOrderForExpiration.map((columnId, index) => {
                    const column = columnsData[columnId]
                    if (index > 6) {
                      return (
                        <DropZoneWrapper
                          key={column.id}
                          data-testid={`ResetCalendarForExpiration-DropZoneWrapper-${column.id}`}
                        >
                          <Droppable
                            data-testid={`ResetCalendarForExpiration-Droppable-${column.id}`}
                            droppableId={column.id}
                            isDropDisabled={
                              column.day && column.day.length === 1
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <SingleDropZoneActive
                                  data-testid='ResetCalendarForExpiration-SingleDropZoneActive'
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  isDraggingOver={snapshot.isDraggingOver}
                                >
                                  <DropContainer
                                    data-testid={`ResetCalendarForExpiration-DropContainer-${column.title}`}
                                  >
                                    {column.title}
                                  </DropContainer>
                                  {isNotNilOrEmpty(column.day) && (
                                    <DayListItem
                                      data-testid={`ResetCalendarForExpiration-DayListItem-${column.day[0]}`}
                                      day={column.day[0]}
                                      index={index}
                                    />
                                  )}
                                  {provided.placeholder}
                                </SingleDropZoneActive>
                              )
                            }}
                          </Droppable>
                        </DropZoneWrapper>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              </PrioriColumn>
            </WrapperDnd>
          </DragDropContext>
          {isNotNilOrEmpty(dndErrors) &&
            touchedFields.includes('priori_days') && (
              <DndErrors data-testid='ResetCalendarForExpiration-DndErrors'>
                {dndErrors.map(error => (
                  <Error data-testid='ResetCalendarForExpiration-PrioriColumn'>
                    {error}
                  </Error>
                ))}
              </DndErrors>
            )}
        </RightColumn>
      </ColumnsWrapper>
      {/* {studyDaysCount > 0 && studyDaysCount < 44 && hasAllValues && (
        <WarningBox>
          <WarningBoxContent>
            Attention: Your current selection has only {studyDaysCount} Study
            Days.
            <br />
            We recommend at least 44 Study Days, so that you have at least one
            study day per chapter.
          </WarningBoxContent>
        </WarningBox>
      )} */}
      <ButtonsWrapper data-testid='ResetCalendarForExpiration-ButtonsWrapper'>
        <ButtonContainer data-testid='ResetCalendarForExpiration-ButtonContainer'>
          <ButtonWrapper
            color='tertiary'
            onClick={handleCancel}
            data-testid='ResetCalendarForExpiration-ButtonWrapper-cancel'
          >
            {t('calendar.button.cancel')}
          </ButtonWrapper>
        </ButtonContainer>
        <ButtonContainer data-testid='ResetCalendarForExpiration-ButtonContainer-reset'>
          {isNotNilOrEmpty(errors) ? (
            <Tooltip
              data-testid='ResetCalendarForExpiration-Tooltip-reset'
              id='inactive-button'
              tooltipContent='This form must be completed in order to continue. You will be able to make changes at any time by clicking on the Reorganize button on your calendar.'
            >
              <ButtonWrapper
                data-testid='ResetCalendarForExpiration-ButtonWrapper-reset'
                variant='contained'
                color='secondary'
                onClick={() => {}}
                disabled
              >
                {t('calendar.reset.button')}
              </ButtonWrapper>
            </Tooltip>
          ) : (
            <ResetCalendarConfirmationModal
              data-testid='ResetCalendarForExpiration-ResetCalendarConfirmationModal'
              values={values}
              disabled={isNotNilOrEmpty(errors)}
              isLiveCourse={isLiveCourse}
            />
          )}
        </ButtonContainer>
      </ButtonsWrapper>
    </Container>
  )
}

export default withSettingsPageWrapper(ResetCalendarForExpiration)

const Container = styled.div`
  margin: 20px 132px;
  font-size: 12px;
  padding-bottom: 30px;
`

const TitleWrapper = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`

const SubtitleWrapper = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: px;
  margin-top: 10px;
`

const LeftColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RightColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ImageContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 65px;
`
const SingleDropZone = styled.div`
  color: ${({ theme }) => theme.colors.main.grey400};
  background-color: #fbf5f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 26px;
  gap: 25px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C5C6C8FF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`
const SingleDropZoneActive = styled(SingleDropZone)`
  color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary500 : '#444'};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary300 : '#fff3ed'};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237B7C7DFF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`

const WrapperDnd = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;

  margin-top: 36px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const ColumnTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  width: 155px;
  text-align: center;
`
const WeekDaysColumn = styled.div``
const PrioriColumn = styled.div``

const DropZoneWrapper = styled.div`
  margin: 6px;
`
const DropContainer = styled.div`
  position: absolute;
`

const PickerWrapper = styled.div`
  display: flex;

  width: 120px;
  margin-top: -20px;
  margin-bottom: -30px;
`
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const QuestionTitle = styled.div`
  font-size: 14px;
  line-height: 30px;
`
const QuestionDescription = styled.div`
  font-size: 10px;
  margin-left: 20px;
  line-height: 20px;
  width: 310px;
`
const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 990px; */
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  font-size: 30px;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 7px;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
  height: ${({ theme }) => theme.dimensions.inputHeight};
`
const StyledSelect = styled(SingleSelect)`
  min-width: 121px;
  max-width: 121px;

  div {
    overflow-x: hidden;
  }
`
const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Error = styled.div`
  color: red;
`

const DndErrors = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`

const WarningBox = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const WarningBoxContent = styled.div`
  text-align: center;
  color: red;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;

  justify-content: center;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

import React from 'react'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import {
  getDiagnosticLegendState,
  getGraphCategories,
  getQuestionsData,
  getQuestionsDifficultyGraph
} from 'modules/diagnostics/ducks/selectors'
import ApexChart from 'react-apexcharts'
import {
  EXAM_SECTION_DIFFICULTY_CHART_SERIES,
  ExamSectionDifficultyChart,
  getExamSectionDifficultySeries
} from 'modules/diagnostics/utils/ExamSectionDifficultyChart'
import { useCustomGrid } from 'modules/diagnostics/hooks/useCustomGrid'
import { useCustomXLabels } from 'modules/diagnostics/hooks/useCustomXLabels'
import { useQuestionHover } from 'modules/diagnostics/hooks/useQuestionHover'

export const DifficultyChartExamSectionDiagnostics = (): JSX.Element => {
  const params = useParams()
  const sectionId: string = R.propOr('', 'sectionId')(params)
  useCustomGrid('difficulty-chart-exam-section')
  useQuestionHover('difficulty-chart-exam-section')
  useCustomXLabels('difficulty-chart-exam-section')

  const questionsData = useSelector(getQuestionsData)
  const questionDifficultyData = useSelector(getQuestionsDifficultyGraph)
  const graphCategories = useSelector(state =>
    getGraphCategories(state, sectionId)
  )
  const diagnosticLegendState = useSelector(getDiagnosticLegendState)

  const checkIsCorrect = (questionOrder: number) => {
    const questionData = R.find(R.propEq('order', questionOrder))(questionsData)
    const answer = R.pathOr('', ['answer'], questionData)
    const correctAnswer = R.pathOr('', ['correct_answer'], questionData)

    return answer === correctAnswer
  }

  const checkIsSkipped = (questionOrder: number) =>
    R.pipe(
      R.find(R.propEq('order', questionOrder)),
      R.propOr(null, 'answer'),
      R.equals(null)
    )(questionsData)

  const chartOptions = ExamSectionDifficultyChart({
    categories: graphCategories,
    checkIsCorrect,
    checkIsSkipped
  })

  const chartSeries = getExamSectionDifficultySeries({
    difficulty: questionDifficultyData
  })

  const isDifficultyOn = R.propOr(
    true,
    EXAM_SECTION_DIFFICULTY_CHART_SERIES.difficulty,
    diagnosticLegendState
  )

  return isDifficultyOn ? (
    <Container>
      {/* @ts-ignore */}
      <ApexChart
        // @ts-ignore
        options={chartOptions}
        series={chartSeries}
        width='100%'
        height='100%'
        id='difficulty-chart-exam-section'
      />
    </Container>
  ) : (
    <div />
  )
}

export default DifficultyChartExamSectionDiagnostics

const Container = styled.div`
  height: 202px;
`

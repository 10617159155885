import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import * as R from 'ramda'

import { ExamIconArrowUp, ExamIconArrowDown } from 'examkrackers-components'

import ExamExplanations from 'modules/exam/components/ExamExplanations'
import QuestionExplanationMetrics from 'modules/exam/components/QuestionPages/QuestionExplanationMetrics'

import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'

import { disableCopyPaste, enableCopyPaste } from 'utils/exam'
import AnalyticsService from '../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../utils/analytics'
import { getStudent } from '../modules/auth/ducks/selectors'
import { RootReducer } from '../types'
import {
  getExamDetails,
  getSectionIdByCurrentPageId,
  getStepHtml
} from '../modules/exam/ducks/selectors'

export const ExamExplanationsPage = (): JSX.Element => {
  const params = useParams()
  const student = useSelector(getStudent)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [metrics, setMetrics] = React.useState(false)

  const examId: string = R.propOr('', 'id')(params)
  const questionId: string = R.propOr('', 'questionId', params)

  const fetchExamDetails = React.useCallback(
    (id: string) => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  const examDetails = useSelector(getExamDetails)
  const stepHtml = useSelector((state: RootReducer) =>
    getStepHtml(state, questionId)
  )
  const passageId = R.propOr('', 'passageId', stepHtml)

  const sectionIdByCurrentPageId = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, questionId)
  )

  const sectionTitle = R.pipe(
    R.propOr([], 'questions'),
    R.find(R.propEq('id', sectionIdByCurrentPageId)),
    R.propOr('-', 'title')
  )(examDetails)

  const sectionOrder = R.pipe(
    R.propOr([], 'questions'),
    R.find(R.propEq('id', sectionIdByCurrentPageId)),
    R.propOr(0, 'order')
  )(examDetails)

  const questionOrder = R.pipe(
    R.propOr([], 'questions'),
    // @ts-ignore
    R.map(R.prop('questions')),
    R.flatten,
    R.find(R.propEq('id', questionId)),
    R.propOr('-', 'order')
    // @ts-ignore
  )(examDetails)

  const passageOrder = R.pipe(
    R.propOr([], 'questions'),
    // @ts-ignore
    R.map(R.prop('passages')),
    R.flatten,
    R.find(R.propEq('id', passageId)),
    R.ifElse(
      R.propEq('is_false_passage', false),
      R.propOr(0, 'realOrder'),
      R.always('DQ')
    )
    // @ts-ignore
  )(examDetails)

  const handleToggleMetrics = () => {
    if (metrics === false) {
      AnalyticsService(student).logEvent(
        ANALYTICS_EVENTS.passageReadingTimeReviewed,
        {
          'Exam Title': R.pathOr('', ['exam', 'title'], examDetails),
          'Exam format': R.pathOr('', ['type', 'title'], examDetails),
          'Section Title': sectionTitle,
          'Section Number': sectionOrder,
          'Passage Number': passageOrder,
          'Question Number': questionOrder
        }
      )
    }

    setMetrics(!metrics)
  }

  React.useEffect(() => {
    disableCopyPaste()

    fetchExamDetails(examId)

    return () => {
      enableCopyPaste()
    }
  }, [])

  return (
    <LayoutContainer>
      <Helmet>
        <title>{t('pages.exam.explanations')}</title>
      </Helmet>
      <Container metrics={metrics}>
        <DrawerButton onClick={handleToggleMetrics}>
          {metrics ? <ExamIconArrowUp /> : <ExamIconArrowDown />}
        </DrawerButton>
        <QuestionExplanationMetrics isOpen={metrics} />
        <ExamExplanations />
      </Container>
    </LayoutContainer>
  )
}

const DrawerButton = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: ${({ theme }) => theme.colors.main.primary500};
  height: 24px;
  width: 128px;
  border-radius: 0px 0px 4px 4px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const LayoutContainer = styled.div`
  height: 100vh;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgrounds.main};
`

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`

export default ExamExplanationsPage

import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getPassageGraphLines } from 'modules/diagnostics/ducks/selectors'
import eventEmitter from '../../../providers/eventEmitter'
import events from '../utils/events'

export const useCustomGrid = id => {
  const params = useParams()
  const sectionId: string = R.propOr('', 'sectionId')(params)
  const passageGraphLines = useSelector(state =>
    getPassageGraphLines(state, sectionId)
  )

  const setLineVisibility = lines => {
    const linesList = Array.from(lines)

    linesList.forEach((line, index) => {
      // @ts-ignore
      line.setAttribute('dataX', index + 1)

      if (R.includes(index + 1, passageGraphLines)) {
        // @ts-ignore
        line.style.visibility = 'visible'
      }
    })
  }

  const customGrid = () => {
    // @ts-ignore
    const linesContainer = document.querySelector(
      `#${id} .apexcharts-gridlines-vertical`
    )

    linesContainer && setLineVisibility(linesContainer.children)
  }

  const timeOutCustomGrid = () => setTimeout(customGrid, 2000)

  React.useEffect(() => {
    timeOutCustomGrid()
  }, [passageGraphLines])

  React.useEffect(() => {
    eventEmitter.on(events.chartUpdated, timeOutCustomGrid)

    return () => {
      eventEmitter.off(events.chartUpdated, timeOutCustomGrid)
    }
  }, [])
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getCalendar, setExamDate } from 'services/CalendarService'
import { BouncingLoader, Button, DatePicker } from 'examkrackers-components'
import { Trans, useTranslation } from 'react-i18next'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'
import { getStudent } from 'modules/auth/ducks/selectors'

const initialValues = {
  calendar_start_at: new Date(),
  exam_at: new Date(),
  mcat_at: new Date()
}

interface CalendarResetSettingsValues {
  calendar_start_at: string | Date
  exam_at: string | Date
  mcat_at: string | Date
}

const CalendarSettingsInit = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] =
    useState<CalendarResetSettingsValues>(initialValues)
  const { push } = useHistory()

  const { t } = useTranslation()

  const studentInfo = useSelector(getStudent)
  const userName = studentInfo.username

  const isMcatDateBeforeFinalDate = () => {
    const mcatDate = new Date(values.mcat_at)
    const finalDate = new Date(values.exam_at)
    return mcatDate < finalDate
  }

  const isFinalDateAfterMcatDate = () => {
    const mcatDate = new Date(values.mcat_at)
    const finalDate = new Date(values.exam_at)
    return finalDate > mcatDate
  }

  const isFinalDateBeforeStartDate = () => {
    const startDate = new Date(values.calendar_start_at)
    const finalDate = new Date(values.exam_at)
    return finalDate < startDate
  }

  const handleCalendarFetch = () => {
    getCalendar()
      .then(resp => {
        const calendarDates = resp.data.calendar

        const startDate = new Date(calendarDates.start_at)
        const finishCourseDate = calendarDates.exam_at
          ? new Date(calendarDates.exam_at)
          : new Date()
        const mcatDateCourse = calendarDates.mcat_at
          ? new Date(calendarDates.mcat_at)
          : new Date()

        setValues({
          calendar_start_at: startDate,
          exam_at: finishCourseDate,
          mcat_at: mcatDateCourse
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const canFormBeSubmitted =
    isMcatDateBeforeFinalDate() ||
    isFinalDateAfterMcatDate() ||
    isFinalDateBeforeStartDate()
  const handleSaveDate = async () => {
    setIsLoading(true)
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
    try {
      await setExamDate({
        calendar_start_at: values.calendar_start_at,
        exam_at: values.exam_at,
        course_id: courseId,
        mcat_at: values.mcat_at
      })
      setIsLoading(false)
      push(PATHS.calendarDaysOff)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    handleCalendarFetch()
  }, [])

  if (isLoading) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <Container>
      <Title>{t('calendar.settings.formTitle')}</Title>
      <ContentWrapper>
        <GreetingsWrapper>
          <Trans i18nKey='calendar.settings.greetings'>
            0<UserNameWrapper>{userName}</UserNameWrapper>2
          </Trans>
        </GreetingsWrapper>
        <GreetingsWrapper>
          {t('calendar.settings.initialSetupTitle')}
        </GreetingsWrapper>
        <GreetingsWrapper>
          {t('calendar.settings.initialSetupDescriptionPartOne')}
        </GreetingsWrapper>
        <GreetingsWrapper>
          {t('calendar.settings.initialSetupDescriptionPartTwo')}
        </GreetingsWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <QuestionTitleWrapper>
          {t('calendar.settings.mcatQuestion')}
        </QuestionTitleWrapper>
        <HelpTextWrapper>
          {t('calendar.settings.mcatQuestionTooltip')}
        </HelpTextWrapper>
        <PickerWrapper>
          <DatePicker
            name='mcat_at'
            id='mcat_at'
            value={values.mcat_at}
            validate={() => true}
            t={t}
            onChange={handleValueChange}
            minDate={new Date()}
          />
          {/* <div>{values.exam_at && values.exam_at}</div> */}
        </PickerWrapper>
        {isMcatDateBeforeFinalDate() && (
          <ErrorWrapper>
            {t('calendar.resetModal.errorWithMcatDate')}
          </ErrorWrapper>
        )}
      </ContentWrapper>
      <ContentWrapper>
        <QuestionTitleWrapper>
          {t('calendar.settings.beginStudyQuestion')}
        </QuestionTitleWrapper>
        <HelpTextWrapper>
          {t('calendar.settings.beginStudyQuestionTooltip')}
        </HelpTextWrapper>
        <PickerWrapper>
          <DatePicker
            name='calendar_start_at'
            id='calendar_start_at'
            value={values.calendar_start_at}
            validate={() => true}
            t={t}
            onChange={handleValueChange}
            minDate={new Date()}
          />
          {/* <div>{values.exam_at && values.exam_at}</div> */}
        </PickerWrapper>
        {isFinalDateBeforeStartDate() && (
          <ErrorWrapper>{t('calendar.resetModal.errorStartDate')}</ErrorWrapper>
        )}
      </ContentWrapper>
      <ContentWrapper>
        <QuestionTitleWrapper>
          {t('calendar.settings.completeStudyQuestion')}
        </QuestionTitleWrapper>
        <HelpTextWrapper>
          {t('calendar.settings.completeStudyQuestionTooltip')}
        </HelpTextWrapper>
        <HelpTextWrapper>
          {t('calendar.settings.completeStudyQuestionDescription')}
        </HelpTextWrapper>
        <PickerWrapper>
          <DatePicker
            name='exam_at'
            id='exam_at'
            value={values.exam_at}
            validate={() => true}
            t={t}
            onChange={handleValueChange}
            minDate={values.exam_at}
          />
        </PickerWrapper>
        {isFinalDateAfterMcatDate() && (
          <ErrorWrapper>
            {t('calendar.resetModal.errorWithStudyDate')}
          </ErrorWrapper>
        )}
      </ContentWrapper>

      <ButtonWrapper>
        <Button
          color='secondary'
          onClick={handleSaveDate}
          disabled={canFormBeSubmitted}
        >
          Save
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

export default CalendarSettingsInit

const Container = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 400px;
  min-width: 400px;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const PickerWrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  flex-direction: row;
  /* gap: 16px; */
  width: 300px;
  margin-top: -30px;
  margin-bottom: -30px;
`
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;

  display: flex;
  justify-content: center;
`
const QuestionTitleWrapper = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
`
const HelpTextWrapper = styled.div`
  margin: 0 16px;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 16px;
`
const GreetingsWrapper = styled.div`
  font-size: 14px;
  line-height: 1.5;
  &:nth-of-type(2n) {
    margin-bottom: 8px;
  }
`
const UserNameWrapper = styled.span`
  font-weight: bold;
`
const ErrorWrapper = styled.div`
  color: ${({ theme }) => theme.colors.main.error500};
  font-size: 12px;
  margin-top: 8px;
`

import React from 'react'
import * as R from 'ramda'
import {
  contentQuestionFilterOptions,
  BOOK_VIEWER_PARAMS,
  QUESTION_FILTERS
} from 'utils/books'
import { SingleSelect } from 'examkrackers-components'
import { useHistory } from 'react-router-dom'
import { getOptionByValue } from 'utils/form'
import qs from 'qs'
import styled from 'styled-components'

export const FilterContentQuestions = (): JSX.Element => {
  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const selectedFilter: string = R.propOr(
    QUESTION_FILTERS.all,
    BOOK_VIEWER_PARAMS.contentQuestionFilter
  )(parsedQuery)

  const handleChange = option => {
    const newQuery = {
      ...parsedQuery,
      [BOOK_VIEWER_PARAMS.contentQuestionFilter]: option.value
    }
    replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
  }

  return (
    <Container>
      <SingleSelect
        removeMargin
        removeTopLabel
        label=''
        size='small'
        // @ts-ignore
        options={contentQuestionFilterOptions()}
        onChange={handleChange}
        value={getOptionByValue(selectedFilter)(contentQuestionFilterOptions())}
        isSearchable={false}
      />
    </Container>
  )
}

export default FilterContentQuestions

const Container = styled.div`
  min-width: 124px;
`

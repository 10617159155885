import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { NavArrowRightIcon, WarningReversed } from 'examkrackers-components'

export const NotificationImpersonate = (): JSX.Element => {
  const { t } = useTranslation()

  const openAdminPanel = () => {
    window.location.href = `${process.env.REACT_APP_ADMIN_URL}`
  }

  return (
    <Container>
      <IconContainer>
        <WarningReversed />
      </IconContainer>
      <span>{t('auth.isImpersonated')}</span>
      <BackBtn onClick={openAdminPanel}>
        <span>{t('auth.goToAdmin')}</span>
        <NavArrowRightIcon />
      </BackBtn>
    </Container>
  )
}

export default NotificationImpersonate

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  gap: 10px;
  color: ${({ theme }) => theme.colors.topNav.freeTrialFont};

  button {
    height: 24px;
    padding: 0 8px;
    background-color: ${({ theme }) => theme.colors.topNav.freeTrialFont};

    &:hover:enabled,
    &:active:enabled {
      background: ${({ theme }) => theme.colors.main.black};
      border-color: ${({ theme }) => theme.colors.main.black};
    }
  }
`

const BackBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.main.primary500};
  gap: 6px;
  cursor: pointer;
  transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary600};
  }
`

const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  line-height: 16px;
  font-size: 16px;
`

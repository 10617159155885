import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import qs from 'qs'
import { pathOr } from 'ramda'
import { useHistory } from 'react-router-dom'

import { VIDEOS_QUERY_PARAMS } from 'utils/videos'

import VideoCategories from 'modules/videos/components/VideoCategories'
import VideoMainFilters from 'modules/videos/components/VideoMainFilters'
import VideoBookFilters from 'modules/videos/components/VideoBookFilters'
import VideoList from 'modules/videos/components/VideoList'

import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

const Videos = () => {
  const { t } = useTranslation()
  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const selectedCategory: string = pathOr(
    'all',
    ['filter', VIDEOS_QUERY_PARAMS.category],
    parsedQuery
  )
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)

  const isBooksCategory = selectedCategory === 'books'

  const [seeVideosPage, setSeeVideosPage] = useState(true)

  // @sieradz - events - try to do like this useEffect:
  // const [seeVideosPage, setSeeVideosPage] = useState(true)
  // if (isNotNilOrEmpty(currentCourse.id) && seeVideosPage) {

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && seeVideosPage) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeVideosPage, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
      setSeeVideosPage(false)
    }
  }, [currentCourse, seeVideosPage])

  return (
    <Container>
      <Helmet>
        <title>{t('pages.videos')}</title>
      </Helmet>
      <VideosLayout>
        <VideosLeftColumn>
          <VideoCategories />
        </VideosLeftColumn>
        <VideosRightColumn>
          <VideoMainFilters />
          {isBooksCategory && <VideoBookFilters />}
          <VideoList />
        </VideosRightColumn>
      </VideosLayout>
    </Container>
  )
}

export default Videos

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  margin-top: 8px;
  padding: 20px 16px;
`

const VideosLayout = styled.div`
  display: flex;
  gap: 48px;
  height: 100%;
`

const VideosLeftColumn = styled.div`
  width: 176px;
  flex: none;
`

const VideosRightColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const VideosSortContainer = styled.div`
  margin-top: 16px;
`

import React, { useEffect } from 'react'

import styled from 'styled-components'

import { useHistory } from 'react-router-dom'

import BooksColumn from 'modules/notes/components/BooksColumn'
import BookNotes from 'modules/notes/components/BookNotes'
import Note from 'modules/notes/components/Note'

import { propOr } from 'ramda'
import { useParams } from 'react-router'
import qs from 'qs'

import { useTranslation } from 'react-i18next'

import PinNoteList from 'modules/notes/components/PinNoteList'

const Notes = () => {
  const { t } = useTranslation()
  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const sectionIdFromQuery = propOr('', 'sectionId', parsedQuery)
  const isSectionIdFromQueryEmpty = sectionIdFromQuery === ''
  const params = useParams()
  const id = propOr('', 'id', params)
  const sectionId = propOr('', 'sectionId', parsedQuery)
  const activeTab = propOr('', 'activeTab', parsedQuery)
  const isNotesActiveTab = activeTab === 'notes'
  const isPinsActiveTab = activeTab === 'pins'

  useEffect(() => {
    const activeTab = propOr('notes', 'activeTab', parsedQuery)

    const newQuery = {
      limit: { ...parsedQuery.limit, page: 1, take: 9 },
      order: { ...parsedQuery.order, by: 'natural', dir: 'asc' },
      activeTab
    }
    isSectionIdFromQueryEmpty &&
      replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
  }, [search])

  return (
    <ContainerWrapper>
      <Container>
        <SectionTitle>{t('navigation.books')}</SectionTitle>
        <BookWrapper>
          <BooksColumn />
        </BookWrapper>
      </Container>
      <MiddleColumn>
        <BookNotes id={id} />
      </MiddleColumn>
      <RightColumn>
        {sectionId && isNotesActiveTab && <Note sectionId={sectionId} />}
        {sectionId && isPinsActiveTab ? (
          <PinNoteList sectionId={sectionId} id={id} />
        ) : null}
      </RightColumn>
    </ContainerWrapper>
  )
}

export default Notes

const ContainerWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  height: 100%;
`

const Container = styled.div`
  width: 174px;
  height: 645px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  overflow: hidden;
`
const BookWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 174px;
  min-height: 570px;
`

const SectionTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
  padding: 16px 16px 0;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-weight: bold;
`
const MiddleColumn = styled.div`
  width: 554px;
  height: 100%;
`
const RightColumn = styled.div`
  height: 645px;
  width: 500px;
  min-height: 645px;
  margin-left: 32px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

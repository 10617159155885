import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Button, CloseIcon, WarningReversed } from 'examkrackers-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ExtendPortal = ({ children }) => {
  const [isPortalOpen, setIsPortalOpen] = useState(false)
  const [portalPosition, setPortalPosition] = useState({ top: 0, left: 0 })
  const divRef = useRef(null)
  const portalContainerRef = useRef(document.createElement('div'))
  const { t } = useTranslation()

  useEffect(() => {
    document.body.appendChild(portalContainerRef.current)
    return () => {
      document.body.removeChild(portalContainerRef.current)
    }
  }, [])

  const handleClickOutside = event => {
    if (
      portalContainerRef.current &&
      !portalContainerRef.current.contains(event.target) &&
      divRef.current &&
      !divRef.current.contains(event.target)
    ) {
      setIsPortalOpen(false)
    }
  }

  useEffect(() => {
    if (isPortalOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPortalOpen])

  const handleDivClick = e => {
    // @ts-ignore
    const rect = divRef.current.getBoundingClientRect()
    setPortalPosition({ top: rect.top, left: rect.right })
    setIsPortalOpen(true)
  }

  const handlePortalMouseDown = e => {
    // Prevent the mousedown event from propagating to the document
    e.stopPropagation()
  }

  return (
    <>
      <div ref={divRef} onClick={handleDivClick}>
        {children}
      </div>
      {isPortalOpen &&
        ReactDOM.createPortal(
          <ModalContentWrapper
            style={{
              top: `${portalPosition.top}px`,
              left: `${portalPosition.left}px`
            }}
            onMouseDown={handlePortalMouseDown}
          >
            <ModalContent>
              <CloseIconWrapper>
                <CloseIcon onClick={() => setIsPortalOpen(false)} />
              </CloseIconWrapper>
              <MessageWrapper>
                <WarningCalendarWrapper>
                  <WarningReversed />
                </WarningCalendarWrapper>
                {t('courses.extendCoursePortal.warningMessage')}
              </MessageWrapper>
              <ButtonContainer>
                <Button
                  variant='contained'
                  size='small'
                  color='secondary'
                  onClick={() => {
                    window.open(
                      'https://examkrackers.com/my-account?panel=orders',
                      '_blank'
                    )
                    setIsPortalOpen(false)
                  }}
                >
                  {t('courses.extendCoursePortal.button')}
                </Button>
              </ButtonContainer>
            </ModalContent>
          </ModalContentWrapper>,
          portalContainerRef.current
        )}
    </>
  )
}

export default ExtendPortal

const ButtonContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 100% !important;
  }
`

const ModalContent = styled.div`
  font-size: 14px;
`

const ModalContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  background: #fff;
  width: 250px;
  z-index: 3000;
  padding: 15px;
  color: ${({ theme }) => theme.colors.main.error500};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const MessageWrapper = styled.div`
  display: flex;
  gap: 7px;
`

const WarningCalendarWrapper = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main.error500};
`

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.main.grey600};

  & > * {
    cursor: pointer;
  }
`

import { useTranslation } from 'react-i18next'
import { getTheme } from 'examkrackers-components'

import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'

export const McatProgressChart = data => {
  const mcatData = data.mcatData
  const apiMcatTags = data.mcatTags

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  const correctMcatTags = apiMcatTags?.map(el => {
    const tagAbbreviation = el.substring(0, 3)
    const finalMcatTags = tagAbbreviation.toUpperCase()
    return finalMcatTags
  })

  const { t } = useTranslation()
  const theme = getTheme()

  return {
    series: [
      {
        data: mcatData?.map(el => parseInt(el, 10))
      }
    ],
    options: {
      chart: {
        type: 'bar',
        background: theme.colors.main.white,
        toolbar: {
          show: false
        }
      },
      title: {
        text: t('dashboard.charts.mcatProgress'),
        align: 'left',
        style: {
          fontSize: '16',
          fontFamily: 'Arial, sans-serif'
        },
        offsetX: 40,
        offsetY: 10
      },
      xaxis: {
        categories: correctMcatTags?.map(el => el.toString()),
        labels: {
          style: {
            colors: theme.colors.dashboard.totalProgress,
            fontFamily: 'Arial, sans-serif'
          }
        },
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        tickAmount: 2,
        min: 118,
        max: 132,
        labels: {
          style: {
            colors: theme.colors.dashboard.totalProgress,
            fontFamily: 'Arial, sans-serif'
          },
          offsetY: 2
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            {
              offset: 0,
              color: theme.colors.dashboard.barsTo,
              opacity: 1
            },
            {
              offset: 9,
              color: theme.colors.dashboard.barsFrom,
              opacity: 80
            },
            {
              offset: 100,
              color: theme.colors.dashboard.barsFrom,
              opacity: 1
            }
          ]
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        formatter: function (val: any) {
          return val > 0 ? val : 'N/A'
        },
        enabled: true,
        offsetY: 0,
        offsetX: -40,
        style: {
          fontSize: '12px',
          colors: isDarkTheme
            ? [theme.colors.main.white]
            : [theme.colors.main.black]
        }
      },
      grid: {
        strokeDashArray: 0,
        // @ts-ignore
        borderColor: theme.colors.dashboard.mcat.strokeDash
      }
    }
  }
}

import { Action, CalendarReducer } from 'types'
import { API_STATES } from 'utils/redux'
import {
  addManualCalendarTaskRoutine,
  clearManualCalendarTasksRoutine,
  getAamcEventsColorsRoutine,
  getManualCalendarTasksRoutine,
  removeManualCalendarTaskRoutine,
  setManualCalendarActiveTabRoutine,
  setManualCalendarTasksRoutine,
  setVisibleDatesRoutine,
  updateManualCalendarTasksRoutine
} from 'modules/calendar/ducks/actions'
import { ARCHIVE_TAB_TYPES } from 'utils/calendar'

export const initialState: CalendarReducer = {
  state: API_STATES.PRISTINE,
  aamcEventsColors: [],
  visibleDates: [],
  manualEvents: {
    readings: [],
    mini_mcats: [],
    reviews: [],
    exams: [],
    exam_reviews: []
  },
  manualCalendarActiveTab: 'readings'
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case getAamcEventsColorsRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case getAamcEventsColorsRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case getAamcEventsColorsRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        aamcEventsColors: action.payload
      }
    case getManualCalendarTasksRoutine.REQUEST:
      return {
        ...state,
        state: API_STATES.IN_PROGRESS
      }
    case getManualCalendarTasksRoutine.FAILURE:
      return {
        ...state,
        state: API_STATES.DONE
      }
    case getManualCalendarTasksRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        manualEvents: action.payload?.filter(e => e.from_manual_setup)
      }
    case setManualCalendarTasksRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        manualEvents: action.payload
      }
    case addManualCalendarTaskRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        manualEvents: {
          ...state.manualEvents,
          [ARCHIVE_TAB_TYPES[action.payload.type]]: [
            ...state.manualEvents[ARCHIVE_TAB_TYPES[action.payload.type]],
            action.payload
          ]
        }
      }
    case removeManualCalendarTaskRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        manualEvents: {
          ...state.manualEvents,
          [ARCHIVE_TAB_TYPES[action.payload.type]]: state.manualEvents[
            ARCHIVE_TAB_TYPES[action.payload.type]
          ].filter(event => event.id !== action.payload.id)
        }
      }
    case clearManualCalendarTasksRoutine.SUCCESS:
      return {
        ...state,
        state: API_STATES.DONE,
        manualEvents: initialState.manualEvents
      }
    case setVisibleDatesRoutine.REQUEST:
      return {
        ...state,
        visibleDates: action.payload
      }
    case setManualCalendarActiveTabRoutine.SUCCESS:
      return {
        ...state,
        manualCalendarActiveTab: action.payload
      }
    case updateManualCalendarTasksRoutine.SUCCESS:
      return {
        ...state,
        manualEvents: {
          ...state.manualEvents,
          [state.manualCalendarActiveTab]: action.payload.list
        }
      }
    default:
      return state
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import styled from 'styled-components'

import {
  getExamDetails,
  getSectionDetailsById
} from 'modules/exam/ducks/selectors'

import {
  getAverageQuestionsWorkingTimeGraph,
  getMaxWorkingQuestionAmount,
  getQuestionsData
} from 'modules/diagnostics/ducks/selectors'

import { formatSecondsToMinutesAndSeconds, isDateBeforeToday } from 'utils/date'
import { getPassagesCount } from 'utils/exam'
import { QuestionGraphEntity, GraphPointsEntity, RootReducer } from 'types'

import {
  Button,
  CheckmarkContainedIcon,
  CloseContainedIcon,
  Text
} from 'examkrackers-components'
import AnalyticsService from '../../../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../../../utils/analytics'
import { getAccountType, getStudent } from '../../auth/ducks/selectors'

export const AnswerSheet = ({ headers }): JSX.Element => {
  const [headersWidth, setHeadersWidth] = React.useState([])
  const { t } = useTranslation()
  const params = useParams()
  const history = useHistory()
  const student = useSelector(getStudent)
  const accountType: string = useSelector(getAccountType)

  const id: string = R.propOr('', 'id')(params)
  const sectionId: string = R.propOr('', 'sectionId')(params)

  const questionsData = useSelector(getQuestionsData)
  const examDetails = useSelector(getExamDetails)
  const maxWorkingSeconds = useSelector(getMaxWorkingQuestionAmount)
  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, sectionId)
  )

  const redirectToExplanations = (): void => {
    AnalyticsService(student).logEvent(ANALYTICS_EVENTS.allAnswerReviewed, {
      'Exam Title': R.pathOr('', ['exam', 'title'], examDetails),
      'Exam format': R.pathOr('', ['type', 'title'], examDetails),
      'Account type': accountType,
      'Precentile Rank': R.pathOr(
        0,
        ['exam', 'scores', 'exam', 'percentile_rank'],
        examDetails
      ),
      'Raw Score': R.pathOr(
        0,
        ['exam', 'scores', 'exam', 'amount_correct'],
        examDetails
      ),
      'Scaled Score': R.pathOr(
        0,
        ['exam', 'scores', 'exam', 'scaled_score'],
        examDetails
      ),
      'Section Title': R.propOr('-', 'title', sectionDetails),
      'Section Number': R.propOr(0, 'order', sectionDetails)
    })
    history.push(`/exam/${id}/explanations/${firstQuestionId}`)
  }

  const passagesCount: number = Number(getPassagesCount(sectionDetails))

  const accessPeriodEndDate: string = R.pathOr(
    '',
    ['exam', 'accessible_to'],
    examDetails
  )

  const flagsLength = R.pipe(
    (questions: QuestionGraphEntity[]) =>
      R.filter(R.propEq('is_flagged', true), questions),
    arr => arr.length
  )(questionsData)

  const correctLength: number = R.pipe(
    (questions: QuestionGraphEntity[]) =>
      R.filter(
        (question: QuestionGraphEntity) =>
          question.answer === question.correct_answer
      )(questions),
    R.length
  )(questionsData)

  const incorrectLength: number = R.pipe(
    (questions: QuestionGraphEntity[]) =>
      R.filter(
        (question: QuestionGraphEntity) =>
          question.answer !== question.correct_answer
      )(questions),
    R.length
  )(questionsData)

  const firstQuestionId: string = String(
    R.pipe(R.head, R.propOr('', 'id'))(questionsData)
  )

  const calculatePercentage = (value: number, total: number) =>
    Math.round((value / total) * 100)

  const difficultyAvg: number = R.pipe(
    (questions: QuestionGraphEntity[]) =>
      R.map(
        R.pathOr(0, ['original_question', 'difficulty_percentage']),
        questions
      ),
    R.sum,
    sum => R.divide(sum, questionsData.length),
    Math.round
  )(questionsData)

  const secondsAvg: number = R.pipe(
    (questions: QuestionGraphEntity[]) =>
      R.map(R.pathOr(0, ['timers', 'working']), questions),
    R.sum,
    sum => R.divide(sum, questionsData.length),
    Math.round
  )(questionsData)

  const averageQuestionsWorkingTimeData = useSelector(
    getAverageQuestionsWorkingTimeGraph
  )

  const averageSecondsAvg: number = R.pipe(
    // @ts-ignore
    (questions: GraphPointsEntity[]) => R.map(R.propOr(0, 'y'), questions),
    R.sum,
    sum => R.divide(sum, questionsData.length),
    Math.round
    // @ts-ignore
  )(averageQuestionsWorkingTimeData)

  const workingLineWidth: number = (secondsAvg / maxWorkingSeconds) * 100
  const averageWorkingLineWidth: number =
    (averageSecondsAvg / maxWorkingSeconds) * 100

  const getActionButton = () => {
    if (isDateBeforeToday(accessPeriodEndDate)) {
      return (
        <Button
          id={`exam-${id}-expired`}
          size='small'
          variant='contained'
          disabled
        >
          {t('exams.actions.expired')}
        </Button>
      )
    } else {
      return (
        <ActionButton
          id={`exam-${id}-explanations-review`}
          onClick={redirectToExplanations}
        >
          {t('exams.actions.viewAll')}
        </ActionButton>
      )
    }
  }

  // Get the width of the headers
  const getHeadersWidth = () =>
    headers.map((header: any) => {
      const element = document.getElementById(header.columnId)

      return {
        columnId: header.columnId,
        width: element ? element.getBoundingClientRect().width : 0
      }
    })

  // Render the summary cells.
  const summaryCells = headers.map((header: any) => {
    // Find the width of the column
    const findWidth = () =>
      R.pipe(
        R.find(R.propEq('columnId', header.columnId)),
        R.propOr(0, 'width')
      )(headersWidth)

    const renderContent = () => {
      switch (header.columnId) {
        case 'passage':
          return (
            <Text bold size='m'>
              <span>{passagesCount}</span>
            </Text>
          )
        case 'order':
          return (
            <Text bold size='m'>
              {questionsData.length}
            </Text>
          )
        case 'correct_answer':
          return (
            <CorrectSummary>
              <div className='row'>
                <CorrectContainer>
                  <CheckmarkContainedIcon />
                </CorrectContainer>
                <div className='text'>
                  <Text bold size='m'>
                    {calculatePercentage(correctLength, questionsData.length)}%{' '}
                  </Text>
                </div>
              </div>
              <div className='row'>
                <IncorrectContainer>
                  <CloseContainedIcon />
                </IncorrectContainer>
                <div className='text'>
                  <Text bold size='m'>
                    {calculatePercentage(incorrectLength, questionsData.length)}
                    %
                  </Text>
                </div>
              </div>
            </CorrectSummary>
          )

        case 'difficulty':
          return (
            <DifficultyContainer>
              <Text bold size='s'>
                {t('diagnostics.avgDifficulty')} {difficultyAvg}%
              </Text>
            </DifficultyContainer>
          )

        case 'timeWorkingQuestion':
          return (
            <TimeWorkingSummaryCell>
              <Text bold={false} size='m'>
                {formatSecondsToMinutesAndSeconds(secondsAvg)}
              </Text>
              <LineGraph>
                <SolidLine workingLineWidth={workingLineWidth} />
                <DottedLine averageWorkingLineWidth={averageWorkingLineWidth} />
              </LineGraph>
            </TimeWorkingSummaryCell>
          )

        case 'is_flagged':
          return (
            <Text bold size='m'>
              {t('diagnostics.answerSheet.summary.flags', {
                count: flagsLength
              })}
            </Text>
          )
        case 'action':
          return getActionButton()
        default:
          return ''
      }
    }

    return (
      <SummaryCell width={findWidth()} key={`summary-${header.columnId}`}>
        {renderContent()}
      </SummaryCell>
    )
  })

  // Update the width of the headers when the questions data,
  // exam object, or URL parameters change.
  React.useEffect(() => {
    setHeadersWidth(getHeadersWidth())
  }, [questionsData, examDetails, params])

  const updateHeadersWidthOnResize = () => {
    setHeadersWidth(getHeadersWidth())
  }

  React.useEffect(() => {
    window.addEventListener('resize', updateHeadersWidthOnResize)

    return () => {
      window.removeEventListener('resize', updateHeadersWidthOnResize)
    }
  }, [])

  return (
    <FloatingSummary>
      <Container>{summaryCells}</Container>
    </FloatingSummary>
  )
}

const CorrectContainer = styled.div`
  color: ${({ theme }) => theme.colors.diagnostics.question.correct};
  font-size: 20px;

  path {
    fill: ${({ theme }) => theme.colors.diagnostics.question.correct};
  }
`

const IncorrectContainer = styled.div`
  color: ${({ theme }) => theme.colors.diagnostics.question.incorrect};
  font-size: 20px;
`

const FloatingSummary = styled.div`
  position: fixed;
  bottom: 10px;
  left: auto;
  right: auto;
  background: ${({ theme }) => theme.colors.main.brownGradient};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border: 1px solid ${({ theme }) => theme.colors.main.brown};
  height: 38px;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  width: 100%;
  max-width: 1260px;
  border-radius: 6px;
`

const Container = styled.div`
  margin: auto;
  max-width: 1280px;
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 0 20px;
`

const SummaryCell = styled.div`
  width: ${({ width }) => width}px !important;
  padding: 0 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
`

const DifficultyContainer = styled.div`
  p {
    display: block;
    text-align: center;
  }
`

const CorrectSummary = styled.div`
  display: flex;

  .row {
    display: inline-flex;
    align-items: center;

    div {
      flex: none;
      display: inline-block;
    }

    &:first-of-type {
      & div:last-of-type {
        margin-right: 15px;
      }
    }
  }

  .text {
    margin-left: 5px;
  }
`

const LineGraph = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`

const Line = styled.div`
  margin-left: 10px;
  height: 2px;
  max-width: 100% !important;
`

const SolidLine = styled(Line)`
  width: ${({ workingLineWidth }) => workingLineWidth}%;
  border-bottom: 3px solid ${({ theme }) => theme.colors.main.text};
  margin-bottom: 5px;
`

const DottedLine = styled(Line)`
  width: ${({ averageWorkingLineWidth }) => averageWorkingLineWidth}%;
  border-bottom: 3px dotted ${({ theme }) => theme.colors.main.text};
`

const TimeWorkingSummaryCell = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const ActionButton = styled.div`
  cursor: pointer;
  text-decoration: underline;
`

export default AnswerSheet

import React, { useEffect, useState } from 'react'

import {
  TestDayCertificationExpireModal,
  TimeEndWarning,
  TimeExpiredModal
} from 'examkrackers-components'
import { useSelector } from 'react-redux'
import {
  getExamQuestionsMap,
  getSectionIdByCurrentPageId,
  getLastSectionId,
  getExamDetails
} from 'modules/exam/ducks/selectors'
import { getSectionPagesIds } from 'modules/exam/utils/examPagesConfig'
import { includes } from 'ramda'
import { RootReducer } from 'types'
import PageIds from 'modules/exam/utils/pageIds'
import usePrevious from 'hooks/usePrevious'
import * as R from 'ramda'

interface TimeAlertsHandlerProps {
  currentPage: string
  timeRemaining: string
  hasTime: boolean
  onTimeEnd: () => any
  setCurrentPage: (id) => void
}

export const TimeAlertsHandler = ({
  currentPage,
  onTimeEnd,
  timeRemaining,
  hasTime,
  setCurrentPage
}: TimeAlertsHandlerProps): JSX.Element => {
  // modal states
  const [
    testDayCertificationExpireModalOpen,
    setTestDayCertificationExpireModalVisibility
  ] = useState(false)
  const [timeExpiredModalOpen, setTimeExpiredModalVisibility] = useState(false)
  const [timeEndModalOpen, setTimeEndModalVisibility] = useState(false)
  const [timeEndPageId, setTimeEndPageId] = useState('')
  const prevPageId = usePrevious(currentPage)

  const sectionIdOnTimeEnd = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, timeEndPageId)
  )
  const lastSectionId = useSelector(getLastSectionId)
  const examDetails = useSelector(getExamDetails)
  const examSectionsCount = R.pathOr(1, ['type', 'section_count'], examDetails)

  // modal triggers
  const openTestDayCertificationExpireModal = () =>
    setTestDayCertificationExpireModalVisibility(true)
  const closeTestDayCertificationExpireModal = () =>
    setTestDayCertificationExpireModalVisibility(false)

  const openTimeExpiredModal = () => setTimeExpiredModalVisibility(true)
  const closeTimeExpiredModal = () => setTimeExpiredModalVisibility(false)

  const openTimeEndModal = () => setTimeEndModalVisibility(true)
  const closeTimeEndModal = () => setTimeEndModalVisibility(false)

  const examPagesSummary = useSelector(getExamQuestionsMap)
  const sectionPagesIds = getSectionPagesIds(examPagesSummary)

  useEffect(() => {
    if (prevPageId !== currentPage && currentPage === PageIds.blankPage) {
      // @ts-ignore
      setTimeEndPageId(prevPageId)
    }
  }, [prevPageId, currentPage])

  const handleTimeEnd = () => {
    const nextPageUrl =
      sectionIdOnTimeEnd === lastSectionId
        ? PageIds.endExam
        : `section-break-${sectionIdOnTimeEnd}`
    setCurrentPage(nextPageUrl)
    closeTimeExpiredModal()
    setTimeEndPageId('')
  }

  useEffect(() => {
    // @ts-ignore
    if (timeRemaining === '00:00:00') {
      hasTime && onTimeEnd()
      closeTestDayCertificationExpireModal()
    }

    if (
      timeRemaining === '00:00:00' &&
      includes(currentPage, sectionPagesIds)
    ) {
      openTimeExpiredModal()
      setCurrentPage(PageIds.blankPage)
    }

    if (
      timeRemaining === '00:30:00' &&
      includes(currentPage, sectionPagesIds) &&
      examSectionsCount > 1
    ) {
      openTimeEndModal()
    }

    // @ts-ignore
    if (timeRemaining === '00:01:00' && currentPage === PageIds.certification) {
      hasTime && openTestDayCertificationExpireModal()
    }
  }, [timeRemaining, examDetails])

  return (
    <div>
      <TestDayCertificationExpireModal
        initHeight={270}
        open={testDayCertificationExpireModalOpen}
        handleClose={closeTestDayCertificationExpireModal}
      />
      <TimeEndWarning
        minLeft='30'
        open={timeEndModalOpen}
        handleClose={closeTimeEndModal}
        handleConfirm={closeTimeEndModal}
      />
      <TimeExpiredModal
        open={timeExpiredModalOpen}
        handleClose={closeTimeExpiredModal}
        handleConfirm={handleTimeEnd}
      />
    </div>
  )
}

export default TimeAlertsHandler

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import qs from 'qs'
import { Modal, Button, Tooltip } from 'examkrackers-components'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { SEVERITY } from 'utils/toast'
import { useHistory } from 'react-router-dom'
import { isNil, pathOr, propOr, dissoc, pipe } from 'ramda'
import { resetFlashcardsProficiencyLevel } from 'services/FlashcardsService'
import {
  fetchFlashcardsStudyListRoutine,
  fetchPlvlStatsRoutine,
  resetSkippedCardsRoutine
} from 'modules/flashcards/ducks/actions'
import { selectAllBooks } from 'modules/books/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/flashcards/utils/events'
import { getIsOrdinaryStudent } from 'modules/auth/ducks/selectors'

export const ModalResetFlashcards = () => {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    push,
    location: { pathname, search }
  } = useHistory()
  const books = useSelector(selectAllBooks)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const filters = { filter: propOr({}, 'filter', parsedQuery) }
  const searchWithoutPlvl = qs.stringify(
    {
      filter: {
        // @ts-ignore
        ...pipe(
          propOr({}, 'filter'),
          // @ts-ignore
          dissoc('f.proficiency_level')
          // @ts-ignore
        )(parsedQuery)
      }
    },
    {
      addQueryPrefix: true,
      encode: false
    }
  )

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const fetchFlashcardStudy = useCallback(
    query => dispatch(fetchFlashcardsStudyListRoutine({ query })),
    [dispatch]
  )

  const fetchPlvlStats = useCallback(
    query => dispatch(fetchPlvlStatsRoutine({ query })),
    [dispatch]
  )

  const resetSkippedCards = useCallback(
    () => dispatch(resetSkippedCardsRoutine({ plvl: 0 })),
    [dispatch]
  )

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleSubmit = () => {
    const handleError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    const handleSuccess = () => {
      const parsedResetQuery = qs.parse(searchWithoutPlvl, {
        ignoreQueryPrefix: true
      })

      const newQuery = qs.stringify(
        {
          ...parsedQuery,
          filter: {
            ...parsedResetQuery.filter,
            'f.proficiency_level': 1
          },
          shuffle: false
        },
        { addQueryPrefix: true }
      )
      push(`${pathname}${searchWithoutPlvl}`)
      fetchPlvlStats(newQuery)
      resetSkippedCards()
      fetchFlashcardStudy(newQuery)
      eventEmitter.emit(events.resetPlvl)
      handleClose()
      showToast({
        key: 'toast.proficiencyLevelResetSuccess',
        severity: SEVERITY.success
      })
    }

    resetFlashcardsProficiencyLevel({ query: searchWithoutPlvl })
      .then(handleSuccess)
      .catch(handleError)
  }

  const description = useMemo(() => {
    // @ts-ignore
    const bookId = pathOr(null, ['filter', 'b.id'], filters)
    const book = books.find(b => propOr('', 'id', b) === bookId)
    const bookChapters = propOr([], 'chapters', book)
    const chapterId = pathOr(null, ['filter', 'bcc.id'], filters)

    // @ts-ignore
    const chapter = bookChapters.find(c => propOr('', 'id', c) === chapterId)
    // Set to null, because we won't restrict on plvl now
    const proficiencyLevel = null

    if ([bookId, chapterId, proficiencyLevel].every(item => isNil(item))) {
      return (
        <ModalContentDescription>
          {t('flashcards.reset.resetAllDescription')}
        </ModalContentDescription>
      )
    } else {
      return (
        <div>
          <ModalContentDescription>
            <span>{t('flashcards.reset.resetFilteredDescription')} </span>
          </ModalContentDescription>
          {isNotNilOrEmpty(book) ? (
            <DescriptionItem>
              <span>• {t('flashcards.filters.book')}: </span>
              {/* @ts-ignore */}
              {book.title}
            </DescriptionItem>
          ) : null}
          {isNotNilOrEmpty(chapter) ? (
            <DescriptionItem>
              <span>• {t('flashcards.filters.chapter')}: </span>
              {chapter.title}
            </DescriptionItem>
          ) : null}
          {isNotNilOrEmpty(proficiencyLevel) ? (
            <DescriptionItem>
              <span>• {t('flashcards.filters.proficiencyLevel')}: </span>
              {proficiencyLevel}
            </DescriptionItem>
          ) : null}
        </div>
      )
    }
  }, [filters])

  return (
    <>
      <TriggerWrapper>
        <Button
          id='reset-flashcards-button'
          onClick={handleOpen}
          size='small'
          color='tertiary'
          disabled={!isOrdinaryStudent}
        >
          {t('flashcards.reset.resetCTA')}
        </Button>
        <Tooltip
          info
          tooltipContent={t('flashcards.reset.resetTooltip')}
          id='reset-plvl-tooltip'
        />
      </TriggerWrapper>
      <Modal
        title={t('flashcards.reset.modalTitle')}
        open={open}
        handleClose={handleClose}
      >
        <ModalContainer>
          {description}
          <ButtonContainer>
            <Button
              size='small'
              color='secondary'
              type='submit'
              id='reset-proficiency-submit'
              onClick={handleSubmit}
            >
              {t('flashcards.reset.submitCTA')}
            </Button>
            <Button
              size='small'
              type='submit'
              color='tertiary'
              variant='contained'
              id='reset-proficiency-cancel'
              onClick={handleClose}
            >
              {t('flashcards.reset.cancelCTA')}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default ModalResetFlashcards

const ButtonContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  justify-content: center;

  button {
    min-width: 130px;
  }

  button + button {
    margin-left: 10px;
  }
`

const ModalContainer = styled.div`
  text-align: left;
`

const ModalContentDescription = styled.div`
  white-space: pre;
`

const DescriptionItem = styled.div`
  span {
    font-weight: bold;
  }
  margin: 5px 0;
`

const TriggerWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  svg {
    font-size: 12px;
  }
`

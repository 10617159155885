import * as R from 'ramda'
import { createSelector, OutputSelector } from 'reselect'
import { SelectionReducer, RootReducer } from 'types'

//
// Selectors
//
export const selectSelection = (state: any) => state.selection

export const getSelectedItems: OutputSelector<
  RootReducer,
  any,
  (res: SelectionReducer) => any
> = createSelector(selectSelection, R.propOr([], 'selectedItems'))

export const getSelectedEntities: OutputSelector<
  RootReducer,
  any,
  (res: SelectionReducer) => any
> = createSelector(selectSelection, R.propOr([], 'selectedEntities'))

export const getHasSomeItemsSelectedGlobally = createSelector(
  selectSelection,
  R.pipe(
    R.propOr([], 'selectedItems'),
    // @ts-ignore
    R.length,
    R.lt(0)
  )
)

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import qs from 'qs'
import { propOr, pipe, filter, propEq, length } from 'ramda'
import { fetchPinNotesForParticularSubChapters } from 'services/PinNotesService'

import PinNote from 'modules/books/components/PinNote'
import { useTranslation } from 'react-i18next'

import { PinIcon } from 'examkrackers-components'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

import { isNilOrEmpty } from 'utils/ramda'

const PinNoteList = ({ sectionId, id }) => {
  const [sectionsWithPinNotes, setSectionWithPinNotes] = useState([])
  const [filteredPinNotes, setFilteredPinNotes] = useState([])
  const [paginationPinNotes, setPaginationPinNotes] = useState([])
  const [filterVariant, setFilterVariant] = useState('')
  const pinNotesCounter = propOr(0, 'recordsTotal', paginationPinNotes)
  const numberOfAPinNotes = pipe(
    // @ts-ignore
    filter(propEq('variant', 'A')),
    length
    // @ts-ignore
  )(sectionsWithPinNotes)
  const numberOfBPinNotes = pipe(
    // @ts-ignore
    filter(propEq('variant', 'B')),
    length
    // @ts-ignore
  )(sectionsWithPinNotes)
  const numberOfCPinNotes = pipe(
    // @ts-ignore
    filter(propEq('variant', 'C')),
    length
    // @ts-ignore
  )(sectionsWithPinNotes)
  const {
    location: { search }
  } = useHistory()

  const { t } = useTranslation()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const sectionIdFromQuery = propOr('', 'sectionId', parsedQuery)
  const bookIdFromQuery: string = propOr('', 'bookId', parsedQuery)
  const fetchPinNotesForSubChapter = async (bookId, sectionId) => {
    try {
      const result = await fetchPinNotesForParticularSubChapters({
        bookId: bookId,
        subChapterId: sectionId
      })
      setSectionWithPinNotes(result.data.data)
      setPaginationPinNotes(result.data.meta)
    } catch (e) {
      console.error(e)
    }
  }

  const handleRefreshPinNotesList = () => {
    fetchPinNotesForSubChapter(bookIdFromQuery, sectionIdFromQuery)
  }

  useEffect(() => {
    fetchPinNotesForSubChapter(bookIdFromQuery, sectionId)
  }, [sectionId, bookIdFromQuery])

  useEffect(() => {
    eventEmitter.on(events.refreshPinNotesList, handleRefreshPinNotesList)

    return () => {
      eventEmitter.off(events.refreshPinNotesList, handleRefreshPinNotesList)
    }
  }, [parsedQuery])

  const handleFilter = variant => {
    setFilterVariant(variant)
  }
  useEffect(() => {
    const filteredPinNotes = pipe(filter(propEq('variant', filterVariant)))(
      sectionsWithPinNotes
    )
    setFilteredPinNotes(filteredPinNotes)
  }, [filterVariant])

  useEffect(() => {
    setFilterVariant('')
  }, [sectionId])

  return (
    <Container>
      <FilterSection>
        <FilterWrapper>
          <Title>{t('pinNotes.filteredBy')}</Title>
          <PinFilters>
            <AllPins
              active={filterVariant === ''}
              onClick={() => handleFilter('')}
            >
              {t('pinNotes.all')}
              <NumberOfPinNotesWrapper>
                {pinNotesCounter}
              </NumberOfPinNotesWrapper>
            </AllPins>
            <PinContainer
              active={filterVariant === 'A'}
              variant='A'
              onClick={() => handleFilter('A')}
            >
              <PinIcon />
              <NumberOfPinNotesWrapper>
                {numberOfAPinNotes}
              </NumberOfPinNotesWrapper>
            </PinContainer>
            <PinContainer
              active={filterVariant === 'B'}
              variant='B'
              onClick={() => handleFilter('B')}
            >
              <PinIcon />
              <NumberOfPinNotesWrapper>
                {numberOfBPinNotes}
              </NumberOfPinNotesWrapper>
            </PinContainer>
            <PinContainer
              active={filterVariant === 'C'}
              variant='C'
              onClick={() => handleFilter('C')}
            >
              <PinIcon />
              <NumberOfPinNotesWrapper>
                {numberOfCPinNotes}
              </NumberOfPinNotesWrapper>
            </PinContainer>
          </PinFilters>
        </FilterWrapper>
      </FilterSection>
      <PinNotesContainer>
        {isNilOrEmpty(filterVariant)
          ? sectionsWithPinNotes.map(pin => {
              const pinId: string = propOr('', 'id', pin)
              const pinNote: string = propOr('', 'note', pin)
              const pinTitle: string = propOr('', 'title', pin)
              const pinVariant: string = propOr('', 'variant', pin)
              const contentId: string = propOr('', 'content_id', pin)
              const studentBookId: string = propOr('', 'student_book_id', pin)

              return (
                <PinWrapper key={pinId}>
                  <PinNote
                    pinNoteList
                    variant={pinVariant}
                    studentBookContentId={contentId}
                    bookId={bookIdFromQuery}
                    pinTitle={pinTitle}
                    pinNote={pinNote}
                    pinNoteId={pinId}
                    studentBookIdFromProps={studentBookId}
                  />
                </PinWrapper>
              )
            })
          : filteredPinNotes.map(pin => {
              const pinId: string = propOr('', 'id', pin)
              const pinNote: string = propOr('', 'note', pin)
              const pinTitle: string = propOr('', 'title', pin)
              const pinVariant: string = propOr('', 'variant', pin)
              const contentId: string = propOr('', 'content_id', pin)
              const studentBookId: string = propOr('', 'student_book_id', pin)

              return (
                <PinWrapper key={pinId}>
                  <PinNote
                    pinNoteList
                    variant={pinVariant}
                    studentBookContentId={contentId}
                    bookId={bookIdFromQuery}
                    pinTitle={pinTitle}
                    pinNote={pinNote}
                    pinNoteId={pinId}
                    studentBookIdFromProps={studentBookId}
                  />
                </PinWrapper>
              )
            })}
      </PinNotesContainer>
    </Container>
  )
}
export default PinNoteList

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const FilterSection = styled.div`
  position: fixed;
  width: 500px;
  height: 48px;
  margin-bottom: 12px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  z-index: 10;
`

const FilterWrapper = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`

const Title = styled.div``

const PinWrapper = styled.div`
  padding: 4px;
`

const PinFilters = styled.div`
  display: flex;
`

const PinContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 17px;
  color: ${({ theme, variant }) => {
    switch (true) {
      case variant === 'A':
        return theme.colors.pins.variantA.shape
      case variant === 'B':
        return theme.colors.pins.variantB.shape
      case variant === 'C':
        return theme.colors.pins.variantC.shape
      default:
        return theme.colors.pins.variantA.shape
    }
  }};
  ${({ active }) =>
    active &&
    css`
      border-bottom: 1px solid
        ${({ theme, variant }) => {
          switch (true) {
            case variant === 'A':
              return theme.colors.pins.variantA.shape
            case variant === 'B':
              return theme.colors.pins.variantB.shape
            case variant === 'C':
              return theme.colors.pins.variantC.shape
            default:
              return theme.colors.pins.variantA.shape
          }
        }};
    `}
`
const AllPins = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey600};
    `}
`
const NumberOfPinNotesWrapper = styled.div`
  padding-left: 4px;
`
const PinNotesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 55px;
`

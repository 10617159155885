import { has } from 'ramda'
import { MixedVideo, Video } from 'types/videos'
import { isNotNilOrEmpty } from 'utils/ramda'
import * as R from 'ramda'

export const VIDEOS_QUERY_PARAMS = {
  category: 'category',
  favorites: 'sfv.id',
  'b.id': 'b.id',
  'bcc.id': 'bcc.id',
  'bsubc.id': 'bsubc.id',
  search: 'search',
  __is_watched: '__is_watched'
}

export function searchByTitle(video: Video, searchText: string) {
  return video.title.toLowerCase().includes(searchText?.toLowerCase() || '')
}

function searchByCategory(video: Video, category: string) {
  if (category === 'all') {
    return video
  }

  if (category === 'favourites') {
    return video.is_favourite
  }

  return video.category === category
}

function searchByIsWatched(video: Video, isWatched: string) {
  if (JSON.parse(isWatched) === false) {
    return video.is_watched === false
  }
  return video.is_watched
}

export function filterVideos(
  videosList: MixedVideo[],
  searchText: string,
  category: string,
  bookId: string,
  chapterId: string,
  subchapterId: string,
  isWatched?: string
) {
  return videosList.filter(video => {
    if (subchapterId && chapterId && bookId) {
      return (
        has('tags', video) &&
        !!video.tags?.find(tag => tag.subchapter_id === subchapterId) &&
        searchByTitle(video, searchText) &&
        searchByCategory(video, category)
      )
    }

    if (chapterId && bookId) {
      return (
        has('tags', video) &&
        !!video.tags?.find(tag => tag.chapter_id === chapterId) &&
        searchByTitle(video, searchText) &&
        searchByCategory(video, category)
      )
    }

    if (bookId && isWatched) {
      return (
        has('tags', video) &&
        !!video.tags?.find(tag => tag.book_id === bookId) &&
        searchByCategory(video, category) &&
        searchByIsWatched(video, isWatched)
      )
    }
    if (bookId) {
      return (
        has('tags', video) &&
        !!video.tags?.find(tag => tag.book_id === bookId) &&
        searchByTitle(video, searchText) &&
        searchByCategory(video, category)
      )
    }

    if (isWatched) {
      return (
        searchByCategory(video, category) && searchByIsWatched(video, isWatched)
      )
    }

    if (!searchText) {
      return searchByCategory(video, category)
    }

    return searchByCategory(video, category) && searchByTitle(video, searchText)
  })
}

export const updateArrayOfVideos = (oldData, newData) => {
  const map = new Map()

  oldData.forEach(item => map.set(item.id, item))
  newData.forEach(item => map.set(item.id, item))

  return [...map.values()]
}

export const videoStringTags = tags => {
  if (isNotNilOrEmpty(tags)) {
    const tagArray = tags.map(el => {
      return el.tag
    })
    return tagArray.toString()
  } else {
    return false
  }
}

export const videoMinutesDuration = duration => {
  const time = duration || ''
  if (time) {
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60
    return `${minutes}:${seconds} min.`
  } else {
    return false
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ExamPageConfigProps, RootReducer } from 'types'
import { ExamContainer, ExamNavigation, Loader } from 'examkrackers-components'

import { generateDynamicExamExplanationPagesConfigs } from 'modules/exam/utils/examPagesConfig'

import {
  getQuestionNavItems,
  tutorialExamSteps,
  getSectionExamStepsById,
  REVIEW_FILTER_TYPES
} from 'modules/exam/utils/exam'

import { isNotNilOrEmpty } from 'utils/ramda'

import ExamTopComponents from 'modules/exam/components/ExamElements/ExplanationsTopComponents'
import BlankPage from 'modules/exam/components/QuestionPages/BlankPage'
import DrawingOnCurrentPage from 'modules/markExam/components/DrawingOnCurrentPage'
import RedirectAfterAccessPeriodTimeout from 'components/RedirectAfterAccessPeriodTimeout'

import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'

import {
  getQuestionStepStates,
  getExamQuestionsMap,
  getSectionIdByCurrentPageId,
  getExamDetails
} from 'modules/exam/ducks/selectors'

const emptyFunction = () => {}

const initNavigationHandlers = {
  endExamButtonOnClick: emptyFunction,
  endSectionButtonOnClick: emptyFunction,
  endTestDayCertificationOnClick: emptyFunction,
  navigationButtonOnClick: emptyFunction,
  nextButtonOnClick: emptyFunction,
  previousButtonOnClick: emptyFunction,
  reviewAllButtonOnClick: emptyFunction,
  reviewFlaggedButtonOnClick: emptyFunction,
  reviewIncompleteButtonOnClick: emptyFunction,
  reviewScreenButtonOnClick: emptyFunction,
  answerSheetButtonOnClick: emptyFunction,
  markExamButtonOnClick: emptyFunction
}

export const ExamExplanations = (): JSX.Element => {
  // Get i18n handler
  // const { t } = useTranslation()
  // Get URL parameters
  const params = useParams()
  const history = useHistory()
  const questionId: string = R.propOr('', 'questionId', params)
  const id: string = R.propOr('', 'id')(params)

  // Get React-Redux dispatch handler
  const dispatch = useDispatch()

  const currentPage = questionId
  const setCurrentPage = (pageId: string) =>
    history.push(`/exam/${id}/explanations/${pageId}`)

  const [navigationHandlers, setNavigationHandlers] = React.useState(
    initNavigationHandlers
  )

  const examPagesSummary = useSelector(getExamQuestionsMap)
  const examDetails = useSelector(getExamDetails)
  const sectionIdByCurrentPageId = useSelector((state: RootReducer) =>
    getSectionIdByCurrentPageId(state, currentPage)
  )
  const allQuestionStates = useSelector(getQuestionStepStates)

  const nextPageHandler = (id: string) => () => setCurrentPage(id)
  const stepsList = isNotNilOrEmpty(sectionIdByCurrentPageId)
    ? getSectionExamStepsById(examDetails, sectionIdByCurrentPageId)
    : tutorialExamSteps

  const navItems = getQuestionNavItems(
    nextPageHandler,
    allQuestionStates,
    stepsList
  )
  const examWizardPages = generateDynamicExamExplanationPagesConfigs(
    REVIEW_FILTER_TYPES.all
  )(examPagesSummary)

  // @ts-ignore
  const currentPageConfig: ExamPageConfigProps = R.find(
    R.propEq('id', currentPage)
    // @ts-ignore
  )(examWizardPages)
  const Component = R.propOr(BlankPage, 'component')(currentPageConfig)

  const fetchExamDetails = React.useCallback(
    payload => dispatch(fetchExamDetailsRoutine(payload)),
    [dispatch]
  )

  React.useEffect(() => {
    fetchExamDetails({ id })
  }, [])

  React.useEffect(() => {
    setNavigationHandlers(prevState => ({
      ...prevState,
      answerSheetButtonOnClick: () =>
        history.push(
          `/exam/${id}/score-report/answers-graph/${sectionIdByCurrentPageId}/answer-sheet`
        )
    }))
  }, [params, examDetails])

  const hasPageConfigFound: boolean = isNotNilOrEmpty(currentPageConfig)
  const hasExamFetched: boolean = isNotNilOrEmpty(examDetails)
  const isTutorialPage = R.includes('tutorial', currentPage)
  const accessPeriod = R.pathOr('', ['exam', 'accessible_to'])(examDetails)

  const setOpenDrawingHandler = (handler: any) => {
    setNavigationHandlers(prevState => ({
      ...prevState,
      markExamButtonOnClick: handler
    }))
  }

  if (
    hasExamFetched &&
    hasPageConfigFound &&
    R.pathOr('', ['exam', 'id'])(examDetails) !== ''
  ) {
    return (
      <>
        <DrawingOnCurrentPage setOpenHandler={setOpenDrawingHandler} />
        <Container>
          <ExamContainer>
            <RedirectAfterAccessPeriodTimeout date={accessPeriod} />
            <div id={`exam-explanation-page-${currentPage}`} />
            <ExamTopComponents
              currentPage={currentPage}
              currentPageConfig={currentPageConfig}
            />
            {/* @ts-ignore */}
            <Component
              currentPage={currentPage}
              currentPageConfig={currentPageConfig}
              setCurrentPage={setCurrentPage}
              setNavigationHandlers={setNavigationHandlers}
              setOnTimeEnd={() => {}}
            />
            <ExamNavigation
              navigationItems={navItems}
              keepPeriodicTable={isTutorialPage}
              endExamButton={currentPageConfig.navigationButtons.endExamButton}
              endSectionButton={
                currentPageConfig.navigationButtons.endSectionButton
              }
              endTestDayCertification={
                currentPageConfig.navigationButtons.endTestDayCertification
              }
              navigationButton={
                currentPageConfig.navigationButtons.navigationButton
              }
              nextButton={currentPageConfig.navigationButtons.nextButton}
              periodicTableButton={
                currentPageConfig.navigationButtons.periodicTableButton
              }
              previousButton={
                currentPageConfig.navigationButtons.previousButton
              }
              reviewAllButton={
                currentPageConfig.navigationButtons.reviewAllButton
              }
              reviewFlaggedButton={
                currentPageConfig.navigationButtons.reviewFlaggedButton
              }
              reviewIncompleteButton={
                currentPageConfig.navigationButtons.reviewIncompleteButton
              }
              reviewScreenButton={
                currentPageConfig.navigationButtons.reviewScreenButton
              }
              answerSheetButton={
                currentPageConfig.navigationButtons.answerSheetButton
              }
              markExamButton
              endExamButtonOnClick={navigationHandlers.endExamButtonOnClick}
              endSectionButtonOnClick={
                navigationHandlers.endSectionButtonOnClick
              }
              endTestDayCertificationOnClick={
                navigationHandlers.endTestDayCertificationOnClick
              }
              nextButtonOnClick={navigationHandlers.nextButtonOnClick}
              previousButtonOnClick={navigationHandlers.previousButtonOnClick}
              reviewAllButtonOnClick={navigationHandlers.reviewAllButtonOnClick}
              reviewFlaggedButtonOnClick={
                navigationHandlers.reviewFlaggedButtonOnClick
              }
              reviewIncompleteButtonOnClick={
                navigationHandlers.reviewIncompleteButtonOnClick
              }
              reviewScreenButtonOnClick={
                navigationHandlers.reviewScreenButtonOnClick
              }
              answerSheetButtonOnClick={
                navigationHandlers.answerSheetButtonOnClick
              }
              markExamButtonOnClick={navigationHandlers.markExamButtonOnClick}
            />
          </ExamContainer>
        </Container>
      </>
    )
  } else {
    return <Loader />
  }
}

export default React.memo(ExamExplanations)

const Container = styled.div`
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  min-height: 0;
  position: relative;
  flex-direction: column;

  & > div {
    height: 100% !important;
  }
`

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { createCalendarRowsFromWeeks, DAYS_OF_WEEK } from 'utils/calendar'
import { flatten } from 'ramda'
import { useDispatch } from 'react-redux'
import CalendarRow from './CalendarRow'
import MonthsToggle from './MonthsToggle'
import { setVisibleDatesRoutine } from 'modules/calendar/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { CalendarContext } from 'hooks/CalendarContext'
import { addDays } from 'date-fns'
import debounce from 'lodash.debounce'
import { DATE_FORMATS, formatDate } from 'utils/date'

// import PreventBackButtonAfterInitModal from './PreventBackButtonAfterInitModal'

const Calendar = ({ manualOpen }) => {
  const { calendarWeeks, archiveEvents, setFirstVisibleSunday, isLoading } =
    useContext(CalendarContext)
  const dispatch = useDispatch()

  const hasManualEvents = isNotNilOrEmpty(archiveEvents)
  const today = new Date().getDay()

  const handleSetFirstVisibleSunday = debounce(setFirstVisibleSunday, 200)

  const handleRowVisibilityChange = () => {
    const visibleRows: string[] = []

    const rows = document.querySelectorAll('.calendar-row')
    rows.forEach(el => {
      const mainCalendarRows = el?.getBoundingClientRect()

      if (mainCalendarRows) {
        const condition =
          mainCalendarRows.top >= 100 &&
          mainCalendarRows.left >= 0 &&
          mainCalendarRows.bottom <=
            (window.innerHeight + 20 ||
              document.documentElement.clientHeight + 20)
        if (condition) {
          visibleRows.push(el.id)
        } else {
          visibleRows.filter(row => row !== el.id)
        }
      }
    })

    const daysArray = visibleRows.map(row => {
      const startDate = new Date(row.slice(0, 10))
      const startUTCDate = new Date(
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startDate.getHours(),
          startDate.getMinutes(),
          startDate.getSeconds()
        )
      )
      const endDate = new Date(row.slice(11, 21))
      const endUTCDate = new Date(
        Date.UTC(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endDate.getHours(),
          endDate.getMinutes(),
          endDate.getSeconds()
        )
      )
      const dates: string[] = []

      let currentDate: Date | string = startUTCDate

      while (currentDate <= endUTCDate) {
        dates.push(currentDate.toISOString().slice(0, 10))

        currentDate = addDays(currentDate, 1)
      }

      return dates
    })

    const result = flatten(daysArray)

    handleSetFirstVisibleSunday(result[0])

    dispatch(setVisibleDatesRoutine(result))
  }

  const getScrollbarWidth = () => {
    const container = document.getElementById('scrollable-calendar-container')
    const width = container ? container.offsetWidth - container.clientWidth : 0

    return width || 0
  }

  useEffect(() => {
    const container = document.getElementById('days-header')
    const scrollbarWidth = getScrollbarWidth()

    if (container) {
      container.style.paddingRight = `${scrollbarWidth - 1}px`
    }

    const calendarContainer = document.getElementById(
      'scrollable-calendar-container'
    )

    handleRowVisibilityChange()

    calendarContainer?.addEventListener('scroll', handleRowVisibilityChange)

    return () => {
      calendarContainer?.removeEventListener(
        'scroll',
        handleRowVisibilityChange
      )
    }
  }, [])

  const todayDate = formatDate(new Date(), DATE_FORMATS.yearFirst)
  const todayCell = document.getElementById(`table-cell-${todayDate}`)

  useEffect(() => {
    if (todayCell) {
      todayCell &&
        setTimeout(() => {
          todayCell.scrollIntoView({ behavior: 'smooth' })
        }, 0)
    }
  }, [])

  // keep it for now (browser's back button control concept)

  // const [isModalOpen, setIsModalOpen] = useState(false)
  // useEffect(() => {
  //   // Push a new state to the history stack
  //   window.history.pushState(null, document.title, window.location.href)

  //   // Add an event listener for the popstate event
  //   const handlePopState = event => {
  //     setIsModalOpen(true)
  //     window.history.pushState(null, document.title, window.location.href)
  //   }

  //   window.addEventListener('popstate', handlePopState)

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('popstate', handlePopState)
  //   }
  // }, [location])

  return (
    <Wrapper data-testid='Calendar-Wrapper'>
      <TableHeader data-testid='Calendar-TableHeader'>
        <MonthsToggle data-testid='Calendar-MonthsToggle' />
      </TableHeader>
      <DaysOfTheWeek id='days-header' data-testid='Calendar-DaysOfTheWeek'>
        {DAYS_OF_WEEK.map((day, index) => (
          <Day
            key={`day-${day}-${index}`}
            isToday={today === index}
            data-testid={`Calendar-Day-${day}-${index}`}
          >
            {day}
          </Day>
        ))}
      </DaysOfTheWeek>
      <CalendarContainer
        data-testid='Calendar-CalendarContainer'
        id='scrollable-calendar-container'
        manualOpen={manualOpen}
        hasManualEvents={hasManualEvents}
      >
        {/* {isModalOpen && <PreventBackButtonAfterInitModal />} */}
        {calendarWeeks
          ? calendarWeeks.map(week => {
              const days = week.days.map(day => day.date)
              const year = new Date(days[0]).getFullYear()
              const month = new Date(days[6]).getMonth() + 1

              const daysRange = `${days[0]} - ${days[days.length - 1]}`

              return (
                <Table
                  key={`${daysRange}-${month}-${year}`}
                  data-testid='Calendar-Table'
                >
                  <tbody data-testid='Calendar-tbody'>
                    {createCalendarRowsFromWeeks(days as string[]).map(
                      (row, rowIndex) => {
                        return (
                          <CalendarRow
                            data-testid={`Calendar-CalendarRow-${month}-${rowIndex}`}
                            key={`${year}-${month}-row-${rowIndex}`}
                            row={row}
                            rowIndex={rowIndex}
                            year={year}
                            month={month}
                          />
                        )
                      }
                    )}
                  </tbody>
                </Table>
              )
            })
          : 'Theres been an error, please refesh the page.'}
      </CalendarContainer>
    </Wrapper>
  )
}

export default Calendar

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.main.primary500};
  border-radius: 10px;
  margin: 10px 0;
  overflow: hidden;
`

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  position: relative;
  height: ${({ manualOpen }) =>
    manualOpen
      ? 'calc(100vh - 207px - 40vh)'
      : 'calc(100vh - 232px)'} !important;
`

const Table = styled.table`
  /* margin: 20px 0 40px; */
  background-color: #fff;

  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }

  td,
  th {
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
  }

  width: 100%;
  table-layout: fixed;
`

// const Caption = styled.caption`
//   font-size: 22px;
//   font-weight: bold;
//   padding: 16px 0;
//   background-color: #fff;
// `

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  background-color: ${({ theme }) => theme.colors.main.primary200};
  font-weight: 700;
  /* position: sticky; */
  /* bottom: 0px; */

  button {
  }

  svg {
    color: #7e441e;
  }
`

const DaysOfTheWeek = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
`

const Day = styled.div`
  background-color: ${({ theme, isToday }) =>
    isToday ? '#EFD8CB;' : theme.colors.main.white};
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: ${({ isToday }) => (isToday ? 'bold' : 'normal')};
  border-right: 1px solid ${({ theme }) => theme.colors.main.grey300};
`

import { pipe, find, propEq, ifElse, identity, always } from 'ramda'
import { isNilOrEmpty } from 'utils/ramda'
import debounce from 'lodash.debounce'

export const getOptionByValue =
  (value: string | number | boolean) =>
  (
    options: {
      label: string
      value: string | number | boolean
      [x: string]: any
    }[]
  ) =>
    pipe(
      find(propEq('value', value)),
      ifElse(isNilOrEmpty, always(null), identity)
    )(options)

export const validateFieldFunction =
  (schema, values) =>
  (name, callback = (p: { valid: boolean; errors: any[] }) => {}) =>
    schema
      .validateAt(name, values)
      // eslint-disable-next-line node/no-callback-literal
      .then(() => callback({ valid: true, errors: [] }))
      .catch(callback)

export const validateField = (schema, values) => {
  return debounce(validateFieldFunction(schema, values), 200)
}

export const validateValuesFunction = schema => (values, callback) =>
  schema.isValid(values).then(callback)

export const validateValues = schema =>
  debounce(validateValuesFunction(schema), 400)

import * as R from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from '../../../utils/redux'

export const selectGlossary = state => state.glossary

export const getGlossaryPhrasesByAvailableBooks = createSelector(
  selectGlossary,
  R.propOr([], 'words')
)

export const getIsGlossaryLoading = createSelector(
  selectGlossary,
  R.pipe(R.pathOr('', ['words', 'state']), R.equals(API_STATES.IN_PROGRESS))
)

import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { getCustomBoxesOptionsByBook } from 'modules/flashcards/ducks/selectors'
import { propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { Button, SingleSelect } from 'examkrackers-components'
import { getOptionByValue } from 'utils/form'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'utils/ramda'
import { addFlashcardToCustomBox } from 'services/FlashcardsService'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from '../../../utils/toast'

import { Course } from 'types'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const FormAddToCustomBox = ({ flashcard, handleClose }): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedBox, setSelectedBox] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const flashcardBookId = propOr('', 'book_id', flashcard)
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)

  // @ts-ignore
  const boxOptions: { value: string; label: string }[] = useSelector(state =>
    getCustomBoxesOptionsByBook(state, flashcardBookId)
  )

  const handleSelectBox = option => setSelectedBox(propOr('', 'value', option))

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleSubmit = e => {
    e.preventDefault()
    const student_flashcard_id: string = propOr(
      '',
      'student_flashcard_id',
      flashcard
    )
    AnalyticsService(user).logEvent(ANALYTICS_EVENTS.flashcardAddedToABox, {
      'Course type': currentCourse.type || false,
      'Course name': currentCourse.title || false,
      'Course expiration date': currentCourse?.accessible_to || false,
      'Flashcard Proficiency Level': flashcard?.proficiency_level || false,
      'Flashcard # id': flashcard?.id || false,
      'Method of adding to the box': 'Select from existing custom box'
    })

    setIsLoading(true)

    const handleSuccess = () => {
      showToast({
        key: 'toast.addedToCustomBox',
        severity: SEVERITY.success,
        options: {
          title: getOptionByValue(selectedBox)(boxOptions).label
        }
      })
      handleClose()
      setIsLoading(false)
    }
    const handleError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
      setIsLoading(false)
    }

    addFlashcardToCustomBox({
      box_id: selectedBox,
      student_flashcard_id
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <SelectContainer>
        <SingleSelect
          removeTopLabel
          removeMargin
          size='small'
          value={getOptionByValue(selectedBox)(boxOptions)}
          options={boxOptions}
          label=''
          placeholder=''
          onChange={handleSelectBox}
          isSearchable={false}
        />
      </SelectContainer>
      <ButtonsContainer>
        <Button
          size='small'
          color='secondary'
          id='add-to-box-submit'
          type='submit'
          isLoading={isLoading}
          disabled={isLoading || isNilOrEmpty(selectedBox)}
        >
          {t('flashcards.addToCustomBox.submitCTA')}
        </Button>
        <Button
          size='small'
          id='add-to-box-cancel'
          disabled={isLoading}
          color='tertiary'
          variant='contained'
          onClick={handleClose}
        >
          {t('flashcards.addToCustomBox.cancelCTA')}
        </Button>
      </ButtonsContainer>
    </FormContainer>
  )
}

export default FormAddToCustomBox

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
const SelectContainer = styled.div`
  width: 100%;
  max-width: 300px;
`

const ButtonsContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  button {
    min-width: 150px;
  }
`

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  AminoAcidGame,
  ThemeProvider,
  GlobalStyles
} from 'examkrackers-amino-acid-game'

import { Course } from 'types'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStudent } from 'modules/auth/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const SaltyBlox = (): JSX.Element => {
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const [playAminoAcidGame, setPlayAminoAcidGame] = useState(true)

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && playAminoAcidGame) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.playAminoAcidGame, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Game name': 'Amino Acid Game'
      })
      setPlayAminoAcidGame(false)
    }
  }, [currentCourse, playAminoAcidGame])

  return (
    <ThemeProvider>
      <>
        <GlobalStyles />
        <AminoAcidGame />
      </>
    </ThemeProvider>
  )
}

export default SaltyBlox

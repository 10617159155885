import React, { useEffect, useState } from 'react'
import { timeConfigs } from 'modules/exam/utils/timerWizard'
import { getTimeConfigBySection } from 'modules/exam/utils/examTimeConfig'

import { ExamHeader } from 'examkrackers-components'
import { ExamPageConfigProps, ExamSectionDetails } from 'types'
import { secondsToTime, getStartSeconds } from 'modules/exam/utils/time'
import usePrevious from 'hooks/usePrevious'
import TimeAlertsHandler from 'modules/exam/components/Timer/TimeAlertsHandler'
import { useSelector } from 'react-redux'
import {
  getExamQuestionsMap,
  getSectionsIdsWithCurrentTime,
  getExamDetails
} from 'modules/exam/ducks/selectors'
import { pathOr, pipe, propOr } from 'ramda'
import { getStudent } from 'modules/auth/ducks/selectors'

interface TimerProps {
  currentPage: string
  currentPageConfig: ExamPageConfigProps
  setSecondsLeft: (e) => void
  setCurrentPage: (id) => void
  onTimeEnd: () => any
}

export const Timer = ({
  currentPage,
  onTimeEnd,
  currentPageConfig,
  setCurrentPage,
  setSecondsLeft
}: TimerProps): JSX.Element => {
  const examPagesSummary: ExamSectionDetails[] =
    useSelector(getExamQuestionsMap)
  const examDetails = useSelector(getExamDetails)
  const timeOptionMultiplier = pipe(
    pathOr('1.0', ['exam', 'time_option']),
    Number
  )(examDetails)
  const user = useSelector(getStudent)

  const sectionIdsWithCurrentTime = useSelector(getSectionsIdsWithCurrentTime)
  const dynamicTimeConfigs = getTimeConfigBySection(
    sectionIdsWithCurrentTime,
    timeOptionMultiplier
  )(examPagesSummary)
  const examTimeConfigs = [...timeConfigs, ...dynamicTimeConfigs]
  const startSeconds = getStartSeconds(examTimeConfigs)(currentPage)
  const [timerState, setTimerState] = useState({
    timeRemaining: secondsToTime(Number(startSeconds)),
    secondsLeft: startSeconds
  })
  const prevPage = usePrevious(currentPage)
  const timeRemaining = `${timerState.timeRemaining.h}:${timerState.timeRemaining.m}:${timerState.timeRemaining.s}`

  const countDown = () => {
    setTimerState({
      // @ts-ignore
      timeRemaining: secondsToTime(timerState.secondsLeft - 1),
      // @ts-ignore
      secondsLeft: timerState.secondsLeft - 1
    })
  }

  // FIXME: This is a hack to stop the pause handler from crashing the page.
  // As soon as this component is rendered, one second is deducted from the exam time.
  useEffect(() => {
    countDown()
  }, [])

  useEffect(() => {
    const timer = setInterval(countDown, 1000)
    const hasTimerConfigChanged =
      startSeconds !== getStartSeconds(examTimeConfigs)(prevPage)

    if (timerState.secondsLeft === 0 || hasTimerConfigChanged) {
      clearInterval(timer)
    }

    if (hasTimerConfigChanged) {
      setTimerState({
        timeRemaining: secondsToTime(Number(startSeconds)),
        secondsLeft: startSeconds
      })
    }

    setSecondsLeft(timerState.secondsLeft)

    return () => {
      clearInterval(timer)
    }
  }, [timerState, currentPage])

  return (
    <>
      <ExamHeader
        title={`${pathOr('', ['exam', 'title'], examDetails)} - ${propOr(
          '',
          'name',
          user
        )}`}
        timer={timeRemaining}
        currentPage={currentPageConfig.currentPage}
        totalPages={currentPageConfig.totalPages}
        timerVisibility={currentPageConfig.timerVisibility}
        pagesVisibility={currentPageConfig.pagesVisibility}
      />
      <TimeAlertsHandler
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        timeRemaining={timeRemaining}
        hasTime={currentPageConfig.timerVisibility}
        onTimeEnd={onTimeEnd}
      />
    </>
  )
}

export default Timer

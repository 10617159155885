import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ExamContent, WysiwygViewer } from 'examkrackers-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  getSectionDetailsById,
  getSectionFullTitleById
} from 'modules/exam/ducks/selectors'
import { RootReducer } from 'types'
import { propOr, pathOr, pipe } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export const SectionIntro = (props): JSX.Element => {
  const { t } = useTranslation()
  const {
    setCurrentPage,
    currentPage,
    currentPageConfig,
    setNavigationHandlers
  } = props
  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, currentPage)
  )
  const sectionFullTitle = useSelector((state: RootReducer) =>
    getSectionFullTitleById(state, currentPage)
  )
  const handleNext = () => setCurrentPage(currentPageConfig.nextPageId)
  const handlePrevious = () => setCurrentPage(currentPageConfig.prevPageId)

  useEffect(() => {
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: handleNext,
      previousButtonOnClick: handlePrevious
    }))
  }, [])

  const sectionDescription = {
    1: (
      <div>
        <p>
          Most questions in the Chemical and Physical Foundations of Biological
          Systems section are organized into question sets, each preceded by a
          descriptive passage. Some questions are not based on a descriptive
          passage and are also independent of one another. You must select the
          best answer option for each question. If you are not certain of an
          answer, eliminate the answer options you know to be incorrect and then
          choose an answer from the remaining options.
        </p>{' '}
        <p>A periodic table is provided for your reference.</p>{' '}
        <p>
          Select the text, graphic, or circle next to the answer option of your
          choice and use{' '}
          <strong>
            <u>N</u>ext
          </strong>{' '}
          to advance to the next question.
        </p>{' '}
        <p>
          Your score is determined by the number of questions you answer
          correctly. There is no penalty for guessing an answer to a question.
          Therefore, it is to your advantage to{' '}
          <strong>answer every question.</strong>
        </p>{' '}
        <p>
          Select{' '}
          <strong>
            <u>N</u>ext
          </strong>{' '}
          to advance to the first question of the Chemical and Physical
          Foundations of Biological Systems section.
        </p>
      </div>
    ),
    2: (
      <div>
        <p>
          There are nine passages in the Critical Analysis and Reasoning Skills
          section. Each passage is followed by several questions. After reading
          a passage, select the best answer to each question. If you are not
          certain of an answer, eliminate the answer options you know to be
          incorrect and then choose an answer from the remaining options.{' '}
        </p>{' '}
        <p>
          Select the text, graphic, or circle next to the answer option of your
          choice and use{' '}
          <strong>
            <u>N</u>ext
          </strong>{' '}
          to advance to the next question.
        </p>{' '}
        <p>
          Your score is determined by the number of questions you answer
          correctly. There is no penalty for guessing an answer to a question.
          Therefore, it is to your advantage to{' '}
          <strong>answer every question.</strong>
        </p>{' '}
        <p>
          Select{' '}
          <strong>
            <u>N</u>ext
          </strong>{' '}
          to advance to the first question of the Critical Analysis and
          Reasoning Skills section.
        </p>
      </div>
    ),
    3: (
      <div>
        <p>
          Most questions in the Biological and Biochemical Foundations of Living
          Systems section are organized into question sets, each preceded by a
          descriptive passage. Some questions are not based on a descriptive
          passage and are also independent of one another. You must select the
          best answer option for each question. If you are not certain of an
          answer, eliminate the answer options you know to be incorrect and then
          choose an answer from the remaining options.
        </p>{' '}
        <p>A periodic table is provided for your reference. </p>{' '}
        <p>
          Select the text, graphic, or circle next to the answer option of your
          choice and use{' '}
          <strong>
            <u>N</u>ext
          </strong>{' '}
          to advance to the next question.
        </p>{' '}
        <p>
          Your score is determined by the number of questions you answer
          correctly. There is no penalty for guessing an answer to a question.
          Therefore, it is to your advantage to{' '}
          <strong>answer every question.</strong>
        </p>{' '}
        <p>
          Select{' '}
          <strong>
            <u>N</u>ext
          </strong>{' '}
          to advance to the first question of the Biological and Biochemical
          Foundations of Living Systems section.
        </p>
      </div>
    ),
    4: (
      <div>
        <p>
          Most questions in the Psychological, Social, and Biological
          Foundations of Behavior section are organized into question sets, each
          preceded by a descriptive passage. Some questions are not based on a
          descriptive passage and are also independent of one another. You must
          select the best answer option for each question. If you are not
          certain of an answer, eliminate the answer options you know to be
          incorrect and then choose an answer from the remaining options.
        </p>{' '}
        <p>
          Select the text, graphic, or circle next to the answer option of your
          choice and use{' '}
          <strong>
            <u>N</u>ext
          </strong>{' '}
          to advance to the next question.
        </p>{' '}
        <p>
          Your score is determined by the number of questions you answer
          correctly. There is no penalty for guessing an answer to a question.
          Therefore, it is to your advantage to{' '}
          <strong>answer every question.</strong>
        </p>{' '}
        <p>
          Select{' '}
          <strong>
            <u>N</u>ext
          </strong>{' '}
          to advance to the first question of the Psychological, Social, and
          Biological Foundations of Behavior section.
        </p>
      </div>
    )
  }

  const savedIntroPage = propOr({}, 'intro_page_content', sectionDetails)

  const findDescriptionProp: number = propOr(1, 'order')(sectionDetails)

  const deltaObject = pipe(
    propOr('{}', 'delta_object'),
    JSON.parse
  )(savedIntroPage)

  const description = isNotNilOrEmpty(savedIntroPage) ? (
    <WysiwygContainer>
      <WysiwygViewer id='intro-page-description' value={deltaObject} />
    </WysiwygContainer>
  ) : (
    sectionDescription[findDescriptionProp]
  )

  return (
    <ExamContent>
      <SectionIntroContainer>
        <h1 className='center'>{sectionFullTitle}</h1>
        <br />
        <p className='center'>
          <strong>
            {t('exam.questionsNo', {
              number: pathOr(0, ['sectionLength', 'amount'])(sectionDetails)
            })}
          </strong>
        </p>
        <p>{description}</p>
      </SectionIntroContainer>
    </ExamContent>
  )
}

export default SectionIntro

const SectionIntroContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;

  .center {
    text-align: center;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
    text-align: justify;
  }

  h1 {
    line-height: 50px;
  }
`

const WysiwygContainer = styled.div`
  .center {
    text-align: center;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
    text-align: justify;
  }

  h1 {
    line-height: 27px;
  }

  .ql-container.ql-snow {
    height: 100%;
    border: none !important;

    font-size: 16px !important;
    line-height: 24px !important;
    text-shadow: 0 0 !important;
    font-family: Verdana, Geneva, sans-serif !important;
    color: ${({ theme }) => theme.exam.original.black} !important;

    * {
      color: ${({ theme }) => theme.exam.original.black} !important;
    }
  }
`
